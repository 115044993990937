﻿//test
import React, { useEffect, useState } from 'react';
import InlineDataGrid from '../../components/common/InlineDataGrid';
import { getUser, getIP, getLanguage, getMenuMappingInfo } from '../../apiInterface/utils/Common';
import { getBusinessIds, getProductInfo, insertProductInfo, updateProductInfo, deleteProductInfo, getProductById } from '../../apiInterface/productGroup/ProductGroupAPI';
import useTranslation from '../../components/customHooks/translations';
import CustomStore from 'devextreme/data/custom_store';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import { useLocation } from 'react-router-dom';
import { DropdownField, FilterComponent } from '../../components/common/FormField';
import BaseDataGrid from '../../components/common/BaseDataGrid';
import { successToastr } from '../../components/common/Toastr';
import { PageDropdown } from '../../components/common/PageDropdown';
import Badge from '../../components/common/Badge';
import { PageTitle } from '../../components/common/PageTitle.jsx';

let searchText = '';
const ProductGroup = () => {
    const [dataSource, setDataSource] = useState([]);
    const [businessIds, setBusinessIds] = useState([]);
    const [curBusinessId, setCurBusinessId] = useState();
    const translation = useTranslation();
    const errorHandler = useErrorHandler();
    const location = useLocation();
    const activeNotActiveJson = { true: translation.active, false: translation.not_active };
    const activeNotActiveClassName = { true: 'grid-badge-use', false: 'grid-badge-disabled' };

    const headerDict = [
        { dataField: 'product_group_no', caption: translation.no, dataType: 'string', isFixed: true, width: 100, alignment: 'center', allowReordering: true },
        { dataField: 'product_group_code', caption: translation.product_group_code.toUpperCase(), dataType: 'string', isFixed: true, allowReordering: true },
        { dataField: 'product_group_name', caption: translation.product_group_name.toUpperCase(), dataType: 'string', isFixed: true, allowReordering: true },
        { dataField: 'user_business_name', caption: translation.user_business_name.toUpperCase(), dataType: 'string', isFixed: true, allowReordering: true },
        {
            dataField: 'product_group_is_active',
            caption: translation.product_group_is_active.toUpperCase(),
            dataType: 'string',
            width: 130,
            alignment: 'center',
            cellRender: (e) => {
                return <Badge className={activeNotActiveClassName[e.value]} text={activeNotActiveJson[e.value]} />;
            },
            allowReordering: true,
        },
        { dataField: 'product_group_comment', caption: translation.product_group_comment.toUpperCase(), dataType: 'string', allowReordering: true },
        { dataField: 'product_group_modified_by', caption: translation.lastModifiedBy.toUpperCase(), dataType: 'string', width: 300, allowReordering: true },
        { dataField: 'product_group_modified_date', caption: translation.lastModifiedDate.toUpperCase(), dataType: 'string', width: 150, allowReordering: true },
    ];

    const onValueChanged = async (e) => {
        makeCustomStore(e.value);
        sessionStorage.setItem('businessId', e.value);
        setCurBusinessId(e.value);
    };

    const makeCustomStore = (businessId = curBusinessId) => {
        const customStore = new CustomStore({
            load: async (loadOptions) => {
                return read(loadOptions, businessId);
            },
            insert: async (value) => {
                create(value, businessId);
            },
            update: async (previouseValue, changedValue) => {
                const newData = { ...previouseValue, ...changedValue };
                update(newData);
            },
            remove: async (value) => {
                remove(value);
            },
        });
        setDataSource(customStore);
    };

    const read = async (loadOptions, businessId) => {
        const language = getLanguage();
        const user = getUser();
        const pageNo = parseInt(loadOptions['skip'] / loadOptions['take']) + 1;
        const param = {
            user_company_id: user.user_company_id,
            sys_lang_code: language,
            user_business_id: businessId,
            row_count: loadOptions['take'],
            page_no: pageNo,
            search_text: searchText,
        };

        const res = await errorHandler(getProductInfo, param);
        if (res) {
            return {
                data: res.data.o_data,
                totalCount: res.data.count,
            };
        }
    };

    const create = async (newData, businessId) => {
        const { id: mapping_id } = await getMenuMappingInfo();
        const user = getUser();
        const ipAddress = getIP();
        const queryData = {
            user_master_id: user.user_master_id,
            user_log_ip: ipAddress,
            menu_mapping_id: mapping_id,
            user_business_id: businessId,
            product_group_code: newData.product_group_code,
            product_group_name: newData.product_group_name,
            product_group_is_active: newData.product_group_is_active,
            product_group_comment: newData.product_group_comment,
        };

        const response = await errorHandler(insertProductInfo, queryData);
        if (response) {
        }
    };

    const update = async (newData) => {
        const { id: mapping_id } = await getMenuMappingInfo();
        const user = getUser();
        const ipAddress = getIP();
        const queryData = {
            user_master_id: user.user_master_id,
            user_log_ip: ipAddress,
            menu_mapping_id: mapping_id,
            product_group_id: newData.product_group_id,
            product_group_name: newData.product_group_name,
            product_group_is_active: newData.product_group_is_active,
            product_group_comment: newData.product_group_comment,
        };

        const response = await errorHandler(updateProductInfo, queryData);
        if (response) {
        }
    };

    const remove = async (value) => {
        const { id: mapping_id } = await getMenuMappingInfo();
        const user = getUser();
        const ipAddress = getIP();
        const queryData = {
            user_master_id: user.user_master_id,
            user_log_ip: ipAddress,
            user_menu_mapping_id: mapping_id,
            product_group_id: value.product_group_id,
        };

        const response = await errorHandler(deleteProductInfo, queryData);
        if (response !== 'fail' && response.message === 'MSG_100') {
            successToastr(translation.success_delete_row);
        }
    };

    //init
    useEffect(() => {
        searchText = '';
        const dropDownItems = async () => {
            const user = getUser();
            let param = { user_company_id: user.user_company_id };
            const result = await errorHandler(getBusinessIds, param);
            if (result) {
                setBusinessIds(result.data.o_data);

                if (location.state?.previousPage == '/ProductGroupForm') {
                    setCurBusinessId(parseInt(sessionStorage.getItem('businessId')));
                } else {
                    let isBusinessExist = false;
                    if (user.user_business_id != null) {
                        const branch = result.data.o_data.find((branch) => branch.user_business_id == user.user_business_id);
                        if (branch != null) {
                            setCurBusinessId(user.user_business_id);
                            isBusinessExist = true;
                        }
                    }

                    //if user info doesn't have business or server's response doesn't have user's business set first row as a default value.
                    if (!isBusinessExist) {
                        setCurBusinessId(result.data.o_data[0].user_business_id);
                    }
                }
            }
        };
        dropDownItems();
    }, []);

    const businessDropdown = <DropdownField label={translation.select_business} value={curBusinessId} dataSource={businessIds} onValueChanged={onValueChanged} valueExpr="user_business_id" displayExpr="user_business_name" isSearchable={true} width={204} />;

    const filtering = (keyword) => {
        searchText = keyword;
        makeCustomStore();
    };

    const filter = <FilterComponent label={translation.filter} width={204} onFilter={filtering} />;

    return (
        <div className="product-group">
            <div className="right-content-title">
                <PageTitle />
                <PageDropdown />
            </div>
            <div className="right-content-body">
                <div className="container">
                    <BaseDataGrid toolbarModules={[businessDropdown, filter]} allowModify={true} dataSource={dataSource} headers={headerDict} isRemote={true} link="ProductGroupForm" selectMode="multiple" isFullSize={true} />
                </div>
            </div>
        </div>
    );
};

export default ProductGroup;
