import React from "react";
import DataGrid, {
  Column, Paging, RequiredRule, Pager, Selection, SearchPanel,
} from 'devextreme-react/data-grid';
import DateBox from 'devextreme-react/date-box';

import { useState, useEffect } from "react";

function Grid(props) {
  const cellRender = (value) => {
    const headerIdx = value.columnIndex;
    const captionForBadge = props.headers[headerIdx]["captionForBadge"];
    const classNameForBadge = props.headers[headerIdx]["classNameForBadge"];
    return (
      <div className="grid-badge">
        {value.value !== undefined && <span className={`${classNameForBadge[value.value]}`}>{captionForBadge[value.value]}</span>}
      </div>
    );
  }

  //const onTextChange = (e) => {
  //  props.setSearchText(e);
  //}
  //const [now, setNow] = useState();
  //const getTime = () => {
  //  const now = new Date();
  //  setNow(now);
  //}

  //useEffect(() => {
  //  getTime();
  //}, [])

  return (
    <>
      {false &&
        <DateBox
          defaultValue={now}
          type="date"
          width={200}
        />
      }
      <DataGrid
        dataSource={props.dataSource}
        onRowClick={props.onRowClick}
        columnAutoWidth={true}
        remoteOperations={true}
        width={props.width}
        hoverStateEnabled={true}
      >
        {
          props.selectMode &&
          <Selection
            allowSelectAll={true}
            selectAllMode={'page'}
            mode={props.selectMode}
          />
        }
        {props?.headers?.map((value) => {
          const validation = value.isRequired && <RequiredRule />
          return (
            <Column
            key={value.dataSource}
            dataField={value.dataSource}
            caption={value.caption}
            dataType={value.dataType}
            width={value.width}
            alignment={value.alignment}
            cellRender={value.isBadge ? cellRender : null}
            >{validation}</Column>
          )
        })}
        {props.isPaging &&
          <Paging defaultPageSize={props.defaultPageSize} />
        }
        <Pager visible={props.pagerVisible} />
      </DataGrid>
    </>
  );
}

export default Grid;