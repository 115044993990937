import React, { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import SunEditor, { buttonList } from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { uploadTextEditorImage } from '../../apiInterface/menu/MenuAPI';
import { getLanguage, getUser } from '../../apiInterface/utils/Common';
import { BASE_URL } from '../../config';

export default function Editor(props) {
    const {
        visible,
        editorContent,
        initVal,
    } = props;

    const editor = useRef();
    const lang = getLanguage();
    const user = getUser();
    const imageList = useRef();

    useEffect(() => {
        editor.current.core.setContents(initVal);
    }, [visible])

    const getSunEditorInstance = (sunEditor) => {
        editor.current = sunEditor;
    }

    const onChange = (e) => {
        console.log('변경중');
        editorContent.current = e;
    }

    const onImageUploadBefore = async (files, info, uploadHandler) => {
        const bodyFormData = new FormData();
        bodyFormData.append('img', files[0]);
        const res = await uploadTextEditorImage(bodyFormData, user.user_company_id);
        const fileList = [];
        if(res !== 'fail' && res.message === "MSG_100") {
            fileList.push({url: `${BASE_URL}static_files/${res.db_url}`, name: files[0].name})
        }
        uploadHandler({result : fileList});
        imageList.current = {result : fileList};
    }

    const onImageUpload = async (targetImgElement, index, state, imageInfo, remainingFilesCount) => {
        if(imageInfo?.src?.includes('base64')) imageInfo.delete();
    }

    return (
        <div>
            <SunEditor
                name="sun-editor"
                lang={lang}
                placeholder="Please type here"
                autoFocus={true}
                setOptions={{
                    buttonList: [
                        ["font", "fontSize"],
                        ["bold", "underline", "italic"],
                        ["align"],
                        ["fontColor", "hiliteColor"],
                        ["undo", "redo"],
                        ["image"],
                    ],
                    font: ["맑은고딕", "Aria", "바탕", "굴림"],
                    // value: initContent,
                    historyStackDelayTime: 0,
                    defaultStyle: "font-family: 맑은고딕",
                    // plugin: []
                    // imageUploadUrl: imageList.current
                }}
                getSunEditorInstance={getSunEditorInstance}
                // setContents={editorContent}
                // hideToolbar={true}
                onChange={onChange}
                // setContents={initVal?.length > 0 ? initVal : ""}
                onImageUploadBefore={onImageUploadBefore}
                onImageUpload={onImageUpload}
            />
        </div>
    );
}