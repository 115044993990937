//test 
import {
    getOperationFactory,
    getEquipmentInfo,
    deleteEquipmentInfoByEquipmentId,
    getMoldInfoByEquipmentId
} from "../../apiInterface/machine/EquipmentAPI";
import { getUser, getLanguage, getIP, getMenuMappingInfo } from '../../apiInterface/utils/Common';
import { DropdownField, FilterComponent } from "../../components/common/FormField";
import { successToastr } from "../../components/common/Toastr";
import { PageDropdown } from "../../components/common/PageDropdown";
import { Allotment } from "allotment";
import { Button } from "devextreme-react";
import { useRef } from "react";
import useErrorHandler from "../../components/customHooks/useErrorHandler";
import useTranslation from "../../components/customHooks/translations";
import BaseDataGrid from "../../components/common/BaseDataGrid";
import CustomStore from 'devextreme/data/custom_store';
import TreeCard from "../../components/common/TreeCard";
import React, { useEffect, useState } from "react";
import { PageTitle } from "../../components/common/PageTitle.jsx";
import Badge from "../../components/common/Badge";

let search_text = ""
const Equipment = () => {
    const translation = useTranslation();
    const allotmentRef = useRef();
    const activeNotActiveJson = { true: translation.active, false: translation.not_active };
    const activeNotActiveClassName = { true: "grid-badge-use", false: "grid-badge-disabled" }
    const [selectedOperationFactory, setSelectedOperationFactory] = useState(null);
    const [operationFactoryList, setOperationFactoryList] = useState([]);
    const [rightPaneVisible, setRightPanelVisible] = useState(false);
    const [customDataSource, setCustomDataSource] = useState([]);
    const [moldInfo, setMoldInfo] = useState();
    const equipmentHeaderInfo = [
        { dataField: "machine_equipment_no", caption: "NO", dataType: "number", width: 100, alignment: "center", isFixed: true },
        { dataField: "machine_equipment_type", caption: translation.machine_equipment_type.toUpperCase(), dataType: "string", isFixed: true },
        { dataField: "machine_equipment_code", caption: translation.machine_equipment_code.toUpperCase(), dataType: "string", isFixed: true },
        { dataField: "machine_equipment_name", caption: translation.machine_equipment_name.toUpperCase(), dataType: "string", isFixed: true },
        { dataField: "machine_equipment_made", caption: translation.machine_equipment_made.toUpperCase(), dataType: "string" },
        { dataField: "machine_equipment_supplier", caption: translation.machine_equipment_supplier.toUpperCase(), dataType: "string" },
        {
            dataField: "machine_equipment_is_active", caption: translation.machine_equipment_is_active.toUpperCase(), dataType: "boolean", width: 130, alignment: "center",
            width: 130, alignment: "center", cellRender: (e) => <Badge className={activeNotActiveClassName[e.value]} text={activeNotActiveJson[e.value]} />
        },
        { dataField: "machine_equipment_modelno", caption: translation.machine_equipment_modelno.toUpperCase(), dataType: "string" },
        { dataField: "machine_equipment_date", caption: translation.machine_equipment_date.toUpperCase(), dataType: "string" },
        { dataField: "machine_equipment_comment", caption: translation.machine_equipment_comment.toUpperCase(), dataType: "string" },
        { dataField: "machine_equipment_modified_by", caption: translation.lastModifiedBy.toUpperCase(), dataType: "string", width: 300 },
        { dataField: "machine_equipment_modified_date", caption: translation.lastModifiedDate.toUpperCase(), dataType: "string", width: 150 },
    ];

    const errorHandler = useErrorHandler();

    const operationFactory = async () => {
        const user = getUser();
        const param = { user_company_id: user.user_company_id };
        const res = await errorHandler(getOperationFactory, param);
        if (res) {
            setOperationFactoryList(res.data.o_data);
            if (res.data.o_data.length > 0) {
                setSelectedOperationFactory(res.data.o_data[0].operation_factory_id);
            }
        }
    }

    useEffect(() => {
        search_text = "";
        operationFactory();
    }, []);

    const onDropdownChanged = async (e) => {
        var operation_factory_id = e.value;
        setSelectedOperationFactory(operation_factory_id);
        setMoldInfo([]);
        makeCustomStore(e.value)
    }

    const makeCustomStore = (factoryId = selectedOperationFactory) => {
        const customStore = new CustomStore({
            load: async (loadOptions) => {
                let skip = 0;
                let take = 10;
                if (loadOptions["skip"]) skip = loadOptions["skip"];
                if (loadOptions["take"]) take = loadOptions["take"];
                const pageNo = parseInt(skip / take) + 1;
                const language = getLanguage();
                const user = getUser();
                const param = {
                    user_company_id: user.user_company_id,
                    operation_factory_id: factoryId,
                    sys_lang_code: language,
                    row_count: take,
                    page_no: pageNo,
                    search_text: search_text,
                }
                const res = await errorHandler(getEquipmentInfo, param);
                if (res) {
                    const equipmentInfo = res.data.o_data;
                    const pagingDict = {
                        data: equipmentInfo,
                        totalCount: res.data.count
                    }
                    return pagingDict;
                } else {
                    return {
                        data: [],
                        totalCount: 0,
                    }
                }
            },
            remove: (key) => {
                deleteEquipmentInfo(key);
            }
        });
        setCustomDataSource(customStore);
    }

    const deleteEquipmentInfo = async (data) => {
        const user = getUser();
        const ipAddress = getIP();
        const mappingInfo = await getMenuMappingInfo();
        const queryData = {
            user_master_id: user.user_master_id,
            user_log_ip: ipAddress,
            user_menu_mapping_id: mappingInfo.id,
            machine_equipment_id: data.machine_equipment_id,
        }

        const res = await errorHandler(deleteEquipmentInfoByEquipmentId, queryData);
        if (res) {
            successToastr(translation.success_delete_row);
        }
    }

    const dropdown = <DropdownField
        label={translation.select_factory}
        dataSource={operationFactoryList}
        displayExpr="operation_factory_name"
        valueExpr="operation_factory_id"
        onValueChanged={onDropdownChanged}
        value={selectedOperationFactory}
        width={204}
    />

    const filtering = (keyword) => {
        search_text = keyword;
        makeCustomStore();
    }

    const filter = <FilterComponent
        label={translation.filter}
        width={204}
        onFilter={filtering}
    />

    const toggleRightPanel = () => {
        if (rightPaneVisible == true) {
            allotmentRef.current.resize([3, 1]);//you can set panel ratio.
        }
        setRightPanelVisible(!rightPaneVisible);
    }

    const onRowClick = async (e) => {
        const param = { machine_equipment_id: e.data.machine_equipment_id };
        const res = await errorHandler(getMoldInfoByEquipmentId, param);
        if (res) {
            setMoldInfo(res.data.o_data);
        }
    }

    return (
        <div className="equipment">
            <div className="right-content-title">
                <PageTitle />
                <div className="page-buttons">
                    <div>
                        <Button
                            icon="ix-panel"
                            type="normal"
                            stylingMode="text"
                            className="btn-s-r"
                            onClick={toggleRightPanel}
                        />
                    </div>
                    <PageDropdown />
                </div>
            </div>
            <div className="right-content-body">
                <Allotment ref={allotmentRef}>
                    <Allotment.Pane minSize={0}>
                        <div className="left-panel">
                            <div className="container">
                                <BaseDataGrid
                                    toolbarModules={[dropdown, filter]}
                                    dataSource={customDataSource}
                                    headers={equipmentHeaderInfo}
                                    isPaging={true}
                                    allowModify={true}
                                    link="EquipmentForm"
                                    selectMode="multiple"
                                    isRemote={true}
                                    isFullSize={false}
                                    onRowClick={onRowClick}
                                    params={{ operation_factory_id: selectedOperationFactory }}
                                />
                            </div>
                        </div>
                    </Allotment.Pane>
                    <Allotment.Pane minSize={0} visible={rightPaneVisible} snap={true}>
                        <div className="right-panel">
                            <div className="container">
                                <div className="panel-header">
                                    <p>Equipment Details</p>
                                </div>
                                <div className="panel-body">
                                    <TreeCard
                                        data={moldInfo}
                                        nameExpr="operation_name"
                                        badgeExprs={["operation_type", "operation_code"]}
                                        parentExpr="operation_parent_id"
                                        keyExpr="operation_id"
                                        isSelectable={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </Allotment.Pane>
                </Allotment>
            </div>
        </div>
    );
};

export default Equipment;
