import { useEffect, useState } from "react";
import useTranslation from "../../components/customHooks/translations";
import { Button, ColorBox, TextBox } from "devextreme-react";
import { TextboxField } from "../../components/common/FormField";
import ColumnStylingPopup from "./popup/ColumnStylingPopup";

const listAnimation = () => {
    let closedList = document.querySelectorAll('.toggle-con');

    closedList.forEach((li) => {
        li.style.height = "0px";
    })

    let openedList = document.querySelector('.open .toggle-con');
    if (openedList != null) {
        let curHeight = document.querySelector('.open .toggle-con ul').clientHeight;
        openedList.style.height = curHeight + "px";
    }
}

const openList = (e) => {
    console.log(e.target);
    if (e.target.closest('.no-open-event') !== null) return;

    let clickedList = e.currentTarget.parentElement;

    if (clickedList.classList.contains('open')) {
        clickedList.classList.toggle('open');
    } else {
        let listToggle = document.querySelectorAll('.toggle-tit');
        listToggle.forEach((t) => {
            t.parentElement.classList.remove('open');
        })

        clickedList.classList.add('open');
    }

    listAnimation();
}

const changeListHeight = () => {
    let openedList = document.querySelector('.open .toggle-con');
    if (openedList != null) {
        let curHeight = document.querySelector('.open .toggle-con ul').clientHeight;
        openedList.style.height = curHeight + "px";
    }
}

export const ColumnSetting = ({
    idx,
    columnInfo,
    isFirst,
    isLast,
    changeOrder = () => { },
    settingValueChanged = () => { },
    onDeleteColumnList = () => { },
    defaultValForStyling, // use this, when columns don't have value. It means just init value
}) => {
    const translation = useTranslation();
    const [isStylingColumnOpen, setStylingColumnOpen] = useState(false);
    const [stylingType, setStylingType] = useState();
    const unitList = ["px", "%"];

    return (
        <li key={idx}>
            <div className="toggle-tit" onClick={(e) => openList(e)}>
                <div className="tit">
                    <i></i>
                    <p>{columnInfo?.column?.name}</p>
                </div>
                <div className="no-open-event">
                    <div className="btn-order">
                        <Button
                            className="btn-s-xxs dx-button-mode-es"
                            type="default"
                            stylingMode="contained"
                            icon="arrowup"
                            disabled={isFirst}
                            onClick={e => changeOrder(idx, "up", "dg_column")}
                        />
                        <Button
                            className="btn-s-xxs dx-button-mode-es"
                            type="default"
                            stylingMode="contained"
                            icon="arrowdown"
                            disabled={isLast}
                            onClick={e => changeOrder(idx, "down", "dg_column")}
                        />
                    </div>
                    <Button
                        className="btn-s-xs btn-hover-red"
                        type="normal"
                        stylingMode="text"
                        icon="trash"
                        onClick={() => onDeleteColumnList(idx)}
                    />
                </div>
            </div>
            <div className="toggle-con">
                <ul>
                    <li>
                        <div style={{ display: "flex", gap: "8px" }}>
                            <TextboxField
                                size="xs"
                                label={translation.width}
                                value={columnInfo?.setting?.width}
                                onValueChanged={(e) => e.event !== undefined ? settingValueChanged("column_list", "width", e.value, idx) : null}
                            />
                            <div className="toggle-radio-btn">
                                {
                                    unitList.map((unit, i) => {
                                        return (
                                            <label key={i} onClick={_ => settingValueChanged("column_list", "unit", unit, idx)}>
                                                <input
                                                    type="radio"
                                                    checked={unit === columnInfo?.setting?.unit ? true : false}
                                                    readOnly
                                                />
                                                <span>{unit.toUpperCase()}</span>
                                            </label>
                                        );
                                    })
                                }
                            </div>
                        </div>
                        <div className="dx-field">
                            <div className="dx-field-label">
                                <p>{translation.header_styling}</p>
                            </div>
                            <div className="dx-field-value">
                                <Button
                                    className="btn-s-xs"
                                    type="normal"
                                    stylingMode="text"
                                    icon="m-setting"
                                    onClick={() => {
                                        setStylingType("header_styling");
                                        setStylingColumnOpen(true);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="dx-field">
                            <div className="dx-field-label">
                                <p>{translation.body_styling}</p>
                            </div>
                            <div className="dx-field-value">
                                <Button
                                    className="btn-s-xs"
                                    type="normal"
                                    stylingMode="text"
                                    icon="m-setting"
                                    onClick={() => {
                                        setStylingType("body_styling");
                                        setStylingColumnOpen(true);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="dx-field">
                            <div className="dx-field-label">
                                <p>{translation.show}</p>
                            </div>
                            <div className="dx-field-value">
                                <label className="toggle-on-off">
                                    <input type="checkbox"
                                        checked={!columnInfo?.setting?.show}
                                        value={columnInfo?.setting?.show}
                                        onClick={(e) => settingValueChanged("column_list", "show", !(e.target.value === "true"), idx)}
                                        readOnly
                                    />
                                </label>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <ColumnStylingPopup
                settingTarget="column_list"
                targetIdx={idx}
                stylingType={stylingType}
                visible={isStylingColumnOpen}
                onClose={() => setStylingColumnOpen(false)}
                onApply={(settingTarget, stylingType, value, targetIdx) => {
                    settingValueChanged(settingTarget, stylingType, value, targetIdx);
                    setStylingColumnOpen(false);
                }}
                stylingVal={columnInfo?.setting[stylingType]}
                defaultValForStyling={defaultValForStyling}
            />
        </li>
    );
}