import React, { useState } from 'react';
import Button from 'devextreme-react/button';
import TextBox from 'devextreme-react/text-box';
import '../../contents/styles/layout/navigation.css';
import 'material-icons';
import { Popup } from 'devextreme-react/popup';
import { ValidationGroup } from 'devextreme-react/validation-group';
import useTranslation from '../../components/customHooks/translations';
import { swal, swalConfirmation, useSwal } from '../../components/common/Swal';
import { changePasswordAPI } from '../../apiInterface/account/LoginAPI';
import { getIP, getLanguage, getServiceMappingInfo, getUser, moveService, removeMenuMappingInfo, removeServiceMappingInfo, removeUserSession, setMenuMappingInfo, removeUserLocal } from '../../apiInterface/utils/Common';
import { Validator, RequiredRule, EmailRule, AsyncRule } from 'devextreme-react/validator';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'devextreme-react/vector-map';
import useErrorHandler from '../customHooks/useErrorHandler';
import { getMenuMappingShortCut } from '../../apiInterface/menu/MenuAPI';
import defaultTemplateIcon from '../../contents/image/icon-template.svg';
import { useRef } from 'react';

function Navigation(props) {
    const translation = useTranslation();
    const validationRef = useRef();
    const navigate = useNavigate();
    const [isPopupVisible, setPopupVisibility] = useState(false);
    const [currentPassword, setCurrentPassword] = useState(null);
    const [newPassword, setNewPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);
    const [shortcutMenu, setShortcutMenu] = useState(null);
    const errorHandler = useErrorHandler();
    const { logout, success, fail, confirm } = useSwal();

    //const showAllMenu = (e) => {
    //    e.target.closest('.all-menu') === null && e.currentTarget.classList.toggle('show');
    //    document.querySelector('.dropdownMenu.show')?.classList.remove('show');
    //}

    const showDropdownMenu = (e) => {
        if (e.target.closest('.dropdownMenu') === null) {
            const showState = e.currentTarget.querySelector('.dropdownMenu')?.classList.contains('show');
            closeOtherDropdown();
            if (!showState) e.currentTarget.querySelector('.dropdownMenu')?.classList.add('show');
        }
    };

    const closeOtherDropdown = () => {
        document.querySelector('.dropdownMenu.show')?.classList.remove('show');
        document.querySelector('.view-all-menu.show')?.classList.remove('show');
    };

    const handleLogout = () => {
        var swalResFn = (res) => {
            if (res.isConfirmed) {
                // removeUserSession();
                // removeUserLocal();
                removeServiceMappingInfo();
                removeMenuMappingInfo();
                navigate('/login');
            }
        };
        logout(`<p>${translation.alertSignOut}</p>`, swalResFn);
    };

    const addProfileSubMenu = (subMenus) => {
        const icons = ['manage_account', 'lock', 'logout'];
        const menus = [];

        const addClickEvt = (e) => {
            const subMenuName = e.currentTarget.querySelector('.menuName').textContent;
            if (subMenuName === translation.myPage) {
                if (props.allMenuList === undefined) {
                    props.clickMenu(e, {
                        name: '회사관리',
                        page: translation.myPage,
                        link: 'CompanyManagementInfo',
                        mappingId: 8179,
                    });
                } else {
                    props.clickMenu(e, props.allMenuList.child[0].child[0].child[0]);
                }
            } else if (subMenuName === translation.changePassword) openPopup();
            else if (subMenuName === translation.logOut) handleLogout();
            closeOtherDropdown();
        };

        for (let i = 0; i < subMenus.length; i++) {
            menus.push(
                <li key={subMenus[i]} onClick={addClickEvt}>
                    <i className={'dx-icon-m-' + icons[i]}></i>
                    <span className="menuName">{subMenus[i]}</span>
                </li>
            );
        }

        return menus;
    };

    const getShortcutMenu = async () => {
        const user = getUser();
        const lang = getLanguage();
        const serviceMappingId = getServiceMappingInfo()?.id;
        if (serviceMappingId) {
            // In mainportal, there is not servicemapping info. so in that case, don't need to get the short cut
            const params = {
                user_company_id: user.user_company_id,
                sys_lang_code: lang,
                user_master_id: user.user_master_id,
                menu_mapping_service_id: serviceMappingId,
            };
            const res = await errorHandler(getMenuMappingShortCut, params);
            if (res) {
                const data = res.data.o_data;
                data.map((d) => (d.mappingId = d.menu_mapping_id));
                setShortcutMenu(data);
            }
        }
    };

    document.querySelector('html').addEventListener('click', function (e) {
        if (
            e.target.closest('.btn-dropdown') === null &&
            e.target.closest('.dropdownMenu') === null && //dropdown
            e.target.closest('.view-all-menu') === null &&
            e.target.closest('.all-menu') === null
        ) {
            //topmenu
            closeOtherDropdown();
        }
    });

    const closePopup = () => setPopupVisibility(!isPopupVisible);

    const openPopup = () => {
        if (validationRef.current != undefined) {
            validationRef.current.instance.reset();
        }
        setCurrentPassword(null);
        setNewPassword(null);
        setConfirmPassword(null);
        setPopupVisibility(!isPopupVisible);
    };

    const validateChangePassword = async (e) => {
        const check = e.validationGroup.validate();
        if (check.isValid) await changePassword();
    };

    const changePassword = async () => {
        if (newPassword !== confirmPassword) {
            setPopupVisibility(!isPopupVisible);
            var swalResFn = (res) => {
                setPopupVisibility(true);
            };
            fail(`<p>${translation.invalid_new_confirm_pwd}</p>`, swalResFn);
        } else if (currentPassword === newPassword) {
            setPopupVisibility(!isPopupVisible);
            var swalResFn = (res) => {
                setPopupVisibility(true);
            };
            fail(`<p>${translation.invalid_current_new_pwd}</p>`, swalResFn);
        } else {
            const ip = getIP();
            const user = getUser();
            const data = {
                id: user.user_master_id,
                email: user.user_master_email,
                old_password: currentPassword,
                new_password: newPassword,
                ip,
            };
            const res = await changePasswordAPI(data);
            if (res !== 'fail' && res.message === 'MSG_100') {
                setPopupVisibility(!isPopupVisible);
                success(`<p>${translation.success_change_pwd}</p>`);
            } else {
                setPopupVisibility(!isPopupVisible);
                var swalResFn = (res) => {
                    setPopupVisibility(true);
                };
                fail(`<p>${translation.invalid_currnet_pwd}</p>`, swalResFn);
            }
        }
    };

    const changePasswordRenderContent = () => {
        const moveFocusPopup = (e) => {
            const focusIdx = parseInt(e.event.currentTarget.getAttribute('popupfocusidx'));
            const nextInput = document.querySelector(`[popupfocusidx='${focusIdx + 1}']`);

            if (nextInput !== null) nextInput.focus();
            else document.querySelector('#changePwdBtn').click();
        };

        const pwdList = {
            currentPassword: [currentPassword, setCurrentPassword],
            newPassword: [newPassword, setNewPassword],
            confirmPassword: [confirmPassword, setConfirmPassword],
        };

        return (
            <>
                <div className="popup-header">
                    <p className="popup-title">{translation.changePassword}</p>
                </div>
                <form id="changePassword">
                    <ValidationGroup ref={validationRef}>
                        <div>
                            <div className="popup-body">
                                {/*<p dangerouslySetInnerHTML={{ __html: translation.forgotPasswordDesc }}></p>*/}
                                <div className="dx-fieldset">
                                    {Object.keys(pwdList).map((pwd, i) => (
                                        <div className="dx-field column">
                                            <div className="dx-field-label">{translation[pwd]}</div>
                                            <div className="dx-field-value">
                                                <TextBox
                                                    name={pwd}
                                                    mode="password"
                                                    placeholder={translation[pwd]}
                                                    className="ix-tfs-r"
                                                    value={pwdList[pwd][0]}
                                                    onValueChange={(e) => {
                                                        pwdList[pwd][1](e);
                                                    }}
                                                    onEnterKey={moveFocusPopup}
                                                    inputAttr={{ popupfocusidx: i }}
                                                >
                                                    <Validator>
                                                        <RequiredRule message={translation[`required_${pwd}`]} />
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="popup-footer">
                                <div className="dx-fieldset">
                                    <Button width="100%" text={translation.cancel} type="normal" stylingMode="text" className="btn-s-r" onClick={closePopup} />
                                </div>
                                <div className="dx-fieldset">
                                    <Button
                                        id="changePwdBtn"
                                        text={translation.confirm}
                                        width="100%"
                                        type="default"
                                        stylingMode="contained"
                                        className="btn-s-r"
                                        onClick={validateChangePassword}
                                        //onClick={nextPopup}
                                    />
                                </div>
                            </div>
                        </div>
                    </ValidationGroup>
                </form>
            </>
        );
    };

    //const allMenuRenderContent =
    //    <>
    //        <div className="menu-bar">
    //            <span></span>
    //            <span></span>
    //            <span></span>
    //        </div>
    //        <div className="all-menu">
    //            <ul>
    //                {props.allMenuList?.child?.map((menu, i) => (
    //                    <li key={i}>
    //                        <div className="service-title">
    //                            <h5>{menu.name}</h5>
    //                        </div>
    //                        <ul className="level1" level="1">
    //                            {menu.child.map((level2, j) => (
    //                                <li key={j} className={`level2-close ${level2.child.length > 0 ? "level2-open" : ""}`}>
    //                                    <p className="level1-menu" onClick={props.toggleMenuIcon}><i className="icon-folder"></i><span>{level2.name}</span></p>
    //                                    <ul className="level2" level="2">
    //                                        {level2.child.map((level3, k) => (
    //                                            <li key={k} level="3">
    //                                                <p className={`level3-menu ${level3.name === props.nowMenu.level3 ? "active" : ""}`}
    //                                                    link={level3.link}
    //                                                    onClick={e => props.clickMenu(e, level3)}>
    //                                                    <i className="material-icons">space_dashboard</i>
    //                                                    <span>{level3.name}</span>
    //                                                </p>
    //                                            </li>
    //                                        ))}
    //                                    </ul>
    //                                </li>
    //                            ))}
    //                        </ul>
    //                    </li>
    //                ))}
    //                <li>
    //                    {/*<button className="btn-add-service"><i className="material-icons">add_circle</i>ADD AI SERVICE</button>*/}
    //                </li>
    //            </ul>
    //        </div>
    //    </>

    //const dropdownMenuRenderContent =
    //    props.dropdownList?.map((dropdown, index) => (
    //        <div key={index} className="btn-dropdown" onClick={showDropdownMenu}>
    //            <span><span className="navProjectName">{dropdown.name}</span>
    //                {index !== 3 && (<i className="material-icons">arrow_drop_down</i>)}
    //            </span>
    //            {index !== 3 &&
    //                (<ul className="dropdown-list dropdownMenu" level={index + 1}>
    //                    {dropdown.child.map((sub, i) => (
    //                        <li key={i}
    //                            link={sub.link !== undefined ? sub.link : null}
    //                            onClick={e => props.clickMenu(e, sub)}>
    //                            <i className="material-icons">{index !== 2 ? "folder" : "space_dashboard"}</i><span>{sub.name}</span>
    //                        </li>
    //                    ))}
    //                </ul>)
    //            }
    //        </div>
    //    ))

    const profileDropdownRenderContent = (
        <div className="btn-dropdown" onClick={showDropdownMenu}>
            <i className="dx-icon-m-person" id="admin"></i>
            <Tooltip target="#admin" showEvent="dxhoverstart" hideEvent="dxhoverend" position="bottom">
                <div>admin</div>
            </Tooltip>
            <ul className="dropdown-list dropdownMenu">
                {(() => {
                    return addProfileSubMenu([translation.myPage, translation.changePassword, translation.logOut]);
                })()}
            </ul>
        </div>
    );

    const appsDropdownRenderContent = () => {
        let shortcutClass;
        if (shortcutMenu === null || shortcutMenu?.length === 0) shortcutClass = 'shortcut-block';
        else if (shortcutMenu.length > 0 && shortcutMenu.length <= 3) shortcutClass = 'shortcut-one';
        else if (shortcutMenu.length > 3 && shortcutMenu.length <= 6) shortcutClass = 'shortcut-two';
        else if (shortcutMenu.length > 6) shortcutClass = 'shortcut-three';

        return (
            <div
                className="group btn-dropdown"
                onClick={(e) => {
                    getShortcutMenu();
                    showDropdownMenu(e);
                }}
            >
                <i className="dx-icon-m-app"></i>
                <div
                    className="dropdown-apps dropdownMenu"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <div className={`favorite-app-wrapper ${shortcutClass}`}>
                        <ul className="favorite-app" level="3">
                            {shortcutMenu?.map((menu, i) => (
                                <li key={i} onClick={(e) => props.clickMenu(e, menu)}>
                                    <img src={menu.template_master_image_white ? menu.template_master_image_white : defaultTemplateIcon} />
                                    <span>{menu.menu_mapping_caption}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="basic-app-wrapper">
                        <ul className="basic-app">
                            {props.serviceMenu?.map((menu, i) => (
                                <li
                                    key={i}
                                    className={`btn-app-${menu.template_master_type}`}
                                    onClick={(e) => {
                                        moveService(menu);
                                    }}
                                >
                                    <div>
                                        <div></div>
                                    </div>
                                    <span>{menu.menu_mapping_caption}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="header dx-swatch-ix-pds">
            {/*<div className="menu">*/}
            {/*    <div className="view-all-menu" onClick={showAllMenu}>*/}
            {/*        {allMenuRenderContent}*/}
            {/*    </div>*/}
            {/*    <div className="company-logo" onClick={() => {*/}
            {/*        //removeServiceMappingInfo();*/}
            {/*        //removeMenuMappingInfo();*/}
            {/*        setMenuMappingInfo(JSON.stringify({ id: undefined, caption: translation.dashboard, path: "Dashboard" }));*/}
            {/*        //props.setChangeState(Date.now());*/}
            {/*        navigate('/Dashboard')*/}
            {/*    }}></div>*/}
            {/*    <div className="block"></div>*/}
            {/*    <div className="breadcrumb">*/}
            {/*        {dropdownMenuRenderContent}*/}
            {/*    </div>*/}
            {/*    <div className="block"></div>*/}
            {/*</div>*/}
            <div className="header-search">
                <i className="material-icons">search</i>
                <input type="text" placeholder="Search here" />
            </div>
            <div className="private">
                {profileDropdownRenderContent}
                {appsDropdownRenderContent()}

                <Popup width={360} height="auto" visible={isPopupVisible} hideOnOutsideClick={true} contentRender={changePasswordRenderContent} dragEnabled={true} position="center" showTitle={false} onHiding={closePopup}></Popup>
            </div>
        </div>
    );
}

export default Navigation;
