// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item-master .right-content-body {
    display: flex;
    height: calc(100% - 44px);
    min-height: calc(100% - 44px);
    align-items: flex-start;
}
.item-master .right-content-body .card {
    width: 324px;
    margin-right: 16px;
    height: 100%;
}
.item-master .right-content-body .card .card-body {
    padding: 12px 24px 12px 24px;
    height: calc(100% - 48px);
}
.item-master .right-content-body .select-category {
    padding: 24px;
    border-bottom: 1px solid var(--border);
}
.item-master .right-content-body .container {
    width: calc(100% - 340px);
}

`, "",{"version":3,"sources":["webpack://./src/contents/styles/item/item-master.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,6BAA6B;IAC7B,uBAAuB;AAC3B;AACA;IACI,YAAY;IACZ,kBAAkB;IAClB,YAAY;AAChB;AACA;IACI,4BAA4B;IAC5B,yBAAyB;AAC7B;AACA;IACI,aAAa;IACb,sCAAsC;AAC1C;AACA;IACI,yBAAyB;AAC7B","sourcesContent":[".item-master .right-content-body {\n    display: flex;\n    height: calc(100% - 44px);\n    min-height: calc(100% - 44px);\n    align-items: flex-start;\n}\n.item-master .right-content-body .card {\n    width: 324px;\n    margin-right: 16px;\n    height: 100%;\n}\n.item-master .right-content-body .card .card-body {\n    padding: 12px 24px 12px 24px;\n    height: calc(100% - 48px);\n}\n.item-master .right-content-body .select-category {\n    padding: 24px;\n    border-bottom: 1px solid var(--border);\n}\n.item-master .right-content-body .container {\n    width: calc(100% - 340px);\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
