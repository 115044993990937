﻿import React, { useEffect, useState, useRef } from 'react';
import Button from 'devextreme-react/button';
import { getLanguage, getUser, getIP, getMenuMappingInfo } from '../../apiInterface/utils/Common';
import { swal, useSwal } from '../../components/common/Swal';
import useTranslation from '../../components/customHooks/translations';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { useLocation, useNavigate } from 'react-router-dom';
import FileUploader from 'devextreme-react/file-uploader';
import { getEquipmentDDByCompanyId, getOperationFactory, insertEquipmentInfoByCompanyId, getMachineEquipmentInfoByMachineId, updateEquipmentInfoByCompanyId, uploadEquipmentImage, saveMoldInfo } from '../../apiInterface/machine/EquipmentAPI';

import { postAasxFileInfoAPI, uploadAasxFileAPI } from '../../apiInterface/aasFile/AASFileManagementAPI';
import { TextboxField, DatePickerField, FileField, DropdownField, BaseField } from '../../components/common/FormField';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import { actionDirect, moveFocus, submit } from '../../components/common/FormFuncWithEnter';
import EquipmentGrid from './EquipmentGrid';
import { PageTitle } from '../../components/common/PageTitle.jsx';
import { BASE_URL } from '../../config';

const prevPage = '/Equipment';
const EquipmentForm = () => {
    const translation = useTranslation();
    const [images, setImages] = useState([]);
    const [factoryList, setFactoryList] = useState([]);
    const [equipmentTypeList, setEquipmentTypeList] = useState([]);
    const [isReadOnly, setReadOnly] = useState(false);
    const aasRef = useRef();
    const getInstance = () => {
        return aasRef.current?.instance;
    };
    const errorHandler = useErrorHandler();
    const navigate = useNavigate();
    const { success, fail } = useSwal();
    const { state } = useLocation();
    const [data, setData] = useState({});
    const [moldDataSource, setMoldDataSource] = useState([]);
    const location = useLocation();
    const title = location.state.title;
    const language = getLanguage();
    const useItems = [
        { value: true, title: translation.use },
        { value: false, title: translation.notUse },
    ];

    useEffect(() => {
        const init = async () => {
            await getFactoryTypeDD();
            await getEquipmentTypeDD();
            if (state.title == 'Edit') {
                await getData();
                setReadOnly(true);
                valueChanged(true, 'is_assx_edit');
            } else {
                valueChanged(state.operation_factory_id, 'operation_factory_id');
                valueChanged(true, 'machine_equipment_is_active');
            }
        };
        init();
    }, []);

    const valueChanged = (value, key) => {
        setData((cur) => {
            let newData = { ...cur };
            newData[key] = value;
            return newData;
        });
    };
    const getFactoryTypeDD = async () => {
        const userInfo = getUser();
        const param = { user_company_id: userInfo.user_company_id };

        const res = await errorHandler(getOperationFactory, param);
        if (res) {
            setFactoryList(res.data.o_data);
            if (res.data.o_data.length > 0) {
                valueChanged(res.data.o_data[0].operation_factory_id, 'operation_factory_id');
            }
        }
    };

    const getEquipmentTypeDD = async () => {
        const userInfo = getUser();
        const lang = getLanguage();
        const queryData = {
            user_company_id: userInfo.user_company_id,
            sys_lang_code: lang,
        };

        const res = await errorHandler(getEquipmentDDByCompanyId, queryData);
        if (res) {
            setEquipmentTypeList(res.data.o_data);
            if (res.data.o_data.length > 0) {
                valueChanged(res.data.o_data[0].sys_division_sub_value, 'machine_equipment_type');
            }
        }
    };
    const exitForm = () => {
        navigate(prevPage, {
            state: {
                previousPage: location.pathname,
            },
        });
    };
    const getData = async () => {
        const queryData = {
            machine_equipment_id: state.rowData.machine_equipment_id,
            sys_lang_code: language,
        };
        const res = await errorHandler(getMachineEquipmentInfoByMachineId, queryData);
        if (res) {
            let temp = res.data.o_data[0];
            const imageUrls = [];
            if (temp.machine_equipment_attachments) {
                const imageJson = temp.machine_equipment_attachments;
                Object.keys(imageJson).forEach((key) => {
                    const url = imageJson[key];
                    imageUrls.push({
                        name: key,
                        data: url,
                    });
                });
            }
            imageUrls.map((img) => {
                img.data = `${BASE_URL}static_files/${img.data}`;
                return img;
            });
            setImages(imageUrls);
            setData(temp);
            setMoldDataSource(JSON.parse(temp.mold_str));
            valueChanged(state.rowData.machine_equipment_id, 'machine_equipment_id');
            if (temp.aasx_file_id) {
                const instance = getInstance();
                instance.option('value', [new File([temp.aasx_file_name], temp.aasx_file_name, { type: '', size: temp.aasx_file_size })]);
            }
        }
    };

    const onInsert = async () => {
        const uploadImages = await uploadImage();
        const mappingInfo = await getMenuMappingInfo();
        const userInfo = getUser();
        const ipAddress = getIP();
        const queryData = {
            operation_factory_id: data.operation_factory_id,
            user_company_id: userInfo.user_company_id,
            machine_equipment_type: data.machine_equipment_type,
            machine_equipment_code: data.machine_equipment_code,
            machine_equipment_comment: data.machine_equipment_comment,
            machine_equipment_name: data.machine_equipment_name,
            machine_equipment_made: data.machine_equipment_made,
            machine_equipment_supplier: data.machine_equipment_supplier,
            machine_equipment_modelno: data.machine_equipment_modelno,
            machine_equipment_date: data.machine_equipment_date,
            machine_equipment_is_active: data.machine_equipment_is_active,
            machine_equipment_attachments: uploadImages,
            user_master_email: userInfo.user_master_email,
            machine_equipment_modified_by: userInfo.user_master_id,
            user_log_ip: ipAddress,
            user_master_id: userInfo.user_master_id,
            user_menu_mapping_id: mappingInfo.id,
        };
        const res = await errorHandler(insertEquipmentInfoByCompanyId, queryData);
        if (res) {
            await insertMold(res.data.o_data[0].v_machine_equipment_id);
            const aasx = await uploadAASX();
            const result = insertAASXInfo(aasx, res.data.o_data[0].v_machine_equipment_id);
            var swalResFn = (response) => {
                navigate(prevPage);
            };
            if (result) success(`<p>${translation.save_successfully}</p>`, swalResFn);
            // else fail(`<p>${translation.save_failed}</p>`, swalResFn)
            else {
                console.log(`${translation.save_failed}`);
                navigate(prevPage);
            }
        }
    };

    const insertMold = async (equipId) => {
        moldDataSource?.forEach(async (arr, idx) => {
            const param = {
                machine_equipment_id: equipId,
                machine_mold_id: arr.machine_mold_id,
            };
            const res = await errorHandler(saveMoldInfo, param);
            return res;
        });
    };

    const uploadAASX = async () => {
        if (data?.aasx_file == null || data?.aasx_file == undefined) {
            return null;
        }
        const body = new FormData();
        for (let i = 0; i < data.aasx_file.length; i++) {
            body.append('aasx_file', data.aasx_file[i]);
        }

        const res = await errorHandler(uploadAasxFileAPI, body);
        if (res) {
            return res.data.o_data;
        }
        return null;
    };
    const insertAASXInfo = async (aasx, equipId) => {
        if (aasx) {
            const user = getUser();
            const ipAddress = getIP();
            const mappingInfo = await getMenuMappingInfo();
            const param = {
                user_master_id: user.user_master_id,
                user_log_ip: ipAddress,
                user_company_id: user.user_company_id,
                menu_mapping_id: mappingInfo.id,
                aasx_file_name: aasx?.file_name,
                aasx_file_path: aasx?.db_url,
                aasx_file_size: aasx?.file_size,
                aasx_file_package_name: data?.machine_equipment_name,
                machine_equipment_id: equipId,
                aasx_file_is_active: true,
            };
            const res = await errorHandler(postAasxFileInfoAPI, param);
            return res;
        } else return true;
    };

    const onFileLoaded = (e) => {
        valueChanged(e.value, 'aasx_file');
    };
    const onUpdate = async () => {
        const uploadImages = await uploadImage();
        const mappingInfo = await getMenuMappingInfo();
        const language = getLanguage();
        const userInfo = getUser();
        const ipAddress = getIP();
        const queryData = {
            user_master_id: userInfo.user_master_id,
            user_company_id: userInfo.user_company_id,
            machine_equipment_id: data.machine_equipment_id,
            operation_factory_id: data.operation_factory_id,
            machine_equipment_type: data.machine_equipment_type,
            machine_equipment_code: data.machine_equipment_code,
            machine_equipment_name: data.machine_equipment_name,
            machine_equipment_made: data.machine_equipment_made,
            machine_equipment_supplier: data.machine_equipment_supplier,
            machine_equipment_modelno: data.machine_equipment_modelno,
            machine_equipment_date: data.machine_equipment_date,
            machine_equipment_is_active: data.machine_equipment_is_active,
            sys_lang_code: language,
            machine_equipment_attachments: uploadImages,
            machine_equipment_comment: data.machine_equipment_comment,
            user_master_email: userInfo.user_master_email,
            user_log_ip: ipAddress,
            user_menu_mapping_id: mappingInfo.id,
        };

        const res = await errorHandler(updateEquipmentInfoByCompanyId, queryData);
        if (res) {
            insertMold(data.machine_equipment_id);
            var swalResFn = (response) => {
                navigate(prevPage);
            };
            success(`<p>${translation.save_successfully}</p>`, swalResFn);
        }
    };

    //uploading equipment images
    const uploadImage = async () => {
        let bodyFormData = new FormData();
        let imageJson = {};
        let isEmpty = true;
        for (let i = 0; i < images.length; i++) {
            if (images[i].file) {
                bodyFormData.append('files', images[i].file);
                isEmpty = false;
            } else {
                imageJson[images[i].name] = images[i].data;
            }
        }

        const userInfo = getUser();
        const data = { user_id: userInfo.user_master_id };
        if (!isEmpty) {
            const res = await errorHandler(uploadEquipmentImage, data, bodyFormData);
            if (res) {
                for (let i = 0; i < res.file_name.length; i++) {
                    imageJson[res.file_name[i]] = res.db_url[i];
                }
            }
        }

        const imageString = JSON.stringify(imageJson);
        return imageString;
    };

    const changedMold = (moldData) => {
        setMoldDataSource(moldData);
    };

    return (
        <>
            <div className="equipment-form">
                <div className="right-content-title">
                    <PageTitle pageState={title} />
                </div>
                <form onSubmit={actionDirect}>
                    <div className="right-content-body">
                        <div className="container">
                            <div className="dx-fieldset">
                                <DropdownField
                                    label={translation.operation_factory_name}
                                    dataSource={factoryList}
                                    value={data?.operation_factory_id}
                                    valueExpr="operation_factory_id"
                                    displayExpr="operation_factory_name"
                                    onValueChanged={(e) => valueChanged(e.value, 'operation_factory_id')}
                                    onEnterKey={moveFocus}
                                    inputAttr={{ focusidx: 0 }}
                                    isEssential={true}
                                    esstialMsg={translation.operation_factory_name + translation.is_required}
                                />
                                <DropdownField
                                    label={translation.machine_equipment_type}
                                    dataSource={equipmentTypeList}
                                    value={data?.machine_equipment_type}
                                    valueExpr="sys_division_sub_value"
                                    displayExpr="sys_division_sub_caption_text"
                                    onValueChanged={(e) => valueChanged(e.value, 'machine_equipment_type')}
                                    onEnterKey={moveFocus}
                                    inputAttr={{ focusidx: 1 }}
                                    isEssential={true}
                                    esstialMsg={translation.machine_equipment_type + translation.is_required}
                                />
                                <TextboxField
                                    label={translation.machine_equipment_code}
                                    value={data?.machine_equipment_code}
                                    onValueChanged={(e) => valueChanged(e.value, 'machine_equipment_code')}
                                    isEssential={true}
                                    esstialMsg={translation.machine_equipment_code + translation.is_required}
                                    onEnterKey={moveFocus}
                                    inputAttr={{ focusidx: 2 }}
                                    readOnly={isReadOnly}
                                />

                                <TextboxField
                                    label={translation.machine_equipment_name}
                                    value={data?.machine_equipment_name}
                                    onValueChanged={(e) => valueChanged(e.value, 'machine_equipment_name')}
                                    isEssential={true}
                                    esstialMsg={translation.machine_equipment_name + translation.is_required}
                                    onEnterKey={moveFocus}
                                    inputAttr={{ focusidx: 3 }}
                                />

                                <TextboxField label={translation.machine_equipment_made} value={data?.machine_equipment_made} onValueChanged={(e) => valueChanged(e.value, 'machine_equipment_made')} isEssential={false} onEnterKey={moveFocus} inputAttr={{ focusidx: 4 }} />

                                <TextboxField label={translation.machine_equipment_supplier} value={data?.machine_equipment_supplier} onValueChanged={(e) => valueChanged(e.value, 'machine_equipment_supplier')} isEssential={false} onEnterKey={moveFocus} inputAttr={{ focusidx: 5 }} />
                                <TextboxField label={translation.machine_equipment_modelno} value={data?.machine_equipment_modelno} onValueChanged={(e) => valueChanged(e.value, 'machine_equipment_modelno')} isEssential={false} onEnterKey={moveFocus} inputAttr={{ focusidx: 6 }} />
                                <DatePickerField
                                    isEssential={false}
                                    essentialMsg={translation.machine_equipment_date + translation.is_required}
                                    label={translation.machine_equipment_date}
                                    value={data?.machine_equipment_date}
                                    onValueChanged={(_, val) => {
                                        valueChanged(val, 'machine_equipment_date');
                                    }}
                                    valueFormat="MM/dd/yyyy"
                                    pickerType="calendar"
                                    valueType="date"
                                    useMask={true}
                                    inputAttr={{ focusidx: 7 }}
                                />

                                <DropdownField
                                    label={translation.use_or_not}
                                    isEssential={true}
                                    esstialMsg={translation.use_or_not + translation.is_required}
                                    dataSource={useItems}
                                    value={data?.machine_equipment_is_active}
                                    valueExpr="value"
                                    displayExpr="title"
                                    onValueChanged={(e) => valueChanged(e.value, 'machine_equipment_is_active')}
                                    onEnterKey={moveFocus}
                                    inputAttr={{ focusidx: 8 }}
                                    isSearchable={false}
                                />
                                <TextboxField label={translation.machine_equipment_comment} value={data?.machine_equipment_comment} onValueChanged={(e) => valueChanged(e.value, 'machine_equipment_comment')} isEssential={false} onEnterKey={moveFocus} inputAttr={{ focusidx: 9 }} />
                            </div>
                            <div className="block"></div>
                            <EquipmentGrid width={880} onDataChange={changedMold} dataSource={moldDataSource} setDataSource={setMoldDataSource} />
                            <div className="block"></div>
                            <div className="dx-fieldset">
                                <FileField isColumn={true} label={translation.machine_equipment_images} imageData={images} setImageData={setImages} />
                                <BaseField label="Uploading AASX File" isColumn={true} isEssential={false} component={<FileUploader accept=".aasx" width={588} className="fileuploader-file" uploadMode="useForm" onValueChanged={onFileLoaded} disabled={data?.is_assx_edit} multiple={false} ref={aasRef}></FileUploader>} />
                            </div>

                            <div className="block"></div>
                            {location.state.title == 'Edit' && (
                                <>
                                    <div className="dx-fieldset write-info">
                                        <div className="dx-field">
                                            <div className="dx-field-label">{translation.lastModifiedDate}</div>
                                            <div className="dx-field-value">{data?.modified_date}</div>
                                        </div>
                                        <div className="dx-field">
                                            <div className="dx-field-label">{translation.lastModifiedBy}</div>
                                            <div className="dx-field-value">
                                                {data?.modified_by_name}
                                                {data?.modified_by_duty && <span className="badge badge-job-position">{data?.modified_by_duty}</span>}
                                                {data?.modified_by_department && <span className="badge badge-team">{data?.modified_by_department}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="block"></div>
                                </>
                            )}
                            <div className="btn-group dx-fieldset" style={{ width: '920px' }}>
                                <Button type="normal" stylingMode="text" className="btn-s-r" onClick={exitForm}>
                                    <div>
                                        <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                        <span className="dx-button-text">{translation.cancel}</span>
                                    </div>
                                </Button>
                                <Button
                                    type="default"
                                    stylingMode="contained"
                                    className="btn-s-r"
                                    useSubmitBehavior={true}
                                    onClick={(e) => {
                                        if (state.title === 'Add') submit(e, onInsert);
                                        else if (state.title === 'Edit') submit(e, onUpdate);
                                    }}
                                >
                                    <div>
                                        <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                        <span className="dx-button-text">{translation.save}</span>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default EquipmentForm;
