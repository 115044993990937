import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useTranslation from '../../components/customHooks/translations';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import { getLanguage, getUser, getMenuMappingInfo, getIP } from '../../apiInterface/utils/Common';
import { PageTitle } from '../../components/common/PageTitle.jsx';
import { Button, Popup, ScrollView } from 'devextreme-react';
import { SearchableField, TextboxField, DropdownField, FilterComponent, FileField } from '../../components/common/FormField';
import TextBox from 'devextreme-react/text-box';
import TextArea from 'devextreme-react/text-area';
import LastModifiedIndicator from '../../components/common/LastModifiedIndicator';
import '../../contents/styles/badtype/inspection.css';
import { swal, useSwal } from '../../components/common/Swal';
import Badge from '../../components/common/Badge';
import { actionDirect, moveFocus, submit } from '../../components/common/FormFuncWithEnter';
import IconCalDelete from '../../contents/image/icon-cal-delete.svg';
import { getOneInspectionLibrary, getSysDivisionCaptionByCode, insertInspectionLibraryDefectType, insertInspectionLibraryCondition, updateInspectionLibraryDefectType, deleteInspectionLibraryDefectType } from '../../apiInterface/inspection/InspectionLibraryCondition';
import { insertInspectionLibraryCopy } from '../../apiInterface/inspection/InspectionStandardLibraryAPI';
import SelectLibraryPopup from './popup/SelectLibraryPopup';
import DefectTypePopup from './popup/DefectTypePopup';
import MeasureConditionPopup from './popup/MeasureConditionPopup';

import TreeCardWithLine from '../../components/common/TreeCardWithLine';
import { SecondNode, ThirdNode } from '../../components/common/TreeCardWithLine';

const DIVISION_CODE = 'INSPECTIONTYPE';
const previousPage = '/DefectTypeStandard';
export default function DefectTypeStandardForm(props) {
    const navigate = useNavigate();
    const translation = useTranslation();
    const location = useLocation();
    const errorHandler = useErrorHandler();
    const user = getUser();
    const ipAddress = getIP();
    const lang = getLanguage();
    const { success } = useSwal();
    const { state } = useLocation();
    const { fail } = useSwal();

    const [data, setData] = useState({
        inspection_library_defect_type_is_active: true,
        inspection_library_is_active: true,
    });
    const [dataSource, setDataSource] = useState([]);

    const [isAddStatus, setAddStatus] = useState(false);
    const [isEditStatus, setEditStatus] = useState(false);

    const [isLibraryPopupVisibility, setLibraryPopupVisibility] = useState(false);
    const [isDefectTypePopupVisibility, setDefectTypePopupVisibility] = useState(false);
    const [isMeasureConPopupVisibility, setMeasureConPopupVisibility] = useState(false);

    const [addDataSource, setAddDataSource] = useState([]); // inspection_library_defect_type_json
    const [showMeasure, setShowMeasure] = useState([]); // inspection_library_defect_type_formula
    const [checkedItems, setCheckedItems] = useState([]); //
    const [checkedItem, setCheckedItem] = useState();
    const [checkedSecondItems, setCheckedSecondItems] = useState([]);

    const [isLoad, setIsLoad] = useState(false);
    const [isConditionAdd, setIsConditionAdd] = useState(false);

    // setting
    const useItems = [
        { value: true, title: translation.use },
        { value: false, title: translation.notUse },
    ];

    // reset
    const reset = () => {
        // defect_type_name validation ignore

        delete data.defect_type_id;
        delete data.defect_type_code;
        delete data.defect_type_name;
        setShowMeasure([]);
        setAddDataSource([]);
        setCheckedItems([]);
    };

    // set edit info
    const setEditInfo = () => {
        dataSource.map((m) => {
            if (m.defect_type_id === Number(checkedSecondItems[0])) {
                let newObj = {
                    defect_type_id: m.defect_type_id,
                    defect_type_code: m.defect_type_code,
                    defect_type_name: m.defect_type_name,
                    inspection_library_defect_type_is_active: true,
                };
                setData({ ...data, ...newObj });

                if (m.inspection_library_defect_type_formula !== undefined && m.inspection_library_defect_type_formula !== null) {
                    setShowMeasure(JSON.parse(m.inspection_library_defect_type_formula));
                } else {
                    setShowMeasure([]);
                }

                if (m.inspection_library_defect_type_json !== undefined && m.inspection_library_defect_type_json !== null) {
                    setAddDataSource(JSON.parse(m.inspection_library_defect_type_json));
                } else {
                    setAddDataSource([]);
                }
            }
        });
    };

    // add condition
    const [count, setCount] = useState(0);
    const [disabled, setDisabled] = useState('disabled');
    const [logicalDisabled, setLogicalDisabled] = useState('disabled');
    const onClickMeasure = (e) => {
        let target = e.target.id === '' ? e.target.parentElement.id : e.target.id;

        let copy = [];
        let cnt = Number(count);
        if (target === 'CE') {
            // 전체 삭제
            copy = [];

            cnt = 0;
            setCount(cnt);
            changeDisabled(cnt);

            changeClassDisabled('remove');
        } else if (target === 'DEL') {
            // 하나 삭제
            copy = [...showMeasure];
            copy.pop();

            let cnt = bracketCheck(copy);
            setCount(cnt);
            changeDisabled(cnt);

            let lastStr = showMeasure[showMeasure.length - 1];
            if (lastStr !== 'OR' && lastStr !== 'AND' && lastStr !== '(' && lastStr !== ')') changeClassDisabled('remove');
            else changeClassDisabled('add');
        } else {
            // 추가
            copy = [...showMeasure, target];

            let cnt = bracketCheck(copy);
            setCount(cnt);

            if (cnt === -1) copy.pop();
            else changeDisabled(cnt);

            if (target === 'OR' || target === 'AND') changeClassDisabled('remove');
        }

        setShowMeasure(copy);
    };

    // change disabled
    const changeDisabled = (cnt) => {
        cnt === 0 ? setDisabled('disabled') : setDisabled('');
    };

    // change class
    const changeClassDisabled = (state) => {
        let str = state === 'remove' ? 'disabled' : '';
        setLogicalDisabled(str);

        let len = document.getElementsByClassName('condition-list')[0].childNodes[0].childNodes.length;
        for (let i = 0; i < len; i++) {
            document.getElementsByClassName('condition-list')[0].childNodes[0].childNodes[i].classList[state]('disabled');
        }
    };

    // edit condition
    const onEditCondition = () => {
        addDataSource.map((m) => {
            if (m.id === checkedItems[0]) {
                setCheckedItem(m);
                setMeasureConPopupVisibility(true);
                setIsConditionAdd(false);
            }
        });
    };

    // delete condition
    const onDelCondition = () => {
        let copy = addDataSource.filter((item) => {
            return !checkedItems.includes(item.id);
        });

        setAddDataSource(copy);
        // reset
        setCheckedItems([]);
    };

    // save defect row
    const onSaveDefectRow = (state) => {
        // validation
        let elem = document.getElementsByClassName('measure-formula-field');
        if (data?.defect_type_code === undefined || addDataSource.length === 0 || showMeasure.length === 0 || count !== 0) {
            if (data?.defect_type_code === undefined) {
                let defect = document.getElementsByName('defect_type_name');
                defect[0].parentElement.parentElement.parentElement.classList.add('dx-invalid');
                return;
            }
            if (addDataSource.length === 0) {
                fail(`<p>${translation.plz_data_setting}</p>`);
                return;
            }
            if (showMeasure.length === 0) {
                elem[0].style.border = '1px solid red';
                return;
            }

            return;
        } else {
            if (state !== 'edit') {
                elem[0].style.border = '1px solid #D4D4D4';
            }
        }

        if (state === 'add') {
            let addObj = {
                inspection_library_defect_type_formula: JSON.stringify(showMeasure),
                inspection_library_defect_type_json: JSON.stringify(addDataSource),
            };

            // compare
            let unique = dataSource.some((id) => id.defect_type_id === data.defect_type_id);
            if (unique) {
                fail(`<p>duplication defect_type_id</p>`);
                return;
            } else {
                let combine = [...dataSource, { ...data, ...addObj }];
                setDataSource(combine);
                setAddStatus(false);
            }

            // let combine = [...dataSource, { ...data, ...addObj }];
            // setDataSource(combine);
            // setAddStatus(false);
        } else {
            dataSource.map((m) => {
                if (m.defect_type_id === Number(checkedSecondItems[0])) {
                    m.inspection_library_defect_type_formula = JSON.stringify(showMeasure);
                    m.inspection_library_defect_type_json = JSON.stringify(addDataSource);
                }
            });

            setEditStatus(false);
            setCheckedSecondItems([]);
        }
    };

    // delete defect row
    const onDeleteSecond = () => {
        let copy = dataSource.filter((item) => {
            return !checkedSecondItems.includes(item.defect_type_id.toString());
        });
        setDataSource(copy);

        setCheckedSecondItems([]);
    };

    // save
    const onSave = async (e) => {
        if (state.title !== 'Copy') {
            if (data?.inspection_library_code === null || data?.inspection_library_code === undefined) {
                const elem = document.querySelectorAll('input.dx-texteditor-input');
                elem[1].parentElement.parentElement.parentElement.classList.add('dx-invalid');
                return;
            }

            if (dataSource.length === 0) {
                fail(`<p>${translation.plz_data_setting}</p>`);
                return;
            }
        }

        const { id: mapping_id } = await getMenuMappingInfo();
        const commonParam = {
            user_master_email: user.user_master_email,
            user_log_ip: ipAddress,
            user_master_id: user.user_master_id,
            user_menu_mapping_id: mapping_id,
            user_company_id: user.user_company_id,
        };

        // edit --> delete
        if (state.title === 'Edit') {
            const delParam = {
                user_master_id: user.user_master_id,
                user_log_ip: ipAddress,
                menu_mapping_id: mapping_id,
                user_company_id: user.user_company_id,
                inspection_library_id: state.id,
            };

            const res = await errorHandler(deleteInspectionLibraryDefectType, delParam);
        }

        if (state.title === 'Copy') {
            const copyParam = {
                ...commonParam,
                inspection_library_copied_id: data.inspection_library_copied_id,
                inspection_library_code: data.inspection_library_code,
                // inspection_library_name: data.inspection_library_name,
                inspection_library_name: data.inspection_library_name === undefined ? sessionStorage.getItem('inspectionName') : data.inspection_library_name,
                inspection_library_is_active: data.inspection_library_is_active,
                inspection_library_comment: data.inspection_library_comment,
            };

            const response = await errorHandler(insertInspectionLibraryCopy, copyParam);
            if (response) {
                let text = `<p>${translation.success_insert}</p>`;
                success(text, exitForm);
            }
        } else {
            let result = [];

            dataSource.length > 0 &&
                dataSource.map(async (m) => {
                    let resObj = {
                        defect_type_id: m.defect_type_id,
                        inspection_library_id: data?.inspection_library_id !== undefined ? data?.inspection_library_id : Number(state.id),
                        inspection_library_defect_type_id: 0,
                        inspection_library_defect_type_formula: m.inspection_library_defect_type_formula,
                        inspection_library_defect_type_is_active: m.inspection_library_defect_type_is_active,
                        inspection_library_defect_type_comment: m.inspection_library_defect_type_comment === undefined ? '' : m.inspection_library_defect_type_comment,
                    };
                    const queryData = { ...commonParam, ...resObj };

                    // let url =
                    //     state.title === 'Edit'
                    //         ? updateInspectionLibraryDefectType
                    //         : insertInspectionLibraryDefectType;
                    const res = await errorHandler(insertInspectionLibraryDefectType, queryData);
                    if (res) {
                        if (res.data.o_data.length !== 0) {
                            let id = {
                                inspection_library_defect_type_id: res.data.o_data[0].inspection_library_defect_type_id,
                            };

                            if (m.inspection_library_defect_type_json !== null && m.inspection_library_defect_type_json !== undefined) {
                                const response = JSON.parse(m.inspection_library_defect_type_json).map(async (k) => {
                                    let param = { ...k, ...id };
                                    const res2 = await errorHandler(insertInspectionLibraryCondition, param);

                                    result.push(res2.message === 'MSG_100' ? true : false);
                                });
                            }
                        }
                    }
                });

            if (result.every((val) => val === true)) {
                // 닫기
                // exitForm();
                navigate(previousPage, {
                    state: {
                        previousPage: location.pathname,
                        title: 'Save',
                    },
                });
            }
        }
    };

    // data value change
    const valueChanged = (value, key) => {
        if (!isAddStatus && !isEditStatus && key === 'inspection_library_name') {
            // validation
            const elem = document.getElementsByName(key);
            if (elem.length !== 0) {
                elem[0].parentElement.parentElement.parentElement.classList.remove('dx-invalid');
            }

            sessionStorage.setItem('inspectionName', value);
            return;
        }

        const element = document.getElementsByName(key);
        if (element.length !== 0) {
            element[0].parentElement.parentElement.parentElement.classList.remove('dx-invalid');
        }

        setData((cur) => {
            let newData = { ...cur };
            newData[key] = value;
            return newData;
        });
    };

    // exit
    const exitForm = () => {
        navigate(previousPage, {
            state: { previousPage: location.pathname },
        });
    };

    // get one inspection info
    const getOneInspectionInfo = async () => {
        const param = {
            inspection_library_id: state.id,
            sys_lang_code: lang,
        };

        const res = await errorHandler(getOneInspectionLibrary, param);
        if (res) {
            let data = res.data.o_data;
            if (data.length !== 0) {
                let copyParam = {};
                if (state.title === 'Copy') {
                    copyParam = {
                        // ...data[0]
                        inspection_library_copied_id: data[0].inspection_library_id,
                        inspection_library_copied_code: data[0].inspection_library_code,
                        inspection_library_copied_name: data[0].inspection_library_name,
                        inspection_type_name: data[0].inspection_type_name,
                        inspection_library_is_active: true,
                        inspection_library_comment: '',
                    };
                } else {
                    copyParam = {
                        inspection_library_id: data[0].inspection_library_id,
                        inspection_library_code: data[0].inspection_library_code,
                        inspection_library_name: data[0].inspection_library_name,
                        inspection_type_name: data[0].inspection_type_name,
                    };
                }
                setData(copyParam);

                if (data[0].inspection_library_defect_type_json !== undefined && data[0].inspection_library_defect_type_json !== null) {
                    let defectTypeJson = JSON.parse(data[0].inspection_library_defect_type_json);

                    let copy = [],
                        copyJson = [];
                    defectTypeJson.map((m) => {
                        let arr = [];
                        if (m.inspection_library_defect_type_json !== null) {
                            JSON.parse(m.inspection_library_defect_type_json).map((k) => {
                                let obj = { ...k, id: `${k.inspection_library_condition}_${k.inspection_library_condition_value}` };
                                copyJson.push(obj);

                                arr.push(obj);
                            });
                            m.inspection_library_defect_type_json = JSON.stringify(arr);
                        }
                        copy.push(m);
                    });
                    setDataSource(copy);
                }
            }
        }
    };

    // init
    useEffect(() => {
        if (state.title !== 'Add' && state?.id !== undefined) {
            getOneInspectionInfo();
        }
    }, []);

    return (
        <div className="defect-type-standard-form">
            <div className="right-content-title">
                {/*<PageTitle pageState={state.title} />*/}
                <PageTitle />
            </div>

            <form name="form">
                <div className="right-content-body">
                    <div className="container not-p">
                        <div className="dx-fieldset">
                            {!isAddStatus && !isEditStatus ? (
                                <>
                                    {state.title === 'Copy' ? (
                                        <>
                                            <SearchableField label={translation.inspection_library_copied_code} text={data?.inspection_library_copied_code} isEssential={true} hasTextboxPopup={true} onSearch={() => setLibraryPopupVisibility(true)} isWritable={false} placeHolder="" />
                                            <TextboxField label={translation.inspection_library_copied_name} value={data?.inspection_library_copied_name} isEssential={true} readOnly={true} />
                                            <TextboxField label={translation.inspection_library_code} value={data?.inspection_library_code} onValueChanged={(e) => valueChanged(e.value, 'inspection_library_code')} readOnly={state?.title == 'Edit' ? true : false} isEssential={true} />
                                        </>
                                    ) : (
                                        <SearchableField
                                            label={translation.inspection_library_code}
                                            isEssential={true}
                                            disabled={true}
                                            text={data?.inspection_library_code}
                                            onSearch={() => {
                                                setLibraryPopupVisibility(true);
                                            }}
                                            hasTextboxPopup={true}
                                            isWritable={false}
                                        />
                                    )}
                                    <TextboxField label={translation.inspection_library_name} isEssential={true} readOnly={state.title === 'Copy' ? false : true} value={data?.inspection_library_name} onValueChanged={(e) => valueChanged(e.value, 'inspection_library_name')} name="inspection_library_name" />
                                </>
                            ) : (
                                <>
                                    <SearchableField label={translation.defect_type_code} isEssential={true} disabled={true} text={data?.defect_type_code} onSearch={() => setDefectTypePopupVisibility(true)} />
                                    <TextboxField label={translation.defect_type_name} isEssential={true} readOnly={true} value={data?.defect_type_name} name="defect_type_name" onValueChanged={(e) => valueChanged(e.value, 'defect_type_name')} />
                                </>
                            )}

                            {state.title === 'Copy' ? (
                                <>
                                    <DropdownField label={translation.inspection_library_is_active} value={data?.inspection_library_is_active} dataSource={useItems} onValueChanged={(e) => valueChanged(e.value, 'inspection_library_is_active')} valueExpr="value" displayExpr="title" isEssential={true} />
                                    <TextboxField label={translation.inspection_library_comment} onValueChanged={(e) => valueChanged(e.value, 'inspection_library_comment')} value={data?.inspection_library_comment} />
                                </>
                            ) : (
                                <TextboxField label={translation.inspection_type_name} readOnly={true} value={data?.inspection_type_name} defaultValue={data?.inspection_type_name} onValueChanged={(e) => valueChanged(e.value, 'inspection_type_name')} />
                            )}
                        </div>

                        {state.title !== 'Copy' && (
                            <div className="">
                                <div className="toolbar">
                                    <div>
                                        {!isAddStatus && !isEditStatus && (
                                            <>
                                                <Button className="btn-s-r btn-hover-red" type="normal" stylingMode="text" icon="trash" onClick={() => onDeleteSecond()} />
                                                <Button className="btn-s-r" type="normal" stylingMode="contained" text="edit" icon="edit" onClick={() => [setEditStatus(true), setEditInfo()]} disabled={checkedSecondItems !== null && checkedSecondItems.length === 1 ? false : true} />
                                                <Button className="btn-s-r" type="default" stylingMode="contained" text="add" icon="add" onClick={() => [setAddStatus(true), reset(), setCheckedSecondItems([])]} />
                                                <Button className="btn-s-r" type="default" stylingMode="contained" text="add defect type" icon="add" onClick={() => [setIsLoad(true), setDefectTypePopupVisibility(true)]} />
                                                <Button className="btn-s-r" type="default" stylingMode="contained" text="add inspection library" icon="add" onClick={() => [setIsLoad(true), setLibraryPopupVisibility(true)]} />
                                            </>
                                        )}
                                        {(isAddStatus || isEditStatus) && (
                                            <>
                                                <Button className="btn-s-r btn-hover-red" type="normal" stylingMode="text" icon="trash" onClick={() => onDelCondition()} />
                                                <Button className="btn-s-r" type="normal" stylingMode="contained" text={translation.edit} icon="edit" onClick={() => onEditCondition()} disabled={checkedItems !== null && checkedItems.length === 1 ? false : true} />
                                                <Button className="btn-s-r dx-button-mode-es" type="default" stylingMode="contained" text={translation.add_measure_condition} icon="add" onClick={() => [setMeasureConPopupVisibility(true), setIsConditionAdd(true)]} />
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="bg-gray">
                                    {!isAddStatus && !isEditStatus && (
                                        <>
                                            {/* <TreeCardWithLine_new
                                            isStartSecond={true}
                                        /> */}
                                            {dataSource !== undefined && dataSource.length !== 0 ? (
                                                dataSource.map((m) => {
                                                    return (
                                                        <div className="ix-treelist2 start-second">
                                                            <SecondNode isStartSecond={true} data={m} getChecked={checkedSecondItems} setChecked={setCheckedSecondItems} />
                                                        </div>
                                                    );
                                                })
                                            ) : (
                                                <div className="no-data-s">
                                                    <div className="no-data-img"></div>
                                                    <h2>{translation.no_data}</h2>
                                                    <p>{translation.no_data_comment}</p>
                                                </div>
                                            )}

                                            {/*If the library code is not selected / No data*/}
                                            {/*Buttons in the toolbar are disabled*/}
                                            {/*And we will show .no-data-s below*/}

                                            {/* <div className="no-data-s">
                                            <div className="no-data-img"></div>
                                            <h2>{translation.no_data}</h2>
                                            <p>{translation.no_data_comment}</p>
                                        </div> */}
                                        </>
                                    )}

                                    {isAddStatus && (
                                        <>
                                            {addDataSource !== null &&
                                                addDataSource.length !== 0 &&
                                                addDataSource.map((m) => {
                                                    return (
                                                        <div className="ix-treelist2 start-third">
                                                            <ThirdNode data={m} isStartThird={true} getDatas={addDataSource} setDatas={setAddDataSource} getChecked={checkedItems} setChecked={setCheckedItems} />
                                                        </div>
                                                    );
                                                })}

                                            {addDataSource === null ||
                                                (addDataSource.length === 0 && (
                                                    <div className="no-data-s">
                                                        <h2>{translation.no_measure_condition}</h2>
                                                        <p>{translation.no_measure_condition_comment}</p>
                                                    </div>
                                                ))}
                                        </>
                                    )}

                                    {isEditStatus &&
                                        addDataSource !== null &&
                                        addDataSource.length !== 0 &&
                                        addDataSource.map((m) => {
                                            return (
                                                <div className="ix-treelist2 start-third">
                                                    <ThirdNode data={m} isStartThird={true} getDatas={addDataSource} setDatas={setAddDataSource} getChecked={checkedItems} setChecked={setCheckedItems} />
                                                </div>
                                            );
                                        })}
                                </div>
                                {(isAddStatus || isEditStatus) && (
                                    <>
                                        <div className="dx-fieldset">
                                            <div className="dx-field column">
                                                <div className="dx-field-label">
                                                    <p className="essential">{translation.inspection_library_defect_type_formula}</p>
                                                </div>
                                                <div className="dx-field-value">
                                                    <div className="measure-formula-field">
                                                        <div className="equal"></div>
                                                        <ul>
                                                            {showMeasure !== undefined &&
                                                                showMeasure !== null &&
                                                                showMeasure.length !== 0 &&
                                                                showMeasure.map((m) => {
                                                                    return <li className={m === 'OR' || m === 'AND' ? 'operator' : ''}>{m}</li>;
                                                                })}
                                                            {/* <ShowMeasureFormula
                                                            data={showMeasure}
                                                            // setData={setShowMeasure}
                                                        /> */}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="calculate-buttons">
                                                <div className="dx-field column">
                                                    <div className="dx-field-label">
                                                        <p>Condition</p>
                                                    </div>
                                                    <div className="dx-field-value">
                                                        <ul class="condition-list">
                                                            <AddCondition data={addDataSource} getData={showMeasure} setData={setShowMeasure} setDisabled={setLogicalDisabled} />
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="dx-field column">
                                                    <div className="dx-field-label">
                                                        <p>Measure</p>
                                                    </div>
                                                    <div className="dx-field-value">
                                                        <ul className="measure-list">
                                                            <li id={`CE`} onClick={(e) => onClickMeasure(e)}>
                                                                CE
                                                            </li>
                                                            <li id={`DEL`} onClick={(e) => onClickMeasure(e)}>
                                                                <img src={IconCalDelete} width="24" height="16"></img>
                                                            </li>
                                                            <li id={`OR`} onClick={(e) => onClickMeasure(e)} className={logicalDisabled}>
                                                                OR
                                                            </li>
                                                            <li id={`AND`} onClick={(e) => onClickMeasure(e)} className={logicalDisabled}>
                                                                AND
                                                            </li>
                                                            <li id={`(`} onClick={(e) => onClickMeasure(e)}>
                                                                (<span class="num">{count === 0 || count === -1 ? '' : count}</span>
                                                            </li>
                                                            <li id={`)`} onClick={(e) => onClickMeasure(e)} className={disabled}>
                                                                )
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dx-fieldset">
                                            <DropdownField
                                                label={translation.inspection_library_defect_type_is_active}
                                                dataSource={useItems}
                                                value={data?.inspection_library_defect_type_is_active}
                                                onValueChanged={(e) => valueChanged(e.value, 'inspection_library_defect_type_is_active')}
                                                valueExpr="value"
                                                displayExpr="title"
                                                isEssential={true}
                                            />
                                            <TextboxField label={translation.inspection_library_defect_type_comment} onValueChanged={(e) => valueChanged(e.value, 'inspection_library_defect_type_comment')} value={data?.inspection_library_defect_type_comment} />
                                        </div>
                                    </>
                                )}
                            </div>
                        )}

                        <div className="container-footer">
                            <div className="btn-group">
                                {isAddStatus && (
                                    <>
                                        <Button className="btn-s-r" type="normal" stylingMode="text" text="cancel" onClick={() => setAddStatus(false)} />
                                        <Button className="btn-s-r" type="default" stylingMode="contained" text="save" onClick={() => onSaveDefectRow('add')} />
                                    </>
                                )}
                                {isEditStatus && (
                                    <>
                                        <Button className="btn-s-r" type="normal" stylingMode="text" text="cancel" onClick={() => [setEditStatus(false), setCheckedSecondItems([])]} />
                                        <Button className="btn-s-r" type="default" stylingMode="contained" text="save" onClick={() => onSaveDefectRow('edit')} />
                                    </>
                                )}
                                {!isAddStatus && !isEditStatus && (
                                    <>
                                        <Button className="btn-s-r" type="normal" stylingMode="text" text={translation.cancel} onClick={exitForm} />
                                        <Button
                                            className="btn-s-r"
                                            type="default"
                                            stylingMode="contained"
                                            text={translation.save}
                                            onClick={(e) => {
                                                onSave(e);
                                                // submit(e, onSave);
                                            }}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            <DefectTypePopup visible={isDefectTypePopupVisibility} onClose={() => [setDefectTypePopupVisibility(false), setIsLoad(false)]} onOk={() => setDefectTypePopupVisibility(true)} getDatas={data} setDatas={setData} isLoad={isLoad} getDataSources={dataSource} setDataSources={setDataSource} />
            <MeasureConditionPopup visible={isMeasureConPopupVisibility} onClose={() => setMeasureConPopupVisibility(false)} onOk={() => setMeasureConPopupVisibility(true)} getDatas={addDataSource} setDatas={setAddDataSource} editData={checkedItem} setEditData={setCheckedItem} state={isConditionAdd} />
            <SelectLibraryPopup visible={isLibraryPopupVisibility} onClose={() => [setLibraryPopupVisibility(false), setIsLoad(false)]} onOk={() => setLibraryPopupVisibility(true)} getDatas={data} setDatas={setData} isLoad={isLoad} getDataSources={dataSource} setDataSources={setDataSource} state={state} />
        </div>
    );
}

// bracket check
const bracketCheck = (str) => {
    let stack = [],
        cnt = 0;
    str.map((x) => {
        if (x === '(') {
            stack.push(x);
            cnt++;
        } else if (x === ')') {
            stack.pop();
            cnt--;
        }
    });

    return cnt;
};

// Measure formula - set condition
const AddCondition = (props) => {
    const { data, setData = () => {}, getData, setDisabled = () => {} } = props;

    const array = [];
    for (let i = 0; i < 16; i++) {
        let name = data[i]?.inspection_library_condition !== undefined ? data[i]?.inspection_library_condition : '';
        let gubun = data[i]?.inspection_library_condition !== undefined ? `${data[i]?.inspection_library_condition}(${data[i]?.inspection_library_condition_value})` : '';
        array.push({ idx: i, name: name, gubun: gubun });
    }

    const onClickMeasure = (e) => {
        setData([...getData, e.target.innerText]);
        setDisabled('');

        let len = document.getElementsByClassName('condition-list')[0].childNodes[0].childNodes.length;
        for (let i = 0; i < len; i++) {
            document.getElementsByClassName('condition-list')[0].childNodes[0].childNodes[i].classList.add('disabled');
        }
    };

    return (
        <ul>
            {array.map((m) => {
                return (
                    <li key={m.idx} onClick={(e) => onClickMeasure(e)} id={m.gubun}>
                        {m.name}
                    </li>
                );
            })}
        </ul>
    );
};
