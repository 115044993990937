import React, { useEffect, useState } from "react";
import { getUser, getIP, getMenuMappingInfo, getLanguage } from '../../apiInterface/utils/Common';
import { useLocation, useNavigate } from "react-router-dom";
import Button from 'devextreme-react/button';
import TextBox from 'devextreme-react/text-box';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import useTranslation from "../../components/customHooks/translations";
import { Validator, RequiredRule, EmailRule, PatternRule, CustomRule } from 'devextreme-react/validator';
import { SelectBox } from 'devextreme-react/select-box';
import { swal, useSwal } from '../../components/common/Swal';
import useErrorHandler from "../../components/customHooks/useErrorHandler";
import SecurityGrid from "./SecurityGrid";
import TreeListPath from "../../components/common/TreeListPath";
import {
    getItemCategoryById,
    getItemMenuMapping,
    getSecuritySetting,
    getSecurityMaster,
    getItemCategory,
    updateItemCategory,
    insertItemCategory,
    deleteItemCategory,
    insertItemCategorySetting,
    updateItemCategorySeq
} from '../../apiInterface/item/ItemCategoryAPI';
import { DropdownField, TextboxField } from "../../components/common/FormField";
import { PageTitle } from "../../components/common/PageTitle.jsx";

export default function ItemCategoryForm() {
    const previousPage = "/ItemCategory";
    const user = getUser();
    const ipAddress = getIP();
    const navigate = useNavigate();
    const errorHandler = useErrorHandler();
    const translation = useTranslation();
    const location = useLocation();
    const title = location.state.title;
    const { state } = useLocation();
    const language = getLanguage();
    const [data, setData] = useState();
    const [securityGroup, setSecurityGroup] = useState([]);
    const { success, fail } = useSwal();


    const onInsert = async () => {
        let { id: mapping_id } = await getMenuMappingInfo();
        const queryData = {
            user_master_id: user.user_master_id,
            user_log_ip: ipAddress,
            user_menu_mapping_id: mapping_id,
            user_company_id: user.user_company_id,
            selected_menu_mapping_id: sessionStorage.getItem("itemMenu"),
            item_category_parent_id: data.item_category_parent_id,
            item_category_name: data.item_category_name,
            item_category_is_active: data.item_category_is_active,
            item_category_comment: data.item_category_comment,
        }

        const response = await errorHandler(insertItemCategory, queryData);
        if (response) {
            const id = response.data.o_data[0].item_category_id;
            if (securityGroup.length > 0) {
                securityGroup.forEach((element) => {
                    element.item_category_id = id;
                    InsertSecurityGroup(element);
                });
            }
            const cb = () => exitForm();
            //alertSuccess(translation.success_insert, cb);
            success(translation.success_insert, cb)
        }
    }

    const onUpdate = async () => {
        const queryData = {
            user_master_id: user.user_master_id,
            user_log_ip: ipAddress,
            selected_menu_mapping_id: sessionStorage.getItem("itemMenu"),
            user_company_id: user.user_company_id,
            item_category_id: data.item_category_id,
            item_category_name: data.item_category_name,
            item_category_is_active: data.item_category_is_active,
            item_category_comment: data.item_category_comment,
        }

        const response = await errorHandler(updateItemCategory, queryData);
        if (response) {
            if (securityGroup.length > 0) {
                securityGroup.forEach((element) => {
                    InsertSecurityGroup(element);
                });
            }
            const cb = () => exitForm();
            //alertSuccess(translation.success_update, cb);
            success(translation.success_update, cb);
        }
    }

    //init
    useEffect(() => {
        const init = async () => {
            if (state.title == "Edit") {
                getData();
            } else if (state.title == "Add") {
                setData({ item_category_parent_id: state.itemId });
                valueChanged(useItems[0].value, "item_category_is_active");
            }
        }
        init();
    }, []);

    const valueChanged = (value, key) => {
        setData((cur) => {
            let newData = { ...cur };
            newData[key] = value;
            return newData;
        });
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (state.title === "Edit") {
            onUpdate();
        }
        else {
            onInsert();
        }
    };


    const InsertSecurityGroup = async (group) => {
        const param = {
            item_category_id: data.item_category_id ? data.item_category_id : group.item_category_id,
            target_table: "security_master",
            target_id: group.security_master_id,
            parent_copy: group.propagateToParent,
            child_copy: group.propagateToChildren,
        }

        const res = await errorHandler(insertItemCategorySetting, param);
        if (res) {

        }
    }

    const customValidation = (option) => {
        if (option.value !== undefined && option.value !== null) {
            return true;
        } else {
            return false;
        }
    }

    const useItems = [
        { value: true, title: translation.use },
        { value: false, title: translation.notUse }
    ];

    const exitForm = () => {
        navigate(previousPage, {
            state: {
                previousPage: location.pathname
            }
        });
    }

    const addPropationOption = (rowData, toParent, toChildren) => {
        rowData.propagateToParent = toParent ? true : false;
        rowData.propagateToChildren = toChildren ? true : false;
        return rowData;
    }

    const getData = async () => {
        let param = {
            item_category_id: state.itemId,
            sys_lang_code: language
        };

        const res = await errorHandler(getItemCategoryById, param);
        if (res) {
            let temp = res.data.o_data[0];
            temp.item_category_id = state.itemId;
            let securityGroupList = [];

            if (temp.security_str) {
                const securityToken = JSON.parse(temp.security_str);
                for (let i = 0; i < securityToken.length; i++) {
                    //change target based data to security type
                    let group = {
                        security_master_id: securityToken[i].target_id,
                        security_master_name: securityToken[i].target_name,
                        user_business_name: securityToken[i].user_business_name,
                        security_master_comment: "",//doesn't supplied from getItemCategoryById
                    }
                    group = addPropationOption(group);
                    securityGroupList.push(group);
                }
            }
            setData(temp);
            setSecurityGroup(securityGroupList)
        }
    }

    const addSecurityGroup = (list) => {
        setSecurityGroup((cur) => {
            let copy = [...cur];
            list.map((e) => addPropationOption(e));

            const unique = list.filter((e) => {
                for (let i = 0; i < copy.length; i++) {
                    if (copy[i].security_master_id == e.security_master_id)
                        return false;
                }
                return true;
            });

            copy.push(...unique);
            return copy
        });
    }

    const deleteSecurityGroup = (list) => {
        setSecurityGroup((cur) => {
            let copy = [...cur];
            list.forEach((e) => {
                const index = copy.findIndex((target) => e.security_master_id == target.security_master_id);
                copy.splice(index, 1);
            });
            return copy;
        });
    }

    const dropdownRule = <CustomRule type={'custom'} validationCallback={customValidation} />;

    return (
        <div className="item-category-form">
            <div className="right-content-title">
                <PageTitle pageState={title} />
            </div>
            <form onSubmit={onSubmit}>
                <div className="right-content-body">
                    <div className="container">
                        <div className="dx-fieldset">
                            <div className="dx-field">
                                <div className="dx-field-label"><p>{translation.item_category_path}</p></div>
                                <div className="dx-field-value">
                                    <div className="parent-item"><TreeListPath path={location.state?.path} /></div>
                                </div>
                            </div>
                            <TextboxField
                                label={translation.item_category_name}
                                value={data?.item_category_name}
                                onValueChanged={(e) => valueChanged(e.value, "item_category_name")}
                                isEssential={true}
                                essentialMsg={translation.item_category_name + translation.is_required}
                            />

                            <DropdownField
                                label={translation.use_or_not}
                                value={data?.item_category_is_active}
                                dataSource={useItems}
                                valueExpr="value"
                                displayExpr="title"
                                onValueChanged={(e) => valueChanged(e.value, "item_category_is_active")}
                                validationRules={dropdownRule}
                                isSearchable={false}
                            />

                            <TextboxField
                                label={translation.operation_factory_comment}
                                value={data?.item_category_comment}
                                onValueChanged={(e) => valueChanged(e.value, "item_category_comment")}
                            />
                        </div>
                        <div className="block"></div>
                        <div className="data-grid security">
                            <div className="dx-field column">
                                <div className="dx-field-label">
                                    {/* <p className="essential">{translation.security_group}</p> */}
                                    {translation.security_group}
                                </div>
                                <div className="dx-field-value">
                                    <SecurityGrid
                                        data={securityGroup}
                                        addData={addSecurityGroup}
                                        deleteData={deleteSecurityGroup}
                                        width={668}
                                    />
                                    <p className="info-comment"><span className="material-icons-outlined">info</span>{translation.info_add_group}</p>
                                </div>
                            </div>
                        </div>
                        <div className="block"></div>
                        {title == "Edit" &&
                            <>
                                <div className="dx-fieldset write-info">
                                    <div className="dx-field">
                                        <div className="dx-field-label">{translation.lastModifiedDate}</div>
                                        <div className="dx-field-value">{data?.modified_date}</div>
                                    </div>
                                    <div className="dx-field">
                                        <div className="dx-field-label">{translation.lastModifiedBy}</div>
                                        <div className="dx-field-value">{data?.modified_by_name}
                                            {
                                                data?.modified_by_duty &&
                                                <span className="badge badge-job-position">{data?.modified_by_duty}</span>
                                            }
                                            {
                                                data?.modified_by_department &&
                                                <span className="badge badge-team">{data?.modified_by_department}</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="block"></div>
                            </>
                        }
                        <div>
                            <div className="btn-group" style={{ width: "708px" }}>
                                <Button
                                    type="normal"
                                    stylingMode="text"
                                    className="btn-s-r"
                                    onClick={exitForm}
                                >
                                    <div>
                                        <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                        <span className="dx-button-text">{translation.cancel}</span>
                                    </div>
                                </Button>
                                <Button
                                    type="default"
                                    stylingMode="contained"
                                    className="btn-s-r"
                                    useSubmitBehavior={true}
                                >
                                    <div>
                                        <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                        <span className="dx-button-text">{translation.save}</span>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}
