import {
  makeTokenAxiosDeleteRequest,
  makeTokenAxiosGetRequest,
  makeTokenAxiosPostRequest,
  makeTokenAxiosPutRequest
} from "../../apiInterface/utils/AxiosMask"

export const getMainSysDivision = async (param) => {
  let res;
  const urlPath = `division_management/get_main_sys_division_dt/`;

  try {
    res = await makeTokenAxiosGetRequest(urlPath, param);
    return res.status === 200 ? res.data : "fail";
  } catch (error) {
    console.error('error', error);
  }
  return res?.status === 200 ? res : "fail";
}

export const getMainSysDivisionSub = async (param) => {
  let res;
  const urlPath = `division_management/get_main_sys_division_sub_dt/`;

  try {
    res = await makeTokenAxiosGetRequest(urlPath, param);
    return res.status === 200 ? res.data : "fail";
  } catch (error) {
    console.error('error', error);
  }
  return res?.status === 200 ? res : "fail";
}

export const getMainSysDivisionById = async (param) => {
  let res;
  const urlPath = `division_management/get_sys_division_by_id/`;

  try {
    res = await makeTokenAxiosGetRequest(urlPath, param);
    return res.status === 200 ? res.data : "fail";
  } catch (error) {
    console.error('error', error);
  }
  return res?.status === 200 ? res : "fail";
}

export const getMainSysDivisionSubById = async (param) => {
  let res;
  const urlPath = `division_management/get_sys_division_sub_by_id/`;

  try {
    res = await makeTokenAxiosGetRequest(urlPath, param);
    return res.status === 200 ? res.data : "fail";
  } catch (error) {
    console.error('error', error);
  }
  return res?.status === 200 ? res : "fail";
}

export const updateSysDivisionSub = async (param) => {
  let res;
  const urlPath = `division_management/`;

  try {
    res = await makeTokenAxiosPutRequest(urlPath, param);
    return res.status === 200 ? res.data : "fail";
  } catch (error) {
    console.error('error', error);
  }
  return res?.status === 200 ? res : "fail";
}

export const insertSysDivisionSub = async (param) => {
  let res;
  const urlPath = `division_management/`;

  try {
    res = await makeTokenAxiosPostRequest(urlPath, param);
    return res.status === 200 ? res.data : "fail";
  } catch (error) {
    console.error('error', error);
  }
  return res?.status === 200 ? res : "fail";
}

export const deleteSysDivisionSub = async (param) => {
  let res;
  const urlPath = `division_management/`;

  try {
    res = await makeTokenAxiosDeleteRequest(urlPath, param);
    return res.status === 200 ? res.data : "fail";
  } catch (error) {
    console.error('error', error);
  }
  return res?.status === 200 ? res : "fail";
}

export const insertSysDivisionSubCaption = async (param) => {
  let res;
  const urlPath = `division_management/insert_sys_division_sub_caption`;

  try {
    res = await makeTokenAxiosPostRequest(urlPath, param);
    return res.status === 200 ? res.data : "fail";
  } catch (error) {
    console.error('error', error);
  }
  return res?.status === 200 ? res : "fail";
}

export const updateSysDivisionSubSeq = async (param) => {
  let res;
  const urlPath = `division_management/update_sys_division_sub_seq`;

  try {
    res = await makeTokenAxiosPutRequest(urlPath, param);
    return res.status === 200 ? res.data : "fail";
  } catch (error) {
    console.error('error', error);
  }
  return res?.status === 200 ? res : "fail";
}