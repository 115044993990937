import CustomStore from 'devextreme/data/custom_store';
import React, { useRef, useEffect, useState } from 'react';
import { getIP, getLanguage, getMenuMappingInfo, getUser } from '../../apiInterface/utils/Common';
import BaseDataGrid from '../../components/common/BaseDataGrid';
import { PageDropdown } from "../../components/common/PageDropdown";
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import { deleteAasxFileAPI, mainAasMaster } from '../../apiInterface/aasFile/AASFileManagementAPI'
import useTranslation from '../../components/customHooks/translations';
import { successToastr } from '../../components/common/Toastr';
import Badge from '../../components/common/Badge';
import { SearchWithTag } from '../../components/common/SearchWithTag';
import { PageTitle } from "../../components/common/PageTitle.jsx";
import { deleteMasterDatasetAPI, getFilterDropdownAPI, getMainMasterDatasetAPI, getMasterDatasetTypeAPI, getOneMasterDatasetAPI } from '../../apiInterface/masterDataset/MasterDatasetAPI';
import { DropdownField } from '../../components/common/FormField';


export default function MasterDataset(props) {
    const [dataSource, setDataSource] = useState(null);
    const [masterDatasetTypes, setMasterDatasetTypes] = useState([]);
    const [searchTags, setSearchTags] = useState([]);
    const errorHandler = useErrorHandler();
    const translation = useTranslation();
    const activeNotActiveJson = { true: translation.active, false: translation.not_active };
    const activeNotActiveClassName = { true: "grid-badge-use", false: "grid-badge-disabled" };
    const searchText = useRef("");
    const searchKey = useRef("");
    const masterDatasetType = useRef("");

    const gridHeader = [
        { dataField: "master_dataset_no", caption: "NO", alignment: "center", dataType: "number", width: 100, isFixed: true, allowReordering: true },
        { dataField: "master_dataset_code", caption: translation.master_dataset_code.toUpperCase(), dataType: "string", isFixed: true, allowReordering: true },
        { dataField: "master_dataset_name", caption: translation.master_dataset_name.toUpperCase(), dataType: "string", isFixed: true, allowReordering: true },
        { dataField: "raw_dataset_name", caption: translation.raw_dataset_name.toUpperCase(), dataType: "string", allowReordering: true },
        { dataField: "master_dataset_start", caption: translation.master_dataset_start.toUpperCase(), dataType: "string", allowReordering: true },
        { dataField: "master_dataset_end", caption: translation.master_dataset_end.toUpperCase(), dataType: "string", allowReordering: true },
        { dataField: "item_master_code", caption: translation.item_master_code.toUpperCase(), dataType: "string", allowReordering: true },
        { dataField: "item_master_name", caption: translation.item_master_name.toUpperCase(), dataType: "string", allowReordering: true },
        { dataField: "machine_equipment_code", caption: translation.machine_equipment_code.toUpperCase(), dataType: "string", allowReordering: true },
        { dataField: "machine_equipment_name", caption: translation.machine_equipment_name.toUpperCase(), dataType: "string", allowReordering: true },
        { dataField: "machine_mold_code", caption: translation.machine_mold_code.toUpperCase(), dataType: "string", allowReordering: true },
        { dataField: "machine_mold_name", caption: translation.machine_mold_name.toUpperCase(), dataType: "string", allowReordering: true },
        {
            dataField: "master_dataset_is_active", caption: translation.use_or_not.toUpperCase(), dataType: "string",
            width: 130, alignment: "center", allowReordering: true,
            cellRender: (e) => <Badge className={activeNotActiveClassName[e.value]} text={activeNotActiveJson[e.value]} />,
        },
        { dataField: "master_dataset_comment", caption: translation.master_dataset_comment.toUpperCase(), dataType: "string", allowReordering: true },
        { dataField: "master_dataset_modified_by", caption: translation.lastModifiedBy.toUpperCase(), dataType: "string", width: 300, allowReordering: true },
        { dataField: "master_dataset_modified_date", caption: translation.lastModifiedDate.toUpperCase(), dataType: "string", width: 150, allowReordering: true },
    ];

    //init
    useEffect(() => {
        getFilterDropdown();
        getMasterDatasetType();
        setCustomStore();
    }, []);

    const getMasterDatasetType = async () => {
        const user = getUser();
        const ipAddr = getIP();
        const lang = getLanguage();
        const menuMappingInfo = getMenuMappingInfo();
        const param = {
            user_company_id: user.user_company_id,
            user_log_ip: ipAddr,
            menu_mapping_id: menuMappingInfo.id,
            user_master_id: user.user_master_id,
            sys_lang_code: lang
        };

        const res = await errorHandler(getMasterDatasetTypeAPI, param);
        if (res) {
            setMasterDatasetTypes(res.data.o_data);
            res.data.o_data.length > 0 && (masterDatasetType.current = res.data.o_data[0].sys_division_sub_value);
        }
    }

    const getFilterDropdown = async () => {
        const user = getUser();
        const ipAddr = getIP();
        const menuMappingInfo = getMenuMappingInfo();
        const lang = getLanguage();
        let param = {
            user_company_id: user.user_company_id,
            user_log_ip: ipAddr,
            menu_mapping_id: menuMappingInfo.id,
            user_master_id: user.user_master_id,
            sys_lang_code: lang
        };

        const res = await errorHandler(getFilterDropdownAPI, param);
        if (res) {
            setSearchTags(res.data.o_data[0].search_dropdown);
        }
    }

    const setCustomStore = () => {
        const customStore = new CustomStore({
            load: (loadOptions) => load(loadOptions),
            remove: (val) => { remove(val); }
        });
        setDataSource(customStore);
    }

    const load = async (loadOptions) => {
        const user = getUser();
        const lang = getLanguage();
        const pageNo = parseInt(loadOptions["skip"] / loadOptions["take"]) + 1;
        let param = {
            user_company_id: user.user_company_id,
            sys_lang_code: lang,
            row_count: loadOptions["take"],
            page_no: pageNo,
            search_text: searchText.current,
            search_key: searchKey.current,
            master_dataset_type: masterDatasetType.current,
        }

        const res = await errorHandler(getMainMasterDatasetAPI, param);
        if (res) {
            let data = res.data.o_data;
            let totalCount = res.data.count;
            return {
                data: data,
                totalCount: totalCount,
            }
        }

        return {
            data: [],
            totalCount: 0,
        }
    }

    const remove = async (value) => {
        const user = getUser();
        const ip = getIP();
        const mappingInfo = await getMenuMappingInfo();
        const param = {
            user_master_id: user.user_master_id,
            user_company_id: user.user_company_id,
            user_log_ip: ip,
            menu_mapping_id: mappingInfo.id,
            master_dataset_id: value.master_dataset_id,
        }

        const res = await errorHandler(deleteMasterDatasetAPI, param);
        if (res) {
            successToastr(translation.success_delete_row);
        }
    }

    const onDropdownChanged = (e) => {
        masterDatasetType.current = e.value;
        setCustomStore();
    }

    const onSelectionChanged = (e) => {
        searchText.current = e.searchText;
        searchKey.current = e.selectedTags.map(e => e.search_key).join(",");
        setCustomStore();
    }

    return (
        <div className="master-dataset">
            <div className="right-content-title">
                <PageTitle />
                <PageDropdown />
            </div>
            <div className="right-content-body">
                <div className="container">
                    <BaseDataGrid
                        toolbarModules={[
                            <DropdownField
                                dataSource={masterDatasetTypes}
                                value={masterDatasetType.current}
                                onValueChanged={onDropdownChanged}
                                displayExpr="sys_division_sub_caption_text"
                                valueExpr="sys_division_sub_value"
                            />,
                            <SearchWithTag
                                items={searchTags}
                                displayExpr={getLanguage()}
                                onClick={onSelectionChanged}
                            />
                        ]}
                        dataSource={dataSource}
                        isRemote={true}
                        link="MasterDatasetForm"
                        headers={gridHeader}
                        isDoubleClicked={false}
                        isFullSize={true}
                        showEdit={false}
                    />
                </div>
            </div>
        </div>
    );
}
