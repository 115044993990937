﻿import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import TextBox from 'devextreme-react/text-box';
import Button from 'devextreme-react/button';
import SelectBox from 'devextreme-react/select-box';
import LoadIndicator from 'devextreme-react/load-indicator';
import {
    Validator,
    RequiredRule
} from 'devextreme-react/validator';
import { getUser, getIP, getLanguage, getMenuMappingInfo } from '../../apiInterface/utils/Common';
import {
    insertSysDivisionSub,
    insertSysDivisionSubCaption,
    getMainSysDivisionById,
    getMainSysDivisionSubById,
    updateSysDivisionSub,
} from "../../apiInterface/divisionManagement/DivisionAPI"

import useTranslation from "../../components/customHooks/translations";
import { swal, useSwal } from '../../components/common/Swal';
import { actionDirect, moveFocus, submit } from "../../components/common/FormFuncWithEnter";
import useErrorHandler from "../../components/customHooks/useErrorHandler";
import CaptionEditor from "../../components/common/CaptionEditor";
import { useRef } from "react";
import { PageTitle } from "../../components/common/PageTitle.jsx";
import TextArea from 'devextreme-react/text-area';


const DivisionForm = () => {
    const navigate = useNavigate();
    const translation = useTranslation();
    const location = useLocation();
    const title = location.state.title;
    const divisionId = location.state.data.sysDivisionId;
    const divisionSubId = location.state.rowData?.sys_division_sub_id;
    const errorHandler = useErrorHandler();
    const { success } = useSwal();
    const captionRef = useRef();

    const useOrNotItems = [translation.sys_division_use, translation.sys_division_not_use];
    const activeJson = { "true": useOrNotItems[0], "false": useOrNotItems[1] };
    const checkJson = { "Y": useOrNotItems[0], "N": useOrNotItems[1] };
    const [divisionCode, setDivisionCode] = useState(null);
    const [divisionName, setDivisionName] = useState(null);
    const [divisionSubValue, setDivisionSubValue] = useState(null);
    const [activeUseOrNot, setActiveUseOrNot] = useState(useOrNotItems[0]);
    const [isActive, setIsActive] = useState(true);
    const [checkUseOrNot, setCheckUseOrNot] = useState(useOrNotItems[0]);
    const [isCheck, setIsCheck] = useState('Y');
    const [divisionComment, setDivisionComment] = useState(null);
    const [editData, setEditData] = useState(null);
    const [captionValues, setCaptionValues] = useState();

    const getSysDivisionInfoById = async () => {
        const params = { division_id: divisionId }
        const res = await errorHandler(getMainSysDivisionById, params);
        if (res) {
            const badTypeInfo = res.data.o_data[0];
            setDivisionCode(badTypeInfo.sys_sys_division_code);
            setDivisionName(badTypeInfo.sys_division_name);
        }
    }

    const getSysDivisionSubInfoById = async () => {
        const lang = getLanguage();
        const params = {
            division_sub_by_id: divisionSubId,
            sys_lang_code: lang,
        }
        const res = await errorHandler(getMainSysDivisionSubById, params);
        if (res) {
            const divisionInfo = res.data.o_data[0];
            setEditData(divisionInfo);
            setDivisionSubValue(divisionInfo.sys_division_sub_value);
            setActiveUseOrNot(activeJson[divisionInfo.sys_division_sub_is_active.toString()]);
            setIsActive(divisionInfo.sys_division_sub_is_active);
            setCheckUseOrNot(checkJson[divisionInfo.sys_division_sub_default_check.toString()]);
            setIsCheck(divisionInfo.sys_division_sub_default_check);
            setDivisionComment(divisionInfo.sys_division_sub_comment);
            setCaptionEditor(divisionInfo.caption_str);
        }
    }

    const setCaptionEditor = (jsonString) => {
        try {
            const captionData = JSON.parse(jsonString);
            let captionValue = {};
            for (let i = 0; i < captionData.length; i++) {
                captionValue[captionData[i].sys_division_sub_lang_code] = captionData[i].sys_division_sub_caption_text;
            }
            setCaptionValues(captionValue);
        } catch {
            return null;
        }
    }

    useEffect(() => {
        getSysDivisionInfoById();
        if (title === 'Edit')
            getSysDivisionSubInfoById();
    }, []);

    const cancelBtnOnClick = () => {
        navigate("/Division");
    }

    const checkSubCatpion = () => {
        let blankCaption = [];
        const captionList = document.querySelectorAll('[aria-colindex="3"]');
        captionList.forEach(caption => {
            if (caption.textContent === ' ') {
                blankCaption.push(caption);
            }
        })
        return blankCaption;
    }

    const insertDivisionSub = async () => {
        const user = getUser();
        const ipAddress = getIP();
        const menuMappingInfo = await getMenuMappingInfo();

        const params = {
            user_company_id: user.user_company_id,
            sys_division_id: divisionId,
            sys_division_sub_parent_id: 0,
            sys_division_sub_value: divisionSubValue,
            sys_division_sub_default_check: isCheck,
            sys_division_sub_is_active: isActive,
            user_log_ip: ipAddress,
            user_master_id: user.user_master_id,
            user_menu_mapping_id: menuMappingInfo.id,
            sys_division_sub_comment: divisionComment,
        }

        const res = await errorHandler(insertSysDivisionSub, params);
        if (res) {
            const subId = res.data.o_data[0].sys_division_sub_id;
            const isSuccess = await insertDivisionSubCaption(subId);
            if (isSuccess) {
                var htmlString = `<p>${translation.success_insert}</p>`;
                var swalResFn = () => { navigate("../Division"); };
                success(htmlString, swalResFn);
            }
        }
    }

    const insertDivisionSubCaption = async (subId) => {
        const user = getUser();
        const menuMappingInfo = await getMenuMappingInfo();

        const captions = captionRef.current.getCaptions();
        for (let i = 0; i < captions.length; i++) {
            const params = {
                user_master_id: user.user_master_id,
                sys_division_sub_id: subId,
                sys_division_sub_language_code: captions[i].langCode,
                sys_division_sub_caption_text: captions[i].caption,
                user_menu_mapping_id: menuMappingInfo.id,
            };

            const res = await errorHandler(insertSysDivisionSubCaption, params);
            if (res == null) {
                return false;
            }
        }
        return true;
    }

    const updateDivisionSub = async () => {
        const user = getUser();
        const ipAddress = getIP();
        const menuMappingInfo = await getMenuMappingInfo();
        const params = {
            user_master_id: user.user_master_id,
            user_log_ip: ipAddress,
            user_menu_mapping_id: menuMappingInfo.id,
            sys_division_sub_id: divisionSubId,
            sys_division_sub_parent_id: 0,
            sys_division_sub_value: divisionSubValue,
            sys_division_sub_default_check: isCheck,
            sys_division_sub_is_active: isActive,
            sys_division_sub_comment: divisionComment,
        }

        const res = await errorHandler(updateSysDivisionSub, params);
        if (res) {
            const isSuccess = await insertDivisionSubCaption(divisionSubId);
            if (isSuccess) {
                var htmlString = `<p>${translation.success_update}</p>`;
                var swalResFn = () => { navigate("../Division"); };
                success(htmlString, swalResFn);
            }
        }
    }

    const isActiveSelectChanged = (e) => {
        setActiveUseOrNot(e.value);
        if (e.value === useOrNotItems[0])
            setIsActive(true);
        else if (e.value === useOrNotItems[1])
            setIsActive(false);
    }

    const isCheckSelectChanged = (e) => {
        setCheckUseOrNot(e.value);
        if (e.value === useOrNotItems[0])
            setIsCheck('Y');
        else if (e.value === useOrNotItems[1])
            setIsCheck('N');
    }

    const onGridUpdate = (values) => {
        setCaptionValues(cur => {
            let copy = { ...cur };
            values.forEach(val => copy[val.langCode] = val.caption);
            return copy;
        });
    }

    return (
        <>
            <div className="division-form">
                <div className="right-content-title">
                    <PageTitle pageState={title} /></div>
                <form onSubmit={actionDirect}>
                    <div className="right-content-body">
                        <div className="container">
                            <div className="dx-fieldset write-info">
                                <div className="dx-field">
                                    <div className="dx-field-label"><p>{translation.sys_division_code}</p></div>
                                    <div className="dx-field-value"><p>{divisionCode}</p></div>
                                </div>
                                <div className="dx-field">
                                    <div className="dx-field-label"><p>{translation.sys_division_name}</p></div>
                                    <div className="dx-field-value"><p>{divisionName}</p></div>
                                </div>
                            </div>
                            <div className="block"></div>
                            <div className="dx-fieldset">
                                <div className="dx-field">
                                    <div className="dx-field-label"><p className="essential">{translation.sys_division_sub_value}</p></div>
                                    <div className="dx-field-value">
                                        <TextBox className="ix-tfs-r"
                                            name="sys_division_sub_value"
                                            value={divisionSubValue}
                                            onValueChanged={e => setDivisionSubValue(e.value)}
                                            onEnterKey={moveFocus}
                                            inputAttr={{ focusidx: 0, autofocus: true }}
                                        >
                                            <Validator>
                                                <RequiredRule message={translation.sys_division_sub_value + translation.is_required} />
                                            </Validator>
                                        </TextBox>
                                    </div>
                                </div>
                                <div className="dx-field">
                                    <div className="dx-field-label"><p className="essential">{translation.sys_division_sub_is_active}</p></div>
                                    <div className="dx-field-value">
                                        <SelectBox
                                            className="ix-tfs-r"
                                            dataSource={useOrNotItems}
                                            value={activeUseOrNot}
                                            onValueChanged={e => isActiveSelectChanged(e)}
                                            onEnterKey={moveFocus}
                                            inputAttr={{ focusidx: 1 }}
                                        />
                                    </div>
                                </div>
                                <div className="dx-field">
                                    <div className="dx-field-label"><p className="essential">{translation.sys_division_sub_default_check}</p></div>
                                    <div className="dx-field-value">
                                        <SelectBox
                                            className="ix-tfs-r"
                                            dataSource={useOrNotItems}
                                            value={checkUseOrNot}
                                            onValueChanged={e => isCheckSelectChanged(e)}
                                            onEnterKey={moveFocus}
                                            inputAttr={{ focusidx: 2 }}
                                        />
                                    </div>
                                </div>
                                <div className="dx-field">
                                    <div className="dx-field-label"><p>{translation.sys_division_comment}</p></div>
                                    <div className="dx-field-value">
                                        {/*<TextBox className="ix-tfs-r"*/}
                                        {/*    name="sys_division_comment"*/}
                                        {/*    value={divisionComment}*/}
                                        {/*    onValueChanged={e => setDivisionComment(e.value)}*/}
                                        {/*    onEnterKey={e => document.querySelector('[aria-rowindex="1"] [aria-colindex="3"]').click()}*/}
                                        {/*    inputAttr={{ focusidx: 3 }}*/}
                                        {/*>*/}
                                        {/*</TextBox>*/}
                                        <TextArea
                                            name="sys_division_comment"
                                            value={divisionComment}
                                            onValueChanged={e => setDivisionComment(e.value)}
                                            onEnterKey={e => document.querySelector('[aria-rowindex="1"] [aria-colindex="3"]').click()}
                                            inputAttr={{ focusidx: 3 }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="block"></div>
                            <div className="dx-fieldset language">
                                <div className="dx-field column">
                                    <div className="dx-field-label"><p>{translation.language}</p></div>
                                    <div className="dx-field-value //data-grid caption-editor">
                                        <CaptionEditor
                                            value={captionValues}
                                            ref={captionRef}
                                            onValueChanged={onGridUpdate}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="block"></div>
                            {title == "Edit" && editData?.modified_date !== null && editData?.modified_by_name !== null &&
                                <>
                                    <div className="dx-fieldset write-info">
                                        <div className="dx-field">
                                            <div className="dx-field-label">{translation.lastModifiedDate}</div>
                                            <div className="dx-field-value">{editData?.modified_date}</div>
                                        </div>
                                        <div className="dx-field">
                                            <div className="dx-field-label">{translation.lastModifiedBy}</div>
                                            <div className="dx-field-value">{editData?.modified_by_name}
                                                <span className="badge badge-job-position">{editData?.modified_by_duty}</span>
                                                <span className="badge badge-team">{editData?.modified_by_department}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="block"></div>
                                </>
                            }
                            <div className="btn-group dx-fieldset">
                                <Button
                                    type="normal"
                                    stylingMode="text"
                                    className="btn-s-r"
                                    onClick={cancelBtnOnClick}
                                >
                                    <div>
                                        <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                        <span className="dx-button-text">{translation.cancel}</span>
                                    </div>
                                </Button>
                                <Button
                                    id="submitBtn"
                                    type="default"
                                    stylingMode="contained"
                                    className="btn-s-r"
                                    useSubmitBehavior={true}
                                    onClick={e => {
                                        const blankCaption = checkSubCatpion();
                                        if (blankCaption.length === 0 && title === 'Add') submit(e, insertDivisionSub);
                                        else if (blankCaption.length === 0 && title === 'Edit') submit(e, updateDivisionSub);
                                        else if (blankCaption.length > 0) blankCaption[0].click();
                                    }}
                                >
                                    <div>
                                        <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                        <span className="dx-button-text">{translation.save}</span>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}

export default DivisionForm;
