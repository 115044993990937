import Button from 'devextreme-react/button';
import { SelectBox } from 'devextreme-react/select-box';
import React, { useRef, useEffect, useState } from 'react';
import {
    getDepartmentDt,
    getSecurityBusinessByCompanyId,
    getSecurityMasterSelectedUserInfoById,
    getUserInfoByDepartmentId,
} from '../../apiInterface/securityManagement/SecurityGroupAPI';
import { getLanguage, getUser } from '../../apiInterface/utils/Common';
import CustomTreeList from '../../components/common/TreeList';
import useTranslation from '../../components/customHooks/translations';
import '../../contents/styles/security/security-group-form.css';
import { moveFocus } from '../../components/common/FormFuncWithEnter';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import { Popup, ScrollView } from 'devextreme-react';
import BaseDataGrid from '../../components/common/BaseDataGrid';


export default function SelectDeptAndUserPopup(props) {
    const {
        visible,
        businessId,
        userDataSource,
        setUserDataSource,
        onHiding = () => { },
    } = props;

    const [businessDataSource, setBusinessDataSource] = useState([]);
    const [departmentDataSource, setDepartmentDataSource] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [selectedUserList, setSelectedUserList] = useState([]);
    const [selectedDepartmentList, setSelectedDepartmentList] = useState([]);
    const [selectedBusinessId, setSelectedBusinessId] = useState(businessId);
    const gridRef = useRef();
    const treeRef = useRef();

    const translation = useTranslation();
    const user = getUser();
    const errorHandler = useErrorHandler();

    const departmentHeaderInfo = [
        { dataField: 'user_department_name', caption: translation.security_group_department, dataType: 'string', visible: true }
    ];

    const userDepartmentHeaderInfo = [
        { dataField: 'user_master_name', caption: translation.security_group_user, dataType: 'string', isEdit: false },
        { dataField: 'user_business_name', caption: translation.security_group_business, dataType: 'string' },
    ];

    const selectList = [...selectedUserList, ...selectedDepartmentList];

    useEffect(() => {
        setSelectedBusinessId(businessId);
        getSecurityBusiness();
    }, []);

    const getUserInfo = async (selectedUserStr) => {
        const language = getLanguage();
        const param = {
            user_department_ids: selectedUserStr,
            sys_lang_code: language,
        };

        const res = await errorHandler(getUserInfoByDepartmentId, param);
        if (res) {
            setDataSource(res.data.o_data);
        }
    };

    const onDepartmentSelectedChanged = (e) => {
        const selected = e.component.getSelectedRowsData('leavesOnly');
        setSelectedDepartmentList(selected);
    };

    const onDeptRowClicked = (leaves) => {
        const ids = leaves.map(e => e.user_department_id);
        getUserInfo(ids.join(','));
    };

    const getSecurityBusiness = async () => {
        const param = { user_company_id: user.user_company_id };

        const res = await errorHandler(getSecurityBusinessByCompanyId, param);
        if (res) {
            setBusinessDataSource(res.data.o_data);
            onChangedBusinessInfoInAddUser(businessId);
        }
    };

    const onUserSelectedChanged = (data) => {
        setSelectedUserList(data);
    };

    const onChangedBusinessInfoInAddUser = async (e) => {
        const user = getUser();
        const param = {
            user_company_id: user.user_company_id,
            user_business_id: e,
        };

        const res = await errorHandler(getDepartmentDt, param);
        if (res) {
            setDepartmentDataSource(res.data.o_data);
        }
    };

    const reset = () => {
        // setBusinessDataSource([]);
        // setDepartmentDataSource([]);
        setDataSource([]);
        setSelectedUserList([]);
        setSelectedDepartmentList([]);
        const treeList = treeRef.current.getInstance();
        treeList.deselectAll();
        const grid = gridRef.current.getInstance();
        grid.deselectAll();
    };


    const removeTag = (i) => {
        let node = selectList[i];

        if (node.user_department_id == null) {
            let index = selectedUserList.findIndex(other => other.user_master_id == node.user_master_id);
            let copy = [...selectedUserList];
            let removed = copy.splice(index, 1);
            setSelectedUserList(removed.map(e => e.user_master_id));

            const gridInstance = gridRef.current.getInstance();
            gridInstance.deselectRows(removed);
        } else {
            let index = selectedDepartmentList.findIndex(other => other.user_department_id == node.user_department_id);
            let copy = [...selectedDepartmentList];
            let removed = copy.splice(index, 1);
            setSelectedDepartmentList(copy);

            const treeInstance = treeRef.current.getInstance();
            treeInstance.deselectRows(removed.map(e => e.user_department_id));
        }
    };

    const removeAllTags = () => {
        setSelectedUserList([]);

        const instance = gridRef.current.getInstance();
        instance.deselectAll();
    };

    const onCancel = () => {
        reset();
        onHiding();
    }

    const saveAddUserInfo = async () => {
        const userList = [];
        for (let i = 0; i < selectedUserList.length; i++) {
            userList.push({ user_master_id: selectedUserList[i].user_master_id });
        }

        let userIds = '';
        let deptIds = '';

        selectedDepartmentList.forEach((val, idx) => {
            deptIds += val.user_department_id;
            if (idx != selectedDepartmentList.length - 1) deptIds += ',';
        });

        selectedUserList.forEach((val, idx) => {
            userIds += val.user_master_id;
            if (idx != selectedUserList.length - 1) userIds += ',';
        });

        const language = getLanguage();
        const param = {
            user_master_ids: userIds,
            user_department_ids: deptIds,
            sys_lang_code: language,
        };

        const res = await errorHandler(getSecurityMasterSelectedUserInfoById, param);
        if (res) {
            let duplicatedArray = [...userDataSource, ...res.data.o_data];
            duplicatedArray = duplicatedArray.filter((arr, index, callback) => index === callback.findIndex((t) => t.target_id === arr.target_id));
            setUserDataSource((prevArr) => {
                let copy = [...duplicatedArray];
                for (let i = 0; i < copy.length; i++) {
                    copy[i].target_no = copy.length - i;
                }
                return copy;
            });
        }
    };

    const onConfirm = () => {
        saveAddUserInfo();
        reset();
        onHiding();
    }

    return (
        <Popup
            width={1100}
            height={850}
            visible={visible}
            hideOnOutsideClick={true}
            onHiding={onHiding}
            dragEnabled={true}
            position="center"
            showTitle={false}
        >
            <div className="popup-select-dept-and-user">
                <div className="popup-header">
                    <p className="popup-title">{translation.select_department_and_user}</p>
                </div>
                <div className="popup-body">
                    <div className="selected-item-viewer">
                        <div className="icon-checked">
                            <i></i>
                        </div>
                        <div className="selected-item-field">
                            {selectList.length > 0 && (
                                <>
                                    <ul className="selected-items">
                                        {selectList.map((e, i) => (
                                            <li key={i} className="item">
                                                <p className="item-name">{e.user_master_name == undefined ? e.user_department_name : e.user_master_name}</p>
                                                <i className="material-icons btn-delete" onClick={() => removeTag(i)}>
                                                    cancel
                                                </i>
                                            </li>
                                        ))}
                                    </ul>
                                    <div className="btn-all-delete" onClick={removeAllTags}>
                                        <button>Delete All</button>
                                    </div>
                                </>
                            )}
                            {selectList.length == 0 && <p className="comment-not-selected">{translation.select_department_and_user_notice}</p>}
                        </div>
                    </div>
                    <div className="item-select-box">
                        <div className="left-panel">
                            <SelectBox
                                className="ix-tfs-r"
                                dataSource={businessDataSource}
                                value={selectedBusinessId}
                                valueExpr="user_business_id"
                                displayExpr="user_business_name"
                                onValueChanged={(e) => {
                                    setSelectedBusinessId(e.value);
                                    onChangedBusinessInfoInAddUser(e.value);
                                }}
                                onEnterKey={moveFocus}
                                inputAttr={{ focusidx: 0 }}
                            />
                            <div className="category">
                                <ScrollView>
                                    <CustomTreeList
                                        ref={treeRef}
                                        width={253}
                                        dataSource={departmentDataSource}
                                        id="user_department_id"
                                        parentId="user_department_parent_id"
                                        headers={departmentHeaderInfo}
                                        selectMode="multiple"
                                        isSelect={true}
                                        onSelectionChanged={onDepartmentSelectedChanged}
                                        onRowClick={onDeptRowClicked}
                                    />
                                </ScrollView>
                            </div>
                        </div>
                        <ScrollView>
                            <div className="container-wrapper">
                                <div className="container">
                                    <BaseDataGrid
                                        width="100%"
                                        dataSource={dataSource}
                                        headers={userDepartmentHeaderInfo}
                                        selectMode="multiple"
                                        defaultPageSize={10}
                                        isPaging={true}
                                        onSelectionChanged={onUserSelectedChanged}
                                        ref={gridRef}
                                        allowModify={false}
                                    />
                                </div>
                            </div>
                        </ScrollView>
                    </div>
                </div>
                <div className="popup-footer">
                    <Button
                        width={86}
                        text={translation.cancel}
                        type="normal"
                        stylingMode="text"
                        className="btn-s-r"
                        onClick={onCancel}
                    />
                    <Button
                        text={translation.confirm}
                        width={86}
                        type="default"
                        stylingMode="contained"
                        className="btn-s-r"
                        onClick={onConfirm}
                    />
                </div>
            </div>
        </Popup>
    );
};