import { makeAxiosPostFileRequest, makeAxiosPostRequest } from "../utils/AxiosMask"
export const inputCompanyInfoAPI = async (data) => {
  
    var res;
    const urlPath = `account/input_company_info/`;
    try {
        res = await makeAxiosPostRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};
export const uploadLogo = async (data) => {
    var res;
    const urlPath = `account/upload_logo`;
    try {
        res = await makeAxiosPostFileRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};