import React from 'react'
import { Tooltip } from 'devextreme-react'

export default function BtnTooltip(props) {
    return (
        <Tooltip
            target={props.target}
            showEvent="dxhoverstart"
            hideEvent="dxhoverend"
            position="bottom"
        >
            <div>{props.text}</div>
        </Tooltip>
    )
}