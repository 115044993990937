import { Button } from "devextreme-react";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useTranslation from "../customHooks/translations";
import { DropdownField } from "./FormField";

export default function DropdownWithAdd(props) {
    const {
        label,
        value,
        onValueChanged: valueChangedCallback = () => { },
        onSelectionChanged = () => { },
        dataSource,
        valueExpr,
        displayExpr,
        isSearchable = true, //allow to search dropdown items
        width,
        isEssential,
        validationRules,
        disabled,
        isColumn,
        essentialMsg,
        onEnterKey,
        inputAttr = "",
        link,
    } = props;

    const translation = useTranslation();
    const [buttonVisible, setButtonVisible] = useState(false);
    const navigate = useNavigate();
    const buttonRef = useRef();
    const buttonClickEvent = useRef(false);

    const onClick = (e) => {
        navigate(link);
    }

    const onKeyUp = (e) => {
        const comp = e.component;
        const timeout = comp.option('searchTimeout');
        setTimeout(() => {
            const items = comp.option('items');
            setButtonVisible(items.length === 0);
        }, timeout + 1);
    }

    const onBlur = (e) => {
        setTimeout(() => {
            if (buttonClickEvent.current !== true)
                setButtonVisible(false);
            buttonClickEvent.current = false;
        }, 100);
    }

    const onButtonFocus = (e) => {
        buttonClickEvent.current = true;
    }

    return (
        <div style={{ display: "flex", marginBottom: "24px", height: "40px", width: "100%" }}>
            <DropdownField
                label={label}
                value={value}
                onValueChanged={valueChangedCallback}
                onSelectionChanged={onSelectionChanged}
                dataSource={dataSource}
                valueExpr={valueExpr}
                displayExpr={displayExpr}
                isSearchable={isSearchable}
                isEssential={isEssential}
                validationRules={validationRules}
                disabled={disabled}
                isColumn={isColumn}
                essentialMsg={essentialMsg}
                onEnterKey={onEnterKey}
                inputAttr={inputAttr}
                onKeyUp={onKeyUp}
                onFocusOut={onBlur}
                width={buttonVisible ? "198px" : "304px"}
            />
            {
                buttonVisible &&
                <div onFocus={onButtonFocus} onBlur={onBlur}>
                    <Button
                        className="btn-s-r"
                        onClick={onClick}
                    >
                        {translation.add}
                    </Button>
                </div>
            }
        </div>
    )
}