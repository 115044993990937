import React ,{ useState, useEffect } from "react";
/*import 'devextreme/dist/css/dx.light.css';*/
import { inaTestGrid } from "../../apiInterface/home/InaTestAPI"


import { DataGrid, Paging, Scrolling, LoadPanel, Column } from 'devextreme-react/data-grid';
import { Button } from "devextreme-react/select-box";
import { Popup } from 'devextreme-react/popup';
import { Lookup } from "devextreme-react";

const employeesList = ['Ina', 'Jisoo', 'Martin', 'Kang']

const InaTest = () => {
  const [tableData, setTableData] = useState([])
  const [isPopupVisible, setPopupVisibility] = useState(false);

  useEffect(() => {
    const get = async () => {
      //const res = await axios.get("https://jsonplaceholder.typicode.com/todos");
      const res = await inaTestGrid()
      setTableData(res);
      //setTableData(res.data);
    }
    get();
  }, []);

  const togglePopup = () => {
    setPopupVisibility(!isPopupVisible)
    console.log('togglePopup')
  }

  return (
    <div className="InaTest">
      <DataGrid
        dataSource={tableData}
        keyExpr="id">
        <Paging defaultPageSize={10} />
      </DataGrid>

      <DataGrid
        height={400}
        remoteOperations={true}
        dataSource={tableData}
        keyExpr="id">
        <Scrolling mode="infinite" />
        <Paging defaultPageSize={10} />
        <LoadPanel enabled />
      </DataGrid>

      <DataGrid
        dataSource={tableData}
        keyExpr="id">
        <Paging defaultPageSize={10} />
        <Column type="buttons" width={300}>
          <Button name="findPopup" text="openPopup" onClick={togglePopup} />
        </Column>
        <Column dataField="userId" width={300}></Column>
        <Column dataField="id" width={300}></Column>
        <Column dataField="title" width={300}></Column>
        <Column dataField="completed" width={300}></Column>        
      </DataGrid>

      <Popup
        id="popuptest"
        width={500}
        height={500}
        visible={isPopupVisible}
        hideOnOutsideClick={true}
        onHiding={togglePopup}
        showTitle={true}
        title="Lookup Popup"
      >
        <Lookup
          items={employeesList}
          defaultValue={employeesList[0]}
        ></Lookup>
      </Popup>
    </div>
  );
}

export default InaTest