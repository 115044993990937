// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.dx-overlay-wrapper div.dx-popup-content .popup-delete-confirm div.popup-body div.dx-fieldset {
    border-bottom: 1px solid var(--border);
    margin: 0px !important;
    padding-top: 24px;
}
.popup-delete-confirm .dx-field {
    padding-bottom: 24px;
}
.popup-delete-confirm .dx-field .dx-field-value {
    display: flex;
    align-items: center;
    gap: 12px;
}
.popup-delete-confirm .dx-field .dx-field-value .info-comment {
    margin-top: 0px;
}
.popup-delete-confirm .delete-confirm-list {
    height: 420px;
    padding: 0px !important;
    margin: 0px !important;
}
.popup-delete-confirm .delete-confirm-list .ix-treelist {
    padding: 16px;
}

/*explorer*/
.aas-master-mgt-explorer .right-content-body {
    height: calc(100% - 44px);
}
.aas-master-mgt-explorer .right-content-body .container {
    padding: 0px;
}
.aas-master-mgt-explorer .right-content-body .right-panel .panel-body {
    display: flex;
    align-items: center;
    justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/contents/styles/dataManagement/aas-master-management.css"],"names":[],"mappings":"AAAA;IACI,sCAAsC;IACtC,sBAAsB;IACtB,iBAAiB;AACrB;AACA;IACI,oBAAoB;AACxB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;AACA;IACI,eAAe;AACnB;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,sBAAsB;AAC1B;AACA;IACI,aAAa;AACjB;;AAEA,WAAW;AACX;IACI,yBAAyB;AAC7B;AACA;IACI,YAAY;AAChB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B","sourcesContent":["div.dx-overlay-wrapper div.dx-popup-content .popup-delete-confirm div.popup-body div.dx-fieldset {\n    border-bottom: 1px solid var(--border);\n    margin: 0px !important;\n    padding-top: 24px;\n}\n.popup-delete-confirm .dx-field {\n    padding-bottom: 24px;\n}\n.popup-delete-confirm .dx-field .dx-field-value {\n    display: flex;\n    align-items: center;\n    gap: 12px;\n}\n.popup-delete-confirm .dx-field .dx-field-value .info-comment {\n    margin-top: 0px;\n}\n.popup-delete-confirm .delete-confirm-list {\n    height: 420px;\n    padding: 0px !important;\n    margin: 0px !important;\n}\n.popup-delete-confirm .delete-confirm-list .ix-treelist {\n    padding: 16px;\n}\n\n/*explorer*/\n.aas-master-mgt-explorer .right-content-body {\n    height: calc(100% - 44px);\n}\n.aas-master-mgt-explorer .right-content-body .container {\n    padding: 0px;\n}\n.aas-master-mgt-explorer .right-content-body .right-panel .panel-body {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
