// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.division .right-content-body {
    display: flex;
    flex-direction: column;
}
.division .right-content-body .select-message {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #d4d4d4;
}
.division .right-content-body .select-message i {
    width: 80px;
    height: 80px;
    font-size: 60px;
    margin-bottom: 4px;
}
.division .selected-row {
    position: absolute;
    left: 4px;
    height: 40px;
    padding: 12px 0px;
}
.division .selected-row p {
    color: var(--inactive-text);
}
.division .selected-row p span {
    font-weight: bold;
    color: var(--primary-500);
}
.division .dx-datagrid .dx-datagrid-rowsview:not(.dx-empty) .dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-expand {
    border-right: none;
}`, "",{"version":3,"sources":["webpack://./src/contents/styles/division/division.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;AACA;IACI,SAAO;IACP,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,cAAc;AAClB;AACA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;IACf,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,SAAS;IACT,YAAY;IACZ,iBAAiB;AACrB;AACA;IACI,2BAA2B;AAC/B;AACA;IACI,iBAAiB;IACjB,yBAAyB;AAC7B;AACA;IACI,kBAAkB;AACtB","sourcesContent":[".division .right-content-body {\n    display: flex;\n    flex-direction: column;\n}\n.division .right-content-body .select-message {\n    flex: 1;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    color: #d4d4d4;\n}\n.division .right-content-body .select-message i {\n    width: 80px;\n    height: 80px;\n    font-size: 60px;\n    margin-bottom: 4px;\n}\n.division .selected-row {\n    position: absolute;\n    left: 4px;\n    height: 40px;\n    padding: 12px 0px;\n}\n.division .selected-row p {\n    color: var(--inactive-text);\n}\n.division .selected-row p span {\n    font-weight: bold;\n    color: var(--primary-500);\n}\n.division .dx-datagrid .dx-datagrid-rowsview:not(.dx-empty) .dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-expand {\n    border-right: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
