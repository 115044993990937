﻿//test 
import React, { useEffect, useState } from "react";
import { getToken, getUser, getIP, getLanguage, getMenuMappingInfo } from '../../apiInterface/utils/Common';
import {
    getBusinessInfoByCompanyId, updateBusinessInfoByCompanyId,
    deleteBusinessInfoByCompanyId, insertBusinessInfoByCompanyId
} from "../../apiInterface/businessManagement/BusinessManagementAPI";
import useTranslation from "../../components/customHooks/translations";
import { swal } from '../../components/common/Swal';
import "../../contents/styles/layout/data-grid.css";
import { useNavigate } from 'react-router-dom';
import BaseDataGrid from "../../components/common/BaseDataGrid";
import { FilterComponent } from "../../components/common/FormField";
import useErrorHandler from "../../components/customHooks/useErrorHandler";
import { successToastr } from "../../components/common/Toastr";
import { PageDropdown } from "../../components/common/PageDropdown";
import { PageTitle } from "../../components/common/PageTitle.jsx";
import Badge from "../../components/common/Badge";

let backupData = [];
const BusinessManagementInfo = () => {
    const translation = useTranslation();
    const activeNotActiveJson = { true: translation.active, false: translation.not_active };
    const activeNotActiveClassName = { true: "grid-badge-use", false: "grid-badge-disabled" }
    const [dataSource, setDataSource] = useState([]);

    const neededBusiness = () => {
        const businessDict = [
            { dataField: "user_business_no", caption: translation.user_business_no, dataType: "number", isEdit: false, width: 100, alignment: "center", isFixed: true, allowReordering: true },
            { dataField: "user_business_name", caption: translation.user_business_name.toUpperCase(), dataType: "string", isRequired: true, isFixed: true, allowReordering: true },
            { dataField: "user_business_address", caption: translation.user_business_address.toUpperCase(), dataType: "string", allowReordering: true },
            { dataField: "user_business_zipcode", caption: translation.user_business_zipcode.toUpperCase(), dataType: "string", width: 100, allowReordering: true },
            {
                dataField: "user_business_phone", caption: translation.user_business_phone.toUpperCase(), dataType: "string", width: 140,
                patternRule: {
                    message: translation.hyphenNotAllowed,
                    pattern: /^\d{2,3}\d{3,4}\d{4}$/
                }, allowReordering: true
            },
            {
                dataField: "user_business_is_active", caption: translation.user_business_is_active.toUpperCase(), dataType: "string", width: 112, alignment: "center",
                cellRender: (e) => <Badge className={activeNotActiveClassName[e.value]} text={activeNotActiveJson[e.value]} />, allowReordering: true,
            },
            { dataField: "user_business_comment", caption: translation.user_business_comment.toUpperCase(), dataType: "string", allowReordering: true },
            { dataField: "user_business_modified_by", caption: translation.lastModifiedBy.toUpperCase(), dataType: "string", isEdit: false, width: 300, allowReordering: true },
            { dataField: "user_business_modified_date", caption: translation.lastModifiedDate.toUpperCase(), dataType: "string", isEdit: false, width: 150, allowReordering: true },

        ]
        return businessDict;
    };
    const [header, setHeader] = useState(neededBusiness());
    const navigate = useNavigate();

    const getBusinessInfo = async () => {
        const user = getUser();
        const lang = getLanguage();
        const param = { "user_company_id": user.user_company_id, "sys_lang_code": lang };

        const res = await errorHandler(getBusinessInfoByCompanyId, param);
        if (res) {
            const businessInfo = res.data.o_data;
            backupData = businessInfo;
            //setDataSource(businessInfo);
            filtering();
        }
    }

    useEffect(() => {
        getBusinessInfo();
    }, []);


    const errorHandler = useErrorHandler();
    const deleteBusinessInfo = async (e) => {
        const user = getUser();
        const ipAddress = getIP();
        const menuMappingInfo = await getMenuMappingInfo().id;
        const data = e.data;
        const queryData = {
            "user_master_id": user.user_master_id,
            "user_log_ip": ipAddress,
            "user_menu_mapping_id": menuMappingInfo,
            "user_business_id": data.user_business_id,
        }
        ;
        const res = await errorHandler(deleteBusinessInfoByCompanyId, queryData)
        if (res) {
            successToastr(translation.success_delete_row);
            let index = backupData.indexOf(data);
            if (index > 0)
                backupData.splice(index, 1);
        }
    }

    const filtering = (keyword) => {
        let filtered = [];
        let lowKeyword = keyword?.toLowerCase();

        if (lowKeyword) {
            backupData.forEach((data) => {
                let res = data.user_business_name.toLowerCase().includes(lowKeyword);
                if (res) {
                    filtered.push(data);
                }
            });
        } else {
            filtered = backupData;
        }

        setDataSource(filtered);
    }

    const filter = <FilterComponent
        label={translation.filter}
        onFilter={filtering}
        width={204}
    />

    return (
        <>
            <div className="business-management-info">
                <div className="right-content-title">
                    <PageTitle />
                    <PageDropdown />
                </div>
                <div className="right-content-body">
                    <div className="container">
                        <BaseDataGrid
                            toolbarModules={[filter]}
                            dataSource={dataSource}
                            headers={header}
                            link="BusinessManagementInfoForm"
                            onRowRemove={deleteBusinessInfo}
                            isFullSize={true}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default BusinessManagementInfo;
