import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomStore from 'devextreme/data/custom_store';
import '../../contents/styles/home/no-page.css';
import { PageTitle } from '../../components/common/PageTitle.jsx';
import { PageDropdown } from '../../components/common/PageDropdown';
import Button from 'devextreme-react/button';
import BaseDataGrid from '../../components/common/BaseDataGrid';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import { getUser, getIP, getLanguage, getMenuMappingInfo } from '../../apiInterface/utils/Common';
import UpDown from '../../components/common/UpDown';
import { SearchWithTag } from '../../components/common/SearchWithTag';
import { DateRangeField, DropdownField } from '../../components/common/FormField';
import DataGrid from 'devextreme-react/data-grid';
import { LineChart, BarChart, PieChart, ScatterChart, chartResize, MultiChart, StepLineChart, StackedAreaChart, RadarChart } from '../../components/ixChart/IXChart';
import useTranslation from '../../components/customHooks/translations';
//APIs importing
import { getAbnormalLogs, getIdShortPieChart, getDailyCountBarChart, getDatasetMasterOfAbnormal, getDatasetMasterPropertyOfAbnormal } from '../../apiInterface/dataManagement/AbnormalAnalysisAPI';

const AbnormalAnalysis = () => {
    const [pieChartInstance, setPieChartInstance] = useState(null);
    const [barChartInstance, setBarChartInstance] = useState(null);
    const [customDataSource, setCustomDataSource] = useState([]);
    const translation = useTranslation();
    const errorHandler = useErrorHandler();
    const formatDate = (date) => {
        const res = date.toISOString().split('T')[0];
        return res;
    };
    const navigate = useNavigate();
    const today = new Date();
    const [startDate, setStartDate] = useState(formatDate(today));
    const [endDate, setEndDate] = useState(formatDate(today));
    const [clickedIdShort, setClickedIdShort] = useState('ServoPressFitting1Sr2Load');
    const upDownClass = { high: 'dx-icon-m-arrow-increase', low: 'dx-icon-m-arrow-decrease' };
    const user = getUser();
    const applyBtnClick = () => {};

    const [datasetDropdown, setDatasetDropdown] = useState([]);
    const [featureDropdown, setFeatureDropdown] = useState([]);
    const [dataset, setDataset] = useState(translation.all);
    const [feature, setFeature] = useState(translation.all);

    const getChart = (chartType, params, type = []) => {
        let chart;
        switch (chartType) {
            case 'Chart-Bar':
                chart = BarChart(params[0]);
                break;
            case 'Chart-Pie':
                chart = PieChart(params[0]);
                break;
            default:
                break;
        }
        return chart;
    };
    //pie chart click event
    const pieChartClickHandler = (params) => {
        setClickedIdShort(params.name);
    };

    const drawChart = (id, chartType, x_value, y_value) => {
        const x = [x_value]; //chartType === "Chart-Pie" ? [x_value] : [Array.from({ length: 31 }, (v, i) => i + 1)];
        const y = [y_value]; //chartType === "Chart-Pie" ? [y_value] : [Array.from({ length: 31 }, (v, i) => Math.floor(Math.random() * 100 + 1))];
        const clickEvent = chartType === 'Chart-Pie' ? pieChartClickHandler : undefined;
        const chartParam = {
            x,
            y,
            legend: ['Abnormal analysis'],
            id,
            color: ['#0FB5AE'],
            backgroundColor: 'white',
            clickEvent: clickEvent,
        };
        const chart = getChart(chartType, [chartParam, undefined]);
        return chart;
    };

    const disposePreviousEchartInstance = (chartInstance) => {
        if (pieChartInstance) {
            // Dispose of the previous ECharts instance
            pieChartInstance.dispose();
        }
    };

    const getPieChartInfo = async () => {
        disposePreviousEchartInstance(pieChartInstance);
        const param = {
            company_code: user.user_company_code,
            start_date: startDate,
            end_date: endDate,
            dataset: dataset !== undefined ? (dataset !== translation.all ? dataset : '') : '',
            feature: feature !== undefined ? (feature !== translation.all ? feature : '') : '',
        };
        const res = await errorHandler(getIdShortPieChart, param);
        if (res) {
            const data = res.data.o_data;
            const x_pie = [];
            const y_pie = [];

            // Iterate over the objects and separate them into lists based on the category
            data.forEach((obj) => {
                x_pie.push(obj.x_values);
                y_pie.push(obj.y_values);
            });
            const chart = drawChart('#test2', 'Chart-Pie', x_pie, y_pie);
            //keep the previous instance of the echart(for disposing in new initialization)
            setPieChartInstance(chart);
        }
    };

    const getBarChartInfo = async () => {
        disposePreviousEchartInstance(barChartInstance);
        const param = {
            company_code: user.user_company_code,
            start_date: startDate,
            end_date: endDate,
            dataset: dataset !== undefined ? (dataset !== translation.all ? dataset : '') : '',
            feature: feature !== undefined ? (feature !== translation.all ? feature : '') : '',
        };
        const res = await errorHandler(getDailyCountBarChart, param);
        if (res) {
            const data = res.data.o_data;
            const x_pie = [];
            const y_pie = [];

            // Iterate over the objects and separate them into lists based on the category
            data.forEach((obj) => {
                x_pie.push(obj.x_values);
                y_pie.push(obj.y_values);
            });
            const chart = drawChart('#test1', 'Chart-Bar', x_pie, y_pie);
            //keep the previous instance of the echart(for disposing in new initialization)
            setBarChartInstance(chart);
        }
    };

    const getDatasetDropdown = async () => {
        const param = {
            user_company_id: user.user_company_id,
        };
        const res = await errorHandler(getDatasetMasterOfAbnormal, param);
        if (res) {
            let all = { dataset_master_id: '', dataset_master_name: translation.all };
            setDatasetDropdown([all, ...res.data.o_data]);
        }
    };

    const getFeatureDropdown = async (id) => {
        if (id === '') {
            setFeatureDropdown([]); // reset
            setFeature(undefined);
            return;
        }

        const param = {
            user_company_id: user.user_company_id,
            dataset_master_id: id,
        };
        const res = await errorHandler(getDatasetMasterPropertyOfAbnormal, param);
        if (res) {
            let all = { id_short: translation.all };
            setFeatureDropdown([all, ...res.data.o_data]);
        }
    };

    useEffect(() => {
        getDatasetDropdown();
        getPieChartInfo();
        getBarChartInfo();
        //drawChart("#test1", "Chart-Bar");
    }, []);

    useEffect(() => {
        getAbnormalInfo();
    }, [clickedIdShort]);

    const handleSubmitBtn = () => {
        getBarChartInfo();
        getPieChartInfo();
    };

    //bottom grid binding
    const header = [
        { dataField: 'No', caption: translation.abnormal_sign_log_no, dataType: 'string', isRequired: true, allowReordering: true, isFixed: true, width: 60, alignment: 'center' },
        { dataField: 'settingType', caption: translation.abnormal_setting_type, dataType: 'string', alignment: 'center', allowReordering: true, isFixed: true, width: 150 },
        { dataField: 'dataSet', caption: translation.dataset_name, dataType: 'string', isRequired: true, allowReordering: true },
        { dataField: 'idShort', caption: translation.id_short, dataType: 'string', allowReordering: true },
        { dataField: 'value', caption: 'LSL', dataType: 'string', allowReordering: true, width: 100 },
        { dataField: 'usl', caption: 'USL', dataType: 'string', allowReordering: true, width: 100 },
        { dataField: 'state', caption: translation.abnormal_sign_log_status, dataType: 'string', isEdit: false, width: 100, allowReordering: true, cellRender: (e) => <UpDown className={upDownClass[e.value]} text={e.value} /> },
        { dataField: 'reason', caption: translation.abnormal_sign_log_reason, dataType: 'string', isEdit: false, alignment: 'center', allowReordering: true },
        { dataField: 'recommend', caption: translation.abnormal_sign_log_recommend, dataType: 'string', isEdit: false, alignment: 'center', allowReordering: true },
        { dataField: 'createdDateTime', caption: translation.abnormal_sign_log_created_date.toUpperCase(), dataType: 'string', isEdit: false, width: 250, alignment: 'center', allowReordering: true },
    ];
    const getAbnormalInfo = async () => {
        const customStore = new CustomStore({
            load: async (loadOptions) => {
                let skip = 0;
                let take = 10;
                if (loadOptions['skip']) skip = loadOptions['skip'];
                if (loadOptions['take']) take = loadOptions['take'];
                const pageNo = parseInt(skip / take) + 1;
                const param = {
                    company_code: user.user_company_code,
                    start_date: startDate,
                    end_date: endDate,
                    row_count: take,
                    page_no: pageNo,
                    id_short: clickedIdShort,
                    dataset: dataset !== undefined ? (dataset !== translation.all ? dataset : '') : '',
                    feature: feature !== undefined ? (feature !== translation.all ? feature : '') : '',
                };
                const res = await errorHandler(getAbnormalLogs, param);
                if (res) {
                    const abnormalLogsData = res.data.o_data;
                    const pagingDict = {
                        data: abnormalLogsData,
                        totalCount: res.data.count,
                    };
                    return pagingDict;
                }
            },
        });
        setCustomDataSource(customStore);
    };

    return (
        <div className="abnormal-analysis">
            <div className="right-content-title">
                <PageTitle />
                <div>
                    <div className="title-toolbar">
                        <DropdownField label="Dataset" dataSource={datasetDropdown} displayExpr="dataset_master_name" valueExpr="dataset_master_name" value={dataset} onValueChanged={(e) => setDataset(e.value)} onSelectionChanged={(e) => getFeatureDropdown(e.selectedItem.dataset_master_id)} />
                        <DropdownField label="Feature" dataSource={featureDropdown} displayExpr="id_short" valueExpr="id_short" value={feature} onValueChanged={(e) => setFeature(e.value)} />
                        <DateRangeField startValue={startDate} endValue={endDate} setStartValue={setStartDate} setEndValue={setEndDate} applyBtnClick={applyBtnClick} />
                        <Button className="btn-s-r dx-button-mode-es btn-search" icon="search" type="default" stylingMode="contained" onClick={handleSubmitBtn} />
                    </div>
                    <PageDropdown />
                </div>
            </div>
            <div className="right-content-body">
                <div>
                    <div className="card">
                        <div className="card-header">
                            <p>Daily Total Count</p>
                        </div>
                        <div id="test1" className="card-body"></div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <p>Total Count of Dataset(Selectable)</p>
                        </div>
                        <div id="test2" className="card-body"></div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header">
                        <p>List of abnormal sign log of selected dataset</p>
                    </div>
                    <div className="card-body">
                        <BaseDataGrid
                            //toolbarModules={[abnormalTypeDropdown, filter, dateRange, searchBtn]}
                            dataSource={customDataSource}
                            allowModify={true}
                            isRemote={true}
                            headers={header}
                            selectMode="multiple"
                            isPaging={true}
                            showEdit={false}
                            showDelete={false}
                            showAdd={false}
                            showCopy={false}
                            showCheckBox={false}
                            link="BadTypeManagementInfoForm"
                            isFullSize={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AbnormalAnalysis;
