import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

export const successToastr = (msg) => {
  toastr.options = {
    closeButton: true,
    positionClass: "toast-bottom-center",
    showDuration: 300,
    hideDuration: 300,
    timeOut: 2000
  }
  toastr.clear()
  toastr.success(msg);
}

export const failToastr = (msg) => {
  toastr.options = {
    closeButton: true,
    positionClass: "toast-bottom-center",
    showDuration: 300,
    hideDuration: 300,
    timeOut: 2000
  }
  toastr.clear()
  toastr.error(msg);
}