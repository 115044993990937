import React, { useState } from "react";
import { useLocation } from 'react-router-dom';
//import DynamicSplitter from "../../components/splitPane/DynamicSplitPane"
import "../../contents/styles/templateMaster/create-template.css";
import CustomTemplate from "../../components/gridStackPane/CustomTemplate"
import "../../contents/styles/common/slider-machine.css";
import { getLanguage, getParam } from "../../apiInterface/utils/Common";
import { getTemplateMasterById } from "../../apiInterface/templateManagment/TemplateManagementAPI";

const CreateTemplate = (props) => {
    const location = useLocation();
    const stateParam = getParam("state");
    const stateParamForWindowPopup = stateParam !== undefined ? JSON.parse(stateParam) : undefined;
    const [templateState, setTemplateState] = useState(location.state);

    //const [title, setTitle] = useState(location.state?.title);
    //const [basicThemeURL, setBasicThemeURL] = useState(location.state?.basicThemeURL);
    //const [darkThemeURL, setDarkThemeURL] = useState(location.state?.darkThemeURL);
    //const [templateCategoryId, setTemplateCategoryId] = useState(location.state?.template_category_id);
    //const [addOrEdit, setAddOrEdit] = useState(location.state?.addOrEdit);


    ///// setting for preview
    const settingPreviewData = async () => {
        const getTemplateMaster = async (id) => {
            const param = {
                template_master_id: id,
                sys_lang_code: getLanguage()
            };
            const res = await getTemplateMasterById(param);
            return res.data.o_data[0];
        }

        const setLocationSatate = async (id) => {
            const templateMaster = await getTemplateMaster(id);
            location.state = { ...stateParamForWindowPopup, ...templateMaster }
            setTemplateState({ ...stateParamForWindowPopup, ...templateMaster })
            //setTitle(location.state.title);
            //setBasicThemeURL(location.state.basicThemeURL);
            //setDarkThemeURL(location.state.darkThemeURL);
            //setTemplateCategoryId(location.state.template_category_id);
            //setAddOrEdit(location.state.addOrEdit);
        }

        stateParamForWindowPopup !== undefined && await setLocationSatate(stateParamForWindowPopup.template_master_id);
    }
    settingPreviewData();
    return (
        //<DynamicSplitter data={location.state} title={title} addOrEdit={addOrEdit} template_category_id={template_category_id } basicThemeURL={basicThemeURL} darkThemeURL={darkThemeURL} />
        <>
            {templateState ?
                //<CustomTemplate data={location.state} title={title} addOrEdit={addOrEdit} template_category_id={templateCategoryId} basicThemeURL={basicThemeURL} darkThemeURL={darkThemeURL} />
                <CustomTemplate /> : <></>
            }
        </>
    );


};

export default CreateTemplate;