import Login from "../pages/home/Login";
import About from "../pages/home/About";
import Home from "../pages/home/Home";
import NoPage from "../pages/home/NoPage";
import Dashboard from "../pages/home/Dashboard";
import Landing from "../pages/home/Landing";
import InaTest from "../pages/home/InaTest";
import Chart from "../pages/home/Echart";
import DataGrid from "../pages/home/DataGrid";
import MultipleChart from "../pages/home/MultipleEChart";
import Subscription from "../pages/home/Subscription";
import FetchDataComponent from "../pages/home/rndConcurrentEchart";

export { Login, Home, NoPage, Dashboard, Landing, InaTest, Chart, DataGrid, MultipleChart, About, Subscription, FetchDataComponent }
