// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*.popup-find-department .dx-treelist-headers {
    display: none;
}
.popup-find-department .department-path-list .dx-treelist .dx-treelist-rowsview .dx-treelist-content .dx-treelist-table .dx-row td {
    border-right: none;
    border-left: none;
}
.popup-find-department .popup-body .dx-treelist-rowsview .dx-treelist-expanded span::before,
.popup-find-department .popup-body .dx-treelist-rowsview .dx-treelist-collapsed span {
    color: #000;
}
.popup-find-department .dx-treelist .dx-treelist-rowsview .dx-treelist-content .dx-row {
    border-bottom: 1px solid var(--border);
}*/`, "",{"version":3,"sources":["webpack://./src/contents/styles/userManagement/user-management-info-form.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;EAaE","sourcesContent":["/*.popup-find-department .dx-treelist-headers {\n    display: none;\n}\n.popup-find-department .department-path-list .dx-treelist .dx-treelist-rowsview .dx-treelist-content .dx-treelist-table .dx-row td {\n    border-right: none;\n    border-left: none;\n}\n.popup-find-department .popup-body .dx-treelist-rowsview .dx-treelist-expanded span::before,\n.popup-find-department .popup-body .dx-treelist-rowsview .dx-treelist-collapsed span {\n    color: #000;\n}\n.popup-find-department .dx-treelist .dx-treelist-rowsview .dx-treelist-content .dx-row {\n    border-bottom: 1px solid var(--border);\n}*/"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
