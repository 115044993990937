import React, { useEffect, useState, useRef } from 'react';
import { Popup, Button, ColorBox, TextBox, ScrollView, SelectBox } from "devextreme-react";
import { DropdownField } from '../../../components/common/FormField';

export default function SetConditionPopup(props) {
    const {
        visible,
        onClose = () => { },
        columnList,
        column,
        criteria,
        setConditionalApply = () => { },
    } = props;

    const [targetCol, setTargetCol] = useState();
    const [criteriaList, setCriteriaList] = useState();
    const [applyBtnDisable, setApplyBtnDisable] = useState();

    useEffect(() => {
        setTargetCol(column?.length > 0 ? column : undefined);
        setCriteriaList(criteria?.length > 0 ? criteria : []);
    }, [visible])

    useEffect(() => {
        const hasTargetCol = targetCol?.length > 0;
        const hasCriteriaList = criteriaList?.length !== 0 ? true : false;
        const hasAllCriteriaVal = criteriaList?.find(criteria => (criteria.type !== "between") && (Object.keys(criteria.value).length !== 1) || (criteria.type === "between") && (Object.keys(criteria.value).length !== 2 )) === undefined
        
        if (hasTargetCol && hasCriteriaList && hasAllCriteriaVal) {
            setApplyBtnDisable(false);
        } else {
            setApplyBtnDisable(true);
        }
    }, [targetCol, criteriaList])

    const settingCriteria = (idx, criteria, isDelete = false) => {
        const cCriteriaList = [...criteriaList];
        if (!isDelete) {
            cCriteriaList[idx !== undefined ? idx : criteriaList.length] = criteria;
        } else {
            cCriteriaList.splice(idx, 1);
        }
        setCriteriaList(cCriteriaList);
    }

    const onApply = () => {
        setConditionalApply(targetCol, criteriaList);
    }

    return (
        <Popup
            width={788}
            height={564}
            visible={visible}
            hideOnOutsideClick={true}
            onHiding={onClose}
            title="Set Condition"
        >
            <div className="popup-set-condition">
                <div className="popup-devide-l">
                    <div>
                        <p>Select column form condition</p>
                    </div>
                    <div>
                        <ScrollView>
                            <ul className="select-list">
                                {
                                    columnList?.map((col, i) => {
                                        const name = col.column.name;
                                        return (
                                            <li
                                                key={i}
                                                className={targetCol === name ? "selected" : ""}
                                                value={name}
                                                onClick={e => setTargetCol(e.currentTarget.getAttribute('value'))}
                                            >
                                                <p>{name}</p>
                                            </li>
                                        )
                                    })
                                }

                            </ul>
                        </ScrollView>

                    </div>
                </div>
                <div className="popup-devide-r">
                    <div className="popup-content-s">
                        <ScrollView>
                            <div className="card">
                                <div className="dx-field column">
                                    <div className="dx-field-label">
                                        <p>Select criteria</p>
                                    </div>
                                    <div className="dx-field-value">
                                        <ul>
                                            {
                                                criteriaList?.map((criteria, idx) =>
                                                    <Condition
                                                        criteria={criteria}
                                                        idx={idx}
                                                        onValueChange={settingCriteria}
                                                    />
                                                )
                                            }
                                            <Condition
                                                criteria={criteria}
                                                idx={undefined}
                                                onValueChange={settingCriteria}
                                                typeAdd={true}
                                            />
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </ScrollView>
                    </div>
                    <div className="popup-footer-s">
                        <Button
                            className="btn-s-xs"
                            type="normal"
                            stylingMode="text"
                            text="CANCEL"
                            onClick={onClose}
                        />
                        <Button
                            className="btn-s-xs"
                            type="default"
                            stylingMode="contained"
                            text="APPLY"
                            disabled={applyBtnDisable}
                            onClick={onApply}
                        />
                    </div>
                </div>
            </div>
        </Popup>
    );
}



const Condition = (props) => {
    const {
        criteria,
        idx,
        onValueChange = () => { },
        typeAdd = false,
    } = props;

    const [type, setType] = useState();
    const [value, setValue] = useState();

    //{ title: 'Min / Max', type: 'minmax' },
    const dummy = [
        { title: 'less', type: 'less' },
        { title: 'greater', type: 'greater' },
        { title: 'equals', type: 'equals' },
        { title: 'is between', type: 'between' }
    ];

    useEffect(() => {
        setType(criteria?.type);
        setValue(criteria?.value);
    }, [criteria])

    const onValueChanged = (target, value, seVal = undefined) => {   // seVal for between (start, end value)
        const type = criteria?.type;
        if (target === "dropdown") {
            onValueChange(idx, { type: value, value: {} });
        } else if (seVal === undefined) {    // less, greater, equals
            onValueChange(idx, { type: type, value: value.length > 0 ? {[type]: value} : {} });
        } else { // between
            const valueJson = {...criteria.value};
            value.length > 0 ? valueJson[seVal] = value : delete valueJson[seVal];
            onValueChange(idx, { type: type, value: valueJson });
        }
    }

    const onDelete = () => {
        onValueChange(idx, {}, true);
    }

    // const onValueChanged = (e, type) => {
    //     if (type === "dropdown") {
    //         setType(e.value);
    //         setLess(null);
    //         setGreater(null);
    //         setEquals(null);
    //         setBetween1(null);
    //         setBetween2(null);
    //     } else if (type === "less") {
    //         setLess(e);
    //     } else if (type === "greater") {
    //         setGreater(e);
    //     } else if (type === "equals") {
    //         setEquals(e);
    //     } else if (type === "between1") {
    //         setBetween1(e);
    //     } else if (type === "between2") {
    //         setBetween2(e);
    //     }
    // }

    const getCriteria = (criteria) => {
        if (["less", "greater", "equals"].includes(criteria?.type)) {
            return (
                <TextBox
                    className="ix-tfs-xs"
                    width="100%"
                    value={value && value[criteria.type] ? value[criteria.type] : ""}
                    onValueChange={e => onValueChanged(criteria.type, e)}
                />
            )
        } else if (["between"].includes(criteria?.type)) {
            return (
                <>
                    <TextBox
                        className="ix-tfs-xs"
                        width="100%"
                        value={value && value.sVal ? value.sVal : ""}
                        onValueChange={e => onValueChanged(criteria.type, e, "sVal")}
                    />
                    <p>and</p>
                    <TextBox
                        className="ix-tfs-xs"
                        width="100%"
                        value={value && value.eVal ? value.eVal : ""}
                        onValueChange={e => onValueChanged(criteria.type, e, "eVal")}
                    />
                </>
            )
        }
    }

    return (
        <li className="filter-dropdown">
            <SelectBox
                className="ix-tfs-xs"
                items={dummy}
                value={type}
                valueExpr="type"
                displayExpr="title"
                onValueChange={e => onValueChanged("dropdown", e)}
            />
            {/* {criteria.type === "minmax" &&
                <>
                    <div className="min-max">
                        <TextBox
                            className="ix-tfs-xs"
                            value={min}
                            onValueChange={e => onValueChanged(e, "min")}
                        />
                        <TextBox
                            className="ix-tfs-xs"
                            value={max}
                            onValueChange={e => onValueChanged(e, "max")}
                        />
                    </div>
                    <Button
                        className="btn-s-xs btn-hover-red"
                        type="normal"
                        stylingMode="text"
                        icon="trash"
                    />
                </>
            } */}

            {
                <>
                    <div className={criteria?.type}>
                        {
                        getCriteria(criteria)
                        }
                    </div>
                    {
                        !typeAdd &&
                        <Button
                            className="btn-s-xs btn-hover-red"
                            type="normal"
                            stylingMode="text"
                            icon="trash"
                            onClick={onDelete}
                        />
                    }
                </>
            }
        </li>
    );
}