import { Button, DataGrid, Popup, ScrollView } from 'devextreme-react';
import { useRef, useState, useTransition } from 'react';
import { getLanguage, getUser } from '../../apiInterface/utils/Common';
import BaseDataGrid from '../../components/common/BaseDataGrid';
import { DropdownField, FilterComponent } from '../../components/common/FormField';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import { getFactoryInfo } from '../../apiInterface/factory/FactoryAPI';
import useTranslation from '../../components/customHooks/translations';
import CustomStore from 'devextreme/data/custom_store';
import { getOperationLineAPI } from '../../apiInterface/menu/MenuAPI';
import Badge from '../../components/common/Badge';

export default function SelectLinePopup(props) {
    const { visible, onSelected = () => {}, onClose = () => {}, onOk = () => {} } = props;

    const [selectedRows, setSelectedRows] = useState([]);
    const [gridData, setGridData] = useState([]);
    const [okBtnDisabled, setOkBtnDisabled] = useState(true);
    const [factoryDropdown, setFactoryDropdown] = useState([]);
    const [curFactory, setCurFactory] = useState();
    const errorHandler = useErrorHandler();
    const searchText = useRef('');
    const translation = useTranslation();
    const gridRef = useRef();

    const activeNotActiveJson = { true: translation.active, false: translation.not_active };
    const activeNotActiveClassName = { true: 'grid-badge-use', false: 'grid-badge-disabled' };
    const headers = [
        { dataField: 'operation_line_no', caption: 'NO', dataType: 'string', width: 100, alignment: 'center', isFixed: true, allowReordering: true },
        { dataField: 'operation_line_code', caption: translation.operation_line_code.toUpperCase(), dataType: 'string', isFixed: true, allowReordering: true },
        { dataField: 'operation_line_name', caption: translation.operation_line_name.toUpperCase(), dataType: 'string', isFixed: true, allowReordering: true },
        { dataField: 'operation_factory_name', caption: translation._factory.toUpperCase(), dataType: 'string', isFixed: true, allowReordering: true },
        { dataField: 'user_business_name', caption: translation._business.toUpperCase(), dataType: 'string', isFixed: true, allowReordering: true },
        {
            dataField: 'operation_line_is_active',
            caption: translation.operation_line_is_active.toUpperCase(),
            dataType: 'boolean',
            width: 130,
            alignment: 'center',
            allowReordering: true,
            cellRender: (e) => <Badge className={activeNotActiveClassName[e.value]} text={activeNotActiveJson[e.value]} />,
        },
        { dataField: 'operation_line_comment', caption: translation.operation_line_comment.toUpperCase(), dataType: 'string', allowReordering: true },
        { dataField: 'operation_line_modified_by', caption: translation.lastModifiedBy.toUpperCase(), dataType: 'string', isEdit: false, width: 300, allowReordering: true },
        { dataField: 'operation_line_modified_date', caption: translation.lastModifiedDate.toUpperCase(), dataType: 'string', isEdit: false, width: 150, allowReordering: true },
    ];

    const init = async () => {
        const user = getUser();
        const lang = getLanguage();
        let param = {
            user_company_id: user.user_company_id,
            sys_lang_code: lang,
            user_business_id: user.user_business_id,
        };

        const res = await errorHandler(getFactoryInfo, param);
        if (res) {
            setFactoryDropdown(res.data.o_data);
            setCurFactory(res.data.o_data[0].operation_factory_id);
        }
    };

    const close = () => {
        onClose();
    };

    const ok = () => {
        onOk(selectedRows);
        close();
    };

    const onRowSelected = (rows) => {
        onSelected(rows);
        if (rows == null) {
            setOkBtnDisabled(true);
            setSelectedRows([]);
        } else {
            setSelectedRows([rows]);
            setOkBtnDisabled(false);
        }
    };

    const removeTag = (i) => {
        let cur = [...selectedRows];
        let removed = cur.splice(i, 1);
        setSelectedRows(cur);

        const instance = gridRef.current.getInstance();
        instance.deselectRows(removed);
    };

    const removeAllTags = () => {
        setSelectedRows([]);

        const instance = gridRef.current.getInstance();
        instance.deselectAll();
    };

    const filtering = (keyword) => {
        searchText.current = keyword;
        // init();
        onDropdownChanged({ value: curFactory });
    };

    const onDropdownChanged = async (e) => {
        let factoryId = e.value;
        const customStore = new CustomStore({
            load: async (loadOptions) => load(loadOptions, factoryId),
        });
        setGridData(customStore);
        setCurFactory(e.value);
    };

    const load = async (loadOptions, factoryId) => {
        const user = getUser();
        const lang = getLanguage();
        const pageNo = parseInt(loadOptions['skip'] / loadOptions['take']) + 1;
        let param = {
            user_company_id: user.user_company_id,
            operation_factory_id: factoryId,
            sys_lang_code: lang,
            row_count: loadOptions['take'],
            page_no: pageNo,
            search_key: 'operation_line_code,operation_line_name',
            search_text: searchText.current,
        };

        const res = await errorHandler(getOperationLineAPI, param);
        if (res) {
            return {
                data: res.data.o_data,
                totalCount: res.data.count,
            };
        }

        return {
            data: [],
            totalCount: 0,
        };
    };

    const dropdown = <DropdownField label={translation.select_factory} isSearchable={false} value={curFactory} dataSource={factoryDropdown} onValueChanged={onDropdownChanged} valueExpr="operation_factory_id" displayExpr="operation_factory_name" />;

    const filter = <FilterComponent label={translation.filter} width={204} onFilter={filtering} />;

    return (
        <Popup width={1100} height={850} visible={visible} hideOnOutsideClick={true} onHiding={close} onInitialized={init} dragEnabled={true} position="center" showTitle={false}>
            <div className="popup-select-equipment">
                <div className="popup-header">
                    <p className="popup-title">{translation.select_line}</p>
                </div>
                <div className="popup-body">
                    <div className="selected-item-viewer">
                        <div className="icon-checked">
                            <i></i>
                        </div>
                        <div className="selected-item-field">
                            {selectedRows.length > 0 && (
                                <>
                                    <ul className="selected-items">
                                        {selectedRows.map((e, i) => (
                                            <li key={i} className="item">
                                                <p className="item-name">{e.operation_line_name}</p>
                                                <i className="material-icons btn-delete" onClick={() => removeTag(i)}>
                                                    cancel
                                                </i>
                                            </li>
                                        ))}
                                    </ul>
                                    <div className="btn-all-delete" onClick={removeAllTags}>
                                        <button>{translation.delete_all}</button>
                                    </div>
                                </>
                            )}
                            {selectedRows.length == 0 && <p className="comment-not-selected">{translation.select_equipment_notice}</p>}
                        </div>
                    </div>
                    <div className="item-select-box">
                        <ScrollView width="100%">
                            <div className="container-wrapper">
                                <div className="container">
                                    <BaseDataGrid ref={gridRef} toolbarModules={[dropdown, filter]} dataSource={gridData} allowModify={false} onSelectionChanged={onRowSelected} selectMode="single" isRemote={true} headers={headers} />
                                </div>
                            </div>
                        </ScrollView>
                    </div>
                </div>
                <div className="popup-footer">
                    <Button width={86} text={translation.cancel} type="normal" stylingMode="text" className="btn-s-r" onClick={close} />
                    <Button text={translation.confirm} width={86} type="default" stylingMode="contained" className="btn-s-r" onClick={ok} disabled={okBtnDisabled} />
                </div>
            </div>
        </Popup>
    );
}
