import Button from 'devextreme-react/button';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { Popup } from 'devextreme-react/popup';
import { SelectBox } from 'devextreme-react/select-box';
import TextBox from 'devextreme-react/text-box';
import React, { useRef, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    getDepartmentDt,
    getUserDTInfoBySearch,
    getSecurityBusinessByCompanyId,
    getSecurityMasterInfoById,
    getSecurityMasterSelectedUserInfoById,
    getSecurityMasterUserInfoById,
    getUserInfoByDepartmentId,
    insertSecurityMasterInfoByCompanyId,
    insertSecurityMasterSetting,
    updateSecurityMasterByCompanyId,
} from '../../apiInterface/securityManagement/SecurityGroupAPI';
import { getIP, getLanguage, getMenuMappingInfo, getUser } from '../../apiInterface/utils/Common';
import InlineDataGrid from '../../components/common/InlineDataGrid';
import { swal, useSwal } from '../../components/common/Swal';
import CustomTreeList from '../../components/common/TreeList';
import useTranslation from '../../components/customHooks/translations';
import '../../contents/styles/security/security-group-form.css';
import { actionDirect, moveFocus, submit } from '../../components/common/FormFuncWithEnter';
import { SearchableField, TextboxField, DatePickerField, FileField, DropdownField, BaseField } from '../../components/common/FormField';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import { PageTitle } from '../../components/common/PageTitle.jsx';
import TextArea from 'devextreme-react/text-area';
import { ScrollView } from 'devextreme-react';
import Search from '../../components/common/Search.jsx';
import BaseDataGrid from '../../components/common/BaseDataGrid';
import SelectDeptAndUserPopup from './SelectDeptAndUserPopup';

const SecurityGroupForm = () => {
    const translation = useTranslation();
    const [userDataSource, setUserDataSource] = useState([]);
    const [isSearchUserPopupVisible, setSearchUserPopupVisible] = useState(false);
    const [isAddUserPopupVisible, setAddUserPopupVisible] = useState(false);
    const [data, setData] = useState({});
    const { state } = useLocation();
    const navigate = useNavigate();
    const location = useLocation();
    const { title, businessId } = location.state;
    const URL = '/SecurityGroup';
    const errorHandler = useErrorHandler();
    const { success, fail } = useSwal();

    const useItems = [
        { value: true, title: translation.use },
        { value: false, title: translation.notUse },
    ];

    const customCellRender = (e) => {
        if (e.text === 'user_master') {
            return <>{translation.user}</>;
        } else if (e.text === 'user_department') {
            return <>{translation.dept}</>;
        }
    };

    const previewUserDepartmentHeaderInfo = [
        { dataSource: 'target_no', caption: translation.security_master_target_no, dataType: 'number', alignment: 'center' },
        { dataSource: 'target_name', caption: translation.security_master_target_name, dataType: 'string' },
        { dataSource: 'user_business_name', caption: translation.security_group_business, dataType: 'string' },
        { dataSource: 'target_table', caption: translation.security_master_target_table, dataType: 'string', cellRender: customCellRender },
    ];

    const valueChanged = async (value, key) => {
        setData((cur) => {
            let newData = { ...cur };
            newData[key] = value;
            return newData;
        });
    };

    const renderSearchUserPopup = () => {
        return (
            <SearchUserPopup
                data={data}
                setValue={(val) => {
                    valueChanged(val.length > 0 ? val[0].searchTxt : null, 'searchTxt');
                }}
                setVisible={setSearchUserPopupVisible}
                setUserDataSource={setUserDataSource}
                userDataSource={userDataSource}
                visible={isSearchUserPopupVisible}
            />
        );
    };

    const insertSecurityGroup = async () => {
        if (checkUserExist()) {
            const { id: menuId } = await getMenuMappingInfo();
            const userInfo = getUser();
            const ipAddress = getIP();
            const queryData = {
                user_company_id: userInfo.user_company_id,
                user_business_id: businessId,
                security_master_name: data.security_master_name,
                security_master_is_active: data.security_master_is_active,
                security_master_comment: data.security_master_comment,
                user_master_email: userInfo.user_master_email,
                user_log_ip: ipAddress,
                user_master_id: userInfo.user_master_id,
                user_menu_mapping_id: menuId,
            };

            const res = await errorHandler(insertSecurityMasterInfoByCompanyId, queryData);
            if (res) {
                await valueChanged(res.data.o_data[0].security_master_id, 'security_master_id');
                await insertUserAndDepartmentInfo(res.data.o_data[0].security_master_id);
                const text = `<p>${translation.success_insert}</p>`;
                success(text, exitForm);
            }
        } else {
            const text = `<p>${translation.security_master_no_user}</p>`;
            fail(text, () => {
                return;
            });
            // console.log(`${translation.security_master_no_user}`);
            return;
        }
    };

    const insertUserAndDepartmentInfo = async (securityMasterId) => {
        userDataSource.forEach(async (val) => {
            const queryData = {
                security_master_id: securityMasterId,
                target_table: val.target_table,
                target_id: val.target_id,
            };
            const res = await errorHandler(insertSecurityMasterSetting, queryData);
            if (res) {
                // if success
            }
        });
    };

    const updateSecurityGroup = async () => {
        if (checkUserExist()) {
            const { id: menuId } = await getMenuMappingInfo();
            const userInfo = getUser();
            const ipAddress = getIP();
            const queryData = {
                security_master_id: data.security_master_id,
                user_company_id: userInfo.user_company_id,
                user_business_id: data.user_business_id,
                security_master_name: data.security_master_name,
                security_master_is_active: data.security_master_is_active,
                security_master_comment: data.security_master_comment,
                user_master_email: userInfo.user_master_email,
                user_log_ip: ipAddress,
                user_master_id: userInfo.user_master_id,
                user_menu_mapping_id: menuId,
            };

            const res = await errorHandler(updateSecurityMasterByCompanyId, queryData);
            if (res) {
                await insertUserAndDepartmentInfo(data.security_master_id);
                const text = `<p>${translation.success_update}</p>`;
                success(text, exitForm);
            }
        } else {
            const text = `<p>${translation.security_master_no_user}</p>`;
            console.log(`${translation.security_master_no_user}`);
            return;
            // fail(text, () => {
            //     return;
            // });
        }
    };

    const getData = async () => {
        const language = getLanguage();
        const queryData = {
            security_master_id: state.rowData.security_master_id,
            sys_lang_code: language,
        };
        const res = await errorHandler(getSecurityMasterInfoById, queryData);
        if (res) {
            const rowData = res.data.o_data[0];
            setData(rowData);
            valueChanged(state.rowData.security_master_id, 'security_master_id');
        }
    };

    const getUserData = async () => {
        const language = getLanguage();
        const queryData = {
            source_table_name: 'security_master',
            source_table_id: state.rowData.security_master_id,
            sys_lang_code: language,
        };
        const res = await errorHandler(getSecurityMasterUserInfoById, queryData);
        if (res) {
            const rowData = res.data.o_data;
            setUserDataSource(rowData);
        }
    };

    const checkUserExist = () => {
        if (userDataSource.length > 0) return true;
        else return false;
    };

    useEffect(() => {
        const init = async () => {
            if (state.title == 'Edit') {
                await getData();
                await getUserData();
            } else {
                valueChanged(useItems[0].value, 'security_master_is_active');
            }
        };
        init();
    }, []);

    //move to /SecurityGroup and pass business group id to restore previous state.
    const exitForm = () => {
        const previousId = location.state.businessId;
        let params = { state: { businessId: previousId } };
        navigate(URL, params);
    };

    const onAddDeptAndUser = (e) => {
        console.log(e);
    };

    return (
        <>
            <div className="security-group-form">
                <div className="right-content-title">
                    <PageTitle pageState={title} />
                </div>
                <form onSubmit={actionDirect}>
                    <div className="right-content-body">
                        <div className="container">
                            <div className="dx-fieldset">
                                <TextboxField
                                    label={translation.security_master_name}
                                    value={data?.security_master_name}
                                    onValueChanged={(e) => valueChanged(e.value, 'security_master_name')}
                                    isEssential={true}
                                    essentialMsg={translation.security_master_name + translation.is_required}
                                    onEnterKey={moveFocus}
                                    inputAttr={{ focusidx: 2 }}
                                />

                                <DropdownField
                                    label={translation.use_or_not}
                                    isEssential={true}
                                    essentialMsg={translation.use_or_not + translation.is_required}
                                    dataSource={useItems}
                                    value={data?.security_master_is_active}
                                    valueExpr="value"
                                    displayExpr="title"
                                    onValueChanged={(e) => valueChanged(e.value, 'security_master_is_active')}
                                    onEnterKey={moveFocus}
                                    inputAttr={{ focusidx: 8 }}
                                    isSearchable={false}
                                />

                                {/*<TextboxField*/}
                                {/*    label={translation.security_master_comment}*/}
                                {/*    value={data?.security_master_comment}*/}
                                {/*    onValueChanged={(e) => valueChanged(e.value, "security_master_comment")}*/}
                                {/*    isEssential={false}*/}
                                {/*    onEnterKey={moveFocus}*/}
                                {/*    inputAttr={{ focusidx: 6 }}*/}
                                {/*/>*/}

                                <div className="dx-field">
                                    <div className="dx-field-label">
                                        <p>{translation.security_master_comment}</p>
                                    </div>
                                    <div className="dx-field-value">
                                        <TextArea value={data?.security_master_comment} onValueChanged={(e) => valueChanged(e.value, 'security_master_comment')} isEssential={false} onEnterKey={moveFocus} inputAttr={{ focusidx: 6 }} />
                                    </div>
                                </div>
                            </div>
                            <div className="block"></div>
                            <div className="data-grid">
                                <div className="dx-field column">
                                    <div className="dx-field-label">
                                        <p className="essential">{translation.security_group}</p>
                                    </div>
                                    <div className="dx-field-value">
                                        <div className="data-grid-toolbar">
                                            <SearchableField
                                                isEssential={false}
                                                essentialMsg={translation.item_master_code + translation.is_required}
                                                onSearch={() => setSearchUserPopupVisible(true)}
                                                text={data?.searchTxt}
                                                onValueChanged={(e) => {
                                                    valueChanged(e, 'searchTxt');
                                                }}
                                            />
                                        </div>
                                        <InlineDataGrid
                                            dataSource={userDataSource}
                                            headers={previewUserDepartmentHeaderInfo}
                                            isSelect={true}
                                            isEdit={false}
                                            isDelete={true}
                                            isAdd={true}
                                            selectMode={'multiple'}
                                            defaultPageSize={10}
                                            isDataDelete={false}
                                            isPaging={false}
                                            isDoubleClicked={false}
                                            onAddClicked={() => setAddUserPopupVisible(true)}
                                            addText={translation.addUser}
                                            addBtnType={'normal'}
                                            addBtnWidth={118}
                                            width={672}
                                        />
                                        <p className="info-comment">
                                            <span className="material-icons-outlined">info</span>
                                            {translation.security_master_notice}
                                        </p>
                                    </div>
                                </div>
                                <div className="block"></div>
                                {location.state.title == 'Edit' && (
                                    <>
                                        <div className="dx-fieldset write-info">
                                            <div className="dx-field">
                                                <div className="dx-field-label">{translation.lastModifiedDate}</div>
                                                <div className="dx-field-value">{data?.modified_date}</div>
                                            </div>
                                            <div className="dx-field">
                                                <div className="dx-field-label">{translation.lastModifiedBy}</div>
                                                <div className="dx-field-value">
                                                    {data?.modified_by_name}
                                                    {data?.modified_by_duty && <span className="badge badge-job-position">{data?.modified_by_duty}</span>}
                                                    {data?.modified_by_department && <span className="badge badge-team">{data?.modified_by_department}</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="block"></div>
                                    </>
                                )}
                                <div className="btn-group" style={{ width: '696px' }}>
                                    <Button type="normal" stylingMode="text" className="btn-s-r" onClick={() => exitForm()}>
                                        <div>
                                            <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                            <span className="dx-button-text">{translation.cancel}</span>
                                        </div>
                                    </Button>
                                    <Button
                                        type="default"
                                        stylingMode="contained"
                                        className="btn-s-r"
                                        useSubmitBehavior={true}
                                        onClick={(e) => {
                                            if (title === 'Add') submit(e, insertSecurityGroup);
                                            else if (title === 'Edit') submit(e, updateSecurityGroup);
                                        }}
                                    >
                                        <div>
                                            <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                            <span className="dx-button-text">{translation.save}</span>
                                        </div>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <SelectDeptAndUserPopup
                    visible={isAddUserPopupVisible}
                    onHiding={() => setAddUserPopupVisible(false)}
                    onConfirm={onAddDeptAndUser}
                    userDataSource={userDataSource}
                    setUserDataSource={setUserDataSource}
                    businessId={businessId}
                />
                {/* <SelectDeptAndUserPopup
                    data={data}
                    setValue={(val) => {
                        valueChanged(val.length > 0 ? val[0].searchTxt : null, 'searchTxt');
                    }}
                    setVisible={setAddUserPopupVisible}
                    setUserDataSource={setUserDataSource}
                    userDataSource={userDataSource}
                    businessId={businessId}
                    visible={isAddUserPopupVisible}
                /> */}
                <Popup
                    width={1100}
                    height={850}
                    visible={isSearchUserPopupVisible}
                    hideOnOutsideClick={true}
                    onHiding={() => setSearchUserPopupVisible(false)}
                    contentRender={renderSearchUserPopup}
                    dragEnabled={true}
                    position="center"
                    showTitle={false}
                ></Popup>
            </div>
        </>
    );
};

export default SecurityGroupForm;

const SearchUserPopup = (props) => {
    const translation = useTranslation();
    const user = getUser();
    const language = getLanguage();
    const errorHandler = useErrorHandler();
    const { visible, setVisible, setValue, data, userDataSource, setUserDataSource } = props;
    const [dataSource, setDataSource] = useState([]);
    const [selectedValue, setSelectedValue] = useState([]);
    const gridRef = useRef();
    const customCellRender = (e) => {
        if (e.text === 'user_master') {
            return <>{translation.user}</>;
        } else if (e.text === 'user_department') {
            return <>{translation.dept}</>;
        }
    };
    const previewUserDepartmentHeaderInfo = [
        { dataField: 'target_no', caption: translation.security_master_target_no, dataType: 'number', alignment: 'center' },
        { dataField: 'target_name', caption: translation.security_master_target_name, dataType: 'string' },
        { dataField: 'user_business_name', caption: translation.security_group_business, dataType: 'string' },
        { dataField: 'target_table', caption: translation.security_master_target_table, dataType: 'string', cellRender: customCellRender },
    ];

    useEffect(() => {
        if (data?.searchTxt) {
            getUserInfoBySearch();
        }
    }, [visible]);

    const onClickSearch = () => {
        getUserInfoBySearch();
    };

    const onSearchUserSelectedChanged = (e) => {
        setSelectedValue(e);
    };

    const getUserInfoBySearch = async () => {
        const param = {
            user_company_id: user.user_company_id,
            sys_lang_code: language,
            search_text: data?.searchTxt,
        };
        const res = await errorHandler(getUserDTInfoBySearch, param);
        if (res) {
            setDataSource(res.data.o_data);
        }
    };

    const makeTable = () => {
        // drop duplicate
        let duplicatedArray = [...userDataSource, ...selectedValue];
        duplicatedArray = duplicatedArray.filter((arr, index, callback) => index === callback.findIndex((t) => t.target_id === arr.target_id));
        setUserDataSource((prevArr) => {
            let copy = [...duplicatedArray];
            for (let i = 0; i < copy.length; i++) {
                copy[i].target_no = copy.length - i;
            }
            return copy;
        });
    };

    const reset = () => {
        setDataSource([]);
        setSelectedValue([]);
    };

    const valueChanged = async (value, key) => {
        setData((cur) => {
            let newData = { ...cur };
            newData[key] = value;
            return newData;
        });
    };

    //const search = <Search
    //    onClick={(value) => {
    //        onClickSearch();
    //    }}
    //    value={data?.searchTxt}
    //    onValueChanged={(value) => {
    //        setValue([{ searchTxt: value }])
    //    }}
    ///>

    const removeTag = (i) => {
        let cur = [...selectedValue];
        let removed = cur.splice(i, 1);
        setSelectedValue(cur);

        const instance = gridRef.current.getInstance();
        instance.deselectRows(removed);
    };

    const removeAllTags = () => {
        setSelectedValue([]);

        const instance = gridRef.current.getInstance();
        instance.deselectAll();
    };

    return (
        <div className="popup-search-user">
            <div className="popup-header">
                <p className="popup-title">{translation.select_user}</p>
            </div>
            <div className="popup-body">
                <div className="selected-item-viewer">
                    <div className="icon-checked">
                        <i></i>
                    </div>
                    <div className="selected-item-field">
                        {selectedValue.length > 0 && (
                            <>
                                <ul className="selected-items">
                                    {selectedValue.map((e, i) => (
                                        <li key={i} className="item">
                                            <p className="item-name">{e.target_name}</p>
                                            <i className="material-icons btn-delete" onClick={() => removeTag(i)}>
                                                cancel
                                            </i>
                                        </li>
                                    ))}
                                </ul>
                                <div className="btn-all-delete" onClick={removeAllTags}>
                                    <button>Delete All</button>
                                </div>
                            </>
                        )}
                        {selectedValue.length == 0 && <p className="comment-not-selected">{translation.select_user_notice}</p>}
                    </div>
                </div>
                <div className="item-select-box">
                    <ScrollView>
                        <div className="container-wrapper">
                            <div className="container">
                                <Search
                                    onClick={onClickSearch}
                                    value={data?.searchTxt}
                                    onValueChanged={(e) => {
                                        setValue([{ searchTxt: e.currentTarget.value }]);
                                    }}
                                />
                                <BaseDataGrid
                                    dataSource={dataSource}
                                    headers={previewUserDepartmentHeaderInfo}
                                    onSelectionChanged={onSearchUserSelectedChanged}
                                    allowModify={false}
                                    isSelect={true}
                                    selectMode={'multiple'}
                                    defaultPageSize={10}
                                    isPaging={true}
                                    ref={gridRef}
                                />
                            </div>
                        </div>
                    </ScrollView>
                </div>
            </div>
            <div className="popup-footer">
                <Button
                    width={86}
                    text={translation.cancel}
                    type="normal"
                    stylingMode="text"
                    className="btn-s-r"
                    onClick={() => {
                        setVisible(false);
                        reset();
                    }}
                />
                <Button
                    text={translation.confirm}
                    width={86}
                    type="default"
                    stylingMode="contained"
                    className="btn-s-r"
                    onClick={(e) => {
                        makeTable();
                        setVisible(false);
                        reset();
                    }}
                />
            </div>
        </div>
    );
};
