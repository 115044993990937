﻿import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getLanguage, getServiceMappingInfo, getUser, moveService, removeMenuMappingInfo, removeServiceMappingInfo, removeUserSession } from '../../apiInterface/utils/Common';
import { swal, swalConfirmation } from '../../components/common/Swal';
import useTranslation from '../../components/customHooks/translations';
import '../../contents/styles/home/dashboard.css';
import Button from 'devextreme-react/button';
import { getMenuMappingServiceAPI } from '../../apiInterface/menu/MenuAPI';
import { getMainDashboardSummary } from '../../apiInterface/home/DashboardAPI';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import useSwal from '../../components/common/Swal';
import { PageTitle } from '../../components/common/PageTitle.jsx';
import { BASE_URL } from '../../config';

const Dashboard = (props) => {
    const [dashboardSummary, setDashboardSummary] = useState();
    const [serviceMenu, setServiceMenu] = useState();
    const translation = useTranslation();
    const navigate = useNavigate();
    const serviceCaption = getServiceMappingInfo()?.caption;
    const errorHandler = useErrorHandler();
    /*const pageTitle = `${serviceCaption} ${menuCaption}`;*/

    // handle click event of logout button
    const handleLogout = () => {
        var iconType = 'bad';
        var swalWidth = '380';
        var htmlString = `<span>${translation.alertSignOut}</span>`;
        var confirmationButtonText = translation.confirm;
        var cancelButtonText = translation.cancel;
        var swalResFn = (res) => {
            if (res.isConfirmed) {
                // removeUserSession();
                removeUserLocal();
                removeServiceMappingInfo();
                removeMenuMappingInfo();
                navigate('/login');
            }
        };
        swalConfirmation(swalWidth, iconType, htmlString, confirmationButtonText, cancelButtonText, swalResFn);
    };

    const getDashboardSummary = async () => {
        const user = getUser();
        const lang = getLanguage();
        const param = {
            user_company_id: user.user_company_id,
            sys_lang_code: lang,
        };

        const res = await errorHandler(getMainDashboardSummary, param);
        if (res) {
            const summary = res.data.o_data[0];
            setDashboardSummary(summary);
        }
    };

    const getServiceMenu = async () => {
        const user = getUser();
        const lang = getLanguage();
        const params = {
            user_company_id: user.user_company_id,
            sys_lang_code: lang,
        };

        const res = await errorHandler(getMenuMappingServiceAPI, params);
        if (res) {
            setServiceMenu(res.data.o_data);
            console.log('mapping menu', res.data.o_data);
        }
    };

    const addService = () => {
        var iconType = 'good';
        var swalWidth = '380';
        var htmlString = `<span>${translation.comingSoon}</span>`;
        var confirmationButtonText = translation.confirm;
        var swalResFn = (res) => {};
        swal(swalWidth, iconType, htmlString, confirmationButtonText, swalResFn);
    };

    useEffect(() => {
        getDashboardSummary();
        getServiceMenu();
    }, []);

    return (
        <>
            <div className="dx-swatch-ix-pds dashboard">
                <div className="right-content-title">
                    <PageTitle />
                </div>
                <div className="right-content-body">
                    <div className="card company-info">
                        <div className="card-header">
                            <h6 className="card-title">{translation.dashboardCompanyInfo}</h6>
                            <Button
                                icon="edit"
                                type="normal"
                                stylingMode="text"
                                className="btn-s-xs"
                                onClick={() => {
                                    //props.setMappingId(0);
                                    //props.setChangeState(Date.now());
                                    //navigate("/CompanyManagementInfo")
                                    document.querySelector('.sidebar-content [link="CompanyManagementInfo"]').click();
                                }}
                            />
                        </div>
                        <div className="card-body">
                            <div className="company-info-list">
                                <div className="company-name">
                                    <p className="text-color-gray">{translation.companyName}</p>
                                    <h3>{dashboardSummary?.user_company_name}</h3>
                                </div>
                                <div className="company-logo">
                                    {dashboardSummary?.user_company_logo !== '' && <img className="fileuploader-image" src={`${BASE_URL}static_files/${dashboardSummary?.user_company_logo}`} />}
                                    {/* <img className="fileuploader-image" src={`http://server.interxlab.io:5680/${dashboardSummary?.user_company_logo}`} /> */}
                                    {/* <img className="fileuploader-image" src={`${BASE_URL}static_files/${dashboardSummary?.user_company_logo}`} /> */}
                                </div>
                                <div className="business-registration">
                                    {/*<p className="text-color-gray">Certification of Business Registration</p>*/}
                                    <p className="text-color-gray">{translation.companyRegNo}</p>
                                    <h6>{dashboardSummary?.user_company_registerno}</h6>
                                </div>
                                <div className="company-owner">
                                    <p className="text-color-gray">{translation.ceo}</p>
                                    <h6>{dashboardSummary?.user_company_owner}</h6>
                                </div>
                                <div className="company-address">
                                    <p className="text-color-gray">{translation.dashbaordAddress}</p>
                                    {/*<h6>Hyeogsin the terrace 10F /7, Jongga 6-gil, Jung-gu, Ulsan, Republic of Korea</h6>*/}
                                    <h6>
                                        {dashboardSummary?.user_company_address} {dashboardSummary?.user_company_address2}
                                    </h6>
                                </div>
                                <div className="company-tel">
                                    <p className="text-color-gray">{translation.contactNo}</p>
                                    <h6>{dashboardSummary?.user_company_phone}</h6>
                                </div>
                                {/*<div className="company-start">*/}
                                {/*    <p className="text-color-gray">{translation.startDate}</p>*/}
                                {/*    <h6>2022-08-20</h6>*/}
                                {/*</div>*/}
                                {/*<div className="company-update">*/}
                                {/*    <p className="text-color-gray">{translation.updateDate}</p>*/}
                                {/*    <h6>2022-08-22</h6>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                    <div className="ix-usage">
                        <div className="card usage-statistics">
                            <div className="card-header">
                                <h6 className="card-title">{translation.dashboardUsageStatistics}</h6>
                            </div>
                            <div className="card-body">
                                <div>
                                    <p className="text-color-gray">Created</p>
                                    <h4>{translation.dashboardBusiness}</h4>
                                    <span>{dashboardSummary?.business_count}</span>
                                </div>
                                <div>
                                    <p className="text-color-gray">Created</p>
                                    <h4>{translation.dashboardDepartment}</h4>
                                    <span>{dashboardSummary?.department_count}</span>
                                </div>
                                <div>
                                    <p className="text-color-gray">Active</p>
                                    <h4>{translation.dashboardMembers}</h4>
                                    <span>{dashboardSummary?.user_count}</span>
                                </div>
                                <div>
                                    <p className="text-color-gray">Created</p>
                                    <h4>{translation.dashboardAIModel}</h4>
                                    <span>{dashboardSummary?.ai_model_count}</span>
                                </div>
                            </div>
                        </div>
                        <div className="card connected-ai">
                            <div className="card-header">
                                <h6 className="card-title">{translation.dashboardConnectedAI}</h6>
                            </div>
                            <div className="card-body">
                                <ul className="apps">
                                    {serviceMenu?.map((menu, i) => (
                                        <li
                                            key={i}
                                            className={`btn-app-${menu.template_master_type} ${!['framework', 'digitaltwin'].includes(menu.template_master_type) && 'badge-ai'}`}
                                            onClick={(e) => {
                                                moveService(menu);
                                            }}
                                        >
                                            <div>
                                                <div></div>
                                            </div>
                                            <span>{menu.menu_mapping_caption}</span>
                                        </li>
                                    ))}
                                    <li className="btn-app-add" onClick={(e) => addService()}>
                                        <div>
                                            <div></div>
                                        </div>
                                        <span>{translation.addApp}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Dashboard;
