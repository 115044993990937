import React, { useState } from 'react';
import { Button, Popup } from 'devextreme-react';
import DraggableTreeList from '../../components/common/DraggableTreeList';
import useTranslation from '../../components/customHooks/translations';
import TemplatemasterEditPopup from './TemplateMasterEditPopup';
import {
    deleteTemplateCategory,
    getMainTemplateCategoryListAPI,
    updateTemplateCategorySeq,
} from '../../apiInterface/templateManagment/TemplateManagementAPI';
import { getIP, getLanguage, getMenuMappingInfo, getUser } from '../../apiInterface/utils/Common';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import { useRef } from 'react';
import Badge from '../../components/common/Badge';
import { failToastr, successToastr } from "../../components/common/Toastr";
import { useEffect } from 'react';

let isCancelButtonClicked = false;// prevent executing onExit function twice.
export default function TemplateMasterSettingPopup(props) {
    const {
        visible = false,
        onExit: exitCallback = () => { },
        onOk: okCallback = () => { },
    } = props;

    const [editPopup, setEditPopup] = useState(false);
    const [editProp, setEditProp] = useState({});
    const [treeData, setTreeData] = useState([]);
    const [hideOnOutsideClick, setHideOnOutsideClick] = useState(true);
    const treeListRef = useRef();
    const translation = useTranslation();
    const errorHandler = useErrorHandler();
    const user = getUser();
    const { id: menuMappingId } = getMenuMappingInfo();

    const header = [
        { dataField: "name", caption: translation.template_category.toUpperCase(), allowReordering: true },
        {
            dataField: "state", caption: translation.state.toUpperCase(), allowReordering: true,
            width: 130, alignment: "center",
            cellRender: (e) => <Badge
                className={e.value ? "grid-badge-use" : "grid-badge-disabled"}
                text={e.value ? translation.active : translation.not_active}
            />
        }
    ];

    useEffect(() => {
        getMainTemplateCategoryList();
    }, [visible])

    const getMainTemplateCategoryList = async () => {
        const language = getLanguage();

        let param = {
            user_company_id: user.user_company_id,
            sys_lang_code: language
        }

        const res = await errorHandler(getMainTemplateCategoryListAPI, param);
        if (res) {
            const transformed = res.data.o_data.map((d) => ({
                id: d.template_category_id,
                name: d.template_category_name,
                parentId: d.template_category_parent_id,
                state: d.template_category_is_active,
                icon: 'activefolder'
            }));
            setTreeData(transformed);
        }
    }

    const prepareEdit = (state) => {
        setEditProp({
            subtitle: state.title,
            path: state.path,
            itemId: state.itemId,
        });
        setEditPopup(true);
    }

    const removeCategory = async (item) => {
        const data = item.data;
        let param = {
            user_master_id: user.user_master_id,
            user_log_ip: getIP(),
            menu_mapping_id: menuMappingId,
            item_category_id: data.id
        }

        const res = await errorHandler(deleteTemplateCategory, param);
        if (res) {
            //reload treeList data
            await getMainTemplateCategoryList();
        }
    }

    const onExit = (e) => {
        const instance = treeListRef.current.getInstance();
        instance.deselectAll();
        treeListRef.current.reset();
        exitCallback(e);
    }

    const onOk = (e) => {
        e.event.preventDefault();
        okCallback(e);
        onExit(e);
    }

    const onCancel = (e) => {
        e.event.preventDefault();
        isCancelButtonClicked = true;
        onExit(e);
    }

    const onHiding = (e) => {
        if (!isCancelButtonClicked)
            onExit(e);
        isCancelButtonClicked = false;
    }

    const updateRowSequence = async (deptId, parentId, topId) => {
        setHideOnOutsideClick(false);
        let param = {
            menu_mapping_id: menuMappingId,
            template_category_id: deptId,
            template_category_parent_id: parentId,
            template_category_top_id: topId,
        }

        const res = await updateTemplateCategorySeq(param);
        if (res !== 'fail' && res.message === 'MSG_100') {
            successToastr(translation.success_update_seq);
            setHideOnOutsideClick(true);
        } else if (res !== 'fail' && res.message === 'MSG_152') {
            failToastr(translation[res.message]);
            setHideOnOutsideClick(true);
            getMainTemplateCategoryList();
        } else {
            failToastr(translation.MSG_103);
            getMainTemplateCategoryList();
            setHideOnOutsideClick(true);
        }
    }

    const onReorder = async (item) => {
        let deptId = item.id;
        let parentId = item.parentId;
        let topId = item.upperNode;
        updateRowSequence(deptId, parentId, topId);
    }

    return (
        <Popup
            width={520}
            height="auto"
            visible={visible}
            hideOnOutsideClick={hideOnOutsideClick}
            onHiding={onHiding}
            onInitialized={getMainTemplateCategoryList}
            dragEnabled={true}
            position="center"
            showTitle={false}
        >
            <div className="popup-template-setting">
                <div className="popup-header">
                    <p className="popup-title">{translation.template_classification} {translation.setting}</p>
                </div>
                <div className="popup-body">
                    <div>
                        <div>
                            <DraggableTreeList
                                ref={treeListRef}
                                dataSource={treeData}
                                keyExpr="id"
                                displayExpr="name"
                                parentIdExpr="parentId"
                                headers={header}
                                onEdit={(val) => prepareEdit(val)}
                                onAdd={(val) => prepareEdit(val)}
                                isRemote={true}
                                onRowRemoving={removeCategory}
                                allowReordering={true}
                                onReorder={onReorder}
                                beforeRemove={() => setHideOnOutsideClick(false)}
                                afterRemove={() => setHideOnOutsideClick(true)}
                            />
                        </div>
                        <div>
                            <p className="info-comment"><span className="material-icons-outlined">info</span>{translation.select_a_row_to_edit}</p>
                        </div>
                    </div>
                </div>
                <div className="popup-footer">
                    <Button
                        width={86}
                        text={translation.cancel}
                        type="normal"
                        stylingMode="text"
                        className="btn-s-r"
                        onClick={onCancel}
                    />
                    <Button
                        text={translation.confirm}
                        width={86}
                        type="default"
                        stylingMode="contained"
                        className="btn-s-r"
                        onClick={onOk}
                    />
                </div>
                <TemplatemasterEditPopup
                    visible={editPopup}
                    // hideOnOutsideClick={hideOnOutsideClick}
                    onHiding={() => setEditPopup(false)}
                    dragEnabled={true}
                    subtitle={editProp?.subtitle}
                    path={editProp?.path}
                    itemId={editProp?.itemId}
                    onExit={(btnType) => {
                        getMainTemplateCategoryList();
                        setEditPopup(false);
                        if (btnType === "save") onExit();
                    }}
                />
            </div>
        </Popup>
    )
}