import * as echarts from "echarts";

// if  method return chart then you can resize each chart.
// using this
// --------------------------------------------------------------------------------------------
// - const line = LineChart({ x: lineXData, y: lineYData, legend: lineLegend, id: "line" });  -
// - window.onresize = function () {                                                          -
// -    line.resize();                                                                        -
// - };                                                                                       -
// --------------------------------------------------------------------------------------------

const handleChartClick = (params) => {
    alert("test")
    
    if (params.componentType === 'series') {
      const dataIndex = params.dataIndex; // Index of the clicked data point
      const dataValue = params.data; // Index of the clicked data point
      // Handle the click event based on the dataIndex or any other parameters you need
      console.log('Clicked data index:', dataIndex);
      console.log('Clicked data value:', dataValue);
    }
  };

const init = (id, option) => {
 
    const chart = echarts.init(document.querySelector(id), null, { renderer: 'svg' });
    chart.setOption(option, true);
    chart.getZr().on("dblclick", (params) => {
        chart.dispatchAction({
            type: 'dataZoom',
            start: 0,
            end: 100
        })
    });
    chart.on('click', option.clickEvent);//to add click event
    chart.dispatchAction({
        type: 'takeGlobalCursor',
        key: 'dataZoomSelect',
        dataZoomSelectActive: option.zoomEvt,
    });

    if (option.h !== undefined)
        chart.resize({ width: option.w, height: option.h })

    return chart;
}
//Custom Template
export const chartResize = (chart, w, h) => {
    if (w < 0 || h < 0) return;
    chart.resize({ width: w, height: h });
}

export const LineChart = (
    {
        id = "",
        x = [],
        y = [],
        legend = [],
        tooltip = {
            trigger: 'axis'
        },
        axisType = {
            x: ['category'],
            y: ['value']
        },
        boundaryGap = {
            x: [0, '%10'],
            y: [0, '%10']
        },
        area = {
            idx: null,
            data: []
        },
        smooth = false,
        xAxisName = [],
        yAxisName = [],
        color = [],
        zoomEvt = true,
        w = null,
        h = null,
        backgroundColor = "",
        
    },
    getOnlyOption = false, // for multi type chart
) => {

    // Make json type data structure
    // y : data
    // area : for chart area
    // smooth : line smooth this type can be float or boolean value
    // legend : To match y and x data

    const makeData = (y, area, smooth, legend, color) => {
        const series = [];
        const pieces = [];
        const markLine = [];
        for (let i = 0; i < y.length; i++) {
            const colorCode = color[i];
            let seriesData = {
                name: legend[i],
                type: 'line',
                smooth: smooth,
                data: y[i],
                showSymbol: false
            }
            if (color.length) {
                seriesData = {
                    ...seriesData,
                    color: colorCode,
                    itemStyle: {
                        color: colorCode
                    },
                    lineStyle: { color: colorCode }
                };
            }
            series.push(seriesData);

        };
        for (let i = 0; i < area.data.length; i++) {
            pieces.push({
                gte: area.data[i][0],
                lte: area.data[i][1],
                color: color[area.idx]
            });
            markLine.push({
                xAxis: area.data[i][0]
            });
            markLine.push({
                xAxis: area.data[i][1]
            })
        }

        if (area.idx != null) {
            series[area.idx].lineStyle = {
                color: color[area.idx]
            };

            series[area.idx].markLine = {
                symbol: ['none', 'none'],
                label: { show: false },
                data: markLine
            }
            series[area.idx].areaStyle = {}
        }


        return { areaPieces: pieces, series: series };
    }

    // Make json type Axis structure
    // axisType : Type of data
    // boundaryGap : the space between elements
    // xArr : x data array

    const makeAxis = (axisType, boundaryGap, xArr, xAxisName, yAxisName) => {
        const xAxis = [];
        const yAxis = [];
        for (let i = 0; i < xArr.length; i++) {
            let xAxisData = {
                type: axisType.x[i],
                boundaryGap: boundaryGap.x,
                data: xArr[i],
                nameLocation: 'center',
                nameTextStyle: {
                    padding: [10, 0, 0, 0]
                }
            }
            if (xAxisName.length) {
                xAxisData = { ...xAxisData, name: xAxisName[i] };
            }
            xAxis.push(xAxisData);
        };
        for (let i = 0; i < axisType.y.length; i++) {
            let yAxisData = {
                max: (item) => {
                    let diff = item.max - item.min;
                    if (diff == 0) { diff = 1 }
                    return (item.max + ((diff) * 0.2)).toFixed(2);
                },
                type: axisType.y[i],
                boundaryGap: boundaryGap.y,
                nameLocation: 'center',
                nameTextStyle: {
                    padding: [0, 20, 0, 0]
                },
                scale: true
            }
            if (yAxisName.length) {
                yAxisData = { ...yAxisData, name: yAxisName[i] };
            }
            yAxis.push(yAxisData);
        };
        return { xAxis: xAxis, yAxis: yAxis }
    }


    // Make option 
    const makeOption = () => {
        const { areaPieces, series } = makeData(y, area, smooth, legend, color);
        const { xAxis, yAxis } = makeAxis(axisType, boundaryGap, x, xAxisName, yAxisName);
        let option = {
            grid: {
                left: '12px',
                right: '12px',
                bottom: '12px',
                top: '12px',
                containLabel: true
            },
            xAxis: xAxis,
            yAxis: yAxis,
            tooltip: tooltip,
            legend: {
                data: legend
            },
            series: series,
            toolbox : {
                orient   : 'vertical',
                itemSize : 13,
                top      : 15,
                right    : -6,
                feature  : {
                    dataZoom : {
                        yAxisIndex  : 'none',
                        icon        : {
                            zoom : 'path://', // hack to remove zoom button
                            back : 'path://', // hack to remove restore button
                        },
                    },
                },
            },
            zoomEvt: zoomEvt,
            w: w,
            h: h,
            backgroundColor
        };

        if (areaPieces.length) {
            option = {
                ...option, visualMap: {
                    type: 'piecewise',
                    show: false,
                    dimension: 0,
                    seriesIndex: 0,
                    pieces: areaPieces
                }
            }
        }
        return { option: option }
    }
    // Draw chart
    const { option } = makeOption();
    if (getOnlyOption) return option;
    else {
        const chart = init(id, option);
        return chart;
    }
}

export const StepLineChart = (
    {
        id = "",
        x = [],
        y = [],
        legend = [],
        tooltip = {
            trigger: 'axis'
        },
        axisType = {
            x: ['category'],
            y: ['value']
        },
        boundaryGap = {
            x: [0, '%10'],
            y: [0, '%10']
        },
        area = {
            idx: null,
            data: []
        },
        smooth = false,
        xAxisName = [],
        yAxisName = [],
        color = [],
        zoomEvt = true,
        w = null,
        h = null,
        backgroundColor = "",
    },
) => {
    const makeData = (y, area, smooth, legend, color) => {
        const series = [];
        const pieces = [];
        const markLine = [];
        for (let i = 0; i < y.length; i++) {
            const colorCode = color[i];
            let seriesData = {
                name: legend[i],
                type: 'line',
                step: legend[i], 
                smooth: smooth,
                data: y[i],
                showSymbol: false
            }
            if (color.length) {
                seriesData = {
                    ...seriesData,
                    color: colorCode,
                    itemStyle: {
                        color: colorCode
                    },
                    lineStyle: { color: colorCode }
                };
            }
            series.push(seriesData);

        };
        for (let i = 0; i < area.data.length; i++) {
            pieces.push({
                gte: area.data[i][0],
                lte: area.data[i][1],
                color: color[area.idx]
            });
            markLine.push({
                xAxis: area.data[i][0]
            });
            markLine.push({
                xAxis: area.data[i][1]
            })
        }

        if (area.idx != null) {
            series[area.idx].lineStyle = {
                color: color[area.idx]
            };

            series[area.idx].markLine = {
                symbol: ['none', 'none'],
                label: { show: false },
                data: markLine
            }
            series[area.idx].areaStyle = {}
        }


        return { areaPieces: pieces, series: series };
    }

    // Make json type Axis structure
    // axisType : Type of data
    // boundaryGap : the space between elements
    // xArr : x data array

    const makeAxis = (axisType, boundaryGap, xArr, xAxisName, yAxisName) => {
        const xAxis = [];
        const yAxis = [];
        for (let i = 0; i < xArr.length; i++) {
            let xAxisData = {
                type: axisType.x[i],
                boundaryGap: boundaryGap.x,
                data: xArr[i],
                nameLocation: 'center',
                nameTextStyle: {
                    padding: [10, 0, 0, 0]
                }
            }
            if (xAxisName.length) {
                xAxisData = { ...xAxisData, name: xAxisName[i] };
            }
            xAxis.push(xAxisData);
        };
        for (let i = 0; i < axisType.y.length; i++) {
            let yAxisData = {
                max: (item) => {
                    let diff = item.max - item.min;
                    if (diff == 0) { diff = 1 }
                    return (item.max + ((diff) * 0.2)).toFixed(2);
                },
                type: axisType.y[i],
                boundaryGap: boundaryGap.y,
                nameLocation: 'center',
                nameTextStyle: {
                    padding: [0, 20, 0, 0]
                },
                scale: true
            }
            if (yAxisName.length) {
                yAxisData = { ...yAxisData, name: yAxisName[i] };
            }
            yAxis.push(yAxisData);
        };
        return { xAxis: xAxis, yAxis: yAxis }
    }


    // Make option 
    const makeOption = () => {
        const { areaPieces, series } = makeData(y, area, smooth, legend, color);
        const { xAxis, yAxis } = makeAxis(axisType, boundaryGap, x, xAxisName, yAxisName);
        let option = {
            grid: {
                left: '12px',
                right: '12px',
                bottom: '12px',
                top: '12px',
                containLabel: true
            },
            xAxis: xAxis,
            yAxis: yAxis,
            tooltip: tooltip,
            legend: {
                data: legend
            },
            series: series,
            toolbox: {
                orient: 'vertical',
                itemSize: 13,
                top: 15,
                right: -6,
                feature: {
                    dataZoom: {
                        yAxisIndex: 'none',
                        icon: {
                            zoom: 'path://', // hack to remove zoom button
                            back: 'path://', // hack to remove restore button
                        },
                    },
                },
            },
            zoomEvt: zoomEvt,
            w: w,
            h: h,
            backgroundColor
        };

        if (areaPieces.length) {
            option = {
                ...option, visualMap: {
                    type: 'piecewise',
                    show: false,
                    dimension: 0,
                    seriesIndex: 0,
                    pieces: areaPieces
                }
            }
        }
        return { option: option }
    }
    // Draw chart
    const { option } = makeOption();
    //if (getOnlyOption ) return option;
    //else {
        const chart = init(id, option);
        return chart;
    //}
}

export const StackedAreaChart = ({
    id = "",
    x = [],
    y = [],
    legend = [],
    tooltip = {
        trigger: 'axis'
    },
    axisType = {
        x: ['category'],
        y: ['value']
    },
    boundaryGap = {
        x: [0, '%10'],
        y: [0, '%10']
    },
    area = {
        idx: null,
        data: []
    },
    smooth = false,
    xAxisName = [],
    yAxisName = [],
    color = [],
    zoomEvt = true,
    w = null,
    h = null,
    backgroundColor = "",
},
    getOnlyOption = false, // for multi type chart
) => {

    // Make json type data structure
    // y : data
    // area : for chart area
    // smooth : line smooth this type can be float or boolean value
    // legend : To match y and x data

    const makeData = (y, area, smooth, legend, color) => {
        const series = [];
        const pieces = [];
        const markLine = [];
        for (let i = 0; i < y.length; i++) {
            const colorCode = color[i];
            let seriesData = {
                name: legend[i],
                type: 'line',
                smooth: smooth,
                data: y[i],
                showSymbol: false,
                stack: 'Total',
                areaStyle: {},
            }
            if (color.length) {
                seriesData = {
                    ...seriesData,
                    color: colorCode,
                    itemStyle: {
                        color: colorCode
                    },
                    lineStyle: { color: colorCode }
                };
            }
            series.push(seriesData);

        };
        for (let i = 0; i < area.data.length; i++) {
            pieces.push({
                gte: area.data[i][0],
                lte: area.data[i][1],
                color: color[area.idx]
            });
            markLine.push({
                xAxis: area.data[i][0]
            });
            markLine.push({
                xAxis: area.data[i][1]
            })
        }

        if (area.idx != null) {
            series[area.idx].lineStyle = {
                color: color[area.idx]
            };

            series[area.idx].markLine = {
                symbol: ['none', 'none'],
                label: { show: false },
                data: markLine
            }
            series[area.idx].areaStyle = {}
        }


        return { areaPieces: pieces, series: series };
    }

    // Make json type Axis structure
    // axisType : Type of data
    // boundaryGap : the space between elements
    // xArr : x data array

    const makeAxis = (axisType, boundaryGap, xArr, xAxisName, yAxisName) => {
        const xAxis = [];
        const yAxis = [];
        for (let i = 0; i < xArr.length; i++) {
            let xAxisData = {
                type: axisType.x[i],
                boundaryGap: boundaryGap.x,
                data: xArr[i],
                nameLocation: 'center',
                nameTextStyle: {
                    padding: [10, 0, 0, 0]
                }
            }
            if (xAxisName.length) {
                xAxisData = { ...xAxisData, name: xAxisName[i] };
            }
            xAxis.push(xAxisData);
        };
        for (let i = 0; i < axisType.y.length; i++) {
            let yAxisData = {
                max: (item) => {
                    let diff = item.max - item.min;
                    if (diff == 0) { diff = 1 }
                    return (item.max + ((diff) * 0.2)).toFixed(2);
                },
                type: axisType.y[i],
                boundaryGap: boundaryGap.y,
                nameLocation: 'center',
                nameTextStyle: {
                    padding: [0, 20, 0, 0]
                },
                scale: true
            }
            if (yAxisName.length) {
                yAxisData = { ...yAxisData, name: yAxisName[i] };
            }
            yAxis.push(yAxisData);
        };
        return { xAxis: xAxis, yAxis: yAxis }
    }


    // Make option 
    const makeOption = () => {
        const { areaPieces, series } = makeData(y, area, smooth, legend, color);
        const { xAxis, yAxis } = makeAxis(axisType, boundaryGap, x, xAxisName, yAxisName);
        let option = {
            grid: {
                left: '12px',
                right: '12px',
                bottom: '12px',
                top: '12px',
                containLabel: true
            },
            xAxis: xAxis,
            yAxis: yAxis,
            tooltip: tooltip,
            legend: {
                data: legend
            },
            series: series,
            toolbox: {
                orient: 'vertical',
                itemSize: 13,
                top: 15,
                right: -6,
                feature: {
                    dataZoom: {
                        yAxisIndex: 'none',
                        icon: {
                            zoom: 'path://', // hack to remove zoom button
                            back: 'path://', // hack to remove restore button
                        },
                    },
                },
            },
            zoomEvt: zoomEvt,
            w: w,
            h: h,
            backgroundColor
        };

        if (areaPieces.length) {
            option = {
                ...option, visualMap: {
                    type: 'piecewise',
                    show: false,
                    dimension: 0,
                    seriesIndex: 0,
                    pieces: areaPieces
                }
            }
        }
        return { option: option }
    }
    // Draw chart
    const { option } = makeOption();
    if (getOnlyOption) return option;
    else {
        const chart = init(id, option);
        return chart;
    }
}

export const BarChart = ({
    tooltip = {
        trigger: 'axis',
        axisPointer: {
            type: 'shadow'
        }
    },
    x = [],
    y = [],
    direction = 'vertical',
    boundaryGap = {
        x: [0, '%10'],
        y: [0, '%10']
    },
    legend = [],
    isMultiLabel = false,
    stackType = false,
    id = "",
    xAxisName = [],
    yAxisName = [],
    color = [],
    zoomEvt = true,
    w = null,
    h = null,
    backgroundColor = ""
},
    getOnlyOption = false, // for multi type chart
) => {

    const makeData = (y, legend, stackType, color) => {
        const series = [];
        for (let i = 0; i < y.length; i++) {
            let seriesData = {
                name: legend[i],
                type: 'bar',
                data: y[i],
                stack: stackType
            }
            if (color.length) {
                seriesData = {
                    ...seriesData, itemStyle: { color: color[i] }
                };
            }
            series.push(seriesData);
        };
        return { series: series };
    }
    const makeAxis = (boundaryGap, direction, legend, x, isMultiLabel, xAxisName, yAxisName) => {
        const valueAxis = [];
        const categoryAxis = [];
        let axisData = {};
        let xAxis = [];
        let yAxis = [];
        if (isMultiLabel) {
            for (let i = 0; i < legend.length; i++) {
                axisData = {
                    type: 'value',
                    boundaryGap: boundaryGap.x,
                    max: (item) => {
                        let diff = item.max - item.min;
                        if (diff == 0) { diff = 1 }
                        return (item.max + ((diff) * 0.2)).toFixed(2);
                    },
                    nameLocation: 'center',
                    nameTextStyle: {
                        padding: [0, 20, 0, 0]
                    }
                }
                if (legend.length) {
                    axisData = {
                        ...axisData, name: legend[i]
                    };
                }
            };
        }
        else {
            axisData = {
                type: 'value',
                max: (item) => {
                    let diff = item.max - item.min;
                    if (diff == 0) { diff = 1 }
                    return (item.max + ((diff) * 0.2)).toFixed(2);
                },
                nameLocation: 'center',
                nameTextStyle: {
                    padding: [0, 20, 0, 0]
                }
            }
            if (yAxisName.length) {
                axisData = {
                    ...axisData, name: yAxisName[0]
                };
            }
        }
        valueAxis.push(axisData);

        let categoryAxisDict = {
            type: 'category',
            data: x[0], //X[0] for same data structure,
            nameLocation: 'center',
            nameTextStyle: {
                padding: [10, 0, 0, 0]
            }
        }
        if (xAxisName.length) {
            categoryAxisDict = {
                ...categoryAxisDict, name: xAxisName[0]
            };
        }
        categoryAxis.push(categoryAxisDict)
        if (direction == "vertical") {
            xAxis = categoryAxis;
            yAxis = valueAxis;
        }
        else {
            xAxis = valueAxis;
            yAxis = categoryAxis;
        }

        return { xAxis: xAxis, yAxis: yAxis }
    }
    const makeOption = (y, legend, stackType, boundaryGap, direction, x, isMultiLabel, xAxisName, yAxisName, color, zoomEvt, w, h) => {
        const { series } = makeData(y, legend, stackType, color);
        const { xAxis, yAxis } = makeAxis(boundaryGap, direction, legend, x, isMultiLabel, xAxisName, yAxisName);
        const option = {
            grid: {
                left: '12px',
                right: '12px',
                bottom: '12px',
                top: '12px',
                containLabel: true
            },
            xAxis: xAxis,
            yAxis: yAxis,
            tooltip: tooltip,
            legend: {
                data: legend
            },
            series: series,
            toolbox : {
                orient   : 'vertical',
                itemSize : 13,
                top      : 15,
                right    : -6,
                feature  : {
                    dataZoom : {
                        yAxisIndex  : 'none',
                        icon        : {
                            zoom : 'path://', // hack to remove zoom button
                            back : 'path://', // hack to remove restore button
                        },
                    },
                },
            },
            zoomEvt: zoomEvt,
            w: w,
            h: h,
            backgroundColor
        };
        return { option: option }
    }


    const { option } = makeOption(y, legend, stackType, boundaryGap, direction, x, isMultiLabel, xAxisName, yAxisName, color, zoomEvt, w, h);
    if (getOnlyOption) return option;
    else {
        const chart = init(id, option);
        return chart;
    }
}

export const ScatterChart = (
    {
        tooltip = {},
        x = [],
        y = [],
        boundaryGap = {
            x: [0, '%10'],
            y: [0, '%10']
        },
        legend = [],
        id = "",
        xAxisName = [],
        yAxisName = [],
        color = [],
        zoomEvt = true,
        w = null,
        h = null,
        backgroundColor = "",
    },
    getOnlyOption = false,
) => {

    const makeData = (y, legend, color) => {
        const series = [];
        for (let i = 0; i < y.length; i++) {
            let seriesData = {
                name: legend[i],
                type: 'scatter',
                data: y[i],
            }
            if (color.length) {
                seriesData = {
                    ...seriesData, color: color[i]
                };
            }
            series.push(seriesData);
        };
        return { series: series };
    }
    const makeAxis = (boundaryGap, xArr, xAxisName, yAxisName) => {
        const xAxis = [];
        for (let i = 0; i < xArr.length; i++) {
            let xAxisData = {
                scale: true,
                boundaryGap: boundaryGap.x,
                data: xArr[i],
                nameLocation: 'center',
                nameTextStyle: {
                    padding: [10, 0, 0, 0]
                }
            }
            if (xAxisName.length) {
                xAxisData = {
                    ...xAxisData, name: xAxisName[i]
                };
            }
            xAxis.push(xAxisData);
        };

        let yAxis = {
            max: (item) => {
                let diff = item.max - item.min;
                if (diff == 0) { diff = 1 }
                return (item.max + ((diff) * 0.2)).toFixed(2);
            },
            nameLocation: 'center',
            nameTextStyle: {
                padding: [0, 20, 0, 0]
            },
            scale: true,
            boundaryGap: boundaryGap.y,
        }
        if (yAxisName.length) {
            yAxis = {
                ...yAxis, name: yAxisName[0]
            };
        }
        return { xAxis: xAxis, yAxis: [yAxis] }
    }
    const makeOption = (boundaryGap, x, y, tooltip, legend, xAxisName, yAxisName, color, zoomEvt,  w, h) => {
        const { series } = makeData(y, legend, color);
        const { xAxis, yAxis } = makeAxis(boundaryGap, x, xAxisName, yAxisName);
        const option = {
            grid: {
                left: '12px',
                right: '12px',
                bottom: '12px',
                top: '12px',
                containLabel: true
            },
            xAxis: xAxis,
            yAxis: yAxis,
            tooltip: tooltip,
            legend: {
                data: legend
            },
            series: series,
            toolbox : {
                orient   : 'vertical',
                itemSize : 13,
                top      : 15,
                right    : -6,
                feature  : {
                    dataZoom : {
                        yAxisIndex  : 'none',
                        icon        : {
                            zoom : 'path://', // hack to remove zoom button
                            back : 'path://', // hack to remove restore button
                        },
                    },
                },
            },
            zoomEvt: zoomEvt,
            w: w,
            h: h,
            backgroundColor
        };
        return { option: option }
    }


    const { option } = makeOption(boundaryGap, x, y, tooltip, legend, xAxisName, yAxisName, color, zoomEvt, w, h);
    if (getOnlyOption) return option;
    else {
        const chart = init(id, option);
        return chart;
    }
}

export const PieChart = ({
    tooltip = {
        trigger: 'item'
    },
    x = [[]],
    y = [[]],
    color = [],
    zoomEvt = true,
    w = null,
    h = null,
    id = "",
    backgroundColor = "",
    clickEvent = () => { }
},
    getOnlyOption = false, // for multi type chart
) => {

    const makeData = (x, y, color) => {
        const dataArr = [];
        for (let i = 0; i < x.length; i++) {
            let dataDict = {
                value: y[i],
                name: x[i]
            }
            if (color.length) {
                dataDict = {
                    ...dataDict,
                    itemStyle: {
                        color: color[i]
                    },
                };
            }
            dataArr.push(dataDict);
        }
        const seriesData = {
            data: dataArr,
            type: 'pie',
            radius: '80%',
        }
        return { series: seriesData };
    }

    const makeOption = (x, y, color, tooltip, zoomEvt, w, h, clickeEvent) => {
        const { series } = makeData(x, y, color);
        const option = {
            grid: {
                left: '12px',
                right: '12px',
                bottom: '12px',
                top: '12px',
                containLabel: true
            },
            tooltip: tooltip,
            legend: {},
            series: series,
            toolbox : {
                orient   : 'vertical',
                itemSize : 13,
                top      : 15,
                right    : -6,
                feature  : {
                    dataZoom : {
                        yAxisIndex  : 'none',
                        icon        : {
                            zoom : 'path://', // hack to remove zoom button
                            back : 'path://', // hack to remove restore button
                        },
                    },
                },
            },
            zoomEvt: zoomEvt,
            w: w,
            h: h,
            backgroundColor,
            clickEvent: clickEvent
        };
        return { option: option }
    }

    const { option } = makeOption(x[0], y[0], color, tooltip, zoomEvt, w, h);
    if (getOnlyOption) return option;
    else {
        const chart = init(id, option);
        return chart;
    }
}

export const HalfDoughnutChart = ({
    tooltip = {
        trigger: 'item'
    },
    x = [[]],
    y = [[]],
    color = [],
    zoomEvt = true,
    w = null,
    h = null,
    id = "",
    backgroundColor = "",
    clickEvent = () => { }
},
    getOnlyOption = false, // for multi type chart
) => {

    const makeData = (x, y, color) => {
        const dataArr = [];
        const sum = y.reduce((accumulator, currentValue) => parseFloat(accumulator )+ parseFloat(currentValue), 0);
        const bottom_half = {
            // make an record to fill the bottom 50%
            value: sum,
            itemStyle: {
                // stop the chart from rendering this piece
                color: 'none',
                decal: {
                    symbol: 'none'
                }
            },
            label: {
                show: false
            }
        };
        for (let i = 0; i < x.length; i++) {
            let dataDict = {
                value: y[i],
                name: x[i]
            }
            if (color.length) {
                dataDict = {
                    ...dataDict,
                    itemStyle: {
                        color: color[i]
                    },
                };
            }
            dataArr.push(dataDict);
        }
        dataArr.push(bottom_half);
        const seriesData = {
            // adjust the start angle
            startAngle: 180,
            data: dataArr,
            type: 'pie',
            radius: ['40%', '70%'],
            center: ['50%', '70%'],
        }
        return { series: seriesData };
    }

    const makeOption = (x, y, color, tooltip, zoomEvt, w, h, clickeEvent) => {
        const { series } = makeData(x, y, color);
        const option = {
            grid: {
                left: '12px',
                right: '12px',
                bottom: '12px',
                top: '12px',
                containLabel: true
            },
            tooltip: tooltip,
            legend: {},
            series: series,
            toolbox: {
                orient: 'vertical',
                itemSize: 13,
                top: 15,
                right: -6,
                feature: {
                    dataZoom: {
                        yAxisIndex: 'none',
                        icon: {
                            zoom: 'path://', // hack to remove zoom button
                            back: 'path://', // hack to remove restore button
                        },
                    },
                },
            },
            zoomEvt: zoomEvt,
            w: w,
            h: h,
            backgroundColor,
            clickEvent: clickEvent
        };
        return { option: option }
    }

    const { option } = makeOption(x[0], y[0], color, tooltip, zoomEvt, w, h);
    if (getOnlyOption) return option;
    else {
        const chart = init(id, option);
        return chart;
    }
}

export const MultiChart = (paramsList, typeList) => {
    const targetChart = {
        "Chart-Bar": BarChart,
        "Chart-Line": LineChart,
        "Chart-Scatter": ScatterChart,
        "Chart-Area": StackedAreaChart,
        "Chart-Half Doughnut": HalfDoughnutChart,
        "Chart-Pie": PieChart,
        "Chart-Radar": RadarChart,
    }
    const optionList = [];

    typeList.forEach((chart, chartIdx) => {
        const targetParam = JSON.parse(JSON.stringify(paramsList[chartIdx]));
        targetParam.legend = targetParam.legend.map((legend) => {
            // handle duplicate legend
            const prevChartLegendList = paramsList.slice(0, chartIdx + 1).map(p => p.legend).flat();
            const dupleLen = prevChartLegendList.filter(l => l === legend).length;
            return dupleLen > 1 ? `${legend}_${dupleLen}` : legend;
        })

        optionList.push(targetChart[chart](targetParam, true));
    })

    const keyList = Object.keys(optionList[0]);
    const multiChartOption = {};
    keyList.forEach(key => {
        if (key === "series" || key === "yAxis")
            multiChartOption[key] = optionList.map(info => info[key]).flat();
        else if (key === "legend")
            multiChartOption[key] = { data: optionList.map(info => info.legend.data).flat() };
        else
            multiChartOption[key] = optionList[0][key];
    })
    const chart = init(paramsList[0].id, multiChartOption);
    return chart;
}

export const RadarChart = ({
    tooltip = {
        trigger: 'axis'
    },
    x = [[]],
    y = [[]],
    color = [],
    legend = [],
    id = ""
},
    getOnlyOption = false, // for multi type chart
) => {

    const makeData = (x, y, color, legend) => {
        const yArr = [];
        const maxArr = [];
        let yDataCnt = 0;
        if (y.length > 0) {
            yDataCnt = y[0].length;
        }
        for (let i = 0; i < yDataCnt; i++) {
            const indexArr = [];
            for (let j = 0; j < y.length; j++) {
                indexArr.push(y[j][i]);
            }
            maxArr.push(Math.max(...indexArr) * 1.1);
        }
        for (let i = 0; i < y.length; i++) {
            let dataDict = {
                value: y[i],
                name: legend[i]
            }
            if (color.length) {
                dataDict = {
                    ...dataDict,
                    itemStyle: {
                        color: color[i]
                    },
                };
            }
            yArr.push(dataDict);
        }
        const seriesData = {
            data: yArr,
            type: 'radar',
        }


        let radarDict = { indicator: [] };
        for (let i = 0; i < x.length; i++) {
            let dataDict = {
                max: maxArr[i],
                name: x[i]
            }
            radarDict.indicator.push(dataDict);
        }

        return { series: seriesData, radar: radarDict };
    }

    const makeOption = (x, y, color, legend) => {
        const { series, radar } = makeData(x, y, color, legend);
        const option = {
            grid: {
                left: '12px',
                right: '12px',
                bottom: '12px',
                top: '12px',
                containLabel: true
            },
            tooltip: tooltip,
            legend: {},
            series: series,
            radar: radar,
            toolbox : {
                orient   : 'vertical',
                itemSize : 13,
                top      : 15,
                right    : -6,
                feature  : {
                    dataZoom : {
                        yAxisIndex  : 'none',
                        icon        : {
                            zoom : 'path://', // hack to remove zoom button
                            back : 'path://', // hack to remove restore button
                        },
                    },
                },
            },
        };
        return { option: option }
    }

    const { option } = makeOption(x[0], y, color, legend);
    if (getOnlyOption) return option;
    else {
        const chart = init(id, option);
        return chart;
    }
}



//Template
export const BellCurveChart = (
    {
        id = "",
        avg = 0,
        std = 1,
        value = 0,
        legend = [],
        tooltip = {
            trigger: 'axis'
        },
        axisType = {
            x: ['category'],
            y: ['value']
        },
        boundaryGap = {
            x: [0, '%10'],
            y: [0, '%10']
        },
        area = {
            idx: null,
            data: []
        },
        smooth = false,
        xAxisName = [],
        yAxisName = [],
        color = [],
    }
) => {

    // Make json type data structure
    // y : data
    // area : for chart area
    // smooth : line smooth this type can be float or boolean value
    // legend : To match y and x data

    const makeData = () => {


        const normalDist = (x) => {
            return 1 / (std * Math.sqrt(2 * Math.PI)) * Math.exp(- (x ** 2 - avg ** 2) / (2 * std ** 2));
        }
        const probVal = () => {
            return (value - avg) / std;
        }


        const markLine = [];
        const series = [];
        const pieces = [];
        const x = [];
        const y = [];
        const prob = probVal();
        let area = {
            idx: 0,
            data: []
        };

        for (let i = -4; i <= 4; i++) {
            if (prob < -3) {
                area.data = [[0, 1]];
            }
            else if (prob > 3) {
                area.data = [[7, 8]];
            }
            else {
                if (prob < i) {
                    area.data = [[i - 1, i]];
                }
            }
            x.push(i);
            y.push(normalDist(i));
        }
        let seriesData = {
            type: 'line',
            smooth: true,
            data: y,
        }
        if (color.length) {
            seriesData = {
                ...seriesData,
                color: color,
                itemStyle: {
                    color: color
                },
                lineStyle: { color: color }
            };
        }
        series.push(seriesData);

        //for (let i = 0; i < area.data.length; i++) {
        //    ;
        //    pieces.push({
        //        gte: area.data[i][0],
        //        lte: area.data[i][1],
        //    });
        //    markLine.push({
        //        xAxis: area.data[i][0]
        //    });
        //    markLine.push({
        //        xAxis: area.data[i][1]
        //    })
        //}

        //if (area.idx != null) {

        //    series[area.idx].markLine = {
        //        symbol: ['none', 'none'],
        //        label: { show: false },
        //        data: markLine
        //    }
        //    series[area.idx].areaStyle = {}
        //}

        //;
        return { areaPieces: pieces, series: series, x: x };
    }

    // Make json type Axis structure
    // axisType : Type of data
    // boundaryGap : the space between elements
    // xArr : x data array

    const makeAxis = (axisType, boundaryGap, x) => {
        const xAxis = [];
        const yAxis = [];
        let xAxisData = {
            type: axisType.x[0],
            boundaryGap: boundaryGap.x,
            data: x,
            nameLocation: 'center',
            nameTextStyle: {
                padding: [10, 0, 0, 0]
            }
        }
        xAxis.push(xAxisData);
        for (let i = 0; i < axisType.y.length; i++) {
            let yAxisData = {
                max: (item) => {
                    let diff = item.max - item.min;
                    if (diff == 0) { diff = 1 }
                    return (item.max + ((diff) * 0.2)).toFixed(2);
                },
                type: axisType.y[i],
                boundaryGap: boundaryGap.y,
                nameLocation: 'center',
                nameTextStyle: {
                    padding: [0, 20, 0, 0]
                }
            }
            yAxis.push(yAxisData);
        };
        return { xAxis: xAxis, yAxis: yAxis }
    }


    // Make option 
    const makeOption = () => {
        const { areaPieces, series, x } = makeData();
        const { xAxis, yAxis } = makeAxis(axisType, boundaryGap, x);
        let option = {
            grid: {
                left: '0%',
                right: '0%',
                bottom: '0%',
                top: '0%',
                containLabel: true
            },
            xAxis: xAxis,
            yAxis: yAxis,
            tooltip: tooltip,
            series: series,
            toolbox : {
                orient   : 'vertical',
                itemSize : 13,
                top      : 15,
                right    : -6,
                feature  : {
                    dataZoom : {
                        yAxisIndex  : 'none',
                        icon        : {
                            zoom : 'path://', // hack to remove zoom button
                            back : 'path://', // hack to remove restore button
                        },
                    },
                },
            },
        };

        if (areaPieces.length) {
            option = {
                ...option, visualMap: {
                    type: 'piecewise',
                    show: false,
                    dimension: 0,
                    seriesIndex: 0,
                    pieces: areaPieces
                }
            }
        }
        return { option: option }
    }
    // Draw chart
    const { option } = makeOption();
    const chart = init(id, option);
    return chart;
}
export const SankeyDiagram = (
    {
        tooltip = {
            trigger: 'item',
            triggerOn: 'mousemove'
        },
        data = [[]],
        id = ""
    }
) => {

    const makeData = (data) => {
        const seriesData = {
            type: 'sankey',
            data: data.nodes,
            links: data.links,
            emphasis: {
                focus: 'adjacency'
            },
            lineStyle: {
                curveness: 0.5
            }
        }

        return { series: seriesData };
    }

    const makeOption = (data) => {
        const { series } = makeData(data);
        const option = {
            grid: {
                left: '0%',
                right: '0%',
                bottom: '0%',
                top: '0%',
                containLabel: true
            },
            tooltip: tooltip,
            series: series,
            toolbox : {
                orient   : 'vertical',
                itemSize : 13,
                top      : 15,
                right    : -6,
                feature  : {
                    dataZoom : {
                        yAxisIndex  : 'none',
                        icon        : {
                            zoom : 'path://', // hack to remove zoom button
                            back : 'path://', // hack to remove restore button
                        },
                    },
                },
            },
        };
        return { option: option }
    }

    const { option } = makeOption(data);
    const chart = init(id, option);
    return chart;

}
export const BoxplotChart = (
    {
        tooltip = {
            trigger: 'item',
            axisPointer: {
                type: 'shadow'
            }
        },
        x = [[]],
        y = [[]],
        color = [],
        id = ""
    }
) => {

    const makeData = (x, y, color) => {
        const dataSet = [
            {
                source: y
            },
            {
                transform: {
                    type: 'boxplot',
                    config: {
                        itemNameFormatter: (params) => {
                            return x[params.value];
                        }
                    }
                }
            },
            {
                fromDatasetIndex: 1,
                fromTransformResult: 1
            }
        ];
        let seriesDict = {
            name: 'boxplot',
            type: 'boxplot',
            datasetIndex: 1
        }
        if (color.length) {
            seriesDict = {
                ...seriesDict, itemStyle: { color: color[0] },
                color: color[0]
            };
        }
        const series = [
            seriesDict,
            {
                name: 'outlier',
                type: 'scatter',
                datasetIndex: 2
            }
        ]
        return { dataset: dataSet, series: series };
    }

    const makeOption = (x, y, color) => {
        const { dataset, series } = makeData(x, y, color);
        const option = {
            grid: {
                left: '0%',
                right: '0%',
                bottom: '0%',
                top: '0%',
                containLabel: true
            },
            tooltip: tooltip,
            dataset: dataset,
            xAxis: {
                type: 'category',
                splitArea: {
                    show: false
                },
                splitLine: {
                    show: false
                }
            },
            yAxis: {
                type: 'value',
                splitArea: {
                    show: true
                }
            },
            series: series,
            toolbox: {
                orient: 'vertical',
                itemSize: 13,
                top: 15,
                right: -6,
                feature: {
                    dataZoom: {
                        yAxisIndex: 'none',
                        icon: {
                            zoom: 'path://', // hack to remove zoom button
                            back: 'path://', // hack to remove restore button
                        },
                    },
                },
            },
        };
        return { option: option }
    }

    const { option } = makeOption(x[0], y, color);
    const chart = init(id, option);
    return chart;

}
