import React, { useEffect, useState } from 'react';
import { getUser, getIP, getMenuMappingInfo, getLanguage } from '../../apiInterface/utils/Common';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from 'devextreme-react/button';
import TextBox from 'devextreme-react/text-box';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import useTranslation from '../../components/customHooks/translations';
import { Validator, RequiredRule, EmailRule, PatternRule, CustomRule } from 'devextreme-react/validator';
import { SelectBox } from 'devextreme-react/select-box';
import { swal, useSwal } from '../../components/common/Swal';
import { getFactoryIds, getOperationLineInfo, insertOperationLineInfo, updateOperationLineInfo, deleteOperationLineInfo, getLineById, insertLineProcess } from '../../apiInterface/line/LineAPI';
import { DropdownField, TextboxField } from '../../components/common/FormField';
import AddGroupGrid from './AddGroupGrid';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import { actionDirect, moveFocus, submit } from '../../components/common/FormFuncWithEnter';
import '../../contents/styles/operationManagement/line-form.css';
import { PageTitle } from '../../components/common/PageTitle.jsx';

export default function LineForm(props) {
    const user = getUser();
    const ipAddress = getIP();
    const navigate = useNavigate();
    const [data, setData] = useState(props.data);
    const [dataSource, setDataSource] = useState([]);
    const translation = useTranslation();
    const { state } = useLocation();
    const location = useLocation();
    const previousPage = '/Line';
    const language = getLanguage();
    const errorHandler = useErrorHandler();
    const { success } = useSwal();

    const onInsert = async () => {
        const { id: mapping_id } = await getMenuMappingInfo();
        const queryData = {
            user_master_id: user.user_master_id,
            user_log_ip: ipAddress,
            user_menu_mapping_id: mapping_id,
            operation_factory_id: sessionStorage.getItem('factoryId'),
            operation_line_code: data.operation_line_code,
            operation_line_name: data.operation_line_name,
            operation_line_is_active: data.operation_line_is_active,
            operation_line_comment: data.operation_line_comment,
        };

        const response = await errorHandler(insertOperationLineInfo, queryData);
        if (response) {
            const lineId = response.data.o_data[0].operation_line_id;
            await insertProcess(lineId);
            const cb = () => exitForm();
            success(translation.success_insert, cb);
        }
    };

    const onUpdate = async () => {
        const { id: mapping_id } = await getMenuMappingInfo();
        const queryData = {
            user_master_id: user.user_master_id,
            user_log_ip: ipAddress,
            user_menu_mapping_id: mapping_id,
            operation_factory_id: sessionStorage.getItem('factoryId'),
            operation_line_id: data.operation_line_id,
            operation_line_code: data.operation_line_code,
            operation_line_name: data.operation_line_name,
            operation_line_is_active: data.operation_line_is_active,
            operation_line_comment: data.operation_line_comment,
        };

        const response = await errorHandler(updateOperationLineInfo, queryData);
        if (response) {
            await insertProcess();
            const cb = () => exitForm();
            success(translation.success_update, cb);
        }
    };

    const insertProcess = async (lineId = data.operation_line_id) => {
        for (let i = 0; i < data.process_str.length; i++) {
            const param = {
                operation_line_id: lineId,
                operation_process_id: data.process_str[i].operation_process_id,
            };

            // const param = {
            //     operation_line_id: lineId,
            //     operation_process_id: data.process_str[i].operation_process_id,
            //     machine_equipment_id: null,
            //     machine_mold_id: null,
            // }

            const res = await errorHandler(insertLineProcess, param);
            if (res) {
                // do something when successs
            } else {
                console.log(`failed to insert | process id: ${param.operation_process_id}`);
            }
        }
    };

    const valueChanged = (value, key) => {
        setData((cur) => {
            let newData = { ...cur };
            newData[key] = value;
            return newData;
        });
    };

    const customValidation = (option) => {
        if (option.value !== undefined && option.value !== null) {
            return true;
        } else {
            return false;
        }
    };

    const useItems = [
        { value: true, title: translation.use },
        { value: false, title: translation.notUse },
    ];

    //init
    useEffect(() => {
        if (state.title == 'Edit') {
            let param = {
                operation_line_id: state.rowData.operation_line_id,
                sys_lang_code: language,
            };
            const fetching = async () => {
                const res = await errorHandler(getLineById, param);
                if (res) {
                    res.data.o_data[0].modified_date = res.data.o_data[0].modified_date.split('T')[0];
                    let temp = res.data.o_data[0];
                    temp.process_str = temp.process_str !== null ? temp.process_str.replaceAll('user_business', 'user_business_name') : null;
                    let json = JSON.parse(temp.process_str);
                    temp.process_str = json ? json : [];
                    temp.operation_line_id = state.rowData.operation_line_id;
                    setData(temp);
                }
            };
            fetching();
        } else {
            valueChanged([], 'process_str');
            valueChanged(useItems[0].value, 'operation_line_is_active');
        }
    }, []);

    const exitForm = () => {
        navigate(previousPage, {
            state: {
                previousPage: location.pathname,
            },
        });
    };

    const dropdownRule = <CustomRule type={'custom'} validationCallback={customValidation} />;

    return (
        <div className="line-form">
            <div className="right-content-title">
                <PageTitle pageState={location.state.title} />
            </div>
            <form onSubmit={actionDirect}>
                <div className="right-content-body">
                    <div className="container">
                        <div className="dx-fieldset">
                            <TextboxField
                                label={translation.operation_line_name}
                                value={data?.operation_line_name}
                                onValueChanged={(e) => valueChanged(e.value, 'operation_line_name')}
                                isEssential={true}
                                essentialMsg={translation.operation_line_name + translation.is_required}
                                onEnterKey={moveFocus}
                                inputAttr={{ focusidx: 0, autofocus: true }}
                            />

                            <TextboxField
                                label={translation.operation_line_code}
                                value={data?.operation_line_code}
                                onValueChanged={(e) => valueChanged(e.value, 'operation_line_code')}
                                isEssential={true}
                                essentialMsg={translation.operation_line_code + translation.is_required}
                                readOnly={state?.title == 'Edit' ? true : false}
                                onEnterKey={moveFocus}
                                inputAttr={{ focusidx: 1 }}
                            />

                            <DropdownField
                                label={translation.use_or_not}
                                dataSource={useItems}
                                value={data?.operation_line_is_active}
                                valueExpr="value"
                                displayExpr="title"
                                onValueChanged={(e) => valueChanged(e.value, 'operation_line_is_active')}
                                validationRules={dropdownRule}
                                onEnterKey={moveFocus}
                                inputAttr={{ focusidx: 2 }}
                                isSearchable={false}
                            />

                            <TextboxField label={translation.operation_line_comment} value={data?.operation_line_comment} onValueChanged={(e) => valueChanged(e.value, 'operation_line_comment')} onEnterKey={moveFocus} inputAttr={{ focusidx: 3 }} />
                        </div>
                        <div className="block"></div>
                        <div className="dx-fieldset">
                            <div className="dx-field column">
                                <div className="dx-field-label">{translation.process_management}</div>
                                <div className="dx-field-value">
                                    <AddGroupGrid
                                        width={668}
                                        dataSource={data?.process_str}
                                        setDataSource={(val) => {
                                            valueChanged(val, 'process_str');
                                        }}
                                        keyExpr="operation_process_id"
                                        displayExpr="operation_process_name"
                                    />
                                    <p className="info-comment">
                                        <span className="material-icons-outlined">info</span>
                                        {translation.info_process_management}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="block"></div>
                        {location.state.title == 'Edit' && (
                            <div>
                                <div className="dx-fieldset write-info">
                                    <div className="dx-field">
                                        <div className="dx-field-label">{translation.lastModifiedDate}</div>
                                        <div className="dx-field-value">{data?.modified_date}</div>
                                    </div>
                                    <div className="dx-field">
                                        <div className="dx-field-label">{translation.lastModifiedBy}</div>
                                        <div className="dx-field-value">
                                            {data?.modified_by_name}
                                            {data?.modified_by_duty && <span className="badge badge-job-position">{data?.modified_by_duty}</span>}
                                            {data?.modified_by_department && <span className="badge badge-team">{data?.modified_by_department}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="block"></div>
                            </div>
                        )}
                        <div>
                            <div className="btn-group dx-fieldset" style={{ width: '708px' }}>
                                <Button type="normal" stylingMode="text" className="btn-s-r" onClick={exitForm}>
                                    <div>
                                        <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                        <span className="dx-button-text">{translation.cancel}</span>
                                    </div>
                                </Button>
                                <Button
                                    id="submitBtn"
                                    type="default"
                                    stylingMode="contained"
                                    className="btn-s-r"
                                    useSubmitBehavior={true}
                                    onClick={(e) => {
                                        if (state.title === 'Add') submit(e, onInsert);
                                        else if (state.title === 'Edit') submit(e, onUpdate);
                                    }}
                                >
                                    <div>
                                        <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                        <span className="dx-button-text">{translation.save}</span>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}
