import logo from './logo.svg';
import React, { useState, useEffect } from 'react';
import 'devextreme/dist/css/dx.material.blue.light.css';
import './contents/styles/dx.material.ix-pds.css';
import './contents/styles/pretendard.css';
import './contents/styles/style.css';
import 'material-icons';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './pages/shared/Layout';
import { NoPage, Home, Dashboard, Landing, InaTest, Chart, DataGrid, MultipleChart, About, Subscription, FetchDataComponent } from './routes/HomeRoute';
import { Login, SelectUserType, InputCompanyInfo, InputPersonalInfo } from './routes/AccountRoute';
import { CompanyManagementInfo } from './routes/CompanyManagementRoute';
import { BusinessManagementInfo, BusinessManagementInfoForm } from './routes/BusinessManagementRoute';
import { DepartmentManagementInfo, DepartmentManagementInfoForm } from './routes/DepartmentManagementRoute';
import { UserManagementInfo, UserManagementInfoForm } from './routes/UserManagementRoute';
import { MainPortal } from './routes/DashboardRoute';
import { ProductCategory, ProductGroup, ProductMaster, ProductGroupForm } from './routes/ProductManagementRoute';
import { Factory, Process, Line, FactoryForm, ProcessForm, LineForm } from './routes/OperationManagementRoute';
import { BadTypeManagementInfo, BadTypeManagementInfoForm, InspectionDefectType, InspectionStandardGroup, DefectTypeStandard, InspectionDefectTypeForm, InspectionStandardLibraryForm, DefectTypeStandardForm, DefectTypeStandardFormDtl } from './routes/BadTypeManagementRoute';
import { SecurityGroup, SecurityGroupForm } from './routes/SecurityManagementRoute';
import { CreateTemplate, TemplateMasterGrid, TemplateMasterForm } from './routes/TemplateManagementRoute';
import { Division, DivisionForm } from './routes/DivisionManagementRoute';
import { ItemCategory, ItemMaster, ItemCategoryForm, ItemMasterForm } from './routes/ItemRoute';
import { Mold, MoldForm, Equipment, EquipmentForm } from './routes/machineRoute';
import { UseHistory } from './routes/UseHistoryRoute';
import { getParam, getToken, getUser } from './apiInterface/utils/Common';
import { Menu, MenuForm, MenuBindingData, MenuSelectTemplate, CMV, ViewSampleData } from './routes/MenuRoute';
import {
    AASFileManagement,
    AASFileManagementForm,
    AASMasterManagement,
    AASMasterManagementExplorer,
    DataConnectionManagement,
    DatasetManagement,
    DatasetManagementDetail,
    DatasetManagementForm,
    DatasetManagementRawData,
    RawData,
    AbnormalSettings,
    AbnormalSignLog,
    AbnormalAnalysis,
    AbnormalSettingsForm,
    AbnormalSignLogForm,
} from './routes/DataManagementRoute';
import MasterDataset from './pages/dataManagement/MasterDataset';
import MasterDatasetForm from './pages/dataManagement/MasterDatasetForm';

function Root() {
    const navigate = useNavigate();
    let user = getUser();
    const token = getToken();
    const href = window.location.href;
    //const [changeState, setChangeState] = useState();

    const initializeToken = () => {};

    useEffect(() => {
        // local case
        const userP = getParam('user');
        const tokenP = getParam('token');
        if (userP && tokenP) {
            if (!user) {
                localStorage.setItem('token', tokenP);
                localStorage.setItem('user', userP);
            }
            history.pushState(null, null, window.location.href.split('?')[0]);
        }

        user = getUser();
        if (!user) {
            if (href.includes('framework')) {
                navigate('/Login', { replace: true });
            } else {
                redirectToLoginPage();
            }
        } else if (!token) {
            const pathnameSplit = window.location.pathname?.split('/');
            const pagename = pathnameSplit[pathnameSplit.length - 1];
            if (href.includes('framework') && pagename !== 'SelectUserType' && pagename !== 'InputCompanyInfo') {
                navigate('/Login', { replace: true });
            } else {
                initializeToken();
            }
        }

        // const token = getToken();
        // const pathnameSplit = window.location.pathname.split('/');
        // const pagename = pathnameSplit[pathnameSplit.length - 1];
        // if (!token) {
        //     // except signup page
        //     if (pagename !== 'SelectUserType' && pagename !== 'InputCompanyInfo') {
        //         navigate('/Login', { replace: true });
        //     }
        // } else {
        //     if (user) {
        //         if (token && (pagename === '' || pagename === 'framework')) {
        //             navigate('/MainPortal', { replace: true });
        //         } else {
        //             console.log(token);
        //         }
        //     } else {
        //         // sesstion remove => login page
        //         navigate('/Login', { replace: true });
        //     }
        // }
    }, []);

    return (
        <div>
            <Routes>
                <Route path="/Login" element={<Login />} />
                <Route path="/SelectUserType" element={<SelectUserType />} />
                <Route path="/InputCompanyInfo" element={<InputCompanyInfo />} />
                <Route path="/InputPersonalInfo" element={<InputPersonalInfo />} />
                <Route path="/CustomTemplatePreview" element={<CreateTemplate />} />
                <Route path="/ViewSampleData" element={<ViewSampleData />} />

                <Route path="/" element={<Layout />}>
                    {/* <Route index element={<Landing />} /> */}
                    <Route path="/Dashboard" element={<Dashboard />} />
                    <Route path="/About" element={<About />} />
                    <Route path="/Subscription" element={<Subscription />} />
                    <Route path="/InaTest" element={<InaTest />} />
                    <Route path="/CompanyManagementInfo" element={<CompanyManagementInfo />} />
                    <Route path="/Chart" element={<Chart />} />
                    {/*Main Portal*/}
                    <Route path="/MainPortal" element={<MainPortal />} />

                    {/*Business(Branch,Team) Management*/}
                    <Route path="/BusinessManagementInfo" element={<BusinessManagementInfo />} />
                    <Route path="/BusinessManagementInfoForm" element={<BusinessManagementInfoForm />} />

                    {/*Department Management*/}
                    <Route path="/DepartmentManagementInfo" element={<DepartmentManagementInfo />} />
                    <Route path="/DepartmentManagementInfoForm" element={<DepartmentManagementInfoForm />} />

                    {/*User Management*/}
                    <Route path="/UserManagementInfo" element={<UserManagementInfo />} />
                    <Route path="/UserManagementInfoForm" element={<UserManagementInfoForm />} />

                    {/*Product Management*/}
                    <Route path="/ProductCategory" element={<ProductCategory />} />
                    <Route path="/ProductGroup" element={<ProductGroup />} />
                    <Route path="/ProductMaster" element={<ProductMaster />} />
                    <Route path="/ProductGroupForm" element={<ProductGroupForm />} />

                    {/*Operation Management*/}
                    <Route path="/Factory" element={<Factory />} />
                    <Route path="/Process" element={<Process />} />
                    <Route path="/Line" element={<Line />} />
                    <Route path="/FactoryForm" element={<FactoryForm />} />
                    <Route path="/ProcessForm" element={<ProcessForm />} />
                    <Route path="/LineForm" element={<LineForm />} />

                    {/*Quality Bad Type*/}
                    <Route path="/BadType" element={<BadTypeManagementInfo />} />
                    <Route path="/BadTypeManagementInfoForm" element={<BadTypeManagementInfoForm />} />
                    <Route path="/InspectionDefectType" element={<InspectionDefectType />} />
                    <Route path="/InspectionStandardGroup" element={<InspectionStandardGroup />} />
                    <Route path="/DefectTypeStandard" element={<DefectTypeStandard />} />
                    <Route path="/InspectionDefectTypeForm" element={<InspectionDefectTypeForm />} />
                    <Route path="/InspectionStandardLibraryForm" element={<InspectionStandardLibraryForm />} />
                    <Route path="/DefectTypeStandardForm" element={<DefectTypeStandardForm />} />
                    <Route path="/DefectTypeStandardFormDtl" element={<DefectTypeStandardFormDtl />} />

                    {/*Equipment*/}
                    <Route path="/Equipment" element={<Equipment />} />
                    <Route path="/EquipmentForm" element={<EquipmentForm />} />

                    {/*Part*/}
                    <Route path="/Mold" element={<Mold />} />
                    <Route path="/MoldForm" element={<MoldForm />} />

                    <Route path="/DataGrid" element={<DataGrid />} />
                    <Route path="/MultipleChart" element={<MultipleChart />} />

                    {/*Use History*/}
                    <Route path="/UseHistory" element={<UseHistory />} />

                    {/*Security Management*/}
                    <Route path="/SecurityGroup" element={<SecurityGroup />} />
                    <Route path="/SecurityGroupForm" element={<SecurityGroupForm />} />

                    {/*Item*/}
                    <Route path="/ItemCategory" element={<ItemCategory />} />
                    <Route path="/ItemCategoryForm" element={<ItemCategoryForm />} />
                    <Route path="/ItemMaster" element={<ItemMaster />} />
                    <Route path="/ItemMasterForm" element={<ItemMasterForm />} />

                    {/*Template master*/}
                    <Route path="/CreateTemplate" element={<CreateTemplate />} />
                    <Route path="/TemplateMasterGrid" element={<TemplateMasterGrid />} />
                    <Route path="/TemplateMasterForm" element={<TemplateMasterForm />} />

                    {/*Division Management*/}
                    <Route path="/Division" element={<Division />} />
                    <Route path="/DivisionForm" element={<DivisionForm />} />

                    {/*Menu*/}
                    <Route path="/Menu" element={<Menu />} />
                    <Route path="/MenuForm" element={<MenuForm />} />
                    <Route path="/MenuSelectTemplate" element={<MenuSelectTemplate />} />
                    <Route path="/MenuBindingData" element={<MenuBindingData />} />
                    <Route path="/CMV/:params" element={<CMV />} />

                    {/*Data management*/}
                    <Route path="/AASFileManagement" element={<AASFileManagement />} />
                    <Route path="/AASFileManagementForm" element={<AASFileManagementForm />} />
                    <Route path="/AASMasterManagement" element={<AASMasterManagement />} />
                    <Route path="/AASMasterManagementExplorer" element={<AASMasterManagementExplorer />} />
                    <Route path="/DataConnection" element={<DataConnectionManagement />} />
                    <Route path="/DatasetManagement" element={<DatasetManagement />} />
                    {/* for pxk */}
                    <Route path="/DatasetManagement2" element={<DatasetManagement />} />
                    <Route path="/DatasetManagement3" element={<DatasetManagement />} />

                    <Route path="/DatasetManagementDetail" element={<DatasetManagementDetail />} />
                    <Route path="/DatasetManagementForm" element={<DatasetManagementForm />} />
                    <Route path="/DatasetManagementRawData" element={<DatasetManagementRawData />} />
                    <Route path="/RawData" element={<RawData />} />

                    <Route path="/MasterDataset" element={<MasterDataset />} />
                    <Route path="/MasterDatasetForm" element={<MasterDatasetForm />} />
                    <Route path="/AbnormalSettings" element={<AbnormalSettings />} />
                    <Route path="/AbnormalSettingsForm" element={<AbnormalSettingsForm />} />
                    <Route path="/AbnormalSignLog" element={<AbnormalSignLog />} />
                    <Route path="/AbnormalSignLogForm" element={<AbnormalSignLogForm />} />
                    <Route path="/AbnormalAnalysis" element={<AbnormalAnalysis />} />

                    <Route path="/FetchDataComponent" element={<FetchDataComponent />} />

                    {/* this is when user enter wrong url address */}
                    <Route path="*" element={<NoPage />} />
                </Route>
            </Routes>
        </div>
    );
}

function App() {
    return (
        <BrowserRouter basename="/framework">
            <Root />
        </BrowserRouter>
    );
}

export default App;
