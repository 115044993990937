import { makeTokenAxiosGetRequest ,makeAxiosGetRequest, makeAxiosPostRequest } from "../utils/AxiosMask"

export const signin = async (data) => {
    var res;
    const urlPath = `account/login/`;
    try {
        res = await makeAxiosGetRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};
export const forgotPasswordAPI = async (data) => {
    var res;
    const urlPath = `account/forgot_password/`;
    try {
        res = await makeAxiosGetRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};
export const changePasswordAPI = async (data) => {
  var res;
  const urlPath = `account/change_password/`;
  try {
    res = await makeTokenAxiosGetRequest(urlPath, data);
    return res.status === 200 ? res.data : "fail";
  } catch (error) {
    console.error('error', error);
  }
  return res.status === 200 ? res : "fail";
};