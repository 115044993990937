import { makeTokenAxiosGetRequest, makeTokenAxiosPostRequest, makeTokenAxiosDeleteRequest } from "../utils/AxiosMask"

export const getEveryService = async (data) => {
  var res;
  const urlPath = `subscription/every_service/`;
  try {
    res = await makeTokenAxiosGetRequest(urlPath, data);
    return res.status === 200 ? res.data : "fail";
  } catch (error) {
    console.error('error', error);
  }
  return res.status === 200 ? res : "fail";
};


export const getUserCompanyService = async (data) => {
  var res;
  const urlPath = "subscription/user_company_service/";
  try {
    res = await makeTokenAxiosGetRequest(urlPath, data);
    return res.status === 200 ? res.data : "fail";
  } catch (error) {
    console.error('error', error);
  }
  return res.status === 200 ? res : "fail";
}

export const insertUserCompanyService = async (data) => {
    var res;
    const urlPath = `subscription/user_company_service?user_master_id=${data.user_master_id}&user_log_ip=${data.user_log_ip}&user_company_id=${data.user_company_id}
                    &user_company_template_type=${data.user_company_template_type}&user_company_service_start=${data.user_company_service_start}&user_company_service_end=${data.user_company_service_end}`;
    try {
        res = await makeTokenAxiosPostRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {

        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
}

export const deleteUserCompanyService= async (data) => {
  var res;
  const urlPath = `subscription/user_company_service/`;
  try {
    res = await makeTokenAxiosDeleteRequest(urlPath, data);
    return res.status === 200 ? res.data : "fail";
  } catch (error) {

    console.error('error', error);
  }
  return res.status === 200 ? res : "fail";
};

