// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.line .right-content-body {
    height: calc(100% - 44px);
    min-height: calc(100% - 44px);
}
/*.selected-deparment {
    margin-bottom: 12px;
}
.selected-department span:first-child {
    color: var(--inactive-text);
    font-weight: normal;
}
.selected-department span:not(first-child) {
    color: var(--primary-500);
    font-weight: bold;
}*/
.line-form .info-comment {
    margin-top: 14px;
}
`, "",{"version":3,"sources":["webpack://./src/contents/styles/operationManagement/line-form.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,6BAA6B;AACjC;AACA;;;;;;;;;;EAUE;AACF;IACI,gBAAgB;AACpB","sourcesContent":[".line .right-content-body {\n    height: calc(100% - 44px);\n    min-height: calc(100% - 44px);\n}\n/*.selected-deparment {\n    margin-bottom: 12px;\n}\n.selected-department span:first-child {\n    color: var(--inactive-text);\n    font-weight: normal;\n}\n.selected-department span:not(first-child) {\n    color: var(--primary-500);\n    font-weight: bold;\n}*/\n.line-form .info-comment {\n    margin-top: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
