import { makeAxiosGetRequest, makeAxiosPostRequest } from "../utils/AxiosMask"

export const inaTestGrid = async (data) => {
    var res;
    const urlPath = `account/login/`;
    try {
        res = await makeAxiosGetRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};

export const echartTest = async (data) => {
    var res;
    const urlPath = `home/echart/data/`;
    try {
        res = await makeAxiosGetRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};


export const echartLiveTest = async (data) => {
    var res;
    const urlPath = `home/echart/livedata/`;
    try {
        res = await makeAxiosGetRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};
