import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import "allotment/dist/style.css";
import { Allotment } from "allotment";
import Button from 'devextreme-react/button';
import TextBox from 'devextreme-react/text-box';
import SelectBox from 'devextreme-react/select-box';
import { Popup } from 'devextreme-react/popup';
import { CheckBox } from 'devextreme-react/check-box';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import ScrollView from 'devextreme-react/scroll-view';
import ColorBox from 'devextreme-react/color-box';
import { Switch } from 'devextreme-react/switch';
import { Popover } from 'devextreme-react/popover';
//custom hooks n components
import useTranslation from "../../components/customHooks/translations";
import { swal, useSwal } from "../common/Swal";
import useErrorHandler from "../../components/customHooks/useErrorHandler";
import { getUser, getIP, getLanguage, getMenuMappingInfo } from '../../apiInterface/utils/Common';
import { PageTitle } from "../../components/common/PageTitle.jsx";

import { getCollListAPI, getCollDataAPI, getTemplateMasterLayoutAPI, insertMenuMapping, insertMenuMappingCaption, insertMenuMappingSetting } from "../../apiInterface/menu/MenuAPI";
//custom chart lib
import { LineChart, BarChart, PieChart, ScatterChart, RadarChart, BoxplotChart } from "../../components/ixChart/IXChart"
//localization configuration
import localization from "../../localization/index";
let initFilterConfig = { "dateTimeField": "", "isDateTimeField": false, "isRealTime": false, "intervalVal": 0 };
let initTopFilter = { "isDateTimeSelected": false, "isLive": false };
let [PANE_Y_AXIS_CHART_INFO, PANE_X_AXIS_CHART_INFO, COLLECTION_NAME, Y_AXIS_TITLE, X_AXIS_TITLE, FILTER_CONFIG, TOPFILTER] = [[], [], "", "", "", initFilterConfig, initTopFilter];

const chartCategory = (chartType, params) => {
    let chart;

    switch (chartType) {
        case "barChart":
            chart = BarChart(params);
            // code block
            break;
        case "lineChart":
            // code block
            chart = LineChart(params);
            break;
        case "pieChart":
            // code block
            chart = PieChart(params);
            break;
        case "scatterChart":
            chart = ScatterChart(params);
            // code block
            break;
        case "radarChart":
            chart = RadarChart(params);
            // code block
            break;
        case "boxplotChart":
            chart = BoxplotChart(params);
            // code block
            break;
        default:
        // code block
    }
    return chart;
}

const updatePaneTitle = (panes, currentWId, title) => {
    const filteredPanes = panes.reduce((acc, p) => {
        if (p?.split) {
            p.panes = updatePaneTitle(p.panes, currentWId, title);
            if (Array.isArray(p?.panes) && p.panes.length > 0) {
                acc.push(p);
            }
        } else if (p.wId === currentWId) {
            p.headerContentInHtml = title;
            acc.push(p);
        }
        else {
            acc.push(p);
        }
        return acc;
    }, []);
    return filteredPanes;
}

var genRandomArray = (cnt) => {
    let length = 10;
    let max = 1000;
    var arrList = [];
    for (var i = 0; i < cnt; i++) {
        arrList.push(Array(length).fill().map(() => Math.round(Math.random() * max)));
    }
    return arrList;
}
//handle chartbinding action
const updatePaneChartInfo = (panes, currentWId, cId) => {
    const filteredPanes = panes.reduce((acc, p) => {
        if (p?.split) {
            p.panes = updatePaneChartInfo(p.panes, currentWId, cId);
            if (Array.isArray(p?.panes) && p.panes.length > 0) {
                acc.push(p);
            }
        } else if (p.wId === currentWId) {
            let params = {
                x: [[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]],
                y: [[10, 4, 51, 32, 3]],
                legend: ["garryTest"],
                id: cId,
                xAxisName: [],
                yAxisName: [],
                color: []
            }
            if (PANE_Y_AXIS_CHART_INFO.length > 0 && PANE_X_AXIS_CHART_INFO.length > 0) {
                document.querySelector(`#${currentWId} .card-header .binding-status`).classList.add("setting-complete")
            }
            else {
                document.querySelector(`#${currentWId} .card-header .binding-status`).classList.remove("setting-complete")
            }

            if (PANE_Y_AXIS_CHART_INFO.length > 0) {

                document.querySelector(`#${currentWId} .card-header .binding-status .binding-status-card .card-body .status-y-axis`).classList.add("setting-complete");
            }
            else {
                document.querySelector(`#${currentWId} .card-header .binding-status .binding-status-card .card-body .status-y-axis`).classList.remove("setting-complete");
            }

            if (PANE_X_AXIS_CHART_INFO.length > 0) {

                document.querySelector(`#${currentWId} .card-header .binding-status .binding-status-card .card-body .status-x-axis`).classList.add("setting-complete");
            }
            else {
                document.querySelector(`#${currentWId} .card-header .binding-status .binding-status-card .card-body .status-x-axis`).classList.remove("setting-complete");
            }

            let ySampleData = genRandomArray(PANE_Y_AXIS_CHART_INFO.length);
            params.y = ySampleData;
            params.legend = PANE_Y_AXIS_CHART_INFO.flatMap(e => e.legend)
            params.color = PANE_Y_AXIS_CHART_INFO.flatMap(e => e.color)
            params.x = PANE_X_AXIS_CHART_INFO.length ? genRandomArray(1) : params.x;//here using genRandomeArray(1) because only one axis for X.
            params.xAxisName = [X_AXIS_TITLE];
            params.yAxisName = [Y_AXIS_TITLE];
            chartCategory(p.chartType, params)

            p.data.collectionName = COLLECTION_NAME;
            p.data.yAxisInfo = PANE_Y_AXIS_CHART_INFO;
            p.data.xAxisInfo = PANE_X_AXIS_CHART_INFO;
            p.data.yAxisTitle = Y_AXIS_TITLE;
            p.data.xAxisTitle = X_AXIS_TITLE;
            acc.push(p);
        }
        else {
            acc.push(p);
        }
        return acc;
    }, []);
    return filteredPanes;
}

//end of chartbinding action
//handle filter
const updateFilterInfo = (panes, currentWId) => {
    const filteredPanes = panes.reduce((acc, p) => {
        if (p?.split) {
            p.panes = updateFilterInfo(p.panes, currentWId);
            if (Array.isArray(p?.panes) && p.panes.length > 0) {
                acc.push(p);
            }
        } else if (p.wId === currentWId) {
            p.data["filter"] = FILTER_CONFIG;
            acc.push(p);
        }
        else {
            acc.push(p);
        }
        return acc;
    }, []);
    return filteredPanes;
}
//end handle filter

const widgets = {
    "split": "vertical",
    "id": "1",
    "root": true,
    "panes": [
        {
            "split": "horizontal",
            "id": "2",
            "panes": [

            ],
            "sizes": [
                912
            ]
        },
    ],
    "sizes": [
        1
    ]
}


function renderSplitPanes(splitPane, s_id) {
    return (
        <Allotment
            separator={false}
            key={splitPane.id}
            vertical={splitPane.split === "vertical"}
            defaultSizes={splitPane.sizes.map((e) => parseFloat(e))}
        >
            {
                splitPane.panes.map((el, i) => {
                    if (Reflect.has(el, "split")) {
                        s_id = el.id;
                        return renderSplitPanes(el, s_id);
                    }

                    return (
                        <Allotment.Pane id="hoja" key={el.wId} visible={el.show}>
                            <div
                                sx={{
                                    padding: "15px",
                                    height: "100%",
                                    position: "absolute",
                                    width: "100%"
                                }}
                                className="card-wrapper"
                            >
                                <div className="card" id={el.wId}>
                                    <div className="card-header" onClick={selectCard}>
                                        <h6 className="card-title">{el.wId}</h6>
                                        <div className="binding-status">
                                            <i className="material-icons">check_circle</i>
                                            <div className="binding-status-card card">
                                                <div className="card-header">
                                                    <p>Binding State</p>
                                                </div>
                                                <div className="card-body">
                                                    <div className="status-y-axis">
                                                        <i className="material-icons">check_circle</i>
                                                        <p className="essential">Y Axis</p>
                                                    </div>
                                                    <div className="status-x-axis">
                                                        <i className="material-icons">check_circle</i>
                                                        <p className="essential">X Axis</p>
                                                    </div>
                                                    <div className="status-date-time">
                                                        <i className="material-icons">check_circle</i>
                                                        <p>Config: Date / Time</p>
                                                    </div>
                                                    <div className="status-real-time">
                                                        <i className="material-icons">check_circle</i>
                                                        <p>Config: Real Time</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body" dangerouslySetInnerHTML={{ __html: el.bodyContentInHtml }}></div>
                                </div>
                            </div>
                        </Allotment.Pane>
                    );
                })}
        </Allotment>
    );
}
//sdf

const DynamicSplitPaneDataBinding = (props) => {
    //const { title, templateId, data } = props;

    const [tbodyData, setTbodyData] = useState([]);
    const [collectionList, setCollectionList] = useState([]);
    const [w, setW] = useState(widgets);
    const navigate = useNavigate();
    const [selectedColumns, setSelectedColumns] = useState([]);
    const location = useLocation();
    const templateName = props.title;
    const [isAddFilterVisible, setAddFilterVisibility] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState([]);
    const [filterDetail, setFilterDetail] = useState('');
    const [isDataSettingVisible, setDataSettingVisibility] = useState(false);
    const [savedFilter, setSavedFilter] = useState([]);
    const translation = useTranslation();
    const dragItem = useRef();
    const dragOverItem = useRef();
    const errorHandler = useErrorHandler();
    const [intervalVal, setIntervalVal] = useState(5);
    const [cardTitle, setCardTitle] = useState("");
    const { success } = useSwal();

    const {
        data = null,
        title = "",
        templateId = null,
        path = [],
    } = props
    /*
    data={location.state.formData} title={title} templateId={templateId} path={location.state.path}
    */

    window.selectCard = (e) => {
        //dsdf();

        const selectedState = e.currentTarget.closest('.card').classList.contains('selected-card');
        const clickedCard = document.querySelector('.clicked-card');
        const notClickedCard = document.querySelector('.not-clicked-card');
        document.querySelector('.template .card.selected-card')?.classList.remove('selected-card');

        if (!selectedState) {
            e.currentTarget.closest('.card').classList.add('selected-card');
            clickedCard.style.display = "block";
            notClickedCard.style.display = "none";
            let selectedCard = document.querySelectorAll('.selected-card .card-header .card-title');
            setCardTitle(selectedCard[0].innerText);
            if (selectedColumns.length > 0) {
                const sC = selectedColumns;
                setSelectedColumns([]);
                PANE_Y_AXIS_CHART_INFO = [];
                PANE_X_AXIS_CHART_INFO = [];
                FILTER_CONFIG = initFilterConfig;
                setTimeout(() => {
                    setSelectedColumns(sC);
                }, 10)
            }
        } else {
            clickedCard.style.display = "none";
            notClickedCard.style.display = "flex";
        }
    }

    const getTemplateMasterLayout = async () => {
        let param = { template_master_id: templateId }

        const res = await errorHandler(getTemplateMasterLayoutAPI, param);
        if (res) {
            setW(res.data.o_data[0].template_custom_layout_json);
        }
    }

    //init
    useEffect(() => {
        getTemplateMasterLayout();
    }, []);

    const updateSizeFormat = (panes) => {
        const updatedPanes = panes.reduce((acc, p) => {
            if (p?.split) {
                try {
                    var minVal = Math.min(...p.sizes);
                    const newSizes = p.sizes.map(el => (el / minVal).toFixed(2))
                    p.sizes = newSizes;
                }
                catch (ex) { console.log("no size"); }
                p.panes = updateSizeFormat(p.panes);
                if (Array.isArray(p?.panes) && p.panes.length > 0) {
                    acc.push(p);
                }
            } else {
                if (p.data) {
                    ;
                    if (TOPFILTER.isDateTimeSelected == false) {
                        if (p.data.filter.isDateTimeField) TOPFILTER.isDateTimeSelected = p.data.filter.isDateTimeField;
                    }
                    if (TOPFILTER.isLive == false) {
                        if (p.data.filter.isRealTime) TOPFILTER.isLive = p.data.filter.isDateTimeField;
                    }
                }
                acc.push(p);
            }
            return acc;
        }, []);
        return updatedPanes;
    }

    const handleSaveMenu = async () => {
        const w1 = { ...w };
        var minVal = Math.min(...w1.sizes);
        const newSizes = w1.sizes.map(el => (el / minVal).toFixed(3))
        w1.sizes = newSizes
        w1.panes = updateSizeFormat(w1.panes)
        setW(w1);
        const menuMappingInfo = await getMenuMappingInfo();
        const userInfo = getUser();
        const ipAddress = getIP();
        const queryData = {
            template_master_id: props.templateId,
            user_company_id: userInfo.user_company_id,
            menu_mapping_comment: props.data.menu_mapping_comment,
            menu_mapping_name: props.data.menu_mapping_name,
            template_master_is_active: props.data.menu_mapping_is_active,
            menu_mapping_parent_id: props.data.menu_mapping_parent_id ? props.data.menu_mapping_parent_id : 0,
            menu_mapping_is_active: props.data.menu_mapping_is_active,
            menu_mapping_layout_json: JSON.stringify(w1),
            menu_mapping_filter_json: JSON.stringify(TOPFILTER),
            user_log_ip: ipAddress,
            user_master_id: userInfo.user_master_id,
            menu_mapping_id: menuMappingInfo.id,
        }
        const res = await errorHandler(insertMenuMapping, queryData);
        if (res) {
            const id = res.data.o_data[0].menu_mapping_id;
            await insertMenuLang(id);
            await insertMenuSecurity(id);

            var htmlString = `<span></span><p>${translation.success_insert}</p>`;
            var swalResFn = (response) => { navigate("/Menu") };
            success(htmlString, swalResFn);
        }
    }

    const insertMenuLang = async (mapId) => {
        const user = getUser();
        let queue = [];
        let caption = props.data.caption;
        for (let i = 0; i < caption.length; i++) {
            let body = {
                user_master_id: user.user_master_id,
                target_menu_mapping_id: mapId ? mapId : props.data.menu_mapping_id,
                menu_mapping_lang_code: caption[i].langCode,
                menu_mapping_caption_text: caption[i].caption,
            }

            queue.push(errorHandler(insertMenuMappingCaption, body));
        }

        await Promise.all(queue).then((val) => {
            //do something if success.
        });
    }

    const insertMenuSecurity = async (mapId) => {
        const data = props.data;
        const user = getUser();
        if (data?.security == null)
            return;
        let queue = [];
        let securityValue = "";

        for (let i = 0; i < data.security.length; i++) {
            securityValue = "";
            securityValue += data.security[i].create ? "C" : ""
            securityValue += data.security[i].update ? "U" : ""
            securityValue += data.security[i].delete ? "D" : ""
            let body = {
                user_master_id: user.user_master_id,
                security_value: securityValue,
                target_id: data.security[i].security_master_id,
                parent_copy: data.security[i].propagateToParent,
                child_copy: data.security[i].propagateToChildren,
                target_menu_mapping_id: mapId ? mapId : data.menu_mapping_id,
            }

            const promise = errorHandler(insertMenuMappingSetting, body);
            queue.push(promise);
        }

        await Promise.all(queue).then((val) => {
            console.log(val);
        });
    }

    const tab = document.querySelectorAll('.tab > div');
    const tabWrapper = document.querySelector('.tab-wrapper');
    tab.forEach((t, i) => {
        t.addEventListener('click', function () {
            if (document.querySelectorAll('.selected-card').length == 1) {
                if (i == 0) {
                    tabWrapper.classList.remove('select-template');
                    tabWrapper.classList.add('select-chart');
                }
                if (i == 1) {
                    tabWrapper.classList.remove('select-chart');
                    tabWrapper.classList.add('select-template');
                }
            }
        })
    })

    const openAddFilter = () => {
        setAddFilterVisibility(!isAddFilterVisible);
    }
    //add filter start
    const addFilter = (type) => {
        let component;
        if (type == 'date') {
            component = { type: "date", name: "YYYY-MM-DD", icon: "dx-icon-ix-date-range", active: "inactive" }
        } else if (type == 'realtime') {
            component = { type: "realtime", name: "RealTime", icon: "dx-icon-ix-real-time", active: "inactive" }
        } else if (type == 'dropdown') {
            component = { type: "dropdown", name: "Drop Down", icon: "dx-icon-ix-dropdown", active: "inactive" }
        } else if (type == 'button') {
            component = { type: "button", name: "Button", icon: "dx-icon-ix-button", active: "inactive" }
        } else if (type == 'toggle') {
            component = { type: "toggle", name: "Toggle", icon: "dx-icon-ix-toggle", active: "inactive" }
        } else if (type == 'checkbox') {
            component = { type: "checkbox", name: "Check box", icon: "dx-icon-ix-checkbox", active: "inactive" }
        } else if (type == 'radio') {
            component = { type: "radio", name: "Radio button", icon: "dx-icon-ix-radio", active: "inactive" }
        } else if (type == 'search') {
            component = { type: "search", name: "Search", icon: "dx-icon-ix-search", active: "inactive" }
        }
        if (selectedFilter.length < 5) setSelectedFilter([component, ...selectedFilter]);
    }
    //add filter end

    //drag and drop event
    const dragStart = (e, position) => {
        dragItem.current = position;
        console.log(e.target.innerHTML);
    };
    const dragEnter = (e, position) => {
        dragOverItem.current = position;
        console.log(e.target.innerHTML);
    };
    const drop = (e) => {
        const copyListItems = [...selectedFilter];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = null;
        dragOverItem.current = null;
        setSelectedFilter(copyListItems);
    };
    //drag and drop event end


    //add filter popup event start
    const clickSelectedFilter = (index) => {
        let copiedFilter = [...selectedFilter];
        selectedFilter.forEach((filter, i) => { if (i != index) filter.active = "inactive" });
        if (selectedFilter[index].active == "active") {
            selectedFilter[index].active = "inactive"
            setFilterDetail('');
        } else {
            selectedFilter[index].active = "active";
            setFilterDetail(selectedFilter[index].type);
        }
        setSelectedFilter(copiedFilter);
    }

    const removeFilter = () => {
        setSelectedFilter(selectedFilter.filter(f => f.active != "active"));
        setFilterDetail('');
    }

    const saveFilter = () => {
        setSavedFilter(selectedFilter);
        setAddFilterVisibility(false);
    }
    //add filter popup event end

    //add filter popup start
    const renderAddFilter = () => {
        return (
            <div className="popup-add-filter">
                <div className="popup-header">
                    <p className="popup-title">{translation.add_filter}</p>
                    <span className="material-icons-outlined">info</span>
                </div>
                <div className="popup-body">
                    <div className="selected-filter">
                        <div className="draggable-container">
                            {selectedFilter?.map((filter, index) => (
                                <div className="draggable" active={filter.active} draggable="true" onDragStart={(e) => dragStart(e, index)} onDragEnter={(e) => dragEnter(e, index)} onDragEnd={drop} onClick={() => clickSelectedFilter(index)} key={index}>
                                    <p>{filter.name}</p>
                                    <span className={filter.icon}></span>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="filters">
                        <div className="filter-list">
                            <ul>
                                <li onClick={() => addFilter('date')}>
                                    <span className="dx-icon-ix-date-range"></span>
                                    <p>{translation.date}</p>
                                </li>
                                <li onClick={() => addFilter('realtime')}>
                                    <span className="dx-icon-ix-real-time"></span>
                                    <p>{translation.real_time}</p>
                                </li>
                                <li onClick={() => addFilter('dropdown')}>
                                    <span className="dx-icon-ix-dropdown"></span>
                                    <p>{translation.dropdown}</p>
                                </li>
                                <li onClick={() => addFilter('search')}>
                                    <span className="dx-icon-ix-search"></span>
                                    <p>{translation.search}</p>
                                </li>
                                <li onClick={() => addFilter('button')}>
                                    <span className="dx-icon-ix-button"></span>
                                    <p>{translation.button}</p>
                                </li>
                                <li onClick={() => addFilter('toggle')}>
                                    <span className="dx-icon-ix-toggle"></span>
                                    <p>{translation.toggle}</p>
                                </li>
                                <li onClick={() => addFilter('checkbox')}>
                                    <span className="dx-icon-ix-checkbox"></span>
                                    <p>{translation.check_box}</p>
                                </li>
                                <li onClick={() => addFilter('radio')}>
                                    <span className="dx-icon-ix-radio"></span>
                                    <p>{translation.radio_button}</p>
                                </li>
                            </ul>
                        </div>
                        <div className="filter-detail">
                            {filterDetail == "" &&
                                (<div className="select-message">
                                    <div></div>
                                    <p>{translation.plz_select_comp}</p>
                                </div>)
                            }
                            {filterDetail &&
                                (<ScrollView>
                                    <div className="filter-detail-header">
                                        <div className="filter-detail-title">
                                            <span className="dx-icon-ix-date-range"></span>
                                            <p>{translation.date}</p>
                                        </div>
                                        <Button
                                            className="btn-s-r btn-hover-red"
                                            type="normal"
                                            stylingMode="text"
                                            icon="trash"
                                            onClick={removeFilter}
                                        />
                                    </div>
                                    <div className="filter-detail-body">
                                        <div className="dx-fieldset">
                                            <div className="dx-field column">
                                                <div className="dx-field-label"><p>{translation.type}</p></div>
                                                <div className="dx-field-value">
                                                    <TextBox
                                                        className="ix-tfs-r"
                                                    />
                                                </div>
                                            </div>
                                            <div className="dx-field column">
                                                <div className="dx-field-label"><p>{translation.option}0</p></div>
                                                <div className="dx-field-value">
                                                    <SelectBox
                                                        className="ix-tfs-r"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dx-fieldset">
                                            <div className="dx-field column">
                                                <div className="dx-field-label"><p>{translation.option}1</p></div>
                                                <div className="dx-field-value">
                                                    <TextBox
                                                        className="ix-tfs-r"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dx-fieldset">
                                            <div className="dx-field column">
                                                <div className="dx-field-label"><p>{translation.option}2</p></div>
                                                <div className="dx-field-value">
                                                    <TextBox
                                                        className="ix-tfs-r"
                                                    />
                                                </div>
                                            </div>
                                            <div className="dx-field column">
                                                <div className="dx-field-label"><p>{translation.option}3</p></div>
                                                <div className="dx-field-value checkbox">
                                                    <div>
                                                        <div><CheckBox defaultValue={false} /></div>
                                                        <label>{translation.check}2</label>
                                                    </div>
                                                    <div>
                                                        <div><CheckBox defaultValue={false} /></div>
                                                        <label>{translation.check}2</label>
                                                    </div>
                                                    <div>
                                                        <div><CheckBox defaultValue={true} /></div>
                                                        <label>{translation.check}2</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dx-fieldset">
                                            <div className="dx-field column">
                                                <div className="dx-field-label"><p>{translation.option}4</p></div>
                                                <div className="dx-field-value">
                                                    <SelectBox
                                                        className="ix-tfs-r"
                                                    />
                                                </div>
                                            </div>
                                            <div className="dx-field column">
                                                <div className="dx-field-label"><p>{translation.option}5</p></div>
                                                <div className="dx-field-value">
                                                    <TextBox
                                                        className="ix-tfs-r"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="filter-detail-footer">
                                        <p className="info-comment">
                                            <span className="material-icons-outlined">info</span>
                                            <div dangerouslySetInnerHTML={{ __html: translation.specify_date }} />
                                        </p>
                                    </div>
                                </ScrollView>)
                            }
                        </div>
                    </div>
                </div>
                <div className="popup-footer">
                    <Button
                        width={86}
                        text={translation.cancel}
                        type="normal"
                        stylingMode="text"
                        className="btn-s-r"
                        onClick={() => setAddFilterVisibility(false)}
                    />
                    <Button
                        text={translation.save}
                        width={86}
                        type="default"
                        stylingMode="contained"
                        className="btn-s-r"
                        onClick={saveFilter}
                    />
                </div>
            </div>
        )
    }
    //add filter popup end


    //data setting popup start
    const resetUseState = () => {
        setSelectedColumns([])
        setTbodyData([])
        PANE_Y_AXIS_CHART_INFO = [];
        PANE_X_AXIS_CHART_INFO = [];
        FILTER_CONFIG = initFilterConfig;
    }

    const getCollectionList = async () => {
        const param = {};

        const res = await errorHandler(getCollListAPI, param);
        if (res) {
            setCollectionList(res.data);//o_data none
        }
    }

    const onDropdownChanged = async (e) => {
        resetUseState();
        var coll_val = e.value;
        //to update the pane info
        handleCollectionNameUpdate(coll_val);
        const param = { "coll_name": coll_val, "db_name": null };
        const res = await errorHandler(getCollDataAPI, param);
        if (res) {
            setTbodyData(res.o_data.data);//o_data sequence
        } else {
            setTbodyData([]);
        }
    }

    const openDataSetting = () => {
        getCollectionList();
        setDataSettingVisibility(!isDataSettingVisible);
    }

    const renderDataSetting = () => {
        const handleCheckBoxChange = (e) => {
            // Destructuring
            const value = e.element.innerText;
            const checked = e.value;
            const sC = selectedColumns;
            // Case 1 : The user checks the box
            if (checked) {
                setSelectedColumns([...sC, {
                    "column": value,
                    "legend": value,
                    "color": "#" + Math.random().toString(16).substr(-6)
                }]);
            }
            // Case 2  : The user unchecks the box
            else {
                setSelectedColumns(
                    sC.filter((e) => e.column !== value)
                );
            }
        };


        return (
            <div className="popup-data-setting">
                <div className="popup-header">
                    <p className="popup-title">{translation.add_data}</p>
                </div>
                <div className="popup-body">

                    <div className="data-setting-tab-content">
                        <div className="active">
                            <div className="select-dataset">
                                <div className="dx-field">
                                    <div className="dx-field-label">{translation.select_dataset}</div>
                                    <div className="dx-field-value">
                                        <SelectBox
                                            dataSource={collectionList}
                                            displayExpr="name"
                                            valueExpr="id"
                                            onValueChanged={onDropdownChanged}
                                            onOpened={(e) => {
                                                if (e.component.option("value")) {
                                                    alert(translation.alert_change_dropdown);
                                                }
                                            }}
                                            className="ix-tfs-r"
                                            width={316}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="select-dataset-datagrid">
                                <div className="table-wrapper">
                                    <ScrollView direction="both">
                                        <table>
                                            <thead>
                                            </thead>
                                            <tbody id="tbodyDataBinding">

                                                {
                                                    tbodyData.map((row, index) => (
                                                        <tr key={index}>
                                                            {row.map((item, ind) => {
                                                                if (ind == 0) return <><td><div><CheckBox default={false} text={item} onValueChanged={handleCheckBoxChange} /></div></td><td><p>{item}</p></td></>;
                                                                else return <td><p>{item}</p></td>;
                                                            })}
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>


                                    </ScrollView>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="popup-footer">
                    <Button
                        width={86}
                        text={translation.cancel}
                        type="normal"
                        stylingMode="text"
                        className="btn-s-r"
                        onClick={() => setDataSettingVisibility(false)}
                    />
                    <Button
                        text={translation.next}
                        width={86}
                        type="default"
                        stylingMode="contained"
                        className="btn-s-r"
                        onClick={() => setDataSettingVisibility(false)}
                    />
                </div>
            </div>
        )
    }
    //data setting popup end

    //tab event start
    const clickTab = (index) => {
        const tabs = document.querySelectorAll('.tab p');
        const tabContents = document.querySelectorAll('.tab-content > div');
        tabs.forEach(tab => tab.classList.remove('active'));
        tabs[index].classList.add('active');
        tabContents.forEach(content => content.classList.remove('active'));
        tabContents[index].classList.add('active');
    }
    //tab event end

    //handle card title change event
    const handleCardTitlleChange = (event) => {
        if (event.event != undefined) {
            let titleVal = event.event?.target?.value != undefined ? event.event.target.value : "";
            let selectedCard = document.querySelectorAll('.selected-card');
            if (selectedCard.length) {
                let elId = selectedCard[0].id;
                //let innerHTMLContent = `<img src=${chartType.imgSrc} className="ca"/>`;
                document.querySelectorAll(`#${elId}> .card-header > .card-title`)[0].innerText = titleVal;
                setCardTitle(titleVal);
                const w1 = { ...w };
                w1.panes = updatePaneTitle(w1.panes, elId, titleVal);
                setW(w1);
            }
        }
    }
    //end of card titile change event

    //handleCollectionname update
    const handleCollectionNameUpdate = (collName) => {
        let selectedCard = document.querySelectorAll('.selected-card');
        if (selectedCard.length) {
            let elId = selectedCard[0].id;
            let cId = `#${elId}> .card-body> div.dynamic-chart`;
            COLLECTION_NAME = collName;
        }
    }
    //end handleCollectionname update

    //handle y axis and x axis chart info
    const handleAxisTitle = (e) => {
        let selectedCard = document.querySelectorAll('.selected-card');
        if (selectedCard.length) {
            const value = e.value;
            let elId = selectedCard[0].id;
            let cId = `#${elId}> .card-body> div.dynamic-chart`;
            const w1 = { ...w };
            let axisFlag = e.event.currentTarget.name;
            axisFlag == "yAxisTitle" ? Y_AXIS_TITLE = value : X_AXIS_TITLE = value;
            w1.panes = updatePaneChartInfo(w1.panes, elId, cId);
            setW(w1);
        }
    }

    const handleYAxis = (e, item) => {
        let selectedCard = document.querySelectorAll('.selected-card');
        if (selectedCard.length) {
            // Destructuring
            const value = e.element.innerText;
            const checked = e.value;
            if (checked) {
                e.element.parentElement.nextElementSibling.classList.remove("read-only")
                PANE_Y_AXIS_CHART_INFO.push({
                    "fieldName": item.column,
                    "legend": item.column,
                    "color": item.color
                });
            }
            else {
                e.element.parentElement.nextElementSibling.classList.add("read-only")
                PANE_Y_AXIS_CHART_INFO = PANE_Y_AXIS_CHART_INFO.filter((e) => e.fieldName !== value);
            }
            let elId = selectedCard[0].id;
            let cId = `#${elId}> .card-body> div.dynamic-chart`;
            const w1 = { ...w };
            w1.panes = updatePaneChartInfo(w1.panes, elId, cId);
            setW(w1);
        }
    }
    const handleXAxis = (e) => {
        let selectedCard = document.querySelectorAll('.selected-card');
        if (selectedCard.length) {
            // Destructuring
            const value = e.element.innerText;
            const checked = e.value;
            if (checked) {
                PANE_X_AXIS_CHART_INFO.push(value);
            }
            else {
                PANE_X_AXIS_CHART_INFO = PANE_X_AXIS_CHART_INFO.filter((e) => e !== value)
            }
            let elId = selectedCard[0].id;
            let cId = `#${elId}> .card-body> div.dynamic-chart`;
            const w1 = { ...w };
            w1.panes = updatePaneChartInfo(w1.panes, elId, cId);
            setW(w1);
        }
    }
    const handleLegend = (e) => {
        let columnValue = e.element.parentElement.dataset.val;
        let value = e.value;
        let selectedCard = document.querySelectorAll('.selected-card');
        if (selectedCard.length) {
            PANE_Y_AXIS_CHART_INFO = PANE_Y_AXIS_CHART_INFO.map((e) => {
                if (e.fieldName === columnValue) {
                    e.legend = value;
                }
                return e;
            });
            let elId = selectedCard[0].id;
            let cId = `#${elId}> .card-body> div.dynamic-chart`;
            const w1 = { ...w };
            w1.panes = updatePaneChartInfo(w1.panes, elId, cId);
            setW(w1);
        }
    }
    const handleColor = (e) => {
        let columnValue = e.element.parentElement.dataset.val;
        let selectedCard = document.querySelectorAll('.selected-card');
        if (selectedCard.length) {
            const value = e.value;
            PANE_Y_AXIS_CHART_INFO = PANE_Y_AXIS_CHART_INFO.map((e) => {
                if (e.fieldName === columnValue) {
                    e.color = value;
                }
                return e;
            });
            let elId = selectedCard[0].id;
            let cId = `#${elId}> .card-body> div.dynamic-chart`;
            const w1 = { ...w };
            w1.panes = updatePaneChartInfo(w1.panes, elId, cId);
            setW(w1);
        }
    }

    const handleConfig = (e, type) => {
        
        const checked = e.value;
        let selectedCard = document.querySelectorAll('.selected-card');

        if (checked) {
            if (type != "dateTime") {
                e.element.parentElement.nextElementSibling.classList.remove("read-only")
            }
            if (type == "realTime") {
                FILTER_CONFIG.isRealTime = true;
                FILTER_CONFIG.intervalVal = intervalVal;
            }
            else if (type == "dateTimeOp") {
                FILTER_CONFIG.isDateTimeField = true;
            }
            else {
                FILTER_CONFIG.dateTimeField = e.value;
            }
        } else {
            if (type == "realTime") {
                FILTER_CONFIG.isRealTime = false;
                FILTER_CONFIG.intervalVal = 0;
            }
            else if (type == "dateTimeOp") {
                FILTER_CONFIG.isDateTimeField = false;
            }
            else {
                FILTER_CONFIG.dateTimeField = "";
            }
            if (type != "dateTime") {
                e.element.parentElement.nextElementSibling.classList.add("read-only")
            }
        }
        let elId = selectedCard[0].id;
        const w1 = { ...w };
        w1.panes = updateFilterInfo(w1.panes, elId);
        setW(w1);
    }
    //end handle y and x axis chart info
    return (
        <>
            <div className="menu-binding-data">
                <div className="right-content-title">
                    <PageTitle pageState={location.state.title} lastPage={translation.binding_data} />
                    {/*<div className="page-number"><span className="current-num">3</span>/<span className="total-num">3</span></div>*/}
                </div>
                <div className="right-content-body">
                    <div className="card left-controller">
                        <div className="card-body">
                            <div className="menu-name">
                                <p className="text-color-gray">{translation.menu_name}</p>
                                <h6>{location.state.formData.menu_mapping_name}</h6>
                            </div>
                            <div className="selected-template">
                                <p className="text-color-gray">{translation.selected_template}</p>
                                <h6>{templateName}</h6>
                            </div>
                            <div className="card-option">
                                <h6>{translation.card_option}</h6>
                                <div className="not-clicked-card">
                                    <p className="info-comment">
                                        <span className="material-icons-outlined">info</span>
                                        <div dangerouslySetInnerHTML={{ __html: translation.select_box_from_canvas }}></div>
                                    </p>
                                </div>
                                <div className="clicked-card">
                                    <div className="card-title">
                                        <p className="text-color-gray">{translation.card_title}</p>
                                        <TextBox
                                            valueChangeEvent="keyup"
                                            onValueChanged={handleCardTitlleChange}
                                            showClearButton={true}
                                            applyValueMode="instantly"
                                            id="txtCardTitle"
                                            className="ix-tfs-r"
                                            value={cardTitle}
                                        />
                                    </div>
                                    <div className="data-setting">
                                        <Button
                                            className="btn-s-r"
                                            type="default"
                                            stylingMode="contained"
                                            text={translation.data_setting}
                                            width="100%"
                                            onClick={openDataSetting}
                                        />
                                    </div>
                                    <div className="tab">
                                        <p className="active //disabled" onClick={() => clickTab(0)}>{translation.y_axis}</p>
                                        <p onClick={() => clickTab(1)}>{translation.x_axis}</p>
                                        <p onClick={() => clickTab(2)}>{translation.filter_config}</p>
                                    </div>
                                    <div className="tab-content">
                                        <div className="y-axis-content active">
                                            <div className="y-axis-title">
                                                <p className="text-color-gray">{translation.title}</p>
                                                <TextBox name="yAxisTitle" onValueChanged={handleAxisTitle} valueChangeEvent="keyup" applyValueMode="instantly"
                                                    className="ix-tfs-r"
                                                />
                                            </div>
                                            <div>
                                                {
                                                    selectedColumns.map((row, index) => (
                                                        <div className="y-axis-list" data-val={row.column}>
                                                            <div><CheckBox text={row.column} onValueChanged={(e) => {
                                                                handleYAxis(e, row)
                                                            }} /></div>
                                                            <div className="y-axis-custom read-only" data-val={row.column} >
                                                                <TextBox className={`tx_${row.column} ix-tfs-xs`} defaultValue={row.column} name={row.column} onValueChanged={handleLegend} placeholder="legend" valueChangeEvent="keyup" applyValueMode="instantly" />
                                                                <ColorBox className={`cl_${row.column}`} name={row.column} defaultValue={row.color} onValueChanged={handleColor} valueChangeEvent="keyup" applyValueMode="instantly" />
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                        <div className="x-axis-content">
                                            <div className="x-axis-title">
                                                <p className="text-color-gray">{translation.title}</p>
                                                <TextBox name="xAxisTitle" onValueChanged={handleAxisTitle} valueChangeEvent="keyup" applyValueMode="instantly"
                                                    className="ix-tfs-r"
                                                />
                                            </div>
                                            <div>
                                                {
                                                    selectedColumns.map((row, index) => (
                                                        <div><CheckBox default={false} text={row.column} onValueChanged={handleXAxis} /></div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                        <div className="config-content">
                                            <div className="date-time">
                                                <div className="config-type">
                                                    <p>{translation.date_time}</p>
                                                    <Switch defaultValue={false} onValueChanged={(e) => { handleConfig(e, "dateTimeOp") }} />
                                                </div>
                                                <div className="config-setting read-only">
                                                    <SelectBox
                                                        className="ix-tfs-r"
                                                        dataSource={selectedColumns}
                                                        displayExpr="column"
                                                        valueExpr="column"
                                                        defaultValue={selectedColumns[0]}
                                                        onValueChanged={(e) => { handleConfig(e, "dateTime") }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="real-time">
                                                <div className="config-type">
                                                    <p>{translation.real_time}</p>
                                                    <Switch defaultValue={false} onValueChanged={(e) => { handleConfig(e, "realTime") }} />
                                                </div>
                                                <div className="config-setting read-only">
                                                    <p>{translation.set_interval}</p>
                                                    <div>
                                                        <div>
                                                            <Button
                                                                className="btn-s-r"
                                                                type="normal"
                                                                stylingMode="text"
                                                                icon="minus"
                                                                onClick={() => { ((intervalVal - 1) < 5 == true) ? alert(translation.min_value_5) : setIntervalVal(intervalVal - 1) }}
                                                            />
                                                        </div>
                                                        <div className="sec">
                                                            <TextBox mode='number'
                                                                className="ix-tfs-r" value={intervalVal} onValueChanged={(e) => setIntervalVal(parseInt(e.value))} width={56}
                                                            />
                                                        </div>
                                                        <div>
                                                            <Button
                                                                className="btn-s-r"
                                                                type="normal"
                                                                stylingMode="text"
                                                                icon="add"
                                                                onClick={() => { setIntervalVal(intervalVal + 1) }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="no-content">
                                            <div></div>
                                            <p>{translation.plz_data_setting}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">

                            <div className="btn-group">
                                <Button
                                    type="normal"
                                    stylingMode="text"
                                    className="btn-s-r"
                                    text="PREV"
                                    onClick={() => navigate(-1)}
                                >
                                    <div>
                                        <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                        <span className="dx-button-text">{translation.prev}</span>
                                    </div>
                                </Button>
                                <Button
                                    type="default"
                                    stylingMode="contained"
                                    className="btn-s-r"
                                    text="SAVE"
                                    onClick={handleSaveMenu}
                                >
                                    <div>
                                        <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                        <span className="dx-button-text">{translation.save}</span>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="template-wrapper">
                        {/*<div className="template-controller">*/}
                        {/*    <div className="template-controller-left">*/}
                        {/*        <div>*/}
                        {/*            <Button*/}
                        {/*                type="normal"*/}
                        {/*                stylingMode="text"*/}
                        {/*                className="btn-s-xs dx-button-toolbar"*/}
                        {/*                icon="ix-preview"*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*        <div>*/}
                        {/*            <Button*/}
                        {/*                type="normal"*/}
                        {/*                stylingMode="text"*/}
                        {/*                className="btn-s-xs dx-button-toolbar"*/}
                        {/*                icon="edit"*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div className="template-controller-right">*/}
                        {/*        {savedFilter.length > 0 &&*/}
                        {/*            <div className="selected-filter">*/}
                        {/*                {savedFilter?.map((filter, index) => (<span className={filter.icon}></span>))}*/}
                        {/*            </div>*/}
                        {/*        }*/}
                        {/*        <div>*/}
                        {/*            <Button*/}
                        {/*                type="normal"*/}
                        {/*                stylingMode="text"*/}
                        {/*                className="btn-s-xs dx-button-toolbar"*/}
                        {/*                icon="add"*/}
                        {/*                text="ADD FILTER"*/}
                        {/*                onClick={openAddFilter}*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="template">
                            {renderSplitPanes(w, w.id)}
                        </div>
                    </div>
                </div>
                <Popup
                    width={960}
                    height="auto"
                    visible={isAddFilterVisible}
                    hideOnOutsideClick={true}
                    onHiding={openAddFilter}
                    contentRender={renderAddFilter}
                    dragEnabled={true}
                    position="center"
                    showTitle={false}
                />
                <Popup
                    width={1000}
                    height="auto"
                    visible={isDataSettingVisible}
                    hideOnOutsideClick={true}
                    onHiding={openDataSetting}
                    contentRender={renderDataSetting}
                    dragEnabled={true}
                    position="center"
                    showTitle={false}
                />
            </div>
        </>
    );
};

export default DynamicSplitPaneDataBinding;
