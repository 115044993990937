import React, { useState, useRef } from "react";
import { ScrollView } from "devextreme-react";
import Button from "devextreme-react/button";
import Popup from "devextreme-react/popup";
import BaseDataGrid from "../../../components/common/BaseDataGrid";
import useTranslation from '../../../components/customHooks/translations';
import { useEffect } from "react";
import { getMenuMappingAndPartPage } from "../../../apiInterface/menu/MenuAPI";
import { getLanguage, getUser } from "../../../apiInterface/utils/Common";

export default function SelectGridLinkPopup(props) {
    const {
        visible,
        onClose,
        value,
        onAddLinkList = () => { },
        serviceId,
    } = props;

    const translation = useTranslation();
    const lang = getLanguage();
    const [dataSource, setDataSource] = useState();
    const [selectedList, setSelectedList] = useState([]);
    const [btnDisable, setBtnDisable] = useState(true);
    const gridRef = useRef();

    useEffect(() => {
        const instance = gridRef.current?.getInstance();
        setSelectedList([]);
        instance?.selectRows([]);
        if (value !== undefined) {
            const linkList = value.map(v=>v.link);
            setSelectedList(linkList);
            instance?.selectRows(linkList);
        }

        const fetching = async () => {
            const user = getUser();
            const param = {
                user_company_id: user.user_company_id,
                sys_lang_code: lang,
                menu_mapping_service_id: serviceId,
                user_master_id: user.user_master_id
            }
            const res = await getMenuMappingAndPartPage(param);
            if(res !== "fail" && res.message === "MSG_100") {
                formatDataSource(res.data.o_data);
                // setDataSource(res.data.o_data)
            }
        }
        fetching();
    }, [visible])

    const header = [
        { dataField: "no", caption: "No", dataType: "string", allowReordering: true },
        { dataField: "sys_part_page_template_type", caption: "Serivce", dataType: "string", allowReordering: true },
        { dataField: "sys_part_page_div_name", caption: "Page Type", dataType: "string", allowReordering: true },
        { dataField: "sys_part_page_name", caption: "Menu Name", dataType: "string", allowReordering: true },
        { dataField: "sys_part_page_parameter_json", caption: "Parameter", dataType: "string", allowReordering: true },
        { dataField: "sys_part_page_comment", caption: "Comment", dataType: "string", allowReordering: true },
    ]

    const formatDataSource = (dataSourceList) => {
        // const dataSource = dataSourceList?.map((data, i) => {
        //     const pageNameCaption = data.sys_part_page_div === "custommenu" ?
        //         JSON.parse(data.sys_part_page_name_caption) : [];
        //     const pageName = pageNameCaption.find(name => name.menu_mapping_lang_code === lang)?.menu_mapping_caption_text;
        //     const paramList = data.sys_part_page_div === "custommenu" ?
        //         data.sys_part_page_parameter_json?.map(param => param.value.binding_column[0].column_name) :
        //         data.sys_part_page_parameter_json?.map(param => param.parameter_name)
        //     return {
        //         "No": i + 1,
        //         "Serivce": data.sys_part_page_template_type,
        //         "Page Type": data.sys_part_page_div_name,
        //         "Menu Name": pageName?.length > 0 ? pageName : data.sys_part_page_name,
        //         "Parameter": paramList,
        //         "Comment": data.sys_part_page_comment
        //     };
        // })

        const dataSource = dataSourceList?.map((data, i) => {
            const paramList = data.sys_part_page_div === "custommenu" ?
                data.sys_part_page_parameter_json?.map(param => param.value.binding_column[0].column_name) :
                data.sys_part_page_parameter_json?.map(param => param.parameter_name)
            data.sys_part_page_parameter_json = paramList;
            data.no = i + 1;
            return data;
        })
        setDataSource(dataSource);
    }

    const close = () => {
        deselectAll();
        onClose();
    }

    const deselectAll = () => {
        const instance = gridRef.current.getInstance();
        instance.deselectAll();
        setSelectedList([]);
        setBtnDisable(true);
    }

    const removeTag = (i) => {
        let cur = [...selectedList];
        let removed = cur.splice(i, 1);
        setSelectedList(cur);

        const instance = gridRef.current.getInstance();
        instance.deselectRows(removed);
    }

    const onSave = () => {
        // valueChanged(selectedList);


        let linkValue = selectedList.map((link, idx) => {
            return {
                link: link,
                value: {
                    wayToMove: "page",
                    linkName: "",
                    captionEn: "",
                    captionKo: "",
                    params: link.sys_part_page_parameter_json.length > 0 ?
                        link.sys_part_page_parameter_json.map(p => { return ({ [p]: {} }) }) :
                        []
                }
            }
        });
        onAddLinkList(linkValue);
        close();
    }

    return (
        <Popup
            width={1100}
            height={850}
            visible={visible}
            title="Select Grid Link"
            hideOnOutsideClick={true}
            onHiding={onClose}
        >
            <div className="popup-select-gridlink">
                <div className="popup-body">
                    <div className="selected-item-viewer">
                        <div className="icon-checked">
                            <i></i>
                        </div>
                        <div className="selected-item-field">
                            {
                                selectedList.map((col, i) => {
                                    const pageNameCaption = col.sys_part_page_div === "custommenu" ?
                                        JSON.parse(col.sys_part_page_name_caption) : [];
                                    const pageName = pageNameCaption.find(name => name.menu_mapping_lang_code === lang)?.menu_mapping_caption_text;
                                    return (
                                        <ul className="selected-items">
                                            <li className="item">
                                                <p className="item-name">{col["sys_part_page_name"]}</p>
                                                <i className="material-icons btn-delete" onClick={() => removeTag(i)}>
                                                    cancel
                                                </i>
                                            </li>
                                        </ul>
                                    )
                                })
                            }
                            <div className="btn-all-delete" onClick={deselectAll}>
                                <button>{translation.delete_all}</button>
                            </div>
                        </div>
                    </div>
                    <div className="item-select-box">
                        <ScrollView>
                            <div className="container-wrapper">
                                <BaseDataGrid
                                    dataSource={dataSource}
                                    allowModify={false}
                                    headers={header}
                                    onSelectionChanged={(selected) => {
                                        if (selected != null) {
                                            setSelectedList(selected);
                                            setBtnDisable(false);
                                        }
                                        else {
                                            setSelectedList([]);
                                            setBtnDisable(true);
                                        }
                                    }}
                                    ref={gridRef}
                                />
                            </div>
                        </ScrollView>
                    </div>
                </div>
                <div className="popup-footer">
                    <Button width={86} text={translation.cancel} type="normal" stylingMode="text" className="btn-s-r" onClick={close} />
                    <Button width={86} text={translation.confirm} type="default" stylingMode="contained" className="btn-s-r" onClick={onSave} disabled={btnDisable}/>
                </div>
            </div>
        </Popup>
    )
}