import React, { useEffect, useState, useRef } from "react";
import Button from 'devextreme-react/button';
import DataGrid, {
    Column, Editing, Paging, Lookup, RequiredRule, Toolbar, Item, Pager, Selection, Texts
} from 'devextreme-react/data-grid';
import BtnTooltip from "../../components/common/BtnTooltip";
import useTranslation from "../../components/customHooks/translations";
import EquipmentAddPupup from "./EquipmentAddPupup";
import BaseDataGrid from "../../components/common/BaseDataGrid";


export default function EquipmentGrid(props) {
    const {
        width,
        dataSource,
        setDataSource,
        onDataChange = () => { }
    } = props;

    const [popupVisible, setPopupVisible] = useState(false);
    const translation = useTranslation();

    const headers = [
        { dataField: "machine_mold_name", caption: translation.machine_mold_name.toUpperCase(), dataType: "number", isEdit: false, width: 100, alignment: "center", allowReordering: true, isFixed: true },
        { dataField: "machine_mold_code", caption: translation.machine_mold_code.toUpperCase(), dataType: "string", isRequired: true, allowReordering: true, isFixed: true },
        { dataField: "machine_mold_no", caption: translation.machine_mold_no.toUpperCase(), dataType: "string", allowReordering: true, isFixed: true },
        { dataField: "operation_factory_name", caption: translation.operation_factory_name.toUpperCase(), dataType: "string", allowReordering: true },
        { dataField: "machine_mold_comment", caption: translation.machine_mold_comment.toUpperCase(), dataType: "string", isEdit: false, width: 300, allowReordering: true },
    ]

    const onPopupExit = (e) => {
        setPopupVisible(false);
    }

    const onPopupOk = (e) => {
        setDataSource(e);
    }

    useEffect(() => {
        onDataChange(dataSource);
    }, [dataSource]);

    return (
        <div className="dx-fieldset">
            <div className="dx-field column">
                <div className="dx-field-label">
                    <p>{translation.mold}</p>
                </div>
                <div className="dx-field-value">
                    <div className="data-grid">

                        <BaseDataGrid
                            dataSource={dataSource}
                            headers={headers}
                            isPaging={true}
                            allowModify={true}
                            selectMode="multiple"
                            isRemote={true}
                            showEdit={false}
                            isFullSize={false}
                            addBtnType={"normal"}
                            addBtnText={translation.add_mold.toUpperCase()}
                            addAction={() => { setPopupVisible(true) }}
                            isDeleteWarning={false}
                            addBtnSize={120}
                            width={width}
                        />
                    </div>

                    <EquipmentAddPupup
                        visible={popupVisible}
                        title={translation.add_mold}
                        onExit={onPopupExit}
                        onClickOk={onPopupOk}
                    />

                    {/* Button Tooltip */}
                    <BtnTooltip target="#dx-toolbar-item-1" text={translation.delete} />
                    <BtnTooltip target="#dx-toolbar-item-2" text={translation.add} />
                    {/* <p className="info-comment"><span className="material-icons-outlined">info</span>{translation.info_add_group}</p> */}
                </div>
            </div>
        </div>
    );
}