import React, { useEffect, useState } from 'react';
import { getEveryService, getUserCompanyService, insertUserCompanyService, deleteUserCompanyService } from '../../apiInterface/home/SubscriptionAPI';
import useErrorHandler from "../../components/customHooks/useErrorHandler";
import { getIP, getLanguage, getUser } from '../../apiInterface/utils/Common';
//ㄴㅇㄹ
const Subscription = () => {
    const errorHandler = useErrorHandler();
    const [everyServiceList, setEveryServiceList] = useState([]);
    const [userCompanyServiceList, setUserCompanyServiceList] = useState([]);

    const getEveryServiceList = async () => {
        const param = {
            user_company_id: getUser().user_company_id,
            sys_lang_code: getLanguage()
        }
        const res = await errorHandler(getEveryService, param);
        if (res) {
            console.log("every service", res.data.o_data)
            setEveryServiceList(res.data.o_data)
        }
    }

    const getUserCompanyServiceList = async () => {
        const param = {
            user_company_id: getUser().user_company_id,
            sys_lang_code: getLanguage()
        }
        const res = await errorHandler(getUserCompanyService, param);
        if (res) {
            const serviceList = {};
            console.log("user company service", res.data.o_data)
            res.data.o_data.forEach(service => { serviceList[service.service_name] = service; })
            setUserCompanyServiceList(serviceList);
        }
    }

    const insertUserCompanyServiceByType = async (service) => {
        const startDate = new Date().toISOString().split('T')[0];
        const user = getUser();
        const ip = getIP();
        const param = {
            user_master_id: user.user_master_id,
            user_log_id: ip,
            user_company_id: user.user_company_id,
            user_company_template_type: service.service_value,
            user_company_service_start: startDate,
            user_company_service_end: "2100-12-06"
        }
        const res = await errorHandler(insertUserCompanyService, param);
        //if (res) {
        //    
        //} else {
        //    
        //}
    }

    const deleteUserCompanyServiceByServiceId = async (service) => {
        const user = getUser();
        const ip = getIP();
        const param = {
            user_master_id: user.user_master_id,
            user_log_ip: ip,
            user_company_id: user.user_company_id,
            user_company_service_id: userCompanyServiceList[service.service_name].service_id
        }
        const res = await errorHandler(deleteUserCompanyService, param);
        //if (res) {
        //    
        //} else {
        //    
        //}
    }

    const clickEvt = (service, isSubscribe) => {
        if (isSubscribe) deleteUserCompanyServiceByServiceId(service);
        else insertUserCompanyServiceByType(service);
    }

    const init = () => {
        getEveryServiceList();
        getUserCompanyServiceList();
    }

    useEffect(() => {
        init();
    }, []);


    const subscribeState = {true: "구독중", false: "구독하기"}

    return (
        <div>
            {everyServiceList.map((service) => (
                <div>
                    <h1>
                        {service.service_name}
                    </h1>
                    <span onClick={e => clickEvt(service, Object.keys(userCompanyServiceList).includes(service.service_name))}>
                        {subscribeState[Object.keys(userCompanyServiceList).includes(service.service_name)]}
                    </span>
                </div>
            ))}
        </div>

    );
};
export default Subscription;