import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

/*
this component prevents users from accessing form page in the wrong way.
check title value of state if invalid send back to previous page.
*/

export default function FormPageValidator(props) {
    const location = useLocation();
    const title = location.state?.title;
    const { path } = props;

    return (
        <>
            {
                title == undefined || (title != "Add" && title != "Edit")
                    ? <Navigate to={-1} />
                    : <></>
            }
        </>
    )
}