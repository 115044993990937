import { useState, useEffect } from 'react';
import { DropdownField, SearchableField, TextboxField } from './FormField';
import Button from 'devextreme-react/button';
import TextBox from 'devextreme-react/text-box';

export function SearchDataFilter(props) {
    const { idx, label, text, isColumn = true, size = 'xs', onSearch = () => {}, value, valueChanged = () => {}, clickDeleteBtn = () => {}, clickFilter = () => {} } = props;

    return (
        <div className="filter-search" onClick={(e) => clickFilter(idx, label)}>
            <div>
                <SearchableField label={label} isColumn={isColumn} size={size} hasTextboxPopup={true} onSearch={onSearch} text={text} />
                <Button className="btn-s-xs btn-hover-red" type="normal" stylingMode="text" icon="trash" onClick={clickDeleteBtn} />
            </div>
        </div>
    );
}
export function TextboxDataFilter(props) {
    const { idx, label, type, isColumn = true, size = 'xs', value, valueChanged = () => {}, clickDeleteBtn = () => {}, clickFilter = () => {} } = props;

    const [equals, setEquals] = useState();

    useEffect(() => {
        setEquals(value?.equals);
    }, [value]);

    useEffect(() => {
        if (equals) {
            if (type === 'string') valueChanged(idx, label, { type: 'equals', min: null, max: null, equals });
            else valueChanged(idx, type, { type: 'equals', min: null, max: null, equals });
        }
    }, [equals]);

    return (
        <div className="filter-textbox" onClick={(e) => clickFilter(idx, label)}>
            <div>
                <TextboxField label={label} value={equals} isColumn={isColumn} size={size} onValueChanged={(e) => setEquals(e.value)} valueChangeEvent="focusout" />
                <Button className="btn-s-xs btn-hover-red" type="normal" stylingMode="text" icon="trash" onClick={clickDeleteBtn} />
            </div>
        </div>
    );
}
export function DropdownDataFilter(props) {
    const { idx, label, value, isColumn = true, size = 'xs', valueChanged = () => {}, clickDeleteBtn = () => {}, clickFilter = () => {}, changeListHeight = () => {} } = props;

    const dataSource = [
        { title: 'Min / Max', type: 'minmax' },
        { title: 'equals', type: 'equals' },
    ];
    const [val, setVal] = useState(value);

    const onValueChange = (value) => {
        valueChanged(idx, label, value);
    };

    const onValueChanged = (e, type) => {
        // const originVal = { ...value };
        const originVal = { ...val };

        if (type === 'dropdown') {
            onValueChange({ type: e, min: null, max: null, equals: null });
            setVal({ type: e, min: null, max: null, equals: null });
        } else if (type === 'min') {
            originVal['min'] = e;
            onValueChange(originVal);
            setVal(originVal);
        } else if (type === 'max') {
            originVal['max'] = e;
            onValueChange(originVal);
            setVal(originVal);
        } else if (type === 'equals') {
            originVal['equals'] = e;
            onValueChange(originVal);
            setVal(originVal);
        }
        changeListHeight();
    };

    useEffect(() => {
        if (value) setVal(value);
    }, [value]);

    return (
        <div className="filter-dropdown" onClick={(e) => clickFilter(idx, label)}>
            <div>
                <DropdownField label={label} isColumn={isColumn} size={size} onItemClick={(e) => onValueChanged(e.itemData.type, 'dropdown')} dataSource={dataSource} value={val?.type} useLocalValue={false} valueExpr="type" displayExpr="title" />
                <Button className="btn-s-xs btn-hover-red" type="normal" stylingMode="text" icon="trash" onClick={clickDeleteBtn} />
            </div>
            {val?.type == 'minmax' && (
                <div className="min-max">
                    <TextBox className="ix-tfs-xs" value={val?.min} onValueChanged={(e) => e.event !== undefined && onValueChanged(e.value, 'min')} valueChangeEvent="keyup" />
                    <TextBox className="ix-tfs-xs" value={val?.max} onValueChanged={(e) => e.event !== undefined && onValueChanged(e.value, 'max')} valueChangeEvent="keyup" />
                </div>
            )}
            {val?.type == 'equals' && (
                <div className="equals">
                    <TextBox className="ix-tfs-xs" width="100%" value={val?.equals} onValueChange={(e) => onValueChanged(e, 'equals')} valueChangeEvent="keyup" />
                </div>
            )}
            {/* {value?.type == 'minmax' && (
                <div className="min-max">
                    <TextBox className="ix-tfs-xs" value={value?.min} onValueChanged={(e) => e.event !== undefined && onValueChanged(e.value, 'min')} valueChangeEvent="keyup" />
                    <TextBox className="ix-tfs-xs" value={value?.max} onValueChanged={(e) => e.event !== undefined && onValueChanged(e.value, 'max')} valueChangeEvent="keyup" />
                </div>
            )}
            {value?.type == 'equals' && (
                <div className="equals">
                    <TextBox className="ix-tfs-xs" width="100%" value={value?.equals} onValueChange={(e) => onValueChanged(e, 'equals')} valueChangeEvent="keyup" />
                </div>
            )} */}
        </div>
    );
}
export function PageFilter(props) {}
