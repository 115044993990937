﻿import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import Button from 'devextreme-react/button';
import LoadIndicator from 'devextreme-react/load-indicator';
import useTranslation from "../../components/customHooks/translations";
import useErrorHandler from "../../components/customHooks/useErrorHandler";
import { actionDirect, moveFocus, submit } from "../../components/common/FormFuncWithEnter";
import { useSwal } from '../../components/common/Swal';
import { getUser, getIP, getLanguage, getMenuMappingInfo } from '../../apiInterface/utils/Common';
import { getQualityBadTypeById, insertQualityBadTypeByCompanyId, updateQualityBadTypeByCompanyId } from "../../apiInterface/badTypeManagement/BadTypeManagementAPI";
import "../../contents/styles/badtype/bad-type-management-info-form.css";
import { DropdownField, SearchableField, TextboxField } from "../../components/common/FormField";
import { PageTitle } from "../../components/common/PageTitle.jsx";
import SelectItemPopup from "./SelectItemPopup";

const BadTypeManagementInfoForm = () => {
    const navigate = useNavigate();
    const translation = useTranslation();
    const location = useLocation();
    const title = location.state.title;
    const badtypeId = location.state.rowData?.quality_badtype_id;
    const errorHandler = useErrorHandler();
    const { success } = useSwal();

    const useOrNotItems = [
        { value: true, title: translation.use },
        { value: false, title: translation.notUse }
    ];
    const [badTypeData, setBadTypeData] = useState({ quality_badtype_is_active: true });
    const [popupVisible, setPopupVisible] = useState(false);

    const valueChanged = (value, key) => {
        setBadTypeData((cur) => {
            let newData = { ...cur };
            newData[key] = value;
            return newData;
        });
    }

    const getBadTypeInfoByBadTypeId = async () => {
        const lang = getLanguage();
        const param = {
            quality_badtype_id: badtypeId,
            sys_lang_code: lang,
        };
        const res = await errorHandler(getQualityBadTypeById, param);
        if (res) {
            const data = res.data.o_data[0];
            setBadTypeData(data);
        }
    }

    const insertBadTypeInfo = async () => {
        const user = getUser();
        const ipAddress = getIP();
        const menuMappingInfo = await getMenuMappingInfo();
        const queryData = {
            user_master_id: user.user_master_id,
            user_log_ip: ipAddress,
            user_menu_mapping_id: menuMappingInfo.id,
            quality_badtype_code: badTypeData.quality_badtype_code,
            item_master_id: badTypeData.item_master_id,
            quality_badtype_name: badTypeData.quality_badtype_name,
            quality_badtype_is_active: badTypeData.quality_badtype_is_active,
            quality_badtype_comment: badTypeData.quality_badtype_comment,
        }
        const res = await errorHandler(insertQualityBadTypeByCompanyId, queryData);
        if (res) {
            var swalResFn = (r) => {
                if (r.isConfirmed) {
                    navigate("../BadType");
                }
            };
            success(`<p>${translation.MSG_100}</p>`, swalResFn)
        }
    }

    const updateBadTypeInfo = async () => {
        const user = getUser();
        const ipAddress = getIP();
        const menuMappingInfo = await getMenuMappingInfo();
        const queryData = {
            user_master_id: user.user_master_id,
            user_log_IP: ipAddress,
            user_menu_mapping_id: menuMappingInfo.id,
            quality_badtype_id: badtypeId,
            item_master_id: badTypeData.item_master_id,
            quality_badtype_name: badTypeData.quality_badtype_name,
            quality_badtype_is_active: badTypeData.quality_badtype_is_active,
            quality_badtype_comment: badTypeData.quality_badtype_comment
        }

        const res = await errorHandler(updateQualityBadTypeByCompanyId, queryData);
        if (res) {
            var swalResFn = (r) => {
                if (r.isConfirmed) {
                    navigate("../BadType");
                }
            };
            success(`<p>${translation.MSG_100}</p>`, swalResFn)
        }
    }

    const cancelBtnOnClick = () => navigate(-1);

    useEffect(() => {
        const init = async () => {
            if (title === "Edit")
                getBadTypeInfoByBadTypeId();
        }
        init();
    }, []);

    const onOk = (e) => {
        if (e.length > 0) {
            valueChanged(e[0].item_master_name, "item_master_name");
            valueChanged(e[0].item_master_code, "item_master_code");
            valueChanged(e[0].item_master_id, "item_master_id");
        } else {
            valueChanged(null, "item_master_name");
            valueChanged(null, "item_master_code");
            valueChanged(null, "item_master_id");
        }
    }

    return (
        <>
            <div className="badtype-form">
                <div className="right-content-title">
                    <PageTitle pageState={title} />
                </div>
                <form onSubmit={actionDirect}>
                    <div className="right-content-body">
                        <div className="container">
                            <div className="dx-fieldset">
                                <TextboxField
                                    label={translation.quality_badtype_name}
                                    value={badTypeData?.quality_badtype_name}
                                    isEssential={true}
                                    esstialMsg={translation.quality_badtype_name + translation.is_required}
                                    onEnterKey={moveFocus}
                                    onValueChanged={e => valueChanged(e.value, "quality_badtype_name")}
                                    inputAttr={{ focusidx: 0 }}
                                />
                                <TextboxField
                                    label={translation.quality_badtype_code}
                                    value={badTypeData?.quality_badtype_code}
                                    isEssential={true}
                                    esstialMsg={translation.quality_badtype_code + translation.is_required}
                                    onEnterKey={moveFocus}
                                    readOnly={title === 'Edit' ? true : false}
                                    onValueChanged={e => valueChanged(e.value, "quality_badtype_code")}
                                    inputAttr={{ focusidx: 1 }}
                                />
                                {/*Searchable Field*/}
                                <SearchableField
                                    label={translation.quality_badtype_item_code}
                                    isWritable={false}
                                    isEssential={true}
                                    esstialMsg={translation.quality_badtype_select_item + translation.is_required}
                                    text={badTypeData.item_master_code}
                                    onSearch={() => setPopupVisible(true)}
                                    elementAttr={{ focusidx: 2 }}
                                    hasTextboxPopup={true}
                                />
                                <TextboxField
                                    label={translation.quality_badtype_item_name}
                                    value={badTypeData?.item_master_name}
                                    isEssential={true}
                                    esstialMsg={translation.quality_badtype_item_name + translation.is_required}
                                    onEnterKey={moveFocus}
                                    onValueChanged={(e) => valueChanged(e.value, "item_master_name")}
                                    inputAttr={{ focusidx: 3 }}
                                    readOnly={true}
                                    hasTextboxPopup={true}
                                    onSearch={() => setPopupVisible(true)}
                                />
                                 <DropdownField
                                    isSearchable={false}
                                    label={translation.quality_badtype_is_active}
                                    dataSource={useOrNotItems}
                                    value={badTypeData?.quality_badtype_is_active}
                                    valueExpr="value"
                                    displayExpr="title"
                                    onSelectionChanged={(e) => { valueChanged(e.selectedItem.value, "quality_badtype_is_active") }}
                                    isEssential={true}
                                    esstialMsg={translation.quality_badtype_is_active + translation.is_required}
                                    onEnterKey={moveFocus}
                                    inputAttr={{ focusidx: 4 }}
                                />
                                <TextboxField
                                    label={translation.quality_badtype_comment}
                                    value={badTypeData?.quality_badtype_comment}
                                    onEnterKey={moveFocus}
                                    onValueChanged={(e) => valueChanged(e.value, "quality_badtype_comment")}
                                    inputAttr={{ focusidx: 5 }}
                                />
                            </div>
                            <div className="block"></div>
                            {title == "Edit" &&
                                <div>
                                    <div className="dx-fieldset write-info">
                                        <div className="dx-field">
                                            <div className="dx-field-label">{translation.lastModifiedDate}</div>
                                            <div className="dx-field-value">{badTypeData?.modified_date}</div>
                                        </div>
                                        <div className="dx-field">
                                            <div className="dx-field-label">{translation.lastModifiedBy}</div>
                                            <div className="dx-field-value">{badTypeData?.modified_by_name}
                                                <span className="badge badge-job-position">{badTypeData?.modified_by_duty}</span>
                                                <span className="badge badge-team">{badTypeData?.modified_by_department}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="block"></div>
                                </div>
                            }
                            <div className="btn-group dx-fieldset">
                                <Button
                                    type="normal"
                                    stylingMode="text"
                                    className="btn-s-r"
                                    onClick={cancelBtnOnClick}
                                >
                                    <div>
                                        <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                        <span className="dx-button-text">{translation.cancel}</span>
                                    </div>
                                </Button>
                                <Button
                                    id="submitBtn"
                                    type="default"
                                    stylingMode="contained"
                                    className="btn-s-r"
                                    useSubmitBehavior={true}
                                    onClick={e => {
                                        if (title === 'Add') submit(e, insertBadTypeInfo);
                                        else if (title === 'Edit') submit(e, updateBadTypeInfo);
                                    }}
                                >
                                    <div>
                                        <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                        <span className="dx-button-text">{translation.save}</span>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
                <SelectItemPopup
                    visible={popupVisible}
                    onExit={() => setPopupVisible(false)}
                    onOk={onOk}
                />
            </div>
        </>
    );
}

export default BadTypeManagementInfoForm;
