import React, { useEffect, useState, useRef } from 'react';
import useTranslation from '../../components/customHooks/translations';
import BaseDataGrid from '../../components/common/BaseDataGrid';
import { getLanguage, getUser, getMenuMappingInfo, getIP } from '../../apiInterface/utils/Common';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import { Button, Popup, ScrollView } from 'devextreme-react';
import CustomStore from 'devextreme/data/custom_store';
import Badge from '../../components/common/Badge';
import { SearchableField, TextboxField, DropdownField, FilterComponent, FileField } from '../../components/common/FormField';
import { getInspectionLibraryDT, getSysDivisionCaptionByCode, deleteStandardLibrary } from '../../apiInterface/inspection/InspectionStandardLibraryAPI';
import { getInspectionLibrary } from '../../apiInterface/inspection/InspectionLibraryCondition';

let searchText = '';
const DIVISION_CODE = 'INSPECTIONTYPE';
const InspectionStandardGroupGrid = (props) => {
    const { visible, items, isDeselect, isVisible } = props;
    const translation = useTranslation();
    const errorHandler = useErrorHandler();
    const user = getUser();
    const lang = getLanguage();
    const gridRef = useRef();

    const [dropdown, setDropdown] = useState();
    const [selectedDropdown, setSelectedDropdown] = useState('');
    const [dataSource, setDataSource] = useState([]);

    // show & select row
    const showApplied = async (cell) => {
        isVisible(true);
        items(cell?.data);
    };

    // row selection changed
    const onRowSelectionChanged = (e) => {
        sessionStorage.removeItem('inspectionLibraryId');

        if (e === null || e === undefined) {
            items({});
        } else {
            let obj = e;

            if (visible === undefined && e.length === 1) {
                obj = e[0];
                sessionStorage.setItem('inspectionLibraryId', obj.inspection_library_id);
                items(obj);
            } else if (visible) {
                if (obj !== undefined) {
                    sessionStorage.setItem('inspectionLibraryId', obj.inspection_library_id);

                    items(obj);
                }
            }
        }
    };

    // get dropdown
    const getDropdownInfo = async () => {
        let param = {
            user_company_id: user.user_company_id,
            sys_division_code: DIVISION_CODE,
            sys_lang_code: lang,
        };

        const res = await errorHandler(getSysDivisionCaptionByCode, param);
        if (res) {
            let newArr = [
                {
                    sys_division_sub_caption_text: translation.all,
                    sys_division_sub_seq: 0,
                    sys_division_sub_value: '',
                },
            ];
            newArr.push(...res.data.o_data);

            setDropdown(newArr);
        }
    };

    // get grid list
    const getDataGridInfo = async () => {
        const customStore = new CustomStore({
            load: async (loadOptions) => {
                let skip = 0;
                let take = 10;
                if (loadOptions['skip']) skip = loadOptions['skip'];
                if (loadOptions['take']) take = loadOptions['take'];
                const pageNo = parseInt(skip / take) + 1;
                const param = {
                    user_company_id: user.user_company_id,
                    sys_lang_code: lang,
                    row_count: take,
                    page_no: pageNo,
                    search_text: searchText,
                    inspection_type: selectedDropdown,
                };

                // separate load popup / page list
                let apiUrl = visible ? getInspectionLibrary : getInspectionLibraryDT;
                const res = await errorHandler(apiUrl, param);
                if (res) {
                    return {
                        data: res.data.o_data,
                        totalCount: res.data.count,
                    };
                }
            },
            remove: async (value) => {
                remove(value);
            },
        });
        setDataSource(customStore);
    };

    // setting grid option
    const header = [
        {
            dataField: 'inspection_library_no',
            caption: translation.inspection_library_no,
            dataType: 'number',
            isEdit: false,
            width: 60,
            alignment: 'center',
            allowReordering: true,
            // isFixed: true,
        },
        {
            dataField: 'inspection_library_id',
            caption: translation.inspection_library_id.toUpperCase(),
            dataType: 'string',
            allowReordering: true,
            // isFixed: true,
            visible: false,
        },
        {
            dataField: 'inspection_type_name',
            caption: translation.inspection_library_type.toUpperCase(),
            dataType: 'string',
            isRequired: true,
            allowReordering: true,
            // isFixed: true,
        },
        {
            dataField: 'inspection_library_code',
            caption: translation.inspection_library_code.toUpperCase(),
            dataType: 'string',
            allowReordering: true,
            // isFixed: true,
        },
        {
            dataField: 'inspection_library_name',
            caption: translation.inspection_library_name.toUpperCase(),
            dataType: 'string',
            allowReordering: true,
            // isFixed: true,
        },
        {
            dataField: 'inspection_library_is_active',
            caption: translation.inspection_library_is_active.toUpperCase(),
            dataType: 'string',
            width: 130,
            alignment: 'center',
            allowReordering: true,
            visible: visible ? false : true,
            cellRender: (e) => <Badge className={activeNotActiveClassName[e.value]} text={activeNotActiveJson[e.value]} />,
        },
        {
            dataField: 'inspection_library_comment',
            caption: translation.inspection_library_comment.toUpperCase(),
            dataType: 'string',
            allowReordering: true,
        },
        {
            dataField: 'inspection_library_modified_by',
            caption: translation.lastModifiedBy.toUpperCase(),
            dataType: 'string',
            isEdit: false,
            width: 200,
            allowReordering: true,
            visible: visible ? false : true,
        },
        {
            dataField: 'inspection_library_modified_date',
            caption: translation.lastModifiedDate.toUpperCase(),
            dataType: 'string',
            isEdit: false,
            width: 150,
            alignment: 'center',
            allowReordering: true,
            visible: visible ? false : true,
        },
        {
            dataField: 'applied_item',
            caption: translation.applied_item.toUpperCase(),
            dataType: 'string',
            width: 200,
            alignment: 'center',
            allowReordering: true,
            visible: visible ? false : true,
            cellRender: (cell) => {
                return (
                    <div className="btn-popup" onClick={() => showApplied(cell)}>
                        <i className="dx-icon-m-popup"></i>
                        {translation.applied_item}
                    </div>
                );
            },
        },
    ];

    // delete
    const remove = async (value) => {
        const { id: mapping_id } = await getMenuMappingInfo();
        const user = getUser();
        const ipAddress = getIP();
        const queryData = {
            user_master_id: user.user_master_id,
            user_log_ip: ipAddress,
            menu_mapping_id: mapping_id,
            user_company_id: user.user_company_id,
            inspection_library_id: value.inspection_library_id,
        };

        const response = await errorHandler(deleteStandardLibrary, queryData);
        if (response !== 'fail' && response.message === 'MSG_100') {
            // successToastr(translation.success_delete_row);
        }
    };

    // m
    useEffect(() => {
        if (visible) {
            getDataGridInfo();

            const instance = gridRef.current.getInstance();
            instance.deselectAll();
        }
    }, [visible]);

    // change selectbox option
    const onValueChanged = (e) => {
        setSelectedDropdown(e.value);
    };

    // refresh list
    useEffect(() => {
        getDataGridInfo();
    }, [selectedDropdown]);

    const filtering = (keyword) => {
        searchText = keyword;
        getDataGridInfo();
    };

    // set badge
    const activeNotActiveClassName = {
        true: 'grid-badge-use',
        false: 'grid-badge-disabled',
    };
    const activeNotActiveJson = {
        true: translation.active,
        false: translation.not_active,
    };

    // set dropdown
    const inspectionDropdown = <DropdownField label={translation.select_inspectionType} value={selectedDropdown} dataSource={dropdown} onValueChanged={onValueChanged} valueExpr="sys_division_sub_value" displayExpr="sys_division_sub_caption_text" isSearchable={true} width={204} />;

    // set filter
    const filter = <FilterComponent label={translation.filter} width={204} onFilter={filtering} />;

    // init
    useEffect(() => {
        getDropdownInfo();
    }, []);

    // deselect
    useEffect(() => {
        if (isDeselect?.status) {
            let removed = isDeselect?.item;

            const instance = gridRef.current.getInstance();
            instance.deselectRows(removed);
        }
    }, [isDeselect]);

    return (
        <div className="container">
            <BaseDataGrid
                toolbarModules={[inspectionDropdown, filter]}
                dataSource={dataSource}
                allowModify={true}
                isRemote={true}
                isPaging={true}
                isFullSize={true}
                link="InspectionStandardLibraryForm"
                headers={header}
                ref={gridRef}
                // visible
                showAdd={visible ? false : true}
                showEdit={visible ? false : true}
                showDelete={visible ? false : true}
                showCopy={visible ? false : true}
                isDoubleClicked={visible ? false : true}
                onSelectionChanged={onRowSelectionChanged}
                selectMode={visible ? 'single' : 'multiple'}
                isDisableCopy={false}
                // showAppliedItem = {visible ? false : true}
            />
        </div>
    );
};
export default InspectionStandardGroupGrid;
