import { deleteUserShortCut, getMenuPageInitInformation, insertUserShortCut } from '../../apiInterface/menu/MenuAPI';
import { getMenuMappingInfo, getUser } from '../../apiInterface/utils/Common';
import useTranslation from '../customHooks/translations';
import useErrorHandler from '../customHooks/useErrorHandler';
import { successToastr } from './Toastr';
import Button from 'devextreme-react/button';
import { useState, useEffect } from 'react';
import { swal } from './Swal';

export function PageDropdown(props) {
    const [isShortcut, setIsShortcut] = useState(null);
    const translation = useTranslation();
    const errorHandler = useErrorHandler();

    const showMenu = (e) => {
        if (e.target.closest('.dropdownMenu') === null) {
            const showState = e.currentTarget.querySelector('.dropdownMenu')?.classList.contains('show');
            const clist = e.currentTarget.querySelector('.dropdownMenu')?.classList;
            closeOtherMenu();
            if (!showState) e.currentTarget.querySelector('.dropdownMenu')?.classList.add('show');
        }
    };

    const closeOtherMenu = (e) => {
        document.querySelector('.dropdownMenu.show')?.classList.remove('show');
        document.querySelector('.view-all-menu.show')?.classList.remove('show');
    };

    const changeShortcutState = async (e) => {
        const user = getUser();
        const type = isShortcut ? deleteUserShortCut : insertUserShortCut;
        const toastStr = isShortcut ? translation.success_delete_shortcut : translation.success_add_shortcut;
        const { id: mappingId } = getMenuMappingInfo();
        const params = {
            user_master_id: user.user_master_id,
            menu_mapping_id: mappingId,
        };

        const res = await errorHandler(type, params);
        if (res) {
            setIsShortcut(!isShortcut);
            successToastr(toastStr);
        }
        e.target.closest('.dropdownMenu').classList.remove('show');
    };

    const getInitInformation = async () => {
        const user = getUser();
        if (sessionStorage.getItem('menuMappingInfo') === null) {
            return;
        }

        const { id: mappingId } = await getMenuMappingInfo();
        const params = {
            user_company_id: user.user_company_id,
            user_master_id: user.user_master_id,
            menu_mapping_id: mappingId,
        };

        try {
            const res = await getMenuPageInitInformation(params);
            if (res !== 'fail' && res.message === 'MSG_100') {
                setIsShortcut(res.data?.o_data[0].is_shortcut);
            } else {
                var iconType = 'bad';
                var swalWidth = '380';
                var htmlString = `<span>${translation.MSG_126}</p>`;
                var confirmationButtonText = translation.confirm;
                var swalResFn = (response) => {};
                // swal(swalWidth, iconType, htmlString, confirmationButtonText, swalResFn);
                console.log(translation.MSG_126);
            }
        } catch (err) {
            var iconType = 'bad';
            var swalWidth = '380';
            var htmlString = `<span>ERROR</span><p>${translation.request_error}</p>`;
            var confirmationButtonText = translation.confirm;
            var swalResFn = (res) => {};
            // swal(swalWidth, iconType, htmlString, confirmationButtonText, swalResFn);
            console.log(translation.request_error);
        }
    };

    useEffect(() => {
        getInitInformation();
    });

    return (
        <>
            <div className="dropdown dropdown-page" onClick={showMenu}>
                <Button type="normal" stylingMode="text" className="btn-s-r btn-dropdown" icon="more" />
                <ul className="dropdown-list dropdownMenu">
                    <li className={`fav ${isShortcut ? 'active' : ''}`} onClick={(e) => changeShortcutState(e)}>
                        <i className="icon-fav"></i>
                        <p>{translation.favorites}</p>
                    </li>
                    {/* <li>
          <i className="icon-print"></i><p>{translation.reportPrint}</p>
        </li> */}
                </ul>
            </div>
        </>
    );
}
