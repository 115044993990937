import React, { useRef, useEffect, useState } from "react";
import DataGrid, {
    Column, Editing, Paging, Lookup, Button, Selection
} from 'devextreme-react/data-grid';
//import FileUploader from 'devextreme-react/file-uploader';

const ImageRender = () => {
    return (
        <div className="chart-cell">
            <img alt="employee pic" />;
        </div>
    );
};
const FileUploader = () => {
    return (
        <div className="chart-cell">
            <input
                type="file"
                accept="image/*" />
        </div>
    );
};


const CustomDataGrid = () => {
    const employees = [{
        ID: 1,
        FirstName: 'John',
        LastName: 'Heart',
        Prefix: 'Mr.',
        Position: 'CEO',
        BirthDate: '1964/03/16',
        HireDate: '1995/01/15',
        Notes: 'John has been in the Audio/Video industry since 1990. He has led DevAv as its CEO since 2003.\r\n\r\nWhen not working hard as the CEO, John loves to golf and bowl. He once bowled a perfect game of 300.',
        Address: '351 S Hill St.',
        StateID: 5,
    }, {
        ID: 2,
        FirstName: 'Olivia',
        LastName: 'Peyton',
        Prefix: 'Mrs.',
        Position: 'Sales Assistant',
        BirthDate: '1981/06/03',
        HireDate: '2012/05/14',
        Notes: 'Olivia loves to sell. She has been selling DevAV products since 2012. \r\n\r\nOlivia was homecoming queen in high school. She is expecting her first child in 6 months. Good Luck Olivia.',
        Address: '807 W Paseo Del Mar',
        StateID: 5,
        }]
    const states = [{
        ID: 1,
        Name: 'Alabama',
    }, {
        ID: 2,
        Name: 'Alaska',
    }, {
        ID: 3,
        Name: 'Arizona',
    }]
    const [event, setEvent] = useState([]);
    const [selectedItemKeys, setSelectedItemKeys] = useState([]);

    const logEvent = (eventName) => {
        setEvent([eventName].concat(event));
    }

    const clearEvents = () => {
        setEvent([]);
    }

    return (
        <>
            <DataGrid
                id="gridContainer"
                dataSource={employees}
                keyExpr="ID"
                allowColumnReordering={true}
                showBorders={true}
                selectedRowKeys={selectedItemKeys}
            >
                <Selection
                    mode="multiple"
                    showCheckBoxesMode= "always"
                />
                <Paging enabled={true} />
                <Editing
                    mode="row"
                    allowUpdating={true}
                    allowDeleting={true}
                    allowAdding={true} />

                <Column dataField="Prefix" caption="Title" />
                <Column dataField="FirstName" />
                <Column dataField="LastName" />
                <Column dataField="Position" width={130} />
                <Column
                    dataField="FileUpload"
                    minWidth={320}
                    cellRender={ImageRender}
                    editCellRender={FileUploader}
                />

                <Column
                    dataField="StateID"
                    caption="State"
                    width={125}
                >
                    <Lookup dataSource={states} displayExpr="Name" valueExpr="ID" />
                </Column>
                <Column
                    dataField="BirthDate"
                    width={125}
                    dataType="date" />
            </DataGrid>

            <div id="events">
                <div>

                    <div className="caption">Fired events</div>
                    <Button id="clear" text="Clear" onClick={clearEvents} />
                </div>
                <ul>
                    {event.map((e, index) => <li key={index}>{e}</li>)}
                </ul>
            </div>
        </>
    );
};

export default CustomDataGrid;