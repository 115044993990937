import { makeTokenAxiosGetRequest, makeTokenAxiosPutRequest, makeTokenAxiosPostRequest } from '../utils/AxiosMask';
const baseUrl = 'abnormal_analysis';

export const getAbnormalLogs = async (data) => {
    var res;
    const urlPath = `${baseUrl}/abnormal_logs_dt/`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, data);
        return res.status === 200 ? res.data : 'fail';
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : 'fail';
};

export const getIdShortPieChart = async (data) => {
    var res;
    const urlPath = `${baseUrl}/id_short_pie_chart/`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, data);
        return res.status === 200 ? res.data : 'fail';
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : 'fail';
};

export const getDailyCountBarChart = async (data) => {
    var res;
    const urlPath = `${baseUrl}/daily_total_count_bar_chart/`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, data);
        return res.status === 200 ? res.data : 'fail';
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : 'fail';
};

export const getDatasetMasterOfAbnormal = async (data) => {
    var res;
    const urlPath = `${baseUrl}/get_dataset_master_of_abnormal/`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, data);
        return res.status === 200 ? res.data : 'fail';
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : 'fail';
};

export const getDatasetMasterPropertyOfAbnormal = async (data) => {
    var res;
    const urlPath = `${baseUrl}/get_dataset_master_property_of_abnormal/`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, data);
        return res.status === 200 ? res.data : 'fail';
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : 'fail';
};
