import { makeTokenAxiosGetRequest } from "../utils/AxiosMask"

export const getMainDashboardSummary = async (data) => {
  var res;
  const urlPath = `account/get_main_dashboard_summary/`;
  try {
    res = await makeTokenAxiosGetRequest(urlPath, data);
    return res.status === 200 ? res.data : "fail";
  } catch (error) {
    console.error('error', error);
  }
  return res.status === 200 ? res : "fail";
};
