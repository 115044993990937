import React, { useEffect, useState } from 'react';
import { getUser, getIP, getMenuMappingInfo } from '../../apiInterface/utils/Common';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from 'devextreme-react/button';
import TextBox from 'devextreme-react/text-box';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import useTranslation from '../../components/customHooks/translations';
import { Validator, RequiredRule, EmailRule, PatternRule, CustomRule } from 'devextreme-react/validator';
import { SelectBox } from 'devextreme-react/select-box';
import { swal, useSwal } from '../../components/common/Swal';
import { getBusinessIds, getProductInfo, insertProductInfo, updateProductInfo, deleteProductInfo, getProductById } from '../../apiInterface/productGroup/ProductGroupAPI';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import { useLanguageContext } from '../../components/contexts/LanguageContext';
import { actionDirect, moveFocus, submit } from '../../components/common/FormFuncWithEnter';
import { PageTitle } from '../../components/common/PageTitle.jsx';

export default function ProductGroupForm(props) {
    const user = getUser();
    const ipAddress = getIP();
    const navigate = useNavigate();
    const [data, setData] = useState(props.data);
    const [isPending, setIsPending] = useState(false);
    const translation = useTranslation();
    const { state } = useLocation();
    const location = useLocation();
    const errorHandler = useErrorHandler();
    const previousPage = '/ProductGroup';
    const { language } = useLanguageContext();
    const { success, fail } = useSwal();

    const onInsert = async () => {
        const { id: mapping_id } = await getMenuMappingInfo();
        const queryData = {
            user_master_id: user.user_master_id,
            user_log_ip: ipAddress,
            user_menu_mapping_id: mapping_id,
            user_business_id: sessionStorage.getItem('businessId'),
            product_group_code: data.product_group_code,
            product_group_name: data.product_group_name,
            product_group_is_active: data.product_group_is_active,
            product_group_comment: data.product_group_comment,
        };

        const response = await errorHandler(insertProductInfo, queryData);
        if (response) {
            const cb = exitForm;
            /*alertSuccess(translation.success_insert, cb);*/
            success(translation.success_insert, cb);
        }
        setIsPending(false);
    };

    const onUpdate = async () => {
        const { id: mapping_id } = await getMenuMappingInfo();
        const queryData = {
            user_master_id: user.user_master_id,
            user_log_ip: ipAddress,
            user_menu_mapping_id: mapping_id,
            product_group_id: data.product_group_id,
            product_group_name: data.product_group_name,
            product_group_is_active: data.product_group_is_active,
            product_group_comment: data.product_group_comment,
        };

        const response = await errorHandler(updateProductInfo, queryData);
        if (response) {
            const cb = exitForm();
            //alertSuccess(translation.success_update, cb);
            success(translation.success_update, cb);
        }
        setIsPending(false);
    };

    const valueChanged = (value, key) => {
        setData((cur) => {
            let newData = { ...cur };
            newData[key] = value;
            return newData;
        });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        setIsPending(true);
        if (state.title === 'Edit') {
            onUpdate();
        } else {
            onInsert();
        }
    };

    const customValidation = (option) => {
        if (option.value !== undefined && option.value !== null) {
            return true;
        } else {
            return false;
        }
    };

    const useItems = [
        { value: true, title: translation.use },
        { value: false, title: translation.notUse },
    ];

    //init
    useEffect(() => {
        if (state.title == 'Edit') {
            let param = {
                product_group_id: state.rowData.product_group_id,
                sys_lang_code: language,
            };

            const fetching = async () => {
                const res = await errorHandler(getProductById, param);
                if (res) {
                    res.data.o_data[0].modified_date = res.data.o_data[0].modified_date.split('T')[0];
                    let temp = res.data.o_data[0];
                    temp.product_group_id = state.rowData.product_group_id;
                    setData(temp);
                }
            };
            fetching();
        } else {
            valueChanged(useItems[0].value, 'product_group_is_active');
        }
    }, []);

    const exitForm = () => {
        navigate(previousPage, {
            state: {
                previousPage: location.pathname,
            },
        });
    };

    return (
        <div className="product-group-form">
            <div className="right-content-title">
                <PageTitle pageState={location.state.title} />
            </div>
            <form onSubmit={actionDirect}>
                <div className="right-content-body">
                    <div className="container">
                        <div className="dx-fieldset">
                            <div className="dx-field">
                                <div className="dx-field-label">
                                    <p className="essential">{translation.product_group_name}</p>
                                </div>
                                <div className="dx-field-value">
                                    <TextBox className="ix-tfs-r" name="product_group_name" value={data?.product_group_name} onValueChanged={(e) => valueChanged(e.value, 'product_group_name')} onEnterKey={moveFocus} inputAttr={{ focusidx: 0, autofocus: true }}>
                                        <Validator>
                                            <RequiredRule message={translation.product_group_name + translation.is_required} />
                                        </Validator>
                                    </TextBox>
                                </div>
                            </div>
                            <div className="dx-field">
                                <div className="dx-field-label">
                                    <p className="essential">{translation.product_group_code}</p>
                                </div>
                                <div className="dx-field-value">
                                    <TextBox className="ix-tfs-r" name="product_group_code" value={data?.product_group_code} onValueChanged={(e) => valueChanged(e.value, 'product_group_code')} readOnly={state?.title == 'Edit' ? true : false} onEnterKey={moveFocus} inputAttr={{ focusidx: 1 }}>
                                        <Validator>
                                            <RequiredRule message={translation.product_group_code + translation.is_required} />
                                        </Validator>
                                    </TextBox>
                                </div>
                            </div>
                            <div className="dx-field">
                                <div className="dx-field-label">
                                    <p>{translation.use_or_not}</p>
                                </div>
                                <div className="dx-field-value">
                                    <SelectBox value={data?.product_group_is_active} dataSource={useItems} className="ix-tfs-r" valueExpr="value" displayExpr="title" onValueChanged={(e) => valueChanged(e.value, 'product_group_is_active')} onEnterKey={moveFocus} inputAttr={{ focusidx: 2 }}>
                                        <Validator>
                                            <CustomRule type={'custom'} validationCallback={customValidation} message={translation.product_group_is_active + translation.is_invalid} />
                                        </Validator>
                                    </SelectBox>
                                </div>
                            </div>
                            <div className="dx-field">
                                <div className="dx-field-label">
                                    <p>{translation.product_group_comment}</p>
                                </div>
                                <div className="dx-field-value">
                                    <TextBox className="ix-tfs-r" name="operation_factry_comment" value={data?.product_group_comment} onValueChanged={(e) => valueChanged(e.value, 'product_group_comment')} onEnterKey={(e) => document.querySelector('#submitBtn').click()} inputAttr={{ focusidx: 3 }} />
                                </div>
                            </div>
                        </div>
                        <div className="block"></div>
                        {location.state.title == 'Edit' && (
                            <div>
                                <div className="dx-fieldset write-info">
                                    <div className="dx-field">
                                        <div className="dx-field-label">{translation.lastModifiedDate}</div>
                                        <div className="dx-field-value">{data?.modified_date}</div>
                                    </div>
                                    <div className="dx-field">
                                        <div className="dx-field-label">{translation.lastModifiedBy}</div>
                                        <div className="dx-field-value">
                                            {data?.modified_by_name}
                                            {data?.modified_by_duty && <span className="badge badge-job-position">{data?.modified_by_duty}</span>}
                                            {data?.modified_by_department && <span className="badge badge-team">{data?.modified_by_department}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="block"></div>
                            </div>
                        )}
                        <div>
                            <div className="btn-group dx-fieldset">
                                <Button type="normal" stylingMode="text" className="btn-s-r" onClick={exitForm}>
                                    <div>
                                        <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                        <span className="dx-button-text">{translation.cancel}</span>
                                    </div>
                                </Button>
                                <Button
                                    id="submitBtn"
                                    type="default"
                                    stylingMode="contained"
                                    className="btn-s-r"
                                    useSubmitBehavior={true}
                                    onClick={(e) => {
                                        if (state.title === 'Add') submit(e, onInsert);
                                        else if (state.title === 'Edit') submit(e, onUpdate);
                                    }}
                                >
                                    <div>
                                        <LoadIndicator className="button-indicator" visible={isPending} width={20} height={20} />
                                        <span className="dx-button-text">{translation.save}</span>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}
