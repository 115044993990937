import {
    makeTokenAxiosDeleteRequest,
    makeTokenAxiosGetRequest,
    makeTokenAxiosPostRequest,
    makeTokenAxiosPutRequest,
    makeAxiosPostFileRequest,
    makeAxiosGetRequest
} from "../utils/AxiosMask"


const baseUrl = 'template_management';
export const getMainTemplateCategoryListAPI = async (param) => {
    let res;
    const urlPath = `${baseUrl}/get_main_template_category`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res.data : "fail";
}

export const getMainInUseTemplateCategoryListAPI = async (param) => {
    let res;
    const urlPath = `${baseUrl}/get_main_template_category_use`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res.data : "fail";
}

export const getTemplateCategoryById = async (param) => {
    let res;
    const urlPath = `${baseUrl}/template_category_by_id`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res.data : "fail";
}

export const postTemplateCategory = async (param) => {
    let res;
    const urlPath = `${baseUrl}/insert_template_category`;

    try {
        res = await makeTokenAxiosPostRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res.data : "fail";
}

export const putTemplateCategory = async (param) => {
    let res;
    const urlPath = `${baseUrl}/update_template_category`;

    try {
        res = await makeTokenAxiosPutRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res.data : "fail";
}

export const updateTemplateCategorySeq = async (param) => {
    let res;
    const urlPath = `${baseUrl}/update_template_category_seq?menu_mapping_id=${param.menu_mapping_id}&template_category_id=${param.template_category_id}&template_category_parent_id=${param.template_category_parent_id}&template_category_top_id=${param.template_category_top_id}`;

    try {
        res = await makeTokenAxiosPutRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res.data : "fail";
}

export const deleteTemplateCategory = async (param) => {
    let res;
    const urlPath = `${baseUrl}/delete_template_category`;

    try {
        res = await makeTokenAxiosDeleteRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res.data : "fail";
}

export const getTemplateMasterById = async (param) => {
    let res;
    const urlPath = `${baseUrl}/get_template_master_by_id/`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res.data : "fail";
}

export const uploadTemplateIconsAPI = async (data, formData) => {
    var res;
    const urlPath = `template_management/upload_template_icons?user_id=${data.user_id}`;
    try {
        res = await makeAxiosPostFileRequest(urlPath, formData);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
}

export const saveImage64 = async (param) => {
    var res;
    const urlPath = `${baseUrl}/save_image_64/`;
    try {
        res = await makeTokenAxiosPostRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
}

export const getTemplateGridByCategoryIdAPI = async (param) => {
    let res;
    const urlPath = `${baseUrl}/`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res.data : "fail";
}

export const update_template_masterAPI = async (data) => {
    var res;
    const urlPath = `${baseUrl}/`;
    try {
        res = await makeTokenAxiosPutRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};

export const insert_template_masterAPI = async (data) => {
    var res;
    const urlPath = `${baseUrl}/`;
    try {
        res = await makeTokenAxiosPostRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};

export const deleteTemplateMaster = async (param) => {
    let res;
    const urlPath = `${baseUrl}/`;

    try {
        res = await makeTokenAxiosDeleteRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res.data : "fail";
}

export const updateTemplateMasterWithoutJson = async (data) => {
    var res;
    const urlPath = `${baseUrl}/update_template_master_without_json`;
    try {
        res = await makeTokenAxiosPutRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};

export const getCumstomTemplates = async (param) => {
    let res;
    const urlPath = `${baseUrl}/get_custom_templates/`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res.data : "fail";
}

export const getExistTemplateMasterInMenuMapping = async (param) => {
    let res;
    const urlPath = `${baseUrl}/exist_template_master_in_menu_mapping/`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res.data : "fail";
}

export const getOneCumstomTemplate = async (param) => {
    let res;
    const urlPath = `${baseUrl}/get_one_custom_template/`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res.data : "fail";
}

export const insertCustomTemplate = async (param) => {
    var res;
    const urlPath = `${baseUrl}/insert_custom_template/`;
    try {
        res = await makeTokenAxiosPostRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
}

export const getTemplateType = async (param) => {
    var res;
    const urlPath = `${baseUrl}/template_type/`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
}

export const getOneTemplateTypeById = async (param) => {
    var res;
    const urlPath = `${baseUrl}/template_type_by_id/`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
}

export const uploadImageInTemplate = async (userId, param) => {
    var res;
    const urlPath = `${baseUrl}/upload_image_in_template?user_id=${userId}`;
    try {
        res = await makeTokenAxiosPostRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
}