import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import * as echarts from 'echarts';

const FetchDataComponent = () => {
    const [chartIds, setChartIds] = useState([]);
    const chartRefs = useRef([]);

    const Baseapi = {
        pythonWebAPI: "http://localhost:8000/api_recipeai", // Replace with your actual API base URL
    };
    const urlPath = `/dashboard/get_custom_page_chart_data_v3?company_code=LNF_ProductionData&company_id=245`; // Replace with your actual URL path

    // Effect to set chart IDs
    useEffect(() => {
        const ids = [];
        for (let i = 0; i < 50; i++) {
            ids.push(`chart-${i}`);
        }
        setChartIds(ids);
    }, []);

    useEffect(() => {
        // Create divs for charts
  
        const pData = {
            "page_filter": [
                {
                    "type": "startDate",
                    "dataset_name": "L01BARH_LFG-RHK-03-01",
                    "collection_name": "L01BARH_LFG-RHK-03-01",
                    "column_type": "x4",
                    "column_name": "ReceivedDateTime",
                    "title": "ReceivedDateTime",
                    "title_json": "[{\"sys_lang_code\":\"ko\",\"sys_lang_code_title\":\"ReceivedDateTime\"},{\"sys_lang_code\":\"en\",\"sys_lang_code_title\":\"ReceivedDateTime\"}]",
                    "datatype": "dateTime",
                    "postgrekey": null,
                    "value": "2024-07-03 00:00:00"
                },
                {
                    "type": "endDate",
                    "dataset_name": "L01BARH_LFG-RHK-03-01",
                    "collection_name": "L01BARH_LFG-RHK-03-01",
                    "column_type": "x4",
                    "column_name": "ReceivedDateTime",
                    "title": "ReceivedDateTime",
                    "title_json": "[{\"sys_lang_code\":\"ko\",\"sys_lang_code_title\":\"ReceivedDateTime\"},{\"sys_lang_code\":\"en\",\"sys_lang_code_title\":\"ReceivedDateTime\"}]",
                    "datatype": "dateTime",
                    "postgrekey": null,
                    "value": "2024-07-04 00:00:00"
                }
            ],
            "component_filter": {
                "chart_type": {
                    "template_type_id": 6,
                    "template_type_name": "Chart-Line",
                    "template_type_is_multi": true
                },
                "x": [
                    {
                        "name": "ReceivedDateTime"
                    }
                ],
                "raw_dataset": {
                    "master_dataset_no": 1,
                    "dataset_master_id": 481,
                    "dataset_master_name": "L01BARH",
                    "dataset_master_interval": 1,
                    "dataset_master_is_active": true,
                    "dataset_master_comment": null,
                    "dataset_master_modified_by": "kcm93@landf.co.kr",
                    "dataset_master_modified_date": "2024/02/22"
                },
                "filter_list": [],
                "filter_value": [],
                "y": [
                    {
                        "name": "14_Zone_하부_온도(℃)",
                        "aas_master_table_name": "L01BARH_LFG-RHK-03-01"
                    }
                ],
                "yColor": [
                    "#0FB5AE"
                ],
                "yAuxiliary": [
                    {
                        "min": true,
                        "max": true,
                        "avg": true
                    }
                ]
            }
        }
        const fetchData = async () => {
            //const access_token = getToken();
            const url = `${Baseapi.pythonWebAPI}${urlPath}`;
            const config = {
                method: 'post',
                url: url,
                data: pData, // Your actual data to be sent in the request
            
            };

            const requests = chartIds.forEach(async (id, index) => {
                
                axios(config)
                    .then(response => {
                       
                        const data = response.data.data.o_data;
                        const y_data = data.map(item => item["14_Zone_하부_온도(℃)"]);
                        const x_data = data.map(item => item["ReceivedDateTime"]);
                        debugger;
                        const chartElement = document.getElementById(id);
                        if (chartElement) {
                            const chart = echarts.init(chartElement);
                            const option = {
                                xAxis: {
                              
                                    data: x_data,// [1,2,3,4,5]
                                },
                                yAxis: {
                                    type: 'value'
                                },
                                series: [
                                    {
                                        data: y_data,// [10, 20, 30, 40, 50],
                                        type: 'line'
                                    }
                                ]
                            };
                            chart.setOption(option);
                            chartRefs.current.push(chart);
                        }
                    })
                    .catch(error => {
                        console.error("Error fetching data", error);
                    })
            }
            );

            // Wait for all requests to settle
            //await Promise.allSettled(requests);
        };

        fetchData();

        // Cleanup charts on unmount
        return () => {
            chartRefs.current.forEach(chart => chart.dispose());
        };
    }, [chartIds]);

    return (
        <div>
            <h1>Fetched Data</h1>
            <div className="chart-container">
            {chartIds.map(id => (
                <div className="chart" key={id} id={id} style={{ width: '600px', height: '400px' }}></div>
            ))}
        </div>
        </div>
    );
};

export default FetchDataComponent;