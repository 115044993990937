// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.content {
    padding: 20px;
}

.header {
    padding: 10px;
    background: #edf2f4;
    border-bottom: 1px solid #999;
}

.header a {
    color: #0072ff;
    text-decoration: none;
    margin-left: 20px;
    margin-right: 5px;
}

.header a:hover {
    color: #8a0f53;
}

.header small {
    color: #666;
}

.header .active {
    color: #2c7613;
}*/`, "",{"version":3,"sources":["webpack://./src/contents/styles/index.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;EAsCE","sourcesContent":["/*body {\n    margin: 0;\n    font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif;\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n    font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\", monospace;\n}\n\n.content {\n    padding: 20px;\n}\n\n.header {\n    padding: 10px;\n    background: #edf2f4;\n    border-bottom: 1px solid #999;\n}\n\n.header a {\n    color: #0072ff;\n    text-decoration: none;\n    margin-left: 20px;\n    margin-right: 5px;\n}\n\n.header a:hover {\n    color: #8a0f53;\n}\n\n.header small {\n    color: #666;\n}\n\n.header .active {\n    color: #2c7613;\n}*/"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
