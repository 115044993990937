import React from "react";
import Button from 'devextreme-react/button';
import { getUser } from "../../apiInterface/utils/Common";
import { useNavigate } from "react-router-dom";

const Landing = () => {
    const navigate = useNavigate();
    return (
        <div> <Button
            text="Click me"
            onClick={sayHelloWorld} />

            <h1>
                This is a landing page.... COMING SOON
            </h1>

        </div>
    );
    function sayHelloWorld() {
        var data = getUser();
        navigate(`/MainPortal?id=${data.user_master_id}`);
        alert('Hello world!');
    }
};

export default Landing;