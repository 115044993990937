import React, { useState, useEffect } from 'react';
import useTranslation from '../../components/customHooks/translations';
import {
    BaseField,
    TextboxField,
    DropdownField,
} from '../../components/common/FormField';
import TreeListPath from '../../components/common/TreeListPath';
import { Button, Popup, ValidationGroup } from 'devextreme-react';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import {
    getTemplateCategoryById,
    putTemplateCategory,
    postTemplateCategory,
} from '../../apiInterface/templateManagment/TemplateManagementAPI';

import {
    getIP,
    getLanguage,
    getMenuMappingInfo,
    getUser,
} from '../../apiInterface/utils/Common';
import { useRef } from 'react';
import { successToastr } from '../../components/common/Toastr';
import {
    insertDefectTypeCategory,
    getOneDefectTypeCategory,
    updateDefectTypeCategory,
} from '../../apiInterface/inspection/InspectionDefectType';

export default function InspectionDefectCategoryEditPopup(props) {
    const {
        subtitle = 'add', // indicate this popup is edit or add
        path = [], // hiararchy path from root to selected node
        onExit = () => {}, //callback event for exit
        itemId,
        visible = true,
        hideOnOutsideClick = true,
        onHiding = () => {},
        dragEnabled = true,
    } = props;

    const validRef = useRef();
    const errorHandler = useErrorHandler();
    const translation = useTranslation();
    const lang = getLanguage();
    const user = getUser();
    const ip = getIP();
    const { id: mappingId } = getMenuMappingInfo();
    const [data, setData] = useState({ defect_type_category_is_active: true });

    const dropdownItems = [
        { value: true, name: translation.use },
        { value: false, name: translation.notUse },
    ];

    const changeValue = (value, key) => {
        setData((cur) => {
            let copy = { ...cur };
            copy[key] = value;
            return copy;
        });
    };

    // get one defect type category
    const getOneDefectCategory = async () => {
        let param = {
            defect_type_category_id: itemId,
            sys_lang_code: lang,
        };

        const res = await errorHandler(getOneDefectTypeCategory, param);
        if (res) {
            if (res.data.o_data.length > 0) {
                setData((cur) => {
                    return { ...cur, ...res.data.o_data[0] };
                });
            } else setData(null);
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        let result = false;

        if (subtitle.toLowerCase() === 'add') {
            result = await insert();
        } else if (subtitle.toLowerCase() === 'edit') {
            result = await update();
        }

        if (result) {
            onExit('save');
            successToastr(translation.save_successfully);
        }
    };

    const insert = async () => {
        let parentId = path.length !== 0 ? itemId : 0;
        const { id: mappingId } = getMenuMappingInfo();
        let commonParam = {
            user_master_id: user.user_master_id,
            user_master_email: user.user_master_email,
            user_log_ip: ip,
            user_menu_mapping_id: mappingId,
            user_company_id: user.user_company_id,
            defect_type_category_id: 0,
            defect_type_category_parent_id: parentId,
        };

        const param = { ...commonParam, ...data };
        const res = await errorHandler(insertDefectTypeCategory, param);
        if (res) {
            return true;
        }
        return false;
    };

    const update = async () => {
        let commonParam = {
            user_master_id: user.user_master_id,
            user_master_email: user.user_master_email,
            user_log_ip: ip,
            menu_mapping_id: mappingId,
            user_company_id: user.user_company_id,
            defect_type_category_id: itemId,
        };

        const param = { ...commonParam, ...data };
        const res = await errorHandler(updateDefectTypeCategory, param);
        if (res) {
            return true;
        }
        return false;
    };

    // const onShowing = () => {
    //     const instance = validRef.current.instance;
    //     instance.reset();

    //     if (subtitle.toLowerCase() == 'edit') {
    //         setData({ defect_type_category_id: itemId });
    //         getById();
    //     } else {
    //         setTimeout(() => {
    //             setData({
    //                 defect_type_category_parent_id: itemId == null ? 0 : itemId,
    //                 defect_type_category_is_active: dropdownItems[0].value,
    //             });
    //         }, 5);
    //     }
    // };

    const onHidden = () => {
        setData(null);
    };

    useEffect(() => {
        if (visible && subtitle === 'Edit') {
            getOneDefectCategory();
        }
    }, [visible]);

    return (
        <Popup
            width={520}
            height="auto"
            visible={visible}
            hideOnOutsideClick={hideOnOutsideClick}
            onHiding={onHiding}
            dragEnabled={dragEnabled}
            position="center"
            showTitle={false}
            // onShowing={onShowing}
            onHidden={onHidden}
        >
            <div className="popup-header">
                <p className="popup-title">
                    {translation.template_classification} /{' '}
                    {translation[subtitle.toLowerCase()]}
                </p>
            </div>
            <form onSubmit={onSubmit}>
                <ValidationGroup ref={validRef}>
                    <div className="popup-body">
                        <div className="dx-fieldset">
                            <BaseField
                                label={translation.hierarchy}
                                isColumn={true}
                                component={
                                    <>
                                        <TreeListPath path={path} />
                                        {path.length > 0 &&
                                        subtitle == 'Add' ? (
                                            <p className="info-comment">
                                                <span className="material-icons-outlined">
                                                    info
                                                </span>
                                                {path[path.length - 1]}{' '}
                                                {translation.template_info_add}
                                            </p>
                                        ) : (
                                            ''
                                        )}
                                    </>
                                }
                            />

                            <TextboxField
                                // label={translation.template_classification}
                                label={`defect_type_category_name`}
                                isEssential={true}
                                isColumn={true}
                                value={data?.defect_type_category_name}
                                onValueChanged={(e) =>
                                    changeValue(
                                        e.value,
                                        'defect_type_category_name'
                                    )
                                }
                            />

                            <DropdownField
                                dataSource={dropdownItems}
                                displayExpr="name"
                                valueExpr="value"
                                isColumn={true}
                                label={translation.use_or_not}
                                isEssential={true}
                                value={data?.defect_type_category_is_active}
                                onValueChanged={(e) =>
                                    changeValue(
                                        e.value,
                                        'defect_type_category_is_active'
                                    )
                                }
                                isSearchable={false}
                            />

                            <TextboxField
                                label={translation.comment}
                                isColumn={true}
                                value={data?.defect_type_category_comment}
                                onValueChanged={(e) =>
                                    changeValue(
                                        e.value,
                                        'defect_type_category_comment'
                                    )
                                }
                            />
                        </div>
                        {subtitle == 'Edit' && (
                            <div className="dx-fieldset write-info">
                                <div className="dx-field">
                                    <div className="dx-field-label">
                                        {translation.lastModifiedDate}
                                    </div>
                                    <div className="dx-field-value">
                                        {data?.modified_date
                                            ? data.modified_date
                                            : ''}
                                    </div>
                                </div>
                                <div className="dx-field">
                                    <div className="dx-field-label">
                                        {translation.lastModifiedBy}
                                    </div>
                                    <div className="dx-field-value">
                                        {data?.modified_by_name
                                            ? data.modified_by_name
                                            : ''}
                                        {data?.modified_by_duty && (
                                            <span className="badge badge-job-position">
                                                {data.modified_by_duty}
                                            </span>
                                        )}
                                        {data?.modified_by_department && (
                                            <span className="badge badge-team">
                                                {data.modified_by_department}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="popup-footer">
                        <Button
                            width={86}
                            text={translation.cancel}
                            type="normal"
                            stylingMode="text"
                            className="btn-s-r"
                            onClick={(e) => onExit('close')}
                        />
                        <Button
                            text={translation.confirm}
                            width={86}
                            type="default"
                            stylingMode="contained"
                            className="btn-s-r"
                            useSubmitBehavior={true}
                        />
                    </div>
                </ValidationGroup>
            </form>
        </Popup>
    );
}
