import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useTranslation from '../../components/customHooks/translations';
import { PageDropdown } from '../../components/common/PageDropdown';
import { PageTitle } from '../../components/common/PageTitle.jsx';
import AppliedItemPopup from './AppliedItemPopup';
import { SearchWithTag } from '../../components/common/SearchWithTag';
import { getLanguage, getUser, getMenuMappingInfo, getIP } from '../../apiInterface/utils/Common';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import Button from 'devextreme-react/button';
import { SearchableField, TextboxField, DropdownField, FilterComponent, FileField } from '../../components/common/FormField';
import { useSwal } from '../../components/common/Swal';
import TreeCardWithLine from '../../components/common/TreeCardWithLine';
import { getSysDivisionCaptionByCode, getMainInspectionLibraryDefectTypeDT, deleteInspectionLibraryDefectType } from '../../apiInterface/inspection/InspectionLibraryCondition';

let searchText = '';
const DIVISION_CODE = 'INSPECTIONTYPE';
const link = 'DefectTypeStandardForm';
const InspectionStandardGroup = () => {
    const navigate = useNavigate();
    const translation = useTranslation();
    const errorHandler = useErrorHandler();
    const user = getUser();
    const ipAddress = getIP();
    const lang = getLanguage();
    const { state } = useLocation();

    const { warning } = useSwal();
    const searchBarRef = useRef();

    const [dropdown, setDropdown] = useState();
    const [selectedDropdown, setSelectedDropdown] = useState();
    const [dataSource, setDataSource] = useState([]);
    const [treeDataSource, setTreeDataSource] = useState([]);

    const [checkedItems, setCheckedItems] = useState([]);

    // get dropdown
    const getDropdownInfo = async () => {
        let param = {
            user_company_id: user.user_company_id,
            sys_division_code: DIVISION_CODE,
            sys_lang_code: lang,
        };

        const res = await errorHandler(getSysDivisionCaptionByCode, param);
        if (res) {
            let optionAll = [{ sys_division_sub_caption_text: translation.all, sys_division_sub_seq: 0, sys_division_sub_value: '' }];
            setDropdown([...optionAll, ...res.data.o_data]);
            // default value
            setSelectedDropdown(optionAll[0].sys_division_sub_value);
        }
    };

    // insert
    const moveToAdd = () => {
        navigate(`/${link}`, {
            state: {
                title: 'Add',
            },
        });
    };

    // update
    const moveToEdit = () => {
        navigate(`/${link}`, {
            state: {
                title: 'Edit',
                id: checkedItems[0],
            },
        });
    };

    // copy
    const moveToCopy = () => {
        navigate(`/${link}`, {
            state: {
                title: 'Copy',
                id: checkedItems[0],
            },
        });
    };

    // delete
    const deleteRow = async () => {
        const { id: mapping_id } = await getMenuMappingInfo();
        const commonParam = {
            user_log_ip: ipAddress,
            user_master_id: user.user_master_id,
            menu_mapping_id: mapping_id,
            user_company_id: user.user_company_id,
        };

        checkedItems.length > 0 &&
            checkedItems.map(async (m) => {
                let id = { inspection_library_id: m };

                const queryData = { ...commonParam, ...id };
                const res = await errorHandler(deleteInspectionLibraryDefectType, queryData);
                if (res) {
                    getDataGridInfo();
                    setCheckedItems([]);
                }
            });
    };

    // get grid list
    const getDataGridInfo = async (id) => {
        // const customStore = new CustomStore({
        //     load: async (loadOptions) => {
        let skip = 0;
        let take = 30;
        // if (loadOptions['skip']) skip = loadOptions['skip'];
        // if (loadOptions['take']) take = loadOptions['take'];
        const pageNo = parseInt(skip / take) + 1;
        const param = {
            user_company_id: user.user_company_id,
            sys_lang_code: lang,
            row_count: take,
            page_no: pageNo,
            search_text: searchText,
            inspection_type: id === undefined ? '' : id,
        };

        const res = await errorHandler(getMainInspectionLibraryDefectTypeDT, param);
        if (res) {
            let rlt = {
                data: res.data.o_data,
                totalCount: res.data.count,
            };
            processingTreeData(rlt.data);
            return rlt;
        }
        //     },
        //     remove: async (value) => {
        //         remove(value);
        //     },
        // });
        // setDataSource(customStore);
    };

    const processingTreeData = (dataSource) => {
        const customData = dataSource.filter((item1, idx1) => {
            return (
                dataSource.findIndex((item2, idx2) => {
                    return item1.inspection_library_id == item2.inspection_library_id;
                }) == idx1
            );
        });

        let copy = [];
        customData.map((m) => {
            let arr = [];
            dataSource.map((k) => {
                if (m.inspection_library_id === k.inspection_library_id) {
                    arr.push(k);
                }
            });

            copy.push({ ...m, child: arr });
        });

        setTreeDataSource(copy);
    };

    useEffect(() => {
        if (state?.title === 'Save') {
            getDataGridInfo();
        }
    }, [state]);

    const filtering = (keyword) => {
        searchText = keyword;
        getDataGridInfo();
    };

    const inspectionDropdown = (
        <DropdownField
            label={translation.select_inspectionType}
            value={selectedDropdown}
            dataSource={dropdown}
            onValueChanged={(e) => {
                [setSelectedDropdown(e.value), getDataGridInfo(e.value)];
            }}
            valueExpr="sys_division_sub_value"
            displayExpr="sys_division_sub_caption_text"
            isSearchable={true}
            width={204}
        />
    );

    // set filter
    const filter = <FilterComponent label={translation.filter} width={204} onFilter={filtering} />;

    // init
    useEffect(() => {
        getDropdownInfo();
        getDataGridInfo();
    }, []);

    return (
        <div className="inspection-standard-group">
            <div className="right-content-title">
                <PageTitle />
                <PageDropdown />
            </div>
            <div className="right-content-body">
                <div className="container not-p">
                    <div className="toolbar">
                        {/* <SearchWithTag
                            displayExpr="display"
                            ref={searchBarRef}
                            placeholder="Name or defect code..."
                        /> */}
                        <div>{[inspectionDropdown, filter]}</div>
                        <div>
                            <Button className="btn-s-r btn-hover-red" type="normal" stylingMode="text" icon="trash" onClick={deleteRow} disabled={checkedItems !== null && checkedItems.length < 1 ? true : false} />
                            <Button className="btn-s-r" type="normal" stylingMode="contained" icon="m-copy" text={translation.copy} onClick={moveToCopy} disabled={checkedItems !== null && checkedItems.length < 2 ? false : true} />
                            <Button className="btn-s-r" type="normal" stylingMode="contained" icon="edit" text={ translation.edit} onClick={moveToEdit} disabled={checkedItems !== null && checkedItems.length === 1 ? false : true} />
                            <Button className="btn-s-r" type="default" stylingMode="contained" icon="add" text={ translation.add} onClick={moveToAdd} />
                        </div>
                    </div>
                    <div className="bg-gray">
                        {treeDataSource.length === 0 && (
                            <div className="no-data-s">
                                <div className="no-data-img"></div>
                                <h2>{translation.no_data}</h2>
                            </div>
                        )}

                        <TreeCardWithLine data={treeDataSource} getChecked={checkedItems} setChecked={setCheckedItems} />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default InspectionStandardGroup;
