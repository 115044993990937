import React, { useRef, useEffect, useState } from 'react';
import * as echarts from "echarts";
import { echartTest, echartLiveTest } from "../../apiInterface/home/HomeAPI";
import { useInterval } from "../../components/customHooks/useInterval";
import { LineChart, BarChart, ScatterChart, PieChart, RadarChart, BoxplotChart } from "../../components/ixChart/IXChart"


const MultipleChart = (props) => {


	const lineYData = [
		[150, 230, 224, 218, 135, -147, 260],[59, 888, 46, 487, 643, 56, 56] 
	];
	const lineXData = [[1, 2, 3, 4, 5, 6, 7]];
	const lineLegend = ['A', 'B'];
	const color = ['#B71C1C', '#112233']
	const xAxisName = ["xAxis"]
	const yAxisName = ["yAxis"]
	const lineAreaData = {
		idx: 0,
		data: [[2, 3], [4, 5]]
	};

	const barYData = [[18203, 23489, 29034, 104970, 131744, 630230],
	[19325, 23438, 31000, 121594, 134141, 681807]]
	const barLegend = ['2011', '2012'];
	const barXdata = [['Brazil', 'Indonesia', 'USA', 'India', 'China', 'World']];


	const scatterXData = [[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]]
	const scatterYData = [[1, 2, 3, 4, 5, 6, 7, 8, 9, 10], [1.5, 2.5, 3.5, 4.5, 5.5, 6.5]]

	const scatterLegend = ['A', 'B'];

	const pieX = [["a", "b", "c", "d", "e"]];
	const pieY = [[120, 239, 100, 230, 5]];

	const pieColor = ['#B71C1C', '#112233', '#122233', '#112433', '#FFE233'];

	const rX = [["a", "b", "c", "d", "e"]];
	const rY = [[120, 239, 100, 230, 5], [213, 21, 180, 30, 25]];

	const rColor = ['#B71C1C', '#112233'];

	const boxY = [[850, 740, 900, 1070, 930, 850, 950, 980, 980, 880, 1000, 980, 930, 650, 760, 810, 1000, 1000, 960, 960],
	[960, 940, 960, 940, 880, 800, 850, 880, 900, 840, 830, 790, 810, 880, 880, 830, 800, 790, 760, 800],
	[880, 880, 880, 860, 720, 720, 620, 860, 970, 950, 880, 910, 850, 870, 840, 840, 850, 840, 840, 840],
	[890, 810, 810, 820, 800, 770, 760, 740, 750, 760, 910, 920, 890, 860, 880, 720, 840, 850, 850, 780],
		[890, 840, 780, 810, 760, 810, 790, 810, 820, 850, 870, 870, 810, 740, 810, 940, 950, 800, 810, 870]];
	const boxX =[ ['A', 'B', 'C', 'D', 'E']];

	useEffect(() => {
		const line = LineChart({
			x: lineXData, y: lineYData, legend: lineLegend, id: ".test > .line", color: color,
			xAxisName: xAxisName, yAxisName: yAxisName
		});
		const bar = BarChart({ y: barYData, legend: barLegend, x: barXdata, id: ".test > .bar", color: color, xAxisName: xAxisName, yAxisName: yAxisName });
		const scatter = ScatterChart({ x: scatterXData, y: scatterYData, legend: scatterLegend, id: ".test > .scatter", color: color, xAxisName: xAxisName, yAxisName: yAxisName });
		const pie = PieChart({ x: pieX, y: pieY, id: ".test > .pie", color: pieColor });
		const radar = RadarChart({ x: rX, y: rY, id: ".test > .radar", color: rColor, legend: scatterLegend });
		const box = BoxplotChart({ x: boxX, y: boxY, id: ".test > .box", color: rColor});
		window.onresize = function () {
			line.resize();
			bar.resize();
			scatter.resize();
			pie.resize();
			radar.resize();
			box.resize();
		};
	}, []);

	return (
		<div style={{
			border: "solid",
			padding: "64px 16px 16px 16px",
			width: "100%",
			height: "1600px",
			display: "flex"
		}} className="test">
			<div class="line"
				style={{
					width: "100%",
					height: "100%",
				}} />
			<div class={"bar"}
				style={{
					width: "100%",
					height: "100%",
				}} />
			<div class="scatter"
				style={{
					width: "100%",
					height: "100%",
				}} />
			<div class="pie"
				style={{
					width: "100%",
					height: "100%",
				}} />
			<div class="radar"
				style={{
					width: "100%",
					height: "100%",
				}} />
			<div class="box"
				style={{
					width: "100%",
					height: "100%",
				}} />
	
		</div>
	)
}

export default MultipleChart;