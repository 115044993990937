﻿import React, { useEffect, useState } from "react";
import { getUser, getIP, getLanguage, getMenuMappingInfo } from '../../apiInterface/utils/Common';
import useTranslation from "../../components/customHooks/translations";
import CustomStore from 'devextreme/data/custom_store';
import {
    getFactoryIds,
    getOperationLineInfo,
    insertOperationLineInfo,
    updateOperationLineInfo,
    deleteOperationLineInfo
} from "../../apiInterface/line/LineAPI";
import { useLocation } from "react-router-dom";
import useErrorHandler from "../../components/customHooks/useErrorHandler";
import BaseDataGrid from "../../components/common/BaseDataGrid";
import { DropdownField, FilterComponent } from "../../components/common/FormField";
import { useRef } from "react";
import { successToastr } from "../../components/common/Toastr";
import { PageDropdown } from "../../components/common/PageDropdown";
import { Allotment } from "allotment";
import { Button } from "devextreme-react";
import Badge from "../../components/common/Badge";
import TreeCard from "../../components/common/TreeCard";
import { PageTitle } from "../../components/common/PageTitle.jsx";

const Line = () => {
    const translation = useTranslation();
    const activeNotActiveJson = { true: translation.active, false: translation.not_active };
    const activeNotActiveClassName = { true: "grid-badge-use", false: "grid-badge-disabled" }
    const [factoryIds, setFactoryIds] = useState([]);
    const [curFactoryId, setCurFactoryId] = useState();
    const [dataSource, setDataSource] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [rightPaneVisible, setRightPanelVisible] = useState(false);
    const allotmentRef = useRef();
    const location = useLocation();
    const errorHandler = useErrorHandler();
    const headers = [
        { dataField: "operation_line_no", "caption": "NO", "dataType": "string", width: 100, alignment: "center", isFixed: true, allowReordering: true },
        { dataField: "operation_line_code", "caption": translation.operation_line_code.toUpperCase(), "dataType": "string", isFixed: true, allowReordering: true },
        { dataField: "operation_line_name", "caption": translation.operation_line_name.toUpperCase(), "dataType": "string", isFixed: true, allowReordering: true },
        { dataField: "operation_factory_name", "caption": translation._factory.toUpperCase(), "dataType": "string", isFixed: true, allowReordering: true },
        { dataField: "user_business_name", "caption": translation._business.toUpperCase(), "dataType": "string", isFixed: true, allowReordering: true },
        {
            dataField: "operation_line_is_active", "caption": translation.operation_line_is_active.toUpperCase(), "dataType": "boolean",
            width: 130, alignment: "center", allowReordering: true, cellRender: (e) => <Badge className={activeNotActiveClassName[e.value]} text={activeNotActiveJson[e.value]} />,
        },
        { dataField: "operation_line_comment", "caption": translation.operation_line_comment.toUpperCase(), "dataType": "string", allowReordering: true },
        { dataField: "operation_line_modified_by", "caption": translation.lastModifiedBy.toUpperCase(), dataType: "string", isEdit: false, width: 300, allowReordering: true },
        { dataField: "operation_line_modified_date", "caption": translation.lastModifiedDate.toUpperCase(), dataType: "string", isEdit: false, width: 150, allowReordering: true },
    ];

    //init
    useEffect(() => {
        const init = async () => {
            const user = getUser();
            let param = { user_company_id: user.user_company_id }
            const result = await errorHandler(getFactoryIds, param);
            if (result) {
                setFactoryIds(result.data.o_data);

                //if previousPage is Form, set dropdown value to what was set before edit/add page is clicked.
                if (location.state?.previousPage == "/LineForm") {
                    setCurFactoryId(parseInt(sessionStorage.getItem("factoryId")));
                } else {
                    if (result.data.o_data.length > 0) {
                        setCurFactoryId(result.data.o_data[0].operation_factory_id);
                    }
                }
            }
        }
        init();
    }, []);

    const onValueChanged = async (e) => {
        sessionStorage.setItem("factoryId", e.value);
        setCurFactoryId(e.value);
        makeCustomStore(e.value);
    }

    const makeCustomStore = (factoryId, keyword = searchText) => {
        const customStore = new CustomStore({
            load: async (loadOptions) => {
                return read(loadOptions, factoryId, keyword)
            },
            insert: async (value) => {
                create(value, factoryId, keyword);
            },
            update: async (previousValue, changedValue) => {
                const newData = { ...previousValue, ...changedValue }
                update(newData, factoryId);
            },
            remove: async (value) => {
                remove(value);
            }
        });
        setDataSource(customStore);
    }

    const read = async (loadOptions, factory_id, keyword) => {
        const language = getLanguage();
        const user = getUser();
        const pageNo = parseInt(loadOptions["skip"] / loadOptions["take"]) + 1;
        const param = {
            "user_company_id": user.user_company_id,
            "operation_factory_id": factory_id,
            "sys_lang_code": language,
            "row_count": loadOptions["take"] != undefined ? loadOptions["take"] : 0,
            "page_no": pageNo,
            "search_text": keyword,
        };

        const response = await errorHandler(getOperationLineInfo, param);
        if (response) {
            const data = response.data.o_data;
            const pagingDict = {
                data: data,
                totalCount: response.data.count
            }
            return pagingDict;
        }
    }

    const create = async (newData, factory_id) => {
        const { id: mapping_id } = await getMenuMappingInfo();
        const user = getUser();
        const ipAddress = getIP();
        const queryData = {
            "user_master_id": user.user_master_id,
            "user_log_ip": ipAddress,
            "user_menu_mapping_id": mapping_id,
            "operation_factory_id": factory_id,
            "operation_factory_line_code": newData.operation_factory_line_code,
            "operation_line_name": newData.operation_line_name,
            "operation_line_is_active": newData.operation_line_is_active,
            "operation_line_comment": newData.operation_line_comment,
        }

        const response = await errorHandler(insertOperationLineInfo, queryData);
        if (response) {
        }
    }

    const update = async (newData, factory_id) => {
        const { id: mapping_id } = await getMenuMappingInfo();
        const user = getUser();
        const ipAddress = getIP();
        const queryData = {
            "user_master_id": user.user_master_id,
            "user_log_ip": ipAddress,
            "user_menu_mapping_id": mapping_id,
            "operation_factory_id": factory_id,
            "operation_line_id": newData.operation_line_id,
            "operation_line_name": newData.operation_line_name,
            "operation_line_is_active": newData.operation_line_is_active,
            "operation_line_comment": newData.operation_line_comment,
        }

        const response = await errorHandler(updateOperationLineInfo, queryData);
        if (response) {
        }
    }

    const remove = async (newData) => {
        const { id: mapping_id } = await getMenuMappingInfo();
        const user = getUser();
        const ipAddress = getIP();
        const queryData = {
            "user_master_id": user.user_master_id,
            "user_log_ip": ipAddress,
            "user_menu_mapping_id": mapping_id,
            "operation_line_id": newData.operation_line_id
        }

        const response = await errorHandler(deleteOperationLineInfo, queryData);
        if (response !== "fail" && response.message === "MSG_100") {
            successToastr(translation.success_delete_row);
        }
    }

    const dropdown = <DropdownField
        label={translation.select_factory}
        dataSource={factoryIds}
        displayExpr="operation_factory_name"
        valueExpr="operation_factory_id"
        onValueChanged={onValueChanged}
        value={curFactoryId}
        width={204}
    />

    const filtering = (keyword) => {
        setSearchText(keyword);
        makeCustomStore(curFactoryId, keyword);
    }

    const filter = <FilterComponent
        label={translation.filter}
        width={204}
        onFilter={filtering}
    />

    const toggleRightPanel = () => {
        if (rightPaneVisible == true) {
            allotmentRef.current.resize([3, 1]);
        }
        setRightPanelVisible(!rightPaneVisible);
    }

    return (
        <div className="line">
            <div className="right-content-title">
                <PageTitle />
                <div className="page-buttons">
                    <div>
                        <Button
                            icon="ix-panel"
                            type="normal"
                            stylingMode="text"
                            className="btn-s-r"
                            onClick={toggleRightPanel}
                        />
                    </div>
                    <PageDropdown />
                </div>
            </div>
            <div className="right-content-body">
                <Allotment ref={allotmentRef}>
                    <Allotment.Pane minSize={0}>
                        <div className="left-panel">
                            <div className="container">
                                <BaseDataGrid
                                    toolbarModules={[dropdown, filter]}
                                    allowModify={true}
                                    dataSource={dataSource}
                                    headers={headers}
                                    isRemote={true}
                                    selectMode="multiple"
                                    link="LineForm"
                                    isFullSize={false}
                                />
                            </div>
                        </div>
                    </Allotment.Pane>
                    <Allotment.Pane minSize={0} visible={rightPaneVisible} snap={true}>
                        <div className="right-panel">
                            <div className="container">
                                <div className="panel-header">
                                    <p>Process Details</p>
                                </div>
                                <div className="panel-body">
                                    <TreeCard
                                        data={dummy}
                                        nameExpr="aas_master_id_short"
                                        detailExpr="aas_master_identification"
                                        badgeExprs={["aas_master_type"]}
                                        parentExpr="aas_master_parent_id"
                                        keyExpr="aas_master_id"
                                        isSelectable={false}
                                    />
                                </div>
                            </div>
                            </div>
                    </Allotment.Pane>
                </Allotment>
            </div>
        </div>
    );
};


let dummy = [
    {
        aas_master_parent_id: 0,
        aas_master_id: 282,
        aas_master_seq: 0,
        aas_master_type: "SMC",
        aas_master_id_short: "productDataBeauty",
        aas_master_id_type: null,
        aas_master_identification: "NULL",
    },
    {
        aas_master_parent_id: 282,
        aas_master_id: 287,
        aas_master_seq: 5,
        aas_master_type: "Prop",
        aas_master_id_short: "serialNumberBeauty",
        aas_master_id_type: null,
        aas_master_identification: "NULL",
    },
    {
        aas_master_parent_id: 282,
        aas_master_id: 288,
        aas_master_seq: 6,
        aas_master_type: "Prop",
        aas_master_id_short: "machineNameBeauty",
        aas_master_id_type: null,
        aas_master_identification: "NULL",
    },
    {
        aas_master_parent_id: 282,
        aas_master_id: 289,
        aas_master_seq: 7,
        aas_master_type: "Prop",
        aas_master_id_short: "machineNumberBeauty",
        aas_master_id_type: null,
        aas_master_identification: "NULL",
    },
    {
        aas_master_parent_id: 282,
        aas_master_id: 290,
        aas_master_seq: 8,
        aas_master_type: "Prop",
        aas_master_id_short: "productNameBeauty",
        aas_master_id_type: null,
        aas_master_identification: "NULL",
    },
    {
        aas_master_parent_id: 282,
        aas_master_id: 291,
        aas_master_seq: 9,
        aas_master_type: "Prop",
        aas_master_id_short: "productNumberBeauty",
        aas_master_id_type: null,
        aas_master_identification: "NULL",
    },
];

export default Line;
