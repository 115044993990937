import {
    makeTokenAxiosDeleteRequest,
    makeTokenAxiosGetRequest,
    makeTokenAxiosPostRequest,
    makeTokenAxiosPutRequest,
    makeAxiosPostFileRequest,
    makeTokenAxiosPutRequestParams,
} from '../utils/AxiosMask';

const baseUrl = 'inspection_defect_type';

export const insertDefectType = async (param) => {
    let res;
    const urlPath = `${baseUrl}/insert_defect_type`;

    try {
        res = await makeTokenAxiosPostRequest(urlPath, param);
        return res.status === 200 ? res.data : 'fail';
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : 'fail';
};

export const updateDefectType = async (param) => {
    let res;
    const urlPath = `${baseUrl}/update_defect_type`;

    try {
        res = await makeTokenAxiosPutRequest(urlPath, param);
        return res.status === 200 ? res.data : 'fail';
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : 'fail';
};

export const deleteDefectType = async (param) => {
    let res;
    const urlPath = `${baseUrl}/delete_defect_type`;

    try {
        res = await makeTokenAxiosDeleteRequest(urlPath, param);
        return res.status === 200 ? res.data : 'fail';
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : 'fail';
};

export const getOneDefectType = async (param) => {
    let res;
    const urlPath = `${baseUrl}/get_one_defect_type`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : 'fail';
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : 'fail';
};

export const getMainDefectType = async (param) => {
    let res;
    const urlPath = `${baseUrl}/get_main_defect_type`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : 'fail';
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : 'fail';
};

export const getSysDivisionCaptionByCode = async (param) => {
    let res;
    const urlPath = `${baseUrl}/get_sys_division_caption_by_code`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : 'fail';
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : 'fail';
};

export const uploadInspectionDefectType = async (data, formData) => {
    var res;
    const urlPath = `${baseUrl}/upload_inspection_defect_type?user_id=${data.user_id}`;
    try {
        res = await makeAxiosPostFileRequest(urlPath, formData);
        return res.status === 200 ? res.data : 'fail';
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : 'fail';
};

export const getDefectTypeCategory = async (param) => {
    let res;
    const urlPath = `${baseUrl}/get_defect_type_category`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : 'fail';
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : 'fail';
};

export const getMainDefectTypeCategory = async (param) => {
    let res;
    const urlPath = `${baseUrl}/get_main_defect_type_category`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : 'fail';
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : 'fail';
};

export const getOneDefectTypeCategory = async (param) => {
    let res;
    const urlPath = `${baseUrl}/get_one_defect_type_category`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : 'fail';
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : 'fail';
};

export const insertDefectTypeCategory = async (param) => {
    let res;
    const urlPath = `${baseUrl}/insert_defect_type_category`;

    try {
        res = await makeTokenAxiosPostRequest(urlPath, param);
        return res.status === 200 ? res.data : 'fail';
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : 'fail';
};

export const updateDefectTypeCategory = async (param) => {
    let res;
    const urlPath = `${baseUrl}/update_defect_type_category`;

    try {
        res = await makeTokenAxiosPutRequest(urlPath, param);
        return res.status === 200 ? res.data : 'fail';
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : 'fail';
};

export const updateDefectTypeCategorySeq = async (param) => {
    let res;
    const urlPath = `${baseUrl}/update_defect_type_category_seq`;

    try {
        res = await makeTokenAxiosPutRequestParams(urlPath, param);
        return res.status === 200 ? res.data : 'fail';
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : 'fail';
};

export const deleteDefectTypeCategory = async (param) => {
    let res;
    const urlPath = `${baseUrl}/delete_defect_type_category`;

    try {
        res = await makeTokenAxiosDeleteRequest(urlPath, param);
        return res.status === 200 ? res.data : 'fail';
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : 'fail';
};
