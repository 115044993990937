import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useTranslation from '../../components/customHooks/translations';
import { PageDropdown } from '../../components/common/PageDropdown';
import { PageTitle } from '../../components/common/PageTitle.jsx';
import BaseDataGrid from '../../components/common/BaseDataGrid';
import { TreeList, Column, SearchPanel } from 'devextreme-react/tree-list';
import { getLanguage, getUser, getMenuMappingInfo, getIP } from '../../apiInterface/utils/Common';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import { Button, Popup, ScrollView } from 'devextreme-react';
import CustomStore from 'devextreme/data/custom_store';
import Badge from '../../components/common/Badge';
import { SearchableField, TextboxField, DropdownField, FilterComponent, FileField } from '../../components/common/FormField';
import InspectionStandardGroupGrid from './InspectionStandardGroupGrid';
import { getInspectionLibraryItemApplied } from '../../apiInterface/inspection/InspectionStandardLibraryAPI';

const InspectionStandardGroup = () => {
    const translation = useTranslation();
    const location = useLocation();
    const errorHandler = useErrorHandler();
    const user = getUser();
    const lang = getLanguage();
    const { state } = useLocation();

    const [isPopupVisibility, setPopupVisibility] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [selectedItems, setSelectedItems] = useState([]);

    return (
        <div className="inspection-standard-group">
            <div className="right-content-title">
                <PageTitle />
                <PageDropdown />
            </div>
            <div className="right-content-body">
                <InspectionStandardGroupGrid items={setSelectedItem} isVisible={setPopupVisibility} />
            </div>
            <AppliedItemPopup
                visible={isPopupVisibility}
                onClose={() => setPopupVisibility(false)}
                // onOk={() => setPopupVisibility(true)}
                item={selectedItem}
            />
        </div>
    );
};
export default InspectionStandardGroup;

const AppliedItemPopup = (props) => {
    const { visible, onClose = () => {}, onOk = () => {}, item } = props;

    const translation = useTranslation();
    const errorHandler = useErrorHandler();
    const user = getUser();
    const lang = getLanguage();

    const [dataSource, setDataSource] = useState([]);
    const [searchTxt, setSearchTxt] = useState('');

    // get grid list
    const getDataGridInfo = async () => {
        const param = {
            user_company_id: user.user_company_id,
            inspection_library_id: item.inspection_library_id,
            sys_lang_code: lang,
            search_text: searchTxt,
        };

        const res = await errorHandler(getInspectionLibraryItemApplied, param);
        if (res) {
            const rlt = [];

            res.data.o_data.map((m) => {
                if (m.inspection_standards_defect_type_json !== null && m.inspection_standards_defect_type_json !== undefined) {
                    let strToJson = JSON.parse(m.inspection_standards_defect_type_json);
                    if (strToJson.length > 0) {
                        strToJson.map((k) => {
                            let parent = { item_master_id: m.item_master_id, item_master_code: m.item_master_code, item_master_name: m.item_master_name, inspection_standards_comment: m.inspection_standards_comment };
                            let combine = { ...parent, ...k };
                            rlt.push(combine);
                        });
                    }
                }
            });

            setDataSource(rlt);
        }
    };

    const header = [
        { dataField: 'item_master_id', caption: translation.item_master_id, dataType: 'string', allowReordering: true, visible: false },
        { dataField: 'item_master_code', caption: translation.item_master_code, dataType: 'string', allowReordering: true },
        { dataField: 'item_master_name', caption: translation.item_master_name, dataType: 'string', allowReordering: true },
        { dataField: 'defect_type_id', caption: translation.defect_type_id, dataType: 'string', allowReordering: true, visible: false },
        { dataField: 'defect_type_code', caption: translation.defect_type_code, dataType: 'string', allowReordering: true },
        { dataField: 'defect_type_name', caption: translation.defect_type_name, dataType: 'string', allowReordering: true },
        { dataField: 'inspection_standards_comment', caption: translation.comment, dataType: 'string', allowReordering: true },
    ];

    const filter = <FilterComponent label={translation.filter} width={204} onFilter={(keyword) => setSearchTxt(keyword)} />;

    useEffect(() => {
        console.log('visible', visible);
        if (visible) getDataGridInfo();
    }, [visible, searchTxt]);

    return (
        <Popup width={1100} height={850} visible={visible} hideOnOutsideClick={true} onHiding={onClose} dragEnabled={true} position="center" showTitle={false}>
            <div className="popup-applied-item">
                <div className="popup-header">
                    <p className="popup-title">Items with Inspection Standard library Applied</p>
                </div>
                <div className="popup-body">
                    <div className="library-info">
                        <ul>
                            <li>
                                <span>{translation.select_inspectionType}</span>
                                <h6>{item.inspection_type_name}</h6>
                            </li>
                            <li>
                                <span>{translation.inspection_library_code}</span>
                                <h6>{item.inspection_library_code}</h6>
                            </li>
                            <li>
                                <span>{translation.inspection_library_name}</span>
                                <h6>{item.inspection_library_name}</h6>
                            </li>
                        </ul>
                    </div>
                    <div className="item-list">
                        <ScrollView width="100%">
                            <div className="container-wrapper">
                                <div className="container">
                                    <BaseDataGrid toolbarModules={[filter]} dataSource={dataSource} headers={header} allowModify={false} showCheckBox={false} />
                                </div>
                            </div>
                        </ScrollView>
                    </div>
                </div>
                <div className="popup-footer">
                    <Button width={86} text={translation.close} type="normal" stylingMode="text" className="btn-s-r" onClick={onClose} />
                </div>
            </div>
        </Popup>
    );
};
