// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aasx-file-mgt-form .dx-fileuploader {
    position: relative;
}
.aasx-file-mgt-form .dx-fileuploader .dx-invalid-message {
    position: absolute;
    top: 0px;
    left: 0px;
}
.aasx-file-mgt-form .dx-fileuploader .dx-invalid-message .dx-invalid-message-always {
    height: unset !important;
}
.aasx-file-mgt-form .dx-fileuploader .dx-invalid-message .dx-invalid-message-always .dx-invalid-message-content {
    transform: unset !important;
    height: 38px !important;
    line-height: 38px;
    left: 250px !important;
}
.aasx-file-mgt-form .dx-fileuploader .dx-fileuploader-files-container {
    padding-top: 0px !important;
}
.aasx-file-mgt-form .fileuploader-file .dx-fileuploader-files-container .dx-fileuploader-file-container {
    margin-top: 8px;
}`, "",{"version":3,"sources":["webpack://./src/contents/styles/dataManagement/aas-file-management.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;AACb;AACA;IACI,wBAAwB;AAC5B;AACA;IACI,2BAA2B;IAC3B,uBAAuB;IACvB,iBAAiB;IACjB,sBAAsB;AAC1B;AACA;IACI,2BAA2B;AAC/B;AACA;IACI,eAAe;AACnB","sourcesContent":[".aasx-file-mgt-form .dx-fileuploader {\n    position: relative;\n}\n.aasx-file-mgt-form .dx-fileuploader .dx-invalid-message {\n    position: absolute;\n    top: 0px;\n    left: 0px;\n}\n.aasx-file-mgt-form .dx-fileuploader .dx-invalid-message .dx-invalid-message-always {\n    height: unset !important;\n}\n.aasx-file-mgt-form .dx-fileuploader .dx-invalid-message .dx-invalid-message-always .dx-invalid-message-content {\n    transform: unset !important;\n    height: 38px !important;\n    line-height: 38px;\n    left: 250px !important;\n}\n.aasx-file-mgt-form .dx-fileuploader .dx-fileuploader-files-container {\n    padding-top: 0px !important;\n}\n.aasx-file-mgt-form .fileuploader-file .dx-fileuploader-files-container .dx-fileuploader-file-container {\n    margin-top: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
