import { Button } from "devextreme-react";
import { useRef } from "react";
import { read } from "xlsx";

export default function FileUploader({
    onUpload = () => { }
}) {
    const inputRef = useRef();

    const upload = (e) => {
        const value = e.target;
        let reader = new FileReader();
        reader.onload = readExcel;
        reader.readAsBinaryString(value.files[0]);
    }

    const readExcel = (e) => {
        let data = e.target.result;
        let workbood = read(data, {type: 'binary'});

        console.log(workbood);
    }

    const onClick = (e) => {
        inputRef.current.click();
    }

    return (
        <>
            <Button onClick={onClick}>File Upload</Button>
            <input
                type="file"
                accept=".csv,.xlsx"
                ref={inputRef}
                style={{ display: "none" }}
                onChange={upload}
            ></input>
        </>
    )
}
