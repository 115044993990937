﻿import React, { useRef, useEffect, useState } from 'react';
import { PageDropdown } from "../../components/common/PageDropdown";
import { Popup } from 'devextreme-react/popup';
import Button from 'devextreme-react/button';
import ScrollView from 'devextreme-react/scroll-view';
import useTranslation from "../../components/customHooks/translations";
import "../../contents/styles/dataManagement/aas-master-management.css";
import SelectBox from 'devextreme-react/select-box';
import BaseDataGrid from '../../components/common/BaseDataGrid';
import { DropdownField, FilterComponent } from '../../components/common/FormField';
import { deleteAasMaster, getAasMasterExplorer, getMainAasMaster } from '../../apiInterface/aasFile/AASMasterManagementAPI';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import { getIP, getLanguage, getMenuMappingInfo, getUser } from '../../apiInterface/utils/Common';
import CustomStore from 'devextreme/data/custom_store';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';
import { successToastr } from '../../components/common/Toastr';
import Badge from '../../components/common/Badge';
import TreeCard from '../../components/common/TreeCard';
import { PageTitle } from "../../components/common/PageTitle.jsx";


let keyword = "";
let selectedData = "";
const AASMasterManagement = () => {
    const [isPopupVisible, setPopupVisibility] = useState(false);
    const [curDropdown, setCurDropdown] = useState(null);
    const [dataSource, setDataSource] = useState([]);
    const translation = useTranslation();
    const errorHandler = useErrorHandler();
    const gridRef = useRef();
    const popupRef = useRef();
    const fakeDropdown = [
        { name: "Asset", value: "Asset" },
        { name: "AAS", value: "AssetAdministrationShell" },
        { name: "SM", value: "Submodel" },
        { name: "SMC", value: "SubmodelElementCollection" },
    ]

    const activeNotActiveJson = { true: translation.active, false: translation.not_active };
    const activeNotActiveClassName = { true: "grid-badge-use", false: "grid-badge-disabled" };
    const aasTypeJson = { true: translation.active, false: translation.not_active };
    const gridHeader = [
        { dataField: "aas_master_no", caption: "NO", alignment: "center", dataType: "number", width: 100, isFixed: true, allowReordering: true },
        {
            dataField: "aas_master_type", caption: "aas type".toUpperCase(), dataType: "string",
            width: 130, alignment: "center", isBadge: true, captionForBadge: "aas_master_type", classNameForBadge: activeNotActiveClassName,
            isFixed: true, allowReordering: true
        },
        { dataField: "aas_master_id_short", caption: "aas name".toUpperCase(), dataType: "string", isFixed: true, allowReordering: true },
        { dataField: "aas_master_id_type", caption: "id type".toUpperCase(), dataType: "string", allowReordering: true },
        { dataField: "aas_master_identification", caption: "aas_master_size".toUpperCase(), dataType: "string", allowReordering: true },
        { dataField: "aas_master_rev_no", caption: "version".toUpperCase(), dataType: "number", allowReordering: true },
        { dataField: "aas_master_rev_date", caption: "rev date".toUpperCase(), dataType: "date", allowReordering: true },
        { dataField: "aas_master_rev_comment", caption: "rev comment".toUpperCase(), dataType: "string", allowReordering: true },
        {
            dataField: "aas_master_is_active", caption: "Use or not".toUpperCase(), dataType: "string",
            width: 130, alignment: "center", allowReordering: true,
            cellRender: (e) => <Badge className={activeNotActiveClassName[e.value]} text={activeNotActiveJson[e.value]} />,
        },
        { dataField: "aas_master_comment", caption: "comment".toUpperCase(), dataType: "string", allowReordering: true },
        { dataField: "aas_master_modified_by", caption: translation.lastModifiedBy.toUpperCase(), dataType: "string", width: 300, allowReordering: true },
        { dataField: "aas_master_modified_date", caption: translation.lastModifiedDate.toUpperCase(), dataType: "string", width: 150, allowReordering: true },
    ];

    const renderDeleteConfirm = () => {
        return <AASMasterDeletePopup
            close={() => { openPopup(false) }}
            data={selectedData}
            ref={popupRef}
        />;
    }

    const openPopup = (val) => {
        setPopupVisibility(val);
    }

    useEffect(() => {
        keyword = "";
        setCurDropdown(fakeDropdown[0].value);
    }, []);

    const makeCustomStore = (aasType = curDropdown) => {
        const customStore = new CustomStore({
            load: async (loadOptions) => {
                return load(loadOptions, aasType);
            },
        });

        setDataSource(customStore);
        return customStore;
    }

    const load = async (loadOptions, aasType) => {
        const language = getLanguage();
        const user = getUser();
        const pageNo = parseInt(loadOptions["skip"] / loadOptions["take"]) + 1;
        let rtn = { data: [], totalCount: 0 };
        const param = {
            user_company_id: user.user_company_id,
            sys_lang_code: language,
            aas_type: aasType,
            row_count: loadOptions["take"],
            page_no: pageNo,
            search_text: keyword,
        };

        const res = await errorHandler(getMainAasMaster, param);
        if (res) {
            rtn.data = res.data.o_data;
            rtn.totalCount = res.data.count;
        }
        return rtn;
    }

    const dropdownValueChanged = (e) => {
        let value = e.value;
        makeCustomStore(value);
        setCurDropdown(value);
    }

    const deleteBtnClick = (instance, selected) => {
        selectedData = selected[0];
        openPopup(true);
    }

    const filtering = (word) => {
        keyword = word;
        makeCustomStore();
    }

    return (
        <>
            <div className="aas-master-mgt">
                <div className="right-content-title">
                    <PageTitle />
                    <PageDropdown />
                </div>
                <div className="right-content-body">
                    <div className="container">
                        <BaseDataGrid
                            ref={gridRef}
                            dataSource={dataSource}
                            headers={gridHeader}
                            toolbarModules={
                                [
                                    <DropdownField
                                        label="AAS Type"
                                        dataSource={fakeDropdown}
                                        value={curDropdown}
                                        displayExpr="name"
                                        valueExpr="value"
                                        width={204}
                                        onValueChanged={dropdownValueChanged}
                                    />,
                                    <FilterComponent
                                        label={translation.filter}
                                        width={204}
                                        onFilter={filtering}
                                    />
                                ]
                            }
                            showAdd={false}
                            showEdit={false}
                            customDelete={deleteBtnClick}
                            isRemote={true}
                            link="AASMasterManagementExplorer"
                            isFullSize={true}
                        />
                    </div>
                </div>
            </div>
            <Popup
                width={840}
                height="auto"
                visible={isPopupVisible}
                hideOnOutsideClick={true}
                onShowing={() => {
                    const instance = popupRef.current;
                    if (instance != null) {
                        instance.init();
                    }
                }}
                onHiding={(e) => openPopup(false)}
                contentRender={renderDeleteConfirm}
                dragEnabled={true}
                position="center"
                showTitle={false}
            />
        </>
    );
}


let temp = null;
function AASMasterDeletePopup(props, ref) {
    const { close, data } = props;
    const [delBtnDisable, setDelBtnDisable] = useState(true);
    const [treeData, setTreeData] = useState([]);
    const translation = useTranslation();
    const errorHandler = useErrorHandler();
    const [curDropdownValue, setCurDropdownValue] = useState();
    const dropdownRef = useRef();
    const fakeDropdown = [
        { name: "Single", value: false },
        { name: "Delete all", value: true },
    ];

    useImperativeHandle(ref, () => ({ init: initalizer }));

    useEffect(() => { initalizer(); }, []);

    const deleteItem = async (data) => {
        setDelBtnDisable(true);
        //do remove item
        const user = getUser();
        const ip = getIP();
        const mappingInfo = await getMenuMappingInfo();
        const param = {
            user_master_id: user.user_master_id,
            user_log_ip: ip,
            menu_mapping_id: mappingInfo.id,
            user_company_id: user.user_company_id,
            aas_master_id: data.aas_master_id,
            all_delete: curDropdownValue,
        }

        const res = await errorHandler(deleteAasMaster, param);
        if (res) {
            successToastr(translation.success_delete_row);
            close();
        }
        setDelBtnDisable(false);
    }

    const initalizer = async () => {
        const instance = dropdownRef.current.instance;
        instance.option("value", fakeDropdown[0].value);

        const user = getUser();
        const param = {
            user_company_id: user.user_company_id,
            aas_master_id: data.aas_master_id
        }

        const res = await errorHandler(getAasMasterExplorer, param);
        if (res) {
            let data = res.data.o_data;
            data.forEach((element) => {
                let detail = element.aas_master_id_short;

                let identification = element.aas_master_identification;
                if (identification != null && identification !== 'NULL') {
                    detail += ` - ${identification}`;
                }

                element.detail = detail;
            });
            setTreeData(data);
        }
    }

    return (
        <div className="popup-delete-confirm">
            <div className="popup-header">
                <p className="popup-title">Delete Confirm</p>
            </div>
            <div className="popup-body">
                <div className="dx-fieldset">
                    <div className="dx-field column">
                        <div className="dx-field-label">
                            <p>Delete Options</p>
                        </div>
                        <div className="dx-field-value">
                            <SelectBox
                                dataSource={fakeDropdown}
                                displayExpr="name"
                                valueExpr="value"
                                className="ix-tfs-r"
                                width={212}
                                onValueChanged={(e) => setCurDropdownValue(e.value)}
                                defaultValue={fakeDropdown[0].value}
                                value={curDropdownValue}
                                ref={dropdownRef}
                            />
                            <p className="info-comment">
                                <span className="material-icons-outlined">info</span>
                                Deletes the selected item and all items contained in the item.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="delete-confirm-list">
                    <ScrollView>
                        <TreeCard
                            data={treeData}
                            onRowClick={(data) => {
                                temp = data;
                                setDelBtnDisable(false);
                            }}
                            keyExpr="aas_master_id"
                            parentExpr="aas_master_parent_id"
                            badgeExprs={["aas_master_type"]}
                            nameExpr="aas_master_short"
                            detailExpr="detail"
                        />
                    </ScrollView>
                </div>
            </div>
            <div className="popup-footer">
                <Button
                    width={86}
                    text={translation.cancel}
                    type="normal"
                    stylingMode="text"
                    className="btn-s-r"
                    onClick={() => close()}
                />
                <Button
                    text={translation.delete}
                    width={86}
                    type="danger"
                    stylingMode="contained"
                    className="btn-s-r"
                    disabled={delBtnDisable}
                    onClick={() => deleteItem(temp)}
                />
            </div>
        </div>
    )
}
AASMasterDeletePopup = forwardRef(AASMasterDeletePopup);

export default AASMasterManagement;
