import BadTypeManagementInfo from "../pages/badTypeManagement/BadTypeManagementInfo";
import BadTypeManagementInfoForm from "../pages/badTypeManagement/BadTypeManagementInfoForm";
import InspectionDefectType from "../pages/badTypeManagement/InspectionDefectType";
import InspectionStandardGroup from "../pages/badTypeManagement/InspectionStandardGroup";
import DefectTypeStandard from "../pages/badTypeManagement/DefectTypeStandard";
import InspectionDefectTypeForm from "../pages/badTypeManagement/InspectionDefectTypeForm";
import InspectionStandardLibraryForm from "../pages/badTypeManagement/InspectionStandardLibraryForm";
import DefectTypeStandardForm from "../pages/badTypeManagement/DefectTypeStandardForm";
import DefectTypeStandardFormDtl from "../pages/badTypeManagement/DefectTypeStandardFormDtl";

export { BadTypeManagementInfo, BadTypeManagementInfoForm, InspectionDefectType, InspectionStandardGroup, DefectTypeStandard, InspectionDefectTypeForm, InspectionStandardLibraryForm, DefectTypeStandardForm, DefectTypeStandardFormDtl }