import React, { useRef, useEffect, useState } from 'react';
import { PageDropdown } from "../../components/common/PageDropdown";

const RawData = () => {
    return (
        <>
            <div className="raw-data">
                <div className="right-content-title">
                    <h3>Raw Data</h3>
                    <PageDropdown />
                </div>
                <div className="block block-title"></div>
                <div className="right-content-body">
                    {/*datagrid*/}
                </div>
            </div>
        </>
    );
}
export default RawData;