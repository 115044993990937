import {
  makeTokenAxiosGetRequest, makeTokenAxiosPostRequest,
  makeTokenAxiosPutRequest, makeTokenAxiosDeleteRequest
} from "../utils/AxiosMask"

export const getQualityBadTypeByCompanyId = async (data) => {
  var res;
  const urlPath = `quality_bad_type/`;
  try {
    res = await makeTokenAxiosGetRequest(urlPath, data);
    return res.status === 200 ? res.data : "fail";
  } catch (error) {
    console.error('error', error);
  }
  return res.status === 200 ? res : "fail";
};


export const getQualityBadTypeById = async (data) => {
  var res;
  //const urlPath = `quality_bad_type/${data.quality_badtype_id}`;
  const urlPath = "quality_bad_type/get_quality_badtype_by_id/";
  try {
    res = await makeTokenAxiosGetRequest(urlPath, data);
    return res.status === 200 ? res.data : "fail";
  } catch (error) {
    console.error('error', error);
  }
  return res.status === 200 ? res : "fail";
}


export const updateQualityBadTypeByCompanyId = async (data) => {
  var res;
  const urlPath = `quality_bad_type/`;
  try {
    res = await makeTokenAxiosPutRequest(urlPath, data);
    return res.status === 200 ? res.data : "fail";
  } catch (error) {
    console.error('error', error);
  }
  return res.status === 200 ? res : "fail";
};


export const deleteQualityBadTypeByCompanyId = async (data) => {
  var res;
  const urlPath = `quality_bad_type/`;
  try {
    res = await makeTokenAxiosDeleteRequest(urlPath, data);
    return res.status === 200 ? res.data : "fail";
  } catch (error) {
    
    console.error('error', error);
  }
  return res.status === 200 ? res : "fail";
};


export const insertQualityBadTypeByCompanyId = async (data) => {
  var res;
  const urlPath = `quality_bad_type/`;
  try {
    res = await makeTokenAxiosPostRequest(urlPath, data);
    return res.status === 200 ? res.data : "fail";
  } catch (error) {
    
    console.error('error', error);
  }
  return res.status === 200 ? res : "fail";
};
