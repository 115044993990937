import React, { useRef, useEffect, useState } from 'react';
import * as echarts from "echarts";
import { echartTest, echartLiveTest } from "../../apiInterface/home/HomeAPI";
import { useInterval } from "../../components/customHooks/useInterval";
import useTransition from '../../components/customHooks/translations';


const getTestData = async (chart_type) => {
	var res = null;
	try {
		const param = { "chart_type": chart_type}
		res = await echartTest(param);
	}
	catch (err) {
		console.log(err);
	}
	return res;
}


const getLiveData = async (lqt) => {
	var res = null;
	try {
		const param = { "lqt": lqt }
		res = await echartLiveTest(param);
	}
	catch (err) {
		console.log(err);
	}
	return res;
}

const makeArea = (areaList) => {
	if (areaList == undefined) return [null,null];
	const linePieces = []
	const areaPieces = []
	areaList.forEach((item) => {
		const lineJson = {
			"gt": item[0],
			"lt": item[1]
        }
		linePieces.push(lineJson);
		areaPieces.push({ "xAxis": item[0] });
		areaPieces.push({ "xAxis": item[1] });
	});
	return [linePieces, areaPieces];
}
const makeMapData = (data, chartType) => {
	if (chartType != "Bar") return [null, null];
	const xList = [];
	const yList = [];
	data.map((item, idx) => {
		xList.push(item[0]);
		yList.push(item[1]);
	});
	return [xList, yList];
}
const makePolygonData = (titleList, dataList) => {
	if (titleList == undefined) return [null];
	const polygonPieces = []
	titleList.map((item, idx) => {
		const polygonJson = {
			value: dataList[idx],
			name: item
		}
		polygonPieces.push(polygonJson)
	});
	return polygonPieces;
}
const makeTreeData = (data, chartType) => {
	if (chartType != "Tree") return ;
	data.children.forEach(function (datum, index) {
		index % 2 === 0 && (datum.collapsed = true);
	});
	return data;
}
const getChartOptionDict = (chartType, data) => {
	const areaValue = makeArea(data["area"]);
	const dataValue = makeMapData(data["data"], chartType);
	const polygonValue = makePolygonData(data["line_title"], data["data"]);
	const treeValue = makeTreeData(data["data"], chartType);
	const chartOptionDict = {
		"Line": {
			dataZoom: [
				{
					type: 'inside',
					start: 0,
					end: 100
				},
				{
					show: true,
					type: 'slider',
					top: '90%',
					start: 0,
					end: 100
				}
			],
			xAxis: {
				type: 'category',
			},
			yAxis: {
				type: 'value'
			},
			visualMap: {
				type: 'piecewise',
				show: false,
				dimension: 0,
				seriesIndex: 0,
				pieces: areaValue[0]
			},
			series: [
				{
					type: 'line',
					smooth: 0.6,
					symbol: 'none',
					lineStyle: {
						color: '#5470C6',
						width: 1
					},
					markLine: {
						symbol: ['none', 'none'],
						label: { show: false },
						data: areaValue[1]
					},
					areaStyle: {},
					data: data["data"]
				}
			]
		},
		"Bar": {
			xAxis: {
				type: 'category',
				data: dataValue[0]
			},
			yAxis: {
				type: 'value'
			},
			series: [
				{
					data: dataValue[1],
					type: 'bar'
				}
			]
		},
		"Scatter": {
			xAxis: {
			},
			yAxis: {
			},
			series: [
				{
					data: data["data"],
					type: 'scatter',

				}
			]
		},
		"Polygon": {
			legend: {
				data: data["line_title"]
			},
			radar: {
				// shape: 'circle',
				indicator: data["indicator"]
			},
			series: [
				{
					type: 'radar',
					data: polygonValue
				}
			]
		}
		,
		"Tree": {
			tooltip: {
				trigger: 'item',
				triggerOn: 'mousemove'
			},
			series: [
				{
					type: 'tree',
					data: [treeValue],
					top: '1%',
					left: '7%',
					bottom: '1%',
					right: '20%',
					symbolSize: 7,
					label: {
						position: 'left',
						verticalAlign: 'middle',
						align: 'right',
						fontSize: 9
					},
					leaves: {
						label: {
							position: 'right',
							verticalAlign: 'middle',
							align: 'left'
						}
					},
					emphasis: {
						focus: 'descendant'
					},
					expandAndCollapse: true,
					animationDuration: 550,
					animationDurationUpdate: 750
				}
			]
		},
		"Live": {
			xAxis: {
				type: 'category',
				boundaryGap: false,
				data: data["x"]
			},
			yAxis: {
				boundaryGap: [0, '50%'],
				type: 'value'
			},
			series: [
				{
					type: 'line',
					smooth: true,
					symbol: 'none',
					stack: 'a',
					areaStyle: {
						normal: {}
					},
					data: data["y"]
				}
			]
		}
	}
	return chartOptionDict[chartType];
}

const Chart = (props) => {
	const translation = useTransition();
	const chartRef = useRef(null);
	const [chartType, setChartType] = useState(null);
	const [chartOption, setChartOption] = useState(null);
	const [count, setCount] = useState(null);
	const [isRunning, setIsRunning] = useState(true);
	const [delay, setDelay] = useState(1000);
	const [lqt, setLqt] = useState("initial");
	useInterval(() => {
		getLiveData(lqt).then(res => {
			return res;
		}).then(data => {
			const xData = chartOption["xAxis"]["data"];
			const yData = chartOption["series"][0]["data"];
			xData.shift(data["x"].length);
			yData.shift(data["y"].length);
			xData.push(data["x"]);
			yData.push(data["y"]);
			const tempChartOption =
			{
				xAxis: {
					data: xData
				},
				series: [
					{
						data: yData
					}
				]
			};
			//const chart = echarts.init(chartRef.current);
			//chart.setOption(tempChartOption);

			const chart = echarts.init(document.getElementById('main'));
			chart.setOption(tempChartOption);
		});
	}, isRunning ? delay : null);

	const updateChartData = (eventChartType) => {
		if (eventChartType === "Live") {
			getLiveData(lqt).then(res => {
				return res;
			}).then(data => {
				setLqt(data["lqt"]);
				const tempChartOption = getChartOptionDict(eventChartType, data);
				setChartOption(tempChartOption);
				setChartType(eventChartType);
				setIsRunning(true);

			});
		}
		else {
			setIsRunning(false);
			setLqt("initial");
			getTestData(eventChartType).then(res => {
				return res;
			}).then(data => {
				const tempChartOption = getChartOptionDict(eventChartType, data);
				setChartOption(tempChartOption);
				setChartType(eventChartType);
			});

		}

	}
	useEffect(() => {
		updateChartData("Line");
	}, []);

	useEffect(() => {
		if (chartType != null) {

			const chart = echarts.init(document.getElementById('main'));
			chart.setOption(chartOption, true);
		}
		//if (chartRef.current && chartType != null) {
		//	const chart = echarts.init(chartRef.current);
		//	chart.setOption(chartOption,true);
		//	window.onresize = () => {
		//		chart.resize();
		//	};
		//}
	}, [chartType]);
	return (
		<div style={{
			border: "solid",
			padding: "64px 16px 16px 16px",
			width: "100%",
			height: "800px",
			display: "flex"
		}}>
			<div style={{
				border: "solid",
				width: "200px",
			}}>
				<button style={{
					border: "solid",
					margin: "8px 16px 16px 8px"
				}} onClick={(e) => updateChartData("Line")}>{translation.lineChart}</button>
				<button style={{
					border: "solid",
					margin: "8px 16px 16px 8px"
				}} onClick={(e) => updateChartData("Bar")}>{translation.barChart}</button>
				<button style={{
					border: "solid",
					margin: "8px 16px 16px 8px"
				}} onClick={(e) => updateChartData("Scatter")}>{translation.scatterChart}</button>
				<button style={{
					border: "solid",
					margin: "8px 16px 16px 8px"
				}} onClick={(e) => updateChartData("Polygon")}>{translation.polygonChart}</button>
				<button style={{
					border: "solid",
					margin: "8px 16px 16px 8px"
				}} onClick={(e) => updateChartData("Tree")}>{translation.treeChart}</button>
				<button style={{
					border: "solid",
					margin: "8px 16px 16px 8px"
				}} onClick={(e) => updateChartData("Live")}>{translation.liveChart}</button>
			</div>
			<div
				id={"main" }
				style={{
				border: "solid",
				width: "calc(100% - 200px)",
				}}
			>
			</div>
		</div>
	)
}

export default Chart;