import React, {
    useState,
    useEffect,
    useRef,
    forwardRef,
    useImperativeHandle,
} from 'react';
import useTranslation from '../customHooks/translations';
import Button from 'devextreme-react/button';
import DataGrid, {
    Column,
    Editing,
    Paging,
    Toolbar,
    Item,
    Pager,
    Selection,
    Scrolling,
} from 'devextreme-react/data-grid';
import { useNavigate } from 'react-router-dom';
import ContextMenu from 'devextreme-react/context-menu';
import { useSwal } from '../../components/common/Swal';
import useWindowSize from '../customHooks/useWindowSize';
import BtnTooltip from './BtnTooltip';

const BaseDataGrid = forwardRef((props, ref) => {
    const translation = useTranslation();
    const {
        addBtnText = translation.add,
        addBtnType = 'default',
        toolbarModules = [],
        width: gridWidth,
        height: gridHeight,
        dataSource,
        selectMode = 'multiple',
        allowRightClick = false,
        headers,
        isPaging = true,
        defaultPageSize = 20, //if isPaging is false, this value will be ignored.
        isRemote = false,
        onRowRemove,
        onRowInsert,
        onRowUpdate,
        link,
        columnAutoWidth,
        allowAdd = true,
        allowEdit = true,
        allowDelete = true,
        params = {},
        gridStyle = 'datagrid-line',
        allowModify = true,
        allowRowSelectByClick = false, //allow selecting a row not only checkbox but also click a row.
        showCheckBox = true,
        onRowClick: onRowClickCallback = () => {},
        infoComment,
        showEdit = true,
        showDelete = true,
        showAdd = true,
        showCopy = false,
        addAction = () => {},
        addBtnSize = 80,
        keyExpr,
        isDoubleClicked = true,
        customDelete = null, //user defied delete function. if function is assigned, ignore default delete procedure.
        onSelectionChanged: onSelectionChangedCallback = () => {},
        allowColumnResizing = false,
        isFullSize = false,
        preRemove = () => {},
        postRemove = () => {},
        isDeleteWarning = true,
        isDisableCopy,
        showAppliedItem = false,
        showLoadDefectTpye = false,
        showLoadInspection = false,
        showAddCondition = false,
        showAddDefect = false,
        checkInUse = () => { },
    } = props;

    const { width: windowWidth, height: windowHeight } = useWindowSize();
    const navigate = useNavigate();
    const [isEdit, disableEdit] = useState(true);
    const [isDelete, disableDelete] = useState(true);
    const [rowData, setRowData] = useState({});
    const gridRef = useRef();
    const getInstance = () => {
        return gridRef.current?.instance;
    };
    const { warning, fail } = useSwal();

    useImperativeHandle(ref, () => ({
        getInstance: getInstance,
    }));

    //list of context menu(right-click menu)
    const contextMenu = [{ text: 'Edit' }, { text: 'Delete' }];

    const cellRender = (isBadge, value, captionForBadge, classNameForBadge) => {
        if (isBadge === true)
            return (
                <div className="grid-badge">
                    {value !== undefined && (
                        <span className={`${classNameForBadge[value]}`}>
                            {captionForBadge[value]}
                        </span>
                    )}
                </div>
            );
        else return value;
    };

    //const cellRender2 = (isUpDown, value, classNameForUpDown) => {
    //    if (isUpDown === true)
    //        return(
    //            <div className="updown">
    //                {value !== undefined && (
    //                    <>
    //                        <i className={classNameForUpDown[value]}></i>
    //                        <p>value</p>
    //                    </>
    //                )}
    //            </div>
    //        )
    //    else return value;
    //}

    const onDelete = () => {
        preRemove();
        if (customDelete != null) {
            const instance = getInstance();
            const selected = instance.getSelectedRowsData();
            customDelete(instance, selected);
            return;
        }

        var cb = (res) => {
            if (res.isConfirmed) {
                removeRows();
            }
            postRemove();
        };
        if (isDeleteWarning) {
            warning(`<p>${translation.confirm_delete}</p>`, cb);
        } else {
            removeRows();
            postRemove();
        }
    };

    //remove mutiple rows
    const removeRows = async () => {
        const instance = getInstance();
        const keys = instance.getSelectedRowKeys();

        const [inUse, templateNameList] = await getInUse(keys);
        if (inUse) {
            const str = `${translation.MSG_137}(${templateNameList.toString()})`;
            fail(`<p>${str}</p>`);
        } else {
            if (!isRemote) {
                for (let i = 0; i < keys.length; i++) {
                    let index = instance.getRowIndexByKey(keys[i]);
                    if (index < 0) continue;
                    instance.deleteRow(index);
                    instance.refresh();
                }
            } else {
                const dataSource = instance.getDataSource();
                const store = dataSource.store();
                keys.forEach((key) => {
                    store.remove(key);
                });
                //instance.refresh();
                dataSource.reload();
            }
            instance.deselectAll();
        }
    };

    //selection change event when user clicked datagrid checkbox.
    //depends on number of checks toggle delete, edit button in tool box.
    //single select with checkbox - https://supportcenter.devexpress.com/ticket/details/t585234/dxdatagrid-row-selection-in-single-mode-using-checkboxes
    let customSelect = false;
    const [cnt, setCnt] = useState(0);
    const onSelectionChanged = async (e) => {
        if (customSelect) return;
        customSelect = true;

        if (selectMode === 'single') {
            const instance = getInstance();
            instance.selectRows(e.currentSelectedRowKeys[0], false);
        }

        let rows = e.selectedRowKeys.length;
        if (rows != 1) {
            disableEdit(true);
        } else {
            disableEdit(false);
            setRowData(getInstance().getSelectedRowsData());
        }

        if (rows >= 1) {
            disableDelete(false);
        } else {
            disableDelete(true);
        }

        setCnt(rows);

        if (selectMode === 'single')
            onSelectionChangedCallback(
                e.selectedRowsData[e.selectedRowsData.length - 1]
            );
        else onSelectionChangedCallback(e.selectedRowsData);
        customSelect = false;
    };

    //If row is double-clicked link to Edit page with clicked row info.
    const onRowDoubleClicked = (e) => {
        if (allowModify && isDoubleClicked != false) {
            const data = e.key;
            moveToEdit(data);

            if (data?.defect_type_id !== undefined)
                sessionStorage.setItem('defectTypeId', data?.defect_type_id);
            if (data?.inspection_library_id !== undefined)
                sessionStorage.setItem(
                    'inspectionLibraryId',
                    data?.inspection_library_id
                );
        }
    };

    //automatically toggle checkbox when user clicked row in datagrid.
    const onRowClick = (e) => {
        onRowClickCallback(e);

        if (!allowRowSelectByClick) return;

        const target = e.key;
        const instance = getInstance();
        const data = instance.getSelectedRowKeys();
        let isSame = data.find((element) => {
            return element == target;
        });
        if (isSame) instance.deselectRows([e.key]);
        else
            instance.selectRows(
                [e.key],
                selectMode === 'multiple' ? true : false
            );
    };

    //get data of row which is invoked context menu
    const prepareContextMenu = (e) => {
        //setRowData(e.row.data);
    };

    //onClick event for context menu
    const onItemClick = (e) => {
        if (allowRightClick) {
            switch (e.itemData.text) {
                case 'Edit':
                    moveToEdit(rowData);
                    break;
                case 'Delete':
                    onDelete();
                    break;
                default:
                    throw 'Unexpected Context Menu Item';
            }
        }
    };

    // for custom template, if target template is in use, then can't delete and can't update structure
    const getInUse = async (rowDataList) => {
        if(link === "TemplateMasterForm") {
            let inUse = false;
            const templateNameList = [];
            for await(let data of rowDataList) {
                const res = await checkInUse(data.template_master_id);
                if(res) {
                    inUse = true;
                    templateNameList.push(data.template_master_name);
                }
            }
            return [inUse, templateNameList];
        } else {
            return [false, undefined];
        }
    }

    //link to edit page.
    const moveToEdit = async (rowData) => {
        const [inUse, templateNameList] = await getInUse(rowData);
        const state = {
            title: 'Edit',
            ...params,
            rowData: Array.isArray(rowData) ? rowData[0] : rowData,
        }
        if (inUse) state["inUse"] = inUse;

        navigate(`/${link}`, { state });
    };

    //link to add page.
    const moveToAdd = () => {
        if (link == null) {
            addAction();
            return;
        }

        navigate(`/${link}`, {
            state: {
                title: 'Add',
                ...params,
            },
        });
    };

    //link to copy page.
    const moveToCopy = async (rowData) => {
        if (Array.isArray(rowData)) {
            rowData = rowData[0];
        }

        // if (link == null) {
        //     addAction();
        //     return;
        // }

        navigate(`/${link}`, {
            state: {
                title: 'Copy',
                ...params,
                rowData: rowData,
            },
        });
    };

    //link to applied page.
    const moveToApplied = async (rowData) => {
        if (Array.isArray(rowData)) {
            rowData = rowData[0];
        }

        let tmpLink = 'InspectionStandardGroup';
        navigate(`/${tmpLink}`, {
            state: {
                title: 'Applied',
                ...params,
                rowData: rowData,
            },
        });
    };

    const moveToAddDefect = async () => {
        if (link == null) {
            return;
        }

        // let tmpLink = 'DefectTypeStandardForm';
        navigate(`/${link}`, {
            state: {
                title: 'Add',
                subTitle: 'Add',
            },
        });
    };

    //link to defect type standard page.
    const moveToLoadDefectTpye = async () => {
        // if (link == null) {
        //     return;
        // }

        let tmpLink = 'DefectTypeStandardForm';
        navigate(`/${tmpLink}`, {
            state: {
                subTitle: 'DffectType',
                ...params,
            },
        });
    };

    //
    const moveToLoadInspection = async () => {
        // if (link == null) {
        //     return;
        // }

        let tmpLink = 'DefectTypeStandardForm';
        navigate(`/${tmpLink}`, {
            state: {
                subTitle: 'Inspection',
                ...params,
            },
        });
    };

    //
    const moveToAddCondition = async () => {
        if (link == null) {
            return;
        }

        navigate(`/${link}`, {
            state: {
                title: 'Condition',
                ...params,
            },
        });
    };

    const getClass = () => {
        let datagridClass = 'data-grid ';
        if (isFullSize) {
            datagridClass += 'full-size-grid ';
        }

        switch (gridStyle) {
            case 'stripe':
                datagridClass += 'datagrid-stripe';
                break;
            case 'line':
                datagridClass += 'datagrid-line';
                break;
            case 'seamless':
                datagridClass += '';
                break;
            default:
                datagridClass += 'datagrid-line';
                break;
        }

        return datagridClass;
    };

    return (
        <div className={getClass()}>
            {allowRightClick && (
                <ContextMenu
                    dataSource={contextMenu}
                    target=".dx-data-row"
                    onItemClick={onItemClick}
                />
            )}

            {/* top left tool bar */}
            {/*<div className={allowModify ? "data-grid-toolbar" : "data-grid-toolbar grid-toolbar"}>*/}
            {/*    {*/}
            {/*        toolbarModules?.map((component, i) => (*/}
            {/*            <div key={i}>{component}</div>*/}
            {/*        ))*/}
            {/*    }*/}
            {/*</div>*/}

            <DataGrid
                width={gridWidth}
                height={gridHeight}
                onContextMenuPreparing={prepareContextMenu}
                dataSource={dataSource}
                onRowClick={onRowClick}
                onRowDblClick={onRowDoubleClicked}
                onSelectionChanged={onSelectionChanged}
                remoteOperations={isRemote}
                onRowRemoving={onRowRemove}
                onRowInserting={onRowInsert}
                onRowUpdating={onRowUpdate}
                ref={gridRef}
                columnAutoWidth={
                    columnAutoWidth == undefined ? true : columnAutoWidth
                }
                keyExpr={keyExpr}
                hoverStateEnabled={true}
                allowColumnResizing={allowColumnResizing}
            >
                {props.isPaging === false && <Scrolling mode="virtual" />}
                {isPaging && (
                    <Pager
                        visible={true}
                        showPageSizeSelector={true}
                        infoText="{0} of {1} ({2} items)"
                        showInfo={true}
                        showNavigationButtons={true}
                    />
                )}
                {isPaging && <Paging defaultPageSize={defaultPageSize} />}
                {headers &&
                    headers.map((header) => {
                        return (
                            <Column
                                allowEditing={header.allowEditing}
                                cellTemplate={header.cellTemplate}
                                fixed={header.isFixed}
                                key={header.dataField}
                                dataField={header.dataField}
                                caption={header.caption}
                                dataType={header.dataType}
                                width={header.width}
                                allowReordering={header.allowReordering}
                                alignment={header.alignment}
                                visible={
                                    header?.visible == undefined
                                        ? true
                                        : header?.visible
                                }
                                cellRender={
                                    header.cellRender != null
                                        ? header.cellRender
                                        : null
                                    // e => cellRender(header.isBadge, e.value, header.captionForBadge, header.classNameForBadge)
                                }
                            />
                        );
                    })}

                {selectMode && (
                    <Selection
                        allowSelectAll={selectMode == 'single' ? false : true}
                        selectAllMode={'page'}
                        //mode={selectMode}
                        mode="multiple"
                        showCheckBoxesMode={showCheckBox ? 'always' : 'none'}
                    />
                )}

                <Editing mode="row" confirmDelete={false} />
                <Toolbar>
                    {toolbarModules?.map((component, i) => (
                        <Item location="before" key={i}>
                            <div key={i} className="module">
                                {component}
                            </div>
                        </Item>
                    ))}
                    {allowModify && showDelete && (
                        <Item location="after">
                            <Button
                                id="dx-toolbar-remove-btn"
                                icon="trash"
                                className="btn-s-r btn-hover-red"
                                disabled={!(!isDelete && allowDelete)}
                                onClick={() => {
                                    onDelete();
                                }}
                                type="normal"
                                stylingMode="text"
                            />
                            <BtnTooltip
                                target="#dx-toolbar-remove-btn"
                                text={translation.delete}
                            />
                        </Item>
                    )}

                    {allowModify && showCopy && (
                        <Item location="after">
                            <Button
                                id="dx-toolbar-copy-btn"
                                // disabled={!(!isEdit && allowEdit)}
                                disabled={
                                    isDisableCopy !== undefined && cnt < 2
                                        ? isDisableCopy
                                        : !(!isEdit && allowEdit)
                                }
                                className="btn-s-r"
                                type="normal"
                                stylingMode="contained"
                                text={translation['copy'].toUpperCase()}
                                icon="m-copy"
                                onClick={() => moveToCopy(rowData)}
                            />
                            <BtnTooltip
                                target="#dx-toolbar-copy-btn"
                                text={translation.copy}
                            />
                        </Item>
                    )}

                    {allowModify && showEdit && (
                        <Item location="after">
                            <Button
                                id="dx-toolbar-edit-btn"
                                disabled={!(!isEdit && allowEdit)}
                                className="btn-s-r"
                                type="normal"
                                stylingMode="contained"
                                text={translation['edit'].toUpperCase()}
                                icon="edit"
                                width={82}
                                onClick={() => moveToEdit(rowData)}
                            />
                            <BtnTooltip
                                target="#dx-toolbar-edit-btn"
                                text={translation.edit}
                            />
                        </Item>
                    )}

                    {allowModify && showAdd && (
                        <Item location="after">
                            <Button
                                id="dx-toolbar-add-btn"
                                type={addBtnType}
                                stylingMode="contained"
                                className="btn-s-r"
                                icon="add"
                                text={addBtnText.toUpperCase()}
                                width={addBtnSize}
                                onClick={() => moveToAdd()}
                                disabled={!allowAdd}
                            />
                            <BtnTooltip
                                target="#dx-toolbar-add-btn"
                                text={translation.add}
                            />
                        </Item>
                    )}

                    {allowModify && showAppliedItem && (
                        <Item location="after">
                            <Button
                                id="dx-toolbar-applied-btn"
                                type="normal"
                                stylingMode="contained"
                                className="btn-s-r"
                                icon="add"
                                text={translation['applied_item'].toUpperCase()}
                                width={120}
                                onClick={() => moveToApplied(rowData)}
                                disabled={!(!isEdit && allowEdit)}
                            />
                            <BtnTooltip
                                target="#dx-toolbar-edit-btn"
                                text={translation.applied_item}
                            />
                        </Item>
                    )}
                    {allowModify && showAddDefect && (
                        <Item location="after">
                            <Button
                                id="dx-toolbar-applied-btn"
                                type={addBtnType}
                                stylingMode="contained"
                                className="btn-s-r"
                                icon="add"
                                text={addBtnText.toUpperCase()}
                                // width={120}
                                onClick={() => moveToAddDefect(rowData)}
                                // disabled={!(!isEdit && allowEdit)}
                            />
                            <BtnTooltip
                                target="#dx-toolbar-edit-btn"
                                text={translation.add}
                            />
                        </Item>
                    )}
                    {allowModify && showLoadDefectTpye && (
                        <Item location="after">
                            <Button
                                id="dx-toolbar-applied-btn"
                                type="normal"
                                stylingMode="contained"
                                className="btn-s-r"
                                icon="add"
                                text={translation[
                                    'load_defect_type'
                                ].toUpperCase()}
                                // width={120}
                                onClick={() => moveToLoadDefectTpye(rowData)}
                                // disabled={!(!isEdit && allowEdit)}
                            />
                            <BtnTooltip
                                target="#dx-toolbar-edit-btn"
                                text="불량유형"
                            />
                        </Item>
                    )}
                    {allowModify && showLoadInspection && (
                        <Item location="after">
                            <Button
                                id="dx-toolbar-applied-btn"
                                type="normal"
                                stylingMode="contained"
                                className="btn-s-r"
                                icon="add"
                                text={translation[
                                    'load_inspection_standard'
                                ].toUpperCase()}
                                // width={120}
                                onClick={() => moveToLoadInspection(rowData)}
                                // disabled={!(!isEdit && allowEdit)}
                            />
                            <BtnTooltip
                                target="#dx-toolbar-edit-btn"
                                text="검사기준라이브러리"
                            />
                        </Item>
                    )}
                    {allowModify && showAddCondition && (
                        <Item location="after">
                            <Button
                                id="dx-toolbar-applied-btn"
                                type="normal"
                                stylingMode="contained"
                                className="btn-s-r"
                                icon="add"
                                text={translation[
                                    'add_condition'
                                ].toUpperCase()}
                                onClick={() => moveToAddCondition()}
                            />
                            <BtnTooltip
                                target="#dx-toolbar-edit-btn"
                                text={translation[
                                    'add_condition'
                                ].toUpperCase()}
                            />
                        </Item>
                    )}
                </Toolbar>

                {isPaging && (
                    <Pager
                        visible={true}
                        showPageSizeSelector={true}
                        infoText="{0} of {1} ({2} items)"
                        showInfo={true}
                        showNavigationButtons={true}
                    />
                )}
                {isPaging && <Paging defaultPageSize={defaultPageSize} />}
            </DataGrid>
            {infoComment && (
                <p className="info-comment">
                    <span className="material-icons-outlined">info</span>
                    {infoComment}
                </p>
            )}
        </div>
    );
});

export default BaseDataGrid;
