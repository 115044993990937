import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import Button from 'devextreme-react/button';
import TextBox, { Button as TextButton } from 'devextreme-react/text-box';
import { Validator, RequiredRule, EmailRule, PatternRule, CustomRule } from 'devextreme-react/validator';
import { SelectBox } from 'devextreme-react/select-box';
import DateBox from 'devextreme-react/date-box';
import useTranslation from '../customHooks/translations';
import FileUploader from 'devextreme-react/file-uploader';
import { renderMatches } from 'react-router-dom';
import Swal from 'sweetalert2';
import ModalContainer from '../modal/ModalContainer';
import { useReducer } from 'react';
import { BASE_URL } from '../../config';

//Collection of fields which are frequently used in form page.

export const BaseField = forwardRef((props, ref) => {
    const { isColumn, label, isEssential, component } = props;

    return (
        <div className={isColumn ? 'dx-field column' : 'dx-field'}>
            <div className="dx-field-label">
                <p className={isEssential ? 'essential' : ''}>{label}</p>
            </div>
            <div className="dx-field-value">{component}</div>
        </div>
    );
});

export const TextboxField = forwardRef((props, ref) => {
    const {
        id,
        isColumn,
        name,
        label,
        value,
        onValueChanged = () => {},
        isEssential,
        essentialMsg,
        isPattern,
        pattern,
        patternMsg,
        validationRules,
        onEnterKey = (e) => {
            e.event.preventDefault();
        },
        width,
        height,
        readOnly,
        inputAttr,
        isFilter,
        onKeyDown = () => {},
        valueChangeEvent = 'keyup',
        showClearButton = false,
        hasTextboxPopup,
        onSearch,
        children,
        size = 'r',
        placeHolder = '',
    } = props;

    const [text, setText] = useState('');
    const textRef = useRef();
    const getInstance = () => {
        return textRef.current.instance;
    };

    useImperativeHandle(ref, () => ({
        getInstance: getInstance,
    }));

    const valueChanged = (e) => {
        onValueChanged(e);
    };

    const search = (e) => {
        onSearch(value, e);
    };

    const textboxSize = { xs: 'ix-tfs-xs', s: 'ix-tfs-s', r: 'ix-tfs-r' };

    return (
        <div className={isColumn ? 'dx-field column' : 'dx-field'}>
            <div className="dx-field-label">
                <p className={isEssential ? 'essential' : ''}>{label}</p>
            </div>
            <div className={isFilter ? 'dx-field-value filter' : 'dx-field-value'}>
                {value != null ? (
                    <TextBox
                        className={textboxSize[size]}
                        ref={textRef}
                        id={id}
                        name={name}
                        width={width}
                        height={height}
                        value={value}
                        onValueChanged={(e) => valueChanged(e)}
                        onEnterKey={onEnterKey}
                        readOnly={readOnly}
                        inputAttr={inputAttr}
                        onKeyDown={onKeyDown}
                        valueChangeEvent={valueChangeEvent}
                        applyValueMode="instantly"
                        showClearButton={showClearButton}
                        placeholder={placeHolder}
                    >
                        <Validator>
                            {isEssential && <RequiredRule message={essentialMsg} />}
                            {isPattern && <PatternRule pattern={pattern} message={patternMsg} />}
                            {validationRules}
                            {children}
                        </Validator>
                        {hasTextboxPopup && <div className="clickable-textbox" onClick={(e) => search(e)}></div>}
                    </TextBox>
                ) : (
                    <TextBox
                        className={textboxSize[size]}
                        ref={textRef}
                        id={id}
                        name={name}
                        width={width}
                        height={height}
                        onValueChanged={(e) => valueChanged(e)}
                        onEnterKey={onEnterKey}
                        readOnly={readOnly}
                        inputAttr={inputAttr}
                        onKeyDown={onKeyDown}
                        valueChangeEvent={valueChangeEvent}
                        applyValueMode="instantly"
                        showClearButton={showClearButton}
                        placeholder={placeHolder}
                    >
                        <Validator>
                            {isEssential && <RequiredRule message={essentialMsg} />}
                            {isPattern && <PatternRule pattern={pattern} message={patternMsg} />}
                            {validationRules}
                            {children}
                        </Validator>
                        {hasTextboxPopup && <div className="clickable-textbox" onClick={(e) => search(e)}></div>}
                    </TextBox>
                )}
            </div>
        </div>
    );
});

//this component will invoke onFilter function when enter key is pressed or text value is changed.
export const FilterComponent = forwardRef((props, ref) => {
    const { label, width, height, onFilter = () => {}, isColumn, showClearButton = true, value } = props;
    const getInstance = () => {
        return textRef.current?.getInstance();
    };
    const textRef = useRef();
    let invoking = false;

    useImperativeHandle(ref, () => ({
        getText: getText,
        getInstance: getInstance,
    }));

    const getText = () => {
        const instance = getInstance();
        return instance.option('text');
    };

    const onTextChanged = (e) => {
        onFilter(e.value);
    };

    const onEnterKey = (e) => {
        e.event.preventDefault();
        // const text = getText();
        // filterring(text);
    };

    const filterring = (text) => {
        onFilter(text);
    };

    return <TextboxField isColumn={isColumn} ref={textRef} label={label} width={width} height={height} value={value} onValueChanged={onTextChanged} onEnterKey={onEnterKey} isFilter={true} valueChangeEvent="keyup" showClearButton={showClearButton} />;
});

//search bar component
//this component will invoke onSearch function when user clicked button or pressed enter key.
export function SearchableField(props) {
    const translation = useTranslation();
    const {
        id,
        label,
        isWritable = true,
        isEssential,
        essentialMsg,
        isPattern,
        pattern,
        patternMsg,
        onSearch = () => {},
        width,
        validationRules,
        text: value,
        onValueChanged: valueChangedCallback = () => {},
        elementAttr,
        hasTextboxPopup,
        placeHolder = translation.search,
        isColumn = false,
        disabled = false,
        children,
        size = 'r',
    } = props;
    const ref = useRef();
    const getInstance = () => {
        return ref.current.instance;
    };
    const [text, setText] = useState('');

    const search = (e) => {
        onSearch(value, e);
    };

    const onValueChanged = (val) => {
        if (value) {
            valueChangedCallback(val);
        } else {
            setText(val);
            valueChangedCallback(val);
        }

        if (onSearch) {
            onSearch(val);
        }
    };

    const textboxSize = { xs: 'ix-tfs-xs', s: 'ix-tfs-s', r: 'ix-tfs-r' };
    const btnSize = { xs: 'btn-s-xs', s: 'btn-s-s', r: 'btn-s-r' };

    return (
        <div className={isColumn ? 'dx-field column' : 'dx-field'}>
            {label != null && (
                <div className="dx-field-label">
                    <p className={isEssential ? 'essential' : ''}>{label}</p>
                </div>
            )}
            <div className="dx-field-value">
                <div className="search">
                    {/* search bar */}
                    <TextBox id={id} className={textboxSize[size]} readOnly={!isWritable} value={value ? value : text} onValueChange={onValueChanged} onEnterKey={(e) => search(e)} width={width} ref={ref} placeholder={placeHolder} disabled={disabled}>
                        {/*<Validator>{validationRules}</Validator>*/}
                        <Validator>
                            {isEssential && <RequiredRule message={essentialMsg} />}
                            {isPattern && <PatternRule pattern={pattern} message={patternMsg} />}
                            {validationRules}
                            {children}
                        </Validator>
                        {hasTextboxPopup && <div className="clickable-textbox" onClick={(e) => search(e)}></div>}
                    </TextBox>
                    {/* search button */}
                    <Button
                        type="default"
                        stylingMode="contained"
                        icon="search"
                        className={btnSize[size] + ' dx-button-mode-es'}
                        onClick={(e) => {
                            search(e);
                        }}
                        elementAttr={elementAttr}
                    />
                </div>
            </div>
        </div>
    );
}

export function DatePickerField(props) {
    const { id, name, label, value, onValueChanged = () => {}, isEssential, essentialMsg, invalidMsg, pickerType, valueType, valueFormat, useMask, width, inputAttr = '', openOnFieldClicked = true, acceptCustomValue = false } = props;

    return (
        <div className="dx-field">
            <div className="dx-field-label">{isEssential ? <p className="essential">{label}</p> : <p>{label}</p>}</div>
            <div className="dx-field-value">
                <DateBox
                    id={id}
                    name={name}
                    className="ix-tfs-r"
                    pickerType={pickerType ? pickerType : 'calendar'}
                    type={valueType ? valueType : 'date'}
                    invalidDateMessage={invalidMsg}
                    value={value}
                    onValueChanged={(e) => {
                        if (e.value) {
                            const text = e.component.option('text');
                            onValueChanged(e, text);
                        }
                    }}
                    displayFormat={valueFormat}
                    useMaskBehavior={useMask}
                    width={width ? width : null}
                    inputAttr={inputAttr}
                    acceptCustomValue={acceptCustomValue}
                    openOnFieldClick={openOnFieldClicked}
                >
                    <Validator>{isEssential && <RequiredRule message={essentialMsg} />}</Validator>
                </DateBox>
            </div>
        </div>
    );
}

export function DateRangeField(props) {
    const { label = 'Date range', startValue, endValue, setStartValue, setEndValue, onValueChanged = () => {}, isEssential, essentialMsg, pickerType, valueFormat, useMask, width, inputAttr = '', openOnFieldClicked = true, acceptCustomValue = false, useApplyBtn = false, applyBtnClick = () => {} } = props;
    const [startMaxDate, setStartMaxDate] = useState(endValue);
    const [endMinDate, setEndMinDate] = useState(startMaxDate);
    const translation = useTranslation();
    const onDateChanged = (e) => {
        const dateType = e.element.id;
        const dateMaskVal = e.component._maskValue;
        if (dateType === 'start') {
            setEndMinDate(dateMaskVal);
            setStartValue(dateMaskVal);
        } else if (dateType === 'end') {
            setStartMaxDate(dateMaskVal);
            setEndValue(dateMaskVal);
        }
        //onValueChanged(e);
    };
    return (
        <div className="dx-field date-field">
            <div className="dx-field-label">{isEssential ? <p className="essential">{label}</p> : <p>{label}</p>}</div>
            <div className="dx-field-value">
                <DateBox
                    className="ix-tfs-r"
                    id={'start'}
                    value={startValue}
                    onValueChanged={onDateChanged}
                    pickerType={pickerType ? pickerType : 'calendar'}
                    displayFormat={valueFormat}
                    useMaskBehavior={useMask}
                    width={width ? width : null}
                    inputAttr={inputAttr}
                    acceptCustomValue={acceptCustomValue}
                    openOnFieldClick={openOnFieldClicked}
                    max={startMaxDate}
                >
                    <Validator>{isEssential && <RequiredRule message={essentialMsg} />}</Validator>
                </DateBox>
                <span>-</span>
                <DateBox
                    id={'end'}
                    className="ix-tfs-r"
                    value={endValue}
                    onValueChanged={onDateChanged}
                    pickerType={pickerType ? pickerType : 'calendar'}
                    displayFormat={valueFormat}
                    useMaskBehavior={useMask}
                    width={width ? width : null}
                    inputAttr={inputAttr}
                    acceptCustomValue={acceptCustomValue}
                    openOnFieldClick={openOnFieldClicked}
                    min={endMinDate}
                >
                    <Validator>{isEssential && <RequiredRule message={essentialMsg} />}</Validator>
                </DateBox>
            </div>
            <div
                style={{
                    marginLeft: '8px',
                    display: useApplyBtn ? '' : 'none',
                }}
            >
                <Button type="default" className="btn-s-r" width={20} text={translation.apply} onClick={applyBtnClick} />
            </div>
        </div>
    );
}

/*
    return type: [{
        data: will be used at src attribute
        file: File type value
        name: name of image
    }, ...]
*/
//this componenet support upload images and show preview of them
//above type comment is data structure of this component give as paramerter to setImageData
//currently setImageData is supposed setState function. this will change in future.
export function FileField(props) {
    const { label, imageData, setImageData, width = 640 } = props;

    const [isDropZoneActive, setIsDropZoneActive] = useState(false);
    const [isEmpty, setIsEmpty] = useState(true);
    const translation = useTranslation();
    const fileExtensions = ['.jpg', '.jepg', '.png'];
    const uploaderRef = useRef();
    const getInstance = () => {
        return uploaderRef.current?.instance;
    };

    const onDropZoneEnter = (e) => {
        setIsDropZoneActive(true);
    };

    const onDropZoneLeave = (e) => {
        setIsDropZoneActive(false);
    };

    useEffect(() => {
        if (imageData && imageData.length > 0) {
            setIsEmpty(false);
        } else {
            setIsEmpty(true);
        }
    }, [imageData]);

    const onFileChanged = (e) => {
        let inputs = e.value;
        let unique = [];

        for (let i = 0; i < inputs.length; i++) {
            let isUnique = true;
            for (let j = 0; j < imageData.length; j++) {
                if (inputs[i].name === imageData[j].name) {
                    isUnique = false;
                    break;
                }
            }

            if (isUnique) {
                unique.push(inputs[i]);
            }
        }

        let result = [];
        for (let i = 0; i < unique.length; i++) {
            let reader = new FileReader();
            let img = {
                data: null,
                name: unique[i].name,
                file: unique[i],
            };

            reader.onload = () => {
                setImageData((cur) => {
                    let imgList = [...cur];

                    const targetIndex = imgList.findIndex((element) => element.name == unique[i].name);
                    if (targetIndex >= 0) {
                        let img = { ...imgList[targetIndex] };
                        img.data = reader.result;
                        imgList.splice(targetIndex, 1, img);
                    }

                    return imgList;
                });
            };

            result.push(img);
            reader.readAsDataURL(unique[i]);
        }

        setImageData((cur) => {
            return [...cur, ...result];
        });
    };

    //modal popup event start
    const [isOpen, setIsOpen] = useState(false);
    const [clickedIndex, setClickedIndex] = useState('');

    //show preview of image with full size
    const showImage = (e, index) => {
        const len = imageData.length;
        console.log(e.target);
        if (e.target.classList.contains('dx-button') == false && e.target.classList.contains('dx-icon') == false && e.target.classList.contains('dx-button-content') == false) setIsOpen(true);
        if (len > index && index >= 0) {
            setClickedIndex(index);
        } else if (index < 0) {
            setClickedIndex(len - 1);
        } else if (index > len - 1) {
            setClickedIndex(0);
        }
    };

    const closeModal = (e) => {
        if (e.target.classList.contains('modal-container')) {
            setIsOpen(false);
        }
    };
    //modal popup event end

    //remove image when trash button on image is clicked.
    const removeImage = (event, value, index) => {
        let copy = [...imageData];
        copy.splice(index, 1);
        setImageData(copy);
        const instance = getInstance();
        instance.removeFile(index);
    };

    const Thumbnail = () => {
        return (
            <>
                {imageData.map((img, index) => {
                    return (
                        <div key={index} className="fileuploader-image-box" onClick={(e) => showImage(e, index)}>
                            <img className="fileuploader-image" alt={img.name} src={img.data} />
                            <Button id="dx-remove-btn" icon="trash" className="btn-s-r btn-hover-red" type="normal" stylingMode="text" onClick={(e) => removeImage(e, img, index)} />
                        </div>
                    );
                })}
                {isOpen && (
                    <ModalContainer>
                        <div className="modal-container" onClick={closeModal}>
                            <div className="btn-modal-close" onClick={(e) => setIsOpen(false)}></div>
                            <div className="btn-prev-img" onClick={(e) => showImage(e, clickedIndex - 1)}></div>
                            <div className="btn-next-img" onClick={(e) => showImage(e, clickedIndex + 1)}></div>
                            <img src={imageData[clickedIndex].data} />
                        </div>
                    </ModalContainer>
                )}
            </>
        );
    };

    return (
        <div className="dx-field widget-container column file-field" style={{ maxWidth: `${width}px` }}>
            <div className="dx-field-label">
                <p>{label}</p>
            </div>
            {/*<div className="dx-field-value widget-container flex-box fileuploader-container">*/}
            <div className="dx-field-value widget-container flex-box ">
                {!isEmpty ? <Thumbnail /> : null}
                <div id="dropzone-external" className={`dropzone ${isDropZoneActive ? '//dx-theme-accent-as-border-color dropzone-active' : 'dx-theme-border-color'}`} style={{ width: '184px' }}>
                    <i className="dx-icon-m-cam"></i>
                    <div id="dropzone-text" className="dropzone-text">
                        <span>{translation.multipleLogoDesc}</span>
                    </div>
                </div>
                <FileUploader
                    id="fileUploader"
                    ref={uploaderRef}
                    allowedFileExtensions={fileExtensions}
                    uploadMode="instantly"
                    dialogTrigger="#dropzone-external"
                    dropZone="#dropzone-external"
                    onDropZoneEnter={onDropZoneEnter}
                    onDropZoneLeave={onDropZoneLeave}
                    selectButtonText={translation.logoDesc}
                    onValueChanged={onFileChanged}
                    multiple={true}
                    visible={false}
                ></FileUploader>
            </div>
            <div className="slide-container" style={{ position: 'absolute' }}></div>
        </div>
    );
}

export function SingleFileField(props) {
    const { label, imageData, setImageData, id, className = '', isColumn } = props;

    const [isModalOpen, setModalOpen] = useState(false);
    const [isDropZoneActive, setIsDropZoneActive] = useState(false);
    const [isEmpty, setIsEmpty] = useState(true);
    const translation = useTranslation();
    const fileExtensions = ['.jpg', '.jepg', '.png'];
    const uploaderRef = useRef();
    const getInstance = () => {
        return uploaderRef.current?.instance;
    };

    const onDropZoneEnter = (e) => {
        setIsDropZoneActive(true);
    };

    const onDropZoneLeave = (e) => {
        setIsDropZoneActive(false);
    };

    useEffect(() => {
        if (imageData && imageData.length > 0) {
            setIsEmpty(false);
        } else {
            setIsEmpty(true);
        }
    }, [imageData]);

    const onFileChanged = (e) => {
        let inputs = e.value;
        let result = [];

        for (let i = 0; i < inputs.length; i++) {
            let reader = new FileReader();
            let img = {
                data: null,
                name: inputs[i].name,
                file: inputs[i],
            };

            reader.onload = () => {
                setImageData((cur) => {
                    let imgList = [...cur];

                    const targetIndex = imgList.findIndex((element) => element.name == inputs[i].name);
                    if (targetIndex >= 0) {
                        let img = { ...imgList[targetIndex] };
                        img.data = reader.result;
                        imgList.splice(targetIndex, 1, img);
                    }

                    return imgList;
                });
            };

            result.push(img);
            reader.readAsDataURL(inputs[i]);
        }

        setImageData(result);
    };

    //modal popup event start
    const [isOpen, setIsOpen] = useState(false);
    const [clickedIndex, setClickedIndex] = useState('');

    //show preview of image with full size
    const showImage = (e, index) => {
        const len = imageData.length;
        console.log(e.target);
        if (e.target.classList.contains('dx-button') == false && e.target.classList.contains('dx-icon') == false && e.target.classList.contains('dx-button-content') == false) setIsOpen(true);

        if (len > index && index >= 0) {
            setClickedIndex(index);
        } else if (index < 0) {
            setClickedIndex(len - 1);
        } else if (index > len - 1) {
            setClickedIndex(0);
        }
    };

    const closeModal = (e) => {
        if (e.target.classList.contains('modal-container')) {
            setIsOpen(false);
        }
    };
    //modal popup event end

    //remove image when trash button on image is clicked.
    const removeImage = (event, value, index) => {
        let copy = [...imageData];
        copy.splice(index, 1);
        setImageData(copy);
        const instance = getInstance();
        instance.removeFile(index);
    };

    const Thumbnail = () => {
        return (
            <>
                {imageData.map((img, index) => {
                    return (
                        <div
                            key={index}
                            className="fileuploader-image-box fileuploader-single-image-box"
                            id={`img-${index}`}
                            onClick={(e) => showImage(e, index)}
                            style={{
                                position: 'absolute',
                                left: 0,
                                top: 0,
                                borderColor: 'transparent',
                            }}
                        >
                            <img className="fileuploader-image" alt={img.name} src={`${img.data}`} key={index} />
                            <Button id="dx-remove-btn" icon="trash" className="btn-s-r btn-hover-red" type="normal" stylingMode="text" onClick={(e) => removeImage(e, img, index)} style={{ zIndex: 2 }} />
                        </div>
                    );
                })}
                {isOpen && (
                    <ModalContainer>
                        <div className="modal-container" onClick={closeModal}>
                            <div className="btn-modal-close" onClick={(e) => setIsOpen(false)}></div>
                            <div className="btn-prev-img" onClick={(e) => showImage(e, clickedIndex - 1)}></div>
                            <div className="btn-next-img" onClick={(e) => showImage(e, clickedIndex + 1)}></div>
                            <img src={imageData[clickedIndex].data} />
                        </div>
                    </ModalContainer>
                )}
            </>
        );
    };

    return (
        <div className={`dx-field widget-container ${className} ${isColumn && 'column'}`}>
            <div className="dx-field-label">
                <p>{label}</p>
            </div>
            <div className="dx-field-value widget-container flex-box" style={{ position: 'relative' }}>
                {/*<div id={`dropzone-external-${id}`}>*/}
                {/*</div>*/}
                {/* {!isEmpty ? (
                    <Thumbnail />
                ) : ( */}
                <Thumbnail />
                <div id={`dropzone-internal-${id}`} className={`dropzone ${isDropZoneActive ? '//dx-theme-accent-as-border-color dropzone-active' : 'dx-theme-border-color'}`} style={{ position: 'relative', background: 'transparent' }}>
                    {isEmpty && (
                        <>
                            <i className="dx-icon-m-cam"></i>
                            <div id="dropzone-text" className="dropzone-text">
                                <span>{translation.multipleLogoDesc}</span>
                            </div>
                        </>
                    )}
                    {/* <i className="dx-icon-m-cam"></i>
                    <div id="dropzone-text" className="dropzone-text">
                        <span>{translation.multipleLogoDesc}</span>
                    </div> */}
                </div>
                {/* )} */}
                <FileUploader
                    id={`fileUploader-${id}`}
                    ref={uploaderRef}
                    allowedFileExtensions={fileExtensions}
                    uploadMode="useButtons"
                    accept="image/*"
                    dialogTrigger={`#dropzone-internal-${id}`}
                    dropZone={`#dropzone-internal-${id}`}
                    onDropZoneEnter={onDropZoneEnter}
                    onDropZoneLeave={onDropZoneLeave}
                    selectButtonText={translation.logoDesc}
                    onValueChanged={onFileChanged}
                    multiple={false}
                    visible={false}
                ></FileUploader>
            </div>
            <div className="slide-container" style={{ position: 'absolute' }}></div>
        </div>
    );
}

//unlike other components required rule is not applied when isEssential is true
//devexpress' required rule does not allow false, undefined, null value but we use false value very often
export function DropdownField(props) {
    const {
        label,
        value: outterValue,
        useLocalValue = true,
        onValueChanged: valueChangedCallback = () => {},
        onSelectionChanged = () => {},
        onItemClick = () => {},
        dataSource,
        valueExpr,
        displayExpr,
        isSearchable = true, //allow to search dropdown items
        width,
        isEssential,
        validationRules,
        disabled,
        isColumn,
        essentialMsg,
        onEnterKey,
        inputAttr = '',
        onKeyUp,
        onFocusOut,
        itemRender,
        size = 'r',
    } = props;

    const textboxSize = { xs: 'ix-tfs-xs', s: 'ix-tfs-s', r: 'ix-tfs-r' };

    const [localValue, setLocalValue] = useState();

    const onValueChanged = (e) => {
        valueChangedCallback(e);
        setLocalValue(e.value);
    };

    const selectionChange = (e) => {
        onSelectionChanged(e);
    };

    const customValidation = (option) => {
        if (option.value !== undefined && option.value !== null) {
            return true;
        } else {
            return false;
        }
    };

    // const value = outterValue == undefined ? localValue : outterValue;
    const value = useLocalValue ? (outterValue == undefined ? localValue : outterValue) : outterValue;

    const requiredRule = <CustomRule type={'custom'} validationCallback={customValidation} message={essentialMsg} />;

    return (
        <div className={isColumn ? 'dx-field column' : 'dx-field'}>
            <div className="dx-field-label">
                <p className={isEssential ? 'essential' : ''}>{label}</p>
            </div>
            <div className="dx-field-value">
                <SelectBox
                    className={textboxSize[size]}
                    value={value}
                    onValueChanged={(e) => onValueChanged(e)}
                    onSelectionChanged={(e) => selectionChange(e)}
                    onItemClick={(e) => onItemClick(e)}
                    dataSource={dataSource}
                    valueExpr={valueExpr}
                    displayExpr={displayExpr}
                    searchEnabled={isSearchable}
                    searchMode="contains"
                    width={width}
                    disabled={disabled}
                    onEnterKey={onEnterKey}
                    inputAttr={inputAttr}
                    onKeyUp={onKeyUp}
                    onFocusOut={onFocusOut}
                    itemRender={itemRender}
                >
                    <Validator>
                        {isEssential && requiredRule}
                        {validationRules}
                    </Validator>
                </SelectBox>
            </div>
        </div>
    );
}
