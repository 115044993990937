import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import axios from 'axios';
import { setUserSession } from '../../apiInterface/utils/Common';
import { signin } from "../../apiInterface/account/LoginAPI";

import "../../contents/styles/account/input-company-info.css";
import Button from 'devextreme-react/button';
import TextBox from 'devextreme-react/text-box';
import ValidationSummary from 'devextreme-react/validation-summary';
import {
    Validator,
    RequiredRule,
    CompareRule,
    EmailRule,
    PatternRule,
    StringLengthRule,
    RangeRule,
    AsyncRule,
} from 'devextreme-react/validator';
import { ValidationGroup } from 'devextreme-react/validation-group';
import useTranslation from "../../components/customHooks/translations";


const InputPersonalInfo = (props) => {
    const translation = useTranslation();
    const navigate = useNavigate();
    const onFormSubmit = (e) => { }
    const onFormSubmit2 = (e) => { }

    function openDaumPostcode() {
        const daum = window.daum;
        new daum.Postcode({
            oncomplete: function (data) {

                var roadAddr = data.roadAddress; // 도로명 주소 변수
                var extraRoadAddr = ''; // 참고 항목 변수

                if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
                    extraRoadAddr += data.bname;
                }
                if (data.buildingName !== '' && data.apartment === 'Y') {
                    extraRoadAddr += (extraRoadAddr !== '' ? ', ' + data.buildingName : data.buildingName);
                }
                if (extraRoadAddr !== '') {
                    extraRoadAddr = ' (' + extraRoadAddr + ')';
                }

                document.querySelector('#sample4_postcode input').value = data.zonecode;
                document.querySelector("#sample4_roadAddress input").value = roadAddr + extraRoadAddr;
            }
        }).open({ autoClose: true })
    }


    //button event (card-footer)
    function goNext() {
        document.querySelector('.current-num').innerText = 2;
        document.querySelector('.dx-validationgroup:first-child').style.display = "none";
        document.querySelector('.dx-validationgroup:last-child').style.display = "block";
    }
    function goPrev() {
        document.querySelector('.current-num').innerText = 1;
        document.querySelector('.dx-validationgroup:first-child').style.display = "block";
        document.querySelector('.dx-validationgroup:last-child').style.display = "none";
    }

    return (
        <div className="dx-swatch-ix-pds input-account-info personal-account">
            <div className="content">
                <h1 className="logo-interx" onClick={() => { navigate("../login") }}></h1>
                <div className="card prev-card">
                    <div className="card-header">
                        <h3 className="card-title"><span>{translation.indivisual}</span><br />{translation.account}</h3>
                        <div className="page-number"><span className="current-num">1</span>/<span className="total-num">2</span></div>
                    </div>
                    <form onSubmit={onFormSubmit}>
                        <ValidationGroup>
                            <div className="dx-fieldset">
                                <div className="dx-field">
                                    <div className="dx-field-label"><p className="essential">{translation.email}</p></div>
                                    <div className="dx-field-value">
                                        <TextBox className="ix-tfs-r" name="user_master_email">
                                            <Validator>
                                                <RequiredRule message={translation.email + translation.is_required} />
                                                <EmailRule message={translation.email + translation.is_invalid} />
                                                <AsyncRule
                                                    message={translation.MSG_101}
                                                    validationCallback={asyncValidation} />
                                            </Validator>
                                        </TextBox>
                                    </div>
                                </div>
                                <div className="dx-field">
                                    <div className="dx-field-label"><p className="essential">{translation.name}</p></div>
                                    <div className="dx-field-value">
                                        <TextBox className="ix-tfs-r" name="user_master_name">
                                            <Validator>
                                                <RequiredRule message={translation.name + translation.is_required} />
                                            </Validator>
                                        </TextBox>
                                    </div>
                                </div>
                                <div className="dx-field">
                                    <div className="dx-field-label"><p className="essential">{translation.contactNo}</p></div>
                                    <div className="dx-field-value">
                                        <TextBox className="ix-tfs-r" name="user_master_phone">
                                            <Validator>
                                                <RequiredRule message={translation.contactNo + translation.is_required} />
                                                <PatternRule message={translation.contactNo + translation.is_invalid} pattern='/^[02-9]\d{9}$/' />
                                            </Validator>
                                        </TextBox>
                                    </div>
                                </div>
                                <div className="btn-group">
                                    <Button
                                        width="76px"
                                        text="PREV"
                                        type="normal"
                                        stylingMode="text"
                                        className="btn-s-r"
                                        onClick={() => { navigate("../SelectUserType") }}
                                    />
                                    <Button
                                        width="76px"
                                        text="Next"
                                        type="default"
                                        stylingMode="contained"
                                        className="btn-s-r"
                                        useSubmitBehavior={true}
                                    />
                                </div>
                            </div>
                        </ValidationGroup>
                        <ValidationGroup>
                            <div className="dx-fieldset">
                                <div className="dx-field">
                                    <div className="dx-field-label">{translation.name}</div>
                                    <div className="dx-field-value">
                                        <TextBox className="ix-tfs-r" disabled={true} defaultValue="name" name="user_master_name">
                                        </TextBox>
                                    </div>
                                </div>
                                <div className="dx-field">
                                    <div className="dx-field-label">{translation.address}</div>
                                    <div className="dx-field-value">
                                        <div className="search">
                                            <TextBox className="ix-tfs-r" id="sample4_postcode" name="user_master_zipcode" />
                                            <Button
                                                icon="search"
                                                type="default"
                                                stylingMode="contained"
                                                className="btn-s-r dx-button-mode-es btn-only-icon"
                                                onClick={openDaumPostcode}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="dx-field">
                                    <div className="dx-field-label"></div>
                                    <div className="dx-field-value">
                                        <TextBox className="ix-tfs-r" id="sample4_roadAddress" name="user_master_address" />
                                    </div>
                                </div>
                                <div className="dx-field">
                                    <div className="dx-field-label">{translation.address2}</div>
                                    <div className="dx-field-value">
                                        <TextBox className="ix-tfs-r" id="sample4_detailAddress" name="user_master_address2">
                                        </TextBox>
                                    </div>
                                </div>
                                <div className="btn-group">
                                    <Button
                                        text="PREV"
                                        type="normal"
                                        stylingMode="text"
                                        className="btn-s-r"
                                        onClick={goPrev}
                                    />
                                    <Button
                                        text="FINISH"
                                        type="default"
                                        stylingMode="contained"
                                        className="btn-s-r"
                                        useSubmitBehavior={true}
                                    />
                                </div>
                            </div>
                        </ValidationGroup>
                    </form>
                </div>
                <div className="copywrite">
                    <p>© INTERX</p>
                </div>
            </div>
        </div>
    );
}

function sendRequest(value) {
    const validEmail = 'test@dx-email.com';
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(value === validEmail);
        }, 1000);
    });
}

function asyncValidation(params) {

    alert("TesT");
    return sendRequest(params.value);
}
export default InputPersonalInfo;