export const loading = (key, state) => {
    // key�� another �� ��� 
    if (key.split("[").length > 1) {
        if (state === "on") {
            document.querySelector(key)?.classList.add('loader');
        } else {
            document.querySelector(key)?.classList.remove('loader');
        }
    }
    // key�� className �� ��� 
    else if (key.split(".").length > 1) {
        if (state == "on") {
            document.getElementsByClassName(key.split(".")[1])[0].classList.add("loader");
        }
        else {
            document.getElementsByClassName(key.split(".")[1])[0].classList.remove("loader");
        }
    }
    // key�� id �� ��� 
    else if (key.split("#").length > 1) {
        if (state == "on") {
            document.getElementById(key.split("#")[1]).classList.add("loader");
        }
        else {
            document.getElementById(key.split("#")[1]).classList.remove("loader");
        }
    }

}
