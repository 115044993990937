import React, { useEffect, useState, useRef } from 'react';
import CustomStore from 'devextreme/data/custom_store';
import { useNavigate } from 'react-router-dom';
import '../../contents/styles/home/no-page.css';
import { PageTitle } from '../../components/common/PageTitle.jsx';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import Button from 'devextreme-react/button';
import { getUser, getIP, getLanguage, getMenuMappingInfo } from '../../apiInterface/utils/Common';
import BaseDataGrid from '../../components/common/BaseDataGrid';
import { SearchWithTag } from '../../components/common/SearchWithTag';
import { DateRangeField, DropdownField, FilterComponent } from '../../components/common/FormField';
import { getAbnormalLogs } from '../../apiInterface/dataManagement/AbnormalLogsAPI';
import UpDown from '../../components/common/UpDown';
import { getSysDivisionCaptionByCode } from '../../apiInterface/menu/MenuAPI';
import useTranslation from '../../components/customHooks/translations';

let setting_type = '';
const AbnormalSignLog = () => {
    const translation = useTranslation();
    const [customDataSource, setCustomDataSource] = useState([]);
    const [abnormalTypeList, setAbnormalTypeList] = useState();
    const formatDate = (date) => {
        const res = date.toISOString().split('T')[0];
        return res;
    };
    const navigate = useNavigate();
    const today = new Date();
    const [startDate, setStartDate] = useState(formatDate(today));
    const [endDate, setEndDate] = useState(formatDate(today));
    const [curAbnormalType, setCurAbnormalType] = useState();
    const errorHandler = useErrorHandler();
    const user = getUser();
    const lang = getLanguage();
    const upDownClass = { high: 'dx-icon-m-arrow-increase', low: 'dx-icon-m-arrow-decrease' };
    const [searchText, setSearchTest] = useState('');
    //
    const [curStatus, setCurStatus] = useState();
    const statusList = [
        {
            sys_division_sub_caption_text: translation.all,
            sys_division_sub_value: 'all',
            sys_division_sub_seq: 1,
            sys_division_sub_default_check: 'Y',
        },

        {
            sys_division_sub_caption_text: 'Orange',
            sys_division_sub_value: 1,
            sys_division_sub_seq: 2,
            sys_division_sub_default_check: 'Y',
        },
        {
            sys_division_sub_caption_text: 'Red',
            sys_division_sub_value: 2,
            sys_division_sub_seq: 3,
            sys_division_sub_default_check: 'Y',
        },
        {
            sys_division_sub_caption_text: 'Green',
            sys_division_sub_value: 0,
            sys_division_sub_seq: 4,
            sys_division_sub_default_check: 'Y',
        }
    ];

    const header = [
        { dataField: 'No', caption: translation.abnormal_sign_log_no, dataType: 'string', isRequired: true, allowReordering: true },
        // { dataField: 'settingType', caption: translation.abnormal_setting_type, dataType: 'string', alignment: 'center', allowReordering: true, isFixed: true },
        { dataField: 'dataSet', caption: translation.dataset_name, dataType: 'string', isRequired: true, allowReordering: true },
        { dataField: 'idShort', caption: translation.id_short, dataType: 'string', allowReordering: true },
        { dataField: 'factoryName', caption: translation.abnormal_setting_factory.toUpperCase(), dataType: 'string' },
        { dataField: 'lineName', caption: translation.abnormal_setting_line.toUpperCase(), dataType: 'string' },
        { dataField: 'processName', caption: translation.abnormal_setting_process.toUpperCase(), dataType: 'string'},
        { dataField: 'equipName', caption: translation.abnormal_setting_machine.toUpperCase(), dataType: 'string' },
        { dataField: 'value', caption: 'Value', dataType: 'string', allowReordering: true },
        { dataField: 'usl', caption: 'USL', dataType: 'string', allowReordering: true },
        { dataField: 'lsl', caption: 'LSL', dataType: 'string', allowReordering: true },
        { dataField: 'ucl', caption: 'UCL', dataType: 'string', allowReordering: true },
        { dataField: 'lcl', caption: 'LCL', dataType: 'string', allowReordering: true },
        {
            dataField: 'state',
            caption: translation.abnormal_sign_log_status,
            dataType: 'string',
            isEdit: false,
            width: 80,
            allowReordering: true,
            cellRender: (e) => {
                // let status = e.value == 1 ? 'warning' : e.value == 2 ? 'ng' : 'ok';
                let status = e.value == 1 ? '#ffb12b' : e.value == 2 ? '#ff1900' : '#13C690';
                return (
                    <div>
                        {/* <span className={`lamp ${status}`}></span> */}
                        <span className={`lamp ${status}`} style={{ display: 'block', width: '20px', height: '20px', borderRadius: '50%', background: status }}></span>
                    </div>
                );
                // <UpDown className={upDownClass[e.value]} text={e.value} />;
            },
        },
        //{ dataField: 'reason', caption: translation.abnormal_sign_log_reason, dataType: 'string', isEdit: false, width: 150, alignment: 'center', allowReordering: true },
        //{ dataField: 'recommend', caption: translation.abnormal_sign_log_recommend, dataType: 'string', isEdit: false, width: 150, alignment: 'center', allowReordering: true },
        { dataField: 'timeStamp', caption: translation.abnormal_sign_log_created_date.toUpperCase(), dataType: 'string', isEdit: false, width: 250, alignment: 'center', allowReordering: true },
        {
            dataField: 'isCheck',
            caption: translation.abnormal_log,
            dataType: 'string',
            isEdit: false,
            width: 100,
            alignment: 'center',
            allowReordering: true,
            cellRender: (e) => {
                return (
                    <div>
                        <span>{e.value ? translation.log_action : translation.log_abnormal}</span>
                    </div>
                );
            },
        },
        { dataField: 'checkedDateTime', caption: translation.checkedDateTime, dataType: 'string', isEdit: false, width: 250, alignment: 'center', allowReordering: true },
    ];
    const getAbnormalInfo = async () => {
        const customStore = new CustomStore({
            load: async (loadOptions) => {
                const user = getUser();

                let skip = 0;
                let take = 10;
                if (loadOptions['skip']) skip = loadOptions['skip'];
                if (loadOptions['take']) take = loadOptions['take'];
                const pageNo = parseInt(skip / take) + 1;
                const param = {
                    company_code: user.user_company_code,
                    start_date: startDate,
                    end_date: endDate,
                    row_count: take,
                    page_no: pageNo,
                    // setting_type: curAbnormalType?.sys_division_sub_value,
                    state: curStatus === 'all' ? null : curStatus,
                    search_text: searchText,
                };
                const res = await errorHandler(getAbnormalLogs, param);
                if (res) {
                    const abnormalLogsData = res.data.o_data;
                    const pagingDict = {
                        data: abnormalLogsData,
                        totalCount: res.data.count,
                    };
                    return pagingDict;
                }

                return { data: [], totalCount: 0 };
            },
            //remove: async (key) => {
            //    deleteBadTypeInfo(key);
            //}
        });
        //setCustomStoreObj(customStore);
        setCustomDataSource(customStore);
    };
    const getDivisionCaption = async () => {
        const params = {
            user_company_id: user.user_company_id,
            sys_division_code: 'ABNORMALTYPE',
            sys_division_sub_lang_code: lang,
        };
        const res = await errorHandler(getSysDivisionCaptionByCode, params);
        if (res !== 'fail' && res.message === 'MSG_100') {
            const typeList = res.data.o_data;
            setAbnormalTypeList(typeList);
            setCurAbnormalType(typeList[0]);
        }
    };

    useEffect(() => {
        // setSearchTest('');
        setting_type = '';
        getAbnormalInfo();
    }, [curAbnormalType, startDate, endDate, searchText, curStatus]);

    useEffect(() => {
        getDivisionCaption();

        // selected default status dropdown
        setCurStatus(statusList?.[0]?.sys_division_sub_value);
    }, []);

    const handleSubmit = (keyword) => getAbnormalInfo();
    const filtering = (keyword) => setSearchTest(keyword);

    const filter = <FilterComponent label={translation.filter} width={204} onFilter={filtering} />;
    const dateRange = <DateRangeField startValue={startDate} endValue={endDate} setStartValue={setStartDate} setEndValue={setEndDate} label={translation.date_range} />;
    const search = <SearchWithTag items={['item name', 'item code', 'equipment name', 'equipment code', 'mold name', 'mold code']} width={200} hasButton={false} />;
    const searchBtn = <Button className="btn-s-r dx-button-mode-es btn-search" icon="search" type="default" stylingMode="contained" onClick={handleSubmit} />;
    // const abnormalTypeDropdown = <DropdownField width={204} label={translation.select_setting_type} value={curAbnormalType} onValueChanged={(e) => setCurAbnormalType(e.value)} dataSource={abnormalTypeList} displayExpr="sys_division_sub_caption_text" />;
    const statusDropdown = <DropdownField width={204} label={translation.abnormal_sign_log_status} value={curStatus} onValueChanged={(e) => setCurStatus(e.value)} dataSource={statusList} displayExpr="sys_division_sub_caption_text" valueExpr="sys_division_sub_value" />;

    return (
        <div className="abnormal-sign-log">
            <div className="right-content-title">
                <PageTitle />
            </div>
            <div className="right-content-body">
                <div className="container">
                    <BaseDataGrid
                        toolbarModules={[filter, statusDropdown, dateRange, searchBtn]}
                        dataSource={customDataSource}
                        allowModify={true}
                        isRemote={true}
                        headers={header}
                        selectMode="multiple"
                        isPaging={true}
                        showEdit={false}
                        showDelete={false}
                        showAdd={false}
                        showCopy={false}
                        showCheckBox={false}
                        link="BadTypeManagementInfoForm"
                        isFullSize={true}
                        isDoubleClicked={false}
                    />
                </div>
            </div>
        </div>
    );
};

export default AbnormalSignLog;
