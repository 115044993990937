
import axios from 'axios';

//export const inaTestGrid = async (data) => {
//  var res;
//  const url = `https://jsonplaceholder.typicode.com/todos/`;

//  try {
//    res = axios.get(url);
//    return res.status === 200 ? res.data : "fail";
//  } catch (error) {
//    console.error('error', error);
//  }
//  return res.status === 200 ? res : "fail";
//};

export const inaTestGrid = async (data) => {
  
  const url = `https://jsonplaceholder.typicode.com/todos/`;
  var test = await abc(url);
  return test;
}



const abc = async (urlPath) => {
  var res;
  try {
    res = await makeAxiosGetRequessdft(urlPath);
    return res.status === 200 ? res.data : "fail";
  } catch (error) {
    console.error('error', error);
  }
  //return res.status === 200 ? res : "fail";
}

const makeAxiosGetRequessdft = async (urlPath) => {

  
  
  var url = urlPath;
  const config = {
    method: "GET",
    url: url
  
  }
  let res = await axios(config);
  return res;
}