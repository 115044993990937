import useTranslation from "./translations";

export const useGetUseOrNotContent = () => {
    const translation = useTranslation();

    return [
        { value: true, name: translation.use },
        { value: false, name: translation.notUse },
    ];
};
