﻿import React, { useState, useEffect, useRef } from 'react';
import { getUser, getLanguage, getIP } from '../../apiInterface/utils/Common';
import { getBusinessIds, getUserInfo, insertUserInfo, updateUserInfo, deleteUserInfo } from '../../apiInterface/userManagement/UserManagementAPI';
import CustomStore from 'devextreme/data/custom_store';
import useTranslation from '../../components/customHooks/translations';
import { useLocation } from 'react-router-dom';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import BaseDataGrid from '../../components/common/BaseDataGrid';
import { DropdownField, FilterComponent } from '../../components/common/FormField';
import { getMenuMappingInfo } from '../../apiInterface/utils/Common';
import { successToastr } from '../../components/common/Toastr';
import { PageDropdown } from '../../components/common/PageDropdown';
import { PageTitle } from '../../components/common/PageTitle.jsx';
import Badge from '../../components/common/Badge';

const UserManagementInfo = () => {
    const translation = useTranslation();
    const user = getUser();
    const activeNotActiveJson = { true: translation.user_master_hired_work, false: translation.user_master_hired_leave };
    const activeNotActiveClassName = { true: 'grid-badge-use', false: 'grid-badge-disabled' };
    const [businessIds, setBusinessIds] = useState([]);
    const [curBusinessId, setCurBusinessId] = useState();
    const [dataSource, setDataSource] = useState([]);
    const language = getLanguage();
    const location = useLocation();
    const errorHandler = useErrorHandler();
    const searchText = useRef('');

    const headerInfo = [
        { dataField: 'user_master_no', caption: 'NO', dataType: 'string', isFixed: true, width: 100, alignment: 'center', allowReordering: true },
        { dataField: 'user_master_name', caption: translation.user_master_name.toUpperCase(), dataType: 'string', priority: 10, isFixed: true, allowReordering: true },
        { dataField: 'user_master_email', caption: translation.user_master_email.toUpperCase(), dataType: 'string', isFixed: true, allowReordering: true },
        { dataField: 'user_master_duty', caption: translation.user_master_duty.toUpperCase(), dataType: 'string', allowReordering: true },
        { dataField: 'user_master_position', caption: translation.user_master_position.toUpperCase(), dataType: 'string', allowReordering: true },
        { dataField: 'user_department_path', caption: translation.user_department_path.toUpperCase(), dataType: 'string', allowReordering: true },
        {
            dataField: 'user_master_is_active',
            caption: translation.user_master_is_active.toUpperCase(),
            dataType: 'string',
            isEdit: false,
            width: 130,
            alignment: 'center',
            cellRender: (e) => <Badge className={activeNotActiveClassName[e.value]} text={activeNotActiveJson[e.value]} />,
            allowReordering: true,
        },
        { dataField: 'user_master_hired_date', caption: translation.user_master_hired_date.toUpperCase(), dataType: 'string', allowReordering: true },
        { dataField: 'user_master_comment', caption: translation.user_master_comment.toUpperCase(), dataType: 'string', allowReordering: true },
        { dataField: 'user_master_modified_by', caption: translation.lastModifiedBy.toUpperCase(), dataType: 'string', priority: 0, width: 300, allowReordering: true },
        { dataField: 'user_master_modified_date', caption: translation.lastModifiedDate.toUpperCase(), dataType: 'string', priority: 1, width: 150, allowReordering: true },
    ];

    const onValueChanged = async (e) => {
        sessionStorage.setItem('businessId', e.value);
        setCurBusinessId(e.value);
        makeCustomStore(e.value);
    };

    const makeCustomStore = (businessId = curBusinessId) => {
        const customStore = new CustomStore({
            load: async (loadOptions) => {
                const pageNo = parseInt(loadOptions['skip'] / loadOptions['take']) + 1;
                const param = {
                    user_company_id: user.user_company_id,
                    user_business_id: businessId,
                    sys_lang_code: language,
                    row_count: loadOptions['take'],
                    page_no: pageNo,
                    search_text: searchText.current,
                };

                const res = await errorHandler(getUserInfo, param);
                if (res) {
                    return {
                        data: res.data.o_data,
                        totalCount: res.data.count,
                    };
                } else {
                    return {
                        data: [],
                        totalCount: 0,
                    };
                }
            },
            insert: async (value) => {
                insert(value, businessId);
            },
            update: async (previousValue, changeValue) => {
                const newData = { ...previousValue, ...changeValue };
                update(newData, businessId);
            },
            remove: async (value) => {
                remove(value, businessId);
            },
        });
        setDataSource(customStore);
    };

    const insert = async (value, businessId) => {
        const { id: mapping_id } = await getMenuMappingInfo();
        const user = getUser();
        const ip = getIP();

        const queryData = {
            user_master_id: user.user_master_id,
            user_log_ip: ip,
            user_company_id: user.user_company_id,
            menu_mapping_id: mapping_id,
            user_department_id: value.user_department_id,
            user_master_email: value.user_master_email,
            user_master_name: value.user_master_name,
            user_master_duty: value.user_master_duty,
            user_master_position: value.user_master_position,
            user_master_phone: value.user_master_phone,
            user_master_hired_date: value.user_master_hired_date,
            user_master_is_active: true,
            user_master_comment: value.user_master_comment,
        };

        const response = await errorHandler(insertUserInfo, queryData);
        if (response) {
        }
    };

    const update = async (value, businessId) => {
        const { id: mapping_id } = await getMenuMappingInfo();
        const user = getUser();
        const ip = getIP();

        const queryData = {
            user_master_id: user.user_master_id,
            user_log_ip: ip,
            user_company_id: user.user_company_id,
            menu_mapping_id: mapping_id,
            update_user_master_id: value.user_master_id,
            user_department_id: value.user_department_id,
            user_master_name: value.user_master_name,
            user_master_duty: value.user_master_duty,
            user_master_position: value.user_master_position,
            user_master_phone: value.user_master_phone,
            user_master_hired_date: value.user_master_hired_date,
            user_master_is_active: true,
            user_master_comment: value.user_master_comment,
        };

        const response = await errorHandler(updateUserInfo, queryData);
        if (response) {
        }
    };

    const remove = async (value, businessId) => {
        const { id: mapping_id } = await getMenuMappingInfo();
        const user = getUser();
        const ip = getIP();

        const queryData = {
            target_id: value.user_master_id,
            user_log_ip: ip,
            user_menu_mapping_id: mapping_id,
            user_master_id: user.user_master_id,
        };

        const response = await errorHandler(deleteUserInfo, queryData);
        if (response !== 'fail' && response.message === 'MSG_100') {
            successToastr(translation.success_delete_row);
            makeCustomStore(parseInt(sessionStorage.getItem('businessId')));
        }
    };

    //init
    useEffect(() => {
        const initSelectBox = async () => {
            const param = { user_company_id: user?.user_company_id };
            const result = await errorHandler(getBusinessIds, param);
            if (result) {
                setBusinessIds(result.data.o_data);

                if (location.state?.previousPage == '/UserManagementInfoForm') {
                    setCurBusinessId(parseInt(sessionStorage.getItem('businessId')));
                } else {
                    let isBusinessExist = false;
                    if (user.user_business_id != null) {
                        const branch = result.data.o_data.find((branch) => branch.user_business_id == user.user_business_id);
                        if (branch != null) {
                            setCurBusinessId(user.user_business_id);
                            isBusinessExist = true;
                        }
                    }

                    //if user info doesn't have business or server's response doesn't have user's business set first row as a default value.
                    if (!isBusinessExist) {
                        setCurBusinessId(result.data.o_data[0]?.user_business_id);
                    }
                }
            }
        };
        initSelectBox();
    }, []);

    const businessDropdown = <DropdownField width={204} label={translation.select_business} value={curBusinessId} onValueChanged={onValueChanged} dataSource={businessIds} displayExpr="user_business_name" valueExpr="user_business_id" />;

    const onFilter = (keyword) => {
        searchText.current = keyword;
        makeCustomStore();
    };

    const filter = <FilterComponent label={translation.filter} width={204} onFilter={onFilter} />;

    return (
        <div className="user-management-info">
            <div className="right-content-title">
                <PageTitle />
                <PageDropdown />
            </div>
            <div className="right-content-body">
                <div className="container">
                    <BaseDataGrid toolbarModules={[businessDropdown, filter]} allowModify={true} allowMoving={true} dataSource={dataSource} selectMode="multiple" isRemote={true} headers={headerInfo} isPaging={true} link="UserManagementInfoForm" isFullSize={true} params={{ business: curBusinessId }} />
                </div>
            </div>
        </div>
    );
};

export default UserManagementInfo;
