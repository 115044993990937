import { makeTokenAxiosDeleteRequest, makeTokenAxiosGetRequest, makeTokenAxiosPostRequest, makeTokenAxiosPutRequest, makeTokenAxiosPutRequestParams } from '../../apiInterface/utils/AxiosMask';
import { getToken, getUser, getIP } from '../../apiInterface/utils/Common';

export const getDepartmentsByCompanyId = async (param) => {
    let res;
    const urlPath = `department/get_business_dd`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : 'fail';
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res : 'fail';
};

export const updateDepartmentSeq = async (param) => {
    let res;
    const urlPath = `department/update_user_department_seq`;

    try {
        res = await makeTokenAxiosPutRequestParams(urlPath, param);
        return res.status === 200 ? res.data : 'fail';
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res : 'fail';
};

export const getDepartmentInfoByDepartmentId = async (param) => {
    let res;
    const urlPath = `department/get_user_department_by_id`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : 'fail';
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res : 'fail';
};

export const getDepartmentInfo = async (param) => {
    let res;
    const urlPath = `department/`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : 'fail';
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res : 'fail';
};

export const insertDepartmentInfo = async (data) => {
    let res;
    const urlPath = `department/`;

    try {
        res = await makeTokenAxiosPostRequest(urlPath, data);
        return res.status === 200 ? res.data : 'fail';
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res : 'fail';
};

export const updateDepartmentInfo = async (data) => {
    let res;
    const urlPath = `department/`;

    try {
        res = await makeTokenAxiosPutRequest(urlPath, data);
        return res.status === 200 ? res.data : 'fail';
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res : 'fail';
};

export const deleteDepartmentInfo = async (data) => {
    let res;
    const urlPath = `department/`;

    try {
        res = await makeTokenAxiosDeleteRequest(urlPath, data);
        return res.status === 200 ? res.data : 'fail';
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res : 'fail';
};
