import { useEffect, useState, useRef } from 'react';
import { GridStack } from 'gridstack';
import { useLocation, useNavigate } from 'react-router-dom';
import { getTemplateType, insert_template_masterAPI, saveImage64, update_template_masterAPI, uploadTemplateIconsAPI } from '../../apiInterface/templateManagment/TemplateManagementAPI';
import { getIP, getMenuMappingInfo, getUser } from '../../apiInterface/utils/Common';
import { PageDropdown } from '../../components/common/PageDropdown';
import PageTitle from '../../components/common/PageTitle';
import Button from 'devextreme-react/button';
import LoadIndicator from 'devextreme-react/load-indicator';
import { SelectBox } from 'devextreme-react/select-box';
import useTranslation from '../customHooks/translations';
import { useSwal } from '../common/Swal';
import 'gridstack/dist/gridstack.min.css';
import '../../contents/styles/templateMaster/custom-template.css';
import { chartResize, MultiChart } from '../ixChart/IXChart';
import { loading } from '../common/Library';
import * as htmlToImage from 'html-to-image';

const CustomTemplate = () => {
    const { addOrEdit, basicThemeURL, darkThemeURL, template_category_id, template_custom_layout_json, template_master_comment, template_master_id, template_master_is_active, template_master_name } = useLocation().state;
    const translation = useTranslation();
    const { success, fail } = useSwal();
    const navigate = useNavigate();
    const user = getUser();
    const ipAddr = getIP();

    let grid;
    const [templateListByComponent, setTemplateListByComponent] = useState({}); // {CARD: [{id, style, filter, etc}, {id, style, filter, etc}], CHART: [{id, style, filter, etc}, {id, style, filter, etc}]}
    const [selectedTemplateType, setSelectedTemplateType] = useState({});
    const isPreview = addOrEdit === 'preview' ? true : false;
    const dragItemRef = useRef({ info: null });

    useEffect(() => {
        template_custom_layout_json && loading('.card-container-box', 'on');
        let componentTypeList;
        const fetchData = async () => {
            componentTypeList = await getComponentTypeList();
        };
        fetchData();

        setTimeout(() => {
            gridSetting();
            template_custom_layout_json ? setInitGridItem(componentTypeList) : null;
        }, 1000);
    }, []);

    const setInitGridItem = (componentTypeList) => {
        if (!(template_custom_layout_json.length > 0)) loading('.card-container-box', 'off');
        else {
            grid.load(template_custom_layout_json);
            template_custom_layout_json.forEach((item, i) => {
                setGridItemContent(item, componentTypeList);
                if (i === template_custom_layout_json.length - 1) loading('.card-container-box', 'off');
            });
        }
    };

    const setGridItemContent = (item, componentTypeList) => {
        const targetItem = document.querySelector(`[gs-id="${item.id}"]`);
        const targetContent = targetItem.querySelector('.grid-stack-item-content');
        targetContent.classList.add(`${item.templateTypeStyle.toLowerCase()}-content`);

        const bindContent = () => {
            if (item.templateTypeStyle === 'CHART') {
                const w = targetContent.offsetWidth;
                const h = targetContent.offsetHeight;
                drawChart(item.id, item.templateTypeName, w, h);
            } else {
                const content = componentTypeList[item.templateTypeStyle].find((i) => i.template_type_name === item.templateTypeName).template_type_image;
                targetContent.innerHTML = content;
            }
        };

        const bindTitleHeader = (title) => {
            targetItem.querySelector('.grid-stack-item-title')?.remove();
            const titleDiv = document.createElement('div');
            titleDiv.setAttribute('class', 'grid-stack-item-title');
            titleDiv.textContent = title;
            targetContent.prepend(titleDiv);
        };

        bindContent();
        item.templateTypeStyle !== 'CARD' && bindTitleHeader(item.settingVal?.title);
    };

    // set responsive chart
    const setObserver = (chart, target) => {
        const observer = new ResizeObserver((entries) => {
            for (let entry of entries) {
                chartResize(chart, target.clientWidth, target.clientHeight - 48);
            }
        });
        observer.observe(target);
    };

    const gridSetting = () => {
        const option = {
            removable: '.tool-delete',
            dragIn: '.grid-stack-item',
            dragInOptions: { revert: 'invalid', scroll: false, appendTo: 'body', helper: 'clone' },
            float: true,
            acceptWidgets: '.grid-stack-item',
            disableDrag: isPreview,
            disableResize: isPreview,
        };

        grid = GridStack.init(option);
        const gridInfo = grid.save(); // need to resize (if don't save, gridstackNode lose attributes like templateTypeStyle, templateTypeName)
        grid.destroy(false); // need to re-load grid
        grid = GridStack.init(option);
        grid.load(gridInfo);

        grid.on('added', (e, item) => {
            if (item[0].id === undefined) {
                // Add some attributes of grid-stack-item
                const id = `template_${new Date().getTime().toString()}`;
                item[0].templateTypeId = dragItemRef.current.info.template_type_id;
                item[0].id = id;
                item[0].el.setAttribute('gs-id', id);
                item[0].templateTypeStyle = dragItemRef.current.info.template_type_style;
                item[0].templateTypeName = dragItemRef.current.info.template_type_name;
                item[0].settingVal === undefined ? (item[0].settingVal = {}) : null;
                item[0].settingVal.title = dragItemRef.current.info.template_type_name;
                item[0].noDataContent = dragItemRef.current.info.template_type_no_image;

                // if template type is chart, then use echart, no svg as content
                if (dragItemRef.current.info.template_type_style === 'CHART') {
                    drawChart(item[0].id, dragItemRef.current.info.template_type_name);
                    item[0].el.querySelector('.grid-stack-item-content svg').remove();
                }
            }
        });

        grid.on('resize', (e, el) => {
            // // Fixed width and height ratio
            // if (e.target.gridstackNode.templateTypeStyle === "CARD") {
            //     const placeholder = document.querySelector('.grid-stack-placeholder');
            //     const w = e.target.gridstackNode.w;
            //     e.target.gridstackNode.w = w;
            //     e.target.gridstackNode.h = w;
            //     placeholder.setAttribute('gs-w', w);
            //     placeholder.setAttribute('gs-h', w);
            //     //e.target.nextSibling.setAttribute('gs-w', w); // if use this, template have issue that chart template is resized when card template is resized
            //     //e.target.nextSibling.setAttribute('gs-h', w);
            // }
        });

        grid.on('resizestop', (e, el) => {
            if (el.gridstackNode.templateTypeStyle === 'CHART') {
                const id = el.getAttribute('gs-id');
                drawChart(id, e.target.gridstackNode.templateTypeName);
            }
        });
    };

    const getComponentTypeList = async () => {
        const param = { user_company_id: user.user_company_id };
        const res = await getTemplateType(param);
        if (res !== 'fail') {
            const templateType = JSON.parse(res.data.o_data[0].get_template_type);
            const templateListByType = {};
            templateType.forEach((type) => {
                /* live text, live image, system image 숨기기 */
                if (['IMAGE', 'TEXT'].includes(type.t_sys_division_sub_value)) {
                    templateListByType[type.t_sys_division_sub_value] = type.t_template_str.filter((sub) => sub.template_type_style_sub === 'custom');
                } else {
                    templateListByType[type.t_sys_division_sub_value] = type.t_template_str;
                }
            });
            setTemplateListByComponent(templateListByType);

            return templateListByType;
        }
    };

    const getChart = (params, type = []) => {
        const chart = MultiChart(params, type);
        return chart;
    };

    const drawChart = (itemId, chartType) => {
        const targetContent = document.querySelector(`[gs-id='${itemId}'] .grid-stack-item-content`);
        const yList = [
            [49, 4, 51, 32, 3, 20, 16, 38, 21, 8],
            [5, 29, 38, 43, 5, 25, 34, 49, 26, 31],
            [26, 29, 15, 7, 39, 23, 20, 33, 40, 18],
            [22, 44, 8, 14, 29, 2, 35, 17, 10, 55],
        ];
        const chartParam = {
            x: [[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]],
            y: (() => {
                if (chartType === 'Chart-Scatter') return yList.slice(0, 4);
                else if (chartType === 'Chart-StepLine') return yList.slice(0, 3);
                else if (chartType === 'Chart-Radar') return yList.slice(0, 2);
                else return yList.slice(0, 1);
            })(),
            legend: ['A', 'B', 'C', 'D'],
            id: `[gs-id='${itemId}'] .grid-stack-item-content`,
            color: ['#0FB5AE', '#4046CA', '#F68511', '#DE3D82'],
            zoomEvt: false,
            w: targetContent.clientWidth,
            h: targetContent.clientHeight - 60,
            backgroundColor: 'white',
            getOnlyOption: chartType !== 'Chart-Multi' ? false : true,
        };
        const forMulti = {
            color: ['#4046CA', '#F68511', '#DE3D82'],
            y: [
                [5, 29, 38, 43, 5, 25, 34, 49, 26, 31],
                [26, 29, 15, 7, 39, 23, 20, 33, 40, 18],
                [22, 44, 8, 14, 29, 2, 35, 17, 10, 55],
            ],
            legend: ['B', 'C', 'D'],
        };
        const chart = getChart([chartParam, forMulti], ['Chart-Line', 'Chart-Scatter']);
        setObserver(chart, targetContent);
    };

    const clickSaveBtn = async () => {
        await saveScreenShot();
        await handleSave();
    };

    const handleSave = async () => {
        const grid = GridStack.init();
        const widgetInfoJson = grid.save(false);
        const menuMappingInfo = await getMenuMappingInfo();
        const param = {
            template_master_id: template_master_id,
            user_master_id: user.user_master_id,
            user_log_ip: ipAddr,
            menu_mapping_id: menuMappingInfo.id,
            user_company_id: user.user_company_id,
            template_category_id: template_category_id,
            template_master_name: template_master_name,
            template_master_is_active: template_master_is_active,
            template_master_comment: template_master_comment,
            template_master_image_white: basicThemeURL,
            template_master_image_black: darkThemeURL,
            template_master_layout_json: JSON.stringify(widgetInfoJson),
        };
        const targetApi = addOrEdit === 'Add' ? insert_template_masterAPI : update_template_masterAPI;
        const res = await targetApi(param);
        if (res === 'fail') {
            var htmlString = `<span></span><p>${translationsave_failed}</p>`;
            fail(htmlString, () => {});
        } else if (res.message !== 'MSG_100') {
            var htmlString = `<span></span><p>${translation[res.message]}</p>`;
            fail(htmlString, () => {});
        } else if (res.message === 'MSG_100') {
            var htmlString = `<span></span><p>${addOrEdit === 'Add' ? translation.save_successfully : translation.update_successfully}</p>`;
            var swalResFn = () => {
                navigate('/TemplateMasterGrid');
            };
            success(htmlString, swalResFn);
        }
    };

    const saveScreenShot = async () => {
        const uploadImage = async (img) => {
            const user = getUser();
            const params = {
                user_id: user.user_master_id,
                image_64: img,
            };
            const res = await saveImage64(params);
            if (res === 'fail' || res.message !== 'MSG_100') {
                console.log('failed save screenshot!!!!!!!!!');
            } else {
                console.log('res', res);
            }
        };
        await htmlToImage
            .toPng(document.querySelector('.card-container'))
            .then(async (dataUrl) => {
                await uploadImage(dataUrl);
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
    };

    return (
        <div className="custom-template">
            <div className="right-content-title" style={{ display: isPreview ? 'none' : '' }}>
                <PageTitle pageState={addOrEdit} />
                <PageDropdown />
            </div>
            <div className="custom-template-body" style={{ display: 'flex' }}>
                <div className="card left-controller" style={{ display: isPreview ? 'none' : '' }}>
                    <div className="card-header">
                        <h6 className="card-title">{translation.builder_option}</h6>
                    </div>
                    <div className="card-body">
                        <div className="template-info">
                            <div className="dx-field column">
                                <div className="dx-field-label">
                                    <p>{translation.template_name}</p>
                                </div>
                                <div className="dx-field-value">
                                    <p>{template_master_name}</p>
                                </div>
                            </div>
                            <div className="dx-field column">
                                <div className="dx-field-label">
                                    <p>{translation.component_type}</p>
                                </div>
                                <div className="dx-field-value">
                                    <SelectBox
                                        dataSource={Object.keys(templateListByComponent)}
                                        className="ix-tfs-r"
                                        placeholder={translation.select_template}
                                        onValueChange={(e) => {
                                            setSelectedTemplateType({ templateTypeName: e, templateList: templateListByComponent[e] });
                                            setTimeout(() => {
                                                gridSetting();
                                            }, 100);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={`template-box-${selectedTemplateType.templateTypeName}`}>
                            <div className="template-list">
                                {selectedTemplateType.templateList?.map((template, i) => {
                                    const type = template.template_type_style;
                                    return <Template key={`${type}${i}`} templateInfo={template} dragItemRef={dragItemRef} />;
                                })}
                            </div>
                        </div>
                        <div className="tool-delete">
                            <div className="btn-delete">
                                <i className="dx-icon-m-trash"></i>
                                <div>{translation.drag_to_delete}</div>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <div className="btn-group">
                            <Button type="normal" stylingMode="text" className="btn-s-r" text="PREV" onClick={() => navigate(-1)}>
                                <div>
                                    <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                    <span className="dx-button-text">{translation.prev}</span>
                                </div>
                            </Button>
                            <Button type="default" stylingMode="contained" className="btn-s-r" text="SAVE" onClick={clickSaveBtn}>
                                <div>
                                    <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                    <span className="dx-button-text">{translation.save}</span>
                                </div>
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="card-container">
                    <div className="card-container-box  grid-stack"></div>
                </div>
            </div>
        </div>
    );
};

const Template = (props) => {
    let w = 4;
    let h = 2;
    const templateTypeStyle = props.templateInfo.template_type_style;
    let templateTypeImage = templateTypeStyle !== 'CARD' ? `<div class="grid-stack-item-title">${props.templateInfo.template_type_name}</div>` + props.templateInfo.template_type_image.replaceAll('"', "'") : props.templateInfo.template_type_image.replaceAll('"', "'");
    if (templateTypeStyle === 'CARD') w = 2;
    return (
        <div
            className="grid-stack-item"
            gs-w={w}
            gs-h={h}
            draggable
            onMouseDown={() => {
                props.dragItemRef.current = { info: props.templateInfo };
            }}
            style={{ height: 'auto' }}
        >
            <div className={`grid-stack-item-content ${templateTypeStyle.toLowerCase()}-content`} dangerouslySetInnerHTML={{ __html: templateTypeImage }}></div>
        </div>
    );
};

export default CustomTemplate;
