﻿import React, { useEffect, useState } from "react";
import CustomStore from 'devextreme/data/custom_store';
import { getUser, getLanguage } from "../../apiInterface/utils/Common"
import { getUseHistoryByCompanyId } from "../../apiInterface/useHistory/UseHistoryAPI"
import useTranslation from "../../components/customHooks/translations"
import { DateRangeField, TextboxField } from "../../components/common/FormField";
import BaseDataGrid from "../../components/common/BaseDataGrid";
import useErrorHandler from "../../components/customHooks/useErrorHandler";
import { PageDropdown } from "../../components/common/PageDropdown";
import { PageTitle } from "../../components/common/PageTitle.jsx";

const UseHistory = () => {
  const formatDate = (targetDate) => {
    const year = targetDate.getFullYear();
    const month = ("0" + (1 + targetDate.getMonth())).slice(-2);
    const date = ("0" + targetDate.getDate()).slice(-2);
    return (`${year}-${month}-${date}`);
  }

  const pageSize = 30;
  const errorHandler = useErrorHandler();
  const translation = useTranslation();
  const today = new Date();
  const [customDataSource, setCustomDataSource] = useState([]);
  //const [customStoreObj, setCustomStoreObj] = useState([]);
  const [searchText, setSearchText] = useState();
  const [startDate, setStartDate] = useState(today);
  //const [startMaxDate, setStartMaxDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  //const [endMinDate, setEndMinDate] = useState(startMaxDate);

  const header = [
    { dataField: "sys_log_history_no", caption: translation.sys_log_history_no, dataType: "number", isEdit: false, width: 100, alignment: "center", isFixed: true, allowReordering: true },
    { dataField: "user_master_name", caption: translation.user_master_name.toUpperCase(), dataType: "string", isEdit: false, isFixed: true, allowReordering: true },
    { dataField: "sys_log_datetime", caption: translation.sys_log_datetime.toUpperCase(), dataType: "string", isEdit: false, width: 200, allowReordering: true },
    { dataField: "user_log_ip", caption: translation.user_log_ip.toUpperCase(), dataType: "string", isEdit: false, width: 200, allowReordering: true },
    { dataField: "sys_log_action", caption: translation.sys_log_action.toUpperCase(), dataType: "string", isEdit: false, allowReordering: true },
    { dataField: "sys_log_result", caption: translation.sys_log_result.toUpperCase(), dataType: "string", isEdit: false, width: 120, allowReordering: true },
    { dataField: "sys_log_comment", caption: translation.sys_log_comment.toUpperCase(), dataType: "string", allowReordering: true },
    { dataField: "sys_menu_mapping", caption: translation.sys_menu_mapping.toUpperCase(), dataType: "string", isEdit: false, allowReordering: true },
  ]

  const getUseHistory = async () => {
    const customStore = new CustomStore({
      key: "",
      load: async (loadOptions) => {
        const user = getUser();
        const lang = getLanguage();
        let skip = 0;
        let take = 10;
        if (loadOptions["skip"]) skip = loadOptions["skip"];
        if (loadOptions["take"]) take = loadOptions["take"];
        const pageNo = parseInt(skip / take) + 1;
        const param = {
          user_company_id: user.user_company_id,
          sys_lang_code: lang,
          start_date: formatDate(startDate),
          end_date: formatDate(endDate),
          row_count: pageSize,
          page_no: pageNo,
          search_text: searchText,
        }
        const res = await errorHandler(getUseHistoryByCompanyId, param);
        if (res) {
          const useHistoryData = res.data.o_data;
          const pagingDict = {
            data: useHistoryData,
            totalCount: res.data.count
          }
          return pagingDict;
        }
      }
    });
    //setCustomStoreObj(customStore);
    setCustomDataSource(customStore);
  }

  useEffect(() => {
    getUseHistory();
  }, [searchText, startDate, endDate]);

  //const onDateChanged = (e, type) => {
  //  const date = e.value;
  //  const dateMaskVal = e.component._maskValue;

  //  if (type === 'start' && new Date(date) <= new Date(endDate)) {
  //    setStartDate(date);
  //    setEndMinDate(dateMaskVal);
  //  } else if (type === 'end' && new Date(startDate) <= new Date(date)) {
  //    setEndDate(date);
  //    setStartMaxDate(dateMaskVal);
  //  }
  //}

  const filter =
    <TextboxField
      label={translation.filter}
      value={searchText}
      onValueChanged={e => setSearchText(e.value)}
      width={204}
      isFilter={true}
    />

  const dateRange = (
      <DateRangeField
          startValue={startDate}
          endValue={endDate}
          setStartValue={setStartDate}
          setEndValue={setEndDate}
      />
    //<>
    //  <DateBox
    //    name={null}
    //    type="date"
    //    className="ix-tfs-r"
    //    width={148}
    //    defaultValue={startDate}
    //    value={startDate}
    //    onValueChanged={e => onDateChanged(e, 'start')}
    //    acceptCustomValue={false}
    //    openOnFieldClick={true}
    //    min={null}
    //    max={startMaxDate}
    //  />
    //  <span>-</span>
    //  <DateBox
    //    name={null}
    //    type="date"
    //    className="ix-tfs-r"
    //    width={148}
    //    defaultValue={endDate}
    //    value={endDate}
    //    onValueChanged={e => onDateChanged(e, 'end')}
    //    acceptCustomValue={false}
    //    openOnFieldClick={true}
    //    min={endMinDate}
    //    max={today}
    //  />
    //</>
  )

    return (
        <>
            <div className="usehistory">
                <div className="right-content-title">
                    <PageTitle />
                    <PageDropdown />
                </div>
                <div className="right-content-body">
                    <div className="container">
                        <BaseDataGrid
                            toolbarModules={[filter, dateRange]}
                            dataSource={customDataSource}
                            headers={header}
                            isPaging={true}
                            defaultPageSize={pageSize}
                            isRemote={true}
                            pagerVisible={true}
                            allowModify={false}
                            selectMode={false}
                            isFullSize={true}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default UseHistory;
