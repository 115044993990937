import React, { useEffect, useRef, useState } from "react";
import Button from 'devextreme-react/button';
import useTranslation from "../../components/customHooks/translations";
import { getItemCategory } from "../../apiInterface/item/ItemMasterAPI";
import { getItemMasterInfo } from "../../apiInterface/machine/MoldAPI";
import useErrorHandler from "../../components/customHooks/useErrorHandler";
import BaseDataGrid from "../../components/common/BaseDataGrid";
import { Popup, ScrollView, TreeView } from "devextreme-react";
import Search from "../../components/common/Search.jsx";
import DraggableTreeList from "../../components/common/DraggableTreeList";
import { getUser, getIP, getLanguage, getMenuMappingInfo } from '../../apiInterface/utils/Common';

let searchText = "";
export default function MoldAddPopup(props) {
    const {
        title,
        onClickOk = () => { },
        onExit = () => { },
        visible,
    } = props;

    const gridRef = useRef();
    const translation = useTranslation();
    const errorHandler = useErrorHandler();
    const [selectItem, setSelectItem] = useState("");
    //const [selectItemRow, setSelectItemRow] = useState(undefined);
    //const [selectItemRow, setSelectItemRow] = useState(undefined);
    const [selectedData, setSelectedData] = useState([]);
    const [itemMasterList, setItemMasterList] = useState(null);
    const [itemCategoryList, setItemCategoryList] = useState([]);
    const itemCategoryHeader = [
        { dataField: "item_category_name", caption: translation.item_category_name.toUpperCase(), dataType: "string", allowReordering: true }
    ]

    const itemMasterHeader = [
        { dataField: "item_master_no", caption: translation.item_master_no, dataType: "number", isEdit: false, width: 100, alignment: "center", allowReordering: true },
        { dataField: "item_master_name", caption: translation.item_master_name.toUpperCase(), dataType: "string", isRequired: true, allowReordering: true },
        { dataField: "item_master_code", caption: translation.item_master_code.toUpperCase(), dataType: "string", allowReordering: true },
        { dataField: "item_master_eo_no", caption: translation.item_master_eo_no.toUpperCase(), dataType: "string", allowReordering: true },
        { dataField: "item_master_eo_date", caption: translation.item_master_eo_date.toUpperCase(), dataType: "string", allowReordering: true },
    ]
    const exit = (e) => {
        const instance = gridRef.current.getInstance();
        instance.deselectAll();
        setSelectedData([]);
        onExit(e);
    }

    const init = async () => {
        await getItemCategoryList();
    }

    const ok = (e) => {
        const instance = gridRef.current.getInstance();
        const selection = instance.getSelectedRowsData();
        onClickOk(selection);
        exit(e);
    }
    const deleteAll = () => {
        gridRef.current.getInstance().deselectAll();
    }

    const deletOne = (e) => {
        gridRef.current.getInstance().deselectRows(e)
    }

    const getItemCategoryList = async () => {
        const user = getUser();
        const menuMappingInfo = await getMenuMappingInfo();

        const params = {
            user_company_id: user.user_company_id,
            menu_mapping_id: menuMappingInfo.id,
            user_master_id: user.user_master_id
        }
        const res = await errorHandler(getItemCategory, params);
        if (res) {
            setItemCategoryList(res.data.o_data);
        }
    }

    const getItemMasterList = async (itemCategoryId, searchText = null) => {
        const user = getUser();
        const params = {
            user_company_id: user.user_company_id,
            item_category_id: itemCategoryId,
            search_text: searchText
        }
        const res = await errorHandler(getItemMasterInfo, params);
        if (res) {
            setItemMasterList(res.data.o_data);
        }
    }

    const searchDiv =
        <Search
            onClick={e => getItemMasterList(0, searchText)}
            value={searchText}
            onValueChanged={(value) => {
                setSearchText(value)
            }}
        />


    const treeDatagrid =
        <DraggableTreeList
            showCheckBox={false}
            allowModify={false}
            dataSource={itemCategoryList}
            keyExpr="item_category_id"
            displayExpr="item_category_name"
            parentIdExpr="item_category_parent_id"
            headers={itemCategoryHeader}
            selectMode="single"
            onRowClick={(e) => { getItemMasterList(e.data.item_category_id) }}
            width={252}
        />

    const onSelectionChanged = (val) => {
        setSelectedData(val);
    }

    return (
        <Popup
            width={1100}
            height={856}
            visible={visible}
            hideOnOutsideClick={true}
            onHiding={exit}
            onInitialized={init}
            dragEnabled={true}
            position="center"
            showTitle={false}
        >
            <div className="popup-select-item">
                <div className="popup-header">
                    <p className="popup-title">{translation.quality_badtype_select_item}</p>
                </div>
                <div className="popup-body">
                    <div className="selected-item-viewer">
                        <div className="icon-checked">
                            <i></i>
                        </div>
                        <div className="selected-item-field">
                            {selectedData?.length != 0 &&
                                <>
                                    <ul className="selected-items">
                                        {
                                            selectedData.map((e, i) =>
                                                <li key={i} className="item">
                                                    <p className="item-name">{`${e.item_master_name} (${e.item_master_code})`}</p>
                                                    <i onClick={() => { deletOne(e) }} className="material-icons btn-delete">cancel</i>
                                                </li>
                                            )
                                        }
                                    </ul>
                                    <div className="btn-all-delete">
                                        <button onClick={deleteAll}>Delete All</button>
                                    </div>
                                </>
                            }
                            {selectedData?.length == 0 &&
                                <p className="comment-not-selected">{translation.select_item_notice}</p>
                            }
                        </div>
                    </div>
                    <div className="item-select-box">
                        {selectItem === "" &&
                            <div className="left-panel">
                                <ScrollView>
                                    {treeDatagrid}
                                </ScrollView>
                            </div>

                        }
                        <ScrollView>
                            <div className="container-wrapper">
                                <div className="container">
                                    {selectItem !== "" ? searchDiv : null}
                                    <BaseDataGrid
                                        allowModify={false}
                                        ref={gridRef}
                                        dataSource={itemMasterList}
                                        headers={itemMasterHeader}
                                        selectMode="multiple"
                                        onSelectionChanged={onSelectionChanged}
                                        isDoubleClicked={false}
                                    />
                                </div>
                            </div>
                        </ScrollView>

                    </div>
                </div>
                <div className="popup-footer">
                    <Button
                        width={86}
                        text={translation.cancel}
                        type="normal"
                        stylingMode="text"
                        className="btn-s-r"
                        onClick={exit}
                    />
                    <Button
                        text={translation.confirm}
                        width={86}
                        type="default"
                        stylingMode="contained"
                        className="btn-s-r"
                        onClick={ok}
                    />
                </div>
            </div>
        </Popup>
    )
}

