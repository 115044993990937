import React, { useState, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Navigation from '../../components/layout/Navigation';
import LeftSideBar from '../../components/layout/LeftSideBar';
import ResizablePanel from '../../components/layout/ResizablePanel';
import LeftSideBarContextProvider from '../../components/contexts/LeftSideBarContext';
import useTranslation from '../../components/customHooks/translations';
import { getMenuMappingServiceChild, getMenuMappingServiceAPI } from '../../apiInterface/menu/MenuAPI';
import { getLanguage, getMenuMappingInfo, getParam, getServiceMappingInfo, getUser, setMenuMappingInfo, setServiceMappingInfo } from '../../apiInterface/utils/Common';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import { useMenuMappingContext } from '../../components/contexts/MenuMappingInfoProvider';
import { useSwal } from '../../components/common/Swal';

const Layout = (props) => {
    const bodyStyle = {
        position: 'absolute',
        top: '56px',
        left: '0px',
        display: 'flex',
        backgroundColor: '#F1F3F4',
        width: '100%',
        height: 'calc(100% - 56px)',
    };

    const [allMenuList, setAllMenuList] = useState();
    const [dropdownList, setDropdownList] = useState();
    const [nowProjectMenuList, setNowProjectMenuList] = useState();
    const [serviceMenu, setServiceMenu] = useState();
    const [nowMenu, setNowMenu] = useState();
    const pathnameSplit = window.location.pathname.split('/');
    const pagename = pathnameSplit[pathnameSplit.length - 1];
    //const pathname = window.location.pathname.replace("/framework/", "");
    const translation = useTranslation();
    const navigate = useNavigate();
    const errorHandler = useErrorHandler();
    const { setMenuMappingInfo: setMenuContext } = useMenuMappingContext();
    const { fail } = useSwal();
    const href = window.location.href;

    const getChildInfo = (menuList) => {
        const infoJson = [];
        menuList.forEach((menu) => {
            infoJson.push({ name: menu.name, link: menu.link, mappingId: menu.mappingId });
        });
        return infoJson;
    };

    const initializeMenuState = (pagename, mappingId, mappingCaption, menuList) => {
        const getChildIdxListForParentId = (menuList) => {
            const res = {};
            menuList.forEach((menu, idx) => {
                const parentId = menu.menu_mapping_parent_id;
                res[parentId] === undefined && (res[parentId] = []);
                res[parentId].push(idx);
            });
            return res;
        };

        let level1Idx = 0;
        let level2Idx = 0;
        let level3Idx = 0;
        let path = '';
        const level1 = [];
        const childIdxForParentIdList = getChildIdxListForParentId(menuList);
        childIdxForParentIdList[mappingId].forEach((level1MenuIdx, i) => {
            const level1Info = menuList[level1MenuIdx];
            const level1Name = level1Info.menu_mapping_caption;
            const level1Id = level1Info.menu_mapping_id;
            const level2 = [];
            childIdxForParentIdList[level1Id] !== undefined &&
                childIdxForParentIdList[level1Id].forEach((level2MenuIdx, j) => {
                    const level2Info = menuList[level2MenuIdx];
                    const level2Name = level2Info.menu_mapping_caption;
                    const level2Id = level2Info.menu_mapping_id;
                    const level2Link = level2Info.template_master_path;
                    const level3 = [];
                    childIdxForParentIdList[level2Id] !== undefined &&
                        childIdxForParentIdList[level2Id].forEach((level3MenuIdx, k) => {
                            const level3Info = menuList[level3MenuIdx];
                            const level3Name = level3Info.menu_mapping_caption;
                            const level3Id = level3Info.menu_mapping_id;
                            const level3Link = level3Info.template_master_path;
                            if (path === '') path = level3Link;
                            if (pagename !== null && (level3Info.template_master_path.toLowerCase() === pagename.toLowerCase() || JSON.parse(level3Info.template_master_path_sub)?.sub_pages.includes(pagename))) {
                                level1Idx = i;
                                level2Idx = j;
                                level3Idx = k;
                                path = level3Info.template_master_path;
                            }
                            level3.push({ level: 3, name: level3Name, child: null, link: level3Link, mappingId: level3Id });
                        });
                    level2.push({ level: 2, name: level2Name, child: level3, link: level2Link, mappingId: level2Id });
                });
            level1.push({ level: 1, name: level1Name, child: level2, link: null, mappingId: level1Id });
        });
        setAllMenuList({ name: mappingCaption, child: level1 });

        const nowProjectMenu = level1[level1Idx];
        setNowProjectMenuList(nowProjectMenu);

        const level1List = level1;
        const level2List = level1List.length !== 0 ? level1List[level1Idx].child : [];
        const level3List = level2List.length !== 0 ? level2List[level2Idx].child : [];
        const level1InfoJson = getChildInfo(level1List);
        const level2InfoJson = getChildInfo(level2List);
        const level3InfoJson = getChildInfo(level3List);
        const level0Name = mappingCaption;
        const level1Name = level1InfoJson[level1Idx]?.name;
        const level2Name = level2InfoJson[level2Idx]?.name;
        const level3Name = level3InfoJson[level3Idx]?.name;
        const dashboard = pagename.toLowerCase() === 'dashboard' ? 'Dashboard' : null;
        dashboard === null
            ? setDropdownList([
                  { name: level0Name, child: level1InfoJson },
                  { name: level1Name, child: level2InfoJson },
                  { name: level2Name, child: level3InfoJson },
                  { name: level3Name, child: [] },
              ])
            : setDropdownList([{ name: level0Name, child: level1InfoJson }]);
        //setNowMenu(dashboard !== null ? dashboard : level3Name);
        setNowMenu(dashboard !== null ? { level2: '', level3: dashboard } : { level2: level2InfoJson[level2Idx], level3: level3InfoJson[level3Idx] });
        dashboard === null
            ? setMenuMappingInfo(JSON.stringify({ id: level3InfoJson[level3Idx].mappingId, caption: { level1: level1Name, level2: level2Name, level3: level3Name }, path: path }))
            : setMenuMappingInfo(JSON.stringify({ id: undefined, caption: { level1: mappingCaption, level3: translation.dashboard }, path: dashboard }));
        setMenuContext(getMenuMappingInfo());
    };

    const getmenuByServiceId = async (pagename = null, mappingId, mappingCaption) => {
        //getData
        const user = getUser();
        const language = getLanguage();
        const data = {
            user_company_id: user.user_company_id,
            sys_lang_code: language,
            menu_mapping_service_id: mappingId,
            user_master_id: user.user_master_id,
        };
        const res = await errorHandler(getMenuMappingServiceChild, data);
        if (res) {
            const menuList = res.data.o_data;

            if (menuList.length !== 0) {
                // delete page or remove auth check
                let isPage = menuList.find((e) => e.template_master_path?.toLowerCase() == pagename?.toLowerCase());

                let FormArray = [];
                menuList.map((m) => {
                    if (JSON.parse(m.template_master_path_sub) !== null && JSON.parse(m.template_master_path_sub) !== undefined) {
                        FormArray = [...FormArray, ...JSON.parse(m.template_master_path_sub).sub_pages];
                    }
                });
                let isForm = FormArray.find((e) => e.toLowerCase() == pagename?.toLowerCase());

                let isExist = isPage !== undefined || isForm !== undefined;
                if (pagename !== 'Dashboard' && pagename !== 'framework' && !isExist) {
                    fail(translation.no_page_available, navigate(`/Dashboard`, {}));
                }
            }

            initializeMenuState(pagename, mappingId, mappingCaption, menuList);
        }
    };

    const getServiceMenu = async () => {
        const user = getUser();
        if (user == null) return;
        const lang = getLanguage();
        const params = {
            user_company_id: user.user_company_id,
            sys_lang_code: lang,
        };

        const res = await errorHandler(getMenuMappingServiceAPI, params);
        if (res) {
            let type;
            if (href.includes('framework')) type = 'framework';
            else if (href.includes('recipeai')) type = 'recipe';
            else if (href.includes('qualityai')) type = 'quality';
            else if (href.includes('3006') || href.includes('3016')) type = getParam('r') === '1' ? 'recipe' : 'quality';
            else if (href.includes('inspectionai')) type = 'inspection';
            const data = res.data.o_data.find((d) => d.enabled_service === true && d.template_master_type === type);
            setServiceMappingInfo(JSON.stringify({ id: data.menu_mapping_id, caption: data.menu_mapping_caption, type: data.template_master_type }));

            setServiceMenu(res.data.o_data);
        }
    };

    const location = useLocation();
    useEffect(() => {
        init();
    }, [location]);

    const init = async () => {
        await getServiceMenu();
        // need when user move another service
        // const id = getParam('id');
        // const caption = getParam('caption');
        // if (id !== undefined && caption !== undefined) {
        //     setServiceMappingInfo(JSON.stringify({ id, caption }));
        //     history.pushState(null, null, window.location.href.split('?')[0]);
        // }

        const serviceMappingInfo = getServiceMappingInfo();
        if (serviceMappingInfo !== null) {
            const serviceMappingId = parseInt(serviceMappingInfo.id);
            const serviceMappingCaption = serviceMappingInfo.caption;
            pagename !== 'MainPortal' && getmenuByServiceId(pagename, serviceMappingId, serviceMappingCaption);
        }
        // getServiceMenu();
    };

    const getMenuInfoByName = (menuList, name) => {
        let returnMenu;
        menuList.forEach((menu) => {
            if (menu.name === name) {
                returnMenu = menu;
            }
        });
        return returnMenu;
    };

    const clickMenu = (e, menu) => {
        if (menu === 'dashboard') {
            setMenuMappingInfo(JSON.stringify({ id: undefined, caption: { level1: dropdownList[0]?.name, level3: translation.dashboard }, path: 'Dashboard' }));
            navigate(`/Dashboard`, {});
            return;
        }
        if (menu.page === translation.myPage) {
            let mappingId = null,
                mappingCaption = null;
            serviceMenu.map((m) => {
                if (m.template_master_type === 'framework') {
                    (mappingId = m.menu_mapping_id), (mappingCaption = m.menu_mapping_caption);
                }
            });

            getmenuByServiceId(pagename, mappingId, mappingCaption);

            navigate(`/CompanyManagementInfo`, {});
            return;
        }

        const mappingId = menu.mappingId;
        let level1Idx = 0;
        let level2Idx = 0;
        let level3Idx = 0;
        let stop = 0;
        for (let i = 0; i < allMenuList.child.length; i++) {
            const level1MenuList = allMenuList.child[i];
            if (level1MenuList.mappingId === mappingId) {
                level1Idx = i;
                level2Idx = 0;
                level3Idx = 0;
                break;
            } else {
                for (let j = 0; j < level1MenuList.child.length; j++) {
                    const level2MenuList = level1MenuList.child[j];
                    if (level2MenuList.mappingId === mappingId) {
                        level1Idx = i;
                        level2Idx = j;
                        level3Idx = 0;
                        stop = 1;
                        break;
                    } else {
                        for (let k = 0; k < level2MenuList.child.length; k++) {
                            const level3MenuList = level2MenuList.child[k];
                            if (level3MenuList.mappingId === mappingId) {
                                level1Idx = i;
                                level2Idx = j;
                                level3Idx = k;
                                stop = 1;
                                break;
                            }
                            if (stop === 1) break;
                        }
                    }
                    if (stop === 1) break;
                }
            }
            if (stop === 1) break;
        }

        const level0Name = dropdownList[0]?.name;
        const level1List = allMenuList?.child;
        const level1Name = level1List[level1Idx]?.name;
        const level2List = level1List[level1Idx]?.child;
        const level2Name = level2List[level2Idx]?.name;
        const level3List = level2List[level2Idx]?.child;
        const level3Name = level3List[level3Idx]?.name;
        const level1InfoJson = getChildInfo(level1List);
        const level2InfoJson = getChildInfo(level2List);
        const level3InfoJson = getChildInfo(level3List);

        setDropdownList([
            { name: level0Name, child: level1InfoJson },
            { name: level1Name, child: level2InfoJson },
            { name: level2Name, child: level3InfoJson },
            { name: level3Name, child: [] },
        ]);
        //setNowMenu(level3Name);
        setNowMenu({ level2: level2InfoJson[level2Idx], level3: level3List[level3Idx] });
        setMenuMappingInfo(JSON.stringify({ id: level3InfoJson[level3Idx]?.mappingId, caption: { level1: level1Name, level2: level2Name, level3: level3Name }, path: level3InfoJson[level3Idx]?.link }));
        const nowProjectMenu = getMenuInfoByName(allMenuList.child, level1Name);
        setNowProjectMenuList(nowProjectMenu);

        e.currentTarget.closest('.dropdownMenu')?.classList.remove('show');
        document.querySelector('.view-all-menu')?.classList.remove('show');
        navigate(`/${level3InfoJson[level3Idx].link}`, {});
    };

    const toggleMenuIcon = (e) => {
        const clickedMenu = e.target.closest('.level2-name').parentNode;
        let hasOpen = clickedMenu.classList.contains('open');
        let childCount = clickedMenu.querySelector('.level3-wrapper').childElementCount;

        // close all other menu
        document.querySelectorAll('.level2.open').forEach((menu) => menu.classList.remove('open'));
        document.querySelectorAll('.level2-name div i').forEach((arrow) => {
            arrow.classList.remove('dx-icon-m-arrow-up');
            arrow.classList.add('dx-icon-m-arrow-down');
        });

        // if clicked menu have children and previous state is close then open the menu
        if (document.querySelectorAll('.sidebar-slim').length == 0 && !hasOpen && childCount != 0) {
            clickedMenu.classList.add('open');
            e.currentTarget.children[1].children[1].classList.remove('dx-icon-m-arrow-down');
            e.currentTarget.children[1].children[1].classList.add('dx-icon-m-arrow-up');
        }
    };

    return (
        <div className={`wrapper ${pagename.toLowerCase() === 'mainportal' ? 'main ' : ''}dx-swatch-ix-pds`}>
            {/*//Header */}
            {/*<Navigation allMenuList={allMenuList}*/}
            {/*  dropdownList={dropdownList}*/}
            {/*  clickMenu={clickMenu}*/}
            {/*  serviceMenu={serviceMenu}*/}
            {/*  nowMenu={nowMenu}*/}
            {/*  toggleMenuIcon={toggleMenuIcon}*/}
            {/*/>*/}
            {/*<Navigation allMenuList={allMenuList}*/}
            {/*  clickMenu={clickMenu}*/}
            {/*  serviceMenu={serviceMenu}*/}
            {/*/>*/}
            <LeftSideBarContextProvider refresh={init}>
                <ResizablePanel leftContent={<LeftSideBar />} rightContent={<Outlet />} allMenuList={allMenuList} clickMenu={clickMenu} nowProjectMenuList={nowProjectMenuList} nowMenu={nowMenu} toggleMenuIcon={toggleMenuIcon} serviceMenu={serviceMenu} />
            </LeftSideBarContextProvider>
            {/*//Footer */}
            {/*<Footer />*/}
        </div>
    );
};

export default Layout;
