import { makeTokenAxiosGetRequest } from "../utils/AxiosMask"

export const getUseHistoryByCompanyId = async (data) => {
  var res;
  const urlPath = `use_history/`;
  try {
    res = await makeTokenAxiosGetRequest(urlPath, data);
    return res.status === 200 ? res.data : "fail";
  } catch (error) {
    console.error('error', error);
  }
  return res.status === 200 ? res : "fail";
};