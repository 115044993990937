import React, { useEffect, useState } from 'react';
import { getUser, getIP, getMenuMappingInfo, getLanguage } from '../../apiInterface/utils/Common';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from 'devextreme-react/button';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import useTranslation from '../../components/customHooks/translations';
import { useSwal } from '../../components/common/Swal';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import { insertItemMaster, updateItemMaster, getItemMasterById, uploadAttachments } from '../../apiInterface/item/ItemMasterAPI';
import { TextboxField, SearchableField, DatePickerField, FileField, DropdownField } from '../../components/common/FormField';
import { useRef } from 'react';
import '../../contents/styles/item/item-master-form.css';
import { actionDirect, moveFocus, submit } from '../../components/common/FormFuncWithEnter';
import SelectProductGroupPopup from './SelectProductGroupPopup';
import { PageTitle } from '../../components/common/PageTitle.jsx';
import { BASE_URL } from '../../config';

export default function ItemMasterForm() {
    const previousPage = '/ItemMaster';
    const user = getUser();
    const ipAddress = getIP();
    const navigate = useNavigate();
    const errorHandler = useErrorHandler();
    const translation = useTranslation();
    const location = useLocation();
    const { state } = useLocation();
    const language = getLanguage();
    const [data, setData] = useState({});
    const [attachments, setAttachments] = useState([]);
    const { success } = useSwal();
    const popupRef = useRef();

    const useItems = [
        { value: true, title: translation.use },
        { value: false, title: translation.notUse },
    ];

    const onInsert = async () => {
        const attachments = await sendImages();
        const { id: mapping_id } = await getMenuMappingInfo();
        const queryData = {
            user_master_id: user.user_master_id,
            user_log_ip: ipAddress,
            user_menu_mapping_id: mapping_id,
            user_company_id: user.user_company_id,
            item_category_id: parseInt(sessionStorage.getItem('categoryId')),
            item_master_name: data.item_master_name,
            item_master_code: data.item_master_code,
            product_group_id: data.product_group_id,
            item_master_eo_no: data.item_master_eo_no,
            item_master_eo_date: data.item_master_eo_date,
            item_master_customer: data.item_master_customer,
            item_master_material: data.item_master_material,
            item_master_size: data.item_master_size,
            item_master_weight: data.item_master_weight,
            item_master_is_active: data.item_master_is_active,
            item_master_comment: data.item_master_comment,
            item_master_attachments: attachments,
        };

        const response = await errorHandler(insertItemMaster, queryData);
        if (response) {
            const cb = () => exitForm();
            success(translation.success_insert, cb);
        }
    };

    //need to fix
    const onUpdate = async () => {
        const attachments = await sendImages();

        const { id: mapping_id } = await getMenuMappingInfo();
        const queryData = {
            user_master_id: user.user_master_id,
            user_log_ip: ipAddress,
            user_menu_mapping_id: mapping_id,
            item_master_id: data.item_master_id,
            item_master_name: data.item_master_name,
            product_group_id: data.product_group_id,
            item_master_eo_date: data.item_master_eo_date,
            item_master_eo_no: data.item_master_eo_no,
            item_master_customer: data.item_master_customer,
            item_master_material: data.item_master_material,
            item_master_size: data.item_master_size,
            item_master_weight: data.item_master_weight,
            item_master_is_active: data.item_master_is_active,
            item_master_comment: data.item_master_comment,
            item_master_attachments: attachments,
        };

        const response = await errorHandler(updateItemMaster, queryData);
        if (response) {
            const cb = () => exitForm();
            success(translation.success_update, cb);
        }
    };

    //init
    useEffect(() => {
        const init = async () => {
            if (state.title == 'Edit') {
                getData();
            } else {
                //set default value of dropdowns
                valueChanged(useItems[0].value, 'item_master_is_active');
            }
        };
        init();
    }, []);

    const getData = async () => {
        setData({ item_master_id: state.rowData.item_master_id });
        const pathParam = state.rowData.item_master_id;
        const param = { sys_lang_code: language };

        const res = await errorHandler(getItemMasterById, pathParam, param);

        const parseCode = new RegExp('\\((.+)\\)');
        const parseName = new RegExp('(.+)\\(');

        if (res) {
            let result = res.data.o_data[0];
            result.item_master_id = pathParam;

            const code = result.product_group_name?.match(parseCode);
            const name = result.product_group_name?.match(parseName);

            if (code != null) result.product_group_code = code[1];
            if (name != null) result.product_group_name = name[1];

            let imgs = [];
            const json = result.item_master_attachments;
            if (json != null) {
                Object.keys(json).forEach((key) => {
                    const dataUrl = json[key];
                    imgs.push({
                        name: key,
                        data: dataUrl,
                    });
                });
                imgs.map((img) => {
                    img.data = `${BASE_URL}static_files/${img.data}`;
                    return img;
                });
                setAttachments(imgs);
            }
            setData(result);
        }
    };

    const valueChanged = (value, key) => {
        setData((cur) => {
            let newData = { ...cur };
            newData[key] = value;
            return newData;
        });
    };

    const exitForm = () => {
        navigate(previousPage, {
            state: {
                previousPage: location.pathname,
            },
        });
    };

    const sendImages = async () => {
        let body = new FormData();
        let json = {};
        let isEmpty = true;

        // console.log(attachments);
        // return;

        for (let i = 0; i < attachments.length; i++) {
            if (attachments[i].file) {
                body.append('files', attachments[i].file);
                isEmpty = false;
            } else {
                json[attachments[i].name] = attachments[i].data.replace(`${BASE_URL}static_files/`, '');
            }
        }

        const id = user.user_master_id;
        if (!isEmpty) {
            const res = await errorHandler(uploadAttachments, id, body);
            if (res) {
                for (let i = 0; i < res.file_name.length; i++) {
                    json[res.file_name[i]] = res.db_url[i];
                }
            }
        }

        const jsonString = JSON.stringify(json);
        return jsonString;
    };

    return (
        <div className="item-master-form">
            <div className="right-content-title">
                <PageTitle pageState={location.state.title} />
            </div>
            <form onSubmit={actionDirect}>
                <div className="right-content-body">
                    <div className="container">
                        <div className="dx-fieldset">
                            <TextboxField
                                column="item_master_name"
                                label={translation.item_master_name}
                                value={data?.item_master_name}
                                onValueChanged={(e) => valueChanged(e.value, 'item_master_name')}
                                isEssential={true}
                                esstialMsg={translation.item_master_name + translation.is_required}
                                onEnterKey={moveFocus}
                                inputAttr={{ focusidx: 0, autofocus: true }}
                            />

                            <TextboxField label={translation.item_master_code} value={data?.item_master_code} onValueChanged={(e) => valueChanged(e.value, 'item_master_code')} isEssential={true} esstialMsg={translation.item_master_code + translation.is_required} onEnterKey={moveFocus} inputAttr={{ focusidx: 1 }} />

                            {/* search item group */}
                            <SearchableField label={translation.product_group_code} isWritable={false} text={data?.product_group_code} onSearch={() => popupRef.current.open()} elementAttr={{ focusidx: 2 }} />

                            <TextboxField label={translation.product_group_name} value={data?.product_group_name} onEnterKey={moveFocus} onValueChanged={(e) => valueChanged(e.value, 'product_group_name')} inputAttr={{ focusidx: 3 }} readOnly={true} hasTextboxPopup={true} onSearch={() => popupRef.current.open()} />

                            <DropdownField label={translation.use_or_not} dataSource={useItems} value={data?.item_master_is_active} valueExpr="value" displayExpr="title" onValueChanged={(e) => valueChanged(e.value, 'item_master_is_active')} onEnterKey={moveFocus} inputAttr={{ focusidx: 4 }} isSearchable={false} />

                            <TextboxField label={translation.item_master_eo_no} value={data?.item_master_eo_no} onValueChanged={(e) => valueChanged(e.value, 'item_master_eo_no')} isEssential={false} onEnterKey={moveFocus} inputAttr={{ focusidx: 5 }} />

                            <DatePickerField
                                label={translation.item_master_eo_date}
                                value={data?.item_master_eo_date}
                                onValueChanged={async (_, val) => {
                                    await valueChanged(val, 'item_master_eo_date');
                                    document.querySelector('[focusidx="7"]').focus();
                                }}
                                valueFormat="MM/dd/yyyy"
                                pickerType="calendar"
                                valueType="date"
                                useMask={true}
                                inputAttr={{ focusidx: 6 }}
                            />

                            <TextboxField label={translation.item_master_customer} value={data?.item_master_customer} onValueChanged={(e) => valueChanged(e.value, 'item_master_customer')} isEssential={false} onEnterKey={moveFocus} inputAttr={{ focusidx: 7 }} />

                            <TextboxField label={translation.item_master_material} value={data?.item_master_material} onValueChanged={(e) => valueChanged(e.value, 'item_master_material')} isEssential={false} onEnterKey={moveFocus} inputAttr={{ focusidx: 8 }} />

                            <TextboxField label={translation.item_master_size} value={data?.item_master_size} onValueChanged={(e) => valueChanged(e.value, 'item_master_size')} isEssential={false} onEnterKey={moveFocus} inputAttr={{ focusidx: 9 }} />

                            <TextboxField label={translation.item_master_weight} value={data?.item_master_weight} onValueChanged={(e) => valueChanged(e.value, 'item_master_weight')} isEssential={false} onEnterKey={moveFocus} inputAttr={{ focusidx: 10 }} />

                            <TextboxField label={translation.item_master_comment} value={data?.item_master_comment} onValueChanged={(e) => valueChanged(e.value, 'item_master_comment')} isEssential={false} valueType="date" pickerType="calendar" onEnterKey={moveFocus} inputAttr={{ focusidx: 11 }} />
                        </div>
                        <div className="block"></div>

                        {console.log(attachments)}
                        <FileField label={translation.attachment} imageData={attachments} setImageData={setAttachments} />

                        <div className="block"></div>
                        {location.state.title == 'Edit' && (
                            <>
                                <div className="dx-fieldset write-info">
                                    <div className="dx-field">
                                        <div className="dx-field-label">{translation.lastModifiedDate}</div>
                                        <div className="dx-field-value">{data?.modified_date}</div>
                                    </div>
                                    <div className="dx-field">
                                        <div className="dx-field-label">{translation.lastModifiedBy}</div>
                                        <div className="dx-field-value">
                                            {data?.modified_by_name}
                                            {data?.modified_by_duty && <span className="badge badge-job-position">{data?.modified_by_duty}</span>}
                                            {data?.modified_by_department && <span className="badge badge-team">{data?.modified_by_department}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="block"></div>
                            </>
                        )}
                        <div>
                            <div className="btn-group dx-fieldset" style={{ width: '608px' }}>
                                <Button type="normal" stylingMode="text" className="btn-s-r" onClick={exitForm}>
                                    <div>
                                        <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                        <span className="dx-button-text">{translation.cancel}</span>
                                    </div>
                                </Button>
                                <Button
                                    id="submitBtn"
                                    type="default"
                                    stylingMode="contained"
                                    className="btn-s-r"
                                    useSubmitBehavior={true}
                                    onClick={(e) => {
                                        if (state.title === 'Add') submit(e, onInsert);
                                        else if (state.title === 'Edit') submit(e, onUpdate);
                                    }}
                                >
                                    <div>
                                        <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                        <span className="dx-button-text">{translation.save}</span>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            {/* search Popup */}
            <SelectProductGroupPopup
                ref={popupRef}
                onOk={(val) => {
                    valueChanged(val[0].product_group_id, 'product_group_id');
                    valueChanged(val[0].product_group_name_only, 'product_group_name');
                    valueChanged(val[0].product_group_code, 'product_group_code');
                }}
            />
        </div>
    );
}
