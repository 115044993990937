import React, { useState, useRef, useEffect } from "react";
import DataGrid, {
  Column, Editing, Paging, Lookup, RequiredRule, Toolbar, Item, Pager, Selection, MasterDetail, Texts, RowDragging, Scrolling
} from 'devextreme-react/data-grid';
import Button from 'devextreme-react/button';
import ContextMenu from 'devextreme-react/context-menu';
import { Link, useNavigate } from 'react-router-dom';
import { swalConfirmation } from "../../components/common/Swal";
import useTranslation from "../../components/customHooks/translations";


function MasterDetailGrid(props) {
  const [selectId, setSelectId] = useState([]);
  const [isEdit, disableEdit] = useState(true);
  const [isDelete, disableDelete] = useState(true);
  const [rowData, setRowData] = useState({});
  const gridRef = useRef();
  const getInstance = () => { return gridRef.current?.instance }
  const navigate = useNavigate();
  const translation = useTranslation();

  const masterDetailHeaders = props.detailDataHeaders;

  const cellRender = (isBadge, value, captionForBadge, classNameForBadge) => {
    if (isBadge === true)
      return (
        <div className="grid-badge">
          {value !== undefined && <span className={`${classNameForBadge[value]}`}>{captionForBadge[value]}</span>}
        </div>
      );
    else
      return (value);
  }

  const DetailSection = (props) => {
    const captionData = JSON.parse(props.data.caption_str);
    const codeToStr = { "en": "English", "ko": "Korean" };
    const detailDataSource = [];
    captionData?.forEach((d, i) => {
      detailDataSource.push({
        "NO": i + 1,
        "sys_division_sub_lang_code": codeToStr[d.sys_division_sub_lang_code],
        "sys_division_sub_caption_text": d.sys_division_sub_caption_text
      })
    })
    return (
      <DataGrid
        dataSource={detailDataSource}
        showBorders={true}
        columnAutoWidth={true}
        hoverStateEnabled={true}
      >
        {masterDetailHeaders?.map((value) => {
          return (
            <Column
              key={value.dataSource}
              dataField={value.dataSource}
              caption={value.caption}
              dataType={value.dataType}
              isEdit={value.isEdit}
              width={value.width}
              alignment={value.alignment}
            />
          )
        })}
      </DataGrid>
    );
  };


  //remove mutiple rows
  const removeRows = () => {
    const instance = getInstance();
    const data = instance.getSelectedRowsData();
    instance.confirmDeleteMessage = "";
    instance.getSelectedRowKeys().forEach(element => {
      let rowIndex = instance.getRowIndexByKey(element);
      instance.deleteRow(rowIndex);
      instance.refresh();
    });
  }

  const deleteConfirm = (selectedData) => {
    var iconType = "bad"
    var swalWidth = "380";
    var htmlString = `<span>${translation.confirm_delete}</span>`;
      var confirmationButtonText = translation.confirm;
      var reverseButtons = true;
    var cancelButtonText = translation.cancel;
    var swalResFn = (res) => {
      if (res.isConfirmed) {
        props.onMultipleDeleted(selectedData);
        removeRows();
      }
    };
      swalConfirmation(swalWidth, iconType, htmlString, confirmationButtonText, cancelButtonText, swalResFn, reverseButtons);
  }

  //list of context menu(right-click menu)
  const contextMenu = [
    { text: "Edit" },
    { text: "Delete (x)" }
  ];

  //selection change event when user clicked datagrid checkbox.
  //depends on number of checks toggle delete, edit button in tool box.
  const onSelectionChanged = (e) => {
    let rows = e.selectedRowKeys.length;

    if (props.canAccess === false) {
      disableEdit(true);
    } else if (rows != 1) {
      disableEdit(true);
    } else if (rows === 1){
      disableEdit(false);
      setRowData(getInstance().getSelectedRowsData()[0]);
    }

    if (props.canAccess === false) {
      disableDelete(true);
    } else if (rows >= 1) {
      disableDelete(false);
    } else if (rows < 1) {
      disableDelete(true);
    }

    const instance = getInstance();
    const data = instance.getSelectedRowsData();
    setSelectId(data);
  }

  const expandOneRow = (e) => {
    e.component.collapseAll(-1);
    e.component.expandRow(e.data);
  }

  //If row is double-clicked link to Edit page with clicked row info.
  const onRowDoubleClicked = (e) => {
    const data = e.key;
    props.canAccess === true && moveToEdit(data);
  }

  //automatically toggle checkbox when user clicked row in datagrid.
  const onRowClick = (e) => {
    const target = e.data;
    const instance = getInstance();
    const data = instance.getSelectedRowsData();

    if (data.find((element) => element === target) &&
      event.target.closest('td').classList[1] !== "dx-datagrid-group-space")
      instance.deselectRows([e.key]);
    else if (!(data.find((element) => element === target)) &&
      event.target.closest('td').classList[1] !== "dx-datagrid-group-space")   //when event target is 'expand or collapse' icon then don't select the row
      instance.selectRows([e.key], true);
    else if (event.target.closest('td').classList[1] === "dx-datagrid-group-space" &&
      event.target.closest('td').querySelector('div').classList[0].includes('opened'))
      e.component.collapseAll(-1);
    else if (event.target.closest('td').classList[1] === "dx-datagrid-group-space" &&
      event.target.closest('td').querySelector('div').classList[0].includes('closed'))
      expandOneRow(e);
  }

  //onClick event for context menu
  const onItemClick = (e) => {
    if (props.canAccess === true) {
      switch (e.itemData.text) {
        case "Edit":
          moveToEdit(rowData)
          break;
        case "Delete":
          break;
        default:
          throw "Unexpected Context Menu Item";
      }
    }
  }

  //get data of row which is invoked context menu
  const prepareContextMenu = (e) => {
    setRowData(e.row.data);
  }

  //link to edit page.
  const moveToEdit = (rowData) => {
    navigate(`/${props.link}`, {
      state: {
        title: "Edit",
        rowData: rowData,
        data: props.addData,
      }
    })
  }

  //link to add page.
  const moveToAdd = () => {
    navigate(`/${props.link}`, {
      state: {
        title: "Add",
        data: props.addData,
      }
    })
  }

  const onReorder = (e) => {
    const visibleRows = e.component.getVisibleRows();
    const dataSource = [...props.dataSource];
    const toIndex = dataSource.indexOf(visibleRows[e.toIndex].data);
    const fromIndex = dataSource.indexOf(e.itemData);

    dataSource.splice(fromIndex, 1);
    dataSource.splice(toIndex, 0, e.itemData);

    props.setDataSource(dataSource);

    const data = {
      subId: e.itemData.sys_division_sub_id,
      topId: toIndex === 0 ? 0 : dataSource[toIndex - 1].sys_division_sub_id
    }
    props.onReorder(data);
  }

  return (
    <>
      {
        props.canAccess === true &&
        <ContextMenu
        dataSource={contextMenu}
        target=".dx-data-row"
        onItemClick={onItemClick}
      />
      }
      <DataGrid id="grid-container"
        visible={props.visibleState === undefined ? true : props.visibleState}
        onContextMenuPreparing={prepareContextMenu}
        dataSource={props.dataSource}
        showBorders={true}
        onRowClick={onRowClick}
        onRowDblClick={onRowDoubleClicked}
        onSelectionChanged={(e) => {
          onSelectionChanged(e);
        }}
        ref={gridRef}
        allowDeleting={true}
        columnAutoWidth={true}
        hoverStateEnabled={true}
      >
        {
          props.canAccess === true &&
          <RowDragging
          allowReordering={true}
          onReorder={onReorder}
        />
        }
        {
          props.selectMode &&
          <Selection
            allowSelectAll={true}
            selectAllMode={'page'}
            mode={props.selectMode}
          />
        }
        {
          props.isEdit &&
          <Editing
            mode="row"
            allowUpdating={false}
            allowDeleting={false}
            allowAdding={false}
            allowColumnReordering={true}
            allowColumnResizing={true}
            confirmDelete={false}
          >
            <Texts confirmDeleteMessage="" />
          </Editing>
        }
        {props?.headers?.map((value) => {
          const validation = value.isRequired && <RequiredRule />
          return (
            <Column
              key={value.dataSource}
              dataField={value.dataSource}
              caption={value.caption}
              dataType={value.dataType}
              allowEditing={value.isEdit}
              width={value.width}
              alignment={value.alignment}
              cellRender={e => cellRender(value.isBadge, e.value, value.captionForBadge, value.classNameForBadge)}
            >{validation}</Column>
          )
        })}

        <MasterDetail
          enabled={true}
          autoExpandAll={false}
          render={DetailSection}
        />

        <Toolbar>
          <Item>
            <Button
              icon="trash"
              className="btn-s-r btn-hover-red"
              type="normal"
              stylingMode="text"
              disabled={isDelete}
              onClick={() => {
                deleteConfirm(selectId);
              }}
            />
          </Item>
          <Item>
            <Button
              disabled={isEdit}
              className="btn-s-r"
              type="normal"
              stylingMode="contained"
              text={translation.edit}
              icon="edit"
              width={82}
              onClick={() => moveToEdit(rowData)}
            />
          </Item>
          <Item>
            <Button
              disabled={!props.canAccess}
              type="default"
              stylingMode="contained"
              className="btn-s-r"
              text={translation.add}
              icon="add"
              width={80}
              onClick={() => moveToAdd()}
            />
            {/*<Link to={{ pathname: `/${props.link}` }} state={{*/}
            {/*  title: "Add", data: props.addData*/}
            {/*}}>*/}
            {/*</Link>*/}
          </Item>
        </Toolbar>
        {
          props.isPaging &&
          <Paging defaultPageSize={props.defaultPageSize} />
        }
        <Pager visible={props.pagerVisible} />
        {
          props.isScrolling &&
          <Scrolling mode="virtual" rowRenderingMode="virtual" />
        }
      </DataGrid>
    </>
  );
}

export default MasterDetailGrid;