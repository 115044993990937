import React, { useEffect, useState, useRef } from 'react';
import Button from 'devextreme-react/button';
import TextBox from 'devextreme-react/text-box';
import { Validator, RequiredRule, CustomRule } from 'devextreme-react/validator';
import { getLanguage, getUser, getIP, getMenuMappingInfo } from '../../apiInterface/utils/Common';
import { swal, useSwal } from '../../components/common/Swal';
import FileUploader from 'devextreme-react/file-uploader';
import useTranslation from '../../components/customHooks/translations';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { useLocation, useNavigate } from 'react-router-dom';
import { SelectBox } from 'devextreme-react/select-box';
import CustomTreeList from '../../components/common/TreeList';
import CustomStore from 'devextreme/data/custom_store';
import { Popup } from 'devextreme-react/popup';
import { TextboxField, SearchableField, DatePickerField, FileField, DropdownField } from '../../components/common/FormField';
import { getOperationFactory, insertMoldInfoByCompanyId, getMachineMoldInfoByMachineId, updateMoldInfoByCompanyId, uploadMoldImage, getItemCategoryInfo, getItemMasterInfo, saveItemInfo } from '../../apiInterface/machine/MoldAPI';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import BaseDataGrid from '../../components/common/BaseDataGrid';
import '../../contents/styles/mold/mold-form.css';
import { actionDirect, moveFocus, submit } from '../../components/common/FormFuncWithEnter';
import MoldGrid from './MoldGrid';
import { PageTitle } from '../../components/common/PageTitle.jsx';
import { ScrollView } from 'devextreme-react';
import { BASE_URL } from '../../config';

const prevPage = '/Mold';
const moldForm = () => {
    const errorHandler = useErrorHandler();
    const translation = useTranslation();
    const [factoryList, setFactoryList] = useState([]);
    const [isReadOnly, setReadOnly] = useState(false);
    const [isBlankPopupVisible, setBlankPopupVisibility] = useState(false);
    const [isTextPopupVisible, setTextPopupVisibility] = useState(false);
    const language = getLanguage();
    const [images, setImages] = useState([]);
    const [data, setData] = useState({});
    const location = useLocation();
    const title = location.state.title;
    const navigate = useNavigate();
    const { success, fail } = useSwal();
    const { state } = useLocation();
    const [itemDataSource, setItemDataSource] = useState([]);

    const valueChanged = (value, key) => {
        setData((cur) => {
            let newData = { ...cur };
            newData[key] = value;
            return newData;
        });
    };

    const useItems = [
        { value: true, title: translation.use },
        { value: false, title: translation.notUse },
    ];

    //select item popup(blank)
    const renderBlankPopup = () => {
        return (
            <SearchItemByCategory
                setValue={(val) => {
                    if (val != null) {
                        valueChanged(val.item_code, 'item_master_code');
                        valueChanged(val.item_name, 'item_master_name');
                        valueChanged(val.item_master_id, 'item_master_id');
                    }
                }}
                setVisible={setBlankPopupVisibility}
            />
        );
    };

    //select item popup(text)
    const renderTextPopup = () => {
        return <SearchItemByText />;
    };

    const openTextPopup = () => {
        setTextPopupVisibility(!isTextPopupVisible);
    };

    useEffect(() => {
        const init = async () => {
            await getFactoryTypeDD();

            if (state.title == 'Edit') {
                await getData();
                setReadOnly(true);
            } else {
                valueChanged(state.operation_factory_id, 'operation_factory_id');
                valueChanged(true, 'machine_mold_is_active');
            }
        };
        init();
    }, []);

    const getFactoryTypeDD = async () => {
        const userInfo = getUser();
        const param = { user_company_id: userInfo.user_company_id };

        const res = await errorHandler(getOperationFactory, param);
        if (res) {
            setFactoryList(res.data.o_data);
            if (res.data.o_data.length > 0) {
                valueChanged(res.data.o_data[0].operation_factory_id, 'operation_factory_id');
            }
        }
    };

    const getData = async () => {
        const queryData = {
            machine_mold_id: state.rowData.machine_mold_id,
            sys_lang_code: language,
        };
        const res = await errorHandler(getMachineMoldInfoByMachineId, queryData);
        if (res) {
            const moldData = res.data.o_data[0];
            let resImg = [];
            if (moldData.machine_mold_attachments) {
                const imageJson = moldData.machine_mold_attachments;
                Object.keys(imageJson).forEach((key) => {
                    resImg.push({
                        name: key,
                        data: imageJson[key],
                        file: null,
                    });
                });
            }
            resImg.map((img) => {
                img.data = `${BASE_URL}static_files/${img.data}`;
                return img;
            });
            setImages(resImg);
            setData(moldData);
            setItemDataSource(JSON.parse(moldData.item_str));
            valueChanged(state.rowData.machine_mold_id, 'machine_mold_id');
        }
    };

    const insertMoldInfo = async () => {
        const uploadImages = await uploadImage();
        const userInfo = getUser();
        const ipAddress = getIP();
        const { id: menuMappingId } = await getMenuMappingInfo();
        const queryData = {
            operation_factory_id: data.operation_factory_id,
            user_company_id: userInfo.user_company_id,
            item_master_id: data.item_master_id,
            machine_mold_code: data.machine_mold_code,
            machine_mold_name: data.machine_mold_name,
            machine_mold_no: data.machine_mold_no,
            machine_mold_org: data.machine_mold_org,
            machine_mold_is_active: data.machine_mold_is_active,
            machine_mold_attachments: uploadImages,
            machine_mold_comment: data.machine_mold_comment,
            user_master_email: userInfo.user_master_email,
            user_log_ip: ipAddress,
            user_master_id: userInfo.user_master_id,
            user_menu_mapping_id: menuMappingId,
        };
        const res = await errorHandler(insertMoldInfoByCompanyId, queryData);
        if (res) {
            await insertItem(res.data.o_data[0].machine_mold_id);
            var swalResFn = (response) => {
                navigate(prevPage);
            };
            success(`<p>${translation.save_successfully}</p>`, swalResFn);
        }
    };

    const insertItem = async (moldId) => {
        itemDataSource?.forEach(async (arr, idx) => {
            const param = {
                machine_mold_id: moldId,
                item_master_id: arr.item_master_id,
            };
            const res = await errorHandler(saveItemInfo, param);
            return res;
        });
    };

    const updateMoldInfo = async () => {
        const uploadImages = await uploadImage();
        const userInfo = getUser();
        const ipAddress = getIP();
        const { id: menuMappingId } = await getMenuMappingInfo();
        const queryData = {
            user_master_id: userInfo.user_master_id,
            user_company_id: userInfo.user_company_id,
            machine_mold_id: data.machine_mold_id,
            operation_factory_id: data.operation_factory_id,
            machine_mold_no: data.machine_mold_no,
            machine_mold_code: data.machine_mold_code,
            machine_mold_name: data.machine_mold_name,
            machine_mold_org: data.machine_mold_org,
            machine_mold_is_active: data.machine_mold_is_active,
            machine_mold_attachments: uploadImages,
            machine_mold_comment: data.machine_mold_comment,
            item_master_id: data.item_master_id,
            user_master_email: userInfo.user_master_email,
            user_log_ip: ipAddress,
            user_menu_mapping_id: menuMappingId,
        };
        const res = await errorHandler(updateMoldInfoByCompanyId, queryData);
        if (res) {
            await insertItem(data.machine_mold_id);
            var swalResFn = (response) => {
                navigate(prevPage);
            };
            success(`<p>${translation.save_successfully}</p>`, swalResFn);
        }
    };

    //uploading mold images
    const uploadImage = async () => {
        const userInfo = getUser();
        const data = { user_id: userInfo.user_master_id };
        const bodyFormData = new FormData();
        let imageJson = {};
        let isEmpty = true;

        images.forEach((img) => {
            if (img.file != null) {
                bodyFormData.append('files', img.file);
                isEmpty = false;
            } else {
                imageJson[img.name] = img.data;
            }
        });

        if (!isEmpty) {
            const res = await errorHandler(uploadMoldImage, data, bodyFormData);
            if (res) {
                for (let i = 0; i < res.file_name.length; i++) {
                    imageJson[res.file_name[i]] = res.db_url[i];
                }
            }
        }

        const imageString = JSON.stringify(imageJson);
        return imageString;
    };
    const changedItem = (moldData) => {
        setItemDataSource(moldData);
    };
    return (
        <>
            <div className="mold-form">
                <div className="right-content-title">
                    <PageTitle pageState={title} />
                </div>
                <form onSubmit={actionDirect}>
                    <div className="right-content-body">
                        <div className="container">
                            <div className="dx-fieldset">
                                <DropdownField
                                    label={translation.operation_factory_name}
                                    isEssential={true}
                                    esstialMsg={translation.operation_factory_name + translation.is_required}
                                    dataSource={factoryList}
                                    value={data?.operation_factory_id}
                                    valueExpr="operation_factory_id"
                                    displayExpr="operation_factory_name"
                                    onValueChanged={(e) => valueChanged(e.value, 'operation_factory_id')}
                                    onEnterKey={moveFocus}
                                    inputAttr={{ focusidx: 0 }}
                                />

                                <TextboxField
                                    label={translation.machine_mold_code}
                                    value={data?.machine_mold_code}
                                    isEssential={true}
                                    esstialMsg={translation.machine_mold_code + translation.is_required}
                                    onEnterKey={moveFocus}
                                    readOnly={isReadOnly}
                                    onValueChanged={(e) => valueChanged(e.value, 'machine_mold_code')}
                                    inputAttr={{ focusidx: 1 }}
                                />
                                <TextboxField
                                    label={translation.machine_mold_name}
                                    value={data?.machine_mold_name}
                                    isEssential={true}
                                    esstialMsg={translation.machine_mold_name + translation.is_required}
                                    onEnterKey={moveFocus}
                                    onValueChanged={(e) => valueChanged(e.value, 'machine_mold_name')}
                                    inputAttr={{ focusidx: 2 }}
                                />

                                <TextboxField label={translation.machine_mold_no} value={data?.machine_mold_no} isEssential={true} esstialMsg={translation.machine_mold_no + translation.is_required} onEnterKey={moveFocus} onValueChanged={(e) => valueChanged(e.value, 'machine_mold_no')} inputAttr={{ focusidx: 3 }} />

                                {/* search item group */}
                                {/*<SearchableField*/}
                                {/*    label={translation.item_master_code}*/}
                                {/*    isWritable={false}*/}
                                {/*    isEssential={true}*/}
                                {/*    esstialMsg={translation.item_master_code + translation.is_required}*/}
                                {/*    text={data?.item_master_code}*/}
                                {/*    onSearch={() => setBlankPopupVisibility(true)}*/}
                                {/*    elementAttr={{ focusidx: 2 }}*/}
                                {/*    hasTextboxPopup={true}*/}
                                {/*/>*/}

                                {/*<TextboxField*/}
                                {/*    label={translation.item_master_name}*/}
                                {/*    value={data?.item_master_name}*/}
                                {/*    isEssential={true}*/}
                                {/*    esstialMsg={translation.item_master_name + translation.is_required}*/}
                                {/*    onEnterKey={moveFocus}*/}
                                {/*    onValueChanged={(e) => valueChanged(e.value, "item_master_name")}*/}
                                {/*    inputAttr={{ focusidx: 3 }}*/}
                                {/*    readOnly={true}*/}
                                {/*    hasTextboxPopup={true}*/}
                                {/*    onSearch={() => setBlankPopupVisibility(true)}*/}
                                {/*/>*/}

                                <TextboxField label={translation.machine_mold_org} value={data?.machine_mold_org} onEnterKey={moveFocus} onValueChanged={(e) => valueChanged(e.value, 'machine_mold_org')} inputAttr={{ focusidx: 3 }} />

                                <DropdownField
                                    label={translation.use_or_not}
                                    dataSource={useItems}
                                    value={data?.machine_mold_is_active}
                                    valueExpr="value"
                                    displayExpr="title"
                                    onValueChanged={(e) => valueChanged(e.value, 'machine_mold_is_active')}
                                    isEssential={true}
                                    esstialMsg={translation.machine_mold_is_active + translation.is_required}
                                    onEnterKey={moveFocus}
                                    inputAttr={{ focusidx: 3 }}
                                    isSearchable={false}
                                />

                                <TextboxField label={translation.machine_mold_comment} value={data?.machine_mold_comment} onEnterKey={moveFocus} onValueChanged={(e) => valueChanged(e.value, 'machine_mold_comment')} inputAttr={{ focusidx: 3 }} />
                            </div>

                            <div className="block"></div>
                            <MoldGrid width={668} onDataChange={changedItem} dataSource={itemDataSource} setDataSource={setItemDataSource} />
                            <div className="block"></div>

                            <FileField label={translation.machine_mold_images} imageData={images} setImageData={setImages} />

                            <div className="block"></div>
                            {location.state.title == 'Edit' && (
                                <>
                                    <div className="dx-fieldset write-info">
                                        <div className="dx-field">
                                            <div className="dx-field-label">{translation.lastModifiedDate}</div>
                                            <div className="dx-field-value">{data?.modified_date}</div>
                                        </div>
                                        <div className="dx-field">
                                            <div className="dx-field-label">{translation.lastModifiedBy}</div>
                                            <div className="dx-field-value">
                                                {data?.modified_by_name}
                                                {data?.modified_by_duty && <span className="badge badge-job-position">{data?.modified_by_duty}</span>}
                                                {data?.modified_by_department && <span className="badge badge-team">{data?.modified_by_department}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="block"></div>
                                </>
                            )}
                            <div className="btn-group dx-fieldset" style={{ width: '708px' }}>
                                <Button
                                    type="normal"
                                    stylingMode="text"
                                    className="btn-s-r"
                                    onClick={() => {
                                        navigate(prevPage);
                                    }}
                                >
                                    <div>
                                        <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                        <span className="dx-button-text">{translation.cancel}</span>
                                    </div>
                                </Button>
                                <Button
                                    id="submitBtn"
                                    type="default"
                                    stylingMode="contained"
                                    className="btn-s-r"
                                    useSubmitBehavior={true}
                                    onClick={(e) => {
                                        if (title === 'Add') submit(e, insertMoldInfo);
                                        else if (title === 'Edit') submit(e, updateMoldInfo);
                                    }}
                                >
                                    <div>
                                        <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                        <span className="dx-button-text">{translation.save}</span>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <Popup width={1100} height={850} visible={isBlankPopupVisible} hideOnOutsideClick={true} onHiding={() => setBlankPopupVisibility(false)} contentRender={renderBlankPopup} dragEnabled={true} position="center" showTitle={false}></Popup>
            <Popup width={1100} height={850} visible={isTextPopupVisible} hideOnOutsideClick={true} onHiding={openTextPopup} contentRender={renderTextPopup} dragEnabled={true} position="center" showTitle={false}></Popup>
        </>
    );
};

export default moldForm;

//const SearchItemByCategory = (props) => {
//    const user = getUser();
//    const language = getLanguage();
//    const { setVisible, setValue } = props;
//    const translation = useTranslation();
//    const errorHandler = useErrorHandler();
//    const [selectedItems, setSelectedItems] = useState([]);
//    const [itemId, setItemId] = useState(null);
//    const [itemName, setItemName] = useState(null);
//    const [dataSource, setDataSource] = useState([]);
//    const [customDataSource, setCustomDataSource] = useState([]);
//    const init = useEffect(() => {
//        getItemCategory();
//    }, []);

//    const getItemCategory = async () => {
//        const user = getUser();
//        const { id: menuMappingId } = await getMenuMappingInfo();
//        const queryData = {
//            user_company_id: user.user_company_id,
//            menu_mapping_id: menuMappingId,
//            user_master_id: user.user_master_id
//        }

//        const res = await errorHandler(getItemCategoryInfo, queryData);
//        if (res) {
//            const itemData = res.data.o_data;
//            setDataSource(itemData);
//        }
//    }

//    const onItemSelectedChanged = (e) => {
//        const selectedItem = e.selectedRowsData[0].item_category_id;
//        getItemMaster(selectedItem);
//    }

//    const getItemMaster = (selectedItem) => {
//        const customStore = new CustomStore({
//            load: async (loadOptions) => {
//                const user = getUser();
//                const param = {
//                    user_company_id: user.user_company_id,
//                    item_category_id: selectedItem,
//                    search_text: "",
//                }

//                const res = await errorHandler(getItemMasterInfo, param);
//                if (res) {
//                    const itemMasterInfo = res.data.o_data;
//                    const pagingDict = {
//                        data: itemMasterInfo,
//                        totalCount: res.data.count
//                    }
//                    return pagingDict;
//                }
//                return {
//                    data: [],
//                    totalCount: 0,
//                }
//            }
//        });
//        setCustomDataSource(customStore);
//    }

//    const close = (e) => {
//        setVisible(false);
//    }

//    const onClickOk = (e) => {
//        setValue(selectedItems);
//        close(e);
//    }

//    const onSelect = (e) => {
//        setSelectedItems(e);
//        setItemId(e[0].item_master_id);
//        setItemName(e[0].item_name);

//    }

//    const itemHeaderDict = [
//        { dataField: "item_category_id", caption: translation.item_category_id, dataType: "number", visible: false },
//        { dataField: "item_category_name", caption: translation.item_category_name, dataType: "string" }
//    ];
//    const itemMasterHeaderDict = [
//        { dataField: "item_master_id", caption: translation.item_master_id, dataType: "number", width: 60 },
//        { dataField: "item_name", caption: translation.item_master_name, dataType: "string", width: 120 },
//        { dataField: "item_code", caption: translation.item_master_code, dataType: "string", width: 100 },
//        { dataField: "product_group", caption: translation.product_group, dataType: "string", width: 100 },
//        { dataField: "item_master_eo_no", caption: translation.item_master_eo_no, dataType: "string", width: 100 },
//        { dataField: "item_master_eo_date", caption: translation.item_master_eo_date, dataType: "string", width: 100 },
//        { dataField: "item_master_customer", caption: translation.item_master_customer, dataType: "string", width: 100 },
//        { dataField: "item_master_material", caption: translation.item_master_material, dataType: "string", width: 100 },
//        { dataField: "item_master_size", caption: translation.item_master_size, dataType: "string", width: 100 },
//        { dataField: "item_master_weight", caption: translation.item_master_weight, dataType: "string", width: 100 }
//    ];

//    return (
//        <div className="popup-select-item">
//            <div className="popup-header">
//                <p className="popup-title">{translation.select_items}</p>
//            </div>
//            <div className="popup-body">
//                <div className="selected-item-viewer">
//                    <div className="icon-checked">
//                        <i></i>
//                    </div>
//                    <div className="selected-item-field">
//                        {selectedItems &&
//                            <>
//                                <ul className="selected-items">
//                                    <li className="item">
//                                        <p className="item-name">{selectedItems?.item_name}</p>
//                                        <i className="material-icons btn-delete">cancel</i>
//                                    </li>
//                                </ul>
//                                <div className="btn-all-delete">
//                                    <button>Delete All</button>
//                                </div>
//                            </>
//                        }
//                        {!selectedItems &&
//                            <p className="comment-not-selected">{translation.user_master_is_select_department}</p>
//                        }
//                    </div>
//                </div>
//                <div className="item-select-box">
//                    <div className="left-panel">
//                        <ScrollView>
//                            <CustomTreeList
//                                width={252}
//                                dataSource={dataSource}
//                                id="item_category_id"
//                                name="item_category_name"
//                                parentId="item_category_parent_id"
//                                headers={itemHeaderDict}
//                                selectMode="single"
//                                isSelect={true}
//                                onSelectionChanged={onItemSelectedChanged}
//                            />
//                        </ScrollView>
//                    </div>
//                    <ScrollView
//                        width={806}
//                    >
//                        <div className="container-wrapper">
//                            <div className="container">
//                                <BaseDataGrid
//                                    width="100%"
//                                    isPaging={false}
//                                    dataSource={customDataSource}
//                                    headers={itemMasterHeaderDict}
//                                    allowModify={false}
//                                    selectMode="single"
//                                    onSelectionChanged={onSelect}
//                                />
//                            </div>
//                        </div>
//                    </ScrollView>
//                </div>
//            </div >
//            <div className="popup-footer">
//                <Button
//                    width={86}
//                    text={translation.cancel}
//                    type="normal"
//                    stylingMode="text"
//                    className="btn-s-r"
//                    onClick={close}
//                />
//                <Button
//                    text={translation.confirm}
//                    width={86}
//                    type="default"
//                    stylingMode="contained"
//                    className="btn-s-r"
//                    onClick={(e) => {
//                        document.querySelector('[focusidx="3"]').click();
//                        onClickOk(e);
//                    }}
//                />
//            </div>
//        </div >
//    )
//}

//const SearchItemByText = (props) => {
//    const user = getUser();
//    const language = getLanguage();
//    const translation = useTranslation();
//    const [searchTxt, setSearchTxt] = useState(null);
//    const [itemId, setItemId] = useState(null);
//    const [itemName, setItemName] = useState(null);

//    const onClickSearch = () => {
//        ;
//    }
//    return (
//        <div className="popup-select-item">
//            <div className="popup-header">
//                <p className="popup-title">{translation.select_items}</p>
//            </div>
//            <div className="popup-body">
//                <div className="dx-fieldset">
//                    <div className="dx-field column">
//                        <div className="dx-field-label">{translation.search}</div>
//                        <div className="dx-field-value">
//                            <div className="search">
//                                <TextBox
//                                    className="ix-tfs-r"
//                                    width={440}
//                                    onValueChanged={(e) => {
//                                        setSearchTxt(e.value);
//                                    }}
//                                    value={searchTxt}
//                                >
//                                </TextBox>
//                                <Button
//                                    type="default"
//                                    stylingMode="contained"
//                                    icon="search"
//                                    className="btn-s-r dx-button-mode-es"
//                                    width={40}
//                                    onClick={onClickSearch}
//                                />
//                            </div>
//                        </div>
//                    </div>
//                </div>
//                <div>
//                    <div className="selected-item"><span>{translation.selected}: </span><span>{itemName}{itemId ? "(" : ""}{itemId}{itemName}{itemId ? ")" : ""}</span></div>
//                    <div className="datagrid">
//                        {/*input Datagrid*/}
//                    </div>
//                </div >
//            </div >
//            <div className="popup-footer">
//                <Button
//                    width={86}
//                    text={translation.cancel}
//                    type="normal"
//                    stylingMode="text"
//                    className="btn-s-r"
//                    onClick={() => setTextPopupVisibility(false)}
//                />
//                <Button
//                    text={translation.confirm}
//                    width={86}
//                    type="default"
//                    stylingMode="contained"
//                    className="btn-s-r"
//                    onClick={(e) => {
//                        setTextPopupVisibility(false);
//                        checkItemState();
//                        document.querySelector('[focusidx="6"]').focus();
//                    }}
//                />
//            </div>
//        </div >
//    )
//}
