import {
    makeTokenAxiosGetRequest, makeTokenAxiosPostRequest,
    makeTokenAxiosPutRequest, makeTokenAxiosDeleteRequest
} from "../utils/AxiosMask"

export const getSecurityBusinessByCompanyId = async (data) => {
    var res;
    const urlPath = `security_master/get_business_dd/`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};

export const getSecurityMainMaster = async (data) => {
    var res;
    const urlPath = `security_master/`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};

export const insertSecurityMasterSetting = async (data) => {
    var res;
    const urlPath = `security_master/insert_security_master_setting?security_master_id=${data.security_master_id}&target_table=${data.target_table}&target_id=${data.target_id}`;
    try {
        res = await makeTokenAxiosPostRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};

export const insertSecurityMasterInfoByCompanyId = async (data) => {
    var res;
    const urlPath = `security_master/`;
    try {
        res = await makeTokenAxiosPostRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};

export const updateSecurityMasterByCompanyId = async (data) => {
    var res;
    const urlPath = `security_master/`;
    try {
        res = await makeTokenAxiosPutRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};

export const deleteSecurityMaster = async (data) => {
    var res;
    const urlPath = `security_master/`;
    try {
        res = await makeTokenAxiosDeleteRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};

export const getDepartmentDt = async (data) => {
    var res;
    const urlPath = `security_master/get_user_department_dt/`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};

export const getUserInfoByDepartmentId = async (data) => {
    var res;
    const urlPath = `security_master/get_user_master_dt/`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};


export const getSecurityMasterInfoById = async (data) => {
    var res;
    const urlPath = `security_master/get_security_master_by_id/`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};

export const getSecurityMasterUserInfoById = async (data) => {
    var res;
    const urlPath = `security_master/get_security_setting_dt/`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};


export const getSecurityMasterSelectedUserInfoById = async (data) => {
    var res;
    const urlPath = `security_master/get_security_setting_selected/`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};

export const getUserDTInfoBySearch = async (data) => {
    var res;
    const urlPath = `security_master/get_valid_user_and_department_dt/`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};