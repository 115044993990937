// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.process .right-content-body,
.line .right-content-body {
    padding: 0px;
}
.process .sash-module_sash__K-9lB:before,
.line .sash-module_sash__K-9lB:before,
.equipment .sash-module_sash__K-9lB:before,
.mold .sash-module_sash__K-9lB:before {
    background: var(--border) !important;
}
.process .sash-module_sash__K-9lB:hover:before,
.line .sash-module_sash__K-9lB:hover:before,
.equipment .sash-module_sash__K-9lB:hover:before,
.mold .sash-module_sash__K-9lB:hover:before {
    background: var(--primary-500) !important;
}
.process .right-content-body .right-panel .panel-body,
.line .right-content-body .right-panel .panel-body {
    padding: 16px;
}
.process .right-content-body .right-panel .container,
.line .right-content-body .right-panel .container {
    padding: 0px;
}
/*process form*/
.process .right-content-body {
    height: calc(100% - 44px);
    min-height: calc(100% - 44px);
}
.process-form .right-content-body .info-comment {
    margin-top: 16px;
}
.popup-add-equipment .popup-body .dx-fieldset {
    display: flex;
    gap: 20px;
    border-bottom: 1px solid var(--border);
}
.popup-add-equipment .popup-body .selected-item span {
    color: var(--primary-500);
}
.popup-add-equipment .popup-body .selected-item span:first-child{
    color: var(--inactive-text);
}
`, "",{"version":3,"sources":["webpack://./src/contents/styles/operationManagement/operation.css"],"names":[],"mappings":"AAAA;;IAEI,YAAY;AAChB;AACA;;;;IAII,oCAAoC;AACxC;AACA;;;;IAII,yCAAyC;AAC7C;AACA;;IAEI,aAAa;AACjB;AACA;;IAEI,YAAY;AAChB;AACA,eAAe;AACf;IACI,yBAAyB;IACzB,6BAA6B;AACjC;AACA;IACI,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,SAAS;IACT,sCAAsC;AAC1C;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,2BAA2B;AAC/B","sourcesContent":[".process .right-content-body,\n.line .right-content-body {\n    padding: 0px;\n}\n.process .sash-module_sash__K-9lB:before,\n.line .sash-module_sash__K-9lB:before,\n.equipment .sash-module_sash__K-9lB:before,\n.mold .sash-module_sash__K-9lB:before {\n    background: var(--border) !important;\n}\n.process .sash-module_sash__K-9lB:hover:before,\n.line .sash-module_sash__K-9lB:hover:before,\n.equipment .sash-module_sash__K-9lB:hover:before,\n.mold .sash-module_sash__K-9lB:hover:before {\n    background: var(--primary-500) !important;\n}\n.process .right-content-body .right-panel .panel-body,\n.line .right-content-body .right-panel .panel-body {\n    padding: 16px;\n}\n.process .right-content-body .right-panel .container,\n.line .right-content-body .right-panel .container {\n    padding: 0px;\n}\n/*process form*/\n.process .right-content-body {\n    height: calc(100% - 44px);\n    min-height: calc(100% - 44px);\n}\n.process-form .right-content-body .info-comment {\n    margin-top: 16px;\n}\n.popup-add-equipment .popup-body .dx-fieldset {\n    display: flex;\n    gap: 20px;\n    border-bottom: 1px solid var(--border);\n}\n.popup-add-equipment .popup-body .selected-item span {\n    color: var(--primary-500);\n}\n.popup-add-equipment .popup-body .selected-item span:first-child{\n    color: var(--inactive-text);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
