﻿import React, { useEffect, useRef, useState } from 'react';
import InlineDataGrid from '../../components/common/InlineDataGrid';
import { getToken, getUser, getIP, getMenuMappingInfo, getLanguage } from '../../apiInterface/utils/Common';
import { getBusinessIds, getFactoryInfo, insertFactoryInfo, updateFactoryInfo, deleteFactoryInfo, getFactoryById } from '../../apiInterface/factory/FactoryAPI';
import useTranslation from '../../components/customHooks/translations';
import { useLocation, useNavigate } from 'react-router-dom';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import BaseDataGrid from '../../components/common/BaseDataGrid';
import { DropdownField, FilterComponent } from '../../components/common/FormField';
import { PageDropdown } from '../../components/common/PageDropdown';
import Badge from '../../components/common/Badge';
import { PageTitle } from '../../components/common/PageTitle.jsx';
import { successToastr } from '../../components/common/Toastr';
import { useSwal } from '../../components/common/Swal.jsx';

let dataSource = [];
const Factory = () => {
    const translation = useTranslation();
    const activeNotActiveJson = { true: translation.active, false: translation.not_active };
    const activeNotActiveClassName = { true: 'grid-badge-use', false: 'grid-badge-disabled' };
    const [filtered, setFiltered] = useState([]);
    const [businessIds, setBusinessIds] = useState([]);
    const [curBusinessId, setCurBusinessId] = useState(null);
    const language = getLanguage();
    const location = useLocation();
    const searchText = useRef();
    const errorHandler = useErrorHandler();
    const navigate = useNavigate();
    const { info } = useSwal();

    const headerDict = [
        { dataField: 'operation_factory_no', caption: 'NO', dataType: 'number', isFixed: true, width: 100, alignment: 'center', isFixed: true, allowReordering: true },
        { dataField: 'operation_factory_code', caption: translation.operation_factory_code.toUpperCase(), dataType: 'string', isFixed: true, allowReordering: true },
        { dataField: 'operation_factory_name', caption: translation.operation_factory_name.toUpperCase(), dataType: 'string', isFixed: true, allowReordering: true },
        { dataField: 'user_business_name', caption: translation._business.toUpperCase(), dataType: 'string', allowReordering: true },
        {
            dataField: 'operation_factory_is_active',
            caption: translation.operation_factory_is_active.toUpperCase(),
            dataType: 'boolean',
            width: 130,
            alignment: 'center',
            allowReordering: true,
            cellRender: (e) => <Badge className={activeNotActiveClassName[e.value]} text={activeNotActiveJson[e.value]} />,
        },
        { dataField: 'operation_factory_comment', caption: translation.operation_factory_comment.toUpperCase(), dataType: 'string', allowReordering: true },
        { dataField: 'operation_factory_modified_by', caption: translation.lastModifiedBy.toUpperCase(), dataType: 'string', width: 300, allowReordering: true },
        { dataField: 'operation_factory_modified_date', caption: translation.lastModifiedDate.toUpperCase(), dataType: 'string', width: 150, allowReordering: true },
    ];

    const onValueChanged = async (e) => {
        sessionStorage.setItem('businessId', e.value);
        const user = getUser();
        let param = {
            user_company_id: user.user_company_id,
            sys_lang_code: language,
            user_business_id: e.value,
        };
        setCurBusinessId(e.value);

        const res = await errorHandler(getFactoryInfo, param);
        if (res) {
            dataSource = res.data.o_data;
            filtering(searchText.current);
        }
    };

    //init
    useEffect(() => {
        dataSource = [];
        const init = async () => {
            const user = getUser();
            let param = { user_company_id: user.user_company_id };
            const result = await errorHandler(getBusinessIds, param);
            if (result) {
                const data = result.data.o_data ? result.data.o_data : [];
                setBusinessIds(data);

                if (data.length === 0) {
                    var swalResFn = () => {
                        navigate('/BusinessManagementInfo');
                    };
                    info(`<p>${translation.after_business_add}</p>`, swalResFn);
                }
                //if previousPage is Form, set dropdown value to what was set before edit/add page is clicked.
                if (location.state?.previousPage == '/FactoryForm') {
                    setCurBusinessId(parseInt(sessionStorage.getItem('businessId')));
                } else {
                    let isBusinessExist = false;
                    if (user?.user_business_id) {
                        const branch = data.length !== 0 && data.find((branch) => branch?.user_business_id == user?.user_business_id);
                        if (branch) {
                            setCurBusinessId(user?.user_business_id);
                            isBusinessExist = true;
                        }
                    }

                    //if user info doesn't have business or server's response doesn't have user's business set first row as a default value.
                    if (!isBusinessExist) {
                        setCurBusinessId(data?.[0]?.user_business_id);
                    }
                }
            }
        };

        init();
    }, []);

    const onRemove = async (e) => {
        const { id: mapping_id } = await getMenuMappingInfo();
        const data = e.data;
        const user = getUser();
        const ipAddress = getIP();
        const queryData = {
            user_master_id: user.user_master_id,
            user_log_ip: ipAddress,
            user_menu_mapping_id: mapping_id,
            operation_factory_id: data.operation_factory_id,
        };

        const reponse = await errorHandler(deleteFactoryInfo, queryData);
        if (reponse) {
            successToastr(translation.success_delete_row);
            let index = dataSource.indexOf(data);
            if (index >= 0) {
                dataSource.splice(index, 1);
            }
        }
    };

    const businessDropdown = <DropdownField label={translation.select_business} value={curBusinessId} dataSource={businessIds} onValueChanged={onValueChanged} valueExpr="user_business_id" displayExpr="user_business_name" isSearchable={true} width={204} />;

    const filtering = (keyword) => {
        let result = [];

        if (!keyword) {
            result = [...dataSource];
        } else {
            for (let i = 0; i < dataSource.length; i++) {
                let isContainName = dataSource[i].operation_factory_name.toLowerCase().includes(keyword.toLowerCase());
                let isContainCode = dataSource[i].operation_factory_code.toLowerCase().includes(keyword.toLowerCase());
                if (isContainName || isContainCode) {
                    result.push(dataSource[i]);
                }
            }
        }

        setFiltered(result);
    };

    const gridFilter = (
        <FilterComponent
            label={translation.filter}
            width={204}
            onFilter={(keyword) => {
                searchText.current = keyword;
                filtering(keyword);
            }}
        />
    );

    return (
        <div className="factory">
            <div className="right-content-title">
                <PageTitle />
                <PageDropdown />
            </div>
            <div className="right-content-body">
                <div className="container">
                    <BaseDataGrid toolbarModules={[businessDropdown, gridFilter]} allowModify={true} dataSource={filtered} headers={headerDict} onRowRemove={onRemove} link="FactoryForm" selectMode="multiple" isFullSize={true} />
                </div>
            </div>
        </div>
    );
};

export default Factory;
