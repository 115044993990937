//test
import React, { useEffect, useState } from 'react';
import InlineDataGrid from '../../components/common/InlineDataGrid';
import { getToken, getUser, getIP, getLanguage } from '../../apiInterface/utils/Common';
import { getBusinessInfoByCompanyId, updateBusinessInfoByCompanyId, deleteBusinessInfoByCompanyId, insertBusinessInfoByCompanyId } from '../../apiInterface/businessManagement/BusinessManagementAPI';
import { swal } from '../../components/common/Swal';
import useTranslation from '../../components/customHooks/translations';

const ProductMaster = () => {
    const [dataSource, setDataSource] = useState([]);
    const translation = useTranslation();
    const language = getLanguage();
    const neededBusiness = () => {
        const businessDict = [
            { dataSource: 'user_business_id', caption: translation.user_business_id, dataType: 'number', isEdit: false },
            { dataSource: 'user_business_name', caption: translation.user_business_name, dataType: 'string', isRequired: true },
            { dataSource: 'user_business_zipcode', caption: translation.user_business_zipcode, dataType: 'string' },
            { dataSource: 'user_business_address', caption: translation.user_business_address, dataType: 'string' },
            { dataSource: 'user_business_address2', caption: translation.user_business_address2, dataType: 'string' },
            { dataSource: 'user_business_phone', caption: translation.user_business_phone, dataType: 'string' },
            { dataSource: 'user_business_is_active', caption: translation.user_business_is_active, dataType: 'boolean' },
            { dataSource: 'user_business_comment', caption: translation.user_business_comment, dataType: 'string' },
            { dataSource: 'user_business_modified_by', caption: translation.user_business_modified_by, dataType: 'number' },
            { dataSource: 'user_business_modified_date', caption: translation.user_business_modified_date, dataType: 'string' },
            { dataSource: 'user_business_created_by', caption: translation.user_business_created_by, dataType: 'number' },
            { dataSource: 'user_business_created_date', caption: translation.user_business_created_date, dataType: 'string' },
        ];
        return businessDict;
    };
    const [header, setHeader] = useState(neededBusiness());

    const getBusinessInfo = async () => {
        const user = getUser();
        const param = { user_company_id: user.user_company_id, sys_lang_code: language };
        try {
            const res = await getBusinessInfoByCompanyId(param);
            if (res !== 'fail' && res.message === 'MSG_100') {
                const businessInfo = res.data.o_data;
                setDataSource(businessInfo);
            } else {
                var iconType = 'bad';
                var swalWidth = '380';
                var htmlString = `<span>${translation[res.message]}</span>`;
                var confirmationButtonText = translation.confirm;
                var swalResFn = (response) => {};
                // swal(swalWidth, iconType, htmlString, confirmationButtonText, swalResFn);
                console.log(`${translation[res.message]}`);
            }
        } catch (err) {
            var iconType = 'bad';
            var swalWidth = '380';
            var htmlString = `<span>${user['user_master_email']}</span><p>${translation.request_error}</p>`;
            var confirmationButtonText = translation.confirm;
            var swalResFn = (res) => {};
            // swal(swalWidth, iconType, htmlString, confirmationButtonText, swalResFn);
            console.log(`${translation.request_error}`);
        } finally {
        }
    };

    useEffect(() => {
        getBusinessInfo();
    }, []);

    const updateBusinessInfo = async (updateData) => {
        const user = getUser();
        const ipAddress = getIP();
        updateData['user_company_id'] = user.user_company_id;
        updateData['user_master_id'] = user.user_master_id;
        updateData['user_master_email'] = user.user_master_email;
        updateData['user_log_ip'] = ipAddress;
        updateData['user_menu_mapping_id'] = 2244;
        updateData['user_business_modified_by'] = user.user_master_id;
        delete updateData['user_business_created_by'];
        delete updateData['user_business_created_date'];
        try {
            const res = await updateBusinessInfoByCompanyId(updateData);
            if (res !== 'fail' && res.message === 'MSG_100') {
                var iconType = 'good';
                var swalWidth = '380';
                var htmlString = `<span></span><p>${translation.confirm_signIn}</p>`;
                var confirmationButtonText = translation.confirm;
                var swalResFn = (response) => {};
                swal(swalWidth, iconType, htmlString, confirmationButtonText, swalResFn);
            } else {
                var iconType = 'bad';
                var swalWidth = '380';
                var htmlString = `<span>${translation[res.message]}</span>`;
                var confirmationButtonText = translation.confirm;
                var swalResFn = (response) => {};
                swal(swalWidth, iconType, htmlString, confirmationButtonText, swalResFn);
            }
        } catch (err) {
            var iconType = 'bad';
            var swalWidth = '380';
            var htmlString = `<span>${user['user_master_email']}</span><p>${translation.request_error}</p>`;
            var confirmationButtonText = translation.confirm;
            var swalResFn = (res) => {};
            swal(swalWidth, iconType, htmlString, confirmationButtonText, swalResFn);
        } finally {
        }
    };
    // Not working now.
    const deleteBusinessInfo = async (deleteData) => {
        const user = getUser();
        const ipAddress = getIP();
        const queryData = {
            user_master_id: user.user_master_id,
            user_log_ip: ipAddress,
            user_menu_mapping_id: 2244,
            user_business_id: deleteData['user_business_id'],
        };
        try {
            const res = await deleteBusinessInfoByCompanyId(queryData);
            if (res !== 'fail' && res.message === 'MSG_100') {
                var iconType = 'good';
                var swalWidth = '380';
                var htmlString = `<span></span><p>${translation.confirm_signIn}</p>`;
                var confirmationButtonText = translation.confirm;
                var swalResFn = (response) => {};
                swal(swalWidth, iconType, htmlString, confirmationButtonText, swalResFn);
            } else {
                var iconType = 'bad';
                var swalWidth = '380';
                var htmlString = `<span>${translation[res.message]}</span>`;
                var confirmationButtonText = translation.confirm;
                var swalResFn = (response) => {};
                swal(swalWidth, iconType, htmlString, confirmationButtonText, swalResFn);
            }
        } catch (err) {
            var iconType = 'bad';
            var swalWidth = '380';
            var htmlString = `<span>${user['user_master_email']}</span><p>${translation.request_error}</p>`;
            var confirmationButtonText = translation.confirm;
            var swalResFn = (res) => {};
            swal(swalWidth, iconType, htmlString, confirmationButtonText, swalResFn);
        } finally {
        }
    };

    const insertBusinessInfo = async (insertdata) => {
        const user = getUser();
        const ipAddress = getIP();
        const queryData = {
            user_company_id: user.user_company_id,
            user_business_name: insertdata.user_business_name,
            user_business_zipcode: insertdata.user_business_zipcode,
            user_business_address: insertdata.user_business_address,
            user_business_address2: insertdata.user_business_address2,
            user_business_phone: insertdata.user_business_phone,
            user_business_comment: insertdata.user_business_comment,
            user_master_email: user.user_master_email,
            user_log_ip: ipAddress,
            user_master_id: user.user_master_id,
            user_menu_mapping_id: 2244,
        };
        try {
            const res = await insertBusinessInfoByCompanyId(queryData);
            if (res !== 'fail' && res.message === 'MSG_100') {
                var iconType = 'good';
                var swalWidth = '380';
                var htmlString = `<span></span><p>${translation.confirm_signIn}</p>`;
                var confirmationButtonText = translation.confirm;
                var swalResFn = (response) => {};
                swal(swalWidth, iconType, htmlString, confirmationButtonText, swalResFn);
            } else {
                var iconType = 'bad';
                var swalWidth = '380';
                var htmlString = `<span>${translation[res.message]}</span>`;
                var confirmationButtonText = translation.confirm;
                var swalResFn = (response) => {};
                swal(swalWidth, iconType, htmlString, confirmationButtonText, swalResFn);
            }
        } catch (err) {
            var iconType = 'bad';
            var swalWidth = '380';
            var htmlString = `<span>${user['user_master_email']}</span><p>${translation.request_error}</p>`;
            var confirmationButtonText = translation.confirm;
            var swalResFn = (res) => {};
            swal(swalWidth, iconType, htmlString, confirmationButtonText, swalResFn);
        } finally {
        }
    };

    const onSaved = (data) => {
        const saveInfo = data.changes;
        saveInfo.map((value, idx) => {
            const type = value.type;
            if (type === 'update') updateBusinessInfo(value.data);
            else if (type === 'remove') deleteBusinessInfo(value.key);
            else if (type == 'insert') insertBusinessInfo(value.data);
        });
    };

    return (
        <div className="dx-swatch-ix-pds">
            <div className="right-content-title">
                <h3>Item</h3>
            </div>
            <div className="block block-title"></div>
            <div className="data-grid">
                <InlineDataGrid dataSource={dataSource} headers={header} onSaved={onSaved} />
            </div>
        </div>
    );
};

export default ProductMaster;
