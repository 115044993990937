import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import useTranslation from "../customHooks/translations";
import "../../contents/styles/common/ix-field.css";


const Search = (props, ref) => {
    const translation = useTranslation();
    const [active, setActive] = useState(false);
    const selectBoxRef = useRef();

    const {
        value,
        width = "200px",
        onValueChanged = () => { },
        onKeyUp = (e) => {
            if (e.key == "Enter")
                props.onClick(e.target.value);
        },
        onClick = (e) => props.onClick(e.target.value),

    } = props;

    useEffect(() => {
        document.addEventListener('mousedown', clickSelectBoxOutside);
        return () => document.removeEventListener('mousedown', clickSelectBoxOutside);
    }, []);

    const clickSelectBoxOutside = (e) => {
        if (!selectBoxRef.current.contains(e.target)) {
            setActive(false);
        } else {
            setActive(true);
        }
    }

    return (
        <div className="ix-field">
            <div className="field-label">
                <p>{translation.search}</p>
            </div>
            <div className="field-value" ref={selectBoxRef}>
                <div className={`search-field${active ? " active" : ""}`}>
                    <input
                        type="text"
                        style={{ "width": width }}
                        value={value}
                        onChange={onValueChanged}
                        onKeyUp={onKeyUp}
                    />
                    <button onClick={onClick}>
                        <i className="dx-icon-m-search"></i>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Search;