import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useTranslation from '../../../components/customHooks/translations';
import useErrorHandler from '../../../components/customHooks/useErrorHandler';
import { getLanguage, getUser, getMenuMappingInfo, getIP } from '../../../apiInterface/utils/Common';
import { Button, Popup, ScrollView } from 'devextreme-react';
import { SearchableField, TextboxField, DropdownField, FilterComponent, FileField } from '../../../components/common/FormField';
import '../../../contents/styles/badtype/inspection.css';
import { getSysDivisionCaptionByCode } from '../../../apiInterface/inspection/InspectionLibraryCondition';
import { useSwal } from '../../../components/common/Swal';

export default function MeasureConditionPopup(props) {
    const { visible, onClose = () => {}, onOk = () => {}, getDatas, setDatas, editData, setEditData, state } = props;

    const translation = useTranslation();
    const location = useLocation();
    const errorHandler = useErrorHandler();
    const user = getUser();
    const lang = getLanguage();
    const { fail } = useSwal();

    const [data, setData] = useState({});
    const [inspectionType, setInspectionType] = useState();
    const [directionType, setDirectionType] = useState();
    const [resultType, setResultType] = useState();

    // dropdown MEASURECONDITION
    const getMeasureCondition = async () => {
        let param = {
            user_company_id: user.user_company_id,
            sys_division_code: 'MEASURECONDITION',
            sys_lang_code: lang,
        };

        const res = await errorHandler(getSysDivisionCaptionByCode, param);
        if (res) {
            setInspectionType(res.data.o_data);
            valueChanged(res.data.o_data[0].sys_division_sub_value, 'inspection_library_condition');
        }
    };

    // dropdown MEASUREDIRECTION
    const getMeasureDirection = async () => {
        let param = {
            user_company_id: user.user_company_id,
            sys_division_code: 'MEASUREDIRECTION',
            sys_lang_code: lang,
        };

        const res = await errorHandler(getSysDivisionCaptionByCode, param);
        if (res) {
            setDirectionType(res.data.o_data);
            valueChanged(res.data.o_data[0].sys_division_sub_value, 'inspection_library_condition_direction');
        }
    };

    // dropdown MEASUREUNIT
    const getMeasureResult = async () => {
        let param = {
            user_company_id: user.user_company_id,
            sys_division_code: 'MEASUREUNIT',
            sys_lang_code: lang,
        };

        const res = await errorHandler(getSysDivisionCaptionByCode, param);
        if (res) {
            setResultType(res.data.o_data);
            valueChanged(res.data.o_data[0].sys_division_sub_value, 'inspection_library_condition_unit');
        }
    };

    const [isSave, setIsSave] = useState(false);
    const onClickOk = (e) => {
        const elem = document.getElementsByName('inspection_library_condition_value');
        if (data.inspection_library_condition_value !== undefined && data.inspection_library_condition_value !== '') {
            setIsSave(true);
            // savvvve();
        } else {
            setIsSave(false);
            elem[0].parentElement.parentElement.parentElement.classList.add('dx-invalid');
        }

        getDatas.map((m) => {
            if (m.inspection_library_condition === data.inspection_library_condition) {
                fail(`<p>등록된 조건입니다.</p>`);
                setIsSave(false);
            }
        });
    };

    const savvvve = () => {
        if (isSave) {
            if (editData !== undefined) {
                let edit = [];
                getDatas.map((m) => {
                    m.id === editData.id ? edit.push(data) : edit.push(m);
                });

                setDatas(edit);
            } else {
                let newObj = { ...data, ...{ id: `${data.inspection_library_condition}_${data.inspection_library_condition_value}` } };
                setDatas([...getDatas, newObj]);
            }

            onClose();
            setEditData(undefined);
        }
    };

    useEffect(() => {
        if (isSave) {
            savvvve();
        }
    }, [isSave]);

    const valueChanged = (value, key) => {
        setData((cur) => {
            let newData = { ...cur };
            newData[key] = value;
            return newData;
        });
    };

    useEffect(() => {
        if (visible) {
            setIsSave(false);

            if (state) {
                // reset
                valueChanged(undefined, 'inspection_library_condition_value');

                const elem = document.getElementsByName('inspection_library_condition_value');
                if (elem.length !== 0) {
                    elem[0].parentElement.parentElement.parentElement.classList.remove('dx-invalid');
                }

                getMeasureCondition();
                getMeasureDirection();
                getMeasureResult();
            } else {
                if (editData !== undefined) {
                    setData(editData);
                }
            }
        }
    }, [visible]);

    return (
        <Popup width={460} height={524} visible={visible} hideOnOutsideClick={true} onHiding={onClose} dragEnabled={true} position="center" showTitle={false}>
            <div className="popup-add-measure-condition">
                <div className="popup-header">
                    <p className="popup-title">Measure condition</p>
                </div>
                <div className="popup-body">
                    <DropdownField
                        isColumn={true}
                        label={translation.inspection_library_condition}
                        value={data?.inspection_library_condition}
                        dataSource={inspectionType}
                        onValueChanged={(e) => valueChanged(e.value, 'inspection_library_condition')}
                        valueExpr="sys_division_sub_value"
                        displayExpr="sys_division_sub_caption_text"
                        isEssential={true}
                    />
                    <div>
                        <TextboxField isColumn={true} label={translation.inspection_library_condition_value} name="inspection_library_condition_value" value={data?.inspection_library_condition_value} onValueChanged={(e) => valueChanged(e.value, 'inspection_library_condition_value')} isEssential={true} />
                        <DropdownField
                            isColumn={true}
                            label={translation.inspection_library_condition_direction}
                            value={data?.inspection_library_condition_direction}
                            dataSource={directionType}
                            onValueChanged={(e) => valueChanged(e.value, 'inspection_library_condition_direction')}
                            valueExpr="sys_division_sub_value"
                            displayExpr="sys_division_sub_caption_text"
                            isEssential={true}
                        />
                    </div>
                    <div>
                        <TextboxField
                            isColumn={true}
                            label={translation.inspection_library_condition_gap_plus}
                            value={data?.inspection_library_condition_gap_plus}
                            onValueChanged={(e) => valueChanged(e.value, 'inspection_library_condition_gap_plus')}
                            // isEssential={true}
                            readOnly={data?.inspection_library_condition_direction === 'EQUAL' ? false : true}
                        />
                        <TextboxField
                            isColumn={true}
                            label={translation.inspection_library_condition_gap_minus}
                            value={data?.inspection_library_condition_gap_minus}
                            onValueChanged={(e) => valueChanged(e.value, 'inspection_library_condition_gap_minus')}
                            // isEssential={true}
                            readOnly={data?.inspection_library_condition_direction === 'EQUAL' ? false : true}
                        />
                    </div>
                    <DropdownField
                        isColumn={true}
                        label={translation.inspection_library_condition_unit}
                        value={data?.inspection_library_condition_unit}
                        dataSource={resultType}
                        onValueChanged={(e) => valueChanged(e.value, 'inspection_library_condition_unit')}
                        valueExpr="sys_division_sub_value"
                        displayExpr="sys_division_sub_caption_text"
                        isEssential={true}
                    />
                </div>

                <div className="popup-footer">
                    <Button width={86} text={translation.cancel} type="normal" stylingMode="text" className="btn-s-r" onClick={onClose} />
                    <Button text={translation.confirm} width={86} type="default" stylingMode="contained" className="btn-s-r" onClick={(e) => onClickOk(e)} />
                </div>
            </div>
        </Popup>
    );
}
