import React from 'react';
import Button from 'devextreme-react/button';
import "../../contents/styles/home/no-page.css";
import { useNavigate } from 'react-router-dom';

const NoPage = () => {
    const navigate = useNavigate();

    return (
        <div className="no-page">
            <div className="no-page-img"></div>
            <div className="btn-go-back" onClick={() => navigate(-1)}>
                <div>
                    <Button
                        icon="arrowleft"
                        type="default"
                        stylingMode="contained"
                        className="dx-button-mode-es"
                        width={52}
                        height={52}
                        />
                </div>
                <p>GO BACK</p>
            </div>
        </div>
    );
};

export default NoPage;