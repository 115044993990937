import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useTranslation from '../../components/customHooks/translations';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import { getLanguage, getUser, getMenuMappingInfo, getIP } from '../../apiInterface/utils/Common';
import { PageTitle } from '../../components/common/PageTitle.jsx';
import { Button, Popup, ScrollView } from 'devextreme-react';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { SearchableField, TextboxField, DropdownField, FilterComponent, FileField } from '../../components/common/FormField';
import BaseDataGrid from '../../components/common/BaseDataGrid';
import TextArea from 'devextreme-react/text-area';
import { actionDirect, moveFocus, submit } from '../../components/common/FormFuncWithEnter';
import LastModifiedIndicator from '../../components/common/LastModifiedIndicator';
import '../../contents/styles/badtype/inspection.css';
import { swal, useSwal } from '../../components/common/Swal';
import { getSysDivisionCaptionByCode, getOneInspectionLibrary, insertStandardLibrary, updateStandardLibrary, insertInspectionLibraryCopy, getInspectionLibraryDT } from '../../apiInterface/inspection/InspectionStandardLibraryAPI';
import InspectionStandardGroupGrid from './InspectionStandardGroupGrid';

const DIVISION_CODE = 'INSPECTIONTYPE';
const previousPage = '/InspectionStandardGroup';
export default function InspectionStandardLibraryForm(props) {
    const navigate = useNavigate();
    const translation = useTranslation();
    const location = useLocation();
    const errorHandler = useErrorHandler();
    const user = getUser();
    const ipAddress = getIP();
    const lang = getLanguage();
    const { success } = useSwal();
    const { state } = useLocation();

    const title = location.state.title;

    const [dropdown, setDropdown] = useState();
    const [data, setData] = useState({ inspection_library_is_active: true });
    const [isPopupVisibility, setPopupVisibility] = useState(false);
    const [loaditem, setLoadItem] = useState({});
    const [copy, setCopy] = useState({});

    useEffect(() => {
        let copy = {
            inspection_library_copied_code: loaditem.inspection_library_code,
            inspection_library_copied_name: loaditem.inspection_library_name,
            inspection_type_name: loaditem.inspection_type_name,
            inspection_library_is_active: true,
        };
        setData(copy);
    }, [loaditem]);

    // get dropdown
    const getDropdownInfo = async () => {
        let param = {
            user_company_id: user.user_company_id,
            sys_division_code: DIVISION_CODE,
            sys_lang_code: lang,
        };

        const res = await errorHandler(getSysDivisionCaptionByCode, param);
        if (res) setDropdown(res.data.o_data);
    };

    const useItems = [
        { value: true, title: translation.use },
        { value: false, title: translation.notUse },
    ];

    // get load grid list
    const getDataGridInfo = async () => {
        const customStore = new CustomStore({
            load: async (loadOptions) => {
                let skip = 0;
                let take = 10;
                if (loadOptions['skip']) skip = loadOptions['skip'];
                if (loadOptions['take']) take = loadOptions['take'];
                const pageNo = parseInt(skip / take) + 1;
                const param = {
                    user_company_id: user.user_company_id,
                    sys_lang_code: lang,
                    row_count: take,
                    page_no: pageNo,
                    search_text: searchText,
                    inspection_type: selectedDropdown,
                };

                const res = await errorHandler(getInspectionLibraryDT, param);
                if (res) {
                    return {
                        data: res.data.o_data,
                        totalCount: res.data.count,
                    };
                }
            },
        });
        setDataSource(customStore);
    };

    useEffect(() => {
        getDropdownInfo();

        if (state.title === 'Edit' || state.title === 'Copy') {
            if (state.rowData?.inspection_library_id !== undefined) {
                // load data
                let param = {
                    inspection_library_id: sessionStorage.getItem('inspectionLibraryId'),
                    sys_lang_code: lang,
                };

                const fetching = async () => {
                    const res = await errorHandler(getOneInspectionLibrary, param);
                    if (res) {
                        let d = res.data.o_data[0];
                        if (state.title === 'Edit') {
                            setData(d);
                            valueChanged(d.inspection_type, 'inspection_type');
                        } else {
                            let copy = {
                                inspection_library_copied_id: d.inspection_library_id,
                                inspection_library_copied_code: d.inspection_library_code,
                                inspection_library_copied_name: d.inspection_library_name,
                                inspection_library_is_active: true,
                            };
                            setData(copy);
                        }
                    }
                };
                fetching();
            }
        }
    }, []);

    // save
    const onSave = async () => {
        const { id: mapping_id } = await getMenuMappingInfo();
        const anotherData = {
            inspection_library_id: state.title !== 'Add' ? sessionStorage.getItem('inspectionLibraryId') : null,
            user_master_email: user.user_master_email,
            user_log_ip: ipAddress,
            user_master_id: user.user_master_id,
            user_menu_mapping_id: mapping_id,
            user_company_id: user.user_company_id,
        };
        const queryData = Object.assign(data, anotherData);

        const response = await errorHandler(state.title === 'Add' ? insertStandardLibrary : state.title === 'Edit' ? updateStandardLibrary : insertInspectionLibraryCopy, queryData);
        if (response) {
            let msg = state.title !== 'Edit' ? translation.success_insert : translation.success_update;
            const text = `<p>${msg}</p>`;
            success(text, exitForm);
        }
    };

    const valueChanged = (value, key) => {
        setData((cur) => {
            let newData = { ...cur };
            newData[key] = value;
            return newData;
        });
    };

    // exit
    const exitForm = () => {
        navigate(previousPage, {
            state: { previousPage: location.pathname },
        });
        sessionStorage.removeItem('inspectionLibraryId');
    };

    return (
        <div className="inspection-standard-library-form">
            <div className="right-content-title">
                <PageTitle pageState={state.title} />
            </div>
            <form>
                <div className="right-content-body">
                    <div className="container">
                        <div className="dx-fieldset">
                            {state.title != 'Copy' && (
                                <DropdownField label={translation.select_inspectionType} value={data?.inspection_type} dataSource={dropdown} onValueChanged={(e) => valueChanged(e.value, 'inspection_type')} valueExpr="sys_division_sub_value" displayExpr="sys_division_sub_caption_text" isEssential={true} />
                            )}
                            {state.title == 'Copy' && (
                                <>
                                    <SearchableField label={translation.inspection_library_copied_code} text={data?.inspection_library_copied_code} isEssential={true} hasTextboxPopup={true} onSearch={() => setPopupVisibility(true)} isWritable={false} placeHolder="" />
                                    <TextboxField label={translation.inspection_library_copied_name} value={data?.inspection_library_copied_name} isEssential={true} readOnly={true} />
                                </>
                            )}
                            <TextboxField label={translation.inspection_library_code} value={data?.inspection_library_code} onValueChanged={(e) => valueChanged(e.value, 'inspection_library_code')} readOnly={state?.title == 'Edit' ? true : false} isEssential={true} />
                            <TextboxField label={translation.inspection_library_name} value={data?.inspection_library_name} onValueChanged={(e) => valueChanged(e.value, 'inspection_library_name')} isEssential={true} />
                            <DropdownField label={translation.inspection_library_is_active} value={data?.inspection_library_is_active} dataSource={useItems} onValueChanged={(e) => valueChanged(e.value, 'inspection_library_is_active')} valueExpr="value" displayExpr="title" isEssential={true} />
                            <TextboxField label={translation.inspection_library_comment} onValueChanged={(e) => valueChanged(e.value, 'inspection_library_comment')} value={data?.inspection_library_comment} />
                        </div>
                        <div className="block"></div>
                        {state.title == 'Edit' && <LastModifiedIndicator date={data?.modified_date} department={data?.modified_by_department} position={data?.modified_by_duty} writer={data?.modified_by_name} />}
                        <div className="btn-group dx-fieldset">
                            <Button type="normal" stylingMode="text" className="btn-s-r" onClick={exitForm}>
                                <div>
                                    <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                    <span className="dx-button-text">{translation.cancel}</span>
                                </div>
                            </Button>
                            <Button
                                id="submitBtn"
                                type="default"
                                stylingMode="contained"
                                className="btn-s-r"
                                useSubmitBehavior={false}
                                onClick={(e) => {
                                    submit(e, onSave);
                                }}
                            >
                                <div>
                                    <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                    <span className="dx-button-text">{translation.save}</span>
                                </div>
                            </Button>
                        </div>
                    </div>
                </div>
            </form>
            <SelectCopyLibrary visible={isPopupVisibility} onClose={() => setPopupVisibility(false)} onOk={() => setPopupVisibility(true)} loadItem={setLoadItem} />
        </div>
    );
}

const SelectCopyLibrary = (props) => {
    const { visible, onClose = () => {}, onOk = () => {}, loadItem } = props;

    const translation = useTranslation();

    const [selectedItem, setSelectedItem] = useState({});
    const [selectedItems, setSelectedItems] = useState([]);

    const onClickOk = (e) => {
        loadItem(selectedItem);
        sessionStorage.removeItem('inspectionLibraryId');
        onClose();
    };

    const [deselect, setDeselect] = useState({ status: false });
    const removeTag = (e) => {
        setSelectedItem({});

        // getRow
        let obj = {
            status: true,
            item: selectedItem,
        };
        setDeselect(obj);
    };

    return (
        <Popup width={1100} height={850} visible={visible} hideOnOutsideClick={true} onHiding={onClose} dragEnabled={true} position="center" showTitle={false}>
            <div className="popup-select-copy-library">
                <div className="popup-header">
                    <p className="popup-title">Select Inspection library</p>
                </div>
                <div className="popup-body">
                    <div className="selected-item-viewer">
                        <div className="icon-checked">
                            <i></i>
                        </div>
                        <div className="selected-item-field">
                            {selectedItem?.inspection_library_id !== undefined && (
                                <>
                                    <ul className="selected-items">
                                        <li key={selectedItem?.inspection_library_id} className="item">
                                            <p className="item-name">{selectedItem?.inspection_library_name}</p>
                                            <i className="material-icons btn-delete" onClick={() => removeTag(selectedItem?.inspection_library_id)}>
                                                cancel
                                            </i>
                                        </li>
                                    </ul>
                                    {/* <div className="btn-all-delete" onClick={removeTag}>
                                        <button>{translation.delete_all}</button>
                                    </div> */}
                                </>
                            )}
                        </div>
                    </div>
                    <div className="item-select-box">
                        <ScrollView width="100%">
                            <div className="container-wrapper">
                                <InspectionStandardGroupGrid visible={visible} items={setSelectedItem} isDeselect={deselect} />
                            </div>
                        </ScrollView>
                    </div>
                </div>
                <div className="popup-footer">
                    <Button width={86} text={translation.cancel} type="normal" stylingMode="text" className="btn-s-r" onClick={onClose} />
                    <Button text={translation.confirm} width={86} type="default" stylingMode="contained" className="btn-s-r" onClick={(e) => onClickOk(e)} />
                </div>
            </div>
        </Popup>
    );
};
