import { useState, useEffect, useRef } from "react";
import "allotment/dist/style.css";
import { Allotment } from "allotment";
import Button from 'devextreme-react/button';
import DateBox from 'devextreme-react/date-box';
//custom hooks n components
import useTranslation from "../../components/customHooks/translations";
import { swal } from "../common/Swal";
import useErrorHandler from "../../components/customHooks/useErrorHandler";
import { getUser, getMenuMappingInfo } from '../../apiInterface/utils/Common';
import { getMenuPageInitInformation, getCMVPaneData } from "../../apiInterface/menu/MenuAPI";
//custom chart lib
import { LineChart, BarChart, PieChart, ScatterChart, RadarChart, BoxplotChart } from "../../components/ixChart/IXChart"
//localization configuration
import { PageDropdown } from "../common/PageDropdown";
import { PageTitle } from "../../components/common/PageTitle.jsx";

let initFilterConfig = { "dateTimeField": "", "isDateTimeField": false, "isRealTime": false, "intervalVal": 0 };
let initTopFilter = { "isDateTimeSelected": false, "isLive": false };
let [FILTER_CONFIG, chartArray] = [[], [], "", "", "", initFilterConfig, initTopFilter, []];

const chartCategory = (chartType, params) => {
    let chart;
    switch (chartType) {
        case "barChart":
            chart = BarChart(params);
            break;
        case "lineChart":
            chart = LineChart(params);
            break;
        case "pieChart":
            chart = PieChart(params);
            break;
        case "scatterChart":
            chart = ScatterChart(params);
            break;
        case "radarChart":
            chart = RadarChart(params);
            break;
        case "boxplotChart":
            chart = BoxplotChart(params);
            break;
        default:
    }
    chartArray.push(chart);
    chart.resize();
    return chart;

}

var genRandomArray = (cnt) => {
    let length = 20;
    let max = 1000;
    var arrList = [];
    for (var i = 0; i < cnt; i++) {
        arrList.push(Array(length).fill().map(() => Math.round(Math.random() * max)));
    }
    return arrList;
}

const bindChart = async (params, p) => {
    //let ySampleData = genRandomArray(p.data.yAxisInfo.length);

    const userInfo = getUser();
    const queryData = {
        pane_data: JSON.stringify(p.data),
        user_company_id: userInfo.user_company_id
    }
    console.log('test' + p.wId)
    const res = await getCMVPaneData(queryData);
    const data = res.data.o_data;
    let getListByKey = (arr, key) => {
        return arr.map(x => x[key])
    }

    let xAxisInfo = [getListByKey(data, p.data.xAxisInfo[0])];

    let yAxisInfo = []
    for (var i = 0; i < p.data.yAxisInfo.length; i++) {
        var response = getListByKey(data, p.data.yAxisInfo[i]["fieldName"])
        yAxisInfo.push(response);
    }
    params.y = yAxisInfo;
    params.legend = p.data.yAxisInfo.flatMap(e => e.legend)
    params.color = p.data.yAxisInfo.flatMap(e => e.color)
    params.x = xAxisInfo;// p.data.xAxisInfo.length ? genRandomArray(1) : genRandomArray(1);//here using genRandomeArray(1) because only one axis for X.
    params.xAxisName = [p.data.xAxisTitle];
    params.yAxisName = [p.data.yAxisTitle];
    console.log(params.id);
    chartCategory(p.chartType, params)
}

//handle chartbinding action
const updatePaneChartInfo = async (panes) => {
    const filteredPanes = await panes.reduce((acc, p) => {
        if (p?.split) {
            updatePaneChartInfo(p.panes);
        }
        else {

            if (p.data) {
                if (p.data["filter"]["isRealTime"] === true) {
                    //here interval function if islive active
                    realTimefn(p)
                }
                else {
                    document.querySelectorAll(`#${p.wId}> .card-header > .card-title`)[0].innerText = p.headerContentInHtml;
                    let params = {
                        x: [[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]],
                        y: [[10, 4, 51, 32, 3]],
                        legend: ["garryTest"],
                        id: `#${p.wId}> .card-body> div.dynamic-chart`,
                        xAxisName: [],
                        yAxisName: [],
                        color: []
                    }
                    if (p.data.yAxisInfo.length > 0) {



                        document.querySelectorAll(`#${p.wId}> .card-body> div.dynamic-chart`)[0]?.classList.remove('hidden');
                        document.querySelectorAll(`#${p.wId}> div.no-data`)[0]?.classList.add('hidden');
                        document.querySelector(`#${p.wId}> .div no-data`)?.classList.add('hidden');
                        let ySampleData = genRandomArray(p.data.yAxisInfo.length);
                        //const userInfo = getUser();
                        //const queryData = {
                        //    pane_data: JSON.stringify(p.data),
                        //    user_company_id: userInfo.user_company_id
                        //}

                        //const res =  errorHandler(getCMVPaneData, queryData);
                        //if (res) {
                        //    alert("successs");
                        //}

                        bindChart(params, p);

                        //callMe(function () {
                        //console.log('test' + p.wId)
                        //          let res =  getCMVPaneData(queryData);

                        //            ;
                        //            params.y = ySampleData;
                        //            params.legend = p.data.yAxisInfo.flatMap(e => e.legend)
                        //            params.color = p.data.yAxisInfo.flatMap(e => e.color)
                        //            params.x = p.data.xAxisInfo.length ? genRandomArray(1) : genRandomArray(1);//here using genRandomeArray(1) because only one axis for X.
                        //            params.xAxisName = [p.data.xAxisTitle];
                        //            params.yAxisName = [p.data.yAxisTitle];
                        //            console.log(params.id);
                        //            chartCategory(p.chartType, params)

                        /*});*/


                    }

                    else {
                        document.querySelectorAll(`#${p.wId}> .card-body> div.dynamic-chart`)[0].classList.add('hidden');
                        document.querySelectorAll(`#${p.wId}> div.no-data`)[0]?.classList.remove('hidden');
                        document.querySelectorAll(`#${p.wId}> .card-header > .card-title`)[0].innerText = "No data selected";
                    }
                }
            }

        }
        return acc;
    }, []);
    const ro = new ResizeObserver(entries => {
        console.log("resize event");
        chartArray.map(x => x.resize())
    });
    // Only observe the second box
    setTimeout(ro.observe(document.querySelector('.card-wrapper')), 500);
    return filteredPanes;
}

let intervals = [];
const realTimefn = async (p) => {

    const interval = setInterval(() => {
        console.log("real time running");
        document.querySelectorAll(`#${p.wId}> .card-header > .card-title`)[0].innerText = p.headerContentInHtml;
        let params = {
            x: [[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]],
            y: [[10, 4, 51, 32, 3]],
            legend: ["garryTest"],
            id: `#${p.wId}> .card-body> div.dynamic-chart`,
            xAxisName: [],
            yAxisName: [],
            color: []
        }
        if (p.data.yAxisInfo.length > 0) {
            document.querySelectorAll(`#${p.wId}> .card-body> div.dynamic-chart`)[0]?.classList.remove('hidden');
            document.querySelectorAll(`#${p.wId}> div.no-data`)[0]?.classList.add('hidden');
            document.querySelector(`#${p.wId}> .div no-data`)?.classList.add('hidden');
            bindChart(params, p);
        }

        else {
            document.querySelectorAll(`#${p.wId}> .card-body> div.dynamic-chart`)[0].classList.add('hidden');
            document.querySelectorAll(`#${p.wId}> div.no-data`)[0]?.classList.remove('hidden');
            document.querySelectorAll(`#${p.wId}> .card-header > .card-title`)[0].innerText = "No data selected";
        }


    }, parseInt(p["data"]["filter"]["intervalVal"]) * 1000);
    intervals.push(interval);
}
//handle filter
const updateFilterInfo = (panes, currentWId) => {
    const filteredPanes = panes.reduce((acc, p) => {
        if (p?.split) {
            p.panes = updateFilterInfo(p.panes, currentWId);
            if (Array.isArray(p?.panes) && p.panes.length > 0) {
                acc.push(p);
            }
        } else if (p.wId === currentWId) {
            p.data["filter"] = FILTER_CONFIG;
            acc.push(p);
        }
        else {
            acc.push(p);
        }
        return acc;
    }, []);
    return filteredPanes;
}

const widgets = {
    "split": "vertical",
    "id": "1",
    "root": true,
    "panes": [],
    "sizes": [1]
}

function RenderSplitPanes(props) {
    const { splitPane, s_id } = props;
    return (
        <Allotment
            separator={false}
            key={splitPane.id}
            vertical={splitPane.split === "vertical"}
            defaultSizes={splitPane.sizes.map((e) => parseFloat(e))}
        >
            {
                splitPane.panes.map((el) => {
                    if (Reflect.has(el, "split")) {
                        return <RenderSplitPanes splitPane={el} s_id={el.id} />
                    }

                    return (
                        <Allotment.Pane id="hoja" key={el.wId} visible={el.show}>
                            <div
                                sx={{
                                    padding: "15px",
                                    height: "100%",
                                    position: "absolute",
                                    width: "100%"
                                }}
                                className="card-wrapper"
                            >
                                <div className="card ca" id={el.wId}>
                                    <div className="card-header ca">
                                        <h6 className="card-title ca">{el.wId}</h6>
                                    </div>
                                    <div className="no-data hidden">No Data for selected chart.</div>
                                    <div className="card-body ca" dangerouslySetInnerHTML={{ __html: el.bodyContentInHtml }}></div>
                                </div>
                            </div>
                        </Allotment.Pane>
                    );
                })
            }
        </Allotment>
    );
}

const CMVPane = (props) => {
    const errorHandler = useErrorHandler();
    const userInfo = getUser();
    const [fBar, setfBar] = useState(null);
    const [w, setW] = useState(widgets);
    const translation = useTranslation();


    const getTemplateMasterLayout = async () => {
        const mappingInfo = await getMenuMappingInfo();
        let param = {
            user_company_id: userInfo.user_company_id,
            user_master_id: userInfo.user_master_id,
            menu_mapping_id: mappingInfo.id,
        };

        const res = await errorHandler(getMenuPageInitInformation, param);
        if (res) {
            setW(res.data.o_data[0].menu_custom_layout);
            const customFilter = res.data.o_data[0].menu_custom_filter;
            if (customFilter.isDateTimeSelected)
                setfBar(<div className="setting-cmv">
                    <DateBox
                        name={null}
                        type="date"
                        className="ix-tfs-r"
                        width={148}
                        acceptCustomValue={false}
                        openOnFieldClick={true}
                    />
                    <Button
                        type="default"
                        stylingMode="contained"
                        className="btn-s-r dx-button-mode-es"
                        icon="search"
                        onClick={dataLoading}
                    />
                </div>)
            if (customFilter.isLive)
                console.log("is live" + customFilter.isLive);
        }
    }

    const dataLoading = (response) => {
        console.log("data loading calling");
        const w1 = { ...w };
        if (w1.panes.length > 0) {
            updatePaneChartInfo(w1.panes);

        }
    }
    useEffect(dataLoading, [w]);
    //init
    useEffect(() => {
        async function fetchData() {
            const response = await getTemplateMasterLayout();
        }
        const test = fetchData();
    }, [props.data]);

    //initialize page title
    useEffect(() => {
        return (
            () => {
                intervals.forEach((interval) => clearInterval(interval));
                intervals = [];
            }
        )
    }, []);



    //end handle y and x axis chart info
    return (
        <div className="cmv-pane">
            <div className="right-content-title">
                <PageTitle />
                <div className="setting-cmv-box">
                    {fBar}
                    <PageDropdown />
                </div>
            </div>
            <div className="menu-binding-data">
                <div className="right-content-body">
                    <div className="template-wrapper">
                        <div className="template">
                            <RenderSplitPanes splitPane={w} s_id={w.id} />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default CMVPane;
