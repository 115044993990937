import {
    makeTokenAxiosGetRequest, makeTokenAxiosPostRequest,
    makeTokenAxiosPutRequest, makeTokenAxiosDeleteRequest,
    makeAxiosPostFileRequest
} from "../utils/AxiosMask"


export const getOperationFactory = async (data) => {
    var res;
    const urlPath = `machine_equipment/get_operation_factory/`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};

export const getMoldInfo = async (data) => {
    var res;
    const urlPath = `machine_mold/`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};


export const deleteMoldInfoByMoldId = async (data) => {
    var res;
    const urlPath = `machine_mold/`;
    try {
        res = await makeTokenAxiosDeleteRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};


export const insertMoldInfoByCompanyId = async (data) => {
    var res;
    const urlPath = `machine_mold/`;
    ;
    try {
        res = await makeTokenAxiosPostRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        ;
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};

export const getMachineMoldInfoByMachineId = async (data) => {
    var res;
    const urlPath = `machine_mold/get_machine_mold_by_id`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};

export const updateMoldInfoByCompanyId = async (data) => {
    var res;
    const urlPath = `machine_mold/`;
    try {
        res = await makeTokenAxiosPutRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};

export const uploadMoldImage = async (data, formData) => {
    var res;
    const urlPath = `machine_mold/upload_attachments?user_id=${data.user_id}`;
    try {
        res = await makeAxiosPostFileRequest(urlPath, formData);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};

export const getItemCategoryInfo = async (param) => {
    let res;
    const urlPath = `machine_mold/get_item_category/`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res : "fail";
};

export const getItemMasterInfo = async (param) => {
    let res;
    const urlPath = `machine_mold/get_item_master/`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        
        console.error('error', error);
    }

    return res?.status === 200 ? res : "fail";
};

export const getItemInfoByMoldId = async (data) => {
    var res;
    const urlPath = `machine_mold/machine_mold_item_by_mold_id/`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};

export const saveItemInfo = async (data) => {
    var res;
    const urlPath = `machine_mold/machine_mold_item?machine_mold_id=${data.machine_mold_id}&item_master_id=${data.item_master_id}`;
    try {
        res = await makeTokenAxiosPostRequest(urlPath, null);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        ;
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};
