import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import useTranslation from "../customHooks/translations";
import "../../contents/styles/common/ix-field.css";

//@ts-check

/**
 * @param {object} items - list of items to contain.
 * @param {function} onSelect - callback function when user selected checkbox.
 * @param {function} onClick - callback function when user clicked search button.
 * @param {string} displayExpr - If the type of element of lists is object, the key value to display its value.
 * @param {*} ref
 * @returns 
 */
export function SearchWithTag(props, ref) {
    const {
        items = [] /** @type {string} */,
        onSelect = () => { },
        onClick: onClickCallback = () => { },
        displayExpr,
        placeholder,
        width = "300px",
        hasButton=true,
    } = props;

    const selectBoxRef = useRef();
    const [isOpen, setOpen] = useState(false);
    const [active, setActive] = useState(false);
    const [allState, setAllState] = useState("none");// indicate state of 'ALL' checkbox. none = none of items are selected, semi-checked = one or multiple items are selected, checked = all items are selected
    const [checkState, setCheckState] = useState();
    const searchText = useRef();
    const translation = useTranslation();

    useEffect(() => {
        document.addEventListener('mousedown', clickSelectBoxOutside);
        return () => document.removeEventListener('mousedown', clickSelectBoxOutside);

    }, []);

    const clickSelectBoxOutside = (e) => {
        if (!selectBoxRef.current.contains(e.target)) {
            setOpen(false);
            setActive(false);
        } else {
            setActive(true);
        }
    }


    const checkAll = (e) => {
        const checked = e.currentTarget.checked;
        if (checked || allState == 'semi-checked') {
            setAllState('checked');
            setCheckState((cur) => {
                let copy = [...cur];
                return copy.map(_ => true);
            });
        } else {
            setAllState('none');
            setCheckState((cur) => {
                let copy = [...cur];
                return copy.map(_ => false);
            });
        }
    }

    if (Array.isArray(items) && items.length > 0) {
        if (checkState == undefined) {
            setCheckState(items.map(_ => false));
        }
    }

    const onCheck = ({ label, checked }) => {
        const index = items.findIndex((e) => {
            if (displayExpr == undefined) {
                return e === label;
            } else {
                return e[displayExpr] === label;
            }
        });

        if (index > -1) {
            onSelect({
                target: items[index],
                checked: checked,
            });
            setCheckState(cur => {
                let copy = [...cur];
                copy[index] = checked;

                let checkedItems = copy.filter(e => e);
                if (checkedItems.length == 0) {
                    setAllState('none');
                } else if (checkedItems.length == items.length) {
                    setAllState('checked');
                } else {
                    setAllState('semi-checked');
                }
                return copy;
            });
        } else {
            throw `Unexpected item name: ${label}`;
        }
    }

    const toggleDropdown = () => setOpen((cur) => !cur);

    const onClick = (e) => {
        let checked = [];
        checkState.forEach((isChecked, i) => {
            if (isChecked) {
                checked.push(items[i]);
            }
        });

        onClickCallback({
            selectedTags: checked,
            searchText: searchText.current,
        });
    }

    const onType = (e) => searchText.current = e.currentTarget.value;

    return (
        <div className="ix-field">
            <div className="field-label">
                <p>{translation.search}</p>
            </div>
            <div className="field-value" ref={selectBoxRef}>
                <form onSubmit={e => e.preventDefault()}>
                    <div className={`search-tag-field${active ? " active" : ""}`}>
                        <div className="select-box">
                            <div className="selected-option" onClick={toggleDropdown}>
                                <p>ALL</p>{/* place holder */}
                                <span className={allState}></span>
                            </div>
                            <ul className="option-list" style={{ display: isOpen ? undefined : "none" }}>
                                <li>
                                    <label>
                                        <input
                                            type="checkbox"
                                            id="option-all"
                                            className={allState}
                                            onChange={checkAll}
                                            checked={allState == "none" ? false : true}
                                        />
                                        <span>ALL</span>
                                    </label>
                                </li>
                                {
                                    items.map((item, i) => (
                                        <TagComponent
                                            key={displayExpr == undefined ? item : item[displayExpr]}
                                            label={displayExpr == undefined ? item : item[displayExpr]}
                                            onCheck={onCheck}
                                            checked={checkState?.[i]}
                                        />
                                    ))
                                }
                            </ul>
                        </div>
                        <div className="border"></div>
                        <input type="text" onChange={onType} placeholder={placeholder} style={{"width": width}} />
                        {hasButton && <button onClick={onClick}><i className="dx-icon-m-search"></i></button>}
                    </div>
                </form>
            </div>
        </div>
    );
}

function TagComponent(props) {
    const {
        label,
        onCheck = () => { },
        checked,
    } = props;

    const onChecked = (e) => {
        onCheck({
            label,
            checked: e.currentTarget.checked
        });
    }

    return (
        <li>
            <label>
                <input type="checkbox" onChange={onChecked} checked={checked} />
                <span>{label}</span>
            </label>
        </li>
    )
}

SearchWithTag = forwardRef(SearchWithTag);