import {
    makeTokenAxiosDeleteRequest,
    makeTokenAxiosGetRequest,
    makeTokenAxiosPostRequest,
    makeTokenAxiosPutRequest,
    makeAxiosPostFileRequest
} from "../utils/AxiosMask"


const baseUrl = 'item_master';
export const getItemCategory = async (param) => {
    let res;
    const urlPath = `${baseUrl}/get_item_category`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res.data : "fail";
}

export const getItemMaster = async (param) => {
    let res;
    const urlPath = `${baseUrl}/`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res.data : "fail";
}

export const updateItemMaster = async (param) => {
    let res;
    const urlPath = `${baseUrl}/`;

    try {
        res = await makeTokenAxiosPutRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res.data : "fail";
}

export const insertItemMaster = async (param) => {
    let res;
    const urlPath = `${baseUrl}/`;

    try {
        res = await makeTokenAxiosPostRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res.data : "fail";
}

export const deleteItemMaster = async (param) => {
    let res;
    const urlPath = `${baseUrl}/`;

    try {
        res = await makeTokenAxiosDeleteRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res.data : "fail";
}

export const getProductGroup = async (param) => {
    let res;
    const urlPath = `${baseUrl}/get_product_group`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res.data : "fail";
}

export const getItemMasterById = async (path, param) => {
    let res;
    const urlPath = `${baseUrl}/${path}`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res.data : "fail";
}

export const uploadAttachments = async (id, data) => {
    var res;
    const urlPath = `${baseUrl}/upload_attachments?user_id=${id}`;
    try {
        res = await makeAxiosPostFileRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res : "fail";
};