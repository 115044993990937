﻿import React, { useRef, useEffect, useState } from 'react';
import { DropdownField, FilterComponent, SearchableField, TextboxField } from '../../components/common/FormField';
import { useGetUseOrNotContent } from '../../components/customHooks/dropdownContent';
import { Button } from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import useTranslation from '../../components/customHooks/translations';
import LastModifiedIndicator from '../../components/common/LastModifiedIndicator';
import BaseDataGrid from '../../components/common/BaseDataGrid';
import { useLocation, useNavigate } from 'react-router-dom';
import DraggableTreeList from '../../components/common/DraggableTreeList';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';
import { getIP, getLanguage, getMenuMappingInfo, getUser } from '../../apiInterface/utils/Common';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import { getAasExplorer, getAasExplorerByProcessId, getAasProperty, getAasPropertyByProcessId, getDatasetById, insertDatasetDetail, insertDatasetMaster, insertDatasetStructure, updateDatasetMaster } from '../../apiInterface/aasFile/DatasetManagementAPI';
import AddProcessPopup from './AddProcessPopup';
import AddDataPopup from './AddDataPopup';
import { useSwal } from '../../components/common/Swal';
import { PageTitle } from '../../components/common/PageTitle.jsx';

let propertiesTemp = [];
const DatasetManagementForm = () => {
    const user = getUser();
    const lang = getLanguage();
    const ip = getIP();
    const mappingInfo = getMenuMappingInfo();
    const translation = useTranslation();
    const useItems = useGetUseOrNotContent();
    const location = useLocation();
    const navigate = useNavigate();
    const [isAddProcessVisible, setAddProcessVisibility] = useState(false);
    const [isAddDatasetVisible, setAddDatasetVisibility] = useState(false);
    const [subtitle, setSubtitle] = useState();
    const [formData, setFormData] = useState({});
    const [structureTable, setStructureTable] = useState([]);
    const [propertyTable, setPropertyTable] = useState([]);
    const [btnPos, setBtnPos] = useState({ x: 0, y: 0 });
    const [readOnly, setReadOnly] = useState(false);
    const datasetPopupRef = useRef();
    const gridRef = useRef();
    const treeRef = useRef();

    const { success } = useSwal();
    const hoverBtnRef = useRef();
    const getHoverBtn = () => {
        return hoverBtnRef?.current;
    };
    const errorHandler = useErrorHandler();

    const structureHeader = [
        { dataField: 'aas_master_type', caption: 'aas type', alignment: 'center', allowReordering: true },
        { dataField: 'aas_master_id_short', caption: 'aas name', alignment: 'center', allowReordering: true },
    ];

    const propertyHeader = [
        { dataField: 'collection_name', caption: 'table'.toUpperCase(), alignment: 'center', allowReordering: true },
        { dataField: 'column_name', caption: 'column'.toUpperCase(), alignment: 'center', allowReordering: true },
        { dataField: 'aas_path', caption: 'aas path'.toUpperCase(), alignment: 'center', allowReordering: true },
    ];

    useEffect(() => {
        setSubtitle(location.state.title);
        if (location.state.title == 'Edit') {
            setReadOnly(true);
            loadSavedData();
        } else {
            setReadOnly(false);
            changeValue('dataset_master_is_active', useItems[0].value);
        }
    }, []);

    const loadSavedData = async () => {
        const data = location.state.rowData;
        const param = {
            user_company_id: user.user_company_id,
            dataset_master_id: data.dataset_master_id,
            sys_lang_code: lang,
        };

        const res = await errorHandler(getDatasetById, param);
        if (res) {
            const resData = res.data.o_data ? res.data.o_data[0] : [];

            let structures = [];
            let properties = [];

            if (resData.explorer_str != null) {
                structures = JSON.parse(resData.explorer_str);
            }

            if (resData.propert_str != null) {
                properties = JSON.parse(resData.propert_str);
            }

            setFormData(resData);
            setStructureTable(structures);
            setPropertyTable(properties);
            propertiesTemp = properties;
        }
    };

    const getStructure = async (data) => {
        const param = {
            user_company_id: user.user_company_id,
            machine_equipment_id: data.aas_master_id,
        };

        const res = await errorHandler(getAasExplorer, param);
        if (res) {
            const merged = mergeProperties('aas_master_id', structureTable, res.data.o_data);
            //const merged = mergeTree('aas_master_id', 'aas_master_parent_id', res.data.o_data, structureTable);
            setStructureTable(merged);
            treeRef.current.getInstance().endCustomLoading();
        }
    };

    const getStructureByProcessId = async (data) => {
        const param = {
            user_company_id: user.user_company_id,
            operation_process_id: data.operation_process_id,
        };

        const res = await errorHandler(getAasExplorerByProcessId, param);
        if (res) {
            setStructureTable(res.data.o_data);
            treeRef.current.getInstance().endCustomLoading();
        }
    };

    const getProperties = async (data) => {
        const param = {
            user_company_id: user.user_company_id,
            aas_master_id: data.aas_master_id,
        };

        const res = await errorHandler(getAasProperty, param);
        if (res) {
            const merged = mergeProperties('aas_master_id', res.data.o_data, propertyTable);
            setPropertyTable(merged);
            gridRef.current.getInstance().endCustomLoading();
            propertiesTemp = merged;
        }
    };

    const getPropertiesByProcessId = async (process) => {
        const param = {
            user_company_id: user.user_company_id,
            operation_process_id: process.operation_process_id,
        };

        const res = await errorHandler(getAasPropertyByProcessId, param);
        if (res) {
            setPropertyTable(res.data.o_data);
            gridRef.current.getInstance().endCustomLoading();
            propertiesTemp = res.data.o_data;
        }
    };

    // get root node from arrays and merge them
    // if one tree is subtree of another, subtree is merged as new root
    // key is id column or arrays
    const mergeTree = (key, parentKey, ...arrays) => {
        let roots = [];
        let result = [];

        for (let i = 0; i < arrays.length; i++) {
            let rootsId = roots.map((node) => node[key]);
            let tree = treelize(key, parentKey, arrays[i]);

            tree.forEach((subTree) => {
                if (!rootsId.includes(subTree[key])) {
                    roots.push(subTree);
                }
            });
        }

        roots.forEach((tree) => {
            let queue = [tree];
            while (queue.length > 0) {
                result.push(queue[0]);
                queue.push(...queue[0]['children']);
                queue.shift();
            }
        });

        return result;
    };

    //make plain data into tree data and return it.
    const treelize = (key, parentKey, plainData) => {
        let dict = {};
        let roots = [];
        for (let i = 0; i < plainData.length; i++) {
            let row = plainData[i];
            row['children'] = [];
            dict[row[key]] = row;
        }

        plainData.forEach((row, idx) => {
            if (dict[row[parentKey]] == undefined) {
                roots.push(row);
            } else {
                dict[row[parentKey]]['children'].push(row);
            }
        });

        return roots;
    };

    // remove duplicated rows and merge them.
    const mergeProperties = (key, ...arrays) => {
        const merged = {};
        for (let i = 0; i < arrays.length; i++) {
            for (let j = 0; j < arrays[i].length; j++) {
                merged[arrays[i][j][key]] = arrays[i][j];
            }
        }

        return Object.values(merged);
    };

    const changeValue = (key, value) => {
        setFormData((cur) => {
            let copy = { ...cur };
            copy[key] = value;
            return copy;
        });
    };

    const openAddProcess = () => {
        setAddProcessVisibility(true);
    };

    const closeAddProcess = () => {
        setAddProcessVisibility(false);
    };

    const addProcessOnSave = (data) => {
        gridRef.current.getInstance().beginCustomLoading('');
        treeRef.current.getInstance().beginCustomLoading('');
        const keys = Object.keys(data);
        const values = Object.values(data);
        for (let i = 0; i < keys.length; i++) {
            changeValue(keys[i], values[i]);
        }
        getStructureByProcessId(data);
        getPropertiesByProcessId(data);
        closeAddProcess();
    };

    const openAddDataset = () => {
        setAddDatasetVisibility(true);
    };

    const closeAddDataset = () => {
        setAddDatasetVisibility(false);
    };

    const addDatasetOnSave = (data) => {
        gridRef.current.getInstance().beginCustomLoading('');
        treeRef.current.getInstance().beginCustomLoading('');
        getStructure(data[0]);
        getProperties(data[0]);
        closeAddDataset();
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (subtitle === 'Add') {
            insertDataset();
        } else {
            updateDataset();
        }
    };

    const insertDataset = async () => {
        const param = {
            user_company_id: user.user_company_id,
            user_master_id: user.user_master_id,
            user_log_ip: ip,
            user_menu_mapping_id: mappingInfo.id,
            dataset_master_name: formData.dataset_master_name,
            dataset_master_is_active: formData.dataset_master_is_active,
            dataset_master_comment: formData.dataset_master_comment,
            operation_line_process_id: formData.operation_line_process_id,
        };

        const res = await errorHandler(insertDatasetMaster, param);
        if (res) {
            let queue = [];
            const id = res.data.o_data[0].dataset_master_id;

            // const structureRes = await insertStructures(id);
            // const propertyRes = await insertProperties(id);
            queue.push(insertStructures(id));
            queue.push(insertProperties(id));

            const callback = () => navigate('/DatasetManagement');
            success(translation.success_insert, callback);
        }
    };

    const updateDataset = async () => {
        const datasetId = location.state.rowData.dataset_master_id;
        const param = {
            user_company_id: user.user_company_id,
            user_master_id: user.user_master_id,
            user_log_ip: ip,
            user_menu_mapping_id: mappingInfo.id,
            dataset_master_id: datasetId,
            dataset_master_name: formData.dataset_master_name,
            dataset_master_is_active: formData.dataset_master_is_active,
            dataset_master_comment: formData.dataset_master_comment,
            operation_line_process_id: formData.operation_line_process_id,
        };

        const res = await errorHandler(updateDatasetMaster, param);
        if (res) {
            let queue = [];

            queue.push(insertStructures(datasetId));
            queue.push(insertProperties(datasetId));

            const callback = () => navigate('/DatasetManagement');
            success(translation.success_update, callback);
        }
    };

    const insertStructures = async (id) => {
        let queue = [];
        for (let i = 0; i < structureTable.length; i++) {
            let param = {
                dataset_master_id: id,
                aas_master_parent_id: structureTable[i].aas_master_parent_id,
                aas_master_id: structureTable[i].aas_master_id,
                aas_master_seq: structureTable[i].aas_master_seq,
                dataset_master_type: structureTable[i].aas_master_type,
                dataset_master_id_short: structureTable[i].aas_master_id_short,
                dataset_master_id_type: structureTable[i].aas_master_id_type,
                dataset_master_identification: structureTable[i].aas_master_identification,
            };

            const promise = errorHandler(insertDatasetStructure, param);
            queue.push(promise);
        }

        return Promise.all(queue);
    };

    const insertProperties = async (id) => {
        let queue = [];
        for (let i = 0; i < propertyTable.length; i++) {
            let param = {
                dataset_master_id: id,
                aas_master_id: propertyTable[i].aas_master_id,
                aas_master_seq: propertyTable[i].aas_master_seq,
                dataset_name: propertyTable[i].dataset_name,
                collection_name: propertyTable[i].collection_name,
                interval_time: propertyTable[i].interval_time,
                table_type: propertyTable[i].table_type,
                column_type: propertyTable[i].column_type,
                column_name: propertyTable[i].column_name,
                column_length: propertyTable[i].column_length,
                description: propertyTable[i].description,
            };

            const promise = errorHandler(insertDatasetDetail, param);
            queue.push(promise);
        }

        return Promise.all(queue);
    };

    const filtering = (keyword) => {
        let result = [];

        if (!keyword) {
            result = [...propertiesTemp];
        } else {
            for (let i = 0; i < propertiesTemp.length; i++) {
                let name = propertiesTemp[i].column_name;

                if (name == null) continue;

                let isContain = name.toLowerCase().includes(keyword.toLowerCase());
                if (isContain) {
                    result.push(propertiesTemp[i]);
                }
            }
        }

        setPropertyTable(result);
    };

    return (
        <>
            <div className="dataset-mgt-form">
                <div className="right-content-title">
                    <PageTitle pageState={subtitle} />
                </div>
                <form onSubmit={onSubmit}>
                    <div className="right-content-body">
                        <div className="container">
                            <div className="dx-fieldset">
                                <TextboxField isEssential={true} essentialMsg={translation.dataset_name + translation.is_required} label={translation.dataset_name} value={formData?.dataset_master_name} onValueChanged={(e) => changeValue('dataset_master_name', e.value)} readOnly={readOnly} />
                                <SearchableField text={formData?.operation_process_name} label={translation.process} isWritable={false} onSearch={openAddProcess} hasTextboxPopup={true} />
                                <DropdownField
                                    isEssential={true}
                                    essentialMsg={translation.use_or_not + translation.is_required}
                                    label={translation.use_or_not}
                                    dataSource={useItems}
                                    displayExpr="name"
                                    valueExpr="value"
                                    isSearchable={false}
                                    value={formData?.dataset_master_is_active}
                                    onValueChanged={(e) => changeValue('dataset_master_is_active', e.value)}
                                />
                                <TextboxField label={translation.comment} value={formData?.dataset_master_comment} onValueChanged={(e) => changeValue('dataset_master_comment', e.value)} />
                            </div>
                            <div className="block"></div>
                            <div className="dx-fieldset dataset-grid">
                                <div className="dx-field column">
                                    <div className="dx-field-label">
                                        <p className="essential">{translation.dataset_setting}</p>
                                    </div>
                                    <div className="dx-field-value">
                                        <div className="btn-add-dataset">
                                            <Button className="btn-s-r" width={520} text={translation.add_dataset} icon="add" type="default" stylingMode="contained" onClick={() => datasetPopupRef.current.open()} />
                                        </div>
                                        <DraggableTreeList dataSource={structureTable} keyExpr="aas_master_id" parentIdExpr="aas_master_parent_id" allowReordering={true} width={520} allowModify={false} isDoubleClicked={false} headers={structureHeader} allowDragAlways={true} ref={treeRef} />
                                    </div>
                                </div>
                                <div className="dx-field column">
                                    <div className="dx-field-label">
                                        <p>{translation.dataset_table}</p>
                                    </div>
                                    <div className="dx-field-value">
                                        {/*datagrid*/}
                                        <BaseDataGrid toolbarModules={[<FilterComponent label="Filter" onFilter={filtering} />]} showEdit={false} showAdd={false} dataSource={propertyTable} width={764} headers={propertyHeader} ref={gridRef} isDoubleClicked={false} />
                                    </div>
                                </div>
                            </div>
                            <div className="block"></div>
                            {subtitle == 'Edit' && <LastModifiedIndicator date={formData?.modified_date} department={formData?.modified_by_department} position={formData?.modified_by_duty} writer={formData?.modified_by_name} />}
                            <div className="btn-group dx-fieldset" style={{ width: '1362px' }}>
                                <Button
                                    type="normal"
                                    stylingMode="text"
                                    className="btn-s-r"
                                    onClick={() => {
                                        navigate('/DatasetManagement');
                                    }}
                                >
                                    <div>
                                        <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                        <span className="dx-button-text">{translation.cancel}</span>
                                    </div>
                                </Button>
                                <Button id="submitBtn" type="default" stylingMode="contained" className="btn-s-r" useSubmitBehavior={true}>
                                    <div>
                                        <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                        <span className="dx-button-text">{translation.save}</span>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <AddDataPopup onSave={addDatasetOnSave} ref={datasetPopupRef} />
            <Popup width={1100} height={850} visible={isAddProcessVisible} hideOnOutsideClick={true} onHiding={() => setAddProcessVisibility(false)} dragEnabled={true} position="center" showTitle={false}>
                <AddProcessPopup onClose={closeAddProcess} onSave={addProcessOnSave} />
            </Popup>
            <HoverButton ref={hoverBtnRef} style={btnPos} />
        </>
    );
};
export default DatasetManagementForm;

const HoverButton = forwardRef((props, ref) => {
    const { style } = props;
    const [visible, setVisible] = useState(false);

    useImperativeHandle(ref, () => ({
        setVisible: setVisible,
    }));

    return (
        <>
            {visible && (
                <span style={style}>
                    <Button type="danger">+</Button>
                    <Button type="default">-</Button>
                </span>
            )}
        </>
    );
});
