import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import useTranslation from '../../components/customHooks/translations';
import Button from 'devextreme-react/button';
import AppliedItemPopup from '../../pages/badTypeManagement/AppliedItemPopup';

function TreeCardWithLine(props) {
    const { isStartSecond = false, isStartThird = false, data, getChecked, setChecked } = props;

    const [classname, setClassname] = useState('ix-treelist2');

    const selectClass = () => {
        if (isStartSecond) {
            setClassname('ix-treelist2 start-second');
        } else if (isStartThird) {
            setClassname('ix-treelist2 start-third');
        } else {
            setClassname('ix-treelist2');
        }
    };

    useEffect(() => {
        selectClass();
    }, []);

    return (
        <div className={classname}>
            {data !== undefined &&
                data.length !== 0 &&
                data.map((m) => {
                    return <FirstNode isStartSecond={isStartSecond} isStartThird={isStartThird} data={data} m={m} getChecked={getChecked} setChecked={setChecked} />;
                })}
        </div>
    );
}
export default TreeCardWithLine;

export const FirstNode = forwardRef((props, ref) => {
    const { isStartSecond = false, isStartThird = false, data, m, getChecked, setChecked } = props;

    const translation = useTranslation();
    const [visible, setVisible] = useState(false);
    const [selectedItem, setSeletedItem] = useState();

    const onChange = (e) => {
        let checked = e.target.checked;
        let id = e.target.id;
        if (checked) {
            setChecked([...getChecked, id]);
        } else {
            // 체크해제
            let copy = getChecked.filter((item) => {
                return item !== id;
            });
            setChecked(copy);
        }
    };

    const onOpenApplied = (id, type) => {
        setSeletedItem({ inspection_library_id: id, inspection_type_name: type });
        setVisible(true);
    };

    return (
        <>
            <div className="first-node">
                <div className="first-node-card" key={m?.inspection_library_id}>
                    <div className="tree-arrow">
                        <label>
                            <input type="checkbox" />
                        </label>
                    </div>
                    <div className="first-node-tit">
                        <label>
                            <input type="checkbox" onChange={onChange} id={m?.inspection_library_id}></input>
                            <span>{m?.inspection_type_name}</span>
                        </label>
                    </div>
                    <div className="first-node-content">
                        <div className="info">
                            <div>
                                <p className="code">
                                    <span>Code: </span>
                                    {/* DefCode001 */}
                                    {m?.inspection_library_code}
                                </p>
                                <h6>
                                    {m?.inspection_library_name}
                                    {/* Surface bad type library Vol.1 */}
                                </h6>
                            </div>
                        </div>
                        <div className="tail">
                            <div>
                                <Button text="applied" type="default" stylingMode="text" className="btn-s-r" icon="m-popup" onClick={() => onOpenApplied(m?.inspection_library_id, m?.inspection_type_name)} />
                            </div>
                            <div className="hierarchy">
                                <i className="dx-icon-m-FwMasters"></i>
                                <p>{m?.child.length !== 0 ? m?.child.length : ''}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="children" id={m?.inspection_library_id}>
                    {m?.child !== undefined &&
                        m?.child.length !== 0 &&
                        m.child.map((k) => {
                            return <SecondNode isStartSecond={isStartSecond} isStartThird={isStartThird} data={k} />;
                        })}
                </div>
            </div>

            <AppliedItemPopup
                visible={visible}
                onClose={() => setVisible(false)}
                // onOk={() => setVisible(true)}
                item={selectedItem}
            />
        </>
    );
});

export const SecondNode = forwardRef((props, ref) => {
    const { isStartSecond = false, isStartThird = false, data, getChecked, setChecked } = props;
    const translation = useTranslation();

    const [cnt, setCnt] = useState();
    const [child, setChild] = useState([]);

    const onChange = (e) => {
        let checked = e.target.checked;
        let id = e.target.id;

        if (checked) {
            setChecked([...getChecked, id]);
        } else {
            // 체크해제
            let copy = getChecked.filter((item) => {
                return item !== id;
            });
            setChecked(copy);
        }
    };

    const target = data?.inspection_library_defect_type_json;
    useEffect(() => {
        let json = target !== null && target !== undefined && target !== 0 ? JSON.parse(target) : null;

        if (json !== null) {
            setCnt(json.length);
            setChild(json);
        }
    }, [data]);

    return (
        <div className="second-node">
            <div className="second-node-card">
                <div className="tree-arrow">
                    <label
                        style={{
                            display: cnt !== null && cnt !== undefined ? 'block' : 'none',
                        }}
                    >
                        <input type="checkbox"></input>
                    </label>
                </div>
                {isStartSecond && (
                    <div className="second-node-tit">
                        <label>
                            <input type="checkbox" onChange={onChange} id={data?.defect_type_id}></input>
                        </label>
                    </div>
                )}

                <div className="second-node-content">
                    <div className="info">
                        <div>
                            <p className="code">
                                <span>Code: </span>
                                {data?.defect_type_code}
                            </p>
                            <h6>{data?.defect_type_name}</h6>
                        </div>
                        <ul>
                            <li>
                                <span>Measure formula</span>
                                <p>{data?.inspection_library_defect_type_formula}</p>
                            </li>
                            <li>
                                <div className="grid-badge">
                                    <span className="grid-badge-use">{data?.inspection_library_defect_type_is_active ? translation.use : translation.notUse}</span>
                                </div>
                            </li>
                            <li>
                                <span>Comment</span>
                                <p>{data?.inspection_library_defect_type_comment}</p>
                            </li>
                        </ul>
                    </div>
                    <div className="tail">
                        <div className="hierarchy">
                            <i className="dx-icon-m-FwMasters"></i>
                            <p>{cnt !== null ? cnt : ''}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="children">
                {child !== undefined &&
                    child.length !== 0 &&
                    child.map((m) => {
                        return <ThirdNode isStartThird={isStartThird} data={m} />;
                    })}
            </div>
        </div>
    );
});

export const ThirdNode = forwardRef((props, ref) => {
    const { data, isStartThird = false, getDatas, setDatas, getChecked, setChecked } = props;

    // selected
    const onChange = (e) => {
        let checked = e.target.checked;
        let id = e.target.id;
        if (checked) {
            setChecked([...getChecked, id]);
        } else {
            // 체크해제
            let copy = getChecked.filter((item) => {
                return item !== id;
            });
            setChecked(copy);
        }
    };

    // delete
    const onDelete = (e, id) => {
        let copy = getDatas.filter((item) => {
            return item.id !== id;
        });
        setDatas(copy);
    };

    return (
        <div className="third-node">
            <div className="third-node-card">
                {isStartThird && (
                    <div className="third-node-tit">
                        <label>
                            <input type="checkbox" id={data?.id} onChange={onChange}></input>
                        </label>
                    </div>
                )}
                <div className="third-node-content">
                    <div className="info">
                        <ul>
                            <li>
                                <span>Measure condition</span>
                                <p>{data?.inspection_library_condition}</p>
                            </li>
                            <li>
                                <span>Measure value</span>
                                <p>{data?.inspection_library_condition_value}</p>
                            </li>
                            <li>
                                <span>Gap+</span>
                                <p>{data?.inspection_library_condition_gap_plus}</p>
                            </li>
                            <li>
                                <span>Gap-</span>
                                <p>{data?.inspection_library_condition_gap_minus}</p>
                            </li>
                            <li>
                                <span>Unit</span>
                                <p>{data?.inspection_library_condition_unit}</p>
                            </li>
                        </ul>
                    </div>
                    {isStartThird && (
                        <div className="tail">
                            <Button className="btn-s-r btn-hover-red" type="normal" stylingMode="text" icon="trash" onClick={(e) => onDelete(e, data?.id)} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
});
