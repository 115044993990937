import { Button, DataGrid } from "devextreme-react";
import { Column, Pager, Paging, Scrolling } from "devextreme-react/data-grid";

const CustomDataGrid = ({
    id,
    dataSource,
    headers = [],
    defaultPageSize = 10,
    callback = () => { },
},) => {
    const cellRender = (cell, data, isHeader, isLink) => {
        const caption = data?.caption;
        const styling = data?.styling
        // set td style (align, vertical, background)
        // const headerTd = document.querySelector(`#${cell.column.headerId}`);  // sometimes, headerId is not correct, I don't know why
        const headerTd = document.querySelector(`[gs-id="${id}"]`).querySelectorAll('.dx-header-row td')[cell.columnIndex];
        let targetTd;
        if (isHeader) {
            targetTd = headerTd;
        } else {
            const dg = headerTd.closest('.dx-datagrid');
            const trList = dg.querySelectorAll('.dx-data-row')[cell.rowIndex];
            targetTd = trList.querySelector(`[aria-colindex="${cell.columnIndex + 1}"]`);

            // this code makes the error that page is changed (first changing is working good, but second is not)
            // targetTd = dg.querySelector(`[aria-rowindex="${(pageSize * pageIndex) + cell.rowIndex + 1}"] [aria-colindex="${cell.columnIndex + 1}"]`);
        }

        let tdStyle = '';
        styling?.align.length > 0 && (tdStyle += `text-align: ${isLink ? "center" : styling.align};`);
        styling?.vertical.length > 0 && (tdStyle += `vertical-align: ${styling.vertical};`);
        styling?.background.length > 0 && (tdStyle += `background: ${styling.background};`);
        targetTd.setAttribute("style", tdStyle);

        // set text style (font-weight, font-style, text-color )
        let textStyle = {};
        styling?.font_style?.includes("dx-icon-m-font-bold") && (textStyle["fontWeight"] = "bold");
        styling?.font_style?.includes("dx-icon-m-font-italic") && (textStyle["fontStyle"] = "italic");
        styling?.font_color.length > 0 && (textStyle["color"] = styling?.font_color);
        
        if (!isLink) {
            return (
                <p style={textStyle}>
                    {caption}
                </p>
            );
        } else {
            return (
                <div style={textStyle}>
                    <Button className="btn-s-r btn-dropdown" icon="m-more" type="normal" stylingMode="text" />
                </div>
            )
        }
    }

    return (
        <div className="data-grid" ref={callback}>
            <DataGrid
                dataSource={dataSource}
            >
                <Scrolling mode="standard" />
                {
                    headers.map((header, i) => {
                        return (
                            <Column
                                key={i}
                                dataField={header.dataField}
                                caption={header.caption}
                                width={header?.width ? header.width : null}
                                headerCellRender={cell =>
                                    cellRender(
                                        cell,
                                        {
                                            caption: header.caption,
                                            styling: header.headerCellRender
                                        },
                                        true,
                                        false
                                    )}
                                cellRender={cell =>
                                    cellRender(
                                        cell,
                                        {
                                            caption: cell.text,
                                            styling: header.cellRender,
                                        },
                                        false,
                                        header.dataField === 'link'
                                    )}
                            />
                        )
                    })
                }
                <Pager
                    visible={true}
                    showPageSizeSelector={true}
                    infoText="{0} of {1} ({2} items)"
                    showInfo={true}
                    showNavigationButtons={true}
                />
                <Paging
                    defaultPageSize={defaultPageSize}
                />
            </DataGrid>
        </div>
    )
}

export default CustomDataGrid;