﻿//test 
import {
    getOperationFactory,
    getMoldInfo,
    deleteMoldInfoByMoldId,
    getItemInfoByMoldId
} from "../../apiInterface/machine/MoldAPI";
import { getUser, getLanguage, getIP, getMenuMappingInfo } from '../../apiInterface/utils/Common';
import { DropdownField, FilterComponent } from "../../components/common/FormField";
import { PageDropdown } from "../../components/common/PageDropdown";
import { Allotment } from "allotment";
import { Button } from "devextreme-react";
import { useRef } from "react";
import useErrorHandler from "../../components/customHooks/useErrorHandler";
import useTranslation from "../../components/customHooks/translations";
import BaseDataGrid from "../../components/common/BaseDataGrid";
import CustomStore from 'devextreme/data/custom_store';
import TreeCard from "../../components/common/TreeCard";
import Badge from "../../components/common/Badge";
import React, { useEffect, useState } from "react";
import { PageTitle } from "../../components/common/PageTitle.jsx";

let searchText = "";
const Mold = () => {
    const translation = useTranslation();
    const allotmentRef = useRef();
    const activeNotActiveJson = { true: translation.active, false: translation.not_active };
    const activeNotActiveClassName = { true: "grid-badge-use", false: "grid-badge-disabled" }
    const [userInfo, setUserInfo] = useState(getUser());
    const [selectedOperationFactory, setSelectedOperationFactory] = useState(null);
    const [operationFactoryList, setOperationFactoryList] = useState([]);
    const [rightPaneVisible, setRightPanelVisible] = useState(false);
    const [customDataSource, setCustomDataSource] = useState([]);
    const [customStoreObj, setCustomStoreObj] = useState([]);
    const [itemInfo, setItemInfo] = useState();
    const moldHeaderInfo = [
        { dataField: "machine_mold_no1", caption: "NO", dataType: "string", width: 100, alignment: "center", isFixed: true, allowReordering: true },
        { dataField: "machine_mold_code", caption: translation.machine_mold_code.toUpperCase(), dataType: "string", isFixed: true, allowReordering: true },
        { dataField: "machine_mold_name", caption: translation.machine_mold_name.toUpperCase(), dataType: "string", isFixed: true, allowReordering: true },
        { dataField: "machine_mold_no", caption: translation.machine_mold_no.toUpperCase(), dataType: "string", allowReordering: true },
        { dataField: "item_master_code", caption: translation.product_item_code.toUpperCase(), dataType: "string", allowReordering: true },
        { dataField: "item_master_name", caption: translation.product_item_name.toUpperCase(), dataType: "string", allowReordering: true },
        { dataField: "machine_mold_org", caption: translation.machine_mold_org.toUpperCase(), dataType: "string", allowReordering: true },
        {
            dataField: "machine_mold_is_active", caption: translation.machine_mold_is_active.toUpperCase(), dataType: "string",
            width: 130, alignment: "center", allowReordering: true,
            cellRender: (e) => <Badge className={activeNotActiveClassName[e.value]} text={activeNotActiveJson[e.value]} />,
        },
        { dataField: "machine_mold_comment", caption: translation.machine_mold_comment.toUpperCase(), dataType: "string", allowReordering: true },
        { dataField: "machine_mold_modified_by", caption: translation.lastModifiedBy.toUpperCase(), dataType: "string", width: 300, allowReordering: true },
        { dataField: "machine_mold_modified_date", caption: translation.lastModifiedDate.toUpperCase(), dataType: "string", width: 150, allowReordering: true },
    ];
    const [header, setHeader] = useState(moldHeaderInfo);
    const errorHandler = useErrorHandler();
     
    const operationFactory = async () => {
        const param = { user_company_id: userInfo.user_company_id };

        const res = await errorHandler(getOperationFactory, param);
        if (res) {
            setOperationFactoryList(res.data.o_data);
            if (res.data.o_data.length > 0) {
                setSelectedOperationFactory(res.data.o_data[0].operation_factory_id);
            }
        }
    }

    useEffect(() => {
        searchText = "";
        operationFactory();
    }, []);

    const onDropdownChanged = async (e) => {
        const operation_factory_id = e.value;
        setSelectedOperationFactory(operation_factory_id);
        setItemInfo([]);
        makeCustomStore(operation_factory_id);
    }

    const makeCustomStore = (factoryId = selectedOperationFactory) => {
        const customStore = new CustomStore({
            load: async (loadOptions) => {
                let skip = 0;
                let take = 10;
                if (loadOptions["skip"]) skip = loadOptions["skip"];
                if (loadOptions["take"]) take = loadOptions["take"];
                const pageNo = parseInt(skip / take) + 1;
                const language = getLanguage();
                const user = getUser();
                const param = {
                    user_company_id: user.user_company_id,
                    operation_factory_id: factoryId,
                    sys_lang_code: language,
                    row_count: take,
                    page_no: pageNo,
                    search_text: searchText
                }

                const res = await errorHandler(getMoldInfo, param);
                if (res) {
                    const equipmentInfo = res.data.o_data;
                    const pagingDict = {
                        data: equipmentInfo,
                        totalCount: res.data.count
                    }
                    return pagingDict;
                } else {
                    return {
                        data: [],
                        totalCount: 0,
                    }
                }
            },
            remove: (key) => {
                deleteMoldInfo(key);
            }
        });
        setCustomStoreObj(customStore);
        setCustomDataSource(customStore);
    }

    const deleteMoldInfo = async (data) => {
        const user = getUser();
        const ipAddress = getIP();
        const { id: mappingId } = await getMenuMappingInfo();
        const queryData = {
            user_master_id: user.user_master_id,
            user_log_ip: ipAddress,
            user_menu_mapping_id: mappingId,
            machine_mold_id: data.machine_mold_id,
        }

        const res = await errorHandler(deleteMoldInfoByMoldId, queryData);
        if (res) {
            successToastr(translation.success_delete_row);
        }
    }

    const dropdown = <DropdownField
        label={translation.select_factory}
        dataSource={operationFactoryList}
        displayExpr="operation_factory_name"
        valueExpr="operation_factory_id"
        onValueChanged={onDropdownChanged}
        value={selectedOperationFactory}
        width={204}
    />

    const filtering = (keyword) => {
        searchText = keyword;
        makeCustomStore();
    }

    const filter = <FilterComponent
        label={translation.filter}
        onFilter={filtering}
        width={204}
    />

    const toggleRightPanel = () => {
        if (rightPaneVisible == true) {
            allotmentRef.current.resize([3, 1]);//you can set panel ratio.
        }
        setRightPanelVisible(!rightPaneVisible);
    }

    const onRowClick = async (e) => {
        const param = { machine_mold_id: e.data.machine_mold_id };
        const res = await errorHandler(getItemInfoByMoldId, param);
        if (res) {
            setItemInfo(res.data.o_data);
        }
    }

    return (
        <div className="mold">
            <div className="right-content-title">
                <PageTitle />
                <div className="page-buttons">
                    <div>
                        <Button
                            icon="ix-panel"
                            type="normal"
                            stylingMode="text"
                            className="btn-s-r"
                            onClick={toggleRightPanel}
                        />
                    </div>
                    <PageDropdown />
                </div>
            </div>
            <div className="right-content-body">

                <Allotment ref={allotmentRef}>
                    <Allotment.Pane minSize={0}>
                        <div className="left-panel">
                            <div className="container">
                                <BaseDataGrid
                                    toolbarModules={[dropdown, filter]}
                                    dataSource={customDataSource}
                                    headers={header}
                                    link="moldForm"
                                    selectMode="multiple"
                                    isRemote={true}
                                    isFullSize={false}
                                    onRowClick={onRowClick}
                                    params={{ operation_factory_id: selectedOperationFactory }}
                                />
                            </div>
                        </div>
                    </Allotment.Pane>
                    <Allotment.Pane minSize={0} visible={rightPaneVisible} snap={true}>
                        <div className="right-panel">
                            <div className="container">
                                <div className="panel-header">
                                    <p>Mold Details</p>
                                </div>
                                <div className="panel-body">
                                    <TreeCard
                                        data={itemInfo}
                                        nameExpr="operation_name"
                                        badgeExprs={["operation_type", "operation_code"]}
                                        parentExpr="operation_parent_id"
                                        keyExpr="operation_id"
                                        isSelectable={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </Allotment.Pane>
                </Allotment>
            </div>
        </div>
    );
};

export default Mold;
