import React, { useEffect, useState, useRef } from 'react';
import Button from 'devextreme-react/button';
import { getUser } from '../../apiInterface/utils/Common';
import useTranslation from '../../components/customHooks/translations';
import { SelectBox } from 'devextreme-react/select-box';
import { Popup } from 'devextreme-react/popup';
import { TreeList, Column, Selection } from 'devextreme-react/tree-list';
import { getDeptTree, getBusinessIds } from '../../apiInterface/userManagement/UserManagementAPI';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import { BaseField } from '../../components/common/FormField';
import '../../contents/styles/userManagement/user-management-info-form.css';
import '../../contents/styles/common/modal-popup.css';
import { ScrollView } from 'devextreme-react';
import { useLocation } from 'react-router-dom';

export default function FindDepartmentPopup(props) {
    const { visible, onOK = () => {}, onClose = () => {} } = props;

    const [dropdownData, setDropdownData] = useState([]);
    const [curDropdown, setCurDropdown] = useState(false);
    const [treeData, setTreeData] = useState([]);
    const [selectedDept, setSelectedDept] = useState(null);
    const deptTree = useRef();
    const errorHandler = useErrorHandler();
    const translation = useTranslation();
    const location = useLocation();

    const getBusiness = async () => {
        const user = getUser();
        let param = { user_company_id: user.user_company_id };
        const res = await errorHandler(getBusinessIds, param);

        if (res) {
            const data = res.data.o_data;
            setDropdownData(res.data.o_data);
            location.state.business;
            let userBusinessId = location.state?.business == null ? user.user_business_id : location.state.business;
            let item = res.data.o_data.find((e) => e.user_business_id == userBusinessId);
            if (item != null) setCurDropdown(item.user_business_id);
            else setCurDropdown(res.data.o_data[0]?.user_business_id);
        }
    };

    const dropdownChanged = async (ev) => {
        setCurDropdown(ev.value);
        const user = getUser();
        let param = {
            user_company_id: user.user_company_id,
            user_business_id: ev.value,
        };

        const res = await errorHandler(getDeptTree, param);
        if (res) {
            setTreeData(res.data.o_data);
        }
    };

    //find parent of department recursively. order is leaf to root.
    const findParent = (node) => {
        let path = [node];
        if (node.user_department_parent_id == 0) {
            return path;
        } else {
            let target = treeData.find((e) => {
                return e.user_department_id === node.user_department_parent_id;
            });
            return [...path, ...findParent(target)];
        }
    };

    const ok = (e) => {
        const path = findParent(selectedDept).reverse();
        onOK({ path: path, selected: selectedDept });
        close(e);
    };

    const close = (e) => {
        onClose(e);
        setSelectedDept(null);
    };

    const removeTag = () => {
        setSelectedDept(null);
        deptTree.current.instance.deselectAll();
    };

    //auto-expand TreeList
    useEffect(() => {
        if (deptTree.current != null) {
            treeData.forEach((e) => deptTree.current.instance.expandRow(e.user_department_id));
        }
    }, [treeData]);

    return (
        <Popup width={400} height={850} visible={visible} hideOnOutsideClick={true} onHiding={close} onInitialized={getBusiness} dragEnabled={true} position="center" showTitle={false}>
            <div className="popup-select-department">
                <div className="popup-header">
                    <p className="popup-title">{translation.select_department}</p>
                </div>
                <div className="popup-body">
                    <div className="selected-item-viewer">
                        <div className="icon-checked">
                            <i></i>
                        </div>
                        <div className="selected-item-field">
                            {selectedDept && (
                                <>
                                    <ul className="selected-items">
                                        <li className="item">
                                            <p className="item-name">{selectedDept?.user_department_name}</p>
                                            <i className="material-icons btn-delete" onClick={removeTag}>
                                                cancel
                                            </i>
                                        </li>
                                    </ul>
                                    <div className="btn-all-delete" onClick={removeTag}>
                                        <button>Delete All</button>
                                    </div>
                                </>
                            )}
                            {!selectedDept && <p className="comment-not-selected">{translation.user_master_is_select_department}</p>}
                        </div>
                    </div>

                    {/*<div className="selected-department">*/}
                    {/*    <h6>*/}
                    {/*        {selectedDept?.user_department_name &&*/}
                    {/*            <span className="badge-checked"></span>}*/}
                    {/*        {selectedDept ? selectedDept?.user_department_name : translation.user_master_is_select_department}*/}
                    {/*    </h6>*/}
                    {/*</div>*/}
                    <div className="select-business">
                        <BaseField label={translation.select_business} component={<SelectBox className="ix-tfs-r" displayExpr="user_business_name" valueExpr="user_business_id" dataSource={dropdownData} value={curDropdown} onValueChanged={dropdownChanged} />} />
                    </div>
                    <div className="item-select-box">
                        <ScrollView>
                            <div className="container-wrapper">
                                <div className="container">
                                    <TreeList ref={deptTree} dataSource={treeData} showRowLines={true} showBorders={true} keyExpr="user_department_id" parentIdExpr="user_department_parent_id" onSelectionChanged={(e) => setSelectedDept(e.selectedRowsData[0])}>
                                        <Selection mode="single" />
                                        <Column dataField="user_department_name" />
                                    </TreeList>
                                </div>
                            </div>
                        </ScrollView>
                    </div>
                    {/*<div className="department-path">*/}
                    {/*    <div className="department-path-list">*/}
                    {/*        <TreeList*/}
                    {/*            ref={deptTree}*/}
                    {/*            dataSource={treeData}*/}
                    {/*            showRowLines={true}*/}
                    {/*            showBorders={true}*/}
                    {/*            keyExpr="user_department_id"*/}
                    {/*            parentIdExpr="user_department_parent_id"*/}
                    {/*            onSelectionChanged={(e) => setSelectedDept(e.selectedRowsData[0])}*/}
                    {/*        >*/}
                    {/*            <Selection mode="single" />*/}
                    {/*            <Column dataField="user_department_name" />*/}
                    {/*        </TreeList>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
                <div className="popup-footer">
                    <Button text={translation.cancel} type="normal" stylingMode="text" className="btn-s-r" onClick={close} />
                    <Button text={translation.confirm} type="default" stylingMode="contained" className="btn-s-r" onClick={ok} />
                </div>
            </div>
        </Popup>
    );
}
