import React from 'react';

export default function TreeListPath(props) {
    return (
        <div className="parent-item">
            {props.path &&
                <div>
                    {props.path.map((val, idx) => { return <p key={`level-${idx}`}>{val}</p>; })}
                </div>
            }
        </div>
    )
}