import React, { useRef } from 'react';
import { Button, ScrollView } from 'devextreme-react';
import { DropdownField, FilterComponent } from '../../components/common/FormField';
import useTranslation from '../../components/customHooks/translations';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';
import BaseDataGrid from '../../components/common/BaseDataGrid';
import { useState } from 'react';
import { getLanguage, getUser } from '../../apiInterface/utils/Common';
import { getFactoryLineProcess, getOperationFactory } from '../../apiInterface/aasFile/DatasetManagementAPI';
import { useEffect } from 'react';
import CustomStore from 'devextreme/data/custom_store';

const FACTORY_NAME = 'operation_factory_name';
const FACTORY_ID = 'operation_factory_id';
let searchText = '';
export default function AddProcessPopup(props, ref) {
    const { onClose = () => {}, onSave = () => {} } = props;
    const translation = useTranslation();
    const errorHandler = useErrorHandler();
    const [dropdown, setDropdown] = useState([]);
    const [curDropdown, setCurDropdown] = useState(null);
    const [gridData, setGridData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [okBtnDisabled, setOkBtnDisabled] = useState(true);
    const gridRef = useRef();

    const header = [
        { dataField: 'operation_line_name', caption: translation.operation_line_name, dataType: 'string', allowReordering: true },
        { dataField: 'operation_process_seq', caption: translation.operation_process_seq, dataType: 'string', alignment: 'center', allowReordering: true },
        { dataField: 'operation_process_name', caption: translation.operation_process_name, dataType: 'string', allowReordering: true },
        { dataField: 'user_business_name', caption: translation.user_business_name, dataType: 'string', allowReordering: true },
    ];

    useImperativeHandle(ref, () => ({
        test: () => {
            alert('component is already rendered');
        },
        exit: onClose,
    }));

    useEffect(() => {
        searchText = '';
        getDropdownValues();
    }, []);

    const getDropdownValues = async () => {
        let param = { user_company_id: getUser().user_company_id };
        const res = await errorHandler(getOperationFactory, param);
        if (res) {
            setDropdown(res.data.o_data);
            setCurDropdown(res.data.o_data[0][FACTORY_ID]);
        }
    };

    const dropdownChanged = async (e) => {
        getGridData(e.value);
        setCurDropdown(e.value);
    };

    // const getGridData = async (factoryId) => {
    //     let param = {
    //         user_company_id: getUser().user_company_id,
    //         operation_factory_id: factoryId,
    //         sys_lang_code: getLanguage(),
    //         row_count: 9999,
    //         page_no: 1,
    //         searchtext: searchText,
    //     };

    //     const res = await errorHandler(getFactoryLineProcess, param);
    //     if (res) {
    //         setGridData(res.data.o_data);
    //     }
    // };
    const getGridData = async (factoryId) => {
        const customStore = new CustomStore({
            load: async (loadOptions) => {
                let skip = 0;
                let take = 10;
                if (loadOptions['skip']) skip = loadOptions['skip'];
                if (loadOptions['take']) take = loadOptions['take'];
                const pageNo = parseInt(skip / take) + 1;
                const param = {
                    user_company_id: getUser().user_company_id,
                    operation_factory_id: factoryId,
                    sys_lang_code: getLanguage(),
                    row_count: take,
                    page_no: pageNo,
                    searchtext: searchText,
                };
                const res = await errorHandler(getFactoryLineProcess, param);
                if (res) {
                    return {
                        data: res.data.o_data,
                        totalCount: res.data.count ? res.data.count : 0,
                    };
                }

                return { data: [], totalCount: 0 };
            },
            //remove: async (key) => {
            //    deleteBadTypeInfo(key);
            //}
        });

        setGridData(customStore);
    };

    const onRowSelected = (rows) => {
        if (rows != null) {
            setOkBtnDisabled(false);
            setSelectedRows(rows);
        } else {
            setSelectedRows([]);
            setOkBtnDisabled(true);
        }
    };

    const dropdownm = <DropdownField label="Select Factory Group" dataSource={dropdown} displayExpr={FACTORY_NAME} valueExpr={FACTORY_ID} value={curDropdown} isSearchable={true} onValueChanged={dropdownChanged} />;

    const filter = (
        <FilterComponent
            isWritable={true}
            label={translation.filter}
            onFilter={(value) => {
                searchText = value;
                getGridData(curDropdown);
            }}
        />
    );

    const removeTag = () => {
        setSelectedRows([]);

        const instance = gridRef.current.getInstance();
        instance.deselectAll();
    };

    return (
        <div className="popup-select-process-and-line">
            <div className="popup-header">
                <p className="popup-title">{translation.select_process_and_line}</p>
            </div>
            <div className="popup-body">
                <div className="selected-item-viewer">
                    <div className="icon-checked">
                        <i></i>
                    </div>
                    <div className="selected-item-field">
                        {selectedRows?.length != 0 && (
                            <>
                                <ul className="selected-items">
                                    <li className="item">
                                        <p className="item-name">{selectedRows?.operation_process_name}</p>
                                        <i className="material-icons btn-delete" onClick={removeTag}>
                                            cancel
                                        </i>
                                    </li>
                                </ul>
                                <div className="btn-all-delete" onClick={removeTag}>
                                    <button>{translation.delete_all}</button>
                                </div>
                            </>
                        )}
                        {selectedRows?.length == 0 && <p className="comment-not-selected">{translation.select_process_and_line_notice}</p>}
                    </div>
                </div>
                <div className="item-select-box">
                    <ScrollView>
                        <div className="container-wrapper">
                            <div className="container">
                                <BaseDataGrid toolbarModules={[dropdownm, filter]} dataSource={gridData} allowModify={false} onSelectionChanged={onRowSelected} selectMode="single" headers={header} ref={gridRef} isRemote={true} />
                            </div>
                        </div>
                    </ScrollView>
                </div>
            </div>
            <div className="popup-footer">
                <Button width={86} text={translation.cancel} type="normal" stylingMode="text" className="btn-s-r" onClick={onClose} />
                <Button text={translation.confirm} width={86} type="default" stylingMode="contained" className="btn-s-r" onClick={() => onSave(selectedRows)} disabled={okBtnDisabled} />
            </div>
        </div>
    );
}

AddProcessPopup = forwardRef(AddProcessPopup);
