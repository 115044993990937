import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import useTranslation from '../customHooks/translations';
const MySwal = withReactContent(Swal);

const getIconByType = (iconType) => {
    let returnURL;

    switch (iconType) {
        case 'good':
            returnURL =
                "data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' height='48' width='48'%3E%3Cpath fill='%2352C773' d='M24 44q-4.15 0-7.8-1.575-3.65-1.575-6.35-4.275-2.7-2.7-4.275-6.35Q4 28.15 4 24t1.575-7.8Q7.15 12.55 9.85 9.85q2.7-2.7 6.35-4.275Q19.85 4 24 4q3.15 0 5.975.9 2.825.9 5.225 2.5l-3.05 3.05Q30.35 9.4 28.3 8.8q-2.05-.6-4.3-.6-6.55 0-11.175 4.625Q8.2 17.45 8.2 24q0 6.55 4.625 11.175Q17.45 39.8 24 39.8q6.55 0 11.175-4.625Q39.8 30.55 39.8 24q0-.85-.075-1.625T39.5 20.85l3.4-3.4q.55 1.55.825 3.175Q44 22.25 44 24q0 4.15-1.575 7.8-1.575 3.65-4.275 6.35-2.7 2.7-6.35 4.275Q28.15 44 24 44Zm-2.75-10.85-8.55-8.6 2.95-2.95 5.65 5.65L41.05 7.5 44 10.4Z'/%3E%3C/svg%3E";
            break;
        case 'bad':
            returnURL =
                "data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' height='48' width='48'%3E%3Cpath fill='%23FF503D' d='M24 34q.7 0 1.175-.475.475-.475.475-1.175 0-.7-.475-1.175Q24.7 30.7 24 30.7q-.7 0-1.175.475-.475.475-.475 1.175 0 .7.475 1.175Q23.3 34 24 34Zm-1.35-7.65h3V13.7h-3ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 23.95q0-4.1 1.575-7.75 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24.05 4q4.1 0 7.75 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Z'/%3E%3C/svg%3E";
            break;
        case 'info':
            returnURL =
                "data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' height='48' width='48'%3E%3Cpath fill='%2352C773' d='M24 34q.7 0 1.175-.475.475-.475.475-1.175 0-.7-.475-1.175Q24.7 30.7 24 30.7q-.7 0-1.175.475-.475.475-.475 1.175 0 .7.475 1.175Q23.3 34 24 34Zm-1.35-7.65h3V13.7h-3ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 23.95q0-4.1 1.575-7.75 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24.05 4q4.1 0 7.75 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Z'/%3E%3C/svg%3E";
            break;
        default:
            returnURL = '';
    }
    return returnURL;
};

export async function swalConfirmation(swalSize, iconType, htmlString, confirmationButtonText, cancelButtonText, responseFn, confirmButtonColor) {
    let icon = getIconByType(iconType);
    swalSize = swalSize ? swalSize : '380';
    MySwal.fire({
        width: swalSize,
        imageUrl: icon,
        html: htmlString,
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: confirmationButtonText ? confirmationButtonText : 'OK',
        cancelButtonText: cancelButtonText ? cancelButtonText : 'Cancel',
        confirmButtonColor: confirmButtonColor,
        allowOutsideClick: false,
    }).then((result) => {
        return responseFn(result);
    });
}
export async function swal(swalSize, iconType, htmlString, confirmationButtonText, responseFn, confirmButtonColor) {
    let icon = getIconByType(iconType);
    swalSize = swalSize ? swalSize : '380';
    MySwal.fire({
        width: swalSize,
        imageUrl: icon,
        html: htmlString,
        confirmButtonText: confirmationButtonText ? confirmationButtonText : 'OK',
        confirmButtonColor: confirmButtonColor,
        allowOutsideClick: false,
    }).then((result) => {
        return responseFn(result);
    });
}

export const useSwal = () => {
    const translation = useTranslation();

    const success = (message = '', callback = () => {}) => {
        swalOneBtnPositive(message, 'good', (callback = callback));
    };

    const fail = (message = '', callback = () => {}) => {
        swalOneBtnPositive(message, 'bad', (callback = callback));
    };

    const warning = (message = '', callback = () => {}) => {
        swalTwoBtnNegative(message, '', (callback = callback));
    };

    const confirm = (message = '', callback = () => {}) => {
        swalTwoBtnPositive(message, 'good', callback);
    };

    const logout = (message = '', callback = () => {}) => {
        swalTwoBtnPositive(message, '', (callback = callback));
    };

    const info = (message = '', callback = () => {}) => {
        swalOneBtnInfo(message, 'info', callback);
    };

    //파란색 글씨 일 땐 span 태그, 검은색일 땐 p 태그로 감싸야함
    const swalTwoBtnPositive = (message, icon = 'good', callback = () => {}) => {
        var iconType = icon;
        var swalWidth = '380';
        var htmlString = message;
        var confirmButtonColor = '#3366FF';
        var cancelButtonText = translation.cancel;
        var confirmationButtonText = translation.confirm;
        var responseFn = callback;
        swalConfirmation(swalWidth, iconType, htmlString, confirmationButtonText, cancelButtonText, responseFn, confirmButtonColor);
    };
    const swalTwoBtnNegative = (message, icon = 'bad', callback = () => {}) => {
        var iconType = icon;
        var swalWidth = '380';
        var htmlString = message;
        var confirmButtonColor = '#FF503D';
        var cancelButtonText = translation.cancel;
        var confirmationButtonText = translation.confirm;
        var responseFn = callback;
        swalConfirmation(swalWidth, iconType, htmlString, confirmationButtonText, cancelButtonText, responseFn, confirmButtonColor);
    };
    const swalOneBtnPositive = (message, icon = 'good', callback = () => {}) => {
        var iconType = icon;
        var swalWidth = '380';
        var htmlString = message;
        var confirmButtonColor = '#3366FF';
        var confirmationButtonText = translation.confirm;
        var responseFn = callback;
        swal(swalWidth, iconType, htmlString, confirmationButtonText, responseFn, confirmButtonColor);
    };
    const swalOneBtnInfo = (message, icon = 'info', callback = () => {}) => {
        var iconType = icon;
        var swalWidth = '380';
        var htmlString = message;
        var confirmButtonColor = '#3366FF';
        var confirmationButtonText = translation.confirm;
        var responseFn = callback;
        swal(swalWidth, iconType, htmlString, confirmationButtonText, responseFn, confirmButtonColor);
    };
    const swalOneBtnNegative = (message, icon = 'bad', callback = () => {}) => {
        var iconType = icon;
        var swalWidth = '380';
        var htmlString = message;
        var confirmButtonColor = '#FF503D';
        var confirmationButtonText = translation.confirm;
        var responseFn = callback;
        swal(swalWidth, iconType, htmlString, confirmationButtonText, responseFn, confirmButtonColor);
    };

    return { success, fail, warning, confirm, logout, info };
};
