import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import useTranslation from '../../components/customHooks/translations';
import { getUser, getIP, getMenuMappingInfo, getLanguage } from '../../apiInterface/utils/Common';
import { TreeView } from 'devextreme-react';
import { getProductGroup, getItemCategory, getItemMaster, deleteItemMaster } from '../../apiInterface/item/ItemMasterAPI';
import { FilterComponent, SearchableField } from '../../components/common/FormField';
import CustomStore from 'devextreme/data/custom_store';
import BaseDataGrid from '../../components/common/BaseDataGrid';
import '../../contents/styles/item/item-master.css';
import { successToastr } from '../../components/common/Toastr';
import { useRef } from 'react';
import { PageDropdown } from '../../components/common/PageDropdown';
import SelectBox from 'devextreme-react/select-box';
import Badge from '../../components/common/Badge';
import { PageTitle } from '../../components/common/PageTitle.jsx';

let searchKeyword = null;
export default function ItemMaster(props) {
    const navigate = useNavigate();
    const errorHandler = useErrorHandler();
    const language = getLanguage();
    const translation = useTranslation();
    const user = getUser();
    const ipAddress = getIP();
    const [category, setCategory] = useState([]);
    const [curCategory, setCurCategory] = useState(null);
    const [itemMaster, setItemMaster] = useState([]);
    const [allowAdd, setAllowAdd] = useState(false);
    const treeViewRef = useRef();
    const categoryHeader = [{ dataField: 'item_category_name', caption: translation.item_category_name, dataType: 'string', allowReordering: true }];
    const activeNotActiveJson = { true: translation.active, false: translation.not_active };
    const activeNotActiveClassName = { true: 'grid-badge-use', false: 'grid-badge-disabled' };
    const itemHeader = [
        { dataField: 'item_master_no', caption: 'NO', dataType: 'string', width: 100, alignment: 'center', isFixed: true, allowReordering: true },
        { dataField: 'item_master_code', caption: translation.item_master_code.toUpperCase(), dataType: 'string', isFixed: true, allowReordering: true },
        { dataField: 'item_master_name', caption: translation.item_master_name.toUpperCase(), dataType: 'string', isFixed: true, allowReordering: true },
        { dataField: 'item_master_comment', caption: translation.item_master_comment.toUpperCase(), dataType: 'string', allowReordering: true },
        { dataField: 'item_master_customer', caption: translation.item_master_customer.toUpperCase(), dataType: 'string', allowReordering: true },
        {
            dataField: 'item_master_is_active',
            caption: translation.item_master_is_active.toUpperCase(),
            dataType: 'string',
            width: 130,
            alignment: 'center',
            allowReordering: true,
            cellRender: (e) => <Badge className={activeNotActiveClassName[e.value]} text={activeNotActiveJson[e.value]} />,
        },
        { dataField: 'item_master_material', caption: translation.item_master_material.toUpperCase(), dataType: 'string', allowReordering: true },
        { dataField: 'item_master_eo_no', caption: translation.item_master_eo_no.toUpperCase(), dataType: 'string', allowReordering: true },
        { dataField: 'item_master_eo_date', caption: translation.item_master_eo_date.toUpperCase(), dataType: 'string', allowReordering: true },
        { dataField: 'item_master_size', caption: translation.item_master_size.toUpperCase(), dataType: 'string', allowReordering: true },
        { dataField: 'item_master_weight', caption: translation.item_master_weight.toUpperCase(), dataType: 'string', allowReordering: true },
        { dataField: 'product_group', caption: translation.product_group_name.toUpperCase(), dataType: 'string', allowReordering: true },
        { dataField: 'item_master_modified_by', caption: translation.lastModifiedBy.toUpperCase(), dataType: 'string', allowReordering: true },
        { dataField: 'item_master_modified_date', caption: translation.lastModifiedDate.toUpperCase(), dataType: 'string', allowReordering: true },
    ];

    //init
    useEffect(() => {
        const init = async () => {
            searchKeyword = null;
            const { id: mappingId } = await getMenuMappingInfo();
            itemCategory(mappingId);
        };
        init();
    }, []);

    const itemCategory = async (mapping_id) => {
        const param = {
            user_company_id: user.user_company_id,
            menu_mapping_id: mapping_id,
            user_master_id: user.user_master_id,
        };

        const res = await errorHandler(getItemCategory, param);
        if (res) {
            res.data.o_data.forEach((data) => {
                data.icon = 'activefolder';
            });
            setCategory(res.data.o_data);
        }
    };

    //auto expand treeView
    useEffect(() => {
        if (treeViewRef.current != null) {
            const instance = treeViewRef.current.instance;
            instance.expandAll();
        }
    }, [category]);

    const categorySelected = (event) => {
        setAllowAdd(true);
        const categoryId = event.itemData.item_category_id;
        setCurCategory(categoryId);
        sessionStorage.setItem('categoryId', categoryId);
        makeCustomStore(categoryId);
    };

    const makeCustomStore = (categoryId = curCategory, key = null) => {
        const customStore = new CustomStore({
            key: key,
            load: async (loadOptions) => {
                return await load(categoryId, loadOptions);
            },
            remove: (key) => {
                removeItem(key);
            },
        });
        setItemMaster(customStore);
    };

    const load = async (categoryId, loadOptions) => {
        let skip = 0;
        let take = 10;
        if (loadOptions) {
            if (loadOptions['skip']) skip = loadOptions['skip'];
            if (loadOptions['take']) take = loadOptions['take'];
        }
        const pageNo = parseInt(skip / take) + 1;
        const param = {
            user_company_id: user.user_company_id,
            sys_lang_code: language,
            item_category_id: categoryId,
            row_count: take,
            page_no: pageNo,
            search_text: searchKeyword,
        };

        const res = await errorHandler(getItemMaster, param);
        if (res) {
            return {
                data: res.data.o_data,
                totalCount: res.data.count,
            };
        }
        return {
            data: [],
            totalCount: 0,
        };
    };

    const removeItem = async (key) => {
        const { id: mapping_id } = await getMenuMappingInfo();
        const param = {
            user_master_id: user.user_master_id,
            user_log_ip: ipAddress,
            user_menu_mapping_id: mapping_id,
            item_master_id: key.item_master_id,
        };

        const res = await errorHandler(deleteItemMaster, param);
        if (res !== 'fail' && res.message === 'MSG_100') {
            successToastr(translation.success_delete_row);
            //do something if success...
        }
    };

    const filterring = (text) => {
        searchKeyword = text;
        if (text === '') searchKeyword = null;
        makeCustomStore();
    };

    const filter = <FilterComponent label={translation.filter} onFilter={filterring} width={204} />;

    return (
        <>
            <div className="item-master">
                <div className="right-content-title">
                    <PageTitle />
                    <PageDropdown />
                </div>
                <div className="right-content-body">
                    <div className="card left-card">
                        <div className="card-header">
                            <h6 className="card-title">{translation.item_classification}</h6>
                        </div>
                        <div className="card-body">
                            <div className="group-grid">
                                {/* groduct group grid */}
                                {category?.length == 0 || category == undefined ? (
                                    <a onClick={() => navigate('/ItemCategory')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                                        {translation.add_new_item_category}
                                    </a>
                                ) : (
                                    <TreeView id="treeView" keyExpr="item_category_id" parentIdExpr="item_category_parent_id" displayExpr="item_category_name" dataStructure="plain" items={category} onItemClick={categorySelected} ref={treeViewRef} />
                                )}
                            </div>
                        </div>
                    </div>
                    {/* item grid */}
                    <div className="container">
                        <BaseDataGrid toolbarModules={[filter]} dataSource={itemMaster} allowModify={true} headers={itemHeader} isRemote={true} isPaging={true} selectMode="multiple" link="ItemMasterForm" allowAdd={allowAdd} isFullSize={true} />
                    </div>
                </div>
            </div>
        </>
    );
}
