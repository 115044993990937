import React, { useState, useContext } from 'react';

const LanguageContext = React.createContext();
export const useLanguageContext = () => useContext(LanguageContext);

export default function LanguageContextProvider({ children }) {
    // let sessionLang = sessionStorage.getItem('lang')
    let localLang = localStorage.getItem('lang');
    //we are using KO for Backend localization and it's mapped with KR

    // if (sessionLang === 'null' || sessionLang === null) sessionStorage.setItem('lang', 'ko');
    // const defaultLang = sessionStorage.getItem('lang') == 'ko' ? 'ko' : sessionStorage.getItem('lang');

    if (localLang === 'null' || localLang === null) localStorage.setItem('lang', 'ko');
    const defaultLang = localLang == 'ko' ? 'ko' : localLang;

    const [language, changeLanguage] = useState(defaultLang);

    return <LanguageContext.Provider value={{ language, changeLanguage }}>{children}</LanguageContext.Provider>;
}
