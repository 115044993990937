import React, { useEffect, useState } from 'react';
import { getUser, getIP, getMenuMappingInfo, getLanguage } from '../../apiInterface/utils/Common';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from 'devextreme-react/button';
import TextBox from 'devextreme-react/text-box';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import useTranslation from '../../components/customHooks/translations';
import { Validator, RequiredRule, EmailRule, PatternRule, CustomRule } from 'devextreme-react/validator';
import { SelectBox } from 'devextreme-react/select-box';
import { swal, useSwal } from '../../components/common/Swal';
import { getBusinessIds, getFactoryInfo, insertFactoryInfo, updateFactoryInfo, deleteFactoryInfo, getFactoryById } from '../../apiInterface/factory/FactoryAPI';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import { DropdownField, TextboxField } from '../../components/common/FormField';
import { actionDirect, moveFocus, submit } from '../../components/common/FormFuncWithEnter';
import { PageTitle } from '../../components/common/PageTitle.jsx';

export default function FactoryForm(props) {
    const user = getUser();
    const ipAddress = getIP();
    const navigate = useNavigate();
    const [data, setData] = useState(props.data);
    const translation = useTranslation();
    const location = useLocation();
    const errorHandler = useErrorHandler();
    const language = getLanguage();
    const { success } = useSwal();

    const onInsert = async () => {
        const { id: mapping_id } = await getMenuMappingInfo();
        const queryData = {
            user_master_id: user.user_master_id,
            user_log_ip: ipAddress,
            menu_mapping_id: mapping_id,
            user_business_id: sessionStorage.getItem('businessId'),
            operation_factory_name: data.operation_factory_name,
            operation_factory_code: data.operation_factory_code,
            operation_factory_is_active: data.operation_factory_is_active,
            operation_factory_comment: data.operation_factory_comment,
        };

        const response = await errorHandler(insertFactoryInfo, queryData);
        if (response) {
            const cb = () => exitForm('/Factory');
            success(translation.success_insert, cb);
        }
    };

    const onUpdate = async () => {
        const { id: mapping_id } = await getMenuMappingInfo();
        const queryData = {
            user_master_id: user.user_master_id,
            user_log_ip: ipAddress,
            menu_mapping_id: mapping_id,
            user_business_id: sessionStorage.getItem('businessId'),
            operation_factory_id: data.operation_factory_id,
            operation_factory_code: data.operation_factory_code,
            operation_factory_name: data.operation_factory_name,
            operation_factory_is_active: data.operation_factory_is_active,
            operation_factory_comment: data.operation_factory_comment,
        };

        const response = await errorHandler(updateFactoryInfo, queryData);
        if (response) {
            const cb = () => exitForm('/Factory');
            success(translation.success_update, cb);
        }
    };

    const valueChanged = (value, key) => {
        setData((cur) => {
            let newData = { ...cur };
            newData[key] = value;
            return newData;
        });
    };

    const useItems = [
        { value: true, title: translation.use },
        { value: false, title: translation.notUse },
    ];

    //init
    useEffect(() => {
        if (location.state.title == 'Edit') {
            console.log(location.state.rowData);
            let param = {
                operation_factory_id: location.state.rowData.operation_factory_id,
                sys_lang_code: language,
            };
            const fetching = async () => {
                const res = await errorHandler(getFactoryById, param);
                if (res) {
                    res.data.o_data[0].modified_date = res.data.o_data[0].modified_date?.split('T')[0];
                    let temp = res.data.o_data[0];
                    temp.operation_factory_id = location.state.rowData.operation_factory_id;
                    setData(temp);
                }
            };
            fetching();
        } else {
            valueChanged(useItems[0].value, 'operation_factory_is_active');
        }
    }, []);

    const exitForm = (targetUrl) => {
        navigate(targetUrl, {
            state: {
                previousPage: location.pathname,
            },
        });
    };

    const submit = (e) => {
        const checkValid = e.validationGroup.validate().isValid;
        if (location.state.title === 'Add' && checkValid) onInsert();
        else if (location.state.title === 'Edit' && checkValid) onUpdate();
    };

    return (
        <div className="factory-form">
            <div className="right-content-title">
                <PageTitle pageState={location.state.title} />
            </div>
            <form onSubmit={actionDirect}>
                <div className="right-content-body">
                    <div className="container">
                        <div className="dx-fieldset">
                            <TextboxField
                                label={translation.operation_factory_name}
                                isEssential={true}
                                essentialMsg={translation.operation_factory_name + translation.is_required}
                                name="operation_factory_name"
                                value={data?.operation_factory_name}
                                onValueChanged={(e) => valueChanged(e.value, 'operation_factory_name')}
                                onEnterKey={moveFocus}
                                inputAttr={{ focusidx: 0, autofocus: true }}
                            />

                            <TextboxField
                                isEssential={true}
                                essentialMsg={translation.operation_factory_code + translation.is_required}
                                label={translation.operation_factory_code}
                                value={data?.operation_factory_code}
                                onValueChanged={(e) => valueChanged(e.value, 'operation_factory_code')}
                                readOnly={location.state?.title == 'Edit' ? true : false}
                                onEnterKey={moveFocus}
                                inputAttr={{ focusidx: 1 }}
                            />

                            <DropdownField
                                label={translation.use_or_not}
                                value={data?.operation_factory_is_active}
                                dataSource={useItems}
                                valueExpr="value"
                                displayExpr="title"
                                onValueChanged={(e) => valueChanged(e.value, 'operation_factory_is_active')}
                                onEnterKey={moveFocus}
                                inputAttr={{ focusidx: 2 }}
                                isEssential={true}
                                essentialMsg={translation.operation_factory_is_active + translation.is_required}
                                isSearchable={false}
                            />

                            <TextboxField label={translation.operation_factory_comment} value={data?.operation_factory_comment} onValueChanged={(e) => valueChanged(e.value, 'operation_factory_comment')} onEnterKey={moveFocus} inputAttr={{ focusidx: 3 }} />
                        </div>
                        <div className="block"></div>
                        {location.state.title == 'Edit' && (
                            <div>
                                <div className="dx-fieldset write-info">
                                    <div className="dx-field">
                                        <div className="dx-field-label">{translation.lastModifiedDate}</div>
                                        <div className="dx-field-value">{data?.modified_date}</div>
                                    </div>
                                    <div className="dx-field">
                                        <div className="dx-field-label">{translation.lastModifiedBy}</div>
                                        <div className="dx-field-value">
                                            {data?.modified_by_name}
                                            {data?.modified_by_duty && <span className="badge badge-job-position">{data?.modified_by_duty}</span>}
                                            {data?.modified_by_department && <span className="badge badge-team">{data?.modified_by_department}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="block"></div>
                            </div>
                        )}
                        <div>
                            <div className="btn-group dx-fieldset">
                                <Button type="normal" stylingMode="text" className="btn-s-r" onClick={(e) => exitForm('/Factory')}>
                                    <div>
                                        <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                        <span className="dx-button-text">{translation.cancel}</span>
                                    </div>
                                </Button>
                                <Button
                                    id="submitBtn"
                                    type="default"
                                    stylingMode="contained"
                                    className="btn-s-r"
                                    useSubmitBehavior={true}
                                    onClick={(e) => {
                                        if (location.state.title === 'Add') submit(e, onInsert);
                                        else if (location.state.title === 'Edit') submit(e, onUpdate);
                                    }}
                                >
                                    <div>
                                        <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                        <span className="dx-button-text">{translation.save}</span>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}
