import {
    makeTokenAxiosGetRequest, makeTokenAxiosPostRequest,
    makeTokenAxiosPutRequest, makeTokenAxiosDeleteRequest
} from "../utils/AxiosMask"

export const getBusinessInfoByCompanyId = async (data) => {
    var res;
    const urlPath = `business/`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};


export const updateBusinessInfoByCompanyId = async (data) => {
    var res;
    const urlPath = `business/`;
    try {
        res = await makeTokenAxiosPutRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};


export const deleteBusinessInfoByCompanyId = async (data) => {
    var res;
    const urlPath = `business/`;
    try {
        res = await makeTokenAxiosDeleteRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};


export const insertBusinessInfoByCompanyId = async (data) => {
    var res;
    const urlPath = `business/`;
    try {
        res = await makeTokenAxiosPostRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};

export const getBusinessInfoByBusinessId = async (data) => {
    var res;
    const urlPath = `business/get_user_business_by_id`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};

