import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useTranslation from '../../components/customHooks/translations';
import { PageDropdown } from '../../components/common/PageDropdown';
import { PageTitle } from '../../components/common/PageTitle.jsx';
import { TreeList, Column, SearchPanel } from 'devextreme-react/tree-list';
import BaseDataGrid from '../../components/common/BaseDataGrid';
import { getLanguage, getUser, getMenuMappingInfo, getIP } from '../../apiInterface/utils/Common';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import { Button, Popup, ScrollView } from 'devextreme-react';
import { SearchableField, TextboxField, DropdownField, FilterComponent, FileField } from '../../components/common/FormField';
import { getInspectionLibraryItemApplied } from '../../apiInterface/inspection/InspectionStandardLibraryAPI';

let searchText = '';
const DIVISION_CODE = 'INSPECTIONTYPE';
const AppliedItemPopup = (props) => {
    const { visible, onClose = () => {}, onOk = () => {}, item } = props;

    const translation = useTranslation();
    const errorHandler = useErrorHandler();
    const user = getUser();
    const lang = getLanguage();

    const [data, setData] = useState();
    const [dataSource, setDataSource] = useState([]);

    // get grid list
    const getDataGridInfo = async () => {
        const param = {
            user_company_id: user.user_company_id,
            inspection_library_id: item.inspection_library_id,
            sys_lang_code: lang,
            search_text: searchText,
        };
        const res = await errorHandler(getInspectionLibraryItemApplied, param);
        if (res) {
            setData({ ...res.data.o_data[0], ...{ inspection_type_name: item.inspection_type_name } });

            const rlt = [];
            res.data.o_data.map((m) => {
                if (m.inspection_standards_defect_type_json !== null && m.inspection_standards_defect_type_json !== undefined) {
                    let strToJson = JSON.parse(m.inspection_standards_defect_type_json);
                    if (strToJson.length > 0) {
                        strToJson.map((k) => {
                            let parent = { item_master_id: m.item_master_id, item_master_code: m.item_master_code, item_master_name: m.item_master_name, inspection_standards_comment: m.inspection_standards_comment };
                            let combine = { ...parent, ...k };
                            rlt.push(combine);
                        });
                    }
                }
            });
            setDataSource(rlt);
        }
    };

    const header = [
        { dataField: 'item_master_id', caption: translation.item_master_id, dataType: 'string', allowReordering: true, visible: false },
        { dataField: 'item_master_code', caption: translation.item_master_code, dataType: 'string', allowReordering: true },
        { dataField: 'item_master_name', caption: translation.item_master_name, dataType: 'string', allowReordering: true },
        { dataField: 'defect_type_id', caption: translation.defect_type_id, dataType: 'string', allowReordering: true, visible: false },
        { dataField: 'defect_type_code', caption: translation.defect_type_code, dataType: 'string', allowReordering: true },
        { dataField: 'defect_type_name', caption: translation.defect_type_name, dataType: 'string', allowReordering: true },
        { dataField: 'inspection_standards_comment', caption: '검사성적서 설명', dataType: 'string', allowReordering: true },
    ];

    const filtering = (keyword) => {
        searchText = keyword;
        getDataGridInfo();
    };

    const filter = <FilterComponent label={translation.filter} width={204} onFilter={filtering} />;

    useEffect(() => {
        if (visible) {
            getDataGridInfo();
        }
    }, [visible]);

    return (
        <Popup width={1100} height={850} visible={visible} hideOnOutsideClick={true} onHiding={onClose} dragEnabled={true} position="center" showTitle={false}>
            <div className="popup-applied-item">
                <div className="popup-header">
                    <p className="popup-title">Items with Inspection Standard library Applied</p>
                </div>
                <div className="popup-body">
                    <div className="library-info">
                        <ul>
                            <li>
                                <span>{translation.select_inspectionType}</span>
                                <h6>{data?.inspection_type_name}</h6>
                            </li>
                            <li>
                                <span>{translation.inspection_library_code}</span>
                                <h6>{data?.inspection_library_code}</h6>
                            </li>
                            <li>
                                <span>{translation.inspection_library_name}</span>
                                <h6>{data?.inspection_library_name}</h6>
                            </li>
                        </ul>
                    </div>
                    <div className="item-list">
                        <ScrollView width="100%">
                            <div className="container-wrapper">
                                <div className="container">
                                    {/* <FilterComponent label={translation.filter} width={204} onFilter={filtering} />
                                    <TreeList
                                        // id="dataSource"
                                        dataSource={dataSource}
                                        showRowLines={true}
                                        showBorders={true}
                                        columnAutoWidth={true}
                                        itemsExpr="items"
                                        dataStructure="tree"
                                    >
                                        <Column dataField="item_inspection_code" caption={translation.item_inspection_code} />
                                        <Column dataField="item_inspection_name" caption={translation.item_inspection_name} />
                                        <Column dataField="item_master_code" caption={translation.item_master_code} />
                                        <Column dataField="item_master_name" caption={translation.item_master_name} />
                                        <Column dataField="defect_type_code" caption={translation.defect_type_code} />
                                        <Column dataField="defect_type_name" caption={translation.defect_type_name} />
                                        <Column dataField="item_inspection_comment" caption={translation.item_inspection_comment} />
                                    </TreeList> */}

                                    <BaseDataGrid toolbarModules={[filter]} dataSource={dataSource} headers={header} allowModify={false} showCheckBox={false} />
                                </div>
                            </div>
                        </ScrollView>
                    </div>
                </div>
                <div className="popup-footer">
                    <Button width={86} text={translation.close} type="normal" stylingMode="text" className="btn-s-r" onClick={onClose} />
                </div>
            </div>
        </Popup>
    );
};
export default AppliedItemPopup;
