import React, { useEffect, useState, useRef } from "react";
import Button from 'devextreme-react/button';
import DataGrid, {
    Column, Editing, Paging, Lookup, RequiredRule, Toolbar, Item, Pager, Selection, Texts
} from 'devextreme-react/data-grid';
import { getUser, getIP } from '../../apiInterface/utils/Common';
import BtnTooltip from "../../components/common/BtnTooltip";
import { Popup } from 'devextreme-react/popup';
import useTranslation from "../../components/customHooks/translations";
import MoldAddPopup from "./MoldAddPopup";


export default function MoldGrid(props) {
    const {
        dataSource,
        setDataSource,
        width,
    } = props;

    const [popupVisible, setPopupVisible] = useState(false);
    const translation = useTranslation();

    const removeRows = () => { }

    const onPopupExit = (e) => {
        setPopupVisible(false);
    }

    const onPopupOk = (e) => {
        setDataSource(e);
    }
    const headers = [
        { dataField: "item_master_no", caption: translation.item_master_no, dataType: "number", isEdit: false, width: 100, alignment: "center", allowReordering: true, isFixed: true },
        { dataField: "item_master_name", caption: translation.item_master_name.toUpperCase(), dataType: "string", isRequired: true, allowReordering: true, isFixed: true },
        { dataField: "item_master_code", caption: translation.item_master_code.toUpperCase(), dataType: "string", allowReordering: true, isFixed: true },
        { dataField: "product_group", caption: translation.product_group.toUpperCase(), dataType: "string", allowReordering: true },
        { dataField: "item_master_comment", caption: translation.item_master_comment.toUpperCase(), dataType: "string", isEdit: false, width: 300, allowReordering: true },
    ]
    return (
        <div className="dx-fieldset">
            <div className="dx-field column">
                <div className="dx-field-label">
                    <p>{translation.quality_badtype_select_item}</p>
                </div>
                <div className="dx-field-value">
                    <div className="data-grid">
                        <DataGrid
                            dataSource={dataSource}
                            width={width}
                        >
                            <Selection mode="multiple" />
                            <Editing
                                mode="cell"
                                allowUpdating={true}
                            />
                            {
                                headers &&
                                headers.map((col) => {
                                    return <Column
                                        key={col.dataField}
                                        dataField={col.dataField}
                                        caption={col.caption}
                                        dataType={col.dataType}
                                        allowEditing={col.allowEditing}
                                    />
                                })
                            }
                            <Toolbar>
                                <Item>
                                    {/* remove button */}
                                    <Button
                                        id="dx-toolbar-item-1"
                                        icon="trash"
                                        className="btn-s-r btn-hover-red"
                                        onClick={removeRows}
                                        type="normal"
                                        stylingMode="text"
                                    />
                                </Item>
                                <Item>
                                    {/* add user button */}
                                    <Button
                                        id="dx-toolbar-item-2"
                                        className="btn-s-r"
                                        type="normal"
                                        stylingMode="contained"
                                        text={translation.add_item.toUpperCase()}
                                        icon="add"
                                        onClick={() => setPopupVisible(true)}
                                    >
                                    </Button>
                                </Item>
                            </Toolbar>
                        </DataGrid>
                    </div>

                    <MoldAddPopup
                        visible={popupVisible}
                        title={translation.add_item}
                        onExit={onPopupExit}
                        onClickOk={onPopupOk}
                    />

                    {/* Button Tooltip */}
                    <BtnTooltip target="#dx-toolbar-item-1" text={translation.delete} />
                    <BtnTooltip target="#dx-toolbar-item-2" text={translation.add} />
                    {/* <p className="info-comment"><span className="material-icons-outlined">info</span>{translation.info_add_group}</p> */}
                </div>
            </div>
        </div>
    );
}