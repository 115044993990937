import {
    makeTokenAxiosDeleteRequest,
    makeTokenAxiosGetRequest,
    makeTokenAxiosPostRequest,
    makeTokenAxiosPutRequest,
    makeTokenAxiosPutRequestParams
} from "../utils/AxiosMask"

const baseUrl = 'inspection_standard_library';

export const insertStandardLibrary = async (param) => {
    let res;
    const urlPath = `${baseUrl}/insert_inspection_library`;

    try {
        res = await makeTokenAxiosPostRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : "fail";
}

export const updateStandardLibrary = async (param) => {
    let res;
    const urlPath = `${baseUrl}/update_inspection_library`;

    try {
        res = await makeTokenAxiosPutRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : "fail";
}

export const deleteStandardLibrary = async (param) => {
    let res;
    const urlPath = `${baseUrl}/delete_inspection_library`;

    try {
        res = await makeTokenAxiosDeleteRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : "fail";
}

export const getOneInspectionLibrary = async (param) => {
    let res;
    const urlPath = `${baseUrl}/get_one_inspection_library`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : "fail";
}

export const getInspectionLibraryDT = async (param) => {
    let res;
    const urlPath = `${baseUrl}/get_inspection_libraryDT`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : "fail";
}

export const getSysDivisionCaptionByCode = async (param) => {
    let res;
    const urlPath = `${baseUrl}/get_sys_division_caption_by_code`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : "fail";
}

export const insertInspectionLibraryCopy = async (param) => {
    let res;
    const urlPath = `${baseUrl}/insert_inspection_library_copy`;

    try {
        res = await makeTokenAxiosPostRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : "fail";
}

export const getInspectionLibraryItemApplied = async (param) => {
    let res;
    const urlPath = `${baseUrl}/get_inspection_library_item_applied`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : "fail";
}