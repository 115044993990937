// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.template-master-grid {
    min-height: calc(100vh - 56px);
    /*height: auto;*/
    flex: 1 1;
}
.template-master-grid .right-content-body {
    display: flex;
    height: calc(100% - 44px);
    min-height: calc(100% - 44px);
    align-items: flex-start;
}
.template-master-grid .classification {
    width: 280px;
    /*height: 100%;*/
    height: calc(100vh - 136px);
    margin-right: 16px;
}
.template-master-grid .classification .card-body {
    width: 100%;
    height: calc(100% - 48px);
    /*padding: 12px 0px;*/
}
.template-master-grid .classification .card-body .classification-list {
    padding: 0px 0px 0px 24px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
}
.template-master-grid .classification .card-body .classification-list .dx-scrollable-content {
    padding: 12px 0px;
}
.template-master-grid .container {
    width: calc(100% - 296px);
    height: auto;
}
.template-master-grid .container .dx-datagrid-rowsview {
    max-height: unset;
}
.popup-template-setting .info-comment {
    margin-top: 14px;
}`, "",{"version":3,"sources":["webpack://./src/contents/styles/templateMaster/template-master-grid.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,gBAAgB;IAChB,SAAO;AACX;AACA;IACI,aAAa;IACb,yBAAyB;IACzB,6BAA6B;IAC7B,uBAAuB;AAC3B;AACA;IACI,YAAY;IACZ,gBAAgB;IAChB,2BAA2B;IAC3B,kBAAkB;AACtB;AACA;IACI,WAAW;IACX,yBAAyB;IACzB,qBAAqB;AACzB;AACA;IACI,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,gBAAgB;AACpB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,yBAAyB;IACzB,YAAY;AAChB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,gBAAgB;AACpB","sourcesContent":[".template-master-grid {\n    min-height: calc(100vh - 56px);\n    /*height: auto;*/\n    flex: 1;\n}\n.template-master-grid .right-content-body {\n    display: flex;\n    height: calc(100% - 44px);\n    min-height: calc(100% - 44px);\n    align-items: flex-start;\n}\n.template-master-grid .classification {\n    width: 280px;\n    /*height: 100%;*/\n    height: calc(100vh - 136px);\n    margin-right: 16px;\n}\n.template-master-grid .classification .card-body {\n    width: 100%;\n    height: calc(100% - 48px);\n    /*padding: 12px 0px;*/\n}\n.template-master-grid .classification .card-body .classification-list {\n    padding: 0px 0px 0px 24px;\n    width: 100%;\n    height: 100%;\n    overflow-y: auto;\n}\n.template-master-grid .classification .card-body .classification-list .dx-scrollable-content {\n    padding: 12px 0px;\n}\n.template-master-grid .container {\n    width: calc(100% - 296px);\n    height: auto;\n}\n.template-master-grid .container .dx-datagrid-rowsview {\n    max-height: unset;\n}\n.popup-template-setting .info-comment {\n    margin-top: 14px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
