﻿//test 
import React, { useEffect, useState } from "react";
import CustomStore from 'devextreme/data/custom_store';
import 'toastr/build/toastr.min.css'
import { getUser, getIP, getLanguage, getMenuMappingInfo } from '../../apiInterface/utils/Common';
import {
    getQualityBadTypeByCompanyId,
    deleteQualityBadTypeByCompanyId
} from "../../apiInterface/badTypeManagement/BadTypeManagementAPI";
import useTranslation from "../../components/customHooks/translations";
import BaseDataGrid from "../../components/common/BaseDataGrid";
import { FilterComponent } from "../../components/common/FormField";
import { successToastr } from "../../components/common/Toastr";
import useErrorHandler from "../../components/customHooks/useErrorHandler";
import { PageDropdown } from "../../components/common/PageDropdown";
import { PageTitle } from "../../components/common/PageTitle.jsx";
import Badge from "../../components/common/Badge";

let searchText = "";
const BadTypeManagementInfo = () => {
    const [customDataSource, setCustomDataSource] = useState([]);
    //const [customStoreObj, setCustomStoreObj] = useState([]);
    const translation = useTranslation();
    //const pageTitle = getMenuMappingInfo().caption;
    //const pageSize = 10;
    const errorHandler = useErrorHandler();
    const activeNotActiveJson = { true: translation.active, false: translation.not_active };
    const activeNotActiveClassName = { true: "grid-badge-use", false: "grid-badge-disabled" }

    const header = [
        { dataField: "quality_badtype_no", caption: translation.quality_badtype_no, dataType: "number", isEdit: false, width: 100, alignment: "center", allowReordering: true, isFixed: true },
        { dataField: "quality_badtype_code", caption: translation.quality_badtype_code.toUpperCase(), dataType: "string", isRequired: true, allowReordering: true, isFixed: true },
        { dataField: "quality_badtype_name", caption: translation.quality_badtype_name.toUpperCase(), dataType: "string", allowReordering: true, isFixed: true },
        {
            dataField: "quality_badtype_is_active", caption: translation.quality_badtype_is_active.toUpperCase(), dataType: "string", width: 100,
            width: 130, alignment: "center", allowReordering: true,
            cellRender: (e) => <Badge className={activeNotActiveClassName[e.value]} text={activeNotActiveJson[e.value]} />,
        },
        { dataField: "quality_badtype_comment", caption: translation.quality_badtype_comment.toUpperCase(), dataType: "string", allowReordering: true },
        { dataField: "quality_badtype_modified_by", caption: translation.lastModifiedBy.toUpperCase(), dataType: "string", isEdit: false, width: 300, allowReordering: true },
        { dataField: "quality_badtype_modified_date", caption: translation.lastModifiedDate.toUpperCase(), dataType: "string", isEdit: false, width: 150, alignment: "center", allowReordering: true },
    ]

    const getBadTypeInfo = async () => {
        const customStore = new CustomStore({
            load: async (loadOptions) => {
                const user = getUser();
                const lang = getLanguage();
                let skip = 0;
                let take = 10;
                if (loadOptions["skip"]) skip = loadOptions["skip"];
                if (loadOptions["take"]) take = loadOptions["take"];
                const pageNo = parseInt(skip / take) + 1;
                const param = {
                    user_company_id: user.user_company_id,
                    sys_lang_code: lang,
                    row_count: take,
                    page_no: pageNo,
                    search_text: searchText,
                }
                const res = await errorHandler(getQualityBadTypeByCompanyId, param);
                if (res) {
                    const badTypeInfo = res.data.o_data;
                    const pagingDict = {
                        data: badTypeInfo,
                        totalCount: res.data.count
                    }
                    return pagingDict;
                }
            },
            remove: async (key) => {
                deleteBadTypeInfo(key);
            }
        });
        //setCustomStoreObj(customStore);
        setCustomDataSource(customStore);
    }

    useEffect(() => {
        searchText = "";
        getBadTypeInfo();
    }, []);

    const deleteBadTypeInfo = async (selectData) => {
        const user = getUser();
        const ipAddress = getIP();
        const menuMappingInfo = await getMenuMappingInfo();
        const queryData = {
            user_master_id: user.user_master_id,
            user_log_ip: ipAddress,
            user_menu_mapping_id: menuMappingInfo.id,
            quality_badtype_id: selectData.quality_badtype_id,
        }
        const res = await errorHandler(deleteQualityBadTypeByCompanyId, queryData);
        if (res) {
            successToastr(translation.success_delete_row);
        }
    }

    const filtering = (keyword) => {
        searchText = keyword;
        getBadTypeInfo();
    }

    const filter = <FilterComponent
        label={translation.filter}
        width={204}
        onFilter={filtering}
    />

    return (
        <>
            <div className="badtype">
                <div className="right-content-title">
                    <PageTitle />
                    <PageDropdown />
                </div>
                <div className="right-content-body">
                    <div className="container">
                        <BaseDataGrid
                            toolbarModules={[filter]}
                            dataSource={customDataSource}
                            allowModify={true}
                            isRemote={true}
                            headers={header}
                            selectMode="multiple"
                            isPaging={true}
                            link="BadTypeManagementInfoForm"
                            isFullSize={true}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default BadTypeManagementInfo;
