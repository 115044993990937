﻿//test 
import React, { useEffect, useState } from "react";
import { getUser, getLanguage, getIP, getMenuMappingInfo } from '../../apiInterface/utils/Common';
import useTranslation from "../../components/customHooks/translations";
import CustomStore from 'devextreme/data/custom_store';
import Button from 'devextreme-react/button';
import "../../contents/styles/templateMaster/template-master-grid.css"
import "material-icons";
import TreeView from 'devextreme-react/tree-view';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import defaultTemplateIcon from "../../contents/image/icon-template.svg";
import TemplateMasterSettingPopup from "./TemplateMasterSettingPopup";
import { successToastr } from "../../components/common/Toastr";
import BaseDataGrid from "../../components/common/BaseDataGrid";
import { FilterComponent } from "../../components/common/FormField";
import { getMainTemplateCategoryListAPI, getTemplateGridByCategoryIdAPI, deleteTemplateMaster, getExistTemplateMasterInMenuMapping, getMainInUseTemplateCategoryListAPI } from '../../apiInterface/templateManagment/TemplateManagementAPI';
import { PageDropdown } from "../../components/common/PageDropdown";
import Badge from "../../components/common/Badge";
import { PageTitle } from "../../components/common/PageTitle.jsx";
import { useNavigate } from "react-router-dom";
import { useSwal } from "../../components/common/Swal";
import { BASE_URL } from "../../config";

export default function TemplateMasterGrid(props) {
    const user = getUser();
    const language = getLanguage();
    const translation = useTranslation();
    const navigate = useNavigate();
    const errorHandler = useErrorHandler();
    const { fail } = useSwal();
    const activeNotActiveJson = { true: translation.active, false: translation.not_active };
    const activeNotActiveClassName = { true: "grid-badge-use", false: "grid-badge-disabled" }
    const [treeList, setTreeList] = useState([]);
    const [customDataSource, setCustomDataSource] = useState([]);
    const [isPopupVisible, setPopupVisibility] = useState(false);
    const [enableAdd, setEnableAdd] = useState(false);
    const [searchText, setSearchText] = useState("");
    //const [curCategory, setCurCategory] = useState(-1);
    const [templateCategory, setTemplateCategory] = useState(-1);

    const templateHeaderInfo = [
        //{ dataField: "template_master_id", "caption": "template_master_id", "dataType": "number", width: 100, alignment: "center" },
        { dataField: "template_master_no", caption: "NO", dataType: "number", width: 100, alignment: "center", allowReordering: true },
        {
            dataField: "template_master_image_white",
            cellRender: (cell) => {
                if (cell.data.template_master_image_white) {
                    return <span><img src={cell.data.template_master_image_white} /></span>
                };
                return <span><img src={defaultTemplateIcon} /></span>;
            }, caption: translation.template_master_icon.toUpperCase(), dataType: "string", allowReordering: true
        },
        { dataField: "template_master_name", caption: translation.template_master_name.toUpperCase(), dataType: "string", allowReordering: true },
        { dataField: "template_master_comment", caption: translation.template_master_comment.toUpperCase(), dataType: "string", allowReordering: true },
        {
            dataField: "template_master_is_active", caption: translation.template_master_is_active.toUpperCase(), dataType: "string",
            width: 130, alignment: "center",
            cellRender: (e) => <Badge className={activeNotActiveClassName[e.value]} text={activeNotActiveJson[e.value]} />, allowReordering: true,
        },
        { dataField: "template_master_modified_by", caption: translation.lastModifiedBy.toUpperCase(), dataType: "string", width: 200, allowReordering: true },
        { dataField: "template_master_modified_date", caption: translation.lastModifiedDate.toUpperCase(), dataType: "string", width: 200, allowReordering: true },
        {
            dataField: "template_preview", caption: translation.preview.toUpperCase(), dataType: "string", width: 150, allowReordering: true, alignment: "center",
            cellRender: (cell) => {
                return <div className="btn-popup" onClick={() => showTemplatePreview(cell)}><i className="dx-icon-m-popup"></i>{translation.preview}</div>
            }
        },
    ];

    const getTemplateGridByCategoryId = async (categoryId = templateCategory.id, keyword = searchText) => {
        const customStore = new CustomStore({
            load: async (loadOptions) => {
                let skip = loadOptions["skip"] == null ? 0 : loadOptions["skip"];
                let take = loadOptions["take"] == null ? 10 : loadOptions["take"];
                const pageNo = parseInt(skip / take) + 1;
                const param = {
                    user_company_id: user.user_company_id,
                    sys_lang_code: language,
                    template_category_id: categoryId,
                    row_count: take,
                    page_no: pageNo,
                    search_text: keyword,
                }

                const res = await errorHandler(getTemplateGridByCategoryIdAPI, param);
                if (res) {
                    const useHistoryData = res.data.o_data;
                    const pagingDict = {
                        data: useHistoryData,
                        totalCount: res.data.count
                    }
                    pagingDict.data.map(test => {   // for image path
                        if (test.template_master_image_black !== "") test.template_master_image_black = `${BASE_URL}static_files/${test.template_master_image_black}`;
                        if (test.template_master_image_white !== "") test.template_master_image_white = `${BASE_URL}static_files/${test.template_master_image_white}`;
                        return test
                    })
                    return pagingDict;
                } else {
                    return {
                        data: [],
                        totalCount: 0
                    }
                }
            },
            remove: async (e) => {
                onRemove(e);
            }
        });
        setCustomDataSource(customStore);
        setEnableAdd(true);
    }

    const selectItem = async (e) => {
        const itemData = e.itemData;
        //if (!e.node.children.length) {
        setTemplateCategory(itemData);
        //setCurCategory(itemData);
        getTemplateGridByCategoryId(itemData.id);
        //}
    }

    const getMainTemplateCategoryList = async () => {
        const user = getUser();
        const language = getLanguage();

        let param = {
            user_company_id: user.user_company_id,
            sys_lang_code: language
        }

        const res = await errorHandler(getMainInUseTemplateCategoryListAPI, param);
            if (res && res.data.o_data.length > 0) {
                const transformed = res.data.o_data.map(({ template_category_id, template_category_name, template_category_parent_id, template_category_comment }) => (
                    { id: template_category_id, name: template_category_name, parentId: template_category_parent_id, icon: 'activefolder', comment: template_category_comment }
                ));
                setTreeList(transformed);
                //setCurCategory(transformed[0]);
                setTemplateCategory(transformed[0]);
                getTemplateGridByCategoryId(transformed[0].id);
            } else if (res && !(res.data.o_data.length > 0)) {
                let htmlString = `<span></span><p>${translation.no_template_classification}</p>`;
                let swalResFn = (res) => {
                    if (res.isConfirmed) {
                        setPopupVisibility(true);
                    }
                };
                fail(htmlString, swalResFn);
            }
    }

    //init
    useEffect(() => {
        getMainTemplateCategoryList();
    }, []);

    const filtering = (text) => {
        setSearchText(text);
        getTemplateGridByCategoryId(templateCategory.id, text);
    }

    const filter = <FilterComponent
        label={translation.filter}
        onFilter={filtering}
    />
    const onRemove = async (e) => {
        const { id: mappingId } = await getMenuMappingInfo();
        const user = getUser();
        const ipAddress = getIP();
        const queryData = {
            user_master_id: user.user_master_id,
            user_log_ip: ipAddress,
            menu_mapping_id: mappingId,
            template_master_id: e.template_master_id,
        }

        const reponse = await errorHandler(deleteTemplateMaster, queryData);
        if (reponse) {
            successToastr(translation.success_delete_row);

        }
    }

    const showTemplatePreview = async (cell) => {
        const state = {
            title: cell.data.template_master_name,
            comment: cell.data.template_master_comment,
            basicThemeURL: cell.data.template_master_image_white,
            darkThemeURL: cell.data.template_master_image_black,
            template_category_id: templateCategory.id,
            addOrEdit: "preview",
            //...await getTemplateMaster(cell.data.template_master_id)
            template_master_id: cell.data.template_master_id
        }

        //const test = document.createElement('form');
        //test.target = "test";
        //test.method = "POST";
        //test.action = `CustomTemplatePreview?state=${JSON.stringify(state)}`
        //test.onsubmit

        //window.open(`CustomTemplatePreview`, "", "popup=true");
        //test.submit();
        window.open(`CustomTemplatePreview?state=${JSON.stringify(state)}`, "", "popup=true,location=no");
    }

    const checkInUse = async (id) => {
        const param = {
            user_company_id: user.user_company_id,
            template_master_id: id
        }
        const res = await getExistTemplateMasterInMenuMapping(param);
        if (res.data.o_data[0].exist_template_master_in_menu_mapping === "MSG_128") {
            return true;
        } else {
            return false;
        }
    }

    return (
        <>
            <div className="template-master-grid">
                <div className="right-content-title">
                    <PageTitle />
                    <PageDropdown />
                </div>
                <div className="right-content-body">
                    <div className="card classification">
                        <div className="card-header">
                            <h6 className="card-title">{translation.template_classification}</h6>
                            <Button
                                icon="ix-setting"
                                type="normal"
                                stylingMode="text"
                                className="btn-s-xs"
                                onClick={() => setPopupVisibility(true)}
                            />
                        </div>
                        <div className="card-body">
                            <div className="classification-list">
                                <TreeView
                                    id="treeView"
                                    dataStructure="plain"
                                    items={treeList}
                                    displayExpr="name"
                                    onItemClick={selectItem}
                                    expandEvent="click"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <BaseDataGrid
                            dataSource={customDataSource}
                            params={{ templateCategory }}
                            headers={templateHeaderInfo}
                            link="TemplateMasterForm"
                            selectMode="multiple"
                            isRemote={true}
                            allowAdd={enableAdd}
                            toolbarModules={[filter]}
                            allowModify={true}
                            isFullSize={false}
                            checkInUse={checkInUse}
                        />
                    </div>
                </div>
            </div>
            <TemplateMasterSettingPopup
                visible={isPopupVisible}
                //onOk={refreshTemplateMenu}
                onExit={() => {
                    getMainTemplateCategoryList();
                    setPopupVisibility(false);
                }}
            />
        </>
    );
};