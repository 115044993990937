import React, { useEffect, useState, useRef } from 'react';
import useTranslation from '../../../components/customHooks/translations';
import useErrorHandler from '../../../components/customHooks/useErrorHandler';
import { getLanguage, getUser, getMenuMappingInfo, getIP } from '../../../apiInterface/utils/Common';
import { Button, Popup, ScrollView } from 'devextreme-react';
import { getInspectionLibraryItemApplied } from '../../../apiInterface/inspection/InspectionStandardLibraryAPI';
import { getOneInspectionLibrary } from '../../../apiInterface/inspection/InspectionLibraryCondition';
import InspectionStandardGroupGrid from '../InspectionStandardGroupGrid';
import { swal, useSwal } from '../../../components/common/Swal';

export default function SelectLibraryPopup(props) {
    const { visible, onClose = () => {}, onOk = () => {}, setDatas, getDatas, isLoad, getDataSources, setDataSources, state } = props;

    const translation = useTranslation();
    const errorHandler = useErrorHandler();
    const user = getUser();
    const ipAddress = getIP();
    const lang = getLanguage();
    const { fail } = useSwal();

    const [selectedItem, setSelectedItem] = useState({});
    const [selectedItems, setSelectedItems] = useState([]);

    const onClickOk = (e) => {
        if (isLoad) {
            if (state?.title === 'Copy') {
                let copy = {
                    inspection_library_copied_code: selectedItem.inspection_library_code,
                    inspection_library_copied_name: selectedItem.inspection_library_name,
                    inspection_type_name: selectedItem.inspection_type_name,
                };
                setDatas({ ...getDatas, ...copy });
            }

            // get one inspection library
            const getOneInspectionInfo = async () => {
                const param = {
                    inspection_library_id: selectedItem.inspection_library_id,
                    sys_lang_code: lang,
                };
                const res = await errorHandler(getOneInspectionLibrary, param);
                if (res) {
                    let data = res.data.o_data;

                    if (data.length !== 0 && data[0].inspection_library_defect_type_json !== null) {
                        let json = JSON.parse(data[0].inspection_library_defect_type_json);

                        let newArr = json.filter((item) => {
                            return !getDataSources
                                .map((m) => {
                                    return m.defect_type_id;
                                })
                                .includes(item.defect_type_id);
                        });

                        setDataSources([...getDataSources, ...newArr]);
                    } else if (data.length !== 0 && data[0].inspection_library_defect_type_json === null) {
                        // fail(`<p>${translation.no_data}</p>`);
                        console.log(`${translation.no_data}`);
                    }
                }
            };

            getOneInspectionInfo();
        } else {
            let objParam =
                state?.title === 'Copy'
                    ? { inspection_library_copied_id: selectedItem.inspection_library_id, inspection_library_copied_code: selectedItem.inspection_library_code, inspection_library_copied_name: selectedItem.inspection_library_name, inspection_type_name: selectedItem.inspection_type_name }
                    : { ...selectedItem };

            let copy = { ...getDatas, ...objParam };
            setDatas(copy);
            sessionStorage.setItem('inspectionName', selectedItem.inspection_library_name);
        }

        sessionStorage.removeItem('inspectionLibraryId');
        onClose();
    };

    const [deselect, setDeselect] = useState({ status: false });
    const removeTag = (e) => {
        setSelectedItem({});

        // getRow
        let obj = {
            status: true,
            item: selectedItem,
        };
        setDeselect(obj);
    };

    useEffect(() => {
        if (selectedItem !== null && selectedItem !== undefined) {
            sessionStorage.setItem('libraryName', selectedItem.inspection_library_name);
        }
    }, [selectedItem]);

    return (
        <Popup width={1100} height={850} visible={visible} hideOnOutsideClick={true} onHiding={onClose} dragEnabled={true} position="center" showTitle={false}>
            <div className="popup-select-copy-library">
                <div className="popup-header">
                    <p className="popup-title">Select Inspection library</p>
                </div>
                <div className="popup-body">
                    <div className="selected-item-viewer">
                        <div className="icon-checked">
                            <i></i>
                        </div>
                        <div className="selected-item-field">
                            {selectedItem?.inspection_library_id !== undefined && (
                                <>
                                    <ul className="selected-items">
                                        <li key={selectedItem?.inspection_library_id} className="item">
                                            <p className="item-name">{selectedItem?.inspection_library_name}</p>
                                            <i className="material-icons btn-delete" onClick={() => removeTag(selectedItem?.inspection_library_id)}>
                                                cancel
                                            </i>
                                        </li>
                                    </ul>
                                    {/* <div className="btn-all-delete" onClick={removeTag}>
                                        <button>{translation.delete_all}</button>
                                    </div> */}
                                </>
                            )}
                        </div>
                    </div>
                    <div className="item-select-box">
                        <ScrollView width="100%">
                            <div className="container-wrapper">
                                <InspectionStandardGroupGrid visible={visible} items={setSelectedItem} isDeselect={deselect} />
                            </div>
                        </ScrollView>
                    </div>
                </div>
                <div className="popup-footer">
                    <Button width={86} text={translation.cancel} type="normal" stylingMode="text" className="btn-s-r" onClick={onClose} />
                    <Button text={translation.confirm} width={86} type="default" stylingMode="contained" className="btn-s-r" onClick={(e) => onClickOk(e)} />
                </div>
            </div>
        </Popup>
    );
}
