import {
    makeAxiosPostFileRequest,
    makeTokenAxiosDeleteRequest,
    makeTokenAxiosGetRequest,
    makeTokenAxiosPostRequest,
    makeTokenAxiosPostRequestParams,
    makeTokenAxiosPutRequest
} from "../../apiInterface/utils/AxiosMask";


const BASE_URL = "dataset_management";
export const getMainDataset = async (param) => {
    let res;
    const urlPath = `${BASE_URL}/main_dataset_master`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res : "fail";
};

export const getMainDatasetInUse = async (param) => {
    let res;
    const urlPath = `${BASE_URL}/main_dataset_master`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res : "fail";
};

export const getAasExplorer = async (param) => {
    let res;
    const urlPath = `${BASE_URL}/aas_master_explorer`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res : "fail";
};

export const getAasExplorerByProcessId = async (param) => {
    let res;
    const urlPath = `${BASE_URL}/aas_master_explorer_by_process_id`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res : "fail";
};

export const getAasProperty = async (param) => {
    let res;
    const urlPath = `${BASE_URL}/aas_master_property`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res : "fail";
};

export const getAasPropertyByProcessId = async (param) => {
    let res;
    const urlPath = `${BASE_URL}/aas_master_property_by_process_id`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res : "fail";
};

export const deleteDatasetMaster = async (param) => {
    let res;
    const urlPath = `${BASE_URL}/dataset_master`;

    try {
        res = await makeTokenAxiosDeleteRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res : "fail";
}

export const getOperationFactory = async (param) => {
    let res;
    const urlPath = `${BASE_URL}/operation_factory`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res : "fail";
};

export const getFactoryLineProcess = async (param) => {
    let res;
    const urlPath = `${BASE_URL}/operation_factory_line_process`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res : "fail";
};

export const getMainAasMaster = async (param) => {
    let res;
    const urlPath = `${BASE_URL}/main_aas_master`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res : "fail";
};

export const getDatasetMasterExplorer = async (param) => {
    let res;
    const urlPath = `${BASE_URL}/dataset_master_explorer`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res : "fail";
};

export const getDatasetMasterProperty = async (param) => {
    let res;
    const urlPath = `${BASE_URL}/dataset_master_property`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res : "fail";
};

export const getDatasetById = async (param) => {
    let res;
    const urlPath = `${BASE_URL}/dataset_master_by_id`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res : "fail";
};

export const insertDatasetMaster = async (data) => {
    var res;
    const urlPath = `${BASE_URL}/dataset_master`;
    try {
        res = await makeTokenAxiosPostRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};

export const updateDatasetMaster = async (data) => {
    var res;
    const urlPath = `${BASE_URL}/dataset_master`;
    try {
        res = await makeTokenAxiosPutRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};

export const insertDatasetStructure = async (data) => {
    var res;
    const urlPath = `${BASE_URL}/dataset_master_structure`;
    try {
        res = await makeTokenAxiosPostRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};

export const insertDatasetDetail = async (data) => {
    var res;
    const urlPath = `${BASE_URL}/dataset_master_detail`;
    try {
        res = await makeTokenAxiosPostRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};

export const getColsByDatasetIdCustomTemp = async (param) => {
    let res;
    const urlPath = `${BASE_URL}/get_cols_by_dataset_id_custom_temp`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res : "fail";
};