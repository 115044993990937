import {
    makeTokenAxiosDeleteRequest,
    makeTokenAxiosGetRequest,
    makeTokenAxiosPostRequest,
    makeTokenAxiosPutRequest
} from "../utils/AxiosMask"

const baseUrl = 'user'
export const getBusinessIds = async (param) => {
    let res;
    const urlPath = `${baseUrl}/get_business_dd`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : "fail";

}

export const getUserInfo = async (param) => {
    let res;
    const urlPath = `${baseUrl}/`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res.data : "fail";
};

export const insertUserInfo = async (data) => {
    let res;
    const urlPath = `${baseUrl}/`;

    try {
        res = await makeTokenAxiosPostRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : "fail";
};

export const updateUserInfo = async (data) => {
    let res;
    const urlPath = `${baseUrl}/`;

    try {
        res = await makeTokenAxiosPutRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res.data : "fail";
}

export const deleteUserInfo = async (data) => {
    let res;
    const urlPath = `${baseUrl}/`;

    try {
        res = await makeTokenAxiosDeleteRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res.data : "fail";
}

export const getUserInfoById = async (param) => {
    let res;
    const urlPath = `${baseUrl}/get_user_master_by_id`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res.data : "fail";
}

export const getDeptTree = async (param) => {
    let res;
    const urlPath = `${baseUrl}/get_department_tree`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res.data : "fail";
};

export const getDuties = async (param) => {
    let res;
    const urlPath = `${baseUrl}/get_sys_division_caption_by_code`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res.data : "fail";
};