import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { setUserSession, setUserLocal } from '../../apiInterface/utils/Common';
import { signin } from '../../apiInterface/home/LoginAPI';

const Login = (props) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const username = useFormInput('');
    const password = useFormInput('');
    const [error, setError] = useState(null);

    // handle button click of login form
    const handleLogin = async () => {
        var res = await signin({ user_name: username.value, password: password.value });
        if (res !== 'fail') {
            setLoading(false);
            // setUserSession("response.data.token", res);
            setUserLocal('response.data.token', res);
            navigate('/Dashboard', { replace: true });
        }
        setError(null);
        setLoading(true);
        //axios.post('http://localhost:4000/users/signin', { username: username.value, password: password.value }).then(response => {
        //    setLoading(false);
        //    setUserSession(response.data.token, response.data.user);
        //    props.history.push('/dashboard');
        //}).catch(error => {
        //    setLoading(false);
        //    if (error.response.status === 401) setError(error.response.data.message);
        //    else setError("Something went wrong. Please try again later.");
        //});
    };

    return (
        <div className="container">
            Login
            <br />
            <br />
            <div>
                Username
                <br />
                <input type="text" {...username} autoComplete="new-password" />
            </div>
            <div style={{ marginTop: 10 }}>
                Password
                <br />
                <input type="password" {...password} autoComplete="new-password" />
            </div>
            {error && (
                <div>
                    <small style={{ color: 'red' }}>{error}</small>
                    <br />
                </div>
            )}
            <br />
            <input type="button" value={loading ? 'Loading...' : 'Login'} onClick={handleLogin} disabled={loading} />
            <br />
        </div>
    );
};
const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e) => {
        setValue(e.target.value);
    };
    return {
        value,
        onChange: handleChange,
    };
};
export default Login;
