import React, { useState, useRef } from "react";
import axios from 'axios';
import Button from 'devextreme-react/button';
import DataGrid, {
    Column, Editing, Paging, Lookup, RequiredRule, Toolbar, Item, Pager, Selection, Texts, Scrolling, Sorting
} from 'devextreme-react/data-grid';
import { useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import ContextMenu from 'devextreme-react/context-menu';
import useTranslation from "../../components/customHooks/translations";
import { useSwal } from '../../components/common/Swal';
import useWindowSize from "../customHooks/useWindowSize";
import BtnTooltip from "./BtnTooltip";
import { ValueErrorBar } from "devextreme-react/chart";
import { LoadPanel } from "devextreme-react";


function InlineDataGrid(props) {

    const translation = useTranslation();
    const { width, height } = useWindowSize();
    const [pageCount, setPageCount] = useState(10);
    const [selectId, setSelectId] = useState([]);
    const navigate = useNavigate();
    const [isEditBtnDisabled, disableEdit] = useState(true);
    const [isAddBtnDisabled, disableAdd] = useState(false);
    const [isDeleteBtnDisabled, disableDelete] = useState(true);
    const [rowData, setRowData] = useState({});
    const [addText, setAddText] = useState('ADD');
    const gridRef = useRef();
    const getInstance = () => { return gridRef.current?.instance }
    const getSelectedRows = () => { return getInstance().getSelectedRowsData() }
    //list of context menu(right-click menu)
    const contextMenu = [
        { text: "Edit" },
        { text: "Delete" }
    ];
    const { warning } = useSwal();

    //resize
    useEffect(() => {

        if (gridRef) {
            //page space except navbar and left-sidebar.
            const outlet = document.querySelector("div.sidebar-rightcontent");
            const outletRect = outlet.getBoundingClientRect();

            //datagrid
            const datagrid = document.querySelector("div.dx-gridbase-container");
            const datagridRect = datagrid.getBoundingClientRect();

            //single row of datagrid
            const datarow = document.querySelector("div.dx-datagrid-content tr.dx-row");
            const datarowRect = datarow.getBoundingClientRect();

            //calculate empty space below the datagrid.
            let remainSpace = outletRect.height - (datagridRect.y - outletRect.y) - datagridRect.height;
            let numRow = remainSpace / datarowRect.height;
            numRow = parseInt(numRow);
            //setPageCount(numRow + pageCount);
        }
    }, [height, props.dataSource]);

    const cellRender = (isBadge, value, captionForBadge, classNameForBadge) => {
        if (isBadge === true)
            return (
                <div className="grid-badge">
                    {value !== undefined && <span className={`${classNameForBadge[value]}`}>{captionForBadge[value]}</span>}
                </div>
            );
        else
            return (value);
    }

    const onDelete = () => {
        var cb = (res) => {
            if (res.isConfirmed) {
                props.onMultipleDeleted(selectId);
                removeRows();
            }
        };
        if (props.isDataDelete) {
            warning(`<p>${translation.confirm_delete}</p>`, cb);
        }
        else {
            removeRows();
        }
    }

    //remove mutiple rows    
    const removeRows = () => {
        const isCustomStored = props.isCustomStored;
        const instance = getInstance();
        const data = instance.getSelectedRowsData();

        instance.confirmDeleteMessage = "";
        if (isCustomStored) {
            isCustomStored.remove(data);
            instance.getDataSource().reload();
            instance.refresh();
        }
        else {
            selectId.forEach((key) => {
                let rowIndex = instance.getRowIndexByKey(key);
                instance.deleteRow(rowIndex);
                instance.refresh();
            });
        }
    }

    //selection change event when user clicked datagrid checkbox.
    //depends on number of checks toggle delete, edit button in tool box.
    const onSelectionChanged = (e) => {
        const gridInstance = getInstance();
        if (props.selectMode == "single") {
            if (e.currentSelectedRowKeys.length > 0) {
                const selectedRowIndex = gridInstance.getRowIndexByKey(e.currentSelectedRowKeys[0]);
                gridInstance.selectRowsByIndexes(selectedRowIndex);
            }
        }
        let rows = e.selectedRowKeys.length;
        if (rows != 1) {
            disableEdit(true);
        } else {
            disableEdit(false);
            setRowData(gridInstance.getSelectedRowsData()[0]);
        }
        if (rows >= 1) {
            disableDelete(false);
        }
        else {
            disableDelete(true);
        }
        const data = gridInstance.getSelectedRowsData();
        setSelectId(data);
        if (props.onSelectionChanged) {
            props.onSelectionChanged(data);
        }
    }

    //If row is double-clicked link to Edit page with clicked row info.
    const onRowDoubleClicked = (e) => {
        if (props.isDoubleClicked) {
            const data = e.key;
            moveToEdit(data);
        }
        else return;

    }

    //automatically toggle checkbox when user clicked row in datagrid.
    const onRowClick = (e) => {
        const target = e.data;
        const instance = getInstance();
        const data = instance.getSelectedRowsData();
        if (data.find((element) => element === target))
            instance.deselectRows([e.key]);
        else
            instance.selectRows([e.key], true);
    }

    //get data of row which is invoked context menu
    const prepareContextMenu = (e) => {
        // setRowData(e.row.data);
    }

    //onClick event for context menu
    const onItemClick = (e) => {
        switch (e.itemData.text) {
            case "Edit":
                moveToEdit(rowData)
                break;
            case "Delete":
                onDelete();
                break;
            default:
                throw "Unexpected Context Menu Item";
        }
    }

    //link to edit page.
    const moveToEdit = async (rowData) => {
        let state = {
            title: "Edit"
        };
        state = { ...state, ...props.params, rowData: rowData }
        navigate(`/${props.link}`, {
            state: state
        })
    }
    //link to add page.
    const moveToAdd = () => {
        let state = {
            title: "Add"
        };
        state = { ...state, ...props.params }
        navigate(`/${props.link}`, {
            state: state
        })
    }

    return (
        <>
            {/* <ContextMenu
                dataSource={contextMenu}
                target=".dx-data-row"
                onItemClick={onItemClick}
            /> */}
            <DataGrid
                height={props.height}
                width={props.width}
                onContextMenuPreparing={prepareContextMenu}
                dataSource={props.dataSource}
                onSaved={props.onSaved}
                onRowUpdated={props.onRowUpdated}
                onRowInserted={props.onRowInserted}
                onRowRemoved={props.onRowRemoved}
                onRowRemoving={props.onRowRemoving}
                onRowClick={onRowClick}
                onRowDblClick={onRowDoubleClicked}
                onSelectionChanged={onSelectionChanged}
                remoteOperations={props.isRemote}
                allowDeleting={true}
                ref={gridRef}
                allowColumnResizing={true}
            >
                {!props.isPaging && <Scrolling mode="virtual" />}
                {
                    props.isPaging &&
                    <Paging defaultPageSize={props.defaultPageSize} />
                }
                {
                    props.isPaging &&
                    <Pager
                        visible={true}
                        showPageSizeSelector={true}
                        infoText="{0} of {1} ({2} items)"
                        showInfo={true}
                        showNavigationButtons={true}
                    />
                }
                {
                    props.selectMode &&
                    <Selection
                        allowSelectAll={true}
                        selectAllMode={'page'}
                        mode={"multiple"}
                    />
                }
                <Editing
                    mode="row"
                    allowUpdating={false}
                    allowDeleting={false}
                    allowAdding={false}
                    confirmDelete={false}
                >
                </Editing>
                {
                    props?.headers?.map((value) => {
                        const validation = value.isRequired && <RequiredRule />
                        return (
                            <Column
                                key={value.dataSource}
                                dataField={value.dataSource}
                                caption={value.caption}
                                dataType={value.dataType}
                                allowEditing={value.isEdit}
                                width={value.width}
                                alignment={value.alignment}
                                //cell render is used for rendering HTML content inside table cell(refernece template master grid(image icon))
                                cellRender={
                                    value.dataSource === 'template_master_image_white' ||
                                        value.cellRender != null ? // for custom cellRender, if cellRender value is not a null run own render function.
                                        value.cellRender :
                                        e => cellRender(value.isBadge, e.value, value.captionForBadge, value.classNameForBadge)
                                }
                            >{validation}</Column>

                        )
                    })
                }
                {props.isInlineEditor &&
                    <Toolbar>
                        {props.isDelete &&
                            <Item>
                                <Button
                                    id="dx-toolbar-item-1"
                                    icon="trash"
                                    className="btn-s-r btn-hover-red"
                                    disabled={isDeleteBtnDisabled}
                                    onClick={() => {
                                        onDelete();
                                    }}
                                    type="normal"
                                    stylingMode="text"
                                />
                                <BtnTooltip target="#dx-toolbar-item-1" text={props.deleteText} />
                            </Item>
                        }
                        {props.isEdit &&
                            <Item>
                                <Button
                                    id="dx-toolbar-item-2"
                                    disabled={isEditBtnDisabled}
                                    type={props.editBtnType}
                                    className="btn-s-r"
                                    stylingMode="contained"
                                    text="EDIT"
                                    icon="edit"
                                    width={props.editBtnWidth}
                                    onClick={() => moveToEdit(rowData)}
                                >
                                    <i className="dx-icon dx-icon-edit"></i>
                                    <span className="dx-button-text">{props.editText}</span>
                                </Button>
                                <BtnTooltip target="#dx-toolbar-item-2" text={props.editText} />
                            </Item>}

                        {props.isAdd &&
                            <Item>
                                <Button
                                    id="dx-toolbar-item-3"
                                    type={props.addBtnType}
                                    disabled={isAddBtnDisabled}
                                    stylingMode="contained"
                                    className="btn-s-r"
                                    icon="add"
                                    text="ADD"
                                    width={props.addBtnWidth}
                                    onClick={() => {
                                        if (props.link) moveToAdd();
                                        else props.onAddClicked();
                                    }}
                                >
                                    <i className="dx-icon dx-icon-add"></i>
                                    <span className="dx-button-text"> {props.addText}</span>
                                    <BtnTooltip target="#dx-toolbar-item-3" text={props.addText} />
                                </Button>
                            </Item>}
                    </Toolbar>}
            </DataGrid>
        </>
    );
}

export default InlineDataGrid;

InlineDataGrid.defaultProps = {
    isCustomStored: false,
    isEdit: true,
    isDelete: true,
    isAdd: true,
    isDoubleClicked: true,
    isInlineEditor: true,
    isDataDelete: true,
    onMultipleDeleted: () => { },
    addBtnType: 'default',
    editBtnType: 'normal',
    addText: 'ADD',
    editText: 'EDIT',
    deleteText: 'DELETE',
    addBtnWidth: 80,
    editBtnWidth: 82,
};