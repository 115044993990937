import React, { useEffect, useState } from "react";
import useTranslation from "../../components/customHooks/translations";
import { swal } from '../../components/common/Swal';
import { getLanguage, getUser, getIP, getMenuMappingInfo } from "../../apiInterface/utils/Common";
import {
    deleteSecurityMaster,
    getSecurityBusinessByCompanyId,
    getSecurityMainMaster,
} from "../../apiInterface/securityManagement/SecurityGroupAPI"
import BaseDataGrid from "../../components/common/BaseDataGrid";
import { DropdownField, FilterComponent } from "../../components/common/FormField";
import useErrorHandler from "../../components/customHooks/useErrorHandler";
import { successToastr } from "../../components/common/Toastr";
import { useLocation } from "react-router-dom";
import { PageDropdown } from "../../components/common/PageDropdown";
import { PageTitle } from "../../components/common/PageTitle.jsx";
import Badge from "../../components/common/Badge";


let backupData = [];
let currentState = { businessId: -1 }; // store last selected businessId to restore current state when came back from /SecurityGroupForm page.
const SecurytyGroup = () => {
    const translation = useTranslation();
    const activeNotActiveJson = { true: translation.active, false: translation.not_active };
    const activeNotActiveClassName = { true: "grid-badge-use", false: "grid-badge-disabled" }
    const [dataSource, setDataSource] = useState([]);
    const [securityBusiness, setSecurityBusiness] = useState([]);
    const [selectedBusinessId, setSelectedBusinessId] = useState(null);
    const errorHandler = useErrorHandler();
    const location = useLocation();

    const header = [
        { dataField: "security_master_no", caption: "No", dataType: "number", isEdit: false, width: 100, alignment: "center", isFixed: true, allowReordering: true },
        { dataField: "security_master_name", caption: translation.security_master_name.toUpperCase(), dataType: "string", isFixed: true, allowReordering: true },
        {
            dataField: "security_master_is_active", caption: translation.security_master_is_active.toUpperCase(), dataType: "string",
            isEdit: false, width: 130, alignment: "center",
            cellRender: (e) => <Badge className={activeNotActiveClassName[e.value]} text={activeNotActiveJson[e.value]} />, allowReordering: true,
        },
        { dataField: "security_master_comment", caption: translation.security_master_comment.toUpperCase(), dataType: "string", allowReordering: true },
        { dataField: "security_master_modified_by", caption: translation.lastModifiedBy.toUpperCase(), dataType: "string", isEdit: false, width: 300, allowReordering: true },
        { dataField: "security_master_modified_date", caption: translation.lastModifiedDate.toUpperCase(), dataType: "string", isEdit: false, width: 150, allowReordering: true },
    ]

    const getSecurityBusiness = async () => {
        const user = getUser();
        const param = { user_company_id: user.user_company_id }

        const res = await errorHandler(getSecurityBusinessByCompanyId, param);
        if (res) {
            setSecurityBusiness(res.data.o_data);
            if (res.data.o_data.length > 0) {
                if (location.state?.businessId == null) {
                    currentState.businessId = res.data.o_data[0].user_business_id;
                    setSelectedBusinessId(res.data.o_data[0].user_business_id);
                } else {
                    currentState.businessId = location.state.businessId;
                    setSelectedBusinessId(location.state.businessId);
                }
            }
        }
    }

    useEffect(() => {
        backupData = [];
        getSecurityBusiness();
    }, []);


    const deleteSecurityMasterInfo = async (e) => {
        const remove = async (e) => {
            const user = getUser();
            const ipAddress = getIP();
            const data = e.data;
            const menuMappingId = getMenuMappingInfo().id;
            const queryData = {
                "user_master_id": user.user_master_id,
                "user_log_ip": ipAddress,
                "user_menu_mapping_id": menuMappingId,
                "security_master_id": data.security_master_id,
            }

            const res = await errorHandler(deleteSecurityMaster, queryData);
            if (res) {
                successToastr(translation.success_delete_row);
            } else {
                return true;
            }
            return false;
        }

        e.cancel = remove(e).then(res => res);
    }

    const onValueChanged = async (e) => {
        setSelectedBusinessId(e.value);
        const user = getUser();
        const lang = getLanguage();
        const param = {
            user_company_id: user.user_company_id,
            sys_lang_code: lang,
            user_business_id: e.value
        };

        currentState.businessId = e.value;

        const res = await errorHandler(getSecurityMainMaster, param);
        if (res) {
            setDataSource(res.data.o_data);
            backupData = res.data.o_data;
        }
    }

    const dropdown = <DropdownField
        label={translation.select_business}
        width={204}
        dataSource={securityBusiness}
        displayExpr="user_business_name"
        valueExpr="user_business_id"
        onValueChanged={onValueChanged}
        value={selectedBusinessId}
    />

    const filtering = (keyword) => {
        let result = [];

        if (!keyword) {
            result = [...backupData];
        } else {
            for (let i = 0; i < backupData.length; i++) {
                let isContain = backupData[i].security_master_name.toLowerCase().includes(keyword.toLowerCase());
                if (isContain) {
                    result.push(backupData[i]);
                }
            }
        }

        setDataSource(result);
    }

    const filter = <FilterComponent
        label={translation.filter}
        width={204}
        onFilter={filtering}
    />

    return (
        <>
            <div className="security-group">
                <div className="right-content-title">
                    <PageTitle />
                    <PageDropdown />
                </div>
                <div className="right-content-body">
                    <div className="container">
                        <BaseDataGrid
                            toolbarModules={[dropdown, filter]}
                            dataSource={dataSource}
                            headers={header}
                            isPaging={true}
                            link="SecurityGroupForm"
                            params={{ businessId: selectedBusinessId }}
                            onRowRemove={deleteSecurityMasterInfo}
                            // params={currentState}
                            isFullSize={true}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default SecurytyGroup;
