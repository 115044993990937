import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useTranslation from '../../components/customHooks/translations';
import { PageDropdown } from '../../components/common/PageDropdown';
import { PageTitle } from '../../components/common/PageTitle.jsx';
import Button from 'devextreme-react/button';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { SearchableField, TextboxField, DropdownField, FilterComponent, SingleFileField, FileField } from '../../components/common/FormField';
import TextArea from 'devextreme-react/text-area';
import { actionDirect, moveFocus, submit } from '../../components/common/FormFuncWithEnter';
import LastModifiedIndicator from '../../components/common/LastModifiedIndicator';
import '../../contents/styles/badtype/inspection.css';
import { getLanguage, getUser, getIP, getMenuMappingInfo } from '../../apiInterface/utils/Common';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import { swal, useSwal } from '../../components/common/Swal';

// api
import { getSysDivisionCaptionByCode, getOneDefectType, insertDefectType, updateDefectType, uploadInspectionDefectType } from '../../apiInterface/inspection/InspectionDefectType';
import { BASE_URL } from '../../config';

const DIVISION_CODE = 'INSPECTIONTYPE';
const previousPage = '/InspectionDefectType';
const baseUrl = `${BASE_URL}static_files/`;
export default function InspectionDefectTypeForm(props) {
    const navigate = useNavigate();
    const translation = useTranslation();
    const errorHandler = useErrorHandler();
    const { state } = useLocation();
    const title = state.title;
    const user = getUser();
    const ipAddress = getIP();
    const lang = getLanguage();
    const { success } = useSwal();

    const [image, setImage] = useState([]);
    const [data, setData] = useState({ defect_type_is_active: true });

    // dropdown
    const [dropdown, setDropdown] = useState();

    // dropdown
    const getInspectionTypes = async () => {
        let param = {
            user_company_id: user.user_company_id,
            sys_division_code: DIVISION_CODE,
            sys_lang_code: lang,
        };

        const res = await errorHandler(getSysDivisionCaptionByCode, param);
        if (res) {
            setDropdown(res.data.o_data);
            setData({ ...data, inspection_type: res.data.o_data[0].sys_division_sub_value });
        }
    };

    const useItems = [
        { value: true, title: translation.use },
        { value: false, title: translation.notUse },
    ];

    // upload image
    const uploadImage = async (img) => {
        const param = { user_id: user.user_master_id };
        let body = new FormData();

        if (img.length < 1) return { file_name: '', db_url: '' };

        if (img[0].file == null) {
            return { file_name: img[0].name, db_url: img[0].data };
        } else {
            body.append('inspection_image', img[0].file);
        }

        const res = await errorHandler(uploadInspectionDefectType, param, body);
        if (res) {
            return res;
        } else {
            return { file_name: '', db_url: '' };
        }
    };

    useEffect(() => {
        // set dropdown
        getInspectionTypes();

        if (state.title == 'Edit') {
            // load data
            let param = {
                user_company_id: user.user_company_id,
                defect_type_id: sessionStorage.getItem('defectTypeId'),
                sys_lang_code: lang,
            };

            const fetching = async () => {
                const res = await errorHandler(getOneDefectType, param);
                if (res) {
                    let d = res.data.o_data[0];
                    setData(d);
                    valueChanged(d.inspection_type, 'inspection_type');

                    if (d.defect_type_example_img) {
                        setImage([
                            {
                                data: `${BASE_URL}static_files/${d.defect_type_example_img}`,
                                name: d.defect_type_example_img.split('/').slice(-1)[0],
                                file: null,
                            },
                        ]);
                    }
                }
            };
            fetching();
        }
    }, []);

    // save
    const onSave = async () => {
        // image
        const immmage = await uploadImage(image);

        const { id: mapping_id } = await getMenuMappingInfo();
        const anotherData = {
            defect_type_id: state.title == 'Add' ? 0 : sessionStorage.getItem('defectTypeId'),
            user_master_email: user.user_master_email,
            user_log_ip: ipAddress,
            user_master_id: user.user_master_id,
            user_menu_mapping_id: mapping_id,
            user_company_id: user.user_company_id,
            defect_type_example_img: immmage.db_url.replace(baseUrl, ''),
            // defect_type_example_img: immmage.db_url,
            defect_type_category_id: state.title == 'Add' ? sessionStorage.getItem('categoryId') : data.defect_type_category_id,
        };
        const queryData = Object.assign(data, anotherData);

        const response = await errorHandler(state.title == 'Add' ? insertDefectType : updateDefectType, queryData);
        if (response) {
            let msg = state.title == 'Add' ? translation.success_insert : translation.success_update;
            const text = `<p>${msg}</p>`;
            success(text, exitForm);
        }
    };

    const valueChanged = (value, key) => {
        setData((cur) => {
            let newData = { ...cur };
            newData[key] = value;
            return newData;
        });
    };

    // exit
    const exitForm = () => {
        navigate(previousPage, {
            state: { previousPage: location.pathname },
        });
    };

    return (
        <>
            <div className="inspection-defect-type-form">
                <div className="right-content-title">
                    <PageTitle pageState={title} />
                    <PageDropdown />
                </div>
                <form>
                    <div className="right-content-body">
                        <div className="container">
                            <div className="dx-fieldset">
                                <DropdownField
                                    label={translation.select_inspectionType}
                                    value={data?.inspection_type}
                                    dataSource={dropdown}
                                    onValueChanged={(e) => valueChanged(e.value, 'inspection_type')}
                                    valueExpr="sys_division_sub_value"
                                    displayExpr="sys_division_sub_caption_text"
                                    disabled={state?.title == 'Edit' ? true : false}
                                    isEssential={true}
                                />
                                <TextboxField label={translation.quality_badtype_code} onValueChanged={(e) => valueChanged(e.value, 'defect_type_code')} isEssential={true} readOnly={state?.title == 'Edit' ? true : false} value={data?.defect_type_code} />
                                <TextboxField label={translation.quality_badtype_name} onValueChanged={(e) => valueChanged(e.value, 'defect_type_name')} isEssential={true} value={data?.defect_type_name} />
                                <SingleFileField label={translation.defect_type_example_img} imageData={image} setImageData={setImage} />
                                <DropdownField label={translation.defect_type_is_active} value={data?.defect_type_is_active} dataSource={useItems} onValueChanged={(e) => valueChanged(e.value, 'defect_type_is_active')} valueExpr="value" displayExpr="title" isEssential={true} />
                                <TextboxField label={translation.defect_type_comment} onValueChanged={(e) => valueChanged(e.value, 'defect_type_comment')} value={data?.defect_type_comment} />
                            </div>
                            <div className="block"></div>
                            {state?.title == 'Edit' && <LastModifiedIndicator date={data?.modified_date} department={data?.modified_by_department} position={data?.modified_by_duty} writer={data?.modified_by_name} />}
                            <div className="btn-group dx-fieldset">
                                <Button type="normal" stylingMode="text" className="btn-s-r" onClick={exitForm}>
                                    <div>
                                        <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                        <span className="dx-button-text">{translation.cancel}</span>
                                    </div>
                                </Button>
                                <Button
                                    id="submitBtn"
                                    type="default"
                                    stylingMode="contained"
                                    className="btn-s-r"
                                    useSubmitBehavior={false}
                                    onClick={(e) => {
                                        submit(e, onSave);
                                    }}
                                >
                                    <div>
                                        <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                        <span className="dx-button-text">{translation.save}</span>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}
