// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.security-group-form .info-comment {
    margin-top: 24px;
}

.popup-add-user .horizon-datagrid{
    display: flex;
    justify-content: space-between;
}
.popup-add-user .dx-datagrid .dx-datagrid-rowsview .dx-datagrid-content .dx-row,
.popup-add-user .dx-treelist .dx-treelist-container .dx-treelist-rowsview .dx-row {
    border-bottom: 1px solid var(--border);
}
.popup-add-user .dx-datagrid .dx-datagrid-rowsview,
.popup-add-user .dx-treelist .dx-treelist-rowsview {
    height: 240px;
    max-height: 240px;
}
.popup-add-user .info-comment,
.popup-search-name .info-comment {
    margin-top: 12px;
}`, "",{"version":3,"sources":["webpack://./src/contents/styles/security/security-group-form.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;AACA;;IAEI,sCAAsC;AAC1C;AACA;;IAEI,aAAa;IACb,iBAAiB;AACrB;AACA;;IAEI,gBAAgB;AACpB","sourcesContent":[".security-group-form .info-comment {\n    margin-top: 24px;\n}\n\n.popup-add-user .horizon-datagrid{\n    display: flex;\n    justify-content: space-between;\n}\n.popup-add-user .dx-datagrid .dx-datagrid-rowsview .dx-datagrid-content .dx-row,\n.popup-add-user .dx-treelist .dx-treelist-container .dx-treelist-rowsview .dx-row {\n    border-bottom: 1px solid var(--border);\n}\n.popup-add-user .dx-datagrid .dx-datagrid-rowsview,\n.popup-add-user .dx-treelist .dx-treelist-rowsview {\n    height: 240px;\n    max-height: 240px;\n}\n.popup-add-user .info-comment,\n.popup-search-name .info-comment {\n    margin-top: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
