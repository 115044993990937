import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useTranslation from '../../components/customHooks/translations';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import { getLanguage, getUser, getMenuMappingInfo, getIP } from '../../apiInterface/utils/Common';
import { PageTitle } from '../../components/common/PageTitle.jsx';
import { Button, Popup, ScrollView } from 'devextreme-react';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { SearchableField, TextboxField, DropdownField, FilterComponent, FileField } from '../../components/common/FormField';
import BaseDataGrid from '../../components/common/BaseDataGrid';
import TextBox from 'devextreme-react/text-box';
import TextArea from 'devextreme-react/text-area';
import { actionDirect, moveFocus, submit } from '../../components/common/FormFuncWithEnter';
import LastModifiedIndicator from '../../components/common/LastModifiedIndicator';
import '../../contents/styles/badtype/inspection.css';
import { swal, useSwal } from '../../components/common/Swal';
import { getOneInspectionLibrary, getOneInspectionLibraryDefectType, getSysDivisionCaptionByCode } from '../../apiInterface/inspection/InspectionLibraryCondition';
import { getOneDefectType } from '../../apiInterface/inspection/InspectionDefectType';
import DefectTypeStandardGrid from './DefectTypeStandardGrid';
import InspectionDefectTypeGrid from './InspectionDefectTypeGrid';

const DIVISION_CODE = 'INSPECTIONTYPE';
const previousPage = '/DefectTypeStandardForm';
export default function DefectTypeStandardFormDtl(props) {
    // const { setDp, loadData, selectedRow, isDel } = props;

    const navigate = useNavigate();
    const translation = useTranslation();
    const location = useLocation();
    const { success } = useSwal();
    const { state } = useLocation();

    const [data, setData] = useState({ inspection_library_is_active: true });
    const [isPopupVisibility, setPopupVisibility] = useState(false);

    const [dataSource, setDataSource] = useState([]);

    // popup
    const [popupInfo, setPopupInfo] = useState({});

    // 1. get defect code
    const [defectTypeId, setDefectTypeId] = useState({});
    const findDefectTypeCode = () => {
        setPopupVisibility(true);
        let info = {
            title: 'Select Defect Type',
            selectMode: 'single',
            type: 'findDefectTypeId',
            loadItem: setDefectTypeId,
        };
        setPopupInfo(info);
    };

    // 1-1. set defect data
    useEffect(() => {
        setData(defectTypeId);
    }, [defectTypeId]);

    // popup2
    const [isAddPopup, setIsAddPopup] = useState(false);
    const [loadCondition, setLoadCondition] = useState();

    // 2. set condition
    useEffect(() => {
        let tOrF = state.title === 'Condition' ? true : false;
        setIsAddPopup(tOrF);
    }, [state]);

    // 2-1. add condition
    useEffect(() => {
        // console.log(loadCondition);
        // console.log(loadCondition?.inspection_library_condition_condition)
        if (loadCondition?.inspection_library_condition_condition !== undefined) {
            let copy = [...dataSource, loadCondition];
            setDataSource(copy);
        } else {
            setDataSource([]);
        }
    }, [loadCondition]);

    // popup3
    const [isAddPopup2, setIsAddPopup2] = useState(false);
    const [loadMeasure, setLoadMeasure] = useState();

    // 3. measure
    const addDefectTypeFormula = () => {
        setIsAddPopup2(true);
    };

    const useItems = [
        { value: true, title: translation.use },
        { value: false, title: translation.notUse },
    ];

    // 4. save
    const onSave = async () => {
        navigate(previousPage, {
            state: {
                title: state.title,
                subTitle: 'DtlSave',
                data: data,
                dataSource: dataSource,
            },
        });
    };

    // 5.
    useEffect(() => {
        if (state.title === 'Edit') {
            setData(state.rowData);
            setDataSource([state.rowData]);
            console.log(state.rowData);
            // console.log(state.rowData);
        }
    }, []);

    // setting grid option
    const header = [
        // { dataField: "inspection_library_defect_type_no", caption: translation.inspection_library_no, dataType: "number", isEdit: false, width: 60, alignment: "center", allowReordering: true, isFixed: true },
        {
            dataField: 'inspection_library_condition_condition',
            caption: '측정조건',
            dataType: 'string',
            allowReordering: true,
        },
        {
            dataField: 'inspection_library_condition_value',
            caption: '측정규격',
            dataType: 'string',
            allowReordering: true,
        },
        {
            dataField: 'inspection_library_condition_direction',
            caption: '측정방향',
            dataType: 'string',
            allowReordering: true,
        },
        {
            dataField: 'inspection_library_condition_gap_plus',
            caption: '측정범위 +',
            dataType: 'string',
            allowReordering: true,
        },
        {
            dataField: 'inspection_library_condition_gap_minus',
            caption: '측정범위 -',
            dataType: 'string',
            allowReordering: true,
        },
        {
            dataField: 'inspection_library_condition_unit',
            caption: '측정단위',
            dataType: 'string',
            allowReordering: true,
        },
    ];

    const valueChanged = (value, key) => {
        setData((cur) => {
            let newData = { ...cur };
            newData[key] = value;
            return newData;
        });
    };

    // exit
    const exitForm = () => {
        // setDp(false);
        navigate(previousPage, {
            state: { previousPage: location.pathname },
        });
        // sessionStorage.removeItem("inspectionLibraryId");
    };

    return (
        <div className="inspection-standard-library-form">
            <div className="right-content-title">
                <PageTitle pageState={state.title} />
            </div>

            <form>
                <div className="right-content-body">
                    <div className="container">
                        <div className="dx-fieldset">
                            <div className="dx-field">
                                <div className="dx-field-label">
                                    <p>{translation.defect_type_code}</p>
                                </div>
                                <div className="dx-field-value">
                                    <div className="search">
                                        <TextBox className="ix-tfs-r" readOnly={true} name="defect_type_code" value={data?.defect_type_code} onValueChanged={(e) => valueChanged(e.value, 'defect_type_code')} onEnterKey={moveFocus}>
                                            <div className="clickable-textbox" onClick={findDefectTypeCode}></div>
                                        </TextBox>
                                        <Button icon="search" type="default" stylingMode="contained" className="btn-s-r dx-button-mode-es btn-only-icon" onClick={findDefectTypeCode} elementAttr={{ focusidx: 5 }} />
                                    </div>
                                </div>
                            </div>

                            <TextboxField label={translation.defect_type_name} value={data?.defect_type_name} onValueChanged={(e) => valueChanged(e.value, 'defect_type_name')} isEssential={true} readOnly={true} />

                            <div
                                style={{
                                    width: '1200px',
                                    margin: '1em',
                                    marginBottom: '3em',
                                }}
                            >
                                <BaseDataGrid
                                    dataSource={dataSource}
                                    allowModify={true}
                                    isRemote={true}
                                    isPaging={false}
                                    isFullSize={true}
                                    link="DefectTypeStandardFormDtl"
                                    headers={header}
                                    // visible
                                    showAdd={false}
                                    showEdit={false}
                                    isDoubleClicked={false}
                                    showAddCondition={true}
                                    // onSelectionChanged={onRowSelectionChanged}
                                />
                            </div>

                            {/* <TextboxField
                                label={translation.inspection_library_defect_type_formula}
                                value={data?.inspection_library_defect_type_formula}
                                onValueChanged={(e) => valueChanged(e.value, "inspection_library_defect_type_formula")}
                                isEssential={true}
                            // readOnly={true}
                            /> */}

                            <div className="dx-field">
                                <div className="dx-field-label">
                                    <p>{translation.inspection_library_defect_type_formula}</p>
                                </div>
                                <div className="dx-field-value">
                                    <div className="search">
                                        <TextBox className="ix-tfs-r" readOnly={true} name="inspection_library_defect_type_formula" value={data?.inspection_library_defect_type_formula} onValueChanged={(e) => valueChanged(e.value, 'inspection_library_defect_type_formula')} onEnterKey={moveFocus}>
                                            <div className="clickable-textbox" onClick={addDefectTypeFormula}></div>
                                        </TextBox>
                                        <Button icon="search" type="default" stylingMode="contained" className="btn-s-r dx-button-mode-es btn-only-icon" onClick={addDefectTypeFormula} elementAttr={{ focusidx: 5 }} />
                                    </div>
                                </div>
                            </div>

                            <DropdownField
                                label={translation.inspection_library_defect_type_is_active}
                                value={data?.inspection_library_defect_type_is_active}
                                dataSource={useItems}
                                onValueChanged={(e) => valueChanged(e.value, 'inspection_library_defect_type_is_active')}
                                valueExpr="value"
                                displayExpr="title"
                                isEssential={true}
                            />
                            <TextboxField label={translation.inspection_library_defect_type_comment} onValueChanged={(e) => valueChanged(e.value, 'inspection_library_defect_type_comment')} value={data?.inspection_library_defect_type_comment} />
                        </div>
                        <div className="block"></div>
                        {state.title == 'Edit' && <LastModifiedIndicator date={data?.modified_date} department={data?.modified_by_department} position={data?.modified_by_duty} writer={data?.modified_by_name} />}
                        <div className="btn-group dx-fieldset">
                            <Button type="normal" stylingMode="text" className="btn-s-r" onClick={exitForm}>
                                <div>
                                    <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                    <span className="dx-button-text">{translation.cancel}</span>
                                </div>
                            </Button>
                            <Button id="submitBtn" type="default" stylingMode="contained" className="btn-s-r" useSubmitBehavior={false} onClick={onSave}>
                                <div>
                                    <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                    <span className="dx-button-text">{translation.save}</span>
                                </div>
                            </Button>
                        </div>
                    </div>
                </div>
            </form>

            <AddCondition visible={isAddPopup} loadItem={setLoadCondition} onClose={() => setIsAddPopup(false)} />

            <AddMeasure visible={isAddPopup2} loadItem={setLoadCondition} conditionData={dataSource} onClose={() => setIsAddPopup2(false)} />
        </div>
    );
}

const AddMeasure = (props) => {
    const { visible, onClose = () => {}, loadItem, conditionData } = props;

    const translation = useTranslation();
    const location = useLocation();
    const lang = getLanguage();
    const { state } = useLocation();

    const [data, setData] = useState();

    const onClickOk = (e) => {
        // 값 어떻게 넣는지 확인!
        // loadItem(data);
        onClose();
    };

    const onClickClose = (e) => {
        onClose();
    };

    return (
        <Popup width={550} height={600} visible={visible} hideOnOutsideClick={true} onHiding={onClose} dragEnabled={true} position="center" showTitle={false}>
            <div className="popup-select-copy-library">
                <div className="popup-header">
                    <p className="popup-title">Measure Condition</p>
                </div>
                <div className="popup-body">
                    {/* <TextboxField
                        label={translation.inspection_library_condition_value}
                        value={data?.inspection_library_condition_value}
                        onValueChanged={(e) => valueChanged(e.value, "inspection_library_condition_value")}
                        isEssential={true}
                    /> */}
                    <div className="dx-field" style={{ flexWrap: 'wrap' }}>
                        <div className="dx-field-label">
                            <p>측정조건 계산식</p>
                        </div>
                        {conditionData.length !== undefined &&
                            conditionData.length !== 0 &&
                            conditionData?.map((m) => {
                                return (
                                    <div className="dx-field-value">
                                        <div className="search">
                                            <TextBox
                                                className="ix-tfs-r"
                                                readOnly={true}
                                                name="measure_condition_calcultor"
                                                value={m?.measure_condition_calcultor}
                                                // onValueChanged={(e) => valueChanged(e.value, "measure_condition_calcultor")}
                                                onEnterKey={moveFocus}
                                            >
                                                {/* <div className="clickable-textbox" onClick={findDefectTypeCode}></div> */}
                                            </TextBox>
                                            <div>
                                                <Button>OR</Button>
                                                <Button>AND</Button>
                                                <Button>( )</Button>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
                <div className="popup-footer">
                    <Button width={86} text={translation.cancel} type="normal" stylingMode="text" className="btn-s-r" onClick={(e) => onClickClose(e)} />
                    <Button text={translation.confirm} width={86} type="default" stylingMode="contained" className="btn-s-r" onClick={(e) => onClickOk(e)} />
                </div>
            </div>
        </Popup>
    );
};

const AddCondition = (props) => {
    const { visible, onClose = () => {}, loadItem } = props;

    const translation = useTranslation();
    const location = useLocation();
    const errorHandler = useErrorHandler();
    const user = getUser();
    const lang = getLanguage();
    const { state } = useLocation();

    const [data, setData] = useState();
    const [selectedItem, setSelectedItem] = useState({});
    const [inspectionType, setInspectionType] = useState();
    const [directionType, setDirectionType] = useState();
    const [resultType, setResultType] = useState();

    // dropdown MEASURECONDITION
    const getMeasureCondition = async () => {
        let param = {
            user_company_id: user.user_company_id,
            sys_division_code: 'MEASURECONDITION',
            sys_lang_code: lang,
        };

        const res = await errorHandler(getSysDivisionCaptionByCode, param);
        if (res) {
            setInspectionType(res.data.o_data);
        }
    };

    // dropdown MEASUREDIRECTION
    const getMeasureDirection = async () => {
        let param = {
            user_company_id: user.user_company_id,
            sys_division_code: 'MEASUREDIRECTION',
            sys_lang_code: lang,
        };

        const res = await errorHandler(getSysDivisionCaptionByCode, param);
        if (res) {
            setDirectionType(res.data.o_data);
        }
    };

    // dropdown MEASUREUNIT
    const getMeasureResult = async () => {
        let param = {
            user_company_id: user.user_company_id,
            sys_division_code: 'MEASUREUNIT',
            sys_lang_code: lang,
        };

        const res = await errorHandler(getSysDivisionCaptionByCode, param);
        if (res) {
            setResultType(res.data.o_data);
        }
    };

    useEffect(() => {
        getMeasureCondition();
        getMeasureDirection();
        getMeasureResult();
    }, []);

    const onClickOk = (e) => {
        loadItem(data);
        state.title = 'Add';
        onClose();
    };

    const onClickClose = (e) => {
        state.title = 'Add';
        onClose();
    };

    const valueChanged = (value, key) => {
        setData((cur) => {
            let newData = { ...cur };
            newData[key] = value;
            return newData;
        });
    };

    useEffect(() => {
        if (visible) {
            setData(undefined);
        }
    }, [visible]);

    return (
        <Popup width={550} height={600} visible={visible} hideOnOutsideClick={true} onHiding={onClose} dragEnabled={true} position="center" showTitle={false}>
            <div className="popup-select-copy-library">
                <div className="popup-header">
                    <p className="popup-title">Measure Condition</p>
                </div>
                <div className="popup-body">
                    <DropdownField
                        label={translation.inspection_library_condition_condition}
                        value={data?.inspection_library_condition_condition}
                        dataSource={inspectionType}
                        onValueChanged={(e) => valueChanged(e.value, 'inspection_library_condition_condition')}
                        valueExpr="sys_division_sub_value"
                        displayExpr="sys_division_sub_caption_text"
                        isEssential={true}
                    />
                    <TextboxField label={translation.inspection_library_condition_value} value={data?.inspection_library_condition_value} onValueChanged={(e) => valueChanged(e.value, 'inspection_library_condition_value')} isEssential={true} />
                    <DropdownField
                        label={translation.inspection_library_condition_direction}
                        value={data?.inspection_library_condition_direction}
                        dataSource={directionType}
                        onValueChanged={(e) => valueChanged(e.value, 'inspection_library_condition_direction')}
                        valueExpr="sys_division_sub_value"
                        displayExpr="sys_division_sub_caption_text"
                        isEssential={true}
                    />
                    <TextboxField
                        label={translation.inspection_library_condition_gap_plus}
                        value={data?.inspection_library_condition_gap_plus}
                        onValueChanged={(e) => valueChanged(e.value, 'inspection_library_condition_gap_plus')}
                        isEssential={true}
                        readOnly={data?.inspection_library_condition_direction === 'EQUAL' ? false : true}
                    />
                    <TextboxField
                        label={translation.inspection_library_condition_gap_minus}
                        value={data?.inspection_library_condition_gap_minus}
                        onValueChanged={(e) => valueChanged(e.value, 'inspection_library_condition_gap_minus')}
                        isEssential={true}
                        readOnly={data?.inspection_library_condition_direction === 'EQUAL' ? false : true}
                    />
                    <DropdownField
                        label={translation.inspection_library_condition_unit}
                        value={data?.inspection_library_condition_unit}
                        dataSource={resultType}
                        onValueChanged={(e) => valueChanged(e.value, 'inspection_library_condition_unit')}
                        valueExpr="sys_division_sub_value"
                        displayExpr="sys_division_sub_caption_text"
                        isEssential={true}
                    />
                </div>
                <div className="popup-footer">
                    <Button width={86} text={translation.cancel} type="normal" stylingMode="text" className="btn-s-r" onClick={(e) => onClickClose(e)} />
                    <Button text={translation.confirm} width={86} type="default" stylingMode="contained" className="btn-s-r" onClick={(e) => onClickOk(e)} />
                </div>
            </div>
        </Popup>
    );
};
