export default function Badge(props) {
    const {
        className,
        text,
    } = props;

    return (
        <div className="grid-badge">
            <span className={className}>{text}</span>
        </div>
    )
}