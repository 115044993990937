import React, { useRef, useEffect, useState } from 'react';
import * as echarts from "echarts";

//chart lib
import { HalfDoughnutChart } from "../../components/ixChart/IXChart"
const About = () => {
    const lineYData = [
        [150, 230, 224, 218]
    ];
    const lineXData = [[1, 2, 3, 4]];
    const lineLegend = ['A', 'B'];
    //setTimeout(function () {
    //    const line = LineChart({
    //        x: lineXData, y: lineYData, legend: lineLegend, id: "#chartTest"
    //    });
    //    window.onresize = function () {
    //        line.resize();

    //    };
    //}, 500)
    useEffect(() => {
        
        const line = HalfDoughnutChart({ x: lineXData, y: lineYData, legend: lineLegend, id: "#chartTest" });
        //const bar = BarChart({ y: barYData, legend: barLegend, x: barXdata, id: "bar" });
        //const scatter = ScatterChart({ x: scatterXData, y: scatterYData, legend: scatterLegend, id: "scatter" });
        //const pie = PieChart({ data: pieData,  id: "pie" });
        window.onresize = function () {
            line.resize();
            //bar.resize();
            //scatter.resize();
            //pie.resize();
        };
    }, []);
    return (
        <div>
            <h1>
                About INTERX.
            </h1>
            <div style={{
                border: "solid",
                padding: "64px 16px 16px 16px",
                width: "100%",
                height: "1600px",
                display: "flex"
            }} className="test">
                <div id="chartTest" style={{
                    width: "100%",
                    height: "100%",
                }}>
                This is a test.
            </div>
            </div>
        </div>
        
    );
};

export default About;