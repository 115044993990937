import React, { useEffect, useState, useRef, useImperativeHandle } from "react";
import Button from 'devextreme-react/button';
import useTranslation from "../../components/customHooks/translations";
import DataGrid, {
    Column, Editing, Paging, Lookup, RequiredRule, Toolbar, Item, Pager, Selection, Texts
} from 'devextreme-react/data-grid';
import { SelectBox } from 'devextreme-react/select-box';
import { getUser, getIP } from '../../apiInterface/utils/Common';
import BtnTooltip from "../../components/common/BtnTooltip";
import { Popup } from 'devextreme-react/popup';
import { TextBox } from 'devextreme-react/text-box';
import SecuritySearchPopup from "./SecuritySearchPopup";
import useErrorHandler from "../../components/customHooks/useErrorHandler";
import { getSecurityMaster, getSecuritySetting } from "../../apiInterface/item/ItemCategoryAPI";
import { swal } from '../../components/common/Swal';
import { forwardRef } from "react";
import { FilterComponent } from "../../components/common/FormField";

let backup = [];
const SecurityGrid = forwardRef((props, ref) => {
    const { data, addData, deleteData, width, isSetSecurityValue = false } = props
    const [filter, setFilter] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [deleteDisable, setDeleteDisable] = useState(true);
    const gridRef = useRef();
    const popupRef = useRef();
    const translation = useTranslation();

    useImperativeHandle(ref, () => ({
        getValues: getValues
    }));

    const getValues = () => (data);

    const headers = [
        { dataField: "security_master_name", caption: translation.security_group.toUpperCase(), dataType: "string", allowEditing: false, allowReordering: true },
        { dataField: "user_business_name", caption: translation._business.toUpperCase(), dataType: "string", allowEditing: false, allowReordering: true },
        // { dataField: "security_master_comment", caption: translation.security_master_comment.toUpperCase(), dataType: "string", allowEditing: false },
        // { dataField: "propagateToParent", caption: translation.propagateToParent.toUpperCase(), dataType: "boolean", allowEditing: true },
        // { dataField: "propagateToChildren", caption: translation.propagateToChildren.toUpperCase(), dataType: "boolean", allowEditing: true },
    ]

    useEffect(() => {
        searchClicked();
    }, [props.data]);

    const searchClicked = async (keyword = searchText) => {
        const copy = [...data];
        let buffer = [];
        let lowerKeyword = keyword.toLowerCase();

        for (let i = 0; i < copy.length; i++) {
            if (typeof (copy[i].security_master_name) === "string") {
                if (copy[i].security_master_name.toLowerCase().includes(lowerKeyword)) {
                    buffer.push(copy[i]);
                    continue;
                }
            }

            if (typeof (copy[i].security_master_comment) === "string") {
                if (copy[i].security_master_comment.toLowerCase().includes(lowerKeyword)) {
                    buffer.push(copy[i]);
                    continue;
                }
            }
        }

        setFilter(buffer);
    }

    //automatically toggle checkbox when user clicked row in datagrid.
    const onRowClick = (e) => {
        const target = e.data;
        const instance = gridRef.current.instance;
        const data = instance.getSelectedRowsData();
        if (data.find((element) => element === target))
            instance.deselectRows([e.key]);
        else
            instance.selectRows([e.key], true);
    }

    const removeRows = () => {
        const instance = gridRef.current.instance;
        const selected = instance.getSelectedRowsData();
        deleteData(selected);
    }

    const rowInitalize = (e) => {
        console.log(e);
    }

    return (
        <>
            {/* search bar */}
            <div className="data-grid">
                <div className="data-grid-toolbar">
                    <FilterComponent
                        width={204}
                        label={translation.filter}
                        onFilter={(keyword) => {
                            setSearchText(keyword);
                            searchClicked(keyword);
                        }}
                        isColumn={false}
                    />
                </div>

                <DataGrid
                    ref={gridRef}
                    dataSource={filter}
                    onRowClick={onRowClick}
                    onSelectionChanged={(e) => { e.selectedRowsData?.length > 0 ? setDeleteDisable(false) : setDeleteDisable(true); }}
                    width={width}
                    onInitNewRow={rowInitalize}
                >
                    <Selection mode="multiple" />
                    <Editing
                        mode="cell"
                        allowUpdating={true}
                    />
                    {
                        headers &&
                        headers.map((col) => {
                            return <Column
                                key={col.dataField}
                                dataField={col.dataField}
                                caption={col.caption}
                                dataType={col.dataType}
                                allowEditing={col.allowEditing}
                            />
                        })
                    }
                    {
                        isSetSecurityValue &&
                        <Column caption={translation.permission_setting.toUpperCase()} alignment="center">
                            <Column dataField="create" caption={translation.create.toUpperCase()} dataType="boolean" allowEditing={true} />
                            <Column dataField="update" caption={translation.update.toUpperCase()} dataType="boolean" allowEditing={true} />
                            <Column dataField="delete" caption={translation.delete.toUpperCase()} dataType="boolean" allowEditing={true} />
                        </Column>
                    }
                    <Column dataField="propagateToParent" caption={translation.propagateToParent.toUpperCase()} dataType="boolean" allowEditing={true} />
                    <Column dataField="propagateToChildren" caption={translation.propagateToChildren.toUpperCase()} dataType="boolean" allowEditing={true} />
                    <Toolbar>
                        <Item>
                            {/* remove button */}
                            <Button
                                id="dx-toolbar-item-1"
                                icon="trash"
                                className="btn-s-r btn-hover-red"
                                onClick={removeRows}
                                type="normal"
                                stylingMode="text"
                                disabled={deleteDisable}
                            />
                        </Item>
                        <Item>
                            {/* add user button */}
                            <Button
                                id="dx-toolbar-item-2"
                                className="btn-s-r"
                                type="normal"
                                stylingMode="contained"
                                text={translation.addGroup}
                                icon="add"
                                onClick={() => popupRef.current.open()}
                            >
                            </Button>
                        </Item>
                    </Toolbar>
                </DataGrid>
            </div>


            {/* search Popup */}
            <SecuritySearchPopup
                ref={popupRef}
                onOk={(val) => { addData(val); }}
            />

            {/* Button Tooltip */}
            <BtnTooltip target="#dx-toolbar-item-1" text={translation.delete} />
            <BtnTooltip target="#dx-toolbar-item-2" text={translation.add} />
        </>
    );
});
export default SecurityGrid;