import { makeTokenAxiosGetRequest, makeTokenAxiosPutRequest, makeAxiosPostFileRequest } from "../utils/AxiosMask"

export const getCompanyInfoByMasterId = async (data) => {
    var res;
    const urlPath = `company/`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};

export const updateCompanyInfoByMasterId = async (data) => {
    var res;
    const urlPath = `company/`;
    try {
        res = await makeTokenAxiosPutRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};

export const uploadLogo = async (data) => {
    var res;
    const urlPath = `company/upload_logo`;
    try {
        res = await makeAxiosPostFileRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};