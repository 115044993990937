import React, { useState } from 'react';
import { useEffect } from 'react';
import { forwardRef } from 'react';


export default function TreeCard(props, ref) {
    const {
        data,
        onRowClick = () => { },
        autoExpand = true,
        keyExpr,
        parentExpr,
        nameExpr,
        detailExpr,
        badgeExprs = [],
        isSelectable = true,
    } = props;

    const [treeData, setTreeData] = useState([]);

    useEffect(() => {
        if (data != null && data.length > 0) {
            const test = makeHierarchy(data);
            setTreeData(makeHierarchy(data));
        }
    }, [data]);

    const makeHierarchy = (plainData) => {
        if (plainData.length == 0) {
            return;
        }

        const dict = {};
        const input = plainData
        for (let i = 0; i < input.length; i++) {
            input[i].children = [];
            const key = input[i][keyExpr];
            dict[key] = input[i];
        }

        let root = [];
        for (let i = 0; i < input.length; i++) {
            if (input[i][parentExpr] == 0) {
                root.push(input[i]);
                continue;
            }

            if (dict[input[i][parentExpr]]?.children == undefined) {
                ;
            }
            dict[input[i][parentExpr]]?.children?.push(input[i]);
        }

        return root;
    }

    return (
        <>
            <ul className={isSelectable ? "ix-treelist" : "ix-treelist not-selectable"}>
                {
                    treeData?.map((root, idx) => {
                        return <Node
                            key={`root${idx}`}
                            data={root}
                            onClick={(data) => { onRowClick(data); }}
                            expand={autoExpand}
                            keyExpr={keyExpr}
                            nameExpr={nameExpr}
                            detailExpr={detailExpr}
                            badgeExprs={badgeExprs}
                            prefix={`(${idx})`}
                        />
                    })
                }
            </ul>
        </>
    )
}

function Node(props) {
    const {
        data,
        onClick: callback,
        expand = false,
        keyExpr,
        badgeExprs = [],
        nameExpr,
        detailExpr,
        prefix = "",
    } = props;

    const onClick = (data) => {
        callback(data);
    }

    return (
        <>
            {
                data &&
                <li>
                    {
                        data.children?.length != null && data.children.length > 0 &&
                        <>
                            <input type="checkbox" id={`dropdown${prefix}-${data[keyExpr]}`} defaultChecked={expand} />
                            <label htmlFor={`dropdown${prefix}-${data[keyExpr]}`} className="dropdown" />
                        </>
                    }
                    <input type="radio" name="select" id={`node${prefix}-${data[keyExpr]}`} />
                    <label htmlFor={`node${prefix}-${data[keyExpr]}`} onClick={() => { onClick(data); }}>
                        {
                            badgeExprs.map((expr, idx) => {
                                //modifiy badge detail
                                let badgeSeq = " ";

                                switch (idx) {
                                    case 1:
                                        badgeSeq += "grid-badge-second";
                                        break;
                                    default:
                                        break;
                                }

                                return <span key={`badge(${idx})`} className={`badge${badgeSeq}`}>{data[expr]}</span>
                            })
                        }
                        <p>
                            {data[nameExpr]}
                            <span>
                                {detailExpr == null || data[detailExpr] == null || data[detailExpr].toUpperCase() == "NULL" ? "" : data[detailExpr]}
                            </span>
                        </p>
                    </label>
                    {/* children node*/}
                    {
                        data.children?.length != null && data.children.length > 0 &&
                        <ul>
                            {
                                data.children.map((child) => {
                                    return (
                                        <Node
                                            key={child[keyExpr]}
                                            data={child}
                                            onClick={(data) => onClick(data)}
                                            expand={expand}
                                            keyExpr={keyExpr}
                                            nameExpr={nameExpr}
                                            detailExpr={detailExpr}
                                            badgeExprs={badgeExprs}
                                            prefix={`${prefix}-${data[keyExpr]}`}
                                        />
                                    )
                                })
                            }
                        </ul>
                    }
                </li>
            }
        </>
    );
}
TreeCard = forwardRef(TreeCard);
