import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import TreeView from 'devextreme-react/tree-view';
import "../../contents/styles/menu/menu-select-template.css";
import Button from 'devextreme-react/button';
import useTranslation from "../../components/customHooks/translations";
import { getUser, getLanguage, getIP, getMenuMappingInfo } from '../../apiInterface/utils/Common';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import { getMainTemplateCategoryListAPI, getTemplateMasterAPI } from '../../apiInterface/menu/MenuAPI';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import iconTemplate from "../../contents/image/icon-template.svg";
import { PageTitle } from "../../components/common/PageTitle.jsx";
import { BASE_URL } from "../../config";

const MenuSelectTemplate = () => {

    const language = getLanguage();
    const translation = useTranslation();
    const user = getUser();
    const errorHandler = useErrorHandler();
    const location = useLocation();
    const { data, title, path, menu, serviceId } = location.state;
    const [selectedTemplate, setSelectedTemplate] = useState({ 'name': "", "id": "" });
    const [secondTitle, setSecondTitle] = useState();
    const [disableNext, setDisablenNext] = useState(true);

    //const [selectedTemplateId, setSelectedTemplateId] = useState('');
    //const [selectedTemplateName, setSelectedTemplateName] = useState('');

    const [templateList, setTemplateList] = useState([]);
    const navigate = useNavigate();
    const [treeList, setTreeList] = useState([]);
    const [templateCategory, setTemplateCategory] = useState('');
    /* tab event */
    const openTab = (e) => {
        const tabs = document.querySelectorAll(".tab p")
        const tabContents = document.querySelectorAll(".tab-content > div")
        tabs.forEach(tab => tab.classList.remove('active'));
        e.currentTarget.classList.add('active');

        tabContents.forEach(content => {
            content.classList.remove('active');
            if (content.getAttribute('name') == e.currentTarget.getAttribute('name')) {
                content.classList.add('active');
            }
        })
    }

    useEffect(() => {
        /*let data = location.state.data;*/
        if (data.seq == undefined || data?.seq < 1) {
            navigate("/Menu");
        } else {
            data.seq = 2;
            setSecondTitle(translation[title.toLowerCase()]);
        }
    }, []);


    /*select folder event*/
    const selectFolder = (e) => {
        const folders = document.querySelectorAll('p[class*="menu"]');
        if (e.currentTarget.classList.contains('active')) {
            e.currentTarget.classList.toggle('active');
        } else {
            folders.forEach(folder => folder.classList.remove('active'));
            e.currentTarget.classList.add('active');
        }
    }


    /* select template event */
    const selectTemplate = (e) => {
        const templates = document.querySelectorAll('.templates li');
        if (e.currentTarget.classList.contains('active')) {
            e.currentTarget.classList.toggle('active');
            setSelectedTemplate({ name: "", id: "" });
            setDisablenNext(true);
        } else {

            templates.forEach(template => template.classList.remove('active'));
            e.currentTarget.classList.add('active');
            const templateName = e.currentTarget.childNodes[1].textContent;
            setSelectedTemplate({ "name": templateName, "id": e.currentTarget.id })
            setDisablenNext(false);
        }
    }
    const getTemplateGridByCategoryId = async (categoryId = templateCategory.id) => {
        try {
            let param = {
                user_company_id: user.user_company_id,
                template_category_id: categoryId
            }
            const res = await getTemplateMasterAPI(param);
            if (res !== 'fail' && res.message === 'MSG_100') {
                //here data binding as icon will comes
                setTemplateList(res.data.o_data);

            } else {
                var iconType = "bad";
                var swalWidth = "380";
                var htmlString = `<span>${translation[res.message]}</p>`;
                var confirmationButtonText = translation.confirm;
                var swalResFn = (response) => { };
                swal(swalWidth, iconType, htmlString, confirmationButtonText, swalResFn);
            }
        } catch (err) {
            var iconType = "bad"
            var swalWidth = "380";
            var htmlString = `<span>${translation.MSG_99}</p>`;
            var confirmationButtonText = translation.confirm;
            var swalResFn = (res) => { };
            swal(swalWidth, iconType, htmlString, confirmationButtonText, swalResFn);
        }

    }
    const getMainTemplateCategoryList = async () => {
        let param = {
            user_company_id: user.user_company_id,
            sys_lang_code: language
        }
        const res = await errorHandler(getMainTemplateCategoryListAPI, param);
        if (res && res.data.o_data.length > 0) {
            var data = res.data.o_data;
            const transformed = res.data.o_data.map(({ template_category_id, template_category_name, template_category_parent_id }) => (
                { id: template_category_id, name: template_category_name, parentId: template_category_parent_id, icon: 'activefolder' }
            ));
            setTreeList(transformed);
            setTemplateCategory(transformed[0]);
            getTemplateGridByCategoryId(transformed[0].id);
        } else if (res && !(res.data.o_data.length > 0)) {

        }
    }
    const selectItem = async (e) => {
        const itemData = e.itemData;
        setTemplateCategory(itemData);
        //if (!e.node.children.length) {
        getTemplateGridByCategoryId(itemData.id);
        //}

    }
    const handleNextButton = async (e) => {
        navigate("../MenuBindingData", {
            state: {
                data, title, path, menu, selectedTemplate, serviceId
                //"selectedTemplate": selectedTemplate,
                //"formData": location.state.data,
                //"path": location.state.path,
                //"addOrEdit": location.state.title,
            }
        })
    }
    //init
    useEffect(() => {
        getMainTemplateCategoryList();
    }, []);

    return (
        <div className="menu-select-template">
            <div className="right-content-title">
                <PageTitle pageState={location.state.title} lastPage={translation.select_template} />
                {/*<div className="page-number"><span className="current-num">2</span>/<span className="total-num">3</span></div>*/}
            </div>
            <div className="right-content-body">
                <div className="card classification">
                    <div className="card-header">
                        <h6 className="card-title">{translation.template_category}</h6>
                    </div>
                    <div className="card-body">
                        <div className="tab">
                            <p className="active" onClick={openTab} name="my-template">{translation.my_tempalte}</p>
                            <p className="tab-template-store" onClick={openTab} name="template-store">{translation.template_store}</p>
                        </div>
                        <div className="tab-content">
                            <div className="my-template-content active" name="my-template">
                                <TreeView
                                    id="treeView"
                                    dataStructure="plain"
                                    items={treeList}
                                    displayExpr="name"
                                    onItemClick={selectItem}
                                    expandEvent="click"
                                />
                            </div>
                            <div className="template-store-content" name="template-store">
                                <div>Test</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card selected-template">
                    <div className="card-header">
                        <p className="card-title"><span>{translation.selected}: </span><span className="seleted-template">{selectedTemplate.name}</span></p>
                    </div>
                    <div className="card-body">
                        {
                            templateList.length > 0 ?
                                <ul className="templates">
                                    {templateList.map((value, index) => {
                                        const imgSrc = value.template_master_image_white ?
                                            `${BASE_URL}static_files/${value.template_master_image_white}`
                                            : iconTemplate;
                                            return (
                                                <li key={index} id={value.template_master_id} onClick={selectTemplate}>
                                                    <div><div><img src={imgSrc} /></div></div>
                                                <span>{value.template_master_name !== null ? value.template_master_name : translation.not_available}</span>
                                            </li>
                                        )
                                    })}
                                </ul> :
                                <>
                                    <div className="no-data">
                                        <div className="no-data-img"></div>
                                        <h2>{translation.template_no_data}</h2>
                                        <p>{translation.template_no_data_comment}</p>
                                    </div>
                                </>

                        }
                    </div>
                    <div className="card-footer">
                        <Button
                            type="normal"
                            stylingMode="text"
                            className="btn-s-r"
                            onClick={() => navigate(-1)}
                        >
                            <div>
                                <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                <span className="dx-button-text">{translation.prev}</span>
                            </div>
                        </Button>
                        <Button
                            type="default"
                            stylingMode="contained"
                            className="btn-s-r"
                            onClick={handleNextButton}
                            disabled={disableNext}
                        >
                            <div>
                                <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                <span className="dx-button-text">{translation.next}</span>
                            </div>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );

}
export default MenuSelectTemplate;
