import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getMenuMappingInfo } from "../../apiInterface/utils/Common";
import { useMenuMappingContext } from "../contexts/MenuMappingInfoProvider";
import useTranslation from "../customHooks/translations";

export const PageTitle = (props) => {
    const translation = useTranslation();
    const { menuMappingInfo } = useMenuMappingContext();

    return (
        <div className="page-title">
            <h4>{menuMappingInfo?.caption?.level3}</h4>
            <div className="breadcrumb">
                {menuMappingInfo?.caption?.level1 !== undefined ? <p>{menuMappingInfo?.caption?.level1}</p> : null}
                {menuMappingInfo?.caption?.level2 !== undefined ? <p>{menuMappingInfo?.caption?.level2}</p> : null}
                {menuMappingInfo?.caption?.level3 !== undefined ? <p>{menuMappingInfo?.caption?.level3}</p> : null}
                {props.pageState !== undefined ? <p>{translation[props.pageState.toLowerCase()]}</p> : null}
                {props.lastPage !== undefined ? <p>{props.lastPage}</p> : null}
            </div>
        </div>
    );
}
export default PageTitle;
