import React, { useState, useEffect } from 'react';
import { getUser, getIP, getMenuMappingInfo, getLanguage } from '../../apiInterface/utils/Common';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import useTranslation from "../../components/customHooks/translations";
import DataGrid, { Column } from 'devextreme-react/data-grid';
import DraggableTreeList from '../../components/common/DraggableTreeList';
import {
    getItemCategoryById,
    getItemMenuMapping,
    getSecuritySetting,
    getItemCategory,
    deleteItemCategory,
    updateItemCategorySeq
} from '../../apiInterface/item/ItemCategoryAPI';
import { DropdownField, FilterComponent } from '../../components/common/FormField';
import { Allotment } from 'allotment';
import "allotment/dist/style.css";
import { successToastr } from '../../components/common/Toastr';
import { PageDropdown } from '../../components/common/PageDropdown';
import Badge from '../../components/common/Badge';
import { PageTitle } from "../../components/common/PageTitle.jsx";

//reference
//https://github.com/johnwalley/allotment
//https://allotment-storybook.netlify.app/?path=/docs/basic--closable
let curSubGridId = null;
let searchText = "";
export default function ItemCategory(props) {
    const translation = useTranslation();
    const errorHandler = useErrorHandler();
    const activeNotActiveJson = { true: translation.active, false: translation.not_active };
    const activeNotActiveClassName = { true: "grid-badge-use", false: "grid-badge-disabled" }
    const [curDropdown, setCurDropdown] = useState();
    const [dropdown, setDropdown] = useState([]);
    const [treeList, setTreeList] = useState([]);
    const [datagrid, setDatagrid] = useState([]);
    const [dropdownDisable, setDropdownDisable] = useState();
    const user = getUser();
    const ipAddress = getIP();
    const [isOpen, setIsOpen] = useState(false);
    const language = getLanguage();

    const headerDict = [
        // { dataField: "item_category_no", caption: "NO", dataType: "number", alignment: "center", isFixed: true, allowReordering: true },
        { dataField: "item_category_name", caption: translation.item_category_name.toUpperCase(), dataType: "string", isFixed: true, allowReordering: true },
        {
            dataField: "item_category_is_active", caption: translation.item_category_is_active.toUpperCase(), dataType: "string",
            width: 130, alignment: "center", allowReordering: true, isBadge: true, captionForBadge: activeNotActiveJson, classNameForBadge: activeNotActiveClassName,
            cellRender: (e) => <Badge className={activeNotActiveClassName[e.value]} text={activeNotActiveJson[e.value]} />,
        },
        { dataField: "item_category_comment", caption: translation.item_category_comment.toUpperCase(), dataType: "string", allowReordering: true },
        { dataField: "item_category_modified_by", caption: translation.lastModifiedBy.toUpperCase(), dataType: "string", isEdit: false, width: 300, allowReordering: true },
        { dataField: "item_category_modified_date", caption: translation.lastModifiedDate.toUpperCase(), dataType: "string", isEdit: false, width: 150, allowReordering: true },
    ];

    const subGridHeader = [
        { dataField: "seq", caption: "NO", dataType: "number", width: 100, alignment: "center", allowReordering: true },
        { dataField: "security_master_name", caption: translation.security_master_name, dataType: "string", allowReordering: true },
        { dataField: "user_business_name", caption: translation.user_business_name, dataType: "string", allowReordering: true },
    ]

    //init
    useEffect(() => {
        searchText = "";
        getDropdown();
    }, []);

    const getDropdown = async () => {
        let param = {
            user_company_id: user.user_company_id,
            sys_lang_code: language
        }

        const res = await errorHandler(getItemMenuMapping, param);
        if (res) {
            setDropdown(res.data.o_data);
            if (res.data.o_data.length > 0) {
                setCurDropdown(res.data.o_data[0].menu_mapping_id);
                getTreeListData(res.data.o_data[0].menu_mapping_id);
            }
        }
    }

    const dropdownValueChanged = async (e) => {
        setCurDropdown(e.value);
        sessionStorage.setItem("itemMenu", e.value);
        if (e.value) {
            getTreeListData(e.value);
        }
    }

    const getTreeListData = async (mapId = curDropdown) => {
        let param = {
            user_company_id: user.user_company_id,
            menu_mapping_id: mapId,
            sys_lang_code: language,
            search_text: searchText
        }

        const res = await errorHandler(getItemCategory, param);
        if (res) {
            setTreeList(res.data.o_data);
        }
    }

    const onMoveDown = () => {
        setDropdownDisable(true);
    }

    const onMoveDownExit = () => {
        setDropdownDisable(false);
    }

    const onReorder = async (item) => {
        let Id = item.item_category_id;
        let parentId = item.item_category_parent_id;
        let topId = item.upperNode;
        updateRowSequence(Id, parentId, topId);
    }

    const updateRowSequence = async (id, parentId, topId) => {
        let param = {
            selected_menu_mapping_id: curDropdown,
            item_category_id: id,
            item_category_parent_id: parentId,
            item_category_top_id: topId,
        }

        const res = await errorHandler(updateItemCategorySeq, param);
        if (res) {

        }
    }

    const onDelete = async (removeList) => {
        let isSuccess = true;
        const { id: mapping_id } = await getMenuMappingInfo();
        for (let i = 0; i < removeList.length; i++) {
            const param = {
                user_master_id: user.user_master_id,
                user_log_ip: ipAddress,
                user_menu_mapping_id: mapping_id,
                item_category_id: removeList[i].item_category_id,
            };

            const res = await errorHandler(deleteItemCategory, param);
            if (!res) {
                isSuccess = false;
                break;
            }
        }

        if (isSuccess) {
            successToastr(translation.success_delete_row);
        }
        getTreeListData(curDropdown);
    }

    const onSelectCategory = async (e) => {
        if (e.currentDeselectedRowKeys.length > 0) {
            e.currentDeselectedRowKeys.forEach((id) => {
                if (id === curSubGridId) {
                    curSubGridId = null;
                    setDatagrid([]);
                    return;
                }
            })
        }
    }

    const onRowClick = async (e) => {
        if (!e.isSelected)
            return;

        const id = e.key;
        curSubGridId = id;
        let param = {
            item_category_id: id,
            sys_lang_code: language
        }

        const res = await errorHandler(getItemCategoryById, param);
        if (res) {
            let temp = res.data.o_data[0];
            let securityGroup = [];

            if (temp.security_str) {
                const securityToken = JSON.parse(temp.security_str);
                for (let i = 0; i < securityToken.length; i++) {
                    //change header
                    let group = {
                        seq: securityToken[i].target_no,
                        security_master_id: securityToken[i].target_id,
                        security_master_name: securityToken[i].target_name,
                        user_business_name: securityToken[i].user_business_name,
                    }
                    securityGroup.push(group);
                }
            }
            setDatagrid(securityGroup);
        }
    }

    const dropdownField = <DropdownField
        label={translation.item_category_template}
        dataSource={dropdown}
        value={curDropdown}
        displayExpr="menu_mapping_name"
        valueExpr="menu_mapping_id"
        onValueChanged={dropdownValueChanged}
        disabled={dropdownDisable}
        width={204}
        isSearchable={true}
    />

    const filtering = (keyword) => {
        searchText = keyword;
        getTreeListData();
    }

    const filter = <FilterComponent
        label={translation.filter}
        width={204}
        onFilter={filtering}
    />

    return (
        <>
            <div className="item-category">
                <div className="right-content-title">
                    <PageTitle />
                    <PageDropdown />
                </div>
                {/* toggling right panel button */}
                {/* <button onClick={() => setIsOpen((cur) => { return !cur; })}>toggle</button> */}
                <div className="right-content-body">
                    <div className="container">
                        <DraggableTreeList
                            toolbarModules={[filter]}
                            dataSource={treeList}
                            keyExpr="item_category_id"
                            displayExpr="item_category_name"
                            parentIdExpr="item_category_parent_id"
                            headers={headerDict}
                            onDelete={onDelete}
                            onReorder={onReorder}
                            link="ItemCategoryForm"
                            allowReordering={true}
                            selectMode="multiple"
                            onMoveDown={onMoveDown}
                            onMoveDownExit={onMoveDownExit}
                            onRowClick={onRowClick}
                            onSelect={onSelectCategory}
                            isFullSize={false}
                        />
                    </div>
                    {/*<Allotment>*/}
                    {/*    <Allotment.Pane>*/}
                    {/*        <DraggableTreeList*/}
                    {/*            toolbarModules={[filter]}*/}
                    {/*            dataSource={treeList}*/}
                    {/*            keyExpr="item_category_id"*/}
                    {/*            displayExpr="item_category_name"*/}
                    {/*            parentIdExpr="item_category_parent_id"*/}
                    {/*            headers={headerDict}*/}
                    {/*            onDelete={onDelete}*/}
                    {/*            onReorder={onReorder}*/}
                    {/*            link="ItemCategoryForm"*/}
                    {/*            allowReordering={true}*/}
                    {/*            selectMode="multiple"*/}
                    {/*            onMoveDown={onMoveDown}*/}
                    {/*            onMoveDownExit={onMoveDownExit}*/}
                    {/*            onRowClick={onRowClick}*/}
                    {/*            onSelect={onSelectCategory}*/}
                    {/*            isFullSize={false}*/}
                    {/*        />*/}
                    {/*    </Allotment.Pane>*/}
                    {/*    <Allotment.Pane*/}
                    {/*        visible={isOpen}*/}
                    {/*        maxSize={500}*/}
                    {/*    >*/}
                    {/*        */}{/* <div className="block"></div> */}
                    {/*        */}{/* right summary panel */}
                    {/*        {*/}
                    {/*            datagrid.length == 0 &&*/}
                    {/*            <div className="select-message">*/}
                    {/*                <div></div>*/}
                    {/*                <p>{translation.sys_division_select_division}</p>*/}
                    {/*            </div>*/}
                    {/*        }*/}
                    {/*        {*/}
                    {/*            datagrid.length > 0 &&*/}
                    {/*            <DataGrid*/}
                    {/*                dataSource={datagrid}*/}
                    {/*            >*/}
                    {/*                {*/}
                    {/*                    subGridHeader.map((val) => {*/}
                    {/*                        return <Column*/}
                    {/*                            key={val?.dataField}*/}
                    {/*                            dataField={val?.dataField}*/}
                    {/*                            caption={val?.caption}*/}
                    {/*                            allowEditing={val?.allowEditing}*/}
                    {/*                            dataType={val?.dataType}*/}
                    {/*                            fixed={val?.isFixed}*/}
                    {/*                            alignment={val?.alignment}*/}
                    {/*                            width={val?.width}*/}
                    {/*                        />*/}
                    {/*                    })*/}
                    {/*                }*/}
                    {/*            </DataGrid>*/}
                    {/*        }*/}
                    {/*    </Allotment.Pane>*/}
                    {/*</Allotment>*/}
                </div>
            </div>
        </>
    )
}
