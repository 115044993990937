import React, { useEffect, useState, useRef } from 'react';
import useTranslation from '../../components/customHooks/translations';
import BaseDataGrid from '../../components/common/BaseDataGrid';
import DraggableTreeList from '../../components/common/DraggableTreeList';
import { TreeList, Column } from 'devextreme-react/tree-list';
import {
    getLanguage,
    getUser,
    getMenuMappingInfo,
    getIP,
} from '../../apiInterface/utils/Common';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import { Button, Popup, ScrollView } from 'devextreme-react';
import CustomStore from 'devextreme/data/custom_store';
import Badge from '../../components/common/Badge';
import {
    SearchableField,
    TextboxField,
    DropdownField,
    FilterComponent,
    FileField,
} from '../../components/common/FormField';
import TreeCardWithLine from '../../components/common/TreeCardWithLine';
import {
    getSysDivisionCaptionByCode,
    getMainInspectionLibraryDefectTypeDT,
    // deleteInspectionLibraryDefectType,
} from '../../apiInterface/inspection/InspectionLibraryCondition';

let searchText = '';
const DIVISION_CODE = 'INSPECTIONTYPE';
const DefectTypeStandardGrid = (props) => {
    const { visible, items, isDeselect, isVisible } = props;

    const translation = useTranslation();
    const errorHandler = useErrorHandler();
    const user = getUser();
    const lang = getLanguage();
    const gridRef = useRef();

    const [dataSource, setDataSource] = useState([]);
    const [treeDataSource, setTreeDataSource] = useState([]);

    // get grid list
    const getDataGridInfo = async () => {
        // const customStore = new CustomStore({
        //     load: async (loadOptions) => {
        let skip = 0;
        let take = 30;
        // if (loadOptions['skip']) skip = loadOptions['skip'];
        // if (loadOptions['take']) take = loadOptions['take'];
        const pageNo = parseInt(skip / take) + 1;
        const param = {
            user_company_id: user.user_company_id,
            sys_lang_code: lang,
            row_count: take,
            page_no: pageNo,
            // search_text: searchText,
            // inspection_type: selectedDropdown,
        };

        const res = await errorHandler(
            getMainInspectionLibraryDefectTypeDT,
            param
        );
        if (res) {
            let rlt = {
                data: res.data.o_data,
                totalCount: res.data.count,
            };
            setDataSource(rlt.data);
            return rlt;
        }
        //     },
        //     remove: async (value) => {
        //         remove(value);
        //     },
        // });
        // setDataSource(customStore);
    };

    useEffect(() => {
        if (dataSource !== undefined) {
            const customData = dataSource.filter((item1, idx1) => {
                return (
                    dataSource.findIndex((item2, idx2) => {
                        return (
                            item1.inspection_library_id ==
                            item2.inspection_library_id
                        );
                    }) == idx1
                );
            });

            let copy = [];
            customData.map((m) => {
                let arr = [];
                dataSource.map((k) => {
                    if (m.inspection_library_id === k.inspection_library_id) {
                        arr.push(k);
                    }
                });

                copy.push({ ...m, child: arr });
            });

            setTreeDataSource(copy);
        }
    }, [dataSource]);

    // delete
    const remove = async (value) => {
        // const { id: mapping_id } = await getMenuMappingInfo();
        // const user = getUser();
        // const ipAddress = getIP();
        // const queryData = {
        //     user_master_id: user.user_master_id,
        //     user_log_ip: ipAddress,
        //     menu_mapping_id: mapping_id,
        //     user_company_id: user.user_company_id,
        //     inspection_library_defect_type_id:
        //         value.inspection_library_defect_type_id,
        // };
        // const response = await errorHandler(
        //     deleteInspectionLibraryDefectType,
        //     queryData
        // );
        // if (response !== 'fail' && response.message === 'MSG_100') {
        //     // successToastr(translation.success_delete_row);
        // }
    };

    // init
    useEffect(() => {
        getDataGridInfo();
    }, []);

    return (
        <div>
            <TreeCardWithLine data={treeDataSource} />
            {/* <BaseDataGrid
                toolbarModules={[inspectionDropdown, filter]}
                dataSource={dataSource}
                allowModify={true}
                isRemote={true}
                isPaging={true}
                isFullSize={true}
                link="DefectTypeStandardForm"
                headers={header}
                ref={gridRef}
                // visible
                showAdd={visible ? false : true}
                showEdit={visible ? false : true}
                showDelete={visible ? false : true}
                showCopy={visible ? false : true}
                isDoubleClicked={visible ? false : true}
                onSelectionChanged={onRowSelectionChanged}
                selectMode={visible ? 'single' : 'multiple'}
                isDisableCopy={false}
                // showAppliedItem = {visible ? false : true}
            /> */}
        </div>
    );
};
export default DefectTypeStandardGrid;
