﻿import { TextBox } from 'devextreme-react';
import CustomStore from 'devextreme/data/custom_store';
import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteDatasetMaster, getMainDataset } from '../../apiInterface/aasFile/DatasetManagementAPI';
import { getIP, getLanguage, getMenuMappingInfo, getUser } from '../../apiInterface/utils/Common';
import Badge from '../../components/common/Badge';
import BaseDataGrid from '../../components/common/BaseDataGrid';
import { FilterComponent } from '../../components/common/FormField';
import { PageDropdown } from "../../components/common/PageDropdown";
import { successToastr } from '../../components/common/Toastr';
import useTranslation from "../../components/customHooks/translations";
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import { PageTitle } from "../../components/common/PageTitle.jsx";

let searchText = "";
const DatasetManagement = () => {
    const translation = useTranslation();
    const navigate = useNavigate();
    const errorHandler = useErrorHandler();
    const [dataSource, setDataSource] = useState([]);
    const activeNotActiveJson = { true: translation.active, false: translation.not_active };
    const activeNotActiveClassName = { true: "grid-badge-use", false: "grid-badge-disabled" };
    const gridHeader = [
        { dataField: "master_dataset_no", caption: "NO", alignment: "center", dataType: "number", width: 100, isFixed: true, allowReordering: true },
        { dataField: "dataset_master_name", caption: translation.dataset_name.toUpperCase(), dataType: "string", isFixed: true, allowReordering: true },
        { dataField: "dataset_master_interval", caption: "interval".toUpperCase(), alignment: "right", dataType: "string", allowReordering: true, cellRender: (e) => <>{`${e.value}ms`}</> },
        {
            dataField: "dataset_master_is_active", caption: translation.use_or_not.toUpperCase(), dataType: "boolean",
            width: 130, alignment: "center", allowReordering: true,
            cellRender: (e) => <Badge className={activeNotActiveClassName[e.value]} text={activeNotActiveJson[e.value]} />,
        },
        { dataField: "dataset_master_comment", caption: translation.comment.toUpperCase(), dataType: "string", allowReordering: true },
        { dataField: "dataset_master_modified_by", caption: translation.lastModifiedBy.toUpperCase(), dataType: "string", allowReordering: true },
        { dataField: "dataset_master_modified_date", caption: translation.lastModifiedDate.toUpperCase(), dataType: "string", allowReordering: true },
    ];

    const onRowClick = (e) => {
        navigate("/DatasetManagementDetail", { state: { data: e.data } });
    }

    //init
    useEffect(() => {
        searchText = "";
        makeCustomStore();
    }, []);

    const makeCustomStore = () => {
        const store = new CustomStore({
            load: async (loadOptions) => {
                return load(loadOptions);
            },
            remove: async (value) => {
                remove(value);
            }
        });
        setDataSource(store);
    }

    const load = async (loadOptions) => {
        let result = { data: [], totalCount: 0 };

        const user = getUser();
        const lang = getLanguage();
        const pageNo = parseInt(loadOptions["skip"] / loadOptions["take"]) + 1;

        const param = {
            user_company_id: user.user_company_id,
            sys_lang_code: lang,
            row_count: loadOptions["take"],
            page_no: pageNo,
            search_text: searchText,
        };

        const res = await errorHandler(getMainDataset, param);
        if (res) {
            const data = res.data.o_data;
            result.data = data;
            result.totalCount = res.data.count;
        }

        return result;
    }

    const remove = async (data) => {
        const user = getUser();
        const ip = getIP();
        const mappingInfo = await getMenuMappingInfo();
        let param = {
            user_master_id: user.user_master_id,
            user_log_ip: ip,
            menu_mapping_id: mappingInfo.id,
            user_company_id: user.user_company_id,
            dataset_master_id: data.dataset_master_id,
        };

        const res = await errorHandler(deleteDatasetMaster, param);
        if (res) {
            successToastr(translation.success_delete_row);
        }
    }

    const filter = <FilterComponent
        label={translation.filter}
        width={204}
        onFilter={(text) => {
            searchText = text;
            makeCustomStore();
        }}
        showClearButton={true}
    />

    return (
        <>
            <div className="dataset-mgt">
                <div className="right-content-title">
                    <PageTitle />
                    <PageDropdown />
                </div>
                <div className="right-content-body">
                    <div className="container">
                        <BaseDataGrid
                            toolbarModules={[filter]}
                            dataSource={dataSource}
                            link="DatasetManagementForm"
                            onRowClick={onRowClick}
                            isRemote={true}
                            isFullSize={true}
                            headers={gridHeader}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
export default DatasetManagement;
