import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Button } from 'devextreme-react/button';
import '../../contents/styles/layout/resizable-panel.css';
import '../../contents/styles/style-darkmode.css';
import LeftSideBarContextProvider, { useSideBarContext } from '../contexts/LeftSideBarContext';
import { Tooltip } from 'devextreme-react/tooltip';
import { useNavigate } from 'react-router-dom';
import '../../contents/styles/icomoon.css';
import Navigation from './Navigation';
import { getServiceMappingInfo, getUser } from '../../apiInterface/utils/Common';

function ResizablePanel(props) {
    const navigate = useNavigate();
    const { allMenuList, toggleMenuIcon, rightContent, clickMenu = () => {}, nowMenu, serviceMenu } = props;
    const sidebarRef = useRef(null);
    const toggleRef = useRef(null);
    const [isOpen, setOpen] = useState(true);
    const [isResizing, setIsResizing] = useState(false);
    const [sidebarWidth, setSidebarWidth] = useState(250);
    const [togglePosition, setTogglePosition] = useState({ x: 0, y: 0 });
    const { setSideBar } = useSideBarContext();
    const sidebarContainerEl = document.querySelector('.sidebar-container');
    const toggleCheckboxEl = document.querySelector('.toggleCheckbox');
    const lastClickedSecondLevelMenu = useRef();
    const menuListRef = useRef();
    const user = getUser();

    const serviceMappingInfo = getServiceMappingInfo();
    //init
    useEffect(() => {
        setSideBar({ open, close });
    }, []);

    useEffect(() => {
        isOpen &&
            document.querySelectorAll('.level2').forEach((lv2) => {
                if (lv2.classList.contains('open')) {
                    let childCnt = lv2.childNodes[1].childNodes.length;
                    //lv2.childNodes[1].style.height = 38 * childCnt + 'px';
                    lv2.childNodes[0].childNodes[1].childNodes[1].classList.remove('dx-icon-m-arrow-down');
                    lv2.childNodes[0].childNodes[1].childNodes[1].classList.add('dx-icon-m-arrow-up');
                }
            });
    }, [isOpen]);

    const hoverEvent = (e, childCount) => {
        const windowHeight = window.innerHeight;
        let rect = e.target.getBoundingClientRect();
        let expectedHeight = 44 + 38 * childCount + 12; // titile height(44) + menu height(38) * child count + height padding(12)
        if (windowHeight - rect.top < expectedHeight) {
            e.target.parentNode.classList.add('up');
            console.log('up');
        } else {
            e.target.parentNode.classList.remove('up');
        }
    };

    const startResizing = React.useCallback((mouseDownEvent) => {
        setIsResizing(true);
    }, []);

    const stopResizing = React.useCallback(() => {
        setIsResizing(false);
    }, []);

    const resize = React.useCallback(
        (mouseMoveEvent) => {
            if (isResizing) {
                const postWidth = mouseMoveEvent.clientX - sidebarRef.current.getBoundingClientRect().left;
                setSidebarWidth(postWidth);
            }
        },
        [isResizing]
    );

    //if sidebarWidth is changed, adjust position of toggle button
    useEffect(() => {
        const maxWidth = parseInt(sidebarRef.current.style['max-width'], 10);
        const minWidth = parseInt(sidebarRef.current.style['min-width'], 10);

        //clamp value of toggle's x position
        let toggleXPos = sidebarWidth;
        if (toggleXPos >= maxWidth) {
            toggleXPos = maxWidth;
        } else if (toggleXPos <= minWidth) {
            toggleXPos = minWidth;
            close();
        } else {
            setOpen(true);
        }

        setTogglePosition({ ...togglePosition, x: toggleXPos });
    }, [sidebarWidth]);

    //open sidebar
    const open = () => {
        setOpen(true);
        setSidebarWidth(250);
        let toggleSidebar = document.querySelector('.sidebar-container > .dx-button');
        toggleSidebar.style.transform = 'rotate(360deg)';
        toggleSidebar.classList.remove('toggle-close');
    };

    //close sidebar
    const close = () => {
        setOpen(false);
        setSidebarWidth(0);
        let toggleSidebar = document.querySelector('.sidebar-container > .dx-button');
        toggleSidebar.style.transform = 'rotate(180deg)';
        toggleSidebar.classList.add('toggle-close');

        document.querySelectorAll('.level3-wrapper ul').forEach((lv3) => {
            lv3.removeAttribute('style');
        });
    };

    //register event to window
    useEffect(() => {
        window.addEventListener('mousemove', resize);
        window.addEventListener('mouseup', stopResizing);
        return () => {
            window.removeEventListener('mousemove', resize);
            window.removeEventListener('mouseup', stopResizing);
        };
    }, [resize, stopResizing]);

    //

    //change color mode(light, dark)
    //const handleMode = (e) => {
    //    sidebarContainerEl.classList.toggle('dark-mode')
    //}
    const handleMode = (e) => {
        document.documentElement.dataset.theme = e.currentTarget.checked ? 'dark' : 'light';
    };

    const findServiceMenuType = (menu) => {
        return menu.menu_mapping_id === parseInt(serviceMappingInfo?.id);
    };

    const secondLevelMenuClick = (e) => {
        toggleMenuIcon(e);

        document.querySelectorAll('.sidebar-slim').length == 0 &&
            document.querySelectorAll('.level3-wrapper ul').forEach((lv3) => {
                lv3.style.height = 0 + 'px';
            });

        if (lastClickedSecondLevelMenu.current != null) {
            lastClickedSecondLevelMenu.current.parentNode.classList.remove('active');
        } else {
            const active = menuListRef.current.querySelector('.active');
            active?.classList?.remove('active');
        }

        lastClickedSecondLevelMenu.current = e.currentTarget;
        if (e.currentTarget.parentNode.classList.contains('open')) {
            e.currentTarget.parentNode.classList.add('active');
            if (isOpen) {
                let length = e.currentTarget.nextSibling.children[0].childNodes.length;
                e.currentTarget.nextSibling.children[0].style.height = length * 38 + 'px';
            }
        } else {
            e.currentTarget.parentNode.classList.remove('active');
            if (isOpen) e.currentTarget.nextSibling.children[0].style.height = 0 + 'px';
        }
    };

    return (
        <div className="sidebar-container">
            <div
                ref={sidebarRef}
                className={`sidebar${isOpen == false ? ' sidebar-slim' : ''}`}
                style={{
                    width: sidebarWidth,
                    minWidth: '80px',
                    maxWidth: '500px',
                }}
                onMouseDown={(e) => e.preventDefault()}
            >
                <div className={`sidebar-content ${serviceMenu?.find((menu) => findServiceMenuType(menu))?.template_master_type}-sidebar`}>
                    <div className="logo-box" onClick={(e) => props.clickMenu(e, 'dashboard')}>
                        <div className={`icon ${user?.user_company_code}`}></div>
                        <div className={`logo ${user?.user_company_code}`}></div>
                    </div>
                    <div className="gnb">
                        <ul className="level1-wrapper" ref={menuListRef}>
                            {allMenuList?.child.map((level1, i) => (
                                <li key={`lvl1_${i}`} className="level1">
                                    <div className="level1-name">
                                        <p>{level1.name}</p>
                                    </div>
                                    <ul className="level2-wrapper">
                                        {level1.child.map((level2, j) => (
                                            <li key={`lvl2_${j}`} className={`level2 ${level2.mappingId === nowMenu.level2.mappingId ? 'active open' : ''}`}>
                                                <div className="level2-name" onClick={secondLevelMenuClick} onMouseOver={(e) => hoverEvent(e, level2.child.length)}>
                                                    <i className={`dx-icon-m-${level2.link}`}></i>
                                                    <div>
                                                        <p>{level2.name}</p>
                                                        {isOpen && <i className={`dx-icon-m-arrow-${level2.mappingId === nowMenu.level2.mappingId ? 'up' : 'down'}`}></i>}
                                                        {/* <i className="dx-icon-m-arrow-down"></i> */}
                                                    </div>
                                                </div>
                                                <div className="level3-wrapper">
                                                    <ul style={{ height: level2.mappingId === nowMenu.level2.mappingId ? `${level2.child.length * 38}px` : '0px' }}>
                                                        {level2.child.map((level3, k) => (
                                                            <li key={`lvl3_${k}`} className={`level3 ${level3.mappingId === nowMenu.level3.mappingId ? 'active' : ''}`}>
                                                                <div className="level3-name" link={level3.link} onClick={(e) => clickMenu(e, level3)}>
                                                                    <p>{level3.name}</p>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                {/*�����ڵ�*/}
                {/*<div className="sidebar-content">*/}
                {/*    <div className="logo-box">*/}
                {/*        <div className="icon"></div>*/}
                {/*        <div className="logo"></div>*/}
                {/*    </div>*/}
                {/*    <div className="project-title">*/}
                {/*        <p>{props.nowProjectMenuList?.name}</p>*/}
                {/*    </div>*/}
                {/*    <div className="project-list">*/}
                {/*        <ul className="level1">*/}
                {/*            {props.nowProjectMenuList?.child.map((level2, i) => (*/}
                {/*                <li key={i} className={`level2-close ${level2.child.length > 0 ? "level2-open" : ""}`}>*/}
                {/*                    <div className="level1-menu" onClick={props.toggleMenuIcon}>*/}
                {/*                        <p>{level2.name}</p>*/}
                {/*                    </div>*/}
                {/*                    <ul className="level2">*/}
                {/*                        {level2.child.map((level3, j) => (*/}
                {/*                            <li key={j} className={`${level3.name === props.nowMenu ? "active" : ""}`}>*/}
                {/*                                <div className="level3-menu"*/}
                {/*                                    link={level3.link}*/}
                {/*                                    onClick={e => props.clickMenu(e, level3)}>*/}
                {/*                                    <p>{level3.name}</p>*/}
                {/*                                </div>*/}
                {/*                            </li>*/}
                {/*                        ))}*/}
                {/*                    </ul>*/}
                {/*                </li>*/}
                {/*            ))}*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="sidebar-resizer" />
            </div>
            <Button
                ref={toggleRef}
                className="toggle-sidebar toggle-button"
                type="default"
                onClick={(e) => {
                    if (isOpen) close();
                    else open();
                }}
            >
                <Tooltip target=".toggle-button" showEvent="dxhoverstart" hideEvent="dxhoverend" position="right">
                    <div>{isOpen ? 'close' : 'open'}</div>
                </Tooltip>
            </Button>
            <div className="sidebar-rightcontent">
                <Navigation allMenuList={allMenuList} clickMenu={clickMenu} serviceMenu={serviceMenu} />
                <div className="toggleBox">
                    <input type="checkbox" id="toggle" className="toggleCheckbox" onChange={handleMode} />
                    <label htmlFor="toggle" className="toggleContainer">
                        <div></div>
                        <div></div>
                    </label>
                </div>
                <div className="page">{rightContent}</div>
            </div>
        </div>
    );
}

export default ResizablePanel;

// function MenuItem(props) {
//     const {
//         level,
//         link,
//         name,
//         active,
//         children,
//     } = props;

//     const [open, setOpen] = useState(false);

//     const toggle = () => setOpen(!open);

//     return (
//         <li className={[
//             `level${level}`,
//             open ? "open" : "close",
//             active ? "active" : undefined].join(" ")}
//         >
//             <div
//                 className={level ? `level${level}-name` : undefined}
//                 onClick={toggle}
//                 link={link}
//             >
//                 <p>{name}</p>
//             </div>
//             {
//                 children &&
//                 <ul className={`level${level + 1}-wrapper`}>
//                     {
//                         children.map(() => {

//                         })
//                     }
//                 </ul>
//             }
//         </li>
//     )
// }
