import {
    makeTokenAxiosGetRequest, makeTokenAxiosPostRequest,
    makeTokenAxiosPutRequest, makeTokenAxiosDeleteRequest,
    makeAxiosPostFileRequest
} from "../utils/AxiosMask"


export const getOperationFactory = async (data) => {
    var res;
    const urlPath = `machine_equipment/get_operation_factory/`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};

export const getEquipmentInfo = async (data) => {
    var res;
    const urlPath = `machine_equipment/`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};

export const deleteEquipmentInfoByEquipmentId = async (data) => {
    var res;
    const urlPath = `machine_equipment/`;
    try {
        res = await makeTokenAxiosDeleteRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};

export const getEquipmentDDByCompanyId = async (data) => {
    var res;
    const urlPath = `machine_equipment/get_equipment_type_dd/`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};

export const insertEquipmentInfoByCompanyId = async (data) => {
    var res;
    const urlPath = `machine_equipment/`;
    try {
        res = await makeTokenAxiosPostRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};

export const getMachineEquipmentInfoByMachineId = async (data) => {
    var res;
    const urlPath = `machine_equipment/get_machine_equipment_by_id/`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};

export const updateEquipmentInfoByCompanyId = async (data) => {
    var res;
    const urlPath = `machine_equipment/`;
    try {
        res = await makeTokenAxiosPutRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};

export const uploadEquipmentImage = async (data, formData) => {
    var res;
    const urlPath = `machine_equipment/upload_attachments?user_id=${data.user_id}`;
    try {
        res = await makeAxiosPostFileRequest(urlPath, formData);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};

export const getMoldInfoByEquipmentId = async (data) => {
    var res;
    const urlPath = `machine_equipment/machine_equipment_mold_item_by_equipment_id/`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};

export const saveMoldInfo = async (data) => {
    var res;
    const urlPath = `machine_equipment/machine_equipment_mold?machine_equipment_id=${data.machine_equipment_id}&machine_mold_id=${data.machine_mold_id}`;
    try {
        res = await makeTokenAxiosPostRequest(urlPath, null);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        ;
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
};
