import { makeTokenAxiosGetRequest, makeTokenAxiosPutRequest, makeTokenAxiosPostRequest } from "../utils/AxiosMask"
const baseUrl = 'abnormal_logs';

export const getAbnormalLogs = async (data) => {
    var res;
    const urlPath = `${baseUrl}/abnormal_logs_dt/`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
}
