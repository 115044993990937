﻿import React, { useEffect, useState } from 'react';
import Button from 'devextreme-react/button';
import TextBox from 'devextreme-react/text-box';
import { getUser, getIP, getLanguage, getMenuMappingInfo } from '../../apiInterface/utils/Common';
import { useSwal } from '../../components/common/Swal';
import useTranslation from '../../components/customHooks/translations';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { useLocation, useNavigate } from 'react-router-dom';
import { insertBusinessInfoByCompanyId, updateBusinessInfoByCompanyId, getBusinessInfoByBusinessId } from '../../apiInterface/businessManagement/BusinessManagementAPI';
import { actionDirect, moveFocus, submit } from '../../components/common/FormFuncWithEnter';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import { TextboxField, DatePickerField, FileField, DropdownField, BaseField } from '../../components/common/FormField';
import { Validator } from 'devextreme-react';
import { RequiredRule } from 'devextreme-react/data-grid';
import { PageTitle } from '../../components/common/PageTitle.jsx';
import TextArea from 'devextreme-react/text-area';

const BusinessManagementInfoForm = () => {
    const translation = useTranslation();
    const language = getLanguage();
    const location = useLocation();
    const title = location.state.title;
    const errorHandler = useErrorHandler();
    const navigate = useNavigate();
    const { success } = useSwal();
    const { state } = useLocation();
    const [data, setData] = useState({});

    const useItems = [
        { value: true, title: translation.use },
        { value: false, title: translation.notUse },
    ];
    useEffect(() => {
        const init = async () => {
            if (state.title == 'Edit') {
                getData();
            } else {
                valueChanged(useItems[0].value, 'user_business_is_active');
            }
        };
        init();
    }, []);

    const valueChanged = (value, key) => {
        setData((cur) => {
            let newData = { ...cur };
            newData[key] = value;
            return newData;
        });
    };

    const openDaumPostcode = () => {
        const daum = window.daum;
        new daum.Postcode({
            oncomplete: function (data) {
                var roadAddr = data.roadAddress; // 도로명 주소 변수
                var extraRoadAddr = ''; // 참고 항목 변수

                if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
                    extraRoadAddr += data.bname;
                }
                if (data.buildingName !== '' && data.apartment === 'Y') {
                    extraRoadAddr += extraRoadAddr !== '' ? ', ' + data.buildingName : data.buildingName;
                }
                if (extraRoadAddr !== '') {
                    extraRoadAddr = ' (' + extraRoadAddr + ')';
                }

                document.querySelector('#sample4_postcode input').value = data.zonecode;
                document.querySelector('#sample4_roadAddress input').value = roadAddr + extraRoadAddr;
                valueChanged(data.zonecode, 'user_business_zipcode');
                valueChanged(roadAddr + extraRoadAddr, 'user_business_address');

                // focus move
                document.querySelector(`[focusidx='4']`).focus();
            },
        }).open({ autoClose: true });
    };

    const getData = async () => {
        const queryData = {
            user_business_id: state.rowData.user_business_id,
            sys_lang_code: language,
        };
        const res = await errorHandler(getBusinessInfoByBusinessId, queryData);
        if (res) {
            let temp = res.data.o_data[0];
            setData(temp);
            valueChanged(state.rowData.machine_equipment_id, 'machine_equipment_id');
        }
    };

    const onInsert = async () => {
        const user = getUser();
        const ipAddress = getIP();
        const menuMappingInfo = await getMenuMappingInfo();
        const queryData = {
            user_master_id: user.user_master_id,
            user_company_id: user.user_company_id,
            user_business_name: data.user_business_name,
            user_business_zipcode: data.user_business_zipcode,
            user_business_address: data.user_business_address,
            user_business_address2: data.user_business_address2,
            user_business_phone: data.user_business_phone,
            user_business_is_active: data.user_business_is_active,
            user_business_comment: data.user_business_comment,
            user_master_email: user.user_master_email,
            user_log_ip: ipAddress,
            user_master_id: user.user_master_id,
            user_menu_mapping_id: menuMappingInfo.id,
        };
        const res = await errorHandler(insertBusinessInfoByCompanyId, queryData);
        if (res) {
            var htmlString = `<span></span><p>${translation.save_successfully}</p>`;
            var swalResFn = (response) => {
                navigate('/businessmanagementinfo');
            };
            success(htmlString, swalResFn);
        }
    };

    const onUpdate = async () => {
        const user = getUser();
        const ipAddress = getIP();
        const menuMappingInfo = await getMenuMappingInfo();
        const queryData = {
            user_master_id: user.user_master_id,
            user_company_id: user.user_company_id,
            user_business_id: data.user_business_id,
            user_business_name: data.user_business_name,
            user_business_zipcode: data.user_business_zipcode,
            user_business_address: data.user_business_address,
            user_business_address2: data.user_business_address2,
            user_business_phone: data.user_business_phone,
            user_business_is_active: data.user_business_is_active,
            user_business_comment: data.user_business_comment,
            user_business_modified_by: user.user_business_modified_by,
            user_master_email: user.user_master_email,
            user_log_ip: ipAddress,
            user_menu_mapping_id: menuMappingInfo.id,
            user_master_password: user.user_master_password,
            sys_lang_code: language,
        };

        const res = await errorHandler(updateBusinessInfoByCompanyId, queryData);
        if (res) {
            var htmlString = `<span></span><p>${translation.save_successfully}</p>`;
            var swalResFn = (response) => {
                navigate('/businessmanagementinfo');
            };
            success(htmlString, swalResFn);
        }
    };
    const exitForm = () => {
        navigate('/BusinessManagementInfo', {
            state: {
                previousPage: location.pathname,
            },
        });
    };

    return (
        <>
            <div className="business-management-info-form">
                <div className="right-content-title">
                    <PageTitle pageState={title} />
                </div>
                <form onSubmit={actionDirect}>
                    <div className="right-content-body">
                        <div className="container">
                            <div className="dx-fieldset">
                                <TextboxField
                                    label={translation.user_business_name}
                                    value={data?.user_business_name}
                                    onValueChanged={(e) => valueChanged(e.value, 'user_business_name')}
                                    isEssential={true}
                                    essentialMsg={translation.user_business_name + translation.is_required}
                                    onEnterKey={moveFocus}
                                    inputAttr={{ focusidx: 0, autofocus: true }}
                                />

                                <DropdownField
                                    isSearchable={false}
                                    label={translation.use_or_not}
                                    isEssential={true}
                                    esstialMsg={translation.use_or_not + translation.is_required}
                                    dataSource={useItems}
                                    value={data?.user_business_is_active}
                                    valueExpr="value"
                                    displayExpr="title"
                                    onValueChanged={(e) => valueChanged(e.value, 'user_business_is_active')}
                                    onEnterKey={(e) => moveFocus(e)}
                                    inputAttr={{ focusidx: 1 }}
                                />

                                <BaseField
                                    label={translation.address}
                                    isColumn={false}
                                    isEssential={true}
                                    component={
                                        <div className="search">
                                            <TextBox className="ix-tfs-r" id="sample4_postcode" name="user_business_zipcode" value={data?.user_business_zipcode} readOnly={true}>
                                                <Validator>
                                                    <RequiredRule message={translation.address + translation.is_required} />
                                                </Validator>
                                                <div className="clickable-textbox" onClick={openDaumPostcode}></div>
                                            </TextBox>
                                            <Button icon="search" type="default" stylingMode="contained" className="btn-s-r dx-button-mode-es btn-only-icon" onClick={openDaumPostcode} onEnterKey={moveFocus} elementAttr={{ focusidx: 2 }} />
                                        </div>
                                    }
                                />

                                <TextboxField
                                    id={'sample4_roadAddress'}
                                    label=""
                                    value={data?.user_business_address}
                                    isEssential={false}
                                    esstialMsg={translation.user_business_address + translation.is_required}
                                    onEnterKey={moveFocus}
                                    readOnly={true}
                                    inputAttr={{ focusidx: 3, autofocus: false }}
                                    onSearch={openDaumPostcode}
                                    hasTextboxPopup={true}
                                />
                                <TextboxField
                                    id={'sample4_detailAddress'}
                                    label={translation.address2}
                                    value={data?.user_business_address2}
                                    onValueChanged={(e) => valueChanged(e.value, 'user_business_address2')}
                                    isEssential={false}
                                    esstialMsg={translation.user_business_address2 + translation.is_required}
                                    onEnterKey={moveFocus}
                                    inputAttr={{ focusidx: 4 }}
                                />

                                <TextboxField
                                    label={translation.user_business_phone}
                                    value={data?.user_business_phone}
                                    onValueChanged={(e) => valueChanged(e.value, 'user_business_phone')}
                                    isEssential={false}
                                    esstialMsg={translation.user_business_phone + translation.is_required}
                                    onEnterKey={moveFocus}
                                    inputAttr={{ focusidx: 5 }}
                                />

                                {/*<TextboxField*/}
                                {/*    label={translation.user_business_comment}*/}
                                {/*    value={data?.user_business_comment}*/}
                                {/*    onValueChanged={(e) => valueChanged(e.value, "user_business_comment")}*/}
                                {/*    isEssential={false}*/}
                                {/*    esstialMsg={translation.user_business_comment + translation.is_required}*/}
                                {/*    onEnterKey={moveFocus}*/}
                                {/*    inputAttr={{ focusidx: 6 }}*/}
                                {/*/>*/}

                                <div className="dx-field">
                                    <div className="dx-field-label">
                                        <p>{translation.user_business_comment}</p>
                                    </div>
                                    <div className="dx-field-value">
                                        <TextArea value={data?.user_business_comment} onValueChanged={(e) => valueChanged(e.value, 'user_business_comment')} inputAttr={{ focusidx: 6 }} />
                                    </div>
                                </div>
                            </div>
                            <div className="block"></div>
                            {location.state.title == 'Edit' && (
                                <>
                                    <div className="dx-fieldset write-info">
                                        <div className="dx-field">
                                            <div className="dx-field-label">{translation.lastModifiedDate}</div>
                                            <div className="dx-field-value">{data?.modified_date}</div>
                                        </div>
                                        <div className="dx-field">
                                            <div className="dx-field-label">{translation.lastModifiedBy}</div>
                                            <div className="dx-field-value">
                                                {data?.modified_by_name}
                                                {data?.modified_by_duty && <span className="badge badge-job-position">{data?.modified_by_duty}</span>}
                                                {data?.modified_by_department && <span className="badge badge-team">{data?.modified_by_department}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="block"></div>
                                </>
                            )}
                            <div className="btn-group dx-fieldset">
                                <Button type="normal" stylingMode="text" className="btn-s-r" onClick={exitForm}>
                                    <div>
                                        <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                        <span className="dx-button-text">{translation.cancel}</span>
                                    </div>
                                </Button>
                                <Button
                                    id="submitBtn"
                                    type="default"
                                    stylingMode="contained"
                                    className="btn-s-r"
                                    useSubmitBehavior={true}
                                    onClick={(e) => {
                                        if (title === 'Add') submit(e, onInsert);
                                        else if (title === 'Edit') submit(e, onUpdate);
                                    }}
                                >
                                    <div>
                                        <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                        <span className="dx-button-text">{translation.save}</span>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default BusinessManagementInfoForm;
