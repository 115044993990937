import { makeTokenAxiosDeleteRequest, makeTokenAxiosGetRequest, makeTokenAxiosPostRequest, makeTokenAxiosPutRequest, makeTokenAxiosPutRequestParams } from "../utils/AxiosMask"
const baseUrl = 'menu';
const getMenuMappingServiceAPI = async (data) => {
    var res;
    const urlPath = `menu/get_menu_mapping_service`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res : "fail";
};

const getMenuMappingServiceChild = async (data) => {
    var res;
    const urlPath = `menu/get_menu_mapping_service_child`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res : "fail";
};

const getMenuMappingShortCut = async (data) => {
    var res;
    const urlPath = `menu/get_menu_mapping_shortcut`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res : "fail";
}

const insertUserShortCut = async (data) => {
    var res;
    const urlPath = `menu/insert_user_shortcut?user_master_id=${data.user_master_id}&menu_mapping_id=${data.menu_mapping_id}`;
    try {
        res = await makeTokenAxiosPostRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {

        console.error('error', error);
    }
    return res.status === 200 ? res : "fail";
}

const deleteUserShortCut = async (data) => {
    var res;
    const urlPath = `menu/delete_user_shortcut`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res : "fail";
}

const getTemplateMasterAPI = async (data) => {
    var res;
    const urlPath = `menu/get_template_master`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res : "fail";
}
const getTemplateGridByCategoryIdAPI = async (param) => {
    let res;
    const urlPath = `${baseUrl}/`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res.data : "fail";
}
const getMainTemplateCategoryListAPI = async (param) => {
    let res;
    const urlPath = `${baseUrl}/get_main_template_category`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res.data : "fail";
}
const getTemplateMasterLayoutAPI = async (data) => {
    var res;
    const urlPath = `menu/get_template_master_layout`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res : "fail";
}
const getCollListAPI = async (param) => {
    let res;
    const urlPath = `${baseUrl}/collection_list`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res.data : "fail";
}
const getCollDataAPI = async (param) => {
    let res;
    const urlPath = `${baseUrl}/get_coll_data`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res.data : "fail";
}

const getMenuGridAPI = async (param) => {
    let res;
    const urlPath = `${baseUrl}/get_main_menu_mapping/`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res.data : "fail";
}

const getMenuMappingById = async (param) => {
    let res;
    const urlPath = `${baseUrl}/get_menu_mapping_by_id/`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res.data : "fail";
}

const insertMenuMapping = async (param) => {
    let res;
    const urlPath = `${baseUrl}/insert_menu_mapping/`;

    try {
        res = await makeTokenAxiosPostRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res.data : "fail";
}

const getMenuPageInitInformation = async (param) => {
    let res;
    const urlPath = `${baseUrl}/get_menu_page_init_information/`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : "fail";
}

const updateMenuMappingSeq = async (param) => {
    let res;
    const urlPath = `${baseUrl}/update_menu_mapping_seq/`;

    try {
        res = await makeTokenAxiosPutRequestParams(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res.data : "fail";
}

const updateMenuMapping = async (param) => {
    let res;
    const urlPath = `${baseUrl}/update_menu_mapping/`;

    try {
        res = await makeTokenAxiosPutRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res.data : "fail";
}

const insertMenuMappingCaption = async (param) => {
    let res;
    const urlPath = `${baseUrl}/insert_menu_mapping_caption/`;

    try {
        res = await makeTokenAxiosPostRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res.data : "fail";
}

const insertMenuMappingSetting = async (param) => {
    let res;
    const urlPath = `${baseUrl}/insert_menu_mapping_setting/`;

    try {
        res = await makeTokenAxiosPostRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res.data : "fail";
}

const deleteMenuMapping = async (param) => {
    let res;
    const urlPath = `${baseUrl}/delete_menu_mapping/`;

    try {
        res = await makeTokenAxiosDeleteRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res.data : "fail";
}
const getCMVPaneData = async (param) => {
    let res;
    const urlPath = `${baseUrl}/get_cmv_pane_data/`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res.data : "fail";
}
const getSysDivisionCaptionByCode = async (param) => {
    let res;
    const urlPath = `${baseUrl}/get_sys_division_caption_by_code/`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : "fail";
}

const getMachineEquipment = async (param) => {
    let res;
    const urlPath = `${baseUrl}/get_machine_equipment/`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : "fail";
}

const getTemplateTypeMultiChart = async (param) => {
    let res;
    const urlPath = `${baseUrl}/get_template_type_multi_chart/`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : "fail";
}

const getDatasetMasterExistsBoth= async (param) => {
    let res;
    const urlPath = `${baseUrl}/get_dataset_master_exists_both/`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : "fail";
}

const getDatasetMasterPropertyDivision = async (param) => {
    let res;
    const urlPath = `${baseUrl}/get_dataset_master_property_division/`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : "fail";
}

export const getDatasetMasterPropertyDivisionByDsIds = async (param) => {
    let res;
    const urlPath = `${baseUrl}/get_dataset_master_property_division_by_ds_ids/`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : "fail";
}

const getPreviewData = async (param) => {
    let res;
    const urlPath = `${baseUrl}/get_preview_data/`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : "fail";
}

export const getOperationLineAPI = async (param) => {
    let res;
    const urlPath = `${baseUrl}/get_operation_line/`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : "fail";
}

export const getMenuMappingAndPartPage = async (param) => {
    let res;
    const urlPath = `${baseUrl}/get_menu_mapping_and_part_page/`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : "fail";
}

export const uploadTextEditorImage = async (param, userId) => {
    let res;
    const urlPath = `${baseUrl}/upload_text_editor_image?user_id=${userId}`;

    try {
        res = await makeTokenAxiosPostRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : "fail";
}

export {
    getMenuMappingServiceAPI,
    getMenuMappingServiceChild,
    getMenuMappingShortCut,
    insertUserShortCut,
    deleteUserShortCut,
    getTemplateMasterAPI,
    getTemplateGridByCategoryIdAPI,
    getMainTemplateCategoryListAPI,
    getTemplateMasterLayoutAPI,
    getCollListAPI,
    getCollDataAPI,
    getMenuGridAPI,
    getMenuMappingById,
    insertMenuMapping,
    updateMenuMappingSeq,
    updateMenuMapping,
    insertMenuMappingCaption,
    insertMenuMappingSetting,
    getMenuPageInitInformation,
    deleteMenuMapping,
    getCMVPaneData,
    getSysDivisionCaptionByCode,
    getTemplateTypeMultiChart,
    getDatasetMasterExistsBoth,
    getDatasetMasterPropertyDivision,
    getPreviewData,
}