import React from 'react';
import ReactDOM from 'react-dom/client';
import '../src/contents/styles/index.css';
import LanguageContextProvider from "../src/components/contexts/LanguageContext"//"./components/contexts/LanguageContext";
// eslint-disable-next-line
/* eslint-disable */
import App from './App';
import MenuMappingInfoProvider from './components/contexts/MenuMappingInfoProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    //<React.StrictMode>
        <LanguageContextProvider>
            <MenuMappingInfoProvider>
                <App />
            </MenuMappingInfoProvider>
        </LanguageContextProvider>
    //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
