import React from 'react';
import { useImperativeHandle } from 'react';
import { useState } from 'react';
import { forwardRef } from 'react';
import useTranslation from '../customHooks/translations';
import { BaseField } from './FormField';


const LastModifiedIndicator = forwardRef((props, ref) => {
    const {
        date = "",
        writer = "",
        position = "",
        department = "",
    } = props;

    const translation = useTranslation();

    return (
        <div>
            <div className="dx-fieldset write-info">
                <BaseField
                    label={translation.lastModifiedDate}
                    component={date}
                />
                <BaseField
                    label={translation.lastModifiedBy}
                    component={
                        <>
                            {writer}
                            {position ? <span className="badge badge-job-position">{position}</span> : <></>}
                            {department ? <span className="badge badge-team">{department}</span> : <></>}
                        </>
                    }
                />
            </div>
            <div className="block"></div>
        </div>
    );
});

export default LastModifiedIndicator;