import useTranslation from '../../components/customHooks/translations';
import { TextboxField } from '../../components/common/FormField';
import { Popup } from 'devextreme-react/popup';
import { ValidationGroup } from 'devextreme-react/validation-group';
import { RequiredRule, EmailRule } from 'devextreme-react/validator';
import Button from 'devextreme-react/button';
import { useState, useEffect } from 'react';

export default function FindPasswordPopup(props) {
    const { onSubmit = () => {}, onClose = () => {}, visible, pending } = props;

    const [text, setText] = useState('');
    const translation = useTranslation();

    const close = () => {
        setText('');
        onClose();
    };

    const handleTextChange = (e) => {
        setText(e.value);
    };
    const submit = async (e) => {
        e.preventDefault();

        if (pending) return;

        await onSubmit(text);
        close();
    };

    return (
        <Popup width={360} height="auto" visible={visible} hideOnOutsideClick={true} onHiding={close} dragEnabled={true} position="center" showTitle={false}>
            <div className="popup-header">
                <p className="popup-title">{translation.findPassword}</p>
            </div>
            <form id="forgotPassword" onSubmit={submit}>
                <ValidationGroup>
                    <div>
                        <div className="popup-body">
                            <div className="find-password-message" dangerouslySetInnerHTML={{ __html: translation.forgotPasswordDesc }}></div>
                            <div className="dx-fieldset">
                                <TextboxField isColumn={true} label={translation.email} onValueChanged={handleTextChange} value={text}>
                                    {/* <RequiredRule message={translation.email + translation.is_required} /> */}
                                    <EmailRule message={translation.email + translation.is_invalid} />
                                </TextboxField>
                            </div>
                        </div>
                        <div className="popup-footer">
                            <div className="dx-fieldset">
                                <Button width="100%" text={translation.cancel} type="normal" stylingMode="text" className="btn-s-r" onClick={close} />
                            </div>
                            <div className="dx-fieldset">
                                <Button text={translation.confirm} width="100%" type="default" stylingMode="contained" className="btn-s-r" disabled={pending} useSubmitBehavior={true} />
                            </div>
                        </div>
                    </div>
                </ValidationGroup>
            </form>
        </Popup>
    );
}
