import {
    makeTokenAxiosDeleteRequest,
    makeTokenAxiosGetRequest,
    makeTokenAxiosPostRequest,
    makeTokenAxiosPutRequest,
    makeTokenAxiosPutRequestParams
} from "../utils/AxiosMask";

const BASE_URL = 'dataset_master';

export async function getMainMasterDatasetAPI(param) {
    var res;
    const urlPath = `${BASE_URL}/main_master_dataset`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res : "fail";
};

export async function getOneMasterDatasetAPI(param) {
    var res;
    const urlPath = `${BASE_URL}/one_master_dataset`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res : "fail";
};

export async function updateMasterDatasetAPI(param) {
    var res;
    const urlPath = `${BASE_URL}/master_dataset`;
    try {
        res = await makeTokenAxiosPutRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res : "fail";
};

export async function insertMasterDatasetAPI(param) {
    var res;
    const urlPath = `${BASE_URL}/master_dataset`;
    try {
        res = await makeTokenAxiosPostRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res : "fail";
};

export async function deleteMasterDatasetAPI(param) {
    var res;
    const urlPath = `${BASE_URL}/master_dataset`;
    try {
        res = await makeTokenAxiosDeleteRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res : "fail";
};

export async function getMoldListAPI(param) {
    var res;
    const urlPath = `${BASE_URL}/machine_mold_by_equipment_id`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res : "fail";
};

export async function getItemListAPI(param) {
    var res;
    const urlPath = `${BASE_URL}/item_master_by_mold_id`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res : "fail";
};

export async function getMasterDatasetTypeAPI(param) {
    var res;
    const urlPath = `${BASE_URL}/get_master_dataset_type`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res : "fail";
};

export async function getFilterDropdownAPI(param) {
    var res;
    const urlPath = `${BASE_URL}/get_filter_dropdown`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res : "fail";
};