import React, { useState, useEffect, useRef, createRef } from "react";
import { BrowserRouter, Route, NavLink } from "react-router-dom";
import TreeView from 'devextreme-react/tree-view';
import Sortable from 'devextreme-react/sortable';
import dummyData from './dummy_data';
import axios from 'axios';

export default function LeftSideBar(porp) {
    const [data, setData] = useState(dummyData.getItemsDriveC());
    const treeViewRef = createRef();


    //Check holding element is child of target element. If it is true cancel moving.
    const onDragChange = (e) => {
        if (e.fromComponent === e.toComponent) {
            const fromNode = findNode(treeViewRef, e.fromIndex);
            const toNode = findNode(treeViewRef, calculateToIndex(e));
            if (toNode !== null && isChildNode(fromNode, toNode)) {
                e.cancel = true;
            }
        }
    }

    const isChildNode = (parentNode, childNode) => {
        let { parent } = childNode;
        while (parent !== null) {
            if (parent.itemData.id === parentNode.itemData.id) {
                return true;
            }
            parent = parent.parent;
        }
        return false;
    }


    const onDragEnd = (e) => {
        //ignore if node is dropped onto itself.
        if (e.fromComponent === e.toComponent && e.fromIndex === e.toIndex) {
            return;
        }

        const fromNode = findNode(treeViewRef, e.fromIndex);
        const toNode = findNode(treeViewRef, calculateToIndex(e));

        //ignore if node is dropped into non directory node.
        //should be modified by structure of data.
        if (e.dropInsideItem && toNode !== null && !toNode.itemData.isDirectory) {
            return;
        }

        const fromItems = data;
        const toItems = data;

        moveNode(fromNode, toNode, fromItems, toItems, e.dropInsideItem);
        setData([...toItems]);
    }

    const moveNode = (fromNode, toNode, fromItems, toItems, isDropInsideItem) => {
        const fromIndex = fromItems.findIndex((item) => item.id === fromNode.itemData.id);
        fromItems.splice(fromIndex, 1);//remove dragging node from list.

        //If the drop position is not correct, move node to tail of toItems.
        const toIndex = toNode === null || isDropInsideItem
            ? toItems.length
            : toItems.findIndex((item) => item.id === toNode.itemData.id);
        toItems.splice(toIndex, 0, fromNode.itemData);//insert the dragging node at toIndex.

        moveChildren(fromNode, fromItems, toItems);
        //change parentId
        if (isDropInsideItem) {
            fromNode.itemData.parentId = toNode.itemData.id;
        } else {//make parent node to be root.
            fromNode.itemData.parentId = toNode != null
                ? toNode.itemData.parentId
                : undefined;
        }
    }

    //should be changed by structure of data.
    const moveChildren = (node, fromDataSource, toDataSource) => {
        if (!node.itemData.isDirectory) {
            return;
        }

        node.children.forEach((child) => {
            if (child.itemData.isDirectory) {
                this.moveChildren(child, fromDataSource, toDataSource);
            }

            const fromIndex = fromDataSource.findIndex((item) => item.id === child.itemData.id);
            fromDataSource.splice(fromIndex, 1);
            toDataSource.splice(toDataSource.length, 0, child.itemData);
        });
    }

    //Returning treeView node by index
    const findNode = (ref, index) => {
        const instance = ref.current.instance;
        const nodeElement = instance.element().querySelectorAll('.dx-treeview-node')[index];
        if (nodeElement) {
            //getNodes() -> Return hierarchy of nodes
            return findNodeById(instance.getNodes(), nodeElement.getAttribute('data-item-id'));
        }
        return null;
    }

    const findNodeById = (nodes, id) => {
        for (let i = 0; i < nodes.length; i += 1) {
            if (nodes[i].itemData.id === id) {
                return nodes[i];
            }
            if (nodes[i].children) {
                const node = findNodeById(nodes[i].children, id);
                if (node != null) {
                    return node;
                }
            }
        }
        return null;
    }

    //Sequence of moving nodes is remove fromNode frist and insert in front of toNode.
    //When you reorder the node if the fromNode is preceded toNode you have to increase toIndex +1
    const calculateToIndex = (e) => {
        if (e.fromComponent !== e.toComponent || e.dropInsideItem) {
            return e.toIndex;
        }

        if (!e.dropInsideItem) {
            console.log("outside");
        }

        return e.fromIndex >= e.toIndex
            ? e.toIndex
            : e.toIndex + 1;
    }

    const getTopVisibleNode = (component) => {
        const treeViewElement = component.element();
        const treeViewTopPosition = treeViewElement.getBoundingClientRect().top;
        const nodes = treeViewElement.querySelectorAll('.dx-treeview-node');
        for (let i = 0; i < nodes.length; i += 1) {
            const nodeTopPosition = nodes[i].getBoundingClientRect().top;
            if (nodeTopPosition >= treeViewTopPosition) {
                return nodes[i];
            }
        }

        return null;
    }

    return (
        <div>
            <div>
                <Sortable
                    data="leftSideBar"
                    filter=".dx-treeview-item"
                    allowDropInsideItem={true}
                    allowReordering={true}
                    onDragEnd={onDragEnd}
                    onDragChange={onDragChange}
                >
                    <TreeView
                        dataStructure="plain"
                        ref={treeViewRef}
                        items={data}
                        displayExpr="name"
                    />
                </Sortable>
            </div>
        </div>
    );
}
