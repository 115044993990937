import React, { useRef, useEffect, useState } from 'react';
import "../../contents/styles/dataManagement/data-management.css";
import { Button } from 'devextreme-react/button';
import { useLocation, useNavigate } from 'react-router-dom';
import { getMenuMappingInfo } from '../../apiInterface/utils/Common';


const DatasetManagementRawData = () => {
    const navigate = useNavigate();
    const [title, setTitle] = useState("");
    const location = useLocation();

    useEffect(() => {
        let mappingInfo = getMenuMappingInfo();
        setTitle(mappingInfo.caption);
    }, []);

    return (
        <>
            <div className="data-management dataset-mgt-rawdata">
                <div className="right-content-title">
                    <h3>{title}<span>/</span><span>Raw Data</span></h3>
                    <div>
                        <Button
                            className="btn-s-r dx-button-mode-es"
                            type="default"
                            stylingMode="contained"
                            icon="back"
                        />
                    </div>
                </div>
                <div className="block block-title"></div>
                <div className="right-content-body">
                    <div className="selected-dataset">
                        <div> <span className="badge">Dataset</span><h6>AAS OF Machine0</h6></div>
                        <div>
                            <div>
                                <Button
                                    className="btn-s-s btn-csv-download"
                                    icon="download"
                                    text="CSV Download"
                                    type="normal"
                                    stylingMode="text"
                                />
                            </div>
                            <div className="detail-or-rawdata">
                                <div className="toggle">
                                    <input type="checkbox" id="DetailOrRawdata" defaultChecked={true} onChange={() => setTimeout(() => navigate("/DatasetManagementDetail", { state: location.state }), 200)}></input>
                                    <label htmlFor="DetailOrRawdata"><p>Details</p><p>Raw Data</p></label>
                                    <span className="move"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default DatasetManagementRawData;