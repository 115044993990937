import React, { useEffect, useState } from "react";
import { getUser, getIP, getMenuMappingInfo, getLanguage } from '../../apiInterface/utils/Common';
import { useLocation, useNavigate } from "react-router-dom";
import Button from 'devextreme-react/button';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import useTranslation from "../../components/customHooks/translations";
import { useSwal } from '../../components/common/Swal';
import useErrorHandler from "../../components/customHooks/useErrorHandler";
import { insertItemMaster, updateItemMaster, getItemMasterById, uploadAttachments } from '../../apiInterface/item/ItemMasterAPI';
import { TextboxField, SearchableField, DatePickerField, FileField, DropdownField } from "../../components/common/FormField";
import { useRef } from "react";
import "../../contents/styles/item/item-master-form.css";
import { actionDirect, moveFocus, submit } from "../../components/common/FormFuncWithEnter";
import { PageTitle } from "../../components/common/PageTitle.jsx";

export default function AbnormalSignLogForm() {
    const navigate = useNavigate();
    const translation = useTranslation();
    const location = useLocation();
    const [data, setData] = useState({});

    return (
        <div className="item-master-form">
            <div className="right-content-title">
                <PageTitle pageState={location.state.title} />
            </div>
            <form>
                <div className="right-content-body">
                    <div className="container">
                        <div className="dx-fieldset">

                            <DropdownField
                                label="Dataset"
                            />
                            <DropdownField
                                label="Feature"
                            />
                            <TextboxField
                                label="USL"
                            />
                            <TextboxField
                                label="LSL"
                            />
                            <DropdownField
                                label="Status"
                            />
                            <TextboxField
                                label="Reason"
                            />
                            <TextboxField
                                label="Recommend"
                            />
                        </div>
                        <div className="block"></div>
                        {location.state.title == "Edit" &&
                            <>
                                <div className="dx-fieldset write-info">
                                    <div className="dx-field">
                                        <div className="dx-field-label">{translation.lastModifiedDate}</div>
                                        <div className="dx-field-value">{data?.modified_date}</div>
                                    </div>
                                    <div className="dx-field">
                                        <div className="dx-field-label">{translation.lastModifiedBy}</div>
                                        <div className="dx-field-value">{data?.modified_by_name}
                                            {
                                                data?.modified_by_duty &&
                                                <span className="badge badge-job-position">{data?.modified_by_duty}</span>
                                            }
                                            {
                                                data?.modified_by_department &&
                                                <span className="badge badge-team">{data?.modified_by_department}</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="block"></div>
                            </>
                        }
                        <div>
                            <div className="btn-group dx-fieldset" style={{ width: "608px" }}>
                                <Button
                                    type="normal"
                                    stylingMode="text"
                                    className="btn-s-r"
                                >
                                    <div>
                                        <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                        <span className="dx-button-text">{translation.cancel}</span>
                                    </div>
                                </Button>
                                <Button
                                    id="submitBtn"
                                    type="default"
                                    stylingMode="contained"
                                    className="btn-s-r"
                                    useSubmitBehavior={true}
                                >
                                    <div>
                                        <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                        <span className="dx-button-text">{translation.save}</span>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}
