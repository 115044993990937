// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item-category .right-content-body {
    height: calc(100% - 44px);
    min-height: calc(100% - 44px);
}
.popup-item-category .dx-fieldset {
    display: flex;
    gap: 20px;
}
.popup-item-category .dx-fieldset .dx-field{
    margin-bottom: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/contents/styles/item/item-category-form.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,6BAA6B;AACjC;AACA;IACI,aAAa;IACb,SAAS;AACb;AACA;IACI,kBAAkB;AACtB","sourcesContent":[".item-category .right-content-body {\n    height: calc(100% - 44px);\n    min-height: calc(100% - 44px);\n}\n.popup-item-category .dx-fieldset {\n    display: flex;\n    gap: 20px;\n}\n.popup-item-category .dx-fieldset .dx-field{\n    margin-bottom: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
