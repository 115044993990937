import { ScrollView } from 'devextreme-react';
import Button from 'devextreme-react/button';
import Popup from 'devextreme-react/popup';
import CustomStore from 'devextreme/data/custom_store';
import { useEffect } from 'react';
import { useRef, useState } from 'react';
import { getMainDatasetInUse } from '../../apiInterface/aasFile/DatasetManagementAPI';
import { getLanguage, getUser } from '../../apiInterface/utils/Common';
import BaseDataGrid from '../../components/common/BaseDataGrid';
import { FilterComponent } from '../../components/common/FormField';
import useTranslation from '../../components/customHooks/translations';
import useErrorHandler from '../../components/customHooks/useErrorHandler';

export default function SelectRawDatasetPopup(props) {
    const { visible, onSelected = () => {}, onOk = () => {}, onClose = () => {}, visibleState, selectedRawData = [] } = props;

    const [selectedRows, setSelectedRows] = useState([]);
    const [gridData, setGridData] = useState([]);
    const [okBtnDisabled, setOkBtnDisabled] = useState(true);
    const translation = useTranslation();
    const errorHandler = useErrorHandler();
    const searchText = useRef('');
    const gridRef = useRef();

    const init = () => {
        const store = new CustomStore({
            load: async (loadOptions) => load(loadOptions),
        });
        setGridData(store);
    };

    const load = async (loadOptions) => {
        const user = getUser();
        const lang = getLanguage();
        let skip = 0;
        let take = 10;
        if (loadOptions['skip']) skip = loadOptions['skip'];
        if (loadOptions['take']) take = loadOptions['take'];
        const pageNo = parseInt(skip / take) + 1;
        const param = {
            user_company_id: user.user_company_id,
            sys_lang_code: lang,
            row_count: take,
            page_no: pageNo,
            search_text: searchText.current,
            is_use: true,
        };

        const res = await errorHandler(getMainDatasetInUse, param);
        if (res) {
            const data = res.data.o_data;
            // const find = data.filter((f) => f?.dataset_master_id === selectedRawData?.[0]?.dataset_master_id);
            // setSelectedRows(find);

            return {
                data: data,
                totalCount: res.data.count,
            };
        }
        return { data: [], totalCount: 0 };
    };

    // useEffect(() => {
    //     const instance = gridRef.current?.getInstance();
    //     instance?.selectRows(selectedRows);
    // }, [selectedRows]);

    useEffect(() => {
        if (visible) {
            const instance = gridRef.current?.getInstance();
            instance?.selectRows(selectedRawData);
            // init();
        }
    }, [visible]);

    const onRowSelected = (rows) => {
        onSelected(rows);
        if (rows == null) {
            setOkBtnDisabled(true);
            setSelectedRows([]);
        } else {
            setSelectedRows([rows]);
            setOkBtnDisabled(false);
        }
    };

    const ok = () => {
        onOk(selectedRows);

        close();
    };

    const close = (e) => {
        onClose();
    };

    const removeTag = (i) => {
        let cur = [...selectedRows];
        let removed = cur.splice(i, 1);
        setSelectedRows(cur);

        const instance = gridRef.current.getInstance();
        instance.deselectRows(removed);
    };

    const removeAllTags = () => {
        setSelectedRows([]);

        const instance = gridRef.current.getInstance();
        instance.deselectAll();
    };

    const filtering = (keyword) => {
        searchText.current = keyword;
        init();
    };

    const filter = <FilterComponent label={translation.filter} width={204} onFilter={filtering} />;

    return (
        <Popup width={1100} height={850} visible={visible} hideOnOutsideClick={true} onHiding={close} onInitialized={init} dragEnabled={true} position="center" showTitle={false}>
            <div className="popup-select-raw-dataset">
                <div className="popup-header">
                    <p className="popup-title">{translation.select_raw_dataset}</p>
                </div>
                <div className="popup-body">
                    <div className="selected-item-viewer">
                        <div className="icon-checked">
                            <i></i>
                        </div>
                        <div className="selected-item-field">
                            {selectedRows.length > 0 && (
                                <>
                                    <ul className="selected-items">
                                        {selectedRows.map((e, i) => (
                                            <li key={i} className="item">
                                                <p className="item-name">{e.dataset_master_name}</p>
                                                <i className="material-icons btn-delete" onClick={() => removeTag(i)}>
                                                    cancel
                                                </i>
                                            </li>
                                        ))}
                                    </ul>
                                    <div className="btn-all-delete" onClick={removeAllTags}>
                                        <button>{translation.delete_all}</button>
                                    </div>
                                </>
                            )}
                            {selectedRows.length == 0 && <p className="comment-not-selected">{translation.select_raw_dataset_notice}</p>}
                        </div>
                    </div>
                    <div className="item-select-box">
                        <ScrollView width="100%">
                            <div className="container-wrapper">
                                <div className="container">
                                    <BaseDataGrid ref={gridRef} toolbarModules={[filter]} dataSource={gridData} allowModify={false} onSelectionChanged={onRowSelected} selectMode="single" isRemote={true} />
                                </div>
                            </div>
                        </ScrollView>
                    </div>
                </div>
                <div className="popup-footer">
                    <Button width={86} text={translation.cancel} type="normal" stylingMode="text" className="btn-s-r" onClick={close} />
                    <Button text={translation.confirm} width={86} type="default" stylingMode="contained" className="btn-s-r" onClick={ok} disabled={okBtnDisabled} />
                </div>
            </div>
        </Popup>
    );
}
