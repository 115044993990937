import { Button, Popup, ScrollView } from 'devextreme-react';
import CustomStore from 'devextreme/data/custom_store';
import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { useImperativeHandle } from 'react';
import { forwardRef } from 'react';
import { getMainAasMaster } from '../../apiInterface/aasFile/DatasetManagementAPI';
import { getSysDivisionCaptionByCode } from '../../apiInterface/menu/MenuAPI';
import { getLanguage, getUser } from '../../apiInterface/utils/Common';
import Badge from '../../components/common/Badge';
import BaseDataGrid from '../../components/common/BaseDataGrid';
import { DropdownField, FilterComponent } from '../../components/common/FormField';
import useTranslation from '../../components/customHooks/translations';
import useErrorHandler from '../../components/customHooks/useErrorHandler';


const AASTYPE = "AASTYPE";
let keyword = "";
export default function AddDataPopup(props, ref) {
    const {
        onSave: onSaveCallback = () => { },
    } = props;

    const translation = useTranslation();
    const errorHandler = useErrorHandler();

    const [selectedNode, setSelectedNode] = useState([]);
    const [dropdown, setDropdown] = useState([]);
    const [curDropdown, setCurDropdown] = useState(null);
    const [gridData, setGridData] = useState(null);
    const [btnDisable, setBtnDisable] = useState(true);
    const [visibleState, setVisibleState] = useState(false);
    const gridRef = useRef();

    const header = [
        { dataField: "aas_master_type", caption: "AAS Type", dataType: "string", width: 100, alignment: "center", cellRender: (e) => { return <Badge className="grid-badge-use" text={e.text} /> }, allowReordering: true },
        { dataField: "aas_master_id_short", caption: "NAME", dataType: "string", allowReordering: true },
        { dataField: "aas_master_identification", caption: "IDENTIFICATION", dataType: "string", allowReordering: true },
    ]

    useImperativeHandle(ref, () => ({
        open: open,
        close: close,
    }));

    const open = () => {
        setVisibleState(true);
    }

    const close = () => {
        setVisibleState(false);
        deselectAll();
    }

    const init = () => {
        getDropdownValues();
        keyword = "";
    }

    const deselectAll = () => {
        const instance = gridRef.current.getInstance();
        instance.deselectAll();
        setSelectedNode([]);
        resetDropdown();
        setBtnDisable(true);
    }

    const resetDropdown = () => {
        setCurDropdown(null);
    }

    const getDropdownValues = async () => {
        let param = {
            user_company_id: getUser().user_company_id,
            sys_division_code: AASTYPE,
            sys_division_sub_lang_code: getLanguage(),
        }

        const res = await errorHandler(getSysDivisionCaptionByCode, param);
        if (res) {
            setDropdown(res.data.o_data);
            setCurDropdown(res.data.o_data[0].sys_division_sub_value);
        }
    };

    const dropdownChanged = (e) => {
        makeCustomStore(e.value);
        setCurDropdown(e.value);
    }

    const makeCustomStore = (aasType) => {
        const store = new CustomStore({
            load: async (loadOptions) => {
                return load(loadOptions, aasType);
            }
        });
        setGridData(store);
    }

    const load = async (loadOptions, aasType) => {
        const language = getLanguage();
        const user = getUser();
        const pageNo = parseInt(loadOptions["skip"] / loadOptions["take"]) + 1;
        if (loadOptions["skip"] == null || aasType == null) {
            return {
                data: [],
                totalCount: 0,
            }
        }

        let result = { data: [], totalCount: 0 };
        const param = {
            user_company_id: user.user_company_id,
            sys_lang_code: language,
            aas_type: aasType,
            row_count: loadOptions["take"],
            page_no: pageNo,
            search_text: keyword,
        };

        const res = await errorHandler(getMainAasMaster, param);
        if (res) {
            const data = res.data.o_data;
            result.data = data;
            result.totalCount = res.data.count;
        }

        return result;
    }

    const onFilter = (value) => {
        keyword = value;
        makeCustomStore(curDropdown);
    }

    const onCancel = (e) => {
        close();
    }

    const onSave = (e) => {
        onSaveCallback([selectedNode]);
        close();
    }

    const dropdownm = <DropdownField
        label="AAS Type"
        dataSource={dropdown}
        value={curDropdown}
        displayExpr="sys_division_sub_caption_text"
        valueExpr="sys_division_sub_value"
        onValueChanged={dropdownChanged}
        isSearchable={false}
    />

    const filter = <FilterComponent
        label="Filter"
        onFilter={onFilter}
    />

    const removeTag = () => {
        setSelectedNode([]);

        const instance = gridRef.current.getInstance();
        instance.deselectAll();
    }


    return (
        <Popup
            width={1100}
            height={850}
            visible={visibleState}
            hideOnOutsideClick={true}
            onHiding={close}
            onInitialized={init}
            dragEnabled={true}
            position="center"
            showTitle={false}
        >
            <div className="popup-select-aas">
                <div className="popup-header">
                    <p className="popup-title">{translation.select_aas}</p>
                </div>
                <div className="popup-body">
                    <div className="selected-item-viewer">
                        <div className="icon-checked">
                            <i></i>
                        </div>
                        <div className="selected-item-field">
                            {selectedNode?.length != 0 &&
                                <>
                                    <ul className="selected-items">
                                        <li className="item">
                                            <p className="item-name">{selectedNode?.aas_master_id_short}</p>
                                            <i className="material-icons btn-delete" onClick={removeTag}>cancel</i>
                                        </li>
                                    </ul>
                                    <div className="btn-all-delete" onClick={removeTag}>
                                        <button>Delete All</button>
                                    </div>
                                </>
                            }
                            {selectedNode?.length == 0 &&
                                <p className="comment-not-selected">{translation.select_aas_notice}</p>
                            }
                        </div>
                    </div>
                    {/*<div className="selected-item">*/}
                    {/*    <span>{translation.selected}: </span>*/}
                    {/*    <span>{*/}
                    {/*        selectedNode == null ? "" :*/}
                    {/*            selectedNode?.aas_master_id_short*/}
                    {/*    }</span>*/}
                    {/*</div>*/}
                    <div className="item-select-box">
                        <ScrollView>
                            <div className="container-wrapper">
                                <div className="container">
                                    <BaseDataGrid
                                        toolbarModules={[dropdownm, filter]}
                                        dataSource={gridData}
                                        onSelectionChanged={(val) => {
                                            if (val != null) {
                                                setSelectedNode(val);
                                                setBtnDisable(false);
                                            }
                                            else {
                                                setSelectedNode([]);
                                                setBtnDisable(true);
                                            }
                                        }}
                                        selectMode="single"
                                        allowModify={false}
                                        isRemote={true}
                                        ref={gridRef}
                                        headers={header}
                                    />
                                </div>
                            </div>
                        </ScrollView>
                    </div>
                </div>
                <div className="popup-footer">
                    <Button
                        width={86}
                        text={translation.cancel}
                        type="normal"
                        stylingMode="text"
                        className="btn-s-r"
                        onClick={onCancel}
                    />
                    <Button
                        text={translation.confirm}
                        width={86}
                        type="default"
                        stylingMode="contained"
                        className="btn-s-r"
                        onClick={onSave}
                        disabled={btnDisable}
                    />
                </div>
            </div>
        </Popup>
    );
}

AddDataPopup = forwardRef(AddDataPopup);