export const actionDirect = async (e) => {
    event.preventDefault();
    return false;
};

export const moveFocus = (e) => {
    let focusIdx = parseInt(event.currentTarget.getAttribute('focusidx'));
    let nextInput;

    do {
        focusIdx++;
        nextInput = document.querySelector(`[focusidx='${focusIdx}']`);
    } while (nextInput?.disabled);

    /**
     * ina
     */
    // if (nextInput === null) {
    // } else if (nextInput.closest('.dx-selectbox-container') || nextInput.classList.contains('dx-button') || nextInput.classList.contains('dx-fileuploader-input') || nextInput.closest('.dx-datebox-calendar')) {
    //     nextInput.click();
    // } else {
    //     nextInput.focus();
    // }

    /**
     * hyeonju
     * enter => valid check -> true -> next input
     */
    if (event.currentTarget.getAttribute('aria-invalid')) {
        console.log('insert value~~');
    } else {
        if (event.currentTarget.getAttribute('aria-expanded') == 'true') {
            if (document.getElementsByClassName('dx-swatch-ix-pds').length !== 0) {
                document.getElementsByClassName('dx-swatch-ix-pds')[document.getElementsByClassName('dx-swatch-ix-pds').length - 1].replaceChildren();
            }
        }

        if (nextInput) {
            if (nextInput.closest('.dx-selectbox-container') || nextInput.classList.contains('dx-button') || nextInput.classList.contains('dx-fileuploader-input') || nextInput.closest('.dx-datebox-calendar')) {
                nextInput.click();
            } else {
                nextInput.focus();
            }
        }
    }
};

export const submit = (e, submitFunc) => {
    const checkValid = e.validationGroup.validate().isValid;
    if (!checkValid) {
        // focus
        const brokenGroup = e.validationGroup.validate().brokenRules;
        brokenGroup[0]?.validator?._$element[0]?.childNodes[1]?.childNodes[0]?.childNodes[0].focus();
        return false;
    }

    submitFunc();

    // if (checkValid) submitFunc();
};
