import React, { useState, useEffect, useImperativeHandle } from 'react';
import useTranslation from '../../components/customHooks/translations';
import Button from 'devextreme-react/button';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { useLocation, useNavigate } from 'react-router-dom';
import { getIP, getLanguage, getMenuMappingInfo, getUser } from '../../apiInterface/utils/Common';
import { BaseField, DropdownField, TextboxField } from '../../components/common/FormField';
import TreeListPath from '../../components/common/TreeListPath';
import { getMenuMappingById, insertMenuMapping, updateMenuMapping, insertMenuMappingCaption, insertMenuMappingSetting, deleteMenuMapping } from '../../apiInterface/menu/MenuAPI';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import SecurityGrid from '../item/SecurityGrid';
import CaptionEditor from '../../components/common/CaptionEditor';
import { useSwal } from '../../components/common/Swal';
import { useRef } from 'react';
import { PageTitle } from '../../components/common/PageTitle.jsx';
import TextArea from 'devextreme-react/text-area';

const MenuForm = () => {
    const translation = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const errorHandler = useErrorHandler();
    const lang = getLanguage();
    const user = getUser();
    //const [subtitle, setSubtitle] = useState("");
    //const [addProgress, setAddProgress] = useState(false);
    const [captions, setCaptions] = useState();
    const { success } = useSwal();
    const captionRef = useRef();

    const useItems = [
        { value: true, title: translation.use },
        { value: false, title: translation.notUse },
    ];

    const [data, setData] = useState({
        caption: [],
        security: [],
    });
    const { title, path, itemId, serviceId, menu = null } = location.state;
    const [isTemplate, setIsTemplate] = useState();

    const valueChanged = (value, key) => {
        setData((cur) => {
            let newData = { ...cur };
            newData[key] = value;
            return newData;
        });
    };

    //init
    useEffect(() => {
        setIsTemplate(getIsTemplate());
        setData({
            ...data,
            path: path,
            menu_mapping_parent_id: itemId ? itemId : serviceId,
            title: title,
            seq: data?.seq != null ? data.seq : 1,
        });

        if (title == 'Edit') {
            loadMenuInfo();
        } else {
            setData((cur) => {
                let copy = { ...cur };
                copy = {
                    menu_mapping_parent_id: itemId === 0 ? serviceId : itemId,
                    menu_mapping_is_active: useItems[0].value,
                    ...copy,
                };
                return copy;
            });
        }
    }, []);

    const loadMenuInfo = async () => {
        let param = {
            menu_mapping_id: itemId,
            sys_lang_code: lang,
        };

        const res = await errorHandler(getMenuMappingById, param);
        if (res) {
            let temp = res.data.o_data[0];
            temp.caption = JSON.parse(temp.caption_str);

            let caption = {};
            if (temp.caption != null) {
                temp.caption.forEach((e) => {
                    caption[e.menu_mapping_lang_code] = e.menu_mapping_caption_text;
                });
                setCaptions(caption);
            }

            temp.security = JSON.parse(temp.security_str);
            if (temp.security == null) temp.security = [];
            else {
                for (let i = 0; i < temp.security.length; i++) {
                    let group = {
                        security_master_id: temp.security[i].target_id,
                        security_master_name: temp.security[i].target_name,
                        user_business_name: temp.security[i].user_business_name,
                        no: temp.security[i].target_no,
                        create: temp.security[i].security_value?.includes('C'),
                        update: temp.security[i].security_value?.includes('U'),
                        delete: temp.security[i].security_value?.includes('D'),
                    };

                    temp.security[i] = addPropagationOption(group);
                }
            }

            setData((cur) => ({ ...cur, ...temp }));
            setIsTemplate(res.data.o_data[0]?.user_defined);
        }
    };

    const addSecurityGroup = (list) => {
        let copy = [...data?.security];
        list.map((e) => {
            let temp = addPropagationOption(e);
            temp = addSecurityValue(temp);
            return temp;
        });
        const unique = list.filter((e) => {
            for (let i = 0; i < copy.length; i++) {
                if (copy[i].security_master_id == e.security_master_id) return false;
            }
            return true;
        });

        copy.push(...unique);
        valueChanged(copy, 'security');
    };

    const deleteSecurityGroup = (list) => {
        let copy = [...data?.security];
        list.forEach((e) => {
            const index = copy.findIndex((target) => e.security_master_id == target.security_master_id);
            copy.splice(index, 1);
        });
        valueChanged(copy, 'security');
    };

    const addPropagationOption = (rowData, toParent, toChildren) => {
        rowData.propagateToParent = toParent ? true : false;
        rowData.propagateToChildren = toChildren ? true : false;
        return rowData;
    };

    const addSecurityValue = (rowData, create, update, del) => {
        rowData.create = create ? true : false;
        rowData.update = update ? true : false;
        rowData.delete = del ? true : false;
        return rowData;
    };

    const submit = (e) => {
        e.preventDefault();

        if (title === 'Add' && !isTemplate) insertMenu();
        else if (title === 'Add' && isTemplate) navigate('/MenuSelectTemplate', { state: { data, title, path, serviceId } });
        else if (title === 'Edit' && !isTemplate) updateMenu();
        else if (title === 'Edit' && isTemplate) {
            data.seq = 2;
            const selectedTemplate = { id: location.state.menu.template_master_id, name: location.state.menu.template_master_name };
            navigate('/MenuBindingData', { state: { data, title, path, menu, selectedTemplate, serviceId } });
        }
    };

    const getIsTemplate = () => {
        if (title == 'Add') {
            return path.length == 2;
        } else {
            return path.length == 3;
        }
    };

    const onGridUpdate = () => {
        let captions = captionRef.current?.getCaptions();
        valueChanged(captions, 'caption');
    };

    const insertMenu = async () => {
        const { id: mappingId } = await getMenuMappingInfo();
        let param = {
            menu_mapping_comment: data.menu_mapping_comment,
            menu_mapping_filter_json: null,
            menu_mapping_id: mappingId,
            menu_mapping_is_active: data.menu_mapping_is_active,
            menu_mapping_layout_json: null,
            menu_mapping_name: data.menu_mapping_name,
            menu_mapping_parent_id: data.menu_mapping_parent_id,
            template_master_id: data.menu_mapping_parent_id,
            template_master_is_active: null,
            user_company_id: user.user_company_id,
            user_log_ip: getIP(),
            user_master_id: user.user_master_id,
        };

        const res = await errorHandler(insertMenuMapping, param);
        if (res) {
            const menuIp = res.data.o_data[0].menu_mapping_id;
            await insertMenuLang(menuIp);
            await insertMenuSecurity(menuIp);
            const text = `<p>${translation.success_insert}</p>`;
            success(text, exitPage);
        }
    };

    const updateMenu = async () => {
        const { id: mappingId } = await getMenuMappingInfo();
        let body = {
            menu_mapping_comment: data.menu_mapping_comment,
            menu_mapping_filter_json: JSON.stringify(data.menu_custom_filter_json),
            menu_mapping_layout_json: JSON.stringify(data.menu_custom_layout_json),
            menu_mapping_id: mappingId,
            menu_mapping_is_active: data.menu_mapping_is_active,
            menu_mapping_name: data.menu_mapping_name,
            target_menu_mapping_id: data.menu_mapping_id,
            template_master_id: data.template_master_id,
            user_log_ip: getIP(),
            user_master_id: user.user_master_id,
        };

        const res = await errorHandler(updateMenuMapping, body);
        if (res) {
            await insertMenuLang();
            await insertMenuSecurity();
            const text = `<p>${translation.success_update}</p>`;
            success(text, exitPage);
        }
    };

    const insertMenuLang = async (mapId) => {
        const { getCaptions } = captionRef.current;
        let captions = getCaptions();
        for (let i = 0; i < captions.length; i++) {
            let body = {
                user_master_id: user.user_master_id,
                target_menu_mapping_id: mapId ? mapId : data.menu_mapping_id,
                menu_mapping_lang_code: captions[i].langCode,
                menu_mapping_caption_text: captions[i].caption,
            };

            let res = await errorHandler(insertMenuMappingCaption, body);
            if (res) {
                //if success do something
            }
        }
    };

    const insertMenuSecurity = async (mapId) => {
        if (data?.security == null) return;
        let queue = [];
        let securityValue = '';

        for (let i = 0; i < data.security.length; i++) {
            securityValue = '';
            securityValue += data.security[i].create ? 'C' : '';
            securityValue += data.security[i].update ? 'U' : '';
            securityValue += data.security[i].delete ? 'D' : '';
            let body = {
                user_master_id: user.user_master_id,
                security_value: securityValue,
                target_id: data.security[i].security_master_id,
                parent_copy: data.security[i].propagateToParent,
                child_copy: data.security[i].propagateToChildren,
                target_menu_mapping_id: mapId ? mapId : data.menu_mapping_id,
            };

            const promise = errorHandler(insertMenuMappingSetting, body);
            queue.push(promise);
        }

        await Promise.all(queue).then((val) => {
            console.log(val);
        });
    };

    const exitPage = () => {
        let id = serviceId;
        navigate('/Menu', {
            state: { serviceId: id },
        });
    };

    return (
        <div className="menu-form">
            <div className="right-content-title">
                <div className="page-title">
                    <PageTitle pageState={title} />
                </div>
                {/*{*/}
                {/*    addProgress &&*/}
                {/*    <div className="page-number"><span className="current-num">1</span>/<span className="total-num">3</span></div>*/}
                {/*}*/}
            </div>
            <form onSubmit={submit}>
                <div className="right-content-body">
                    <div className="container">
                        <div className="dx-fieldset">
                            <BaseField label={translation.menu_path} component={<TreeListPath path={path} />} />

                            <TextboxField name="menu_mapping_name" value={data?.menu_mapping_name} onValueChanged={(e) => valueChanged(e.value, 'menu_mapping_name')} label={translation.menu_mapping_name} isEssential={true} essentialMsg={translation.menu_mapping_name + translation.is_required} />

                            <DropdownField
                                name="menu_mapping_is_active"
                                label={translation.use_or_not}
                                dataSource={useItems}
                                valueExpr="value"
                                displayExpr="title"
                                value={data?.menu_mapping_is_active}
                                onValueChanged={(e) => valueChanged(e.value, 'menu_mapping_is_active')}
                                isEssential={true}
                                essentialMsg={translation.use_or_not + translation.is_required}
                                isSearchable={false}
                            />

                            {/*<TextboxField*/}
                            {/*    label={translation.comment}*/}
                            {/*    value={data?.menu_mapping_comment}*/}
                            {/*    onValueChanged={(e) => valueChanged(e.value, "menu_mapping_comment")}*/}
                            {/*/>*/}

                            <div className="dx-field">
                                <div className="dx-field-label">
                                    <p>{translation.comment}</p>
                                </div>
                                <div className="dx-field-value">
                                    <TextArea value={data?.menu_mapping_comment} onValueChanged={(e) => valueChanged(e.value, 'menu_mapping_comment')} />
                                </div>
                            </div>
                        </div>
                        <div className="block"></div>
                        <div className="dx-fieldset language">
                            <div className="dx-field column">
                                <div className="dx-field-label">
                                    <p>{translation.language}</p>
                                </div>
                                <div className="dx-field-value //data-grid caption-editor">
                                    <CaptionEditor value={captions} ref={captionRef} onRowUpdated={onGridUpdate} />
                                    <p className="info-comment">
                                        <span className="material-icons-outlined">info</span>
                                        {translation.info_menu_lang}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="block"></div>
                        <div style={{ width: '1268px' }}>
                            <BaseField
                                label={translation.security_group}
                                isColumn={true}
                                component={
                                    <>
                                        <SecurityGrid data={data?.security} addData={addSecurityGroup} deleteData={deleteSecurityGroup} isSetSecurityValue={true} />
                                        <p className="info-comment">
                                            <span className="material-icons-outlined">info</span>
                                            {translation.menu_security_notice}
                                        </p>
                                    </>
                                }
                            />
                        </div>
                        <div className="block"></div>
                        {title === 'Edit' && (
                            <>
                                <div className="dx-fieldset write-info">
                                    <div className="dx-field">
                                        <div className="dx-field-label">{translation.lastModifiedDate}</div>
                                        <div className="dx-field-value">{data?.modified_date}</div>
                                    </div>
                                    <div className="dx-field">
                                        <div className="dx-field-label">{translation.lastModifiedBy}</div>
                                        <div className="dx-field-value">
                                            {data?.modified_by_name}
                                            {data?.modified_by_duty && <span className="badge badge-job-position">{data?.modified_by_duty}</span>}
                                            {data?.modified_by_department && <span className="badge badge-team">{data?.modified_by_department}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="block"></div>
                            </>
                        )}
                        <div className="btn-group" style={{ width: '1268px' }}>
                            <Button type="normal" stylingMode="text" className="btn-s-r" onClick={() => exitPage()}>
                                <div>
                                    <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                    <span className="dx-button-text">{translation.cancel}</span>
                                </div>
                            </Button>
                            <Button type="default" stylingMode="contained" className="btn-s-r" useSubmitBehavior={true}>
                                <div>
                                    <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                    <span className="dx-button-text">{isTemplate ? translation.next : translation.save}</span>
                                </div>
                            </Button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};
export default MenuForm;
