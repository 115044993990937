import React, { useEffect, useState } from 'react';
import useTranslation from '../../components/customHooks/translations';
import { BaseField, TextboxField, DropdownField } from '../../components/common/FormField';
import TreeListPath from '../../components/common/TreeListPath';
import { Button, Popup, ValidationGroup } from 'devextreme-react';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import { getTemplateCategoryById, putTemplateCategory, postTemplateCategory } from '../../apiInterface/templateManagment/TemplateManagementAPI';
import { getIP, getLanguage, getMenuMappingInfo, getUser } from '../../apiInterface/utils/Common';
import { useRef } from 'react';
import { successToastr } from '../../components/common/Toastr';
import { useSwal } from '../../components/common/Swal';


export default function TemplatemasterEditPopup(props) {
    const {
        subtitle = 'add', // indicate this popup is edit or add
        path = [], // hiararchy path from root to selected node
        onExit = () => { }, //callback event for exit
        itemId,
        visible = true,
        onHiding = () => { },
        dragEnabled = true,
    } = props;

    const { fail } = useSwal();
    const validRef = useRef();
    const errorHandler = useErrorHandler();
    const translation = useTranslation();
    const dropdownItems = [
        { value: true, name: translation.use },
        { value: false, name: translation.notUse }
    ];
    const lang = getLanguage();
    const [data, setData] = useState({ template_category_is_active: true });
    const [hideOnOutsideClick, setHideOnOutsideClick] = useState(true);
    const user = getUser();
    const ip = getIP();

    const getById = async () => {
        let param = {
            template_category_id: itemId,
            sys_lang_code: lang,
        }

        const res = await errorHandler(getTemplateCategoryById, param);
        if (res) {
            if (res.data.o_data.length > 0) {
                setData((cur) => {
                    let copy = { ...cur, ...res.data.o_data[0] };
                    return copy;
                });
            }
            else
                setData(null);
        }
    }

    const changeValue = (value, key) => {
        setData((cur) => {
            let copy = { ...cur };
            copy[key] = value;
            return copy;
        })
    }

    const onSubmit = async (e) => {
        setHideOnOutsideClick(false);
        e.preventDefault();
        let result = false;
        if (subtitle.toLowerCase() === 'add') {
            result = await insert();
        } else if (subtitle.toLowerCase() === 'edit') {
            result = await update();
        }
        if (result) {
            onExit("save");
            successToastr(translation.save_successfully);
            setHideOnOutsideClick(true);
        }
    }

    const insert = async () => {
        const { id: mappingId } = getMenuMappingInfo();
        let param = {
            user_master_id: user.user_master_id,
            user_log_ip: ip,
            menu_mapping_id: mappingId,
            user_company_id: user.user_company_id,
            template_category_parent_id: data.template_category_parent_id,
            template_category_name: data.template_category_name,
            template_category_is_active: data.template_category_is_active,
            template_category_comment: data.template_category_comment
        }

        var swalResFn = (response) => {
            setHideOnOutsideClick(true);
        };

        const res = await postTemplateCategory(param);``
        if (res !== 'fail' && res.message === "MSG_100") {
            return true;
        } else if (res !== 'fail') {
            fail(`<p>${translation[res.message]}</p>`, swalResFn);
            return false;
        } else {
            fail(`<p>${translation.MSG_103}</p>`, swalResFn);
            return false;
        }


        // const res = await errorHandler(postTemplateCategory, param);
        // if (res) {
        //     return true;
        // }
        // return false;
    }

    const update = async () => {
        const { id: mappingId } = getMenuMappingInfo();
        let param = {
            user_master_id: user.user_master_id,
            user_log_ip: ip,
            menu_mapping_id: mappingId,
            user_company_id: user.user_company_id,
            template_category_id: data.template_category_id,
            template_category_name: data.template_category_name,
            template_category_is_active: data.template_category_is_active,
            template_category_comment: data.template_category_comment
        }

        const res = await errorHandler(putTemplateCategory, param);
        if (res) {
            return true;
        }
        return false;
    }

    const onShowing = () => {
        const instance = validRef.current.instance;
        instance.reset();

        if (subtitle.toLowerCase() == 'edit') {
            setData({ template_category_id: itemId });
            getById();
        } else {
            setTimeout(() => {
                setData({
                    template_category_parent_id: itemId == null ? 0 : itemId,
                    template_category_is_active: dropdownItems[0].value,
                });
            }, 5)
        }
    }

    const onHidden = () => {
        setData(null);
    }

    return (
        <Popup
            width={520}
            height="auto"
            visible={visible}
            hideOnOutsideClick={hideOnOutsideClick}
            onHiding={onHiding}
            dragEnabled={dragEnabled}
            position="center"
            showTitle={false}
            onShowing={onShowing}
            onHidden={onHidden}
        >
            <div className="popup-header">
                <p className="popup-title">{translation.template_category} / {translation[subtitle.toLowerCase()]}</p>
            </div>
            <form onSubmit={onSubmit}>
                <ValidationGroup ref={validRef}>
                    <div className="popup-body">
                        <div className="dx-fieldset">
                            <BaseField
                                label={translation.template_path}
                                isColumn={true}
                                component={
                                    <>
                                        <TreeListPath path={path} />
                                        {
                                            path.length > 0 && subtitle == "Add" ?
                                                <p className="info-comment"><span className="material-icons-outlined">info</span>{path[path.length - 1]} {translation.template_info_add}</p> :
                                                ""
                                        }
                                    </>
                                }
                            />

                            <TextboxField
                                label={translation.template_category}
                                isEssential={true}
                                essentialMsg={translation.template_category + translation.is_required}
                                isColumn={true}
                                value={data?.template_category_name}
                                onValueChanged={(e) => changeValue(e.value, "template_category_name")}
                            />

                            <DropdownField
                                dataSource={dropdownItems}
                                displayExpr="name"
                                valueExpr="value"
                                isColumn={true}
                                label={translation.use_or_not}
                                isEssential={true}
                                essentialMsg={translation.template_category + translation.is_required}
                                value={data?.template_category_is_active}
                                onValueChanged={(e) => changeValue(e.value, "template_category_is_active")}
                                isSearchable={false}
                            />

                            <TextboxField
                                label={translation.comment}
                                isColumn={true}
                                value={data?.template_category_comment}
                                onValueChanged={(e) => changeValue(e.value, "template_category_comment")}
                            />

                        </div>
                        {subtitle == "Edit" &&
                            <div className="dx-fieldset write-info">
                                <div className="dx-field">
                                    <div className="dx-field-label">{translation.lastModifiedDate}</div>
                                    <div className="dx-field-value">{data?.modified_date ? data.modified_date : ""}</div>
                                </div>
                                <div className="dx-field">
                                    <div className="dx-field-label">{translation.lastModifiedBy}</div>
                                    <div className="dx-field-value">
                                        {data?.modified_by_name ? data.modified_by_name : ""}
                                        {data?.modified_by_duty && <span className="badge badge-job-position">{data.modified_by_duty}</span>}
                                        {data?.modified_by_department && <span className="badge badge-team">{data.modified_by_department}</span>}
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="popup-footer">
                        <Button
                            width={86}
                            text={translation.cancel}
                            type="normal"
                            stylingMode="text"
                            className="btn-s-r"
                            onClick={(e) => onExit("close")}
                        />
                        <Button
                            text={translation.confirm}
                            width={86}
                            type="default"
                            stylingMode="contained"
                            className="btn-s-r"
                            useSubmitBehavior={true}
                        />
                    </div>
                </ValidationGroup>
            </form>
        </Popup>
    )
}