import React, { useEffect, useRef, useState } from 'react';
import Button from 'devextreme-react/button';
import useTranslation from '../../components/customHooks/translations';
import DataGrid, { Column, Editing, Paging, Lookup, RequiredRule, Toolbar, Item, Pager, Selection, Texts } from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react/popup';
import { getUser, getIP } from '../../apiInterface/utils/Common';
import { getSecurityMaster } from '../../apiInterface/item/ItemCategoryAPI';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import BaseDataGrid from '../../components/common/BaseDataGrid';
import { DropdownField, FilterComponent } from '../../components/common/FormField';
import '../../contents/styles/item/item-category-form.css';
import { getSecurityBusinessByCompanyId } from '../../apiInterface/securityManagement/SecurityGroupAPI';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';
import ScrollView from 'devextreme-react/scroll-view';

export default function SecuritySearchPopup(props, ref) {
    const { onOk = () => {} } = props;
    const translation = useTranslation();
    const [searchText, setSearchText] = useState('');
    const [dropdown, setDropdown] = useState([]);
    const [curDropdown, setCurDropdown] = useState(null);
    const [data, setData] = useState();
    const [visibleState, setVisibleState] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const user = getUser();
    const gridRef = useRef();
    const errorHandler = useErrorHandler();

    const header = [
        { dataField: 'security_master_name', caption: translation.security_group.toUpperCase(), dataType: 'string', allowReordering: true },
        { dataField: 'user_business_name', caption: translation._business.toUpperCase(), dataType: 'string', allowReordering: true },
        { dataField: 'security_master_comment', caption: translation.security_master_comment.toUpperCase(), dataType: 'string', allowReordering: true },
    ];

    useImperativeHandle(ref, () => ({
        open: open,
        close: close,
    }));

    const open = () => {
        const instance = gridRef.current.getInstance();
        instance.deselectAll();

        getListOfDropdown();
        setSearchText('');

        setVisibleState(true);
    };

    const close = () => {
        setSearchText('');
        setVisibleState(false);
    };

    const getListOfDropdown = async () => {
        const user = getUser();
        const param = { user_company_id: user.user_company_id };
        const res = await errorHandler(getSecurityBusinessByCompanyId, param);
        if (res) {
            setDropdown(res.data.o_data);
            let isBusinessExist = false;
            if (user.user_business_id != null) {
                const branch = res.data.o_data.find((branch) => branch.user_business_id == user.user_business_id);
                if (branch != null) {
                    setCurDropdown(user.user_business_id);
                    isBusinessExist = true;
                }
            }

            //if user info doesn't have business or server's response doesn't have user's business set first row as a default value.
            if (!isBusinessExist) {
                setCurDropdown(res.data.o_data[0].user_business_id);
            }
        }
    };

    const onClickOk = (e) => {
        const instance = gridRef.current.getInstance();
        const selection = instance.getSelectedRowsData();
        setSearchText('');
        onOk(selection);
        close();
    };

    const onClickCancel = (e) => {
        setSearchText('');
        close();
    };

    const searchClicked = async (val) => {
        setSearchText(val);
        getSecurityGroups(curDropdown, val);
    };

    const getSecurityGroups = async (businessId = curDropdown, keyword = searchText) => {
        let param = {
            user_company_id: user.user_company_id,
            user_business_id: businessId,
            search_text: keyword,
        };

        const res = await errorHandler(getSecurityMaster, param);
        if (res) {
            const o_data = res.data.o_data;
            setData(o_data);
        }
    };

    const dropdownChanged = async (e) => {
        setCurDropdown(e.value);
        getSecurityGroups(e.value);
    };

    const onSelect = (e) => {
        setSelectedItems(e);
    };

    const onTagDelete = (element) => {
        const instance = gridRef.current.getInstance();
        instance.deselectRows([element.security_master_id]);
        setSelectedItems((cur) => cur.filter((item) => item.security_master_id != element.security_master_id));
    };

    const dropdownm = <DropdownField label={translation.select_business} dataSource={dropdown} displayExpr="user_business_name" valueExpr="user_business_id" onValueChanged={dropdownChanged} value={curDropdown} />;

    const filter = <FilterComponent label={translation.filter} value={searchText} onFilter={(val) => setSearchText(val)} />;

    useEffect(() => {
        searchClicked(searchText);
    }, [searchText]);

    const removeTag = (i) => {
        let cur = [...selectedItems];
        let removed = cur.splice(i, 1);
        setSelectedItems(cur);

        const instance = gridRef.current.getInstance();
        removed = removed.map((e) => {
            return e['security_master_id'];
        });
        instance.deselectRows(removed);
    };

    const removeAllTags = () => {
        // setSelectedItems([]);

        const instance = gridRef.current.getInstance();
        instance.deselectRows(selectedItems.map(item => item.security_master_id));
        setSelectedItems([]);
        // instance.deselectAll();
    };

    return (
        <Popup width={1100} height={850} visible={visibleState} hideOnOutsideClick={true} onHiding={close} dragEnabled={true} position="center" showTitle={false}>
            <div className="popup-select-security-group">
                <div className="popup-header">
                    <p className="popup-title">{translation.select_security_group}</p>
                </div>
                <div className="popup-body">
                    <div className="selected-item-viewer">
                        <div className="icon-checked">
                            <i></i>
                        </div>
                        <div className="selected-item-field">
                            {selectedItems.length != 0 && (
                                <>
                                    <ul className="selected-items">
                                        {selectedItems.map((e, i) => (
                                            <li key={i} className="item">
                                                <p className="item-name">{e.security_master_name}</p>
                                                <i className="material-icons btn-delete" onClick={() => removeTag(i)}>
                                                    cancel
                                                </i>
                                            </li>
                                        ))}
                                    </ul>
                                    <div className="btn-all-delete" onClick={removeAllTags}>
                                        <button>Delete All</button>
                                    </div>
                                </>
                            )}
                            {selectedItems.length == 0 && <p className="comment-not-selected">{translation.select_security_group_notice}</p>}
                        </div>
                    </div>
                    <div className="item-select-box">
                        <ScrollView>
                            <div className="container-wrapper">
                                <div className="container">
                                    <BaseDataGrid toolbarModules={[dropdownm, filter]} dataSource={data} ref={gridRef} allowModify={false} defaultPageSize={7} selectMode="multiple" headers={header} keyExpr={'security_master_id'} onSelectionChanged={onSelect} />
                                </div>
                            </div>
                        </ScrollView>
                    </div>
                </div>
                <div className="popup-footer">
                    <Button width={86} text={translation.cancel} type="normal" stylingMode="text" className="btn-s-r" onClick={onClickCancel} />
                    <Button text={translation.confirm} width={86} type="default" stylingMode="contained" className="btn-s-r" onClick={onClickOk} />
                </div>
            </div>
        </Popup>
    );
}

SecuritySearchPopup = forwardRef(SecuritySearchPopup);
