import React, { useEffect, useState, useRef } from 'react';
import { Popup, Button, ColorBox, TextBox, ScrollView } from "devextreme-react";

export default function SelectWhatToFormatPopup(props) {
    const {
        visible,
        onClose = () => { },
        columnList, // entire column
        whatTo = [],
        whatToApply = () => { },
    } = props;

    const [selectedCol, setSelectedCol] = useState();

    useEffect(() => {
        setSelectedCol(whatTo);
    }, [visible])

    return (
        <Popup
            width={308}
            height={440}
            visible={visible}
            hideOnOutsideClick={true}
            onHiding={onClose}
            title="Select what to format"
        >
            <div className="popup-select-what">
                <div className="popup-content-s">
                    <ScrollView>
                        <ul className="select-list">
                            {
                                [{ column: { name: "Entire Row" } }, ...columnList].map((col, i) =>
                                    <li
                                        key={i}
                                        className={selectedCol?.includes(col.column.name) ? "selected" : ""}
                                        value={col.column.name}
                                        onClick={e => {
                                            const value = e.currentTarget.getAttribute('value')
                                            if (value === 'Entire Row' && selectedCol.indexOf(value) < 0) {
                                                setSelectedCol([value]);
                                            } else if (value !== 'Entire Row' && selectedCol.indexOf(value) < 0) {
                                                const hasEntire = selectedCol.includes("Entire Row");
                                                const cSelectedCol = hasEntire ? [] : [...selectedCol];
                                                cSelectedCol.push(value);
                                                setSelectedCol(cSelectedCol);
                                            } else {
                                                const cSelectedCol = [...selectedCol];
                                                cSelectedCol.splice(cSelectedCol.indexOf(value), 1);
                                                setSelectedCol(cSelectedCol);
                                            }
                                        }}
                                    >
                                        <p>{col.column.name}</p>
                                    </li>
                                )
                            }
                        </ul>
                    </ScrollView>
                </div>
                <div className="popup-footer-s">
                    <Button
                        className="btn-s-xs"
                        type="normal"
                        stylingMode="text"
                        text="CANCEL"
                        width="50%"
                        onClick={onClose}
                    />
                    <Button
                        className="btn-s-xs"
                        type="default"
                        stylingMode="contained"
                        text="APPLY"
                        width="50%"
                        onClick={() => whatToApply(selectedCol)}
                    />
                </div>
            </div>
        </Popup>
    );
}