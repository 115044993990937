﻿import CustomStore from 'devextreme/data/custom_store';
import React, { useRef, useEffect, useState } from 'react';
import { getIP, getLanguage, getMenuMappingInfo, getUser } from '../../apiInterface/utils/Common';
import BaseDataGrid from '../../components/common/BaseDataGrid';
import { PageDropdown } from '../../components/common/PageDropdown';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import { deleteAasxFileAPI, mainAasMaster } from '../../apiInterface/aasFile/AASFileManagementAPI';
import { DropdownField, FilterComponent } from '../../components/common/FormField';
import { useLocation } from 'react-router-dom';
import useTranslation from '../../components/customHooks/translations';
import { deleteAasMaster } from '../../apiInterface/aasFile/AASMasterManagementAPI';
import { failToastr, successToastr } from '../../components/common/Toastr';
import Badge from '../../components/common/Badge';
import { PageTitle } from '../../components/common/PageTitle.jsx';

let searchText = '';
let param = {};
const tempAASType = ['AAS', 'ASSET', 'SM'];
const AASFileManagement = () => {
    const [dataSource, setDataSource] = useState(null);
    const errorHandler = useErrorHandler();
    const translation = useTranslation();
    const activeNotActiveJson = { true: translation.active, false: translation.not_active };
    const activeNotActiveClassName = { true: 'grid-badge-use', false: 'grid-badge-disabled' };

    const gridHeader = [
        { dataField: 'aasx_file_no', caption: 'NO', alignment: 'center', dataType: 'number', width: 100, isFixed: true, allowReordering: true },
        { dataField: 'aasx_file_package_name', caption: 'package name'.toUpperCase(), dataType: 'string', isFixed: true, allowReordering: true },
        { dataField: 'aasx_file_name', caption: 'aasx_file_name'.toUpperCase(), dataType: 'string', allowReordering: true },
        { dataField: 'aasx_file_size', caption: 'aasx_file_size'.toUpperCase(), dataType: 'string', allowReordering: true },
        { dataField: 'aasx_file_path', caption: 'aasx_file_path'.toUpperCase(), dataType: 'string', allowReordering: true },
        {
            dataField: 'aasx_file_is_active',
            caption: 'Use or not'.toUpperCase(),
            dataType: 'string',
            width: 130,
            alignment: 'center',
            allowReordering: true,
            cellRender: (e) => <Badge className={activeNotActiveClassName[e.value]} text={activeNotActiveJson[e.value]} />,
        },
        { dataField: 'aasx_file_comment', caption: 'comment'.toUpperCase(), dataType: 'string', allowReordering: true },
        { dataField: 'aasx_file_modified_by', caption: translation.lastModifiedBy.toUpperCase(), dataType: 'string', width: 300, allowReordering: true },
        { dataField: 'aasx_file_modified_date', caption: translation.lastModifiedDate.toUpperCase(), dataType: 'string', width: 150, allowReordering: true },
    ];

    const init = useEffect(() => {
        searchText = '';
        setCustomStore();
    }, []);

    const setCustomStore = () => {
        const customStore = new CustomStore({
            load: (loadOptions) => load(loadOptions),
            remove: (val) => {
                remove(val);
            },
        });

        setDataSource(customStore);
    };

    const load = async (loadOptions) => {
        const user = getUser();
        const lang = getLanguage();
        const pageNo = parseInt(loadOptions['skip'] / loadOptions['take']) + 1;
        let param = {
            user_company_id: user.user_company_id,
            sys_lang_code: lang,
            row_count: loadOptions['take'],
            page_no: pageNo,
            search_text: searchText,
        };

        const res = await errorHandler(mainAasMaster, param);
        if (res) {
            let data = res.data.o_data;
            let totalCount = res.data.count;
            return {
                data: data,
                totalCount: totalCount,
            };
        }

        return {
            data: [],
            totalCount: 0,
        };
    };

    const remove = async (value) => {
        const user = getUser();
        const ip = getIP();
        const mappingInfo = await getMenuMappingInfo();
        const param = {
            user_master_id: user.user_master_id,
            user_company_id: user.user_company_id,
            user_log_ip: ip,
            menu_mapping_id: mappingInfo.id,
            aasx_file_id: value.aasx_file_id,
        };

        const res = await errorHandler(deleteAasxFileAPI, param);
        if (res) {
            //success.
            successToastr(translation.success_delete_row);
        }
    };

    const filtering = (keyword) => {
        searchText = keyword;
        setCustomStore();
    };

    const filter = <FilterComponent label={translation.filter} width={204} onFilter={filtering} />;

    return (
        <>
            <div className="aasx-file-mgt">
                <div className="right-content-title">
                    <PageTitle />
                    <PageDropdown />
                </div>
                <div className="right-content-body">
                    <div className="container">
                        <BaseDataGrid toolbarModules={[filter]} dataSource={dataSource} isRemote={true} link="AASFileManagementForm" params={param} showEdit={false} headers={gridHeader} isDoubleClicked={false} isFullSize={true} />
                    </div>
                </div>
            </div>
        </>
    );
};
export default AASFileManagement;
