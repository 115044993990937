import React, { useEffect, useState } from 'react';
import SelectBox from 'devextreme-react/select-box';
import { useLanguageContext } from './contexts/LanguageContext';

export default function LanguageHandler(props) {
    const { value: controlledValue, onChange: changeCallback = () => {} } = props;
    const { changeLanguage } = useLanguageContext();
    // const [localValue, setLocalValue] = useState('ko');

    const [localValue, setLocalValue] = useState();
    const options = [
        { label: '한국어', value: 'ko' },
        { label: 'english', value: 'en' },
    ];

    const value = controlledValue === undefined ? localValue : controlledValue;

    // useEffect(() => {
    //     sessionStorage.setItem('lang', value);
    //     changeLanguage(value);
    // }, [controlledValue]);

    const onChange = (val) => {
        setLocalValue(val);
        // sessionStorage.setItem('lang', val);
        localStorage.setItem('lang', val);
        changeLanguage(val);
        changeCallback(val);
    };

    return <SelectBox className="ix-tfs-r" dataSource={options} value={value} onValueChange={onChange} displayExpr="label" valueExpr="value" />;
}
