import {
    makeTokenAxiosDeleteRequest,
    makeTokenAxiosGetRequest,
    makeTokenAxiosPostRequest,
    makeTokenAxiosPutRequest,
    makeTokenAxiosPostRequestParams
} from "../utils/AxiosMask"

const baseUrl = 'operation_line'
export const getFactoryIds = async (param) => {
    let res;
    const urlPath = `${baseUrl}/get_operation_factory`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : "fail";

};

export const getOperationLineInfo = async (param) => {
    let res;
    const urlPath = `${baseUrl}/`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res.data : "fail";
};

export const insertOperationLineInfo = async (data) => {
    let res;
    const urlPath = `${baseUrl}/`;

    try {
        res = await makeTokenAxiosPostRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : "fail";
};

export const updateOperationLineInfo = async (data) => {
    let res;
    const urlPath = `${baseUrl}/`;

    try {
        res = await makeTokenAxiosPutRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res.data : "fail";
}

export const deleteOperationLineInfo = async (data) => {
    let res;
    const urlPath = `${baseUrl}/`;

    try {
        res = await makeTokenAxiosDeleteRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res.data : "fail";
}

export const getLineById = async (param) => {
    let res;
    const urlPath = `${baseUrl}/get_operation_line_by_id`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res.data : "fail";
};

export const insertLineProcess = async (param) => {
    let res;
    const urlPath = `${baseUrl}/insert_operation_line_process`;

    try {
        res = await makeTokenAxiosPostRequestParams(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res.data : "fail";
};

export const getEquipmentByLineId = async (param) => {
    let res;
    const urlPath = `${baseUrl}/operation_line_process_equipment_by_line_id`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }

    return res?.status === 200 ? res.data : "fail";
};