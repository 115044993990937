//AXIOS reference :--> https://zetcode.com/javascript/axios/
import axios from 'axios';
import { getToken } from '../utils/Common';
import { Baseapi } from '../../config';

export async function makeAxiosGetRequest(urlPath, data) {
    var url = `${Baseapi.pythonWebAPI}${urlPath}`;
    const config = {
        method: 'GET',
        url: url,
        params: data,
    };
    console.log(config);
    let res = await axios(config);
    return res;
}
export async function makeAxiosPostRequest(urlPath, data) {
    const url = `${Baseapi.pythonWebAPI}${urlPath}`;
    const config = {
        method: 'post',
        url: url,
        data: data,
    };
    let res = await axios(config);
    return res;
}

export async function makeAxiosPostFileRequest(urlPath, data) {
    const url = `${Baseapi.pythonWebAPI}${urlPath}`;
    const config = {
        method: 'post',
        url: url,
        data: data,
        headers: { 'Content-Type': 'multipart/form-data' },
    };
    let res = await axios(config);
    return res;
}

export async function makeTokenAxiosGetRequest(urlPath, data) {
    var access_token = getToken();
    var url = `${Baseapi.pythonWebAPI}${urlPath}`;
    const config = {
        method: 'GET',
        url: url,
        params: data,
        headers: {
            Authorization: `Bearer ${access_token}`,
        },
    };
    console.log(config);
    let res = await axios(config);
    return res;
}

export async function makeTokenAxiosPostRequest(urlPath, data) {
    var access_token = getToken();
    const url = `${Baseapi.pythonWebAPI}${urlPath}`;
    const config = {
        method: 'post',
        url: url,
        data: data,
        headers: {
            Authorization: `Bearer ${access_token}`,
        },
    };
    let res = await axios(config);
    return res;
}

export async function makeTokenAxiosPostRequestParams(urlPath, data) {
    var access_token = getToken();
    const url = `${Baseapi.pythonWebAPI}${urlPath}`;
    const config = {
        method: 'post',
        url: url,
        params: data,
        headers: {
            Authorization: `Bearer ${access_token}`,
        },
    };
    let res = await axios(config);
    return res;
}

export async function makeTokenAxiosPutRequest(urlPath, data) {
    var access_token = getToken();
    const url = `${Baseapi.pythonWebAPI}${urlPath}`;
    const config = {
        method: 'put',
        url: url,
        data: data,
        headers: {
            Authorization: `Bearer ${access_token}`,
        },
    };
    let res = await axios(config);
    return res;
}

export async function makeTokenAxiosPutRequestParams(urlPath, params) {
    var access_token = getToken();
    const url = `${Baseapi.pythonWebAPI}${urlPath}`;
    const config = {
        method: 'put',
        url: url,
        params: params,
        headers: {
            Authorization: `Bearer ${access_token}`,
        },
    };
    let res = await axios(config);
    return res;
}

export async function makeTokenAxiosDeleteRequest(urlPath, data) {
    var access_token = getToken();
    const url = `${Baseapi.pythonWebAPI}${urlPath}`;
    const config = {
        method: 'delete',
        url: url,
        params: data,
        headers: {
            Authorization: `Bearer ${access_token}`,
        },
    };
    let res = await axios(config);
    return res;
}

export async function makeTokenAxiosGetRequestLnf(urlPath, data) {
    var access_token = getToken();
    var url = `${Baseapi.lnfAPI}${urlPath}`;
    const config = {
        method: 'GET',
        url: url,
        params: data,
        headers: {
            Authorization: `Bearer ${access_token}`,
        },
    };
    let res = await axios(config);
    return res;
}
