//Custome menu view(This is for the all the framework custom menus which will be created by template builder)
import React, { useState, useEffect, useRef } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { getUser, getIP, getLanguage, getMenuMappingInfo } from '../../apiInterface/utils/Common';
import CMVPane from "../../components/splitPane/CMVPane"
import CMVPane2 from "../../components/gridStackPane/CMVPane2"
import { useLocation } from 'react-router-dom';
// Promise that is resolved after waiting 1 second 
const waitSec = () => new Promise(resolve => setTimeout(resolve, 1000));

// Return an Object with a structure after 1 second. 
const getStructure = async () => {
    await waitSec();
    return { a: 1, b: 'b' };
};
//here props may include some information
const CMV = (props) => {
    const { params } = useParams();
    const location = useLocation();
    //const navigate = useNavigate();
    //navigate("/CMV1")
    //const mappingInfo = getMenuMappingInfo();
    // const mappingInfoObj = {
    //     "mappingInfoId": mappingInfo.id,
    //     "title": mappingInfo.caption
    // };
    //all the created pan for loop

    //return (<><CMVPane data={params} /></>)
    return (<><CMVPane2 data={params} /></>)
}

export default CMV;