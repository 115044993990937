import React, { useRef, useEffect, useState } from 'react';
import { Allotment } from 'allotment';
import "allotment/dist/style.css";
import "../../contents/styles/dataManagement/data-management.css";
import BaseDataGrid from '../../components/common/BaseDataGrid';
import { useLocation } from 'react-router-dom';
import { getIP, getMenuMappingInfo, getUser } from '../../apiInterface/utils/Common';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import { deleteAasMaster, getAasMasterExplorer, getAasMasterExplorerDetail } from '../../apiInterface/aasFile/AASMasterManagementAPI';
import { FilterComponent } from '../../components/common/FormField';
import useTranslation from '../../components/customHooks/translations';
import TreeCard from '../../components/common/TreeCard';
import PageTitle from '../../components/common/PageTitle';


let temp = [];
const AASMasterManagementExplorer = () => {
    const [selectedNode, setSelectedNode] = useState([]);
    const [data, setData] = useState([]);
    const location = useLocation();
    const errorHandler = useErrorHandler();
    const [title, setTitle] = useState("");
    const translation = useTranslation();

    useEffect(() => {
        getAasExplorer();
        let map = getMenuMappingInfo();
        setTitle(map.caption);
    }, []);

    const getAasExplorer = async () => {
        const state = location.state;
        const aasData = state?.rowData;//aasx_file_id
        const user = getUser();

        const param = {
            user_company_id: user.user_company_id,
            aas_master_id: aasData.aas_master_id,
        };

        const res = await errorHandler(getAasMasterExplorer, param);
        if (res) {
            let data = res.data.o_data;
            // data.forEach((element) => {
            //     let detail = element.aas_master_id_short;

            //     let identification = element.aas_master_identification;
            //     if (identification != null && identification !== 'NULL') {
            //         detail += ` - ${identification}`;
            //     }

            //     element.detail = detail;
            // });
            setData(data);
        }
    }

    const getAasDetail = async (data) => {
        const param = { aas_master_id: data.aas_master_id };
        const res = await errorHandler(getAasMasterExplorerDetail, param);

        if (res) {
            setSelectedNode(res.data.o_data);
            const str = JSON.stringify(res.data.o_data);
            temp = res.data.o_data;
        }
    }

    const onRowClick = (rowData) => { getAasDetail(rowData); }

    const deleteDetail = async (data) => {
        const user = getUser();
        const ip = getIP();
        const mappingInfo = getMenuMappingInfo();

        const param = {
            user_master_id: user.user_master_id,
            user_log_id: ip,
            menu_mapping_id: mappingInfo.id,
            user_company_id: user.user_company_id,
            aas_master_id: data.aas_master_id,
            all_delete: data.all_delete
        }

        const res = await errorHandler(deleteAasMaster, param);
        if (res) {
            //do something if success
        }
    }

    const filtering = (keyword) => {
        let result = [];

        if (!keyword) {
            result = [...temp];
        } else {
            for (let i = 0; i < selectedNode.length; i++) {
                let isContain = selectedNode[i].aas_master_detail_name.toLowerCase().includes(keyword.toLowerCase());
                if (isContain) {
                    result.push(selectedNode[i]);
                }
            }
        }

        setSelectedNode(result);
    }

    return (
        <>
            <div className="data-management aas-master-mgt-explorer">
                <div className="right-content-title">
                    <PageTitle />
                </div>
                <div className="right-content-body">
                    <Allotment>
                        <Allotment.Pane>
                            <div className="left-panel">
                                <div className="container">
                                    <div className='panel-header'>
                                        <p>AAS Package Structure</p>
                                    </div>
                                    <div className="panel-body">
                                        <TreeCard
                                            data={data}
                                            onRowClick={onRowClick}
                                            keyExpr="aas_master_id"
                                            parentExpr="aas_master_parent_id"
                                            badgeExprs={["aas_master_type"]}
                                            nameExpr="aas_master_id_short"
                                            detailExpr="aas_master_identification"
                                        />
                                    </div>
                                </div>
                            </div>
                        </Allotment.Pane>
                        <Allotment.Pane>
                            <div className="right-panel">
                                <div className="container">
                                    <div className='panel-header'>
                                        <p>Element</p>
                                    </div>
                                    <div className="panel-body">
                                        {
                                            selectedNode.length > 0 ?
                                                <BaseDataGrid
                                                    toolbarModules={[<FilterComponent width={204} onFilter={filtering} label="Filter" />]}
                                                    dataSource={selectedNode}
                                                    allowModify={false}
                                                /> :
                                                <div className="select-message">
                                                    <div></div>
                                                    <p>{translation.no_data}</p>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </Allotment.Pane>
                    </Allotment>
                </div>
            </div>
        </>
    );
}
export default AASMasterManagementExplorer;
