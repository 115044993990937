import React, { useRef, useEffect, useState } from 'react';
import { Allotment } from 'allotment';
import 'allotment/dist/style.css';
import '../../contents/styles/dataManagement/data-management.css';
import BaseDataGrid from '../../components/common/BaseDataGrid';
import { Button } from 'devextreme-react/button';
import { useLocation, useNavigate } from 'react-router-dom';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import { getMenuMappingInfo, getUser } from '../../apiInterface/utils/Common';
import { getAasExplorer, getDatasetMasterExplorer, getDatasetMasterProperty } from '../../apiInterface/aasFile/DatasetManagementAPI';
import useTranslation from '../../components/customHooks/translations';
import TreeCard from '../../components/common/TreeCard';
import PageTitle from '../../components/common/PageTitle';

const DatasetManagementDetail = () => {
    const [selectedNode, SetSelectedNode] = useState([]);
    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [treeData, setTreeData] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const errorHandler = useErrorHandler();
    const translation = useTranslation();

    const header = [
        { dataField: 'collection_name', caption: 'table'.toUpperCase(), dataType: 'string', allowReordering: true },
        { dataField: 'column_name', caption: 'column'.toUpperCase(), dataType: 'string', allowReordering: true },
        { dataField: 'aas_path', caption: 'aas path'.toUpperCase(), dataType: 'string', allowReordering: true },
    ];

    const getDatasetProperties = async () => {
        const user = getUser();
        const data = location.state.data;
        const param = {
            user_company_id: user.user_company_id,
            dataset_master_id: data.dataset_master_id,
        };

        const res = await errorHandler(getDatasetMasterProperty, param);
        if (res) {
            const data = res.data.o_data;
            if (data) {
                SetSelectedNode(data.length !== 0 ? data : []);
            }
        }
    };

    //init
    useEffect(() => {
        getDatasetStructure();
        getDatasetProperties();
        setSubtitle(location.state?.data?.dataset_master_name);
        let mappingInfo = getMenuMappingInfo();
        setTitle(mappingInfo.caption);
    }, []);

    const getDatasetStructure = async () => {
        const user = getUser();
        const data = location.state.data;
        const param = {
            user_company_id: user.user_company_id,
            dataset_master_id: data.dataset_master_id,
        };

        const res = await errorHandler(getDatasetMasterExplorer, param);
        if (res) {
            setTreeData(res.data.o_data);
        }
    };

    return (
        <>
            <div className="data-management dataset-mgt-detail">
                <div className="right-content-title">
                    <PageTitle />
                    <div>
                        <Button className="btn-s-r dx-button-mode-es" type="default" stylingMode="contained" icon="back" onClick={() => navigate('/DatasetManagement')} />
                    </div>
                </div>
                <div className="right-content-body">
                    <div className="container">
                        <div className="selected-dataset">
                            <div>
                                <span className="badge">Dataset</span>
                                <h6>{subtitle}</h6>
                            </div>
                            {/* <div>
                                <div>
                                    <Button className="btn-s-r btn-hover-red" icon="trash" type="normal" stylingMode="text" />
                                    <Button className="btn-s-r" icon="edit" type="normal" stylingMode="text" />
                                </div>
                                <div className="detail-or-rawdata">
                                    <div className="toggle">
                                        <input type="checkbox" id="DetailOrRawdata" onChange={() => setTimeout(() => navigate('/DatasetManagementRawData', { state: location.state }), 200)}></input>
                                        <label htmlFor="DetailOrRawdata">
                                            <p>Details</p>
                                            <p>Raw Data</p>
                                        </label>
                                        <span className="move"></span>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <Allotment>
                            <Allotment.Pane>
                                <div className="left-panel">
                                    <div className="panel-header">
                                        <p>AAS Package Structure</p>
                                    </div>
                                    <div className="panel-body">
                                        <TreeCard
                                            data={treeData}
                                            // onRowClick={onRowClick}
                                            nameExpr="aas_master_id_short"
                                            detailExpr="aas_master_identification"
                                            badgeExprs={['aas_master_type']}
                                            parentExpr="aas_master_parent_id"
                                            keyExpr="aas_master_id"
                                            isSelectable={false}
                                        />
                                    </div>
                                </div>
                            </Allotment.Pane>
                            <Allotment.Pane>
                                <div className="right-panel">
                                    <div className="panel-header">
                                        <p>Dataset Table</p>
                                    </div>
                                    <div className="panel-body">
                                        {selectedNode.length > 0 ? (
                                            <BaseDataGrid dataSource={selectedNode} allowModify={false} headers={header} selectMode={null} />
                                        ) : (
                                            <div className="select-message">
                                                <div></div>
                                                <p>{translation.no_data}</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Allotment.Pane>
                        </Allotment>
                    </div>
                </div>
            </div>
        </>
    );
};
export default DatasetManagementDetail;
