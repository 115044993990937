import CustomStore from 'devextreme/data/custom_store';
import { useEffect, useState } from 'react';
import { getPreviewData } from '../../apiInterface/menu/MenuAPI';
/* new options */
import { getLanguage, getParam, getUser } from '../../apiInterface/utils/Common';
/* new options */
import BaseDataGrid from '../../components/common/BaseDataGrid';
import { DateRangeField } from '../../components/common/FormField';

export default function ViewSampleData() {
    const user = getUser();
    /* new options */
    const lang = getLanguage();
    /* new options */
    const [dataSource, setDataSource] = useState();

    /* new options */
    const pageSize = 30;
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    /* new options */

    /* new options */
    useEffect(() => {
        const rawDatasetId = getParam('raw_dataset_id');
        if (rawDatasetId) getDataSample(rawDatasetId);
    }, [startDate, endDate]);

    const formatDate = (targetDate) => {
        if (targetDate == null) return undefined;
        const year = targetDate.getFullYear();
        const month = ('0' + (1 + targetDate.getMonth())).slice(-2);
        const date = ('0' + targetDate.getDate()).slice(-2);
        return `${year}-${month}-${date}`;
    };

    const getDataSample = async (id) => {
        const customStore = new CustomStore({
            load: async (loadOptions) => {
                let skip = 0;
                let take = 10;
                if (loadOptions['skip']) skip = loadOptions['skip'];
                if (loadOptions['take']) take = loadOptions['take'];
                const pageNo = parseInt(skip / take) + 1;
                const params = {
                    user_company_code: user.user_company_code,
                    company_id: user.user_company_id,
                    dataset_id: id,
                    lang_code: lang,
                    start_date: formatDate(startDate),
                    end_date: formatDate(endDate),
                    row_count: pageSize,
                    page_no: pageNo,
                    sort: loadOptions.sort?.length > 0 ? JSON.stringify([{ selector: loadOptions.sort[0].selector, desc: JSON.stringify(loadOptions.sort[0].desc) }]) : undefined,
                };
                const res = await getPreviewData(params);
                if (res !== 'fail' && res.message === 'MSG_100') {
                    // setDataSource(res.data.o_data);
                    return { data: res.data.o_data, totalCount: res.counmt };
                } else {
                    // setDataSource([]);
                    return { data: [], totalCount: 0 };
                }
            },
        });
        setDataSource(customStore);
    };
    /* new options */

    const dateRange = <DateRangeField startValue={startDate} endValue={endDate} setStartValue={setStartDate} setEndValue={setEndDate} />;

    return (
        <div className="dx-swatch-ix-pds window-popup">
            <div className="popup-header">
                <h4>Sample Data</h4>
            </div>
            <div className="popup-body">
                {/* new options */}
                <div className="card">{<BaseDataGrid toolbarModules={[dateRange]} dataSource={dataSource} isRemote={true} showEdit={false} showDelete={false} showAdd={false} isDoubleClicked={false} showCheckBox={false} isFullSize={true} isPaging={true} />}</div>
                {/* new options */}
            </div>
        </div>
    );
}
