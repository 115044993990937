import React, { useEffect, useMemo, useState } from "react";
import { getUser, getIP, getMenuMappingInfo, getLanguage } from '../../apiInterface/utils/Common';
import { useLocation, useNavigate } from "react-router-dom";
import Button from 'devextreme-react/button';
import DateBox from 'devextreme-react/date-box';
import TextArea from 'devextreme-react/text-area';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import useTranslation from "../../components/customHooks/translations";
import { Validator, RequiredRule } from 'devextreme-react/validator';
import { useSwal } from '../../components/common/Swal';
import useErrorHandler from "../../components/customHooks/useErrorHandler";
import { DropdownField, SearchableField, TextboxField } from "../../components/common/FormField";
import { moveFocus } from "../../components/common/FormFuncWithEnter";
import { PageTitle } from "../../components/common/PageTitle.jsx";
import { getOneMasterDatasetAPI, updateMasterDatasetAPI, getMoldListAPI, getItemListAPI, insertMasterDatasetAPI, getMasterDatasetTypeAPI } from "../../apiInterface/masterDataset/MasterDatasetAPI";
import SelectRawDatasetPopup from "./SelectRawDataPopup";
import SelectEquipmentPopup from "./SelectEquipmentPopup";
import SelectItemPopup from "./SelectItemPopup";
import SelectLinePopup from "./SelectLinePopup";
import { getEquipmentByLineId } from "../../apiInterface/line/LineAPI";


const previousPage = "/MasterDataset";
export default function MasterDatasetForm() {
    const user = getUser();
    const ipAddress = getIP();
    const navigate = useNavigate();
    const [data, setData] = useState();
    const translation = useTranslation();
    const location = useLocation();
    const errorHandler = useErrorHandler();
    const { success } = useSwal();
    const [startMaxDate, setStartMaxDate] = useState();
    const [endMinDate, setEndMinDate] = useState();
    const [masterDatasetTypes, setMasterDatasetTypes] = useState([]);
    const [moldDropdown, setMoldDropdown] = useState([]);
    const [itemDropdown, setItemDropdown] = useState([]);
    const [processDropdown, setProcessDropdown] = useState([]);
    const [isEquipmentPopupVisible, setEquipmentPopupVisibility] = useState(false);
    const [isRawDatasetPopupVisible, setRawDatasetPopupVisibility] = useState(false);
    const [isItemPopupVisible, setIsItemPopupVisible] = useState(false);
    const [isLinePopupVisible, setIsLinePopupVisible] = useState(false);

    const useItems = [
        { value: true, title: translation.use },
        { value: false, title: translation.notUse }
    ];

    //init
    useEffect(() => {
        getMasterDatasetType();
        if (location.state.title == "Edit")
            getDetailData();
        else
            valueChanged(useItems[0].value, "operation_factory_is_active");
    }, []);

    const getDetailData = async () => {
        const lang = getLanguage();
        const { master_dataset_id } = location.state.rowData;
        const param = {
            master_dataset_id: master_dataset_id,
            sys_lang_code: lang
        }

        const res = await errorHandler(getOneMasterDatasetAPI, param);
        if (res) {
            let data = res.data.o_data[0];
            data["master_dataset_end_date"] = parseDateString(data.master_dataset_end);
            data["master_dataset_start_date"] = parseDateString(data.master_dataset_start);

            if (data.machine_equipment_id != null)
                getMoldDropdown(data.machine_equipment_id);
            if (data.machine_mold_id != null)
                getItemDropdown(data.machine_mold_id);

            setData(data);
        }
    }

    const getMasterDatasetType = async () => {
        const user = getUser();
        const ipAddr = getIP();
        const lang = getLanguage();
        const menuMappingInfo = getMenuMappingInfo();
        const param = {
            user_company_id: user.user_company_id,
            user_log_ip: ipAddr,
            menu_mapping_id: menuMappingInfo.id,
            user_master_id: user.user_master_id,
            sys_lang_code: lang
        };

        const res = await errorHandler(getMasterDatasetTypeAPI, param);
        if (res) {
            setMasterDatasetTypes(res.data.o_data);
            valueChanged(res.data.o_data[0].sys_division_sub_value, "master_dataset_type");
        }
    }

    const onInsert = async () => {
        const { id: mapping_id } = await getMenuMappingInfo();

        let master_dataset_custom = {}
        if (data.machine_equipment_id != null)
            master_dataset_custom.machine_equipment_id = data.machine_equipment_id;
        if (data?.machine_mold_id != null)
            master_dataset_custom.machine_mold_id = data.machine_mold_id;
        if (data.operation_line_id != null)
            master_dataset_custom.operation_line_id = data.operation_line_id;
        if (data.operation_process_id != null)
            master_dataset_custom.operation_process_id = data.operation_process_id;
        master_dataset_custom = JSON.stringify(master_dataset_custom);

        const queryData = {
            user_master_id: user.user_master_id,
            user_log_ip: ipAddress,
            menu_mapping_id: mapping_id,
            user_company_id: user.user_company_id,
            master_dataset_name: data.master_dataset_name,
            master_dataset_type: data.master_dataset_type,
            raw_dataset_name: data.raw_dataset_name,
            item_master_id: data.item_master_id,
            master_dataset_start: data.master_dataset_start,
            master_dataset_end: data.master_dataset_end,
            master_dataset_is_active: data.master_dataset_is_active,
            master_dataset_comment: data.master_dataset_comment,
            master_dataset_custom: master_dataset_custom
        }

        const response = await errorHandler(insertMasterDatasetAPI, queryData);
        if (response) {
            const cb = () => exitForm(previousPage);
            success(translation.success_insert, cb);
        }
    }

    const onUpdate = async () => {
        const { id: mapping_id } = await getMenuMappingInfo();

        let master_dataset_custom = JSON.stringify({
            machine_equipment_id: data.machine_equipment_id,
            machine_mold_id: data.machine_mold_id
        });

        const queryData = {
            user_master_id: user.user_master_id,
            user_log_ip: ipAddress,
            menu_mapping_id: mapping_id,
            user_company_id: user.user_company_id,
            master_dataset_name: data.master_dataset_name,
            raw_dataset_name: data.raw_dataset_name,
            item_master_id: data.item_master_id,
            machine_equipment_id: data.machine_equipment_id,
            machine_mold_id: data.machine_mold_id,
            master_dataset_start: data.master_dataset_start,
            master_dataset_end: data.master_dataset_end,
            master_dataset_is_active: data.master_dataset_is_active,
            master_dataset_comment: data.master_dataset_comment,
            master_dataset_type: data.master_dataset_type,
            master_dataset_custom: master_dataset_custom
        }

        const response = await errorHandler(updateMasterDatasetAPI, queryData);
        if (response) {
            const cb = () => exitForm(previousPage);
            success(translation.success_update, cb);
        }
    }

    const valueChanged = (value, key) => {
        setData((cur) => {
            let newData = { ...cur };
            newData[key] = value;
            return newData
        });
    }

    const exitForm = (targetUrl) => {
        navigate(targetUrl, {
            state: { previousPage: location.pathname }
        })
    }

    const submit = (e) => {
        e.preventDefault();
        if (location.state.title === "Add") onInsert();
        else if (location.state.title === "Edit") onUpdate();
    }

    const onDateChanged = (e, key) => {
        if (e.value) {
            const text = e.component.option("text");
            valueChanged(text, key);
            valueChanged(e.value, key + "_date");

            if (e.value != null) {
                if (key === 'master_dataset_start')
                    setEndMinDate(e.value);
                if (key === 'master_dataset_end')
                    setStartMaxDate(e.value);
            }
        }
    }

    const rawDatasetSelected = (e) => {
        e.forEach((d) => {
            valueChanged(d.dataset_master_name, "raw_dataset_name");
            valueChanged(d.dataset_master_id, "raw_dataset_id");
        });
    }

    const equipmentSelected = (e) => {
        let id = e[0].machine_equipment_id;
        let name = e[0].machine_equipment_name;

        valueChanged(id, "machine_equipment_id");
        valueChanged(name, "machine_equipment_name");

        if (data?.master_dataset_type == "INJ-RQ") {
            // due to data dependency, removes previous mold, item values.
            valueChanged(null, "machine_mold_id");
            valueChanged(null, "item_master_id");
        }

        getMoldDropdown(e[0].machine_equipment_id);
    }

    const itemSelected = (e) => {
        valueChanged(e[0].item_master_id, "item_master_id");
        valueChanged(e[0].item_master_name, "item_master_name");
    }

    const lineSelected = (e) => {
        valueChanged(e[0].operation_line_id, "operation_line_id");
        valueChanged(e[0].operation_line_name, "operation_line_name");
        getProcessDropdown(e[0].operation_line_id);
        //valueChanged(e[0].item_master_id, "item_master_id"); line column name
    }

    const getMoldDropdown = async (id) => {
        const param = {
            user_company_id: user.user_company_id,
            machine_equipment_id: id
        };
        const res = await errorHandler(getMoldListAPI, param);
        if (res) {
            setMoldDropdown(res.data.o_data);
        }
    }

    const moldDropdownOnChanged = (e) => {
        valueChanged(e.value, "machine_mold_id");
        valueChanged(null, "item_master_id");
        getItemDropdown(e.value);
    }

    const getItemDropdown = async (id) => {
        const param = {
            user_company_id: user.user_company_id,
            machine_mold_id: id
        };
        const res = await errorHandler(getItemListAPI, param);
        if (res) {
            setItemDropdown(res.data.o_data);
        }
    }

    const getProcessDropdown = async (id) => {
        const param = { operation_line_id: id };
        const res = await errorHandler(getEquipmentByLineId, param);
        if (res) {
            setProcessDropdown(res.data.o_data);
        }
    }

    const itemDropdownOnChanged = (e) => {
        valueChanged(e.value, "item_master_id");
    }

    const processDropdownOnChanged = (e) => {
        valueChanged(e.value, "operation_process_id");
    }

    const onMasterDatasetTypeChanged = (e) => {
        valueChanged(null, "master_dataset_item_image");
        valueChanged(null, "master_dataset_item");
        valueChanged(null, "master_dataset_equipment");
        valueChanged(null, "master_dataset_mold");
        valueChanged(e.value, "master_dataset_type");
    }

    useEffect(() => {
        valueChanged("Inspection type", "master_dataset_type");
    }, [])

    return (
        <div className="MasterDataset-form">
            <div className="right-content-title">
                <PageTitle pageState={location.state.title} />
            </div>
            <form onSubmit={submit}>
                <div className="right-content-body">
                    <div className="container">
                        <div className="dx-fieldset">
                            <DropdownField
                                label={translation.master_dataset_type}
                                value={data?.master_dataset_type}
                                dataSource={masterDatasetTypes}
                                valueExpr="sys_division_sub_value"
                                displayExpr="sys_division_sub_caption_text"
                                isEssential={true}
                                isSearchable={false}
                                inputAttr={{ focusidx: 1 }}
                                onValueChanged={onMasterDatasetTypeChanged}
                                onEnterKey={moveFocus}
                            />

                            {
                                location.state.title == "Edit" &&
                                <TextboxField
                                    isEssential={true}
                                    essentialMsg={translation.master_dataset_code + translation.is_required}
                                    label={translation.master_dataset_code}
                                    value={data?.master_dataset_code}
                                    onValueChanged={(e) => valueChanged(e.value, "master_dataset_code")}
                                    readOnly={true}
                                    onEnterKey={moveFocus}
                                    inputAttr={{ focusidx: 2 }}
                                />
                            }

                            <TextboxField
                                isEssential={true}
                                essentialMsg={translation.master_dataset_name + translation.is_required}
                                label={translation.master_dataset_name}
                                value={data?.master_dataset_name}
                                onValueChanged={(e) => valueChanged(e.value, "master_dataset_name")}
                                readOnly={location.state?.title == "Edit" ? true : false}
                                onEnterKey={moveFocus}
                                inputAttr={{ focusidx: 2 }}
                            />

                            <SearchableField
                                isEssential={true}
                                label={translation.raw_dataset}
                                hasTextboxPopup={true}
                                onSearch={() => setRawDatasetPopupVisibility(true)}
                                text={data?.raw_dataset_name}
                            />



                            <div className="dx-field">
                                <div className="dx-field-label">
                                    <p className="essential">{translation.period}</p>
                                </div>
                                <div className="dx-field-value date-range-field">
                                    <DateBox
                                        name={null}
                                        type="date"
                                        className="ix-tfs-r"
                                        value={data?.master_dataset_start_date}
                                        onValueChanged={e => onDateChanged(e, 'master_dataset_start')}
                                        acceptCustomValue={false}
                                        openOnFieldClick={true}
                                        max={startMaxDate}
                                        displayFormat="MM/dd/yyyy"
                                    >
                                        <Validator>
                                            <RequiredRule message={"Start Date" + translation.is_required} />
                                        </Validator>
                                    </DateBox>
                                    <DateBox
                                        name={null}
                                        type="date"
                                        className="ix-tfs-r"
                                        value={data?.master_dataset_end_date}
                                        onValueChanged={e => onDateChanged(e, 'master_dataset_end')}
                                        acceptCustomValue={false}
                                        openOnFieldClick={true}
                                        min={endMinDate}
                                    >
                                        <Validator>
                                            <RequiredRule message={"End Date" + translation.is_required} />
                                        </Validator>
                                    </DateBox>
                                </div>
                            </div>
                            {data?.master_dataset_type == "VIS-I" &&
                                <>
                                    <div className="group-field">
                                        <SearchableField
                                            label={translation.item}
                                            isEssential={true}
                                            hasTextboxPopup={true}
                                            onSearch={() => setEquipmentPopupVisibility(true)}
                                        />
                                        <DropdownField
                                            label={translation.item_image}
                                            isEssential={true}
                                            isSearchable={false}
                                            disabled={true}
                                        />
                                    </div>
                                </>
                            }
                            {data?.master_dataset_type == "INJ-RQ" &&
                                <>
                                    <div className="group-field">
                                        <SearchableField
                                            label={translation._equipment}
                                            isEssential={true}
                                            hasTextboxPopup={true}
                                            onSearch={() => setEquipmentPopupVisibility(true)}
                                            text={data?.machine_equipment_name}
                                        />

                                        <DropdownField
                                            label={translation.mold}
                                            value={data?.machine_mold_id}
                                            dataSource={moldDropdown}
                                            valueExpr="machine_mold_id"
                                            displayExpr="machine_mold_name"
                                            onValueChanged={moldDropdownOnChanged}
                                            onEnterKey={moveFocus}
                                            inputAttr={{ focusidx: 2 }}
                                            isEssential={true}
                                            isSearchable={false}
                                            disabled={data?.machine_equipment_id ? false : true}
                                            placeholder={translation.select_mold}
                                        />

                                        <DropdownField
                                            label={translation.item}
                                            dataSource={itemDropdown}
                                            value={data?.item_master_id}
                                            valueExpr="item_master_id"
                                            displayExpr="item_master_name"
                                            onValueChanged={itemDropdownOnChanged}
                                            onEnterKey={moveFocus}
                                            inputAttr={{ focusidx: 2 }}
                                            isEssential={true}
                                            essentialMsg={"Item" + translation.is_required}
                                            isSearchable={false}
                                            disabled={data?.machine_mold_id ? false : true}
                                            placeholder="select item"
                                        />
                                    </div>
                                </>
                            }
                            {data?.master_dataset_type == "CNC-RQ" &&
                                <>
                                    <div className="group-field">
                                        <SearchableField
                                            label={translation._equipment}
                                            isEssential={true}
                                            hasTextboxPopup={true}
                                            onSearch={() => setEquipmentPopupVisibility(true)}
                                            text={data?.machine_equipment_name}
                                        />
                                        <SearchableField
                                            label={translation.item}
                                            isEssential={true}
                                            hasTextboxPopup={true}
                                            onSearch={() => setIsItemPopupVisible(true)}
                                            text={data?.item_master_name}
                                        />
                                        <SearchableField
                                            label={translation.line}
                                            isEssential={true}
                                            hasTextboxPopup={true}
                                            onSearch={() => setIsLinePopupVisible(true)}
                                            text={data?.operation_line_name}
                                        />
                                        <DropdownField
                                            label={translation.process}
                                            isEssential={true}
                                            essentialMsg={translation.process + translation.is_required}
                                            isSearchable={false}
                                            disabled={data?.operation_line_name == undefined ? true : false}
                                            dataSource={processDropdown}
                                            valueExpr="operation_id"
                                            displayExpr="operation_name"
                                            onValueChanged={processDropdownOnChanged}
                                            onEnterKey={moveFocus}
                                            placeholder={`select ${translation.process}`}
                                        />
                                    </div>
                                </>
                            }
                            <DropdownField
                                label={translation.use_or_not}
                                value={data?.master_dataset_is_active}
                                dataSource={useItems}
                                valueExpr="value"
                                displayExpr="title"
                                onValueChanged={(e) => valueChanged(e.value, "master_dataset_is_active")}
                                onEnterKey={moveFocus}
                                inputAttr={{ focusidx: 3 }}
                                isEssential={true}
                                isSearchable={false}
                            />
                            <div className="dx-field">
                                <div className="dx-field-label">
                                    <p>{translation.comment}</p>
                                </div>
                                <div className="dx-field-value">
                                    <TextArea
                                        value={data?.master_dataset_comment}
                                        onValueChanged={e => valueChanged(e.value, "master_dataset_comment")}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="block"></div>
                        {location.state.title == "Edit" &&
                            <div>
                                <div className="dx-fieldset write-info">
                                    <div className="dx-field">
                                        <div className="dx-field-label">{translation.lastModifiedDate}</div>
                                        <div className="dx-field-value">{data?.modified_date}</div>
                                    </div>
                                    <div className="dx-field">
                                        <div className="dx-field-label">{translation.lastModifiedBy}</div>
                                        <div className="dx-field-value">{data?.modified_by_name}
                                            {
                                                data?.modified_by_duty &&
                                                <span className="badge badge-job-position">{data?.modified_by_duty}</span>
                                            }
                                            {
                                                data?.modified_by_department &&
                                                <span className="badge badge-team">{data?.modified_by_department}</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="block"></div>
                            </div>
                        }
                        <div>
                            <div className="btn-group dx-fieldset">
                                <Button
                                    type="normal"
                                    stylingMode="text"
                                    className="btn-s-r"
                                    onClick={_ => exitForm("/MasterDataset")}
                                >
                                    <div>
                                        <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                        <span className="dx-button-text">{translation.cancel}</span>
                                    </div>
                                </Button>
                                <Button
                                    id="submitBtn"
                                    type="default"
                                    stylingMode="contained"
                                    className="btn-s-r"
                                    useSubmitBehavior={true}
                                >
                                    <div>
                                        <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                        <span className="dx-button-text">{translation.save}</span>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <SelectRawDatasetPopup
                visible={isRawDatasetPopupVisible}
                onOk={rawDatasetSelected}
                onClose={() => setRawDatasetPopupVisibility(false)}
            />
            <SelectEquipmentPopup
                visible={isEquipmentPopupVisible}
                onOk={equipmentSelected}
                onClose={() => setEquipmentPopupVisibility(false)}
            />
            <SelectLinePopup
                visible={isLinePopupVisible}
                onOk={lineSelected}
                onClose={() => setIsLinePopupVisible(false)}
            />
            <SelectItemPopup
                visible={isItemPopupVisible}
                onOk={itemSelected}
                onClose={() => setIsItemPopupVisible(false)}
            />
        </div>
    );
}

function parseDateString(str) {
    let result = str.match("([0-9]+)[^0-9]+([0-9]+)[^0-9]+([0-9]+)");
    if (result.length != 4)
        return null;

    return new Date(result[3], result[2], result[1]);
}