﻿import React, { useEffect, useState, useRef } from 'react';
import Button from 'devextreme-react/button';
import TextBox from 'devextreme-react/text-box';
import { Validator, RequiredRule, EmailRule, PatternRule, CustomRule } from 'devextreme-react/validator';
import { getToken, getUser, getIP, getMenuMappingInfo } from '../../apiInterface/utils/Common';
import { swal, useSwal } from '../../components/common/Swal';
import useTranslation from '../../components/customHooks/translations';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { useLocation, useNavigate } from 'react-router-dom';
import { insertDepartmentInfo, updateDepartmentInfo, getDepartmentInfoByDepartmentId } from '../../apiInterface/departmentManagement/DepartementManagementAPI';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import TreeListPath from '../../components/common/TreeListPath';
import { useLanguageContext } from '../../components/contexts/LanguageContext';
import { actionDirect, moveFocus, submit } from '../../components/common/FormFuncWithEnter';
import { DropdownField } from '../../components/common/FormField';
import { PageTitle } from '../../components/common/PageTitle.jsx';
import TextArea from 'devextreme-react/text-area';

const DepartmentManagementInfoForm = () => {
    const translation = useTranslation();
    const location = useLocation();
    const title = location.state.title;
    const navigate = useNavigate();
    const [deptData, setDeptData] = useState(null);
    const errorHandler = useErrorHandler();
    const { language } = useLanguageContext();
    const { success } = useSwal();

    //init
    useEffect(() => {
        if (location.state.itemId && title == 'Edit') {
            let param = {
                user_department_by_id: location.state.itemId,
                sys_lang_code: language,
            };
            const fetching = async () => {
                const res = await errorHandler(getDepartmentInfoByDepartmentId, param);
                if (res) {
                    res.data.o_data[0].modified_date = res.data.o_data[0].modified_date.split('T')[0];
                    setDeptData(res.data.o_data[0]);
                }
            };
            fetching();
        } else if (location.state.title == 'Add') {
            setDeptData({
                user_department_parent_id: location.state.itemId,
                user_department_is_active: useItems[0].value,
            });
        }
    }, []);

    const useItems = [
        { value: true, title: translation.use },
        { value: false, title: translation.notUse },
    ];

    const onValueChanged = (e, key) => {
        /*
        let temp = { ...deptData }
        temp[key] = e.value;
        setDeptData(temp);
        */
        setDeptData((cur) => {
            let newData = { ...cur };
            newData[key] = e.value;
            return newData;
        });
    };

    const onClickCancel = (e) => {
        navigate('/DepartmentManagementInfo', {
            state: { previousPage: location.pathname },
        });
    };

    //Insert API for DepartmentManagementInfo
    const onRowInserting = async (e) => {
        const user = getUser();
        const ipAddress = getIP();
        const { id: mapping_id } = await getMenuMappingInfo();
        const param = {
            user_master_id: user.user_master_id,
            user_log_ip: ipAddress,
            menu_mapping_id: mapping_id,
            user_business_id: sessionStorage.getItem('businessId'),
            user_department_parent_id: deptData.user_department_parent_id ? deptData.user_department_parent_id : 0,
            user_department_name: deptData.user_department_name,
            user_department_is_active: deptData.user_department_is_active,
            user_department_comment: deptData.user_department_comment,
        };

        const res = await errorHandler(insertDepartmentInfo, param);
        if (res) {
            const cb = () => onClickCancel(e);
            const text = `<p>${translation.success_insert}</p>`;
            success(text, cb);
        }
    };

    //Update API for DepartmentManagementInfo
    const onRowUpdating = async (e) => {
        const user = getUser();
        const ipAddress = getIP();
        const { id: mapping_id } = await getMenuMappingInfo();

        let param = {
            user_master_id: user.user_master_id,
            user_log_ip: ipAddress,
            menu_mapping_id: mapping_id,
            user_department_id: location.state.itemId,
            user_department_name: deptData.user_department_name,
            user_department_is_active: deptData.user_department_is_active,
            user_department_comment: deptData.user_department_comment,
        };

        const res = await errorHandler(updateDepartmentInfo, param);
        if (res) {
            const cb = () => onClickCancel(e);
            const text = `<p>${translation.success_update}</p>`;
            success(text, cb);
        }
    };

    const customValidation = (option) => {
        if (option.value !== undefined && option.value !== null) {
            return true;
        } else {
            return false;
        }
    };

    const dropdownRule = <CustomRule type={'custom'} validationCallback={customValidation} />;

    return (
        <>
            <div className="department-management-info-form">
                <div className="right-content-title">
                    <PageTitle pageState={title} />
                </div>
                <form onSubmit={actionDirect}>
                    <div className="right-content-body">
                        <div className="container">
                            <div className="dx-fieldset">
                                <div className="dx-field">
                                    <div className="dx-field-label">
                                        <p>{translation.user_department_path}</p>
                                    </div>
                                    <div className="dx-field-value">
                                        <TreeListPath path={location.state?.path} />
                                    </div>
                                </div>
                                <div className="dx-field">
                                    <div className="dx-field-label">
                                        <p className="essential">{translation.user_department_name}</p>
                                    </div>
                                    <div className="dx-field-value">
                                        <TextBox className="ix-tfs-r" name="user_department_name" value={deptData?.user_department_name} onValueChanged={(e) => onValueChanged(e, 'user_department_name')} onEnterKey={moveFocus} inputAttr={{ focusidx: 0, autofocus: true }}>
                                            <Validator>
                                                <RequiredRule message={translation.user_department_name + translation.is_required} />
                                            </Validator>
                                        </TextBox>
                                    </div>
                                </div>
                                <DropdownField
                                    dataSource={useItems}
                                    displayExpr="title"
                                    onEnterKey={moveFocus}
                                    onValueChanged={(e) => onValueChanged(e, 'user_department_is_active')}
                                    isEssential={true}
                                    inputAttr={{ focusidx: 1 }}
                                    isSearchable={false}
                                    label={translation.user_department_is_active}
                                    validationRules={dropdownRule}
                                    value={deptData?.user_department_is_active}
                                    valueExpr="value"
                                />
                                {/*<div className="dx-field">*/}
                                {/*    <div className="dx-field-label"><p>{translation.user_department_comment}</p></div>*/}
                                {/*    <div className="dx-field-value">*/}
                                {/*        <TextBox className="ix-tfs-r"*/}
                                {/*            name="user_department_comment"*/}
                                {/*            value={deptData?.user_department_comment}*/}
                                {/*            onValueChanged={e => onValueChanged(e, "user_department_comment")}*/}
                                {/*            onEnterKey={e => document.querySelector('#submitBtn').click()}*/}
                                {/*            inputAttr={{ focusidx: 2 }}*/}
                                {/*        />*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div className="dx-field">
                                    <div className="dx-field-label">
                                        <p>{translation.user_department_comment}</p>
                                    </div>
                                    <div className="dx-field-value">
                                        {/* <TextArea name="user_department_comment" value={deptData?.user_department_comment} onValueChanged={(e) => onValueChanged(e, 'user_department_comment')} onEnterKey={(e) => document.querySelector('#submitBtn').click()} inputAttr={{ focusidx: 2 }} /> */}
                                        <TextArea name="user_department_comment" value={deptData?.user_department_comment} onValueChanged={(e) => onValueChanged(e, 'user_department_comment')} inputAttr={{ focusidx: 2 }} />
                                    </div>
                                </div>
                            </div>
                            <div className="block"></div>
                            {title == 'Edit' && (
                                <div>
                                    <div className="dx-fieldset write-info">
                                        <div className="dx-field">
                                            <div className="dx-field-label">{translation.lastModifiedDate}</div>
                                            <div className="dx-field-value">{deptData?.modified_date}</div>
                                        </div>
                                        <div className="dx-field">
                                            <div className="dx-field-label">{translation.lastModifiedBy}</div>
                                            <div className="dx-field-value">
                                                {deptData?.modified_by_name}
                                                {deptData?.modified_by_duty && <span className="badge badge-job-position">{deptData?.modified_by_duty}</span>}
                                                {deptData?.modified_by_department && <span className="badge badge-team">{deptData?.modified_by_department}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="block"></div>
                                </div>
                            )}
                            <div className="btn-group dx-fieldset">
                                <Button type="normal" stylingMode="text" className="btn-s-r" onClick={onClickCancel}>
                                    <div>
                                        <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                        <span className="dx-button-text">{translation.cancel}</span>
                                    </div>
                                </Button>
                                <Button
                                    id="submitBtn"
                                    type="default"
                                    stylingMode="contained"
                                    className="btn-s-r"
                                    useSubmitBehavior={true}
                                    onClick={(e) => {
                                        if (location.state.title.toLowerCase() === 'add') submit(e, onRowInserting);
                                        else if (location.state.title.toLowerCase() === 'edit') submit(e, onRowUpdating);
                                    }}
                                >
                                    <div>
                                        <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                        <span className="dx-button-text">{translation.save}</span>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default DepartmentManagementInfoForm;
