import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'devextreme-react/button';
import TextBox from 'devextreme-react/text-box';
import { CheckBox } from 'devextreme-react/check-box';
import { ValidationGroup } from 'devextreme-react/validation-group';
import { Validator, RequiredRule, EmailRule } from 'devextreme-react/validator';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import LanguageHandler from '../../components/LanguageHandler';
import { useSwal } from '../../components/common/Swal';
import { getIP, getLanguage, removeUserSession, saveIPAddress, setUserSession, setUserLocal, removeUserLocal, getUser } from '../../apiInterface/utils/Common';
import { signin, forgotPasswordAPI } from '../../apiInterface/account/LoginAPI';
import '../../contents/styles/account/login.css';
import useTranslation from '../../components/customHooks/translations';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import FindPasswordPopup from './FindPasswordPopup';

const Login = (props) => {
    const translation = useTranslation();
    const loginValidRef = useRef();
    const intialValues = { email: '', password: '', ip: '' };
    const [formValues, setFormValues] = useState(intialValues);
    const [isLogging, setIsLogging] = useState(false);
    const [isFindingPW, setIsFindingPW] = useState(false);
    const [isSave, setIsSave] = useState(false);
    const [language, setLanguage] = useState('ko');
    const { success } = useSwal();
    const { fail } = useSwal();
    const errorHandler = useErrorHandler();
    const user = getUser();

    //input change handler
    const handleChange = (e) => {
        const { name, value } = e.event.target;
        setFormValues((cur) => ({ ...cur, [name]: value }));
    };

    const navigate = useNavigate();
    const [isPopupVisible, setPopupVisibility] = useState(false);

    //init
    useEffect(() => {
        // const sessionStorLang = getLanguage();
        const localStorLang = getLanguage();

        // reset sessionStorage
        // when user try to logout in another service not framework, then need to reset the user session
        // removeUserSession();
        removeUserLocal();

        // set Language
        // if (sessionStorLang) {
        //     sessionStorage.setItem('lang', sessionStorLang);
        //     setLanguage(sessionStorLang);
        // } else {
        //     const prevLang = localStorage.getItem('previousLang');
        //     sessionStorage.setItem('lang', prevLang);
        //     setLanguage(prevLang);
        // }
        if (localStorLang) {
            // sessionStorage.setItem('lang', sessionStorLang);
            localStorage.setItem('lang', localStorLang);
            setLanguage(localStorLang);
        } else {
            const prevLang = localStorage.getItem('previousLang');
            localStorage.setItem('lang', prevLang);
            setLanguage(prevLang);
        }

        //ip address storing
        saveIPAddress();

        // set id & pw
        const id = localStorage.getItem('previousId');
        const pw = localStorage.getItem('previousPw');
        let lang = localStorage.getItem('previousLang');
        if (id && pw) {
            setIsSave(true);
            setFormValues({
                ...formValues,
                email: id,
                password: pw,
            });
        }
    }, []);

    const findPassword = () => {
        setPopupVisibility(!isPopupVisible);
    };

    //handle forgot password test
    const handleForgotPassword = async (email) => {
        setIsFindingPW(true);
        await forgotPassword(email);
        setIsFindingPW(false);
    };

    const forgotPassword = async (email) => {
        var ip = getIP();
        var data = { email: email, ip: ip };
        const res = await errorHandler(forgotPasswordAPI, data);

        if (res) {
            setPopupVisibility(false);
            success(`<span>${email}</span><p>${translation.success_forgot_pwd}</p>`);
        } else {
            setPopupVisibility(false);
            var swalResFn = () => {
                setPopupVisibility(true);
            };
            fail(`<p>${translation.invalid_email}</p>`, swalResFn);
        }
    };

    const validateLogin = async (e) => {
        let check = e.validationGroup.validate();
        if (check.isValid) {
            setIsLogging(true);
            await submit();
            setIsLogging(false);
        }
    };

    const submit = async () => {
        // formValues['ip'] = sessionStorage.getItem('ipData') || null;
        formValues['ip'] = localStorage.getItem('ipData') || null;
        if (formValues.ip == null) {
            formValues.ip = await saveIPAddress();
        }

        const res = await errorHandler(signin, formValues);
        if (res) {
            var swalResFn = (r) => {
                if (r.isConfirmed) {
                    let access_token = res.access_token;
                    // setUserSession(access_token, res.data);
                    setUserLocal(access_token, res.data);
                    navigate('/MainPortal', { replace: true });

                    //if save button is checked and login is success save data to local storage.
                    if (isSave) {
                        localStorage.setItem('previousId', formValues.email);
                        localStorage.setItem('previousPw', formValues.password);
                        // localStorage.setItem('previousLang', sessionStorage.getItem('lang'));
                        localStorage.setItem('previousLang', localStorage.getItem('lang'));
                    } else {
                        localStorage.removeItem('previousId');
                        localStorage.removeItem('previousPw');
                        localStorage.removeItem('previousLang');
                    }
                }
            };
            success(`<span>${formValues['email']}</span><p>${translation.confirm_signIn}</p>`, swalResFn);
        } else fail(`<p>${translation.signIn_failed}<p>`);
    };

    const onEnterKeyLogin = (e) => {
        const loginBtn = document.querySelector('#loginBtn');
        loginBtn.click();
    };

    return (
        <div className="dx-swatch-ix-pds login">
            <div className="dx-card">
                <div className="card-img">
                    {/* <h1 className={`logo lnf`}></h1> */}
                    <h1 className={`logo`}></h1>
                    <div className="img-login-box">
                        <div className="img-login"></div>
                    </div>
                </div>
                <div className="login-field">
                    <div className="card-header">
                        <p className="card-title">{translation.signIn}</p>
                        <div className="dx-field">
                            <div className="dx-field-label">
                                <i className="dx-icon-m-global"></i>
                            </div>
                            <div className="dx-field-value" style={{ width: 112 }}>
                                <LanguageHandler value={language} onChange={(val) => setLanguage(val)} />
                            </div>
                        </div>
                    </div>
                    <ValidationGroup ref={loginValidRef}>
                        <div className="card-body">
                            <div className="dx-fieldset">
                                <div className="dx-field column">
                                    <div className="dx-field-label">{translation.id}</div>
                                    <div className="dx-field-value">
                                        <TextBox onEnterKey={onEnterKeyLogin} name="email" value={formValues.email} onChange={handleChange} placeholder={translation.email} className="ix-tfs-r">
                                            <Validator>
                                                <RequiredRule message={translation.email + translation.is_required} />
                                                <EmailRule message={translation.email + translation.is_invalid} />
                                            </Validator>
                                        </TextBox>
                                    </div>
                                </div>
                                <div className="dx-field column">
                                    <div className="dx-field-label">{translation.password}</div>
                                    <div className="dx-field-value">
                                        <TextBox onEnterKey={onEnterKeyLogin} name="password" mode="password" value={formValues.password} onChange={handleChange} placeholder={translation.password} className="ix-tfs-r">
                                            <Validator>
                                                <RequiredRule message={translation.password + translation.is_required} />
                                            </Validator>
                                        </TextBox>
                                    </div>
                                </div>
                                <div className="dx-field checkbox">
                                    <div className="dx-field-value">
                                        <CheckBox value={isSave} iconSize={16} text={translation.save} className="ix-tfs-r" icon="todo" onValueChanged={(e) => setIsSave(e.value)} />
                                    </div>
                                    <p onClick={findPassword}>{translation.forgotPassword}</p>
                                </div>
                            </div>
                            <div>
                                <Button id="loginBtn" width="100%" type="default" stylingMode="contained" className="btn-s-r" disabled={isLogging} onClick={validateLogin} useSubmitBehavior={true}>
                                    <div className="buttonloader">
                                        <LoadIndicator className="button-indicator" visible={isLogging} />
                                        <span className="dx-button-text">{translation.signIn}</span>
                                    </div>
                                </Button>
                            </div>
                            <div className="block"></div>
                            <div>
                                <Button
                                    width="100%"
                                    text={translation.signUp}
                                    stylingMode="contained"
                                    type="default"
                                    className="btn-s-r dx-button-special //dx-button-mode-es"
                                    onClick={() => {
                                        navigate('../SelectUserType');
                                    }}
                                />
                            </div>
                        </div>
                    </ValidationGroup>
                </div>
            </div>
            <FindPasswordPopup onClose={() => setPopupVisibility(false)} onSubmit={handleForgotPassword} visible={isPopupVisible} pending={isFindingPW} />
            <div className="copywrite">
                <p>&copy; INTERX</p>
                {/* <p>&copy; ㈜엘앤에프</p> */}
            </div>
        </div>
    );
};

export default Login;
