// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup-select-item .dx-fieldset {
    border-bottom: 1px solid var(--border);
    padding-bottom: 24px !important;
}`, "",{"version":3,"sources":["webpack://./src/contents/styles/badtype/bad-type-management-info-form.css"],"names":[],"mappings":"AAAA;IACI,sCAAsC;IACtC,+BAA+B;AACnC","sourcesContent":[".popup-select-item .dx-fieldset {\n    border-bottom: 1px solid var(--border);\n    padding-bottom: 24px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
