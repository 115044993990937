// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../image/icon-filter.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup-product-group .dx-fieldset {
    display: flex;
    padding: 24px;
    border-bottom: 1px solid var(--border);
}
.popup-product-group .dx-fieldset .dx-field:first-child {
    margin-right: 20px;
}
.popup-product-group .dx-field-value .dx-texteditor.dx-textbox .dx-placeholder:before {
    font-size: 14px;
    padding: 12px 0px 12px 36px;
}
.popup-product-group .dx-field:last-child .dx-field-value .dx-texteditor.dx-textbox .dx-texteditor-input {
    padding: 12px 0px 12px 32px;
    background: 8px / 24px url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat;
}
/*.popup-product-group .dx-field-value .dx-texteditor.dx-textbox .dx-placeholder {
    background: 8px / 24px url("../../image/icon-filter.svg") no-repeat;
}*/
.selected-product-group {
    margin-bottom: 12px;
}
.selected-product-group span:first-child {
    color: var(--inactive-text);
    font-weight: normal;
}
.selected-product-group span:not(first-child) {
    color: var(--primary-500);
    font-weight: bold;
}
.popup-product-group .info-comment {
    margin-top: 12px;
}
.popup-product-group .dx-datagrid {
    max-height: 708px;
}
`, "",{"version":3,"sources":["webpack://./src/contents/styles/item/item-master-form.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,sCAAsC;AAC1C;AACA;IACI,kBAAkB;AACtB;AACA;IACI,eAAe;IACf,2BAA2B;AAC/B;AACA;IACI,2BAA2B;IAC3B,wEAAmE;AACvE;AACA;;EAEE;AACF;IACI,mBAAmB;AACvB;AACA;IACI,2BAA2B;IAC3B,mBAAmB;AACvB;AACA;IACI,yBAAyB;IACzB,iBAAiB;AACrB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,iBAAiB;AACrB","sourcesContent":[".popup-product-group .dx-fieldset {\n    display: flex;\n    padding: 24px;\n    border-bottom: 1px solid var(--border);\n}\n.popup-product-group .dx-fieldset .dx-field:first-child {\n    margin-right: 20px;\n}\n.popup-product-group .dx-field-value .dx-texteditor.dx-textbox .dx-placeholder:before {\n    font-size: 14px;\n    padding: 12px 0px 12px 36px;\n}\n.popup-product-group .dx-field:last-child .dx-field-value .dx-texteditor.dx-textbox .dx-texteditor-input {\n    padding: 12px 0px 12px 32px;\n    background: 8px / 24px url(\"../../image/icon-filter.svg\") no-repeat;\n}\n/*.popup-product-group .dx-field-value .dx-texteditor.dx-textbox .dx-placeholder {\n    background: 8px / 24px url(\"../../image/icon-filter.svg\") no-repeat;\n}*/\n.selected-product-group {\n    margin-bottom: 12px;\n}\n.selected-product-group span:first-child {\n    color: var(--inactive-text);\n    font-weight: normal;\n}\n.selected-product-group span:not(first-child) {\n    color: var(--primary-500);\n    font-weight: bold;\n}\n.popup-product-group .info-comment {\n    margin-top: 12px;\n}\n.popup-product-group .dx-datagrid {\n    max-height: 708px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
