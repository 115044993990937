import React, { useEffect, useRef, useState } from "react";
import Button from 'devextreme-react/button';
import useTranslation from "../../components/customHooks/translations";
import { getUser, getIP, getLanguage } from '../../apiInterface/utils/Common';
import { getBusinessIds, getProcessInfo } from "../../apiInterface/process/ProcessAPI";
import useErrorHandler from "../../components/customHooks/useErrorHandler";
import { DropdownField, FilterComponent } from "../../components/common/FormField";
import BaseDataGrid from "../../components/common/BaseDataGrid";
import CustomStore from "devextreme/data/custom_store";
import { Popup, ScrollView } from "devextreme-react";


let searchText = "";
export default function AddGroupPopup(props) {
    const translation = useTranslation();
    const gridRef = useRef();
    const errorHandler = useErrorHandler();
    const language = getLanguage();
    const [selectedData, setSelectedData] = useState([]);
    const [curBusinessId, setCurBusinessId] = useState();
    const [businessIds, setBusinessIds] = useState([]);
    const [dataSource, setDataSource] = useState([]);

    const {
        title,
        onClickOk,
        onExit: onExitCallback = () => { },
        visible,
    } = props;

    const headers = [
        { dataField: "operation_process_name", caption: translation.operation_process_name.toUpperCase(), dataType: "string", allowReordering: true },
        { dataField: "operation_process_code", caption: translation.operation_process_code.toUpperCase(), dataType: "string", allowReordering: true },
        { dataField: "operation_process_comment", caption: translation.operation_process_comment.toUpperCase(), dataType: "string", allowReordering: true },
    ];

    //init
    useEffect(() => {
        searchText = "";
        const initDropdown = async () => {
            const user = getUser();
            let param = { user_company_id: user.user_company_id }
            const res = await errorHandler(getBusinessIds, param);
            if (res) {
                setBusinessIds(res.data.o_data);
                if (res.data.o_data.length > 1) {
                    let isBusinessExist = false;
                    if (user.user_business_id != null) {
                        const branch = res.data.o_data.find((branch) => branch.user_business_id == user.user_business_id);
                        if (branch != null) {
                            setCurBusinessId(user.user_business_id);
                            isBusinessExist = true;
                        }
                    }

                    //if user info doesn't have business or server's response doesn't have user's business set first row as a default value.
                    if (!isBusinessExist) {
                        setCurBusinessId(res.data.o_data[0].user_business_id);
                    }
                }
            }
        }
        initDropdown();
    }, []);

    const ok = (e) => {
        const instance = gridRef.current.getInstance();
        const selection = instance.getSelectedRowsData();
        onClickOk(selection);
        onExit(e);
    }

    const onExit = (e) => {
        const instance = gridRef.current.getInstance();
        instance.deselectAll();
        setSelectedData([]);
        onExitCallback(e);
    }

    const searchClicked = async (text) => {
        searchText = text;
        makeCustomStore();
    }

    const onSelectionChanged = (val) => { 
        setSelectedData(val);
        console.log(selectedData)
     }

    const onValueChanged = (e) => {
        setCurBusinessId(e.value);
        makeCustomStore(e.value);
    }

    const makeCustomStore = (businessId = curBusinessId) => {
        const customStore = new CustomStore({
            load: async (loadOptions) => {
                if (loadOptions["take"] == undefined)
                    return;

                const user = getUser();
                let pageNo = parseInt(loadOptions["skip"] / loadOptions["take"]) + 1;
                let param = {
                    user_company_id: user.user_company_id,
                    sys_lang_code: language,
                    user_business_id: businessId,
                    row_count: loadOptions['take'],
                    page_no: pageNo,
                    search_text: searchText
                }

                const res = await errorHandler(getProcessInfo, param);
                if (res) {
                    return {
                        totalCount: res.data.count,
                        data: res.data.o_data,
                    };
                } else {
                    return {
                        totalCount: 0,
                        data: []
                    }
                }
            }
        });
        setDataSource(customStore);
    }

    // const dropdown = <DropdownField
    //     label={translation.select_business}
    //     value={curBusinessId}
    //     dataSource={businessIds}
    //     onValueChanged={onValueChanged}
    //     displayExpr="user_business_name"
    //     valueExpr="user_business_id"
    // />

    // const onFilter = (keyword) => {
    //     searchText = keyword;
    //     makeCustomStore();
    // }

    // const filter = <FilterComponent
    //     label={translation.filter}
    //     width={204}
    //     onFilter={onFilter}
    // />

    const dropdown = <DropdownField
        label={translation.select_business}
        value={curBusinessId}
        dataSource={businessIds}
        onValueChanged={onValueChanged}
        displayExpr="user_business_name"
        valueExpr="user_business_id"
    />

    const filter = <FilterComponent
        isWritable={true}
        label={translation.filter}
        onFilter={(text) => searchClicked(text)}
        text={searchText}
    />

    const removeTag = (i) => {
        let cur = [...selectedData];
        let removed = cur.splice(i, 1);
        setSelectedData(cur);

        const instance = gridRef.current.getInstance();
        instance.deselectRows(removed);
    }

    const removeAllTags = () => {
        setSelectedData([])

        const instance = gridRef.current.getInstance();
        instance.deselectAll();
    }

    return (
        <Popup
            width={1100}
            height={850}
            visible={visible}
            hideOnOutsideClick={true}
            onHiding={onExit}
            dragEnabled={true}
            position="center"
            showTitle={false}
        >
            <div className="popup-select-process">
                <div className="popup-header">
                    <p className="popup-title">{title}</p>
                </div>
                <div className="popup-body">
                    <div className="selected-item-viewer">
                        <div className="icon-checked">
                            <i></i>
                        </div>
                        <div className="selected-item-field">
                            {selectedData.length > 0 &&
                                <>
                                    <ul className="selected-items">
                                        {
                                            selectedData.map((e, i) =>
                                                <li key={i} className="item">
                                                    <p className="item-name">{e.operation_process_name}</p>
                                                    <i className="material-icons btn-delete" onClick={() => removeTag(i)}>cancel</i>
                                                </li>
                                            )
                                        }
                                    </ul>
                                    <div className="btn-all-delete" onClick={removeAllTags}>
                                        <button>Delete All</button>
                                    </div>
                                </>
                            }
                            {selectedData.length == 0 &&
                                <p className="comment-not-selected">{translation.select_process_notice}</p>
                            }
                        </div>
                    </div>
                    <div className="item-select-box">
                        <ScrollView>
                            <div className="container-wrapper">
                                <div className="container">
                                    <BaseDataGrid
                                        toolbarModules={[dropdown, filter]}
                                        dataSource={dataSource}
                                        ref={gridRef}
                                        selectMode="multiple"
                                        allowModify={false}
                                        isRemote={true}
                                        onSelectionChanged={onSelectionChanged}
                                        headers={headers}
                                        isPaging={false}
                                    />
                                </div>
                            </div>
                        </ScrollView>
                    </div>
                </div>
                <div className="popup-footer">
                    <Button
                        width={86}
                        text={translation.cancel}
                        type="normal"
                        stylingMode="text"
                        className="btn-s-r"
                        onClick={onExit}
                    />
                    <Button
                        text={translation.confirm}
                        width={86}
                        type="default"
                        stylingMode="contained"
                        className="btn-s-r"
                        onClick={ok}
                    />
                </div>
            </div>
        </Popup>
    )
}