import React, { useState, useEffect } from 'react';
import { Button, Popup } from 'devextreme-react';
import DraggableTreeList from '../../components/common/DraggableTreeList';
import useTranslation from '../../components/customHooks/translations';
import { getIP, getLanguage, getMenuMappingInfo, getUser } from '../../apiInterface/utils/Common';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import { useRef } from 'react';
import Badge from '../../components/common/Badge';
import {
    deleteDefectTypeCategory,
    getMainDefectTypeCategory,
    updateDefectTypeCategorySeq,
    // updateDefectTypeCategory,
} from '../../apiInterface/inspection/InspectionDefectType';
import InspectionDefectCategoryEditPopup from './InspectionDefectCategoryEditPopup';

let isCancelButtonClicked = false; // prevent executing onExit function twice.
export default function InspectionDefectCategorySettingPopup(props) {
    const { visible, onExit: exitCallback = () => {}, onOk: okCallback = () => {}, data } = props;

    const [editPopup, setEditPopup] = useState(false);
    const [editProp, setEditProp] = useState({});
    const [treeData, setTreeData] = useState([]);
    const [hideOnOutsideClick, setHideOnOutsideClick] = useState(true);
    const treeListRef = useRef();
    const translation = useTranslation();
    const user = getUser();
    const language = getLanguage();
    const ip = getIP();
    const { id: mappingId } = getMenuMappingInfo();
    const errorHandler = useErrorHandler();

    const [newReorder, setNewReorder] = useState([]);
    const [reorder, setReorder] = useState();
    const [reorderArr, setReorderArr] = useState([]);

    const header = [
        {
            dataField: 'name',
            caption: translation.name.toUpperCase(),
            allowReordering: true,
        },
        {
            dataField: 'state',
            caption: translation.state.toUpperCase(),
            allowReordering: true,
            width: 130,
            alignment: 'center',
            cellRender: (e) => <Badge className={e.value ? 'grid-badge-use' : 'grid-badge-disabled'} text={e.value ? translation.active : translation.not_active} />,
        },
    ];

    // get CategoryList
    const getMainDefectTypeCategoryList = async () => {
        let param = {
            user_company_id: user.user_company_id,
            sys_lang_code: language,
            search_text: '',
        };

        const res = await errorHandler(getMainDefectTypeCategory, param);
        if (res) {
            const transformed = res.data.o_data.map((d) => ({
                id: d.defect_type_category_id,
                name: d.defect_type_category_name,
                parentId: d.defect_type_category_parent_id,
                state: d.defect_type_category_is_active,
                icon: 'activefolder',
            }));
            setTreeData(transformed);
        }
    };

    const prepareEdit = (state) => {
        setEditProp({
            subtitle: state.title,
            path: state.path,
            itemId: state.itemId,
        });
        setEditPopup(true);
    };

    const removeCategory = async (item) => {
        const { id: menuMappingId } = getMenuMappingInfo();
        const data = item.data;
        const user = getUser();
        let param = {
            user_master_id: user.user_master_id,
            user_log_ip: getIP(),
            menu_mapping_id: menuMappingId,
            defect_type_category_id: data.id,
        };

        const res = await errorHandler(deleteDefectTypeCategory, param);
        if (res) {
            //reload treeList data
            await getMainDefectTypeCategoryList();
        }
    };

    const onExit = (e) => {
        const instance = treeListRef.current.getInstance();
        instance.deselectAll();
        treeListRef.current.reset();

        // reload
        getMainDefectTypeCategoryList();
        exitCallback(e);
    };

    const onOk = (e) => {
        e.event.preventDefault();
        okCallback(e);
        onExit(e);
    };

    const onCancel = (e) => {
        e.event.preventDefault();
        isCancelButtonClicked = true;
        // setReorderArr([]);
        onExit(e);
    };

    const onHiding = (e) => {
        if (!isCancelButtonClicked) onExit(e);
        isCancelButtonClicked = false;
    };

    useEffect(() => {
        if (newReorder !== undefined && newReorder.length !== 0) {
            reorderCategory(newReorder);
        }
    }, [newReorder]);

    const reorderCategory = async (newReorder) => {
        newReorder.map(async (m) => {
            let param = {
                defect_type_category_id: m.id,
                defect_type_category_parent_id: m.parentId,
                defect_type_category_top_id: m.parentId,
            };

            const res = await errorHandler(updateDefectTypeCategorySeq, param);
            if (res) {
                return true;
            }
            return false;
        });
    };

    return (
        <Popup width={650} height="auto" visible={visible} hideOnOutsideClick={hideOnOutsideClick} onHiding={onHiding} onInitialized={getMainDefectTypeCategoryList} dragEnabled={true} position="center" showTitle={false}>
            <div className="popup-template-setting">
                <div className="popup-header">
                    <p className="popup-title">
                        {/* {translation.template_classification}{' '} */}
                        {translation.setting}
                    </p>
                </div>
                <div className="popup-body">
                    <div>
                        <div>
                            <DraggableTreeList
                                ref={treeListRef}
                                dataSource={treeData}
                                keyExpr="id"
                                displayExpr="name"
                                parentIdExpr="parentId"
                                headers={header}
                                onEdit={(val) => prepareEdit(val)}
                                onAdd={(val) => prepareEdit(val)}
                                isRemote={true}
                                onRowRemoving={removeCategory}
                                allowReordering={true}
                                beforeRemove={() => setHideOnOutsideClick(false)}
                                afterRemove={() => setHideOnOutsideClick(true)}
                                onReorder={setReorder}
                                newDataSource={setNewReorder}
                            />
                        </div>
                        <div>
                            <p className="info-comment">
                                <span className="material-icons-outlined">info</span>
                                {translation.select_a_row_to_edit}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="popup-footer">
                    <Button width={86} text={translation.cancel} type="normal" stylingMode="text" className="btn-s-r" onClick={onCancel} />
                    <Button text={translation.confirm} width={86} type="default" stylingMode="contained" className="btn-s-r" onClick={onOk} />
                </div>

                <InspectionDefectCategoryEditPopup
                    visible={editPopup}
                    hideOnOutsideClick={true}
                    onHiding={() => setEditPopup(false)}
                    dragEnabled={true}
                    subtitle={editProp?.subtitle}
                    path={editProp?.path}
                    itemId={editProp?.itemId}
                    onExit={(btnType) => {
                        setEditPopup(false);
                        if (btnType === 'save') onExit();
                    }}
                />
            </div>
        </Popup>
    );
}
