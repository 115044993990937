import {
    makeTokenAxiosDeleteRequest,
    makeTokenAxiosGetRequest,
    makeTokenAxiosPostRequest,
    makeTokenAxiosPutRequest
} from "../utils/AxiosMask"


const baseUrl = 'item_category'
const getItemCategoryById = async (param) => {
    let res;
    const urlPath = `${baseUrl}/get_item_category_by_id`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : "fail";
}

const getSecuritySetting = async (param) => {
    let res;
    const urlPath = `${baseUrl}/get_security_setting`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : "fail";
}

const getSecurityMaster = async (param) => {
    let res;
    const urlPath = `${baseUrl}/get_security_master`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : "fail";
}

const getItemMenuMapping = async (param) => {
    let res;
    const urlPath = `${baseUrl}/get_item_menu_mapping`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : "fail";
}

const getItemCategory = async (param) => {
    let res;
    const urlPath = `${baseUrl}/`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : "fail";
}

const updateItemCategory = async (param) => {
    let res;
    const urlPath = `${baseUrl}/`;

    try {
        res = await makeTokenAxiosPutRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : "fail";
}

const insertItemCategory = async (param) => {
    let res;
    const urlPath = `${baseUrl}/`;

    try {
        res = await makeTokenAxiosPostRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : "fail";
}

const deleteItemCategory = async (param) => {
    let res;
    const urlPath = `${baseUrl}/`;

    try {
        res = await makeTokenAxiosDeleteRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : "fail";
}

const insertItemCategorySetting = async (param) => {
    let res;
    const urlPath = `${baseUrl}/insert_item_category_setting`;

    try {
        res = await makeTokenAxiosPostRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : "fail";
}

const updateItemCategorySeq = async (param) => {
    let res;
    const urlPath = `${baseUrl}/update_item_category_seq`;

    try {
        res = await makeTokenAxiosPutRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : "fail";
}

export {
    getItemCategoryById,
    getItemMenuMapping,
    getSecuritySetting,
    getSecurityMaster,
    getItemCategory,
    updateItemCategory,
    insertItemCategory,
    deleteItemCategory,
    insertItemCategorySetting,
    updateItemCategorySeq
}
