﻿import React, { useEffect, useState } from 'react';
import Button from 'devextreme-react/button';
import TextBox from 'devextreme-react/text-box';
import { EmailRule, CustomRule } from 'devextreme-react/validator';
import { getUser, getIP, getMenuMappingInfo, getLanguage } from '../../apiInterface/utils/Common';
import useTranslation from '../../components/customHooks/translations';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { useLocation, useNavigate } from 'react-router-dom';
import { updateUserInfo, insertUserInfo, getUserInfoById, getDuties } from '../../apiInterface/userManagement/UserManagementAPI';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import { TextboxField, DropdownField, DatePickerField } from '../../components/common/FormField';
import '../../contents/styles/userManagement/user-management-info-form.css';
import { actionDirect, moveFocus, submit } from '../../components/common/FormFuncWithEnter';
import { useSwal } from '../../components/common/Swal';
import FindDepartmentPopup from './FindDepartmentPopup';
import { PageTitle } from '../../components/common/PageTitle.jsx';
import TextArea from 'devextreme-react/text-area';
import DropdownWithAdd from '../../components/common/DropdownWithAdd';
import FileUploader from '../../components/common/FileUploader';

const UserManagementInfoForm = (props) => {
    const divisionCode = 'USERDUTY';
    const translation = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const [isPopupVisible, setPopupVisibility] = useState(false);
    const [rowData, setRowData] = useState(location.state.rowData);
    const [duties, setDuties] = useState([]);
    const [btnDisable, setBtnDisable] = useState(false);
    const title = location.state.title;
    const errorHandler = useErrorHandler();
    const language = getLanguage();
    const { success } = useSwal();
    const useItems = [
        { value: true, title: translation.work },
        { value: false, title: translation.leave },
    ];

    const findDepartment = () => {
        setPopupVisibility(true);
    };

    //init
    useEffect(() => {
        getDutyDropdown();
        if (location.state.title == 'Edit') {
            let userId = location.state.rowData.user_master_id;
            let param = {
                user_master_id: userId,
                sys_lang_code: language,
            };

            const fetching = async () => {
                const res = await errorHandler(getUserInfoById, param);
                if (res) {
                    res.data.o_data[0].modified_date = res.data.o_data[0].modified_date.split('T')[0];
                    res.data.o_data[0].user_master_id = userId;
                    setRowData(res.data.o_data[0]);
                }
            };
            fetching();
        } else {
            valueChanged(true, 'user_master_is_active');
        }
    }, []);

    const exitForm = () => {
        navigate('/UserManagementInfo', {
            state: { previousPage: location.pathname },
        });
    };

    const getDutyDropdown = async () => {
        const user = getUser();
        const param = {
            user_company_id: user.user_company_id,
            sys_division_code: divisionCode,
            sys_division_sub_lang_code: language,
        };

        const res = await errorHandler(getDuties, param);
        if (res) {
            setDuties(res.data.o_data);

            if (res.data.o_data.length > 0 && location.state.title !== 'Edit') {
                valueChanged(res.data.o_data[0].sys_division_sub_value, 'user_master_duty');
            }
        }
    };

    const popupConfirm = ({ path, selected }) => {
        let pathString = '';
        path.forEach((e) => (pathString += '/' + e.user_department_name));
        pathString = pathString.substring(1);

        valueChanged(pathString, 'user_department_path');
        valueChanged(selected.user_department_id, 'user_department_id');
        setPopupVisibility(false);
    };

    const onUpdate = async () => {
        const { id: mapping_id } = await getMenuMappingInfo();
        const user = getUser();
        const ip = getIP();
        let queryData = {
            user_master_created_by_id: rowData.user_master_id,
            user_master_id: user.user_master_id,
            user_log_ip: ip,
            user_company_id: user.user_company_id,
            user_menu_mapping_id: mapping_id,
            target_id: rowData.user_master_id,
            user_department_id: rowData.user_department_id,
            user_master_email: rowData.user_master_email,
            user_master_name: rowData.user_master_name,
            user_master_duty: rowData.user_master_duty,
            user_master_position: rowData.user_master_position,
            user_master_phone: rowData.user_master_phone,
            user_master_hired_date: rowData.user_master_hired_date,
            user_master_is_active: rowData.user_master_is_active,
            user_master_comment: rowData.user_master_comment,
        };

        const response = await errorHandler(updateUserInfo, queryData);
        if (response) {
            const text = `<p>${translation.success_update}</p>`;
            success(text, exitForm);
        }
        setBtnDisable(false);
    };

    const onInsert = async () => {
        const { id: mapping_id } = await getMenuMappingInfo();
        const user = getUser();
        const ip = getIP();
        const queryData = {
            user_master_id: user.user_master_id,
            user_log_ip: ip,
            user_company_id: user.user_company_id,
            user_menu_mapping_id: mapping_id,
            user_department_id: rowData.user_department_id,
            user_master_email: rowData.user_master_email,
            user_master_name: rowData.user_master_name,
            user_master_duty: rowData.user_master_duty,
            user_master_position: rowData.user_master_position,
            user_master_phone: rowData.user_master_phone,
            user_master_hired_date: rowData.user_master_hired_date,
            user_master_is_active: rowData.user_master_is_active,
            user_master_comment: rowData.user_master_comment,
        };

        const res = await errorHandler(insertUserInfo, queryData);
        if (res) {
            const text = `<p>${translation.success_insert}</p>`;
            success(text, exitForm);
        }

        setBtnDisable(false);
    };

    const valueChanged = (value, key) => {
        setRowData((cur) => {
            let newData = { ...cur };
            newData[key] = value;
            return newData;
        });
    };

    const customValidation = (option) => {
        if (option.value !== undefined && option.value !== null) {
            return true;
        } else {
            return false;
        }
    };

    const onSubmit = (e) => {
        setBtnDisable(true);
        if (btnDisable) return;

        const res = submit(e, location.state.title.toLowerCase() === 'add' ? onInsert : onUpdate);
        setBtnDisable(false);
        // if (!res) {
        //     setBtnDisable(false);
        // }

        // if (location.state.title.toLowerCase() === 'add') submit(e, onInsert);
        // else if (location.state.title.toLowerCase() === 'edit') submit(e, onUpdate);
    };

    const emailRule = <EmailRule message={translation.user_master_email + translation.is_invalid} />;
    // const phoneNumRule = <PatternRule message={translation.user_master_phone + translation.is_invalid} pattern="^[0-9]{10}$|^[0-9]{11}$" />
    const useOrNotRule = <CustomRule message={translation.user_master_is_work + translation.is_required} type={'custom'} validationCallback={(op) => customValidation(op)} />;

    return (
        <>
            <div className="user-management-info-form">
                <div className="right-content-title">
                    <PageTitle pageState={title} />
                </div>
                <form onSubmit={actionDirect}>
                    <div className="right-content-body">
                        <div className="container">
                            <div className="dx-fieldset">
                                {/* <FileUploader /> */}
                                <TextboxField
                                    label={translation.user_master_email}
                                    isEssential={true}
                                    essentialMsg={translation.user_master_email + translation.is_required}
                                    value={rowData?.user_master_email}
                                    onValueChanged={(e) => valueChanged(e.value, 'user_master_email')}
                                    validationRules={emailRule}
                                    readOnly={title == 'Edit' ? true : false}
                                    onEnterKey={moveFocus}
                                    inputAttr={{ focusidx: 0, autofocus: true }}
                                />

                                <TextboxField
                                    label={translation.user_master_name}
                                    isEssential={true}
                                    essentialMsg={translation.user_master_name + translation.is_required}
                                    value={rowData?.user_master_name}
                                    onValueChanged={(e) => valueChanged(e.value, 'user_master_name')}
                                    onEnterKey={moveFocus}
                                    inputAttr={{ focusidx: 1 }}
                                />

                                <DropdownWithAdd
                                    label={translation.user_master_duty}
                                    value={rowData?.user_master_duty}
                                    onValueChanged={(e) => valueChanged(e.value, 'user_master_duty')}
                                    isEssential={true}
                                    essentialMsg={translation.user_master_duty + translation.is_required}
                                    dataSource={duties}
                                    valueExpr="sys_division_sub_value"
                                    displayExpr="sys_division_sub_caption_text"
                                    onEnterKey={moveFocus}
                                    inputAttr={{ focusidx: 2 }}
                                    link="/Division"
                                />

                                <TextboxField label={translation.user_master_position} value={rowData?.user_master_position} onValueChanged={(e) => valueChanged(e.value, 'user_master_position')} onEnterKey={moveFocus} inputAttr={{ focusidx: 3 }} />

                                <DropdownField
                                    label={translation.user_master_is_work}
                                    value={rowData?.user_master_is_active}
                                    dataSource={useItems}
                                    valueExpr="value"
                                    displayExpr="title"
                                    isEssential={true}
                                    onValueChanged={(e) => valueChanged(e.value, 'user_master_is_active')}
                                    validationRules={useOrNotRule}
                                    onEnterKey={moveFocus}
                                    inputAttr={{ focusidx: 4 }}
                                    isSearchable={false}
                                />

                                <div className="dx-field">
                                    <div className="dx-field-label">
                                        <p>{translation.user_department_path}</p>
                                    </div>
                                    <div className="dx-field-value">
                                        <div className="search">
                                            <TextBox className="ix-tfs-r" readOnly={true} name="user_department_path" value={rowData?.user_department_path} onValueChanged={(e) => valueChanged(e.value, 'user_department_path')} onEnterKey={moveFocus}>
                                                <div className="clickable-textbox" onClick={findDepartment}></div>
                                            </TextBox>
                                            <Button icon="search" type="default" stylingMode="contained" className="btn-s-r dx-button-mode-es btn-only-icon" onClick={findDepartment} elementAttr={{ focusidx: 5 }} />
                                        </div>
                                    </div>
                                </div>

                                <TextboxField
                                    isEssential={true}
                                    essentialMsg={translation.user_master_phone + translation.is_required}
                                    label={translation.user_master_phone}
                                    value={rowData?.user_master_phone}
                                    onValueChanged={(e) => valueChanged(e.value, 'user_master_phone')}
                                    // validationRules={phoneNumRule}
                                    onEnterKey={moveFocus}
                                    inputAttr={{ focusidx: 6 }}
                                />

                                <DatePickerField
                                    label={translation.user_master_hired_date}
                                    value={rowData?.user_master_hired_date}
                                    isEssential={true}
                                    essentialMsg={translation.user_master_hired_date + translation.is_required}
                                    onValueChanged={async (_, val) => {
                                        await valueChanged(val, 'user_master_hired_date');
                                        document.querySelector('[focusidx="8"]').focus();
                                    }}
                                    pickerType="calendar"
                                    valueType="date"
                                    valueFormat="MM/dd/yyyy"
                                    useMask={true}
                                    invalidMsg={translation.user_master_hired_date + translation.is_invalid}
                                    inputAttr={{ focusidx: 7 }}
                                />

                                {/*<TextboxField*/}
                                {/*    label={translation.user_master_comment}*/}
                                {/*    value={rowData?.user_master_comment}*/}
                                {/*    onValueChanged={(e) => valueChanged(e.value, "user_master_comment")}*/}
                                {/*    onEnterKey={e => document.querySelector('#submitBtn').click()}*/}
                                {/*    inputAttr={{ focusidx: 8 }}*/}
                                {/*/>*/}
                                <div className="dx-field">
                                    <div className="dx-field-label">
                                        <p>{translation.user_master_comment}</p>
                                    </div>
                                    <div className="dx-field-value">
                                        <TextArea
                                            value={rowData?.user_master_comment}
                                            onValueChanged={(e) => valueChanged(e.value, 'user_master_comment')}
                                            // onEnterKey={e => document.querySelector('#submitBtn').click()}
                                            inputAttr={{ focusidx: 8 }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="block"></div>
                            {title == 'Edit' && (
                                <div>
                                    <div className="dx-fieldset write-info">
                                        <div className="dx-field">
                                            <div className="dx-field-label">{translation.lastModifiedDate}</div>
                                            <div className="dx-field-value">{rowData?.modified_date}</div>
                                        </div>
                                        <div className="dx-field">
                                            <div className="dx-field-label">{translation.lastModifiedBy}</div>
                                            <div className="dx-field-value">
                                                {rowData?.modified_by_name}
                                                {rowData?.modified_by_duty && <span className="badge badge-job-position">{rowData?.modified_by_duty}</span>}
                                                {rowData?.modified_by_department && <span className="badge badge-team">{rowData?.modified_by_department}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="block"></div>
                                </div>
                            )}
                            <div className="btn-group dx-fieldset">
                                <Button type="normal" stylingMode="text" className="btn-s-r" onClick={exitForm}>
                                    <div>
                                        <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                        <span className="dx-button-text">{translation.cancel}</span>
                                    </div>
                                </Button>
                                <Button id="submitBtn" type="default" stylingMode="contained" className="btn-s-r" useSubmitBehavior={true} onClick={(e) => onSubmit(e)} disabled={btnDisable}>
                                    <div>
                                        <LoadIndicator className="button-indicator" visible={btnDisable} width={20} height={20} />
                                        <span className="dx-button-text">{translation.save}</span>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
                <FindDepartmentPopup visible={isPopupVisible} onOK={popupConfirm} onClose={() => setPopupVisibility(false)} />
            </div>
        </>
    );
};

export default UserManagementInfoForm;
