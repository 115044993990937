import React, { useEffect, useState, useRef } from 'react';
import Button from 'devextreme-react/button';
import TreeList, { Column, Editing, RowDragging, Scrolling, Selection } from 'devextreme-react/tree-list';
import DataGrid, { Paging, Lookup, RequiredRule, Toolbar, Item, Pager } from 'devextreme-react/data-grid';
import { useSwal, swalConfirmation } from '../../components/common/Swal';
import { useLocation, useNavigate } from 'react-router-dom';
import useTranslation from '../../components/customHooks/translations';
import BtnTooltip from './BtnTooltip';
import { failToastr, successToastr } from '../../components/common/Toastr';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';

var selectionChangeTriggered = false;
var isCollapseBtn = false;
export default function DraggableTreeList(props, ref) {
    const {
        toolbarModules,
        keyExpr,
        parentIdExpr,
        displayExpr,
        selectMode = 'multiple',
        headers,
        dataSource = [],
        onDelete = () => {},
        allowReordering = false,
        allowModify = true,
        onMoveDown = () => {},
        onMoveDownExit = () => {},
        link = '',
        onSelect = () => {},
        onRowClick: rowClickCallback = () => {},
        onAdd: onAddCallback = () => {},
        onEdit: onEditCallback = () => {},
        onReorder: reorderCallback = () => {},
        gridStyle,
        isFullSize = false,
        rootValue = 0,
        allowAdd = true,
        allowEdit = true,
        allowDelete = true,
        maxLevel = -1, // if maxLevel is negative value, will not restrict level
        params = {},
        showCheckBox = true,
        allowReorderingOnly = false, //allow node move to different depth.
        allowRowSelectByClick = false, //allow selecting a row not only checkbox but also click a row.
        isDoubleClicked = false,
        width,
        showAdd = true,
        showEdit = true,
        showDelete = true,
        showMovedown = true,
        onCellHover: onCellHoverCallback = () => {},
        allowDragAlways = false,
        allowColumnResizing = true,
        beforeRemove = () => {},
        afterRemove = () => {},
        newDataSource,
    } = props;

    const [data, setData] = useState(dataSource); //data for treeList
    const [isEditMode, setEditMode] = useState(false); //let user reordering rows by dragging
    const [disableUndo, setDisableUndo] = useState(false);
    const [disableRedo, setDisableRedo] = useState(false);
    const [disableEdit, setDisableEdit] = useState(true);
    const [disableDelete, setDisableDelete] = useState(true);
    const [disableAdd, setDisableAdd] = useState(false);
    const [selectedDept, setSelectedRow] = useState(); //Selected department id in datagrid;
    const [rollback, setRollback] = useState({
        //save back-up data for cancel in edit mode.
        elements: [],
        parentIds: [],
    });
    const [buffer, setBuffer] = useState({
        //save history while user reordering rows. used for redo and undo.
        index: -1,
        history: [],
    });
    const treeRef = useRef();
    const navigate = useNavigate();
    const translation = useTranslation();
    const location = useLocation();
    const getInstance = () => {
        return treeRef?.current?.instance;
    };
    const originalParent = 'originalParent';
    const upperNode = 'upperNode';
    const { warning } = useSwal();

    useImperativeHandle(ref, () => ({
        getInstance: () => treeRef.current.instance,
        reset: reset,
        expandAll,
    }));

    useEffect(() => {
        if (dataSource != null) {
            expandAll();
        }
    }, [dataSource]);

    const expandAll = () => {
        setData(dataSource);
        const instance = getInstance();
        let copy = [...dataSource];
        //to reduce time complex create obj that key is id of data and value is value.
        let tree = {};
        copy.forEach((data) => {
            tree[data[keyExpr]] = data;
            data.hasChildren = false;
        });

        copy.forEach((data) => {
            let parentId = data[parentIdExpr];
            if (parentId !== 0) {
                if (tree[data[parentIdExpr]] != null) tree[data[parentIdExpr]].hasChildren = true;
            }
        });

        copy.forEach((data) => {
            if (data.hasChildren) {
                instance.expandRow(data[keyExpr]);
            }
        });
        isCollapseBtn = false;
    };

    const reset = () => {
        const instance = treeRef.current.instance;
        instance.deselectAll();
        cancelReordering();
    };

    const cellRender = (isBadge, value, captionForBadge, classNameForBadge) => {
        if (isBadge === true) return <div className="grid-badge">{value !== undefined && <span className={`${classNameForBadge[value]}`}>{captionForBadge[value]}</span>}</div>;
        else return value;
    };

    //Invoked when row is dragged and dropped
    const onReorder = (ev) => {
        const visibleRows = ev.component.getVisibleRows();
        let movingNode = ev.itemData;
        let newData = [...data];
        const sourceIndex = data.indexOf(movingNode);

        let movement = {
            isDropInside: ev.dropInsideItem,
            data: movingNode,
            previousIndex: sourceIndex,
            nextIndex: null,
            previousParentId: movingNode[parentIdExpr],
            nextParentId: null,
        };

        let isHierachyChaged = true; // indicate moving data's parent is changed.
        let targetNode = null; // the node at the location pointed by the moved node.
        if (ev.dropInsideItem) {
            targetNode = visibleRows[ev.toIndex];
            if (targetNode.data[keyExpr] === movingNode[parentIdExpr]) {
                // if target node is the parent of moving node, then the moving node is moved to the first child of own parent,
                // even thougth moving node is dropped into another node.
                isHierachyChaged = false;
            }
        } else {
            const toIndex = ev.fromIndex > ev.toIndex ? ev.toIndex - 1 : ev.toIndex;

            // if moving node is moved to the first toIndex will be -1 and targetNode is null.
            if (toIndex === -1) {
                isHierachyChaged = true;
            } else {
                targetNode = visibleRows[toIndex];
                if (targetNode.data[parentIdExpr] === movingNode[parentIdExpr]) {
                    // if both data's parent id are same then only sequence has changed within same parent.
                    isHierachyChaged = targetNode.isExpanded ? true : false;
                } else if (targetNode.data[keyExpr] === movingNode[parentIdExpr]) {
                    // if target node is the parent of moving node, then the moving node is moved to the first child of own parent,
                    // even thougth they have different parents.
                    isHierachyChaged = false;
                }
            }
        }

        if (isHierachyChaged) {
            if (allowReorderingOnly) {
                failToastr(translation.only_reordering);
                return;
            }

            if (targetNode == null) {
                // if moving node is moved to the first, targetNode is null.
                // set moving node's parent and upper node to 0
                movingNode[parentIdExpr] = 0;
                movement.nextParentId = 0;
                movingNode[upperNode] = 0;
                newData = [movingNode, ...newData.slice(0, sourceIndex), ...newData.slice(sourceIndex + 1)];
            } else {
                const parentNode = ev.component.getNodeByKey(movingNode[parentIdExpr]);
                if (parentNode.children.length == 1 && parentNode.children[0].data[keyExpr] === movingNode[keyExpr]) {
                    // after moving node has moved, if parent node is empty, set parent's expend state to close.
                    ev.component.collapseRow(parentNode.key);
                }

                if (ev.dropInsideItem || targetNode.isExpanded) {
                    movingNode[parentIdExpr] = targetNode.data[keyExpr];
                    movement.nextParentId = targetNode.data[keyExpr];
                    movingNode[upperNode] = newData[sourceIndex - 1][keyExpr];
                } else {
                    movingNode[parentIdExpr] = targetNode.data[parentIdExpr];
                    movement.nextParentId = targetNode.data[parentIdExpr];
                    movingNode[upperNode] = targetNode.data[keyExpr];
                }

                if (!ev.dropInsideItem) {
                    newData = [...newData.slice(0, sourceIndex), ...newData.slice(sourceIndex + 1)];
                    const targetIndex = newData.indexOf(targetNode.data) + 1;
                    newData = [...newData.slice(0, targetIndex), movingNode, ...newData.slice(targetIndex)];
                    movement.nextIndex = targetIndex;
                }
            }
            reorderCallback(movingNode);
        } else {
            //remove moving node from array and insert at behind of target node.
            newData = [...newData.slice(0, sourceIndex), ...newData.slice(sourceIndex + 1)];
            const targetIndex = newData.indexOf(targetNode.data) + 1;
            newData = [...newData.slice(0, targetIndex), movingNode, ...newData.slice(targetIndex)];

            movement.nextIndex = targetIndex;
            movingNode[upperNode] = newData[targetIndex - 1][keyExpr];
            reorderCallback(movingNode);
        }

        setData(newData);
        if (newDataSource !== undefined) {
            newDataSource(newData);
        }

        setBuffer({
            index: buffer.index + 1,
            history: buffer.index < 0 ? [movement] : [...buffer.history.slice(0, buffer.index + 1), movement],
        });
        setDisableUndo(false);
        setDisableRedo(true);
    };

    const undo = () => {
        let history = buffer.history[buffer.index];
        let index = buffer.index - 1;
        let temp_data = data;

        if (history.isDropInside) {
            history.data[parentIdExpr] = history.previousParentId;
            temp_data = [...temp_data.slice(0, history.previousIndex), history.data, ...temp_data.slice(history.previousIndex + 1)];
        } else {
            temp_data = [...temp_data.slice(0, history.nextIndex), ...temp_data.slice(history.nextIndex + 1)];
            temp_data = [...temp_data.slice(0, history.previousIndex), history.data, ...temp_data.slice(history.previousIndex)];
            if (history.nextParentId !== null) {
                history.data[parentIdExpr] = history.previousParentId;
            }
        }

        history.data[upperNode] = history.previousIndex > 0 ? temp_data[history.previousIndex - 1][keyExpr] : 0;
        reorderCallback(history.data);

        if (index < 0) {
            setDisableUndo(true);
        }
        setDisableRedo(false);
        setBuffer({
            index: index,
            history: buffer.history,
        });
        setData(temp_data);
    };

    const redo = () => {
        let history = buffer.history[buffer.index + 1];
        let index = buffer.index + 1;
        let temp_data = data;

        if (history.isDropInside) {
            history.data[parentIdExpr] = history.nextParentId;
            temp_data = [...temp_data.slice(0, history.previousIndex), history.data, ...temp_data.slice(history.previousIndex + 1)];
        } else {
            temp_data = [...temp_data.slice(0, history.previousIndex), ...temp_data.slice(history.previousIndex + 1)];
            temp_data = [...temp_data.slice(0, history.nextIndex), history.data, ...temp_data.slice(history.nextIndex)];
            if (history.nextParentId !== null) {
                history.data[parentIdExpr] = history.nextParentId;
            }
        }

        history.data[upperNode] = history.nextIndex > 0 ? temp_data[history.nextIndex - 1][keyExpr] : 0;
        reorderCallback(history.data);

        if (index >= buffer.history.length - 1) {
            setDisableRedo(true);
        }
        setDisableUndo(false);

        setBuffer({
            index: index,
            history: buffer.history,
        });
        setData(temp_data);
    };

    const onDragChange = (ev) => {
        const visibleRows = ev.component.getVisibleRows();
        const sourceNode = ev.component.getNodeByKey(ev.itemData[keyExpr]);
        let targetNode = visibleRows[ev.toIndex].node;

        //prevent user drop item in same place.
        while (targetNode && targetNode.data) {
            if (targetNode.data[keyExpr] === sourceNode.data[keyExpr]) {
                ev.cancel = true;
                break;
            }
            targetNode = targetNode.parent;
        }
    };

    const getUpperNodeId = (row) => {
        const instance = getInstance();
        const curNode = instance.getNodeByKey(row[keyExpr]);
        let indexInParent = curNode.parent.children.findIndex((child) => child === curNode);
        return indexInParent == 0 ? curNode.parent.key : curNode.parent.children[indexInParent - 1].key;
    };

    const onMoveDownClick = (e) => {
        if (!isEditMode) {
            onMoveDown();

            let list = [];
            let parent = [];
            let temp = [...data];
            temp.map((e) => {
                e[originalParent] = e[parentIdExpr];
            });
            setData(temp);

            for (let i = 0; i < data.length; i++) {
                list.push(data[i]);
                parent.push(data[i][parentIdExpr]);
            }

            setRollback({
                elements: list,
                parentIds: parent,
            });

            setDisableRedo(true);
            setDisableUndo(true);
            setEditMode(true);
        } else {
            let list = [];
            let parent = [];
            let temp = [...data];
            temp.map((e) => {
                e[originalParent] = e[parentIdExpr];
            });
            setData(temp);

            for (let i = 0; i < data.length; i++) {
                list.push(data[i]);
                parent.push(data[i][parentIdExpr]);
            }

            // setNewDataSource
            if (newDataSource !== undefined) {
                newDataSource(temp);
            }

            clearHistory();
            clearRollback();
            onMoveDownExit();
            setEditMode(false);
        }
    };

    const cancelReordering = () => {
        if (rollback.elements.length > 0) {
            for (let i = 0; i < rollback.elements.length; i++) {
                rollback.elements[i][parentIdExpr] = rollback.parentIds[i];
            }
            setData(rollback.elements);
        }
        setEditMode(false);
        clearHistory();
        clearRollback();
        onMoveDownExit();
    };

    const saveOnClick = () => {
        let changed = [];
        for (let i = 0; i < data.length; i++) {
            const upperId = getUpperNodeId(data[i]);
            if (upperId !== data[i][upperNode] || data[i][originalParent] !== data[i][parentIdExpr]) {
                data[i][upperNode] = upperId;
                changed.push(data[i]);
            }
        }

        reorderCallback(changed);
        onMoveDownExit();
        setEditMode(false);
    };

    //when add button clicked move to link(property) page
    const moveToAdd = (data) => {
        let state = {
            title: 'Add',
            itemId: 0,
            path: [],
            ...location.state,
            ...params,
        };

        // if selected data exist get data's id and path
        if (data != null && data.length > 0) {
            state.itemId = data[data.length - 1][keyExpr];
            state.path = getRoute(data[data.length - 1]);
        }

        onAddCallback(state);

        if (link !== '') navigate(`/${link}`, { state: state });
    };

    //when add button clicked move to link(property) page.
    const moveToEdit = (data) => {
        let state = {
            title: 'Edit',
            itemId: data[0][keyExpr],
            path: getRoute(data[0]),
            ...params,
        };

        onEditCallback(state);

        if (link !== '') navigate(`/${link}`, { state: state });
    };

    //Get node path from root.
    const getRoute = (dept) => {
        let path = [];
        let target = dept;
        let parentId;

        if (!dept) return;

        while (true) {
            path = [target[displayExpr], ...path];
            parentId = target[parentIdExpr];

            target = data.find((element) => element[keyExpr] === parentId);
            if (target) {
                continue;
            } else {
                break;
            }
        }

        return path;
    };

    //clear drag history
    const clearHistory = () => {
        setBuffer({
            index: -1,
            history: [],
        });
    };

    //clear back up data for cancel
    const clearRollback = () => {
        setRollback({
            elements: [],
            parentIds: [],
        });
    };

    // enable/disable edit, add, delete buttons depends on number of rows selected.
    const onSelectionChanged = (e) => {
        setSelectedRow(getInstance().getSelectedRowsData());

        if (e.selectedRowKeys.length > 0) {
            setDisableDelete(false);
        } else {
            setDisableDelete(true);
        }

        if (e.selectedRowKeys.length == 1) {
            setDisableEdit(false);
        } else {
            setDisableEdit(true);
        }

        if (e.selectedRowKeys.length == 1 || e.selectedRowKeys.length == 0) {
            setDisableAdd(false);
        } else {
            setDisableAdd(true);
        }

        selectionChangeTriggered = true;
        onSelect(e);
    };

    const onDeleteClick = (e) => {
        beforeRemove();
        confirmDelete();
    };

    //when delete button is clicked confirm to user and delete selected items
    const confirmDelete = () => {
        let isRecursiveDelete = false;
        for (let i = 0; i < selectedDept.length; i++) {
            if (selectedDept[i].hasChildren) {
                isRecursiveDelete = true;
                break;
            }
        }

        var cb = (res) => {
            if (res.isConfirmed) {
                const instance = getInstance();
                onDelete(selectedDept);

                selectedDept.forEach((node) => {
                    let idx = instance.getRowIndexByKey(node[keyExpr]);
                    instance.deleteRow(idx);
                    instance.refresh();
                });
                instance.deselectAll();
            }
            afterRemove();
        };

        warning(`<p>${isRecursiveDelete ? translation.confirm_recursive_delete : translation.confirm_delete}</p>`, cb);
    };

    //get all descendant of node.
    const getChild = (node) => {
        let rtn = [node];
        if (!node.hasChildren) return rtn;

        node.children.forEach((child) => {
            rtn.push(...getChild(child));
        });
        return rtn;
    };

    // select and deselect row when the row is clicked.
    const onRowClick = (e) => {
        const instance = getInstance();
        const { isSelected, key } = e;

        if (allowRowSelectByClick && !isCollapseBtn) {
            if (isSelected) {
                instance.deselectRows([key]);
                e.isSelected = false;
            } else {
                instance.selectRows([key], selectMode === 'multiple' ? true : false);
                e.isSelected = true;
            }
        }
        isCollapseBtn = false;
        rowClickCallback(e);
    };

    const onRowCollapsing = (e) => {
        isCollapseBtn = true;
    };
    const onRowExpanding = (e) => {
        isCollapseBtn = true;
    };
    const renderToolbar = () => {
        let toolbar = [];
        //const delimiter = <div className="block-toolbar"></div>
        if (Array.isArray(toolbarModules)) {
            for (let i = 0; i < toolbarModules.length; i++) {
                const comp = Object.assign({}, toolbarModules[i]);
                comp.key = `comp-${i + 1}`;
                toolbar.push(comp);

                //const bar = Object.assign({}, delimiter);
                //bar.key = `bar-${i + 1}`;
                //toolbar.push(bar);
            }

            //if (toolbar.length > 0)
            //    toolbar = toolbar.slice(0, -1);
        } else {
            toolbar.push(toolbarModules);
        }

        return toolbar;
    };

    //If row is double-clicked link to Edit page with clicked row info.
    const onRowDoubleClicked = (e) => {
        if (allowModify && isDoubleClicked != false) {
            const data = e.data;
            moveToEdit(data);
        }
    };

    const onCellHover = (e) => {
        onCellHoverCallback(e);
    };

    const getClass = () => {
        let datagridClass = 'data-grid ';
        if (isFullSize) {
            datagridClass += 'full-size-grid ';
        }

        switch (gridStyle) {
            case 'stripe':
                datagridClass += 'datagrid-stripe';
                break;
            case 'line':
                datagridClass += 'datagrid-line';
                break;
            case 'seamless':
                datagridClass += '';
                break;
            default:
                datagridClass += 'datagrid-line';
                break;
        }

        return datagridClass;
    };

    return (
        <div className={getClass()}>
            <div className="data-grid-toolbar">{renderToolbar()}</div>
            <TreeList
                width={width}
                keyExpr={keyExpr}
                parentIdExpr={parentIdExpr}
                dataSource={data}
                showRowLines={true}
                showBorders={true}
                columnAutoWidth={true}
                onRowInserted={props.onRowInserted}
                onRowInserting={props.onRowInserting}
                onRowRemoved={props.onRowRemoved}
                onRowRemoving={props.onRowRemoving}
                onRowUpdated={props.onRowUpdated}
                onRowUpdating={props.onRowUpdating}
                onRowPrepared={props.onRowPrepared}
                onInitNewRow={props.onInitNewRow}
                onRowDblClick={onRowDoubleClicked}
                onSelectionChanged={onSelectionChanged}
                onRowClick={onRowClick}
                ref={treeRef}
                onRowCollapsing={onRowCollapsing}
                onRowExpanding={onRowExpanding}
                remoteOperations="auto"
                dataStructure="plain"
                rootValue={rootValue}
                hoverStateEnabled={true}
                onCellHoverChanged={onCellHover}
                allowColumnResizing={allowColumnResizing}
            >
                <Scrolling mode="vertical" />
                {showCheckBox && <Selection mode="multiple" allowSelectAll={selectMode == 'single' ? false : true} />}

                <RowDragging onDragChange={onDragChange} onReorder={onReorder} allowDropInsideItem={allowDragAlways ? true : isEditMode} allowReordering={allowDragAlways ? true : isEditMode} showDragIcons={allowDragAlways ? true : isEditMode} />

                <Editing confirmDelete={false} />

                {headers?.map((val) => {
                    return (
                        <Column
                            key={val?.dataField}
                            dataField={val?.dataField}
                            caption={val?.caption}
                            allowEditing={val?.allowEditing}
                            dataType={val?.dataType}
                            width={val.width}
                            alignment={val.alignment}
                            fixed={val.isFixed}
                            allowReordering={val.allowReordering}
                            cellRender={val.cellRender != null ? val.cellRender : (e) => cellRender(val.isBadge, e.value, val.captionForBadge, val.classNameForBadge)}
                        />
                    );
                })}

                {allowModify && (
                    <Toolbar>
                        {allowReordering && showMovedown && (
                            <Item>
                                <div id="movedownBtn">
                                    <Button icon="ix-movedown" type="normal" stylingMode="text" className="btn-s-r" onClick={onMoveDownClick} />
                                </div>
                                <BtnTooltip target="#movedownBtn" text={translation.moveRow} />
                            </Item>
                        )}

                        {!isEditMode && showDelete && (
                            <Item>
                                <div id="removeBtn">
                                    <Button icon="trash" type="normal" stylingMode="text" className="btn-s-r btn-hover-red" disabled={disableDelete || !allowDelete} onClick={onDeleteClick} />
                                </div>
                                <BtnTooltip target="#removeBtn" text={translation.delete} />
                            </Item>
                        )}

                        {!isEditMode && showEdit && (
                            <Item>
                                <div id="editBtn">
                                    <Button
                                        className="btn-s-r"
                                        type="normal"
                                        stylingMode="contained"
                                        text={translation.edit}
                                        icon="edit"
                                        width={82}
                                        disabled={disableEdit || !allowEdit}
                                        onClick={() => {
                                            moveToEdit(selectedDept);
                                        }}
                                    />
                                </div>
                                <BtnTooltip target="#editBtn" text={translation.edit} />
                            </Item>
                        )}

                        {!isEditMode && showAdd && (
                            <Item>
                                <div id="addBtn">
                                    <Button
                                        type="default"
                                        stylingMode="contained"
                                        className="btn-s-r"
                                        icon="add"
                                        text={translation.add}
                                        width={80}
                                        disabled={disableAdd || !allowAdd}
                                        onClick={(e) => {
                                            moveToAdd(selectedDept);
                                        }}
                                    />
                                </div>
                                <BtnTooltip target="#addBtn" text={translation.add} />
                            </Item>
                        )}

                        {isEditMode && (
                            <Item>
                                <div id="undoBtn">
                                    <Button icon="undo" type="normal" stylingMode="text" className="btn-s-r" onClick={undo} disabled={disableUndo} />
                                </div>
                                <BtnTooltip target="#undoBtn" text={translation.undo} />
                            </Item>
                        )}

                        {isEditMode && (
                            <Item>
                                <div id="redoBtn">
                                    <Button icon="redo" type="normal" stylingMode="text" className="btn-s-r" onClick={redo} disabled={disableRedo} />
                                </div>
                                <BtnTooltip target="#redoBtn" text={translation.redo} />
                            </Item>
                        )}

                        {/*isEditMode &&
                    <Item>
                        <div title={translation.cancel}>
                            <Button
                                text="cancel"
                                icon="close"
                                type="normal"
                                stylingMode="contained"
                                className="btn-s-r"
                                width={104}
                                onClick={cancelReordering}
                            />
                        </div>
                    </Item>
                    */}

                        {/*isEditMode &&
                            <Item>
                                <div title={translation.save}>
                                    <Button
                                        text="save"
                                        icon="save"
                                        type="default"
                                        stylingMode="contained"
                                        className="btn-s-r"
                                        width={86}
                                        onClick={saveOnClick}
                                    />
                                </div>
                            </Item>
                        */}
                    </Toolbar>
                )}
            </TreeList>
        </div>
    );
}

DraggableTreeList = forwardRef(DraggableTreeList);
