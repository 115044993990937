import React, { useState, useRef } from "react";
import { ScrollView } from "devextreme-react";
import Button from "devextreme-react/button";
import Popup from "devextreme-react/popup";
import BaseDataGrid from "../../../components/common/BaseDataGrid";
import { FilterComponent } from "../../../components/common/FormField";
import useTranslation from '../../../components/customHooks/translations';
import { useEffect } from "react";


export default function SelectColumnsPopup(props) {
    const {
        visible,
        onClose = () => { },
        dataSource = [],
        value,
        paramterIdx,
        valueChanged = () => { },
    } = props;

    const translation = useTranslation();
    const [selectedList, setSelectedList] = useState([]);
    const [btnDisable, setBtnDisable] = useState(true);
    const gridRef = useRef();

    useEffect(() => {
        const instance = gridRef.current?.getInstance();
        setSelectedList([]);
        instance?.selectRows([]);
        if (value !== undefined) {
            const key = Object.keys(value)[0];
            if (value[key].name !== undefined) {
                setSelectedList([value[key]]);
                instance?.selectRows([value[key]]);
            }
        }
    }, [visible])

    const close = () => {
        deselectAll();
        onClose();
    }

    const deselectAll = () => {
        const instance = gridRef.current.getInstance();
        instance.deselectAll();
        setSelectedList([]);
        setBtnDisable(true);
    }

    const removeTag = (i) => {
        let cur = [...selectedList];
        let removed = cur.splice(i, 1);
        setSelectedList(cur);

        const instance = gridRef.current.getInstance();
        instance.deselectRows(removed);
    }

    const onSave = () => {
        valueChanged("params", selectedList[0]);
        close();
    }


    const filter = <FilterComponent label={translation.filter} width={204} />;
    return (
        <Popup
            width={1100}
            height={850}
            visible={visible}
            title="Select Columns"
            hideOnOutsideClick={true}
            onHiding={onClose}
        >
            <div className="popup-select-columns">
                <div className="popup-body">
                    <div className="selected-item-viewer">
                        <div className="icon-checked">
                            <i></i>
                        </div>
                        <div className="selected-item-field">
                            {
                                selectedList.map((col, i) => {
                                    return (
                                        <ul className="selected-items">
                                            <li className="item">
                                                <p className="item-name">{col.name}</p>
                                                <i className="material-icons btn-delete" onClick={() => removeTag(i)}>
                                                    cancel
                                                </i>
                                            </li>
                                        </ul>
                                    )
                                })
                            }

                            <div className="btn-all-delete" onClick={deselectAll}>
                                <button>{translation.delete_all}</button>
                            </div>
                        </div>
                    </div>
                    <div className="item-select-box">
                        <ScrollView>
                            <div className="container-wrapper">
                                <BaseDataGrid
                                    allowModify={false}
                                    toolbarModules={[filter]}
                                    dataSource={dataSource}
                                    selectMode="single"
                                    onSelectionChanged={(selected) => {
                                        if (selected != null) {
                                            setSelectedList([selected]);
                                            setBtnDisable(false);
                                        }
                                        else {
                                            setSelectedList([]);
                                            setBtnDisable(true);
                                        }
                                    }}
                                    ref={gridRef}
                                />
                            </div>
                        </ScrollView>
                    </div>
                </div>
                <div className="popup-footer">
                    <Button width={86} text={translation.cancel} type="normal" stylingMode="text" className="btn-s-r" onClick={close} />
                    <Button text={translation.confirm} width={86} type="default" stylingMode="contained" className="btn-s-r" onClick={() => onSave()} disabled={btnDisable}/>
                </div>
            </div>
        </Popup>
    )
}