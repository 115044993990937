﻿//test
import React, { useEffect, useState } from 'react';
import { getUser, getIP, getLanguage, getMenuMappingInfo } from '../../apiInterface/utils/Common';
import { getBusinessIds, getProcessInfo, insertProcessInfo, updateProcessInfo, deleteProcessInfo, getEquipmentByProcessId } from '../../apiInterface/process/ProcessAPI';
import useTranslation from '../../components/customHooks/translations';
import CustomStore from 'devextreme/data/custom_store';
import { useLocation, useNavigate } from 'react-router-dom';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import BaseDataGrid from '../../components/common/BaseDataGrid';
import { DropdownField, FilterComponent } from '../../components/common/FormField';
import { successToastr } from '../../components/common/Toastr';
import { PageDropdown } from '../../components/common/PageDropdown';
import '../../contents/styles/operationManagement/operation.css';
import { Allotment } from 'allotment';
import { Button, DataGrid } from 'devextreme-react';
import { useRef } from 'react';
import Badge from '../../components/common/Badge';
import TreeCard from '../../components/common/TreeCard';
import { PageTitle } from '../../components/common/PageTitle.jsx';
import { useSwal } from '../../components/common/Swal.jsx';

let searchText = '';
const Process = () => {
    const translation = useTranslation();
    const activeNotActiveJson = { true: translation.active, false: translation.not_active };
    const activeNotActiveClassName = { true: 'grid-badge-use', false: 'grid-badge-disabled' };
    const [dataSource, setDataSource] = useState([]);
    const [businessIds, setBusinessIds] = useState([]);
    const [curBusinessId, setCurBusinessId] = useState();
    const [equipmentInfo, setEquipmentInfo] = useState();
    const [rightPaneVisible, setRightPanelVisible] = useState(false);
    const allotmentRef = useRef();
    const location = useLocation();
    const navigate = useNavigate();
    const errorHandler = useErrorHandler();
    const { info } = useSwal();

    const headerDict = [
        { dataField: 'operation_process_no', caption: 'NO', dataType: 'number', isEdit: false, width: 100, alignment: 'center', isFixed: true, allowReordering: true },
        { dataField: 'operation_process_code', caption: translation.operation_process_code.toUpperCase(), dataType: 'string', isFixed: true, allowReordering: true },
        { dataField: 'operation_process_name', caption: translation.operation_process_name.toUpperCase(), dataType: 'string', isRequired: true, isFixed: true, allowReordering: true },
        { dataField: 'user_business_name', caption: translation._business.toUpperCase(), dataType: 'string', isFixed: true, allowReordering: true },
        {
            dataField: 'operation_process_is_active',
            caption: translation.operation_process_is_active.toUpperCase(),
            dataType: 'boolean',
            width: 130,
            alignment: 'center',
            allowReordering: true,
            cellRender: (e) => <Badge className={activeNotActiveClassName[e.value]} text={activeNotActiveJson[e.value]} />,
        },
        { dataField: 'operation_process_comment', caption: translation.operation_process_comment.toUpperCase(), dataType: 'string', allowReordering: true },
        { dataField: 'operation_process_modified_by', caption: translation.lastModifiedBy.toUpperCase(), dataType: 'string', width: 300, allowReordering: true },
        { dataField: 'operation_process_modified_date', caption: translation.lastModifiedDate.toUpperCase(), dataType: 'string', width: 150, allowReordering: true },
    ];

    const onValueChanged = async (e) => {
        sessionStorage.setItem('businessId', e.value);
        makeCustomStore(e.value);
        setCurBusinessId(e.value);
    };

    const makeCustomStore = (businessId = curBusinessId) => {
        const customStore = new CustomStore({
            load: async (loadOptions) => {
                return read(loadOptions, businessId);
            },
            insert: async (value) => {
                create(value, businessId);
            },
            update: async (previouseValue, changedValue) => {
                const newData = { ...previouseValue, ...changedValue };
                update(newData, businessId);
            },
            remove: async (value) => {
                remove(value, businessId);
            },
        });
        setDataSource(customStore);
    };

    const read = async (loadOptions, businessId) => {
        const language = getLanguage();
        const user = getUser();
        const pageNo = parseInt(loadOptions['skip'] / loadOptions['take']) + 1;
        const param = {
            user_company_id: user.user_company_id,
            sys_lang_code: language,
            user_business_id: businessId,
            row_count: loadOptions['take'],
            page_no: pageNo,
            search_text: searchText,
        };
        const response = await errorHandler(getProcessInfo, param);
        if (response) {
            return {
                data: response.data.o_data,
                totalCount: response.data.count,
            };
        }
    };

    const create = async (newData, businessId) => {
        const { id: mapping_id } = await getMenuMappingInfo();
        const user = getUser();
        const ipAddress = getIP();
        const queryData = {
            user_master_id: user.user_master_id,
            user_log_ip: ipAddress,
            menu_mapping_id: mapping_id,
            user_business_id: businessId,
            operation_process_code: newData.operation_process_code,
            operation_process_name: newData.operation_process_name,
            operation_process_is_active: newData.operation_process_is_active,
            operation_process_comment: newData.operation_process_comment,
        };

        const response = await errorHandler(insertProcessInfo, queryData);
        if (response) {
        }
    };

    const update = async (newData, businessId) => {
        const { id: mapping_id } = await getMenuMappingInfo();
        const user = getUser();
        const ipAddress = getIP();
        const queryData = {
            user_master_id: user.user_master_id,
            user_log_ip: ipAddress,
            menu_mapping_id: mapping_id,
            user_business_id: businessId,
            operation_process_id: newData.operation_process_id,
            operation_process_name: newData.operation_process_name,
            operation_process_is_active: newData.operation_process_is_active,
            operation_process_comment: newData.operation_process_comment,
        };

        const response = await errorHandler(updateProcessInfo, queryData);
        if (response) {
        }
    };

    const remove = async (newData, businessId) => {
        const { id: mapping_id } = await getMenuMappingInfo();
        const user = getUser();
        const ipAddress = getIP();
        const queryData = {
            user_master_id: user.user_master_id,
            user_log_ip: ipAddress,
            user_menu_mapping_id: mapping_id,
            operation_process_id: newData.operation_process_id,
        };

        const response = await errorHandler(deleteProcessInfo, queryData);
        if (response !== 'fail' && response.message === 'MSG_100') {
            successToastr(translation.success_delete_row);
        }
    };

    //init
    useEffect(() => {
        searchText = '';
        const dropDownItems = async () => {
            const user = getUser();
            let param = { user_company_id: user.user_company_id };
            const response = await errorHandler(getBusinessIds, param);
            if (response) {
                const data = response.data.o_data ? response.data.o_data : [];
                setBusinessIds(data);

                if (data.length === 0) {
                    var swalResFn = () => {
                        navigate('/BusinessManagementInfo');
                    };
                    info(`<p>${translation.after_business_add}</p>`, swalResFn);
                }

                //if previousPage is Form, set dropdown value to what was set before edit/add page is clicked.
                if (location.state?.previousPage == '/ProcessForm') {
                    setCurBusinessId(parseInt(sessionStorage.getItem('businessId')));
                } else {
                    let isBusinessExist = false;
                    if (user?.user_business_id) {
                        const branch = data.length !== 0 && data.find((branch) => branch?.user_business_id == user?.user_business_id);
                        if (branch) {
                            setCurBusinessId(user?.user_business_id);
                            isBusinessExist = true;
                        }
                    }

                    //if user info doesn't have business or server's response doesn't have user's business set first row as a default value.
                    if (!isBusinessExist) {
                        setCurBusinessId(data?.[0]?.user_business_id);
                    }
                }
            }
        };

        dropDownItems();
    }, []);

    const dropdown = <DropdownField width={204} label={translation.select_business} value={curBusinessId} dataSource={businessIds} onValueChanged={onValueChanged} displayExpr="user_business_name" valueExpr="user_business_id" />;

    const filtering = (keyword) => {
        searchText = keyword;
        makeCustomStore();
    };

    const filter = <FilterComponent label={translation.filter} onFilter={filtering} width={204} />;

    const toggleRightPanel = () => {
        if (rightPaneVisible == true) {
            allotmentRef.current.resize([3, 1]); //you can set panel ratio.
        }
        setRightPanelVisible(!rightPaneVisible);
    };

    const onRowClick = async (e) => {
        const param = { operation_process_id: e.data.operation_process_id };

        const res = await errorHandler(getEquipmentByProcessId, param);
        if (res) {
            setEquipmentInfo(res.data.o_data);
        }
    };

    return (
        <div className="process">
            <div className="right-content-title">
                <PageTitle />
                <div className="page-buttons">
                    <div>
                        <Button icon="ix-panel" type="normal" stylingMode="text" className="btn-s-r" onClick={toggleRightPanel} />
                    </div>
                    <PageDropdown />
                </div>
            </div>
            <div className="right-content-body">
                <Allotment ref={allotmentRef}>
                    <Allotment.Pane minSize={0}>
                        <div className="left-panel">
                            <div className="container">
                                <BaseDataGrid toolbarModules={[dropdown, filter]} allowModify={true} dataSource={dataSource} headers={headerDict} isRemote={true} selectMode="multiple" link="ProcessForm" isFullSize={false} onRowClick={onRowClick} />
                            </div>
                        </div>
                    </Allotment.Pane>
                    <Allotment.Pane minSize={0} visible={rightPaneVisible} snap={true}>
                        <div className="right-panel">
                            <div className="container">
                                <div className="panel-header">
                                    <p>Process Details</p>
                                </div>
                                <div className="panel-body">
                                    <TreeCard data={equipmentInfo} nameExpr="operation_name" badgeExprs={['operation_type', 'operation_code']} parentExpr="operation_parent_id" keyExpr="operation_id" isSelectable={false} />
                                </div>
                            </div>
                        </div>
                    </Allotment.Pane>
                </Allotment>
            </div>
        </div>
    );
};

export default Process;
