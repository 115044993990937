import React, { useEffect, useState, useRef } from "react";
import Button from 'devextreme-react/button';
import useTranslation from "../../components/customHooks/translations";
import DataGrid, { Column, Editing, Toolbar, Item, Selection } from 'devextreme-react/data-grid';
import { getUser, getIP, getLanguage } from '../../apiInterface/utils/Common';
import BtnTooltip from "../../components/common/BtnTooltip";
import { Popup } from 'devextreme-react/popup';
import useErrorHandler from "../../components/customHooks/useErrorHandler";
import { FilterComponent, SearchableField } from "../../components/common/FormField";
import AddEquipmentPopup from "./AddEquipmentPopup";

export default function AddEquipmentGrid(props) {
    const {
        width,
        dataSource,
        setDataSource = () => { },
        keyExpr,
    } = props;

    const [searchResult, setSearchResult] = useState([]);
    const [searchPopup, setSearchPopup] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [filtered, setFiltered] = useState([]);
    const errorHandler = useErrorHandler();
    const gridRef = useRef();
    const translation = useTranslation();
    const getInstance = () => { return gridRef.current.instance; }

    const headers = [
        { dataField: "machine_equipment_name", caption: translation._equipment.toUpperCase(), dataType: "string", allowReordering: true },
        { dataField: "operation_factory_name", caption: translation._factory.toUpperCase(), dataType: "string", allowReordering: true },
    ];

    useEffect(() => {
        onSearch();
    }, [dataSource])

    const onPopupOk = (result) => {
        let unique = [];

        result.forEach((e) => {
            let isUnique = true;

            dataSource.forEach((d) => {
                if (keyExpr) {
                    if (d[keyExpr] == e[keyExpr])
                        isUnique = false;
                } else {
                    if (d == e)
                        isUnique = false;
                }
            });

            if (isUnique) {
                unique.push(e);
            }
        });

        setDataSource([...dataSource, ...unique]);
    }

    const onSearch = async (keyword = searchText) => {
        let filter = [];
        if (dataSource == null) {
            return;
        }
        setSearchText(keyword);

        dataSource.forEach((e) => {
            if (keyword == '' || e.machine_equipment_name.includes(keyword)) {
                filter.push(e);
            }
        });
        setFiltered(filter);
    }

    //automatically toggle checkbox when user clicked row in datagrid.
    const onRowClick = (e) => {
        const target = e.data;
        const instance = gridRef.current.instance;
        const data = instance.getSelectedRowsData();
        if (data.find((element) => element === target))
            instance.deselectRows([e.key]);
        else
            instance.selectRows([e.key], true);
    }

    const removeRows = async () => {
        const instance = getInstance();
        let selectedRows = instance.getSelectedRowKeys();
        let curData = [...dataSource];
        const subtract = curData.filter(e => !selectedRows.includes(e));
        setDataSource(subtract);
    }

    return (
        <>
            {/* search bar */}
            <div className="data-grid">
                <div className="data-grid-toolbar">
                    <FilterComponent
                        label={translation.filter}
                        width={204}
                        onFilter={onSearch}
                    />
                </div>

                <DataGrid
                    ref={gridRef}
                    dataSource={filtered}
                    onRowClick={onRowClick}
                    width={width}
                    allowColumnResizing={true}
                >
                    <Selection mode="multiple" />
                    <Editing
                        mode="cell"
                        allowUpdating={true}
                        confirmDelete={false}
                    />
                    {
                        headers.map((col) => {
                            return <Column
                                key={col.dataField}
                                dataField={col.dataField}
                                caption={col.caption}
                                dataType={col.dataType}
                                allowEditing={col.allowEditing ? col.allowEditing : false}
                                fixed={col.isFixed}
                            />
                        })
                    }
                    <Toolbar>
                        <Item>
                            {/* remove button */}
                            <Button
                                id="dx-toolbar-item-remove"
                                icon="trash"
                                className="btn-s-r btn-hover-red"
                                onClick={removeRows}
                                type="normal"
                                stylingMode="text"
                            />
                        </Item>
                        <Item>
                            {/* add user button */}
                            <Button
                                id="dx-toolbar-item-add"
                                className="btn-s-r"
                                type="normal"
                                stylingMode="contained"
                                text={translation.add_equipment}
                                icon="add"
                                onClick={() => setSearchPopup(true)}
                            >
                            </Button>
                        </Item>
                    </Toolbar>
                </DataGrid>
            </div>

            {/* search Popup */}
            <Popup
                width={1100}
                height={850}
                visible={searchPopup}
                hideOnOutsideClick={true}
                onHiding={() => setSearchPopup(false)}
                dragEnabled={true}
                position="center"
                showTitle={false}
            >
                <AddEquipmentPopup
                    dataSource={searchResult}
                    setDataSource={setSearchResult}
                    setVisible={setSearchPopup}
                    onClickOk={onPopupOk}
                    keyExpr={keyExpr}
                    title={translation.select_equipment}
                />
            </Popup>

            {/* Button Tooltip */}
            <BtnTooltip target="#dx-toolbar-item-remove" text={translation.delete} />
            <BtnTooltip target="#dx-toolbar-item-add" text={translation.add} />
        </>
    );
}