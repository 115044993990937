import {
    makeAxiosPostFileRequest,
    makeTokenAxiosDeleteRequest,
    makeTokenAxiosGetRequest,
    makeTokenAxiosPostRequest,
    makeTokenAxiosPostRequestParams,
    makeTokenAxiosPutRequest
} from "../../apiInterface/utils/AxiosMask"

const BASE_URL = "aas_file_management";
export const mainAasMaster = async (param) => {
    let res;
    const urlPath = `${BASE_URL}/main_aasx_file`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res : "fail";
}

export const getAssxFileById = async (param) => {
    let res;
    const urlPath = `${BASE_URL}/aasx_file_by_id`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res : "fail";
}

export const uploadAasxFileAPI = async (data) => {
    let res;
    const urlPath = `${BASE_URL}/upload_aasx_file`;

    try {
        res = await makeAxiosPostFileRequest(urlPath, data);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res : "fail";
}

export const postAasxFileInfoAPI = async (param) => {
    let res;
    const urlPath = `${BASE_URL}/aasx_file_info`;

    try {
        //res = await makeTokenAxiosPostRequest(urlPath, data);
        res = await makeTokenAxiosPostRequestParams(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res : "fail";
}

export const deleteAasxFileAPI = async (param) => {
    let res;
    const urlPath = `${BASE_URL}/aasx_file`;

    try {
        res = await makeTokenAxiosDeleteRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res : "fail";
}