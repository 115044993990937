import { makeAxiosGetRequest, makeAxiosPostRequest } from '../utils/AxiosMask';
// import { Baseapi } from '../utils/Common';
import { Baseapi } from '../../config';

export const signin = async (data) => {
    var res;
    const urlPath = `home/signin/`;
    try {
        res = await makeAxiosGetRequest(urlPath, data);
        return res.status === 200 ? res.data : 'fail';
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : 'fail';
};
