import { getParam, getServiceMappingInfo } from './apiInterface/utils/Common';

const hostname = window.location.hostname;
const serviceTypeParam = getParam('type');
const serviceInfo = getServiceMappingInfo();
const service = serviceTypeParam !== undefined ? serviceTypeParam : serviceInfo?.type !== undefined ? serviceInfo.type : 'framework';
const serviceStr = service === 'framework' ? `api_${service}/` : `api_${service}ai/`;

const portNum = {
    framework: 9005,
    recipe: 9001,
    quality: 9001,
    //digitaltwin: 9003,
    inspection: 9003,
};

const ipNumOfTesting = service !== 'inspection' ? 141 : 144;

// export const BASE_URL = service !== 'digitaltwin' ? (hostname === 'www.interx.ai' ? `//www.interx.ai/${serviceStr}` : `//www.interone.ai/${serviceStr}`) : `//server.interxlab.io:5684`;
export const BASE_URL = hostname.includes('interone.ai') ? `//interone.ai/${serviceStr}` : `//interx.ai/${serviceStr}`;
// customer
// export const BASE_URL = hostname === '//www.interone.ai' ? `//www.interone.ai/${serviceStr}` : `${hostname}:${portNum[service]}/${serviceStr}`;
const pageStr = window.location.pathname.split('/')[2];
const API_URL = {
    // inspection
    InspectionDefectType: 'inspection_defect_type',
    InspectionStandardGroup: 'inspection_standard_library',
    DefectTypeStandard: 'i_inspection_library_condition',
};

export const Baseapi = {
    // pythonWebAPI: `${BASE_URL}${API_URL[pageStr]}`,
    //  pythonWebAPI: "http://127.0.0.1:8000/api_framework/"
    pythonWebAPI: BASE_URL,
    lnfAPI: 'http://192.168.200.10:86/api_framework/',
};
