// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mold .right-content-body, .equipment .right-content-body {
    height: calc(100% - 44px);
    min-height: calc(100% - 44px);
}`, "",{"version":3,"sources":["webpack://./src/contents/styles/mold/mold-form.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,6BAA6B;AACjC","sourcesContent":[".mold .right-content-body, .equipment .right-content-body {\n    height: calc(100% - 44px);\n    min-height: calc(100% - 44px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
