import React, { useEffect, useRef, useState } from "react";
import Button from 'devextreme-react/button';
import useTranslation from "../../components/customHooks/translations";
import { getUser, getIP, getLanguage } from '../../apiInterface/utils/Common';
import { getBusinessIds, getMachineEquipment, getProcessInfo } from "../../apiInterface/process/ProcessAPI";
import useErrorHandler from "../../components/customHooks/useErrorHandler";
import { DropdownField, SearchableField, FilterComponent } from "../../components/common/FormField";
import BaseDataGrid from "../../components/common/BaseDataGrid";
import CustomStore from "devextreme/data/custom_store";
import { getFactoryIds } from "../../apiInterface/line/LineAPI";
import { ScrollView } from "devextreme-react";


let searchText = "";
export default function AddEquipmentPopup(props) {
    const translation = useTranslation();
    const user = getUser();
    const gridRef = useRef();
    const errorHandler = useErrorHandler();
    const language = getLanguage();
    const [selectedData, setSelectedData] = useState([]);
    const [curBusinessId, setCurBusinessId] = useState();
    const [businessIds, setBusinessIds] = useState([]);
    const [dataSource, setDataSource] = useState([]);

    const {
        title,
        onClickOk,
        setVisible,
        displayExpr,
        keyExpr,
        selectMode = "multiple",
        visibleState,
        selectedEquip = [],
    } = props

    const headers = [
        { dataField: "machine_equipment", caption: translation._equipment.toUpperCase(), dataType: "string", allowReordering: true },
        { dataField: "operation_factory_name", caption: translation._factory.toUpperCase(), dataType: "string", allowReordering: true },
        { dataField: "machine_equipment_comment", caption: translation.machine_equipment_comment.toUpperCase(), dataType: "string", allowReordering: true },
    ];

    //init
    useEffect(() => {
        searchText = "";
        const initDropdown = async () => {
            let param = { user_company_id: user.user_company_id }
            const res = await errorHandler(getFactoryIds, param);
            if (res) {
                setBusinessIds(res.data.o_data);
                if (res.data.o_data.length > 1) {
                    setCurBusinessId(res.data.o_data[0]?.operation_factory_id);
                }
            }
        }
        initDropdown();
    }, []);

    useEffect(() => {
        const instance = gridRef.current.getInstance();
        instance.selectRows(selectedEquip);
    }, [visibleState])

    const ok = (e) => {
        const instance = gridRef.current.getInstance();
        const selection = instance.getSelectedRowsData();
        onClickOk(selection);
        setVisible(false);
    }
    const cancel = (e) => { setVisible(false); }

    const searchClicked = async (text) => {
        searchText = text;
        makeCustomStore();
    }

    const onSelectionChanged = (val) => {
        if (selectMode === "single" && val === undefined)
            setSelectedData([]);
        else if (selectMode === "single" && val !== undefined)
            setSelectedData([val]);
        else if (selectMode === "multiple")
            setSelectedData(val);
    }

    const onValueChanged = (e) => {
        if (gridRef.current != null) {
            gridRef.current.getInstance().deselectAll();

            //https://supportcenter.devexpress.com/ticket/details/t336794/dxdatagrid-customstore-load-event-is-raised-twice-if-statestoring-and-selection-options
            //https://supportcenter.devexpress.com/ticket/details/t459700/dxdatagrid-customstore-load-is-called-multiple-times-when-dxdatagrid-has-configured
        }
        setCurBusinessId(e.value);
        setSelectedData([]);
        makeCustomStore(e.value);
    }

    const makeCustomStore = (businessId = curBusinessId) => {
        const customStore = new CustomStore({
            load: async (loadOptions) => {
                let pageNo = parseInt(loadOptions["skip"] / loadOptions["take"]) + 1;
                let param = {
                    user_company_id: user.user_company_id,
                    operation_factory_id: businessId,
                    search_text: searchText
                }

                const res = await errorHandler(getMachineEquipment, param);
                if (res) {
                    let data = res.data.o_data;
                    data = data.map((e) => {
                        e.machine_equipment = `${e.machine_equipment_name}(${e.machine_equipment_code})`;
                        return e;
                    });
                    return {
                        totalCount: data.length,
                        data: data,
                    };
                } else {
                    return {
                        totalCount: 0,
                        data: []
                    }
                }
            }
        });
        setDataSource(customStore);
    }

    const dropdown = <DropdownField
        label={translation.select_factory}
        value={curBusinessId}
        dataSource={businessIds}
        onValueChanged={onValueChanged}
        displayExpr="operation_factory_name"
        valueExpr="operation_factory_id"
    />

    const onFilter = (keyword) => {
        searchText = keyword;
        makeCustomStore();
    }

    const filter = <FilterComponent
        isWritable={true}
        label={translation.filter}
        onFilter={(text) => searchClicked(text)}
        text={searchText}
    />

    const removeTag = (i) => {
        let cur = [...selectedData];
        let removed = cur.splice(i, 1);
        setSelectedData(cur);

        const instance = gridRef.current.getInstance();
        instance.deselectRows(removed);
    }

    const removeAllTags = () => {
        setSelectedData([]);

        const instance = gridRef.current.getInstance();
        instance.deselectAll();
    }

    return (
        <div className="popup-select-equipment">
            <div className="popup-header">
                <p className="popup-title">{title}</p>
            </div>
            <div className="popup-body">
                <div className="selected-item-viewer">
                    <div className="icon-checked">
                        <i></i>
                    </div>
                    <div className="selected-item-field">
                        {selectedData.length > 0 &&
                            <>
                                <ul className="selected-items">
                                    {
                                    selectedData.map((e, i) =>
                                            <li key={i} className="item">
                                                <p className="item-name">{e.machine_equipment_name}</p>
                                                <i className="material-icons btn-delete" onClick={() => removeTag(i)}>cancel</i>
                                            </li>
                                        )
                                    }
                                </ul>
                                <div className="btn-all-delete" onClick={removeAllTags }>
                                    <button>Delete All</button>
                                </div>
                            </>
                        }
                        {selectedData.length == 0 &&
                            <p className="comment-not-selected">{translation.select_equipment_notice}</p>
                        }
                    </div>
                </div>
                <div className="item-select-box">
                    <ScrollView>
                        <div className="container-wrapper">
                            <div className="container">
                                <BaseDataGrid
                                    toolbarModules={[dropdown, filter]}
                                    dataSource={dataSource}
                                    ref={gridRef}
                                    selectMode={selectMode}
                                    allowModify={false}
                                    isRemote={true}
                                    onSelectionChanged={onSelectionChanged}
                                    headers={headers}
                                    isPaging={true}
                                    allowColumnResizing={true}
                                    isFullSize={true}
                                />
                            </div>
                        </div>
                    </ScrollView>
                </div>
            </div>
            <div className="popup-footer">
                <Button
                    width={86}
                    text={translation.cancel}
                    type="normal"
                    stylingMode="text"
                    className="btn-s-r"
                    onClick={cancel}
                />
                <Button
                    text={translation.confirm}
                    width={86}
                    type="default"
                    stylingMode="contained"
                    className="btn-s-r"
                    onClick={ok}
                />
            </div>
        </div>
    )
}