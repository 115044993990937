﻿import React, { useEffect, useState } from 'react';
import DraggableTreeList from '../../components/common/DraggableTreeList';
import { getToken, getUser, getIP, getMenuMappingInfo, getLanguage } from '../../apiInterface/utils/Common';
import useTranslation from '../../components/customHooks/translations';
import { getDepartmentInfo, getDepartmentsByCompanyId, insertDepartmentInfo, updateDepartmentInfo, deleteDepartmentInfo, updateDepartmentSeq } from '../../apiInterface/departmentManagement/DepartementManagementAPI';
import { useLocation } from 'react-router-dom';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import { DropdownField, FilterComponent } from '../../components/common/FormField';
import { successToastr } from '../../components/common/Toastr';
import { PageDropdown } from '../../components/common/PageDropdown';
import { PageTitle } from '../../components/common/PageTitle.jsx';
import { TextBox } from 'devextreme-react';

let backupData = [];
let searchText = '';
const DepartmentManagementInfo = () => {
    const translation = useTranslation();
    const activeNotActiveJson = { true: translation.active, false: translation.not_active };
    const activeNotActiveClassName = { true: 'grid-badge-use', false: 'grid-badge-disabled' };
    const [businessIds, setBusinessIds] = useState([]);
    const [curBusinessId, setCurBusinessId] = useState();
    const [dataSource, setDataSource] = useState([]);
    const [disableSelectBox, setSelectBoxDisable] = useState(false);
    const language = getLanguage();
    const location = useLocation();
    const errorHandler = useErrorHandler();

    //{ dataField: str, caption: str, dataType: str, isEdit: bool, isRequired: bool },
    const headerDict = [
        // { dataField: "user_department_no", caption: translation.no, dataType: "string", width: 150, alignment: "center", isFixed: true },
        { dataField: 'user_department_name', caption: translation.user_department_name.toUpperCase(), dataType: 'string', isFixed: true, allowReordering: true },
        {
            dataField: 'user_department_is_active',
            caption: translation.user_department_is_active.toUpperCase(),
            dataType: 'string',
            width: 130,
            alignment: 'center',
            isBadge: true,
            captionForBadge: activeNotActiveJson,
            classNameForBadge: activeNotActiveClassName,
            allowReordering: true,
        },
        { dataField: 'user_department_comment', caption: translation.user_department_comment.toUpperCase(), dataType: 'string', allowReordering: true },
        { dataField: 'user_department_modified_by', caption: translation.lastModifiedBy.toUpperCase(), dataType: 'string', isEdit: false, width: 300, allowReordering: true },
        { dataField: 'user_department_modified_date', caption: translation.lastModifiedDate.toUpperCase(), dataType: 'string', isEdit: false, width: 150, allowReordering: true },
    ];

    useEffect(() => {
        searchText = '';
        const init = async () => {
            const user = getUser();
            const param = { user_company_id: user.user_company_id };
            const res = await errorHandler(getDepartmentsByCompanyId, param);
            if (res) {
                setBusinessIds(res.data.o_data);
                if (res.data.o_data.length > 0) {
                    if (location.state?.previousPage == '/DepartmentManagementInfoForm') {
                        setCurBusinessId(parseInt(sessionStorage.getItem('businessId')));
                    } else {
                        let isBusinessExist = false;
                        if (user.user_business_id != null) {
                            const branch = res.data.o_data.find((branch) => branch.user_business_id == user.user_business_id);
                            if (branch != null) {
                                setCurBusinessId(user.user_business_id);
                                isBusinessExist = true;
                            }
                        }

                        //if user info doesn't have business or server's response doesn't have user's business set first row as a default value.
                        if (!isBusinessExist) {
                            setCurBusinessId(res.data.o_data[0].user_business_id);
                        }
                    }
                }
            }
        };
        init();
    }, []);

    const onValueChanged = async (e) => {
        setCurBusinessId(e.value);
        sessionStorage.setItem('businessId', e.value);
        loadRows(e.value);
    };

    const loadRows = async (businessId) => {
        const user = getUser();
        let param = {
            user_company_id: user.user_company_id,
            user_business_id: businessId,
            sys_lang_code: language,
        };

        const res = await errorHandler(getDepartmentInfo, param);
        if (res) {
            backupData = res.data.o_data;
            filtering();
            //setDataSource(backupData);
        }
    };

    const onDelete = async (deptList) => {
        const user = getUser();
        const ipAddress = getIP();
        const menuMappingInfo = await getMenuMappingInfo();
        let isSuccess = true;

        let param = {
            user_master_id: user.user_master_id,
            user_log_ip: ipAddress,
            user_menu_mapping_id: menuMappingInfo.id,
        };

        for (let i = 0; i < deptList.length; i++) {
            param.user_department_id = deptList[i].user_department_id;
            const res = await errorHandler(deleteDepartmentInfo, param);
            if (!res) {
                isSuccess = false;
                break;
            }
        }

        loadRows(curBusinessId);
        if (isSuccess) {
            //alertSuccess(translation.success_remove);
            successToastr(translation.success_delete_row);
        }
    };

    const updateRowSequence = async (deptId, parentId, topId) => {
        let param = {
            user_business_id: curBusinessId,
            user_department_id: deptId,
            user_department_parent_id: parentId,
            user_department_top_id: topId,
        };

        const res = await errorHandler(updateDepartmentSeq, param);
        if (res) {
            successToastr(translation.success_update_seq);
        }
    };

    const onReorder = async (item) => {
        let deptId = item.user_department_id;
        let parentId = item.user_department_parent_id;
        let topId = item.upperNode;
        updateRowSequence(deptId, parentId, topId);
    };

    const onMoveDown = () => {
        setSelectBoxDisable(true);
    };

    const onMoveDownExit = () => {
        setSelectBoxDisable(false);
    };

    const dropdown = <DropdownField label={translation.select_business} dataSource={businessIds} displayExpr="user_business_name" valueExpr="user_business_id" onValueChanged={onValueChanged} disabled={disableSelectBox} value={curBusinessId} width={204} />;

    const filtering = (keyword = searchText) => {
        searchText = keyword.toLowerCase();
        let filtered = {};
        let dict = {};

        if (keyword) {
            for (let i = 0; i < backupData.length; i++) {
                dict[backupData[i].user_department_id] = backupData[i];

                let deptName = backupData[i].user_department_name.toLowerCase();
                if (deptName.includes(keyword)) {
                    filtered[backupData[i].user_department_id] = backupData[i];
                }
            }

            Object.values(filtered).forEach((dept) => {
                let parentId = dept.user_department_parent_id;
                while (parentId !== 0) {
                    filtered[parentId] = dict[parentId];
                    parentId = dict[parentId].user_department_parent_id;
                }
            });
        } else {
            filtered = backupData;
        }

        let result = Object.values(filtered);
        setDataSource(result);
    };

    const filter = <FilterComponent label={translation.filter} width={204} onFilter={filtering} />;

    return (
        <div className="department-management-info">
            <div className="right-content-title">
                <PageTitle />
                <PageDropdown />
            </div>
            <div className="right-content-body">
                <div className="container">
                    <DraggableTreeList
                        toolbarModules={[dropdown, filter]}
                        dataSource={dataSource}
                        keyExpr="user_department_id"
                        displayExpr="user_department_name"
                        parentIdExpr="user_department_parent_id"
                        allowReordering={true}
                        headers={headerDict}
                        onDelete={onDelete}
                        onReorder={onReorder}
                        onMoveDown={onMoveDown}
                        onMoveDownExit={onMoveDownExit}
                        link="DepartmentManagementInfoForm"
                        selectMode="multiple"
                        isFullSize={true}
                    />
                </div>
            </div>
        </div>
    );
};

export default DepartmentManagementInfo;
