import {
    makeAxiosPostFileRequest,
    makeTokenAxiosDeleteRequest,
    makeTokenAxiosGetRequest,
    makeTokenAxiosPostRequest,
    makeTokenAxiosPostRequestParams,
    makeTokenAxiosPutRequest
} from "../../apiInterface/utils/AxiosMask"

const BASE_URL = "aas_master_management";
export const getMainAasMaster = async (param) => {
    let res;
    const urlPath = `${BASE_URL}/main_aas_master`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res : "fail";
};

export const getAasMasterExplorer = async (param) => {
    let res;
    const urlPath = `${BASE_URL}/aas_master_explorer`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res : "fail";
};

export const getAasMasterExplorerDetail = async (param) => {
    let res;
    const urlPath = `${BASE_URL}/aas_master_explorer_detail`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res : "fail";
};

export const deleteAasMaster = async (param) => {
    let res;
    const urlPath = `${BASE_URL}/aas_master`;

    try {
        res = await makeTokenAxiosDeleteRequest(urlPath, param);
        return res.status === 200 ? res.data : "fail";
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res : "fail";
};