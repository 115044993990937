import { useState, useEffect, useRef } from 'react';
import { GridStack } from 'gridstack';
import { Button, Popup, ScrollView, LoadIndicator } from 'devextreme-react';
import { useLocation, useNavigate } from 'react-router-dom';
import useTranslation from '../../components/customHooks/translations';
import useErrorHandler from '../customHooks/useErrorHandler';
import { chartResize, MultiChart } from '../../components/ixChart/IXChart';
import { getOneTemplateTypeById, uploadImageInTemplate } from '../../apiInterface/templateManagment/TemplateManagementAPI';
import { getDatasetMasterPropertyDivisionByDsIds, getTemplateMasterLayoutAPI, getTemplateTypeMultiChart, insertMenuMapping, insertMenuMappingCaption, insertMenuMappingSetting, updateMenuMapping } from '../../apiInterface/menu/MenuAPI';
import { getIP, getLanguage, getMenuMappingInfo, getUser } from '../../apiInterface/utils/Common';
import { PageDropdown } from '../../components/common/PageDropdown';
import PageTitle from '../../components/common/PageTitle';
import { useSwal } from '../common/Swal';
import { SearchableField, TextboxField, DropdownField, SingleFileField, DateRangeField, FilterComponent } from '../common/FormField';
import AddEquipmentPopup from '../../pages/operationManagement/AddEquipmentPopup';
import SelectRawDatasetPopup from '../../pages/dataManagement/SelectRawDataPopup';
import 'gridstack/dist/gridstack.min.css';
import '../../contents/styles/templateMaster/custom-menu.css';
import { loading } from '../common/Library.jsx';
import Search from '../common/Search';
import { AxisList, ChartFilter, DataFilter, DataGridFilter, DataGridSetting, PageFilter } from './FilterSetting';
import BaseDataGrid from '../../components/common/BaseDataGrid';
import CustomDataGrid from './CustomDataGrid';
import ReactDOM from 'react-dom/client';
import ConditionFormattingPopup from '../../pages/menu/popup/ConditionFormattingPopup';
import TextEditorPopup from '../../pages/menu/popup/TextEditorPopup';
import { getColsByDatasetIdCustomTemp } from '../../apiInterface/aasFile/DatasetManagementAPI';
import CustomStore from 'devextreme/data/custom_store';

const colorJson = {
    // false 사용가능, true 사용중
    '#0FB5AE': false,
    '#4046CA': false,
    '#F68511': false,
    '#DE3D82': false,
    '#7E84FA': false,
    '#72E06A': false,
    '#147AF3': false,
    '#7326D3': false,
    '#E8C600': false,
    '#CB5D00': false,
    '#008F5D': false,
    '#BCE931': false,
};

export const initStyling = {
    font_style: [],
    align: 'left',
    vertical: 'middle',
    font_color: '#000000',
    background: '',
};

const initGridSettingVal = {
    useNumbering: true,
    paginationSize: 10,
    header_styling: initStyling,
    body_styling: initStyling,
};

const CustomMenu = () => {
    let grid = document.querySelector('.grid-stack')?.gridstack;
    const translation = useTranslation();
    const errorHandler = useErrorHandler();
    const navigate = useNavigate();
    const location = useLocation();
    const user = getUser();
    const ipAddr = getIP();
    const lang = getLanguage();
    const menuMappingInfo = getMenuMappingInfo();
    const { success, fail, confirm } = useSwal();
    const { data, title, path, menu, selectedTemplate, serviceId } = location.state;

    const [loaderVisible, setLoaderVisible] = useState(false);

    // for grid stack
    const [gridItemInfo, setGridItemInfo] = useState([]);
    const [selectedComponent, setSelectedComponent] = useState(null); // {idx: int, item: []}
    const [componentTitle, setComponentTitle] = useState('');

    // common or etc in component setting
    const [rawDataset, setRawDataset] = useState();
    const [customImage, setCustomImage] = useState([]);
    const [customText, setCustomText] = useState();
    const [targetFilter, setTargetFilter] = useState({});
    const [searchRawDatasetPopup, setSearchRawDatasetPopup] = useState(false);

    // for chart
    const [targetListContent, setTargetListContent] = useState(null);
    const [allChartList, setAllChartList] = useState([]);
    const [chartTypeList, setChartTypeList] = useState([]); // [{template_type_id: 6, template_type_name: 'Chart-Line'}, {}]
    const [hasSingleChart, setHasSingleChart] = useState(false);

    // for chart data filter
    const [addFilterPopup, setAddFilterPopup] = useState(false);
    const [addXaxisPopup, setAddXaxisPopup] = useState(false);
    const [addYaxisPopup, setAddYaxisPopup] = useState(false);
    const [equipment, setEquipment] = useState();
    const [searchEquipPopup, setSearchEquipPopup] = useState(false);
    const [addXAxisDisable, setAddXAxisDisable] = useState(true);

    // for data-grid filter
    const [selectedColListForDG, setSelectedColListForDG] = useState([]);
    // const [columnListForDG, setColumnListForDG] = useState([]);
    const [addDGColumnPopup, setAddDGColumnPopup] = useState(false);
    const [dgColumnDataSource, setDGColumnDataSource] = useState();

    // for data filter for chart and DG
    const [filterDataSource, setFilterDataSource] = useState(); // add filter popup datasource
    const [dataFilterList, setDataFilterList] = useState([]);

    // for drawing chart
    const [demoEchartData, setDemoEchartData] = useState([]);
    const [xAxis, setXAxis] = useState([]);
    const [yAxis, setYAxis] = useState();
    /* new options */
    const [yRange, setYRange] = useState([null, null]);
    /* new options */
    const [selectedX, setSelectedX] = useState([]);
    // const [selectedY, setSelectedY] = useState([]);
    const selectedY = useRef();
    const [selectedColorOfY, setSelectedColorOfY] = useState([]);
    /* new options */
    const [yAuxiliary, setYAuxiliary] = useState([]);
    /* new options */
    const [colorList, setColorList] = useState(colorJson);

    // for text editor
    const [textEditorPopup, setTextEditorPopup] = useState(false);

    // for page filter
    const [onFilter, setOnFilter] = useState(false);
    const [pageFilterList, setPageFilterList] = useState([]);
    const [addBindingColPopup, setAddBindingColPopup] = useState(false);
    const [bindingColDataSource, setBindingColDataSource] = useState();
    const [selectedBindingCol, setSelectedBindingCol] = useState([]);

    useEffect(() => {
        const fetchComponentData = async () => {
            // for chart type (init chart component(set default chart))
            const multiChartList = await getTemplateTypeMultiChartList();
            const initComponentData = (gridItemInfo) => {
                gridItemInfo.map((item) => {
                    if (item.templateTypeStyle === 'CHART') {
                        item.settingVal['chartTypeList'] = [multiChartList[0]];
                        item.settingVal['chartDataFilterInfo'] = [{ raw_dataset: {}, filter_list: [], filter_value: [] }];
                    } else if (item.templateTypeStyle === 'DG') {
                        item.settingVal['gridDataFilterInfo'] = {
                            raw_dataset: {},
                            grid_setting: initGridSettingVal,
                            filter_list: [],
                            filter_value: [],
                            column_list: [],
                            conditional_formatting: [],
                        };
                    }
                });
                return gridItemInfo;
            };

            // get/set structure of template (GridStack.js)
            let gridItemInfo = title === 'Add' ? initComponentData(await getCustomTemplateById()) : data.menu_custom_layout_json;
            setGridItemInfo(gridItemInfo);
            gridItemInfo.length > 0 ? loading('.card-container', 'on') : null;

            const option = {
                disableResize: true,
                disableDrag: true,
                float: true,
            };
            grid = GridStack.init(option);
            grid.load(gridItemInfo, true);

            // get/set content of each component
            //const filterListByIdx = {};
            gridItemInfo.forEach(async (item, i) => {
                const id = item.id;
                const param = {
                    user_company_id: user.user_company_id,
                    template_type_id: item.templateTypeId,
                    sys_lang_code: lang,
                };
                const res = await getOneTemplateTypeById(param);
                const templateInfo = res.data.o_data[0];

                // add badge
                //let filter = templateInfo.template_type_filter;
                //if (["CHART", "DG"].includes(item.templateTypeStyle))
                //    filter = templateInfo.template_type_filter?.filter(f => f.dataset_name === rawDataset);
                //badgeSetting(item.id, filter);
                // set filter
                //filterListByIdx[id] = templateInfo.template_type_filter;
                //setFilterList(filterListByIdx);

                if (item.templateTypeStyle === 'CHART') {
                    const chartDataFilterInfo = item.settingVal?.chartDataFilterInfo ? item.settingVal?.chartDataFilterInfo : [];
                    const chartTypeList = item.settingVal.chartTypeList?.map((chart) => chart.template_type_name);
                    const chartYList = [];
                    const chartColorList = [];
                    chartDataFilterInfo?.forEach((info, i) => {
                        chartYList.push(chartDataFilterInfo[i]?.y);
                        chartColorList.push(chartDataFilterInfo[i]?.yColor);
                    });
                    const settingData = {
                        chartData: chartYList?.length > 0 ? { y: chartYList, color: chartColorList } : { y: [undefined, undefined], color: [undefined, undefined] },
                        chartTypeList: chartTypeList,
                    };
                    contentSetting(id, templateInfo, settingData);
                } else if (item.templateTypeStyle === 'DG') {
                    const gridDataFilterInfo = item.settingVal?.gridDataFilterInfo ? item.settingVal?.gridDataFilterInfo : [];
                    const colList = gridDataFilterInfo?.column_list?.length > 0 ? gridDataFilterInfo?.column_list : [];
                    const settingData = {
                        colList: colList,
                        gridInfo: item,
                        //gridSetting: gridDataFilterInfo?.grid_setting ? gridDataFilterInfo.grid_setting : initGridSettingVal
                    };
                    contentSetting(id, templateInfo, settingData);
                } else {
                    contentSetting(id, templateInfo);
                }

                // set title header
                titleHeaderSetting(id, item);

                // set loader
                if (i === gridItemInfo.length - 1) loading('.card-container', 'off');
            });
        };

        const fetchPageFilterData = () => {
            let pageFilterInfo = title === 'Add' ? [{ type: { sys_division_sub_caption_text: `Date Range ${translation.type}`, sys_division_sub_value: 'DATERANGE', sys_division_sub_seq: 0, sys_division_sub_default_check: 'Y' }, value: {} }] : data.menu_custom_filter_json;
            setPageFilterList(pageFilterInfo);
        };

        // for component (gridInfo)
        fetchComponentData();
        // for page filter
        fetchPageFilterData();
    }, []);

    useEffect(() => {
        componentTitle !== '' && selectedComponent ? titleHeaderSetting(selectedComponent.item.id, selectedComponent.item) : null;
    }, [componentTitle]);

    // useEffect(() => { // 데이터 세팅할 타겟 차트가 있을 때 (차트 컴포넌트 내에서 chart1 / chart2 클릭)
    //     const fetch = async () => {
    //         if (targetListContent === null) return;

    //         if (["DATERANGE", "SEARCH", "DDL", "TXT"].includes(targetListContent?.type)) { // page setting 인 경우
    //             setSelectedBindingCol(pageFilterList[targetListContent.idx].value.binding_column);
    //         } else { // chart 인 경우
    //             const settingVal = selectedComponent?.item?.settingVal;
    //             const chartDataFilterInfo = settingVal?.chartDataFilterInfo[targetListContent?.idx];
    //             const rawDataset = chartDataFilterInfo?.raw_dataset;
    //             setRawDataset(rawDataset);
    //             rawDataset?.dataset_master_id != null && setYAxis(await getColsByDatasetId(rawDataset.dataset_master_id));
    //             setDataFilterList(chartDataFilterInfo?.filter_list);
    //             setSelectedX(settingVal?.x);
    //             setSelectedY(chartDataFilterInfo?.y);
    //             setSelectedColorOfY(chartDataFilterInfo?.yColor);
    //         }
    //     }
    //     fetch();
    // }, [targetListContent])

    useEffect(() => {
        const fetchChartFilter = async () => {
            const rawDataIdList = selectedComponent?.item?.settingVal?.chartDataFilterInfo?.map((info) => info?.raw_dataset?.dataset_master_id).filter((id) => id !== undefined);
            const rawDataIdListLen = rawDataIdList?.length;
            const chartCount = selectedComponent?.item?.settingVal?.chartTypeList?.length;

            // set common column
            if (rawDataIdListLen > 0) {
                //const commonCol = await getCommonColumn(rawDataIdList);
                const commonCol = await getCommonDatasetMasterPropertyDivision(rawDataIdList, 'XAXIS');
                setXAxis(
                    commonCol.map((col) => {
                        return { name: col.column_name, table_name: col.collection_name, local_name: col.title };
                    })
                );
                // setYAxis(await getColsByDatasetId(rawDataIdList[0]));
            } else {
                // setYAxis(null);
            }
            if (rawDataset?.dataset_master_id != null) setYAxis(await getColsByDatasetId(rawDataset.dataset_master_id));
            else setYAxis(null);

            // set Add X Axis button disabled state
            if (chartCount > 0 && chartCount === rawDataIdListLen) setAddXAxisDisable(false);
            else setAddXAxisDisable(true);
        };

        const fetchPageFilter = () => {
            const pageFilterResetList = [];
            let needToSet = false;
            pageFilterList.forEach(async (filter) => {
                const commonColList = await bindingColSettingForPageFilter(filter.type.sys_division_sub_value);
                const bindingColumnVal = filter?.value?.binding_column;
                const sameInNewCommonColList = commonColList?.find((col) => bindingColumnVal && col.collection_name === bindingColumnVal[0].collection_name && col.column_name === bindingColumnVal[0].column_name);
                if (bindingColumnVal && !sameInNewCommonColList) {
                    // 새로 받아온 binding column 안에 이미 선택했던 것이 없을 때, 리셋 후 저장
                    needToSet = true;
                    delete filter?.value?.binding_column;
                }
                pageFilterResetList.push(filter);
            });
            needToSet && setPageFilterList(pageFilterResetList);
        };

        // raw data set 선택했을 때, chart의 X Axis 다시 구함
        const selectedTemplateStyle = selectedComponent?.item?.templateTypeStyle;
        if (selectedTemplateStyle === 'CHART') fetchChartFilter();
        // else if(selectedTemplateStyle === "DG") fetchDGFilter();

        // raw dataset 변하면 page filter도 다시 받아와야함
        fetchPageFilter();
    }, [rawDataset]);

    useEffect(() => {
        // if select single type chart (that can't use as multi chart), block the add new chart type button
        const selectedSingleChart = chartTypeList.filter((chart) => chart.template_type_is_multi === false);
        if (selectedSingleChart?.length > 0) setHasSingleChart(true);
        else setHasSingleChart(false);
    }, [chartTypeList]);

    useEffect(() => {}, [selectedComponent]);

    // css 수정 필요 (아마 필수로 써야하는 필터가 없어질 예정)
    //const badgeSetting = (id, filter) => {
    //    const checkBindingStateBadge = (filter) => selectedComponent?.item?.settingVal[filter] !== undefined && selectedComponent?.item?.settingVal[filter]?.length !== 0
    //    //filter === "custom_image" ?
    //    //    selectedComponent?.item?.settingVal[filter].length !== 0 :

    //    const badgeState = selectedComponent !== null && filter.every(checkBindingStateBadge) ? "check" : "error";
    //    const badge =
    //        <div className="binding-state">
    //            <div className={`item-badge error binding-state-badge`}></div>
    //            <div className="item-dropdown">
    //                <ul className="item-dropdown-menu">
    //                    <span>{translation.binding_state}</span>
    //                    <div>
    //                        {
    //                            filter?.map((f, i) => {
    //                                const liState = selectedComponent?.item.settingVal[f] !== undefined && selectedComponent?.item.settingVal[f]?.length !== 0 ? "check" : "none";
    //                                return (<li key={i} className={liState}>{f[lang]}</li>);
    //                            })
    //                        }
    //                    </div>
    //                </ul>
    //            </div>
    //        </div>;
    //    document.querySelector(`[gs-id="${id}"] .binding-state`)?.remove()
    //    document.querySelector(`[gs-id="${id}"]`).innerHTML += ReactDomServer.renderToString(badge);
    //}

    /* ---------- for all component ---------- */

    // const getColsByDatasetId = async (id) => {
    //     const customStore = new CustomStore({
    //         load: async (loadOptions) => {
    //             const user = getUser();
    //             const params = {
    //                 user_company_id: user.user_company_id,
    //                 dataset_master_id: id
    //             }
    //             const res = await getColsByDatasetIdCustomTemp(params);
    //             if (res !== "fail" && res.message === "MSG_100") {
    //                 return {
    //                     data: res.data.o_data.map(y => {
    //                         return { name: y.aas_master_column_name, aas_master_table_name: y.aas_master_table_name };
    //                     }),
    //                     totalCount: res.data.o_data.length
    //                 }
    //             } else {
    //                 return {data: [], totalCount: 0}
    //             }
    //         },
    //     })

    //     return customStore;
    // }

    const getColsByDatasetId = async (id) => {
        const param = {
            user_company_id: user.user_company_id,
            dataset_master_id: id,
        };
        const res = await getColsByDatasetIdCustomTemp(param);
        if (res !== 'fail') {
            return res?.data?.o_data?.map((y) => {
                return { name: y.aas_master_column_name, aas_master_table_name: y.aas_master_table_name, local_name: y.title };
            });
        }
    };

    const getCustomTemplateById = async () => {
        const param = { template_master_id: parseInt(selectedTemplate.id) };
        const res = await getTemplateMasterLayoutAPI(param);
        if (res !== 'fail') {
            let widgetInfo = Object.values(res.data.o_data[0].template_custom_layout_json);
            return widgetInfo;
        } else console.log('failed getting custom template');
    };

    const contentSetting = (id, templateInfo, settingData) => {
        const templateStyle = templateInfo.template_type_style;
        document.querySelector(`[gs-id="${id}"] .grid-stack-item-content`).classList.add(`${templateStyle.toLowerCase()}-content`);

        if (templateStyle === 'CHART') {
            drawChart(id, settingData.chartData, settingData.chartTypeList);
        } else if (templateStyle === 'DG') {
            makeDataGrid(id, settingData.colList, settingData.gridInfo);
        } else {
            document.querySelector(`[gs-id="${id}"] .grid-stack-item-content`).innerHTML = templateInfo.template_type_image;
        }
    };

    const titleHeaderSetting = (id, templateInfo) => {
        document.querySelector(`[gs-id="${id}"] .grid-stack-item-title`)?.remove();
        if (templateInfo.templateTypeStyle !== 'CARD') {
            const titleDiv = document.createElement('div');
            titleDiv.setAttribute('class', 'grid-stack-item-title');
            titleDiv.textContent = templateInfo.settingVal?.title;
            document.querySelector(`[gs-id="${id}"] .grid-stack-item-content`).prepend(titleDiv);
        }
    };

    /* new options */
    const clickComponent = (e) => {
        document.activeElement.blur();
        const targetItem = e.target.closest('.grid-stack-item');

        // if chartN have open class, then close the chart
        const chartList = document.querySelector('.setting-area.chart');
        if (chartList && chartList.querySelectorAll('.open')[0]) {
            chartList.querySelectorAll('.open')[0].querySelector('.toggle-tit')?.click();
        }

        // 컴포넌트 클릭했을 때, 클릭한 컴포넌트가 비활성화 상태
        if (targetItem !== null && !targetItem.classList.contains('itemActive')) {
            // target 컴포넌트 선택되도록
            const prevTarget = document.querySelector('.itemActive');
            prevTarget?.classList.remove('itemActive');
            targetItem.classList.add('itemActive');

            // target component info
            const targetItemId = targetItem.getAttribute('gs-id');
            const selectedItemIdx = gridItemInfo.findIndex(function (e) {
                return e.id === targetItemId;
            });
            const itemInfo = gridItemInfo[selectedItemIdx];

            setOnFilter(false);
            setRawDataset(null);
            setTargetListContent(null);
            // setYAxis([]);
            setXAxis([]);
            setSelectedX([]);
            setYRange([null, null]);
            setYAuxiliary([]);
            setChartTypeList(itemInfo.settingVal?.chartTypeList ? itemInfo.settingVal.chartTypeList : []);
            setSelectedComponent({ idx: selectedItemIdx, item: itemInfo /*, filter: filter */ });
            setTargetFilter({});

            setTimeout(() => {
                const settingVal = gridItemInfo[selectedItemIdx]?.settingVal;
                setComponentTitle(settingVal?.title);
                // 선택한 컴포넌트가 차트인 경우에는 title, multi chart dropdown list만 바인딩됨
                // 이외의 경우에는 설정한 모든 데이터 설정
                const templateTypeStyle = itemInfo.templateTypeStyle;
                if (templateTypeStyle === 'CHART') {
                    settingVal?.chartTypeList?.length > 0 && setTargetListContent(settingVal.chartTypeList[0]);
                    // open first chart
                    document.querySelector('.setting-area.chart').querySelectorAll('.toggle-tit')[0].click();
                } else if (templateTypeStyle === 'DG') {
                    setRawDataset(settingVal.gridDataFilterInfo?.raw_dataset);
                    //setYAxis
                    setDataFilterList(settingVal.gridDataFilterInfo.filter_list);
                } else {
                    setEquipment(settingVal?.machine_equipment);
                    setCustomImage(settingVal?.custom_image === undefined ? [] : settingVal?.custom_image);
                    setCustomText(settingVal?.custom_text);
                    setRawDataset(settingVal?.raw_dataset);
                }
            }, 50);
        } else if (targetItem !== null && targetItem.classList.contains('itemActive')) {
            // 카드 클릭했을 때, 클릭한 카드가 활성화 된 상태
            targetItem.classList.remove('itemActive');
            setSelectedComponent(null);
        }
    };
    /* new options */

    /* new options */
    const componentSettingChanged = (type, value) => {
        const originGridItemInfo = [...gridItemInfo];
        const targetIdx = selectedComponent.idx;
        const targetItemInfo = originGridItemInfo[targetIdx];
        const targetItemId = targetItemInfo.id;
        const targetSettingVal = targetItemInfo['settingVal'];

        targetSettingVal === undefined ? (targetSettingVal = {}) : null;

        const templateTypeStyle = targetItemInfo.templateTypeStyle;
        if (templateTypeStyle === 'CHART') {
            switch (type) {
                case 'title':
                case 'chartTypeList':
                case 'x':
                case 'yRange':
                    targetSettingVal[type] = value;
                    break;
                case 'chartDataFilterInfo':
                    targetSettingVal[type] === undefined ? (targetSettingVal[type] = []) : null;
                    targetSettingVal[type].push({ raw_dataset: {}, filter_list: [], filter_value: [] });
                    break;
                case 'raw_dataset':
                case 'filter_list':
                case 'filter_value':
                case 'y':
                case 'yColor':
                case 'yAuxiliary':
                    targetSettingVal['chartDataFilterInfo'][targetListContent.idx][type] = value;
                    break;
                default:
                    targetFilter.idx !== undefined && (targetSettingVal['chartDataFilterInfo'][targetListContent.idx]['filter_value'][targetFilter.idx] = value);
                    break;
            }
        } else if (templateTypeStyle === 'DG') {
            switch (type) {
                case 'title':
                    targetSettingVal[type] = value;
                    break;
                case 'raw_dataset':
                case 'grid_setting':
                case 'filter_list':
                case 'filter_value':
                case 'column_list':
                case 'conditional_formatting':
                case 'link_list':
                    targetSettingVal['gridDataFilterInfo'][type] = value;
                    break;
                default:
                    targetSettingVal['gridDataFilterInfo']['filter_value'][targetFilter.idx] = value;
                    break;
            }
        } else {
            targetSettingVal[type] = value;
        }

        setGridItemInfo(originGridItemInfo);
        setSelectedComponent({ idx: targetIdx, item: originGridItemInfo[targetIdx] });
        if (type === 'title') setComponentTitle(value);
        else if (type === 'machine_equipment') setEquipment(value);
        else if (type === 'custom_image') setCustomImage(value);
        else if (type === 'custom_text') setCustomText(value);
        else if (type === 'raw_dataset') setRawDataset(value);
        else if (type === 'chartTypeList') setChartTypeList(value);
        else if (type === 'filter_list') setDataFilterList(value);
        else if (type === 'y') /*setSelectedY(value);*/ selectedY.current = value;
        else if (type === 'x') setSelectedX(value);
        else if (type === 'yColor') setSelectedColorOfY(value);
        else if (type === 'yAuxiliary') setYAuxiliary(value);
        else if (type === 'yRange') setYRange(value);

        if (['yColor', 'multiTypeData', 'chartTypeList'].includes(type)) {
            const chartDataFilterInfo = targetSettingVal?.chartDataFilterInfo ? targetSettingVal?.chartDataFilterInfo : [];
            const chartTypeList = targetSettingVal.chartTypeList.map((chart) => chart.template_type_name);
            const chartYList = [];
            const chartColorList = [];
            targetSettingVal.chartTypeList.map((chart, i) => {
                chartYList.push(chartDataFilterInfo[i]?.y);
                chartColorList.push(chartDataFilterInfo[i]?.yColor);
            });
            drawChart(targetItemId, chartYList?.length > 0 ? { y: chartYList, color: chartColorList } : { y: [undefined, undefined], color: [undefined, undefined] }, chartTypeList);
        }
        if (['column_list', 'grid_setting', 'link_list'].includes(type)) {
            makeDataGrid(targetItemId, targetSettingVal['gridDataFilterInfo']['column_list'], selectedComponent.item);
        }
    };
    /* new options */

    const rawDatasetChanged = (selectedRaw) => {
        let htmlString = `<span></span><p>${translation.alert_reset_filter}</p>`;
        let swalResFn = (res) => {
            if (res.isConfirmed) setRawDatasetAndResetPageFilter();
        };

        const setRawDatasetAndResetPageFilter = () => {
            // reset raw dataset
            const selectedRawDataSet = selectedRaw[0];
            componentSettingChanged('raw_dataset', selectedRawDataSet);

            // const selectedComp = { ...selectedComponent };
            // setSelectedComponent(selectedComp);

            // init chart (data filter, y axis, x axis), datagrid (column list, data filter)
            if (selectedComponent.item.templateTypeStyle === 'CHART') {
                onAddYAxisList([]);
                onAddXAxisList([]);
                onAddFilterList([]);
            } else if (selectedComponent.item.templateTypeStyle === 'DG') {
                onAddDGColumnList([]);
                onAddFilterList([]);
            }
            // init binding column of page filter
            if (hasBindingColInPageFilter) {
                const cPageFilterList = [...pageFilterList];
                cPageFilterList.map((filter) => {
                    delete filter.value.binding_column;
                    return filter;
                });
                setPageFilterList(cPageFilterList);
            }
        };
        const hasBindingColInPageFilter = pageFilterList.find((filter) => !filter.value.binding_column) === undefined; // if page filter has binding column, then true
        // const hasPageFilterSetting = pageFilterList.find(filter => !filter.value.binding_column) !== undefined ? true : false;
        // check page filter state,
        // if there is page filter setting, then, alert page filter will be reset
        // if (hasPageFilterSetting) setRawDatasetAndResetPageFilter();
        // else confirm(htmlString, swalResFn);

        if (hasBindingColInPageFilter) confirm(htmlString, swalResFn);
        else setRawDatasetAndResetPageFilter();
    };
    /* --------------------------------------- */
    /* ------------- draw chart -------------- */
    const getChart = (params = [], type = []) => {
        const chart = MultiChart(params, type);
        return chart;
    };

    const drawChart = (itemId, chartData = [], multiType = ['Chart-Line', 'Chart-Scatter']) => {
        let randomData;
        const targetContent = document.querySelector(`[gs-id='${itemId}'] .grid-stack-item-content`);
        const makeRandomData = (num) => {
            const yData = [...demoEchartData];
            for (let i = 0; i < num; i++) {
                if (i < yData.length) continue;
                else
                    yData.push(
                        Array(10)
                            .fill()
                            .map((n) => Math.floor(Math.random() * 50 + 1))
                    );
            }
            return yData;
        };

        const getChartParam = (chartData, colorData, idx) => {
            const colorList = ['#0FB5AE', '#4046CA'];
            const chartDataY = chartData;
            if (chartDataY !== undefined) {
                let prevYDataLen = 0;
                for (let i = 0; i < idx; i++) prevYDataLen += chartDataList[0].y.length;
                const randomDataCnt = idx === 0 ? chartData.length : prevYDataLen + chartData.length;
                randomData = makeRandomData(randomDataCnt);
                setDemoEchartData(randomData);
            }

            const chartParam = {
                x: [[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]],
                y: !(chartDataY?.length > 0) ? [[]] : randomData.slice(idx, idx + chartDataY.length),
                legend: chartDataY?.length > 0 ? chartDataY.map((d) => (d?.name ? d?.name : d?.aas_master_column_name)) : [`legend${idx + 1}`],
                id: `[gs-id='${itemId}'] .grid-stack-item-content`,
                color: chartDataY?.length > 0 ? colorData : [colorList[idx]],
                backgroundColor: 'white',
                w: targetContent.clientWidth,
                h: targetContent.clientHeight - 48,
            };
            return chartParam;
        };
        const chartDataList = [];
        const colorList = chartData.color;
        const yDataList = chartData.y;
        yDataList.forEach((d, i) => {
            chartDataList.push(getChartParam(d, colorList[i], i));
        });

        const chart = getChart(chartDataList, multiType);
        setObserver(chart, targetContent);
    };

    const setObserver = (chart, target) => {
        const observer = new ResizeObserver((entries) => {
            for (let entry of entries) {
                chartResize(
                    // set responsive chart
                    chart,
                    target.clientWidth,
                    target.clientHeight - 48
                );
            }
        });
        observer.observe(target);
    };
    /* --------------------------------------- */
    /* ------- chart component setting ------- */
    // draw chart
    const getTemplateTypeMultiChartList = async () => {
        const param = { user_company_id: user.user_company_id };
        const res = await getTemplateTypeMultiChart(param);
        if (res) {
            setAllChartList(res.data.o_data);
            return res.data.o_data;
        }
    };

    const addChart = () => {
        const copy = [...chartTypeList];
        copy.push(allChartList[0]);
        componentSettingChanged('chartTypeList', copy);
        componentSettingChanged('chartDataFilterInfo', null);

        // init XAxis
        setAddXAxisDisable(true);
        setXAxis([]);
        setSelectedX([]);
    };

    const deleteChart = (idx) => {
        let cChartTypeList = [...chartTypeList];
        cChartTypeList = cChartTypeList.filter((chart, i) => i !== idx);
        componentSettingChanged('chartTypeList', cChartTypeList ? cChartTypeList : []);

        // gridItemInfo 에서 해당 chart 정보 삭제
        const compIdx = selectedComponent.idx;
        let cGridItemInfo = [...gridItemInfo];
        cGridItemInfo[compIdx].settingVal['chartDataFilterInfo'] = cGridItemInfo[compIdx].settingVal['chartDataFilterInfo'].filter((info, i) => i !== idx);

        setGridItemInfo(cGridItemInfo);
        setSelectedComponent({ ...selectedComponent, settingVal: cGridItemInfo.settingVal });
    };

    // set filter
    const deleteFilter = (idx) => {
        let cDataFilterList = [...dataFilterList];
        cDataFilterList.splice(idx, 1);
        componentSettingChanged('filter_list', cDataFilterList);

        // // gridItemInfo 에서 해당 chart filter 정보 삭제
        // const compIdx = selectedComponent.idx;
        // let cGridItemInfo = [...gridItemInfo];
        // cGridItemInfo[compIdx].settingVal.chartDataFilterInfo[targetListContent.idx].filter_value = cGridItemInfo[compIdx].settingVal.chartDataFilterInfo[targetListContent.idx]?.filter_value?.filter((f, i) => i !== idx);
        // setGridItemInfo(cGridItemInfo);
        // setSelectedComponent({ ...selectedComponent, settingVal: cGridItemInfo.settingVal });

        // filter value
        const filterValueList = selectedComponent.item.templateTypeStyle === 'CHART' ? [...selectedComponent.item.settingVal.chartDataFilterInfo[targetListContent.idx].filter_value] : [...selectedComponent.item.settingVal.gridDataFilterInfo.filter_value];
        filterValueList.splice(idx, 1);
        componentSettingChanged('filter_value', filterValueList);
    };

    const selectChartType = (e, idx) => {
        const copy = [...chartTypeList];
        copy[idx] = e;
        componentSettingChanged('chartTypeList', copy);
    };

    const changeTargetListContent = async (targetInfo, idx) => {
        setTargetListContent({ ...targetInfo, idx });
        const targetListContentType = targetInfo.type;
        if (['DATERANGE', 'SEARCH', 'DDL', 'TXT'].includes(targetListContentType)) {
            setSelectedBindingCol(pageFilterList[idx].value.binding_column);
        } else {
            // chart 인 경우
            const settingVal = selectedComponent?.item?.settingVal;
            const chartDataFilterInfo = settingVal?.chartDataFilterInfo[idx];
            const rawDataset = chartDataFilterInfo?.raw_dataset;
            setRawDataset(rawDataset);
            // if (rawDataset?.dataset_master_id != null)
            //     setYAxis(await getColsByDatasetId(rawDataset.dataset_master_id));
            // else
            //     setYAxis(null);
            setDataFilterList(chartDataFilterInfo?.filter_list);
            setSelectedX(settingVal?.x);
            // setSelectedY(chartDataFilterInfo?.y);
            selectedY.current = chartDataFilterInfo?.y;
            /* new options */
            setYRange(settingVal?.yRange ? settingVal.yRange : [null, null]);
            /* new options */
            setSelectedColorOfY(chartDataFilterInfo?.yColor);
            /* new options */
            setYAuxiliary(
                chartDataFilterInfo?.yAuxiliary
                    ? chartDataFilterInfo.yAuxiliary
                    : selectedY.current?.map((y) => {
                          return { min: false, max: false, avg: false };
                      })
            );
            /* new options */
        }
    };

    const clickViewDataSample = async () => window.open(`ViewSampleData?raw_dataset_id=${rawDataset?.dataset_master_id}`, '', 'popup=true');

    const onAddFilterList = (filterList) => {
        setAddFilterPopup(false);
        setAddBindingColPopup(false);

        if (['DATERANGE', 'DDL', 'TXT', 'SEARCH'].includes(targetListContent?.type)) {
            // page filter에서 column 선택
            pageFilterValueChanged(targetListContent.idx, 'binding_column', filterList);
        } else if (['CHART', 'DG'].includes(selectedComponent.item.templateTypeStyle)) {
            // chart, dg에서 column 선택
            const originFilterList = selectedComponent.item.templateTypeStyle === 'CHART' ? selectedComponent.item.settingVal.chartDataFilterInfo[targetListContent.idx].filter_list : selectedComponent.item.settingVal.gridDataFilterInfo.filter_list;
            const originFilterValueList = selectedComponent.item.templateTypeStyle === 'CHART' ? selectedComponent.item.settingVal.chartDataFilterInfo[targetListContent.idx].filter_value : selectedComponent.item.settingVal.gridDataFilterInfo.filter_value;
            const filterValueList = filterList.map((filter) => {
                const findFilterIdx = originFilterList.findIndex((f) => JSON.stringify(f) === JSON.stringify(filter));
                if (!(findFilterIdx < 0)) return originFilterValueList[findFilterIdx];
                else return null;
            });
            componentSettingChanged('filter_list', filterList);
            componentSettingChanged('filter_value', filterValueList);
        }
    };

    const datafilterChanged = (idx, type, value) => {
        const filterValueList = selectedComponent.item.templateTypeStyle === 'CHART' ? [...selectedComponent.item.settingVal.chartDataFilterInfo[targetListContent.idx].filter_value] : [...selectedComponent.item.settingVal.gridDataFilterInfo.filter_value];
        filterValueList[idx] = value;
        componentSettingChanged('filter_value', filterValueList);
    };

    const initAxisColor = (colorList, yList, selectedColorList) => {
        yList.forEach((y, i) => {
            const colorListKeys = Object.keys(colorList);
            const colorListValues = Object.values(colorList);
            if (selectedColorList[i] === undefined) {
                const usableColorIdx = colorListValues.indexOf(false);
                colorList[colorListKeys[usableColorIdx]] = true;
                selectedColorList[i] = colorListKeys[usableColorIdx];
            }
        });
        return [colorList, selectedColorList];
    };

    /* new options */
    const onAddYAxisList = (yAxisList) => {
        if (yAxisList.length !== 0) {
            const nowSelectedYNameList = selectedY.current?.map((y) => y.name);
            const cColorJson = { ...colorJson };
            const selectedColorList = [];
            const auxiliaryList = [];
            yAxisList?.forEach((y, i) => {
                // color & auxiliary
                if (nowSelectedYNameList?.includes(y.name)) {
                    selectedColorList[i] = nowSelectedYNameList[nowSelectedYNameList.indexOf(y.name)].color;
                    auxiliaryList[i] = yAuxiliary[i];
                } else {
                    selectedColorList[i] = undefined;
                    auxiliaryList[i] = { min: false, max: false, avg: false };
                }
            });
            const [initColorList, initSelectedColor] = initAxisColor(cColorJson, yAxisList, selectedColorList);
            setColorList(initColorList);
            componentSettingChanged('y', yAxisList);
            componentSettingChanged('yColor', initSelectedColor);
            componentSettingChanged('yAuxiliary', auxiliaryList);
        } else {
            setColorList([]);
            componentSettingChanged('y', []);
            componentSettingChanged('yColor', []);
            componentSettingChanged('yAuxiliary', []);
        }
        setAddYaxisPopup(false);
    };
    /* new options */

    const deleteAxis = (type, targetAxis) => {
        const axisList = type === 'y' ? [...selectedY.current] : [...selectedX];
        const targetIdx = axisList.indexOf(targetAxis);
        if (type === 'y') {
            // color setting after delete
            const selectedColorList = [...selectedColorOfY];
            selectedColorList.splice(targetIdx, 1);
            componentSettingChanged('yColor', selectedColorList);
            /* new options */
            // auxiliary
            componentSettingChanged('yAuxiliary', yAuxiliary.toSpliced(targetIdx, 1));
            /* new options */

            const colorNameList = Object.keys(colorJson);
            const colorListAfterDelete = {};
            colorNameList.forEach((color) => {
                colorListAfterDelete[color] = selectedColorList.includes(color) ? true : false;
            });
            setColorList(colorListAfterDelete);
        }
        // axis setting after delete
        axisList.splice(targetIdx, 1);
        componentSettingChanged(type, axisList);
    };

    const onAddXAxisList = (xAxisList) => {
        setSelectedX(xAxisList);
        componentSettingChanged('x', xAxisList);
        setAddXaxisPopup(false);
    };

    const setUsableColor = (targetColor) => {
        const cColorList = { ...colorList };
        const keys = Object.keys(cColorList);
        if (keys.includes(targetColor)) {
            cColorList[targetColor] = false;
            setColorList(cColorList);
        }
    };

    const colorBoxChanged = (e, col, idx) => {
        const targetCol = { ...col };
        setUsableColor(targetCol.color);
        const cSelectedY = [...selectedY.current];
        const cSelectedColorOfY = [...selectedColorOfY];
        cSelectedColorOfY[idx] = e;
        componentSettingChanged('y', cSelectedY);
        componentSettingChanged('yColor', cSelectedColorOfY);

        const chartDataFilterInfo = selectedComponent.item.settingVal.chartDataFilterInfo;
        const chartTypeList = selectedComponent.item.settingVal.chartTypeList.map((chart) => chart.template_type_name);
        const chartYList = [];
        const chartColorList = [];
        selectedComponent.item.settingVal.chartTypeList.forEach((chart, i) => {
            chartYList.push(chartDataFilterInfo[i]?.y);
            chartColorList.push(chartDataFilterInfo[i]?.yColor);
        });
        drawChart(selectedComponent.item.id, chartYList?.length > 0 ? { y: chartYList, color: chartColorList } : { y: [undefined, undefined], color: [undefined, undefined] }, chartTypeList);
    };

    /* new options */
    const onChangeAuxiliary = (list) => {
        componentSettingChanged('yAuxiliary', list);
    };
    /* new options */

    /* --------------------------------------- */
    /* -------- DG component setting --------- */
    const makeDataGrid = (id, colList, gridInfo) => {
        const gridSettingVal = gridInfo.settingVal.gridDataFilterInfo.grid_setting;
        const useNumbering = gridSettingVal?.useNumbering;
        const globalHeaderStyling = gridSettingVal?.header_styling;
        const globalBodyStyling = gridSettingVal?.body_styling;
        const defaultColList = [
            { column: { name: 'col_1' }, setting: {} },
            { column: { name: 'col_2' }, setting: {} },
        ];
        const targetColList = colList.length > 0 ? colList : defaultColList;
        const dataFieldList = targetColList.map((col) => col.column.name);
        const hasLinkOption = gridInfo.settingVal.gridDataFilterInfo.link_list?.length > 0 ? true : false;
        const headers = [];
        targetColList.map((col) => {
            if (col?.setting?.show !== false) {
                headers.push({
                    dataField: col.column.name,
                    caption: col.column.name,
                    width: col.setting.width ? `${col.setting.width}${col.setting.unit}` : undefined,
                    headerCellRender: getCellSetting(globalHeaderStyling, col.setting.header_styling),
                    cellRender: getCellSetting(globalBodyStyling, col.setting.body_styling),
                });
            }
        });

        // add numbering if user want
        if (useNumbering !== false) {
            headers.unshift({
                dataField: 'no',
                caption: 'No',
                headerCellRender: getCellSetting(globalHeaderStyling, {}),
                cellRender: getCellSetting(globalBodyStyling, {}),
            });
        }

        // add link column if user add any link option
        if (hasLinkOption) {
            headers.push({
                dataField: 'link',
                caption: 'Link',
                headerCellRender: getCellSetting(globalHeaderStyling, {}),
                cellRender: getCellSetting(globalBodyStyling, {}),
            });
        }

        // make dataSource
        const dataSource = [];
        if (colList.length > 0) {
            for (let i = 0; i < 100; i++) {
                const data = {};
                dataFieldList.forEach((df, dfIdx) => {
                    data['no'] = i + 1;
                    data[df] = dfIdx;
                });
                dataSource.push(data);
            }
        }

        // make data grid
        ReactDOM.createRoot(document.querySelector(`[gs-id="${id}"] .grid-stack-item-content`)).render(<CustomDataGrid id={id} dataSource={dataSource} headers={headers} defaultPageSize={gridSettingVal.paginationSize} callback={() => titleHeaderSetting(id, gridInfo)} />);
    };

    const getCellSetting = (global, specific) => {
        return {
            font_style: specific?.font_style?.length > 0 ? specific.font_style : global.font_style,
            align: specific?.align?.length > 0 ? specific.align : global.align,
            vertical: specific?.vertical?.length > 0 ? specific.vertical : global.vertical,
            font_color: specific?.font_color?.length > 0 ? specific.font_color : global.font_color,
            background: specific?.background?.length > 0 ? specific.background : global.background,
        };
    };

    const onAddDGColumnList = (colList) => {
        setSelectedColListForDG(colList);
        const initColList = colList.map((col) => {
            const initStyling = {
                font_style: [],
                align: '',
                vertical: '',
                font_color: '',
                background: '',
            };
            return {
                column: col,
                setting: { width: '', unit: 'px', header_styling: undefined, body_styling: undefined, show: true },
            };
        });
        componentSettingChanged('column_list', initColList);
        setAddDGColumnPopup(false);
    };

    /* --------------------------------------- */
    /* -------- ETC component setting -------- */
    const equipmentChanged = (selectedEquip) => {
        componentSettingChanged('machine_equipment', selectedEquip[0]);
    };

    useEffect(() => {
        // custom image 추가시
        if (selectedComponent?.item.templateTypeStyle === 'IMAGE') {
            componentSettingChanged('custom_image', customImage);
        }
    }, [customImage]);
    /* --------------------------------------- */
    /* ------------- handle save ------------- */
    const clickSaveBtn = () => {
        // check page filter validation
        // const needMoreSetFilter = pageFilterList.find((filter) => !filter.value.binding_column);
        const needMoreSetFilter = pageFilterList.find((filter) => {
            if (!filter.value.binding_column) return true;
            else if (filter?.value?.isHours === true && !(filter?.value?.hours?.length > 0)) return true;
            else return false;
        });
        if (needMoreSetFilter !== undefined) {
            let htmlString = `<span></span><p>${translation.alert_need_more_page_filter_setting}</p>`;
            fail(htmlString, () => {});
            return 0;
        }

        let htmlString = `<span></span><p>${translation.request_save}</p>`;
        let swalResFn = (res) => {
            if (res.isConfirmed) {
                handleSave();
                setLoaderVisible(true);
            }
        };
        confirm(htmlString, swalResFn);
    };

    const uploadImage = async () => {
        const originGridItemInfo = gridItemInfo;
        for await (let info of originGridItemInfo) {
            const imageUrlList = [];
            if (info.settingVal?.custom_image !== undefined) {
                for await (let img of info.settingVal.custom_image) {
                    if (info.settingVal.custom_image[0].data !== undefined) {
                        const bodyFormData = new FormData();
                        bodyFormData.append('img', img.file);
                        const res = await uploadImageInTemplate(user.user_company_id, bodyFormData);
                        imageUrlList.push(res.db_url);
                    }
                }
            }
            imageUrlList.length > 0 && (info.settingVal.custom_image = imageUrlList);
        }
        return originGridItemInfo;
    };

    const handleSave = async () => {
        const gridItemInfoAfterUploadImage = await uploadImage();
        const param = {
            user_company_id: user.user_company_id,
            menu_mapping_parent_id: data.menu_mapping_parent_id,
            menu_mapping_id: menuMappingInfo.id,
            menu_mapping_is_active: data.menu_mapping_is_active,
            user_master_id: user.user_master_id,
            template_master_id: parseInt(selectedTemplate.id),
            menu_mapping_name: data.menu_mapping_name,
            menu_mapping_comment: data.menu_mapping_comment,
            user_log_ip: ipAddr,
            menu_mapping_filter_json: JSON.stringify(pageFilterList),
            menu_mapping_layout_json: JSON.stringify(gridItemInfoAfterUploadImage),
            target_menu_mapping_id: data?.menu_mapping_id,
        };
        const targetApi = title === 'Add' ? insertMenuMapping : updateMenuMapping;
        const res = await targetApi(param);
        if (res !== 'fail' && res.message === 'MSG_100') {
            const insertMenuId = title === 'Add' ? res.data.o_data[0].menu_mapping_id : data.menu_mapping_id;
            await insertMenuLang(insertMenuId);
            await insertMenuSecurity(insertMenuId);

            let htmlString = `<span></span><p>${title === 'Add' ? translation.save_successfully : translation.update_successfully}</p>`;
            let swalResFn = (response) => {
                navigate('/Menu');
            };
            success(htmlString, swalResFn);
        } else if (res !== 'fail') {
            let htmlString = `<span></span><p>${translation[res.message]}</p>`;
            fail(htmlString);
        }
        setLoaderVisible(false);
    };

    const insertMenuLang = async (mapId) => {
        let queue = [];
        let caption = data.caption;
        for (let i = 0; i < caption.length; i++) {
            let body = {
                user_master_id: user.user_master_id,
                target_menu_mapping_id: mapId ? mapId : data.menu_mapping_id,
                menu_mapping_lang_code: caption[i].langCode,
                menu_mapping_caption_text: caption[i].caption,
            };

            queue.push(errorHandler(insertMenuMappingCaption, body));
        }

        await Promise.all(queue).then((val) => {
            //do something if success.
        });
    };

    const insertMenuSecurity = async (mapId) => {
        const security = data.security;
        if (security == null) return;
        let queue = [];
        let securityValue = '';

        for (let i = 0; i < security.length; i++) {
            securityValue = '';
            securityValue += security[i].create ? 'C' : '';
            securityValue += security[i].update ? 'U' : '';
            securityValue += security[i].delete ? 'D' : '';
            let body = {
                user_master_id: user.user_master_id,
                security_value: securityValue,
                target_id: security[i].security_master_id,
                parent_copy: security[i].propagateToParent,
                child_copy: security[i].propagateToChildren,
                target_menu_mapping_id: mapId ? mapId : data.menu_mapping_id,
            };

            const promise = errorHandler(insertMenuMappingSetting, body);
            queue.push(promise);
        }

        await Promise.all(queue).then((val) => {
            console.log(val);
        });
    };
    /* --------------------------------------- */
    /* ----------------- ETC ----------------- */
    const mouseMoveEvt = (e) => {
        const removeAllOpacity = () => {
            Array.from(document.querySelectorAll('.grid-stack-item-content')).forEach((item) => item.classList.remove('opacity'));
        };

        const setOpacityOfOthereItem = (id) => {
            // opacity 제외할 아이템의 아이디
            const otherGridStackItemList = Array.from(document.querySelectorAll('.grid-stack-item')).filter((item) => item.getAttribute('gs-id') !== id);
            otherGridStackItemList.forEach((item) => {
                // 클릭된 template 제외
                item.getAttribute('gs-id') === selectedComponent?.item.id ? null : item.querySelector('.grid-stack-item-content').classList.add('opacity');
            });
        };

        removeAllOpacity();

        const target = e.target.closest('.grid-stack-item-content');
        if (target !== null) {
            const targetId = e.target.closest('.grid-stack-item').getAttribute('gs-id');
            setOpacityOfOthereItem(targetId);
        } else if (target === null) {
            selectedComponent === null ? removeAllOpacity() : setOpacityOfOthereItem(selectedComponent.item.id);
        }
    };
    /* --------------------------------------- */
    /* ------------- page filter ------------- */
    const clickFilter = () => {
        setOnFilter(!onFilter);

        // component highlight
        const prevTarget = document.querySelector('.itemActive');
        prevTarget?.classList.remove('itemActive');
        setSelectedComponent(null);
    };

    const changeOrder = (originIdx, type, targetListType) => {
        // targetListType "page_filter", "dg_column", "link_list"
        let targetList;
        if (targetListType === 'page_filter') targetList = pageFilterList;
        else if (targetListType === 'dg_column') targetList = selectedComponent?.item?.settingVal?.gridDataFilterInfo?.column_list;
        else if (targetListType === 'link_list') targetList = selectedComponent?.item?.settingVal?.gridDataFilterInfo?.link_list;

        const copy = [...targetList];
        const desIdx = type === 'up' ? originIdx - 1 : originIdx + 1;
        const originVal = targetList[originIdx];
        const desVal = targetList[desIdx];
        copy[desIdx] = originVal;
        copy[originIdx] = desVal;

        if (targetListType === 'page_filter') setPageFilterList(copy);
        else if (targetListType === 'dg_column') {
            const columnList = copy.map((c) => c.column);
            setSelectedColListForDG(columnList);
            componentSettingChanged('column_list', copy);
        } else if (targetListType === 'link_list') {
            componentSettingChanged('link_list', copy);
        }
    };

    const pageFilterTypeChaged = (e, idx) => {
        const cPageFilterList = [...pageFilterList];
        cPageFilterList[idx]['type'] = e;
        cPageFilterList[idx]['value'] = {};
        setPageFilterList(cPageFilterList);

        // binding col datasource
        bindingColSettingForPageFilter(e.sys_division_sub_value);
    };

    const pageFilterValueChanged = (idx, valName, value) => {
        const cPageFilterList = [...pageFilterList];
        cPageFilterList[idx]['value'][valName] = value;
        setPageFilterList(cPageFilterList);
        if (valName === 'binding_column') {
            setSelectedBindingCol(value);
        }
    };

    useEffect(() => {
        // page filter 중 하나 클릭했을 때 binding columng에 대한 data source 가져옴
        if (targetListContent?.type) {
            bindingColSettingForPageFilter(targetListContent.type);
        }
    }, [targetListContent]);

    const bindingColSettingForPageFilter = async (divisionType) => {
        let idList = getRawDataSetIdListForPageFilter();
        if (divisionType === 'DATERANGE' && idList.length === 0) idList = [-1];
        if (idList?.length > 0) {
            const dataSource = await getCommonDatasetMasterPropertyDivision(idList, divisionType);
            setBindingColDataSource(dataSource);
            return dataSource;
        }
    };

    const getRawDataSetIdListForPageFilter = () => {
        const rawDatasetId = [];
        gridItemInfo.forEach((item) => {
            if (item.templateTypeStyle === 'DG' && item.settingVal.gridDataFilterInfo.raw_dataset.dataset_master_id) {
                rawDatasetId.push(item.settingVal.gridDataFilterInfo.raw_dataset.dataset_master_id);
            } else if (item.templateTypeStyle === 'CHART') {
                item.settingVal?.chartDataFilterInfo?.forEach((info) => {
                    if (info?.raw_dataset?.dataset_master_id) rawDatasetId.push(info?.raw_dataset?.dataset_master_id);
                });
            }
        });

        return [...new Set(rawDatasetId)];
    };

    const addPageFilter = () => {
        setPageFilterList([...pageFilterList, { type: {}, value: {} }]);
    };

    const getCommonDatasetMasterPropertyDivision = async (idList, divisionType) => {
        const cIdList = [...new Set(idList)];
        const divison = { DATERANGE: 'DATETIME', DDL: 'DROPDOWN', TXT: 'ALL', SEARCH: 'FWKEY', XAXIS: 'ALL' };
        const param = {
            user_company_id: user.user_company_id,
            sys_lang_code: lang,
            dataset_master_ids: cIdList.toString(),
            search_division: divison[divisionType],
        };
        const res = await getDatasetMasterPropertyDivisionByDsIds(param);
        if (res !== 'fail' && res.message === 'MSG_100') {
            return res?.data?.o_data ? res.data.o_data : [];
        } else return [];
    };

    const onDeletePageFilter = (idx) => {
        const cPageFilterList = [...pageFilterList];
        cPageFilterList.splice(idx, 1);
        setPageFilterList(cPageFilterList);
    };
    /* --------------------------------------- */

    return (
        <div className="custom-template custom-menu" onMouseMove={mouseMoveEvt}>
            <div className="right-content-title">
                <PageTitle pageState={title} lastPage={translation.binding_data} />
                <PageDropdown />
            </div>
            {/*<div className="block block-title"></div>*/}
            <div className="custom-template-body" style={{ display: 'flex' }}>
                <div className="card left-controller">
                    <div className="card-header">
                        <h6 className="card-title">{translation.option}</h6>
                    </div>
                    <div className="card-body">
                        <div className="template-info">
                            <div className="dx-field column">
                                <div className="dx-field-label">
                                    <p>{translation.menu_mapping_name}</p>
                                </div>
                                <div className="dx-field-value">
                                    <p>{data?.menu_mapping_name}</p>
                                </div>
                            </div>
                        </div>
                        <div className="component-content">
                            {selectedComponent === null && !onFilter && (
                                <div className="select-notice">
                                    <i className="dx-icon-m-tune"></i>
                                    <p>{translation.select_component_setting}</p>
                                </div>
                            )}
                            {selectedComponent !== null && (
                                <>
                                    {/* title은 모든 컴포넌트 필수 */}
                                    {selectedComponent.item.templateTypeStyle === 'CHART' && (
                                        <div className="chart">
                                            <div className="select-info">
                                                <TextboxField label={translation.component_title} value={componentTitle} onValueChanged={(e) => componentSettingChanged('title', e.value)} isColumn={true} isEssential={true} essentialMsg={translation.component_title + translation.is_required} size="xs" />
                                                <Button type="default" stylingMode="contained" icon="add" text={`${translation.add_new_chart_type} (${chartTypeList.length})`} className="btn-s-xs dx-button-mode-es" width="100%" onClick={addChart} disabled={hasSingleChart} />
                                            </div>
                                            <div className="setting-area chart">
                                                <ul className="list-toggle">
                                                    {selectedComponent?.item?.settingVal?.chartTypeList?.map((t, i) => {
                                                        return (
                                                            <li key={i}>
                                                                {/* new options */}
                                                                <ChartFilter
                                                                    idx={i}
                                                                    chart={t}
                                                                    rawDataset={rawDataset}
                                                                    selectChartType={selectChartType}
                                                                    allChartList={allChartList}
                                                                    hasSingleChart={hasSingleChart}
                                                                    useOnlyMultiChart={!hasSingleChart && chartTypeList?.length > 1}
                                                                    setSearchRawDatasetPopup={setSearchRawDatasetPopup}
                                                                    setAddFilterPopup={setAddFilterPopup}
                                                                    deleteChart={(e) => deleteChart(i)}
                                                                    deleteFilter={deleteFilter}
                                                                    deleteAxis={deleteAxis}
                                                                    targetListContent={targetListContent}
                                                                    changeTargetListContent={changeTargetListContent}
                                                                    filterValueChanged={datafilterChanged}
                                                                    setFilterDataSource={setFilterDataSource}
                                                                    selectedYAxis={selectedY.current}
                                                                    // setYAxis={setYAxis}
                                                                    setAddYaxisPopup={setAddYaxisPopup}
                                                                    setSearchEquipPopup={setSearchEquipPopup}
                                                                    setTargetFilter={setTargetFilter}
                                                                    selectedComponent={selectedComponent}
                                                                    colorBoxChanged={colorBoxChanged}
                                                                    clickViewDataSample={clickViewDataSample}
                                                                    colorList={selectedColorOfY}
                                                                    yAuxiliary={yAuxiliary}
                                                                    onChangeAuxiliary={onChangeAuxiliary}
                                                                    disabledDeleteChart={selectedComponent.item.settingVal.chartTypeList.length > 1 ? false : true}
                                                                />
                                                                {/* new options */}
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                                {/* new options */}
                                                {/* y축 범위 설정 */}
                                                <div className="y-axis-range-setting">
                                                    <div className="dx-field column">
                                                        <div className="dx-field-label">
                                                            <p>Y축 범위 설정</p>
                                                        </div>
                                                        <div className="dx-field-value min-max">
                                                            <TextboxField
                                                                value={yRange?.[0]}
                                                                onValueChanged={(e) => {
                                                                    if (e?.event?.type != undefined) componentSettingChanged('yRange', [e.value, yRange?.[1]]);
                                                                }}
                                                                size="xs"
                                                            />
                                                            <TextboxField
                                                                value={yRange?.[1]}
                                                                onValueChanged={(e) => {
                                                                    if (e?.event?.type != undefined) componentSettingChanged('yRange', [yRange?.[0], e.value]);
                                                                }}
                                                                size="xs"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* new options */}

                                                <div className="x-axis">
                                                    <div className="dx-field column">
                                                        <div className="dx-field-label">
                                                            <p>
                                                                {translation.x_axis} ({translation.component})
                                                            </p>
                                                        </div>
                                                        <div className="dx-field-value">
                                                            <Button
                                                                type="default"
                                                                stylingMode="contained"
                                                                icon="add"
                                                                text={`${translation.add_x_axis} (${selectedX?.length === undefined ? 0 : selectedX?.length})`}
                                                                className="btn-s-xs dx-button-mode-es"
                                                                width="100%"
                                                                onClick={() => setAddXaxisPopup(true)}
                                                                disabled={addXAxisDisable}
                                                            />
                                                            <AxisList axis={selectedX} type="x" colorBoxChanged={colorBoxChanged} deleteAxis={deleteAxis} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {selectedComponent.item.templateTypeStyle === 'CARD' && (
                                        <>
                                            <div className="select-info">
                                                {/*<TextboxField*/}
                                                {/*    label={translation.component_title}*/}
                                                {/*    value={componentTitle}*/}
                                                {/*    onValueChanged={e => componentSettingChanged("title", e.value)}*/}
                                                {/*    isColumn={true}*/}
                                                {/*    size="xs"*/}
                                                {/*    isEssential={true}*/}
                                                {/*/>*/}
                                                <SearchableField label={translation._equipment} isColumn={true} size="xs" text={selectedComponent?.item?.settingVal?.machine_equipment?.machine_equipment_code} onSearch={() => setSearchEquipPopup(true)} />
                                            </div>
                                        </>
                                    )}
                                    {selectedComponent.item.templateTypeStyle === 'DG' && (
                                        <div className="datagrid">
                                            <div className="select-info">
                                                <TextboxField label={translation.component_title} value={componentTitle} onValueChanged={(e) => componentSettingChanged('title', e.value)} isColumn={true} size="xs" isEssential={true} essentialMsg={translation.component_title + translation.is_required} />
                                                <SearchableField label={translation.raw_dataset} isColumn={true} hasTextboxPopup={true} size="xs" onSearch={() => setSearchRawDatasetPopup(true)} text={rawDataset?.dataset_master_name} disabled={targetListContent === null} />
                                                <Button type="default" stylingMode="contained" text={translation.view_data_sample} className="btn-s-xs dx-button-mode-es" width="100%" disabled={rawDataset?.dataset_master_id ? false : true} onClick={clickViewDataSample} />
                                            </div>
                                            <DataGridSetting
                                                serviceId={serviceId}
                                                rawDataset={rawDataset}
                                                selectedComponent={selectedComponent}
                                                setAddFilterPopup={setAddFilterPopup}
                                                setFilterDataSource={setFilterDataSource}
                                                filterValueChanged={datafilterChanged}
                                                setSearchEquipPopup={setSearchEquipPopup}
                                                setTargetFilter={setTargetFilter}
                                                setAddDGColumnPopup={setAddDGColumnPopup}
                                                setDGColumnDataSource={setDGColumnDataSource}
                                                changeOrder={changeOrder}
                                                componentSettingChanged={componentSettingChanged}
                                                deleteFilter={deleteFilter}
                                            />
                                        </div>
                                    )}
                                    {selectedComponent.item.templateTypeStyle === 'IMAGE' && (
                                        <>
                                            <div className="select-info">
                                                <TextboxField label={translation.component_title} value={componentTitle} onValueChanged={(e) => componentSettingChanged('title', e.value)} isColumn={true} size="xs" isEssential={true} essentialMsg={translation.component_title + translation.is_required} />
                                            </div>
                                            <div className="setting-area">
                                                <SingleFileField label="사용자입력이미지" imageData={customImage} setImageData={setCustomImage} isColumn={true} />
                                            </div>
                                        </>
                                    )}
                                    {selectedComponent.item.templateTypeStyle === 'TEXT' && (
                                        <>
                                            <div className="select-info">
                                                <TextboxField label={translation.component_title} value={componentTitle} onValueChanged={(e) => componentSettingChanged('title', e.value)} isColumn={true} size="xs" isEssential={true} essentialMsg={translation.component_title + translation.is_required} />
                                            </div>
                                            <div className="setting-area">
                                                {/*<TextboxField*/}
                                                {/*    label="사용자입력텍스트"*/}
                                                {/*    value={customText}*/}
                                                {/*    onValueChanged={e => componentSettingChanged("custom_text", e.value)}*/}
                                                {/*    isColumn={true}*/}
                                                {/*    size="xs"*/}
                                                {/*/>*/}
                                                <div>
                                                    <Button type="default" stylingMode="contained" text="텍스트 입력" className="btn-s-xs dx-button-mode-es" onClick={() => setTextEditorPopup(true)} width="100%" />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                            {onFilter && (
                                <>
                                    <div className="select-info">
                                        <Button type="default" stylingMode="contained" icon="add" text={`${translation.add_page_filter} (${pageFilterList?.length})`} className="btn-s-xs dx-button-mode-es" width="100%" onClick={addPageFilter} disabled={pageFilterList?.length < 4 ? false : true} />
                                    </div>
                                    <div className="setting-area">
                                        <ul className="list-toggle">
                                            {pageFilterList?.map((filter, i) => {
                                                return (
                                                    <li key={i}>
                                                        <PageFilter
                                                            key={i}
                                                            filterInfo={filter}
                                                            changeOrder={changeOrder}
                                                            idx={i}
                                                            isFirst={i === 0 ? true : false}
                                                            isLast={i === pageFilterList.length - 1 ? true : false}
                                                            pageFilterValueChanged={pageFilterValueChanged}
                                                            pageFilterTypeChaged={pageFilterTypeChaged}
                                                            setAddBindingColPopup={setAddBindingColPopup}
                                                            targetListContent={targetListContent}
                                                            changeTargetListContent={changeTargetListContent}
                                                            onDeletePageFilter={onDeletePageFilter}
                                                        />
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div>
                    <div className="filter-component" onClick={clickFilter}>
                        <ul className={!onFilter ? 'disabled' : ''}>
                            {[...pageFilterList].reverse().map((filter, i) => {
                                const type = filter.type.sys_division_sub_value;
                                if (type === 'DATERANGE')
                                    return (
                                        <li key={i}>
                                            <DateRangeField />
                                        </li>
                                    );
                                else if (type === 'DDL')
                                    return (
                                        <li key={i}>
                                            <DropdownField label="Drop down" width={190} />
                                        </li>
                                    );
                                else if (type === 'SEARCH')
                                    return (
                                        <li key={i}>
                                            <Search width="150px" disabled={true} />
                                        </li>
                                    );
                                else if (type === 'TXT')
                                    return (
                                        <li key={i}>
                                            <DropdownField label="Text" width={190} />
                                        </li>
                                    );
                            })}
                        </ul>
                        <Button className="btn-s-r" type="default" stylingMode="contained" disabled={true} text="apply" />
                    </div>
                    <div className="card-container">
                        <div className="card-container-box grid-stack" onClick={clickComponent}></div>
                    </div>
                    <div className="btn-group">
                        <Button
                            type="normal"
                            stylingMode="text"
                            className="btn-s-r"
                            text="PREV"
                            onClick={() => {
                                navigate(-1);
                            }}
                        >
                            <div>
                                <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                <span className="dx-button-text">{translation.prev}</span>
                            </div>
                        </Button>
                        <Button type="default" stylingMode="contained" className="btn-s-r" text="SAVE" onClick={clickSaveBtn} useSubmitBehavior={true}>
                            <div>
                                <LoadIndicator className="button-indicator" visible={loaderVisible} width={20} height={20} />
                                <span className="dx-button-text">{translation.save}</span>
                            </div>
                        </Button>
                    </div>
                </div>
            </div>
            <Popup width={1100} height={850} visible={searchEquipPopup} hideOnOutsideClick={true} onHiding={() => setSearchEquipPopup(false)} dragEnabled={true} position="center" showTitle={false}>
                <AddEquipmentPopup setVisible={setSearchEquipPopup} visibleState={searchEquipPopup} selectedEquip={equipment} title={translation.select_equipment} selectMode="single" onClickOk={equipmentChanged} />
            </Popup>
            <SelectRawDatasetPopup visible={searchRawDatasetPopup} onOk={rawDatasetChanged} onClose={() => setSearchRawDatasetPopup(false)} visibleState={searchRawDatasetPopup} selectedRawData={rawDataset ? [rawDataset] : []} />
            <AddColumnPopup popupTitle={translation.add_filter} type="filter" dataSource={filterDataSource} visible={addFilterPopup} onClose={() => setAddFilterPopup(false)} onSave={onAddFilterList} selectMode="multiple" selectedCol={dataFilterList} />
            <AddColumnPopup popupTitle={translation.add_x_axis} type="X" dataSource={xAxis} visible={addXaxisPopup} onClose={() => setAddXaxisPopup(false)} onSave={onAddXAxisList} selectMode="single" selectedCol={selectedX} />
            <AddColumnPopup popupTitle={translation.add_y_axis} type="Y" dataSource={yAxis} visible={addYaxisPopup} onClose={() => setAddYaxisPopup(false)} onSave={onAddYAxisList} selectMode="multiple" selectedCol={selectedY.current} />
            <AddColumnPopup
                popupTitle={translation.add_dg_columns}
                type="dg_column"
                dataSource={dgColumnDataSource}
                visible={addDGColumnPopup}
                onClose={() => setAddDGColumnPopup(false)}
                onSave={onAddDGColumnList}
                selectMode="multiple"
                selectedCol={selectedComponent?.item?.settingVal?.gridDataFilterInfo?.column_list.map((col) => col.column)}
            />
            <AddColumnPopup popupTitle={translation.add_binding_column} type="column" dataSource={bindingColDataSource} visible={addBindingColPopup} onClose={() => setAddBindingColPopup(false)} onSave={onAddFilterList} selectMode="single" selectedCol={selectedBindingCol} />
            <TextEditorPopup visible={textEditorPopup} componentSettingChanged={componentSettingChanged} onClose={() => setTextEditorPopup(false)} content={selectedComponent?.item?.settingVal?.editorContent} />
        </div>
    );
};

export default CustomMenu;

const AddColumnPopup = ({ popupTitle = '', type, dataSource, visible, onClose = () => {}, onSave = () => {}, selectMode = 'multiple', selectedCol }) => {
    const translation = useTranslation();
    const [header, setHeader] = useState();
    const [okBtnDisabled, setOkBtnDisabled] = useState(true);
    const [selectedRows, setSelectedRows] = useState();
    const gridRef = useRef();
    const [gridData, setGridData] = useState();
    // const backupData = JSON.parse(JSON.stringify(dataSource));
    // const instance = gridRef.current?.getInstance();

    useEffect(() => {
        const cDataGrid = JSON.parse(JSON.stringify(dataSource ? dataSource : []));
        setGridData(cDataGrid);
        // set selected rows, and if type is filte or column then need pre-work
        let targetRow;
        if (selectedCol?.length > 0) {
            targetRow = JSON.parse(JSON.stringify(selectedCol));
            if (['filter', 'column'].includes(type)) {
                const foundRows = cDataGrid.filter((data) => {
                    const same = targetRow.find((row) => row?.collection_name === data?.collection_name && row?.column_name === data?.column_name);
                    if (same != null) return data;
                });
                targetRow = foundRows;
            } else if (type === 'X') {
                // targetRow = [cDataGrid.find((d) => d?.name === selectedCol[0]?.name)];
                const find = cDataGrid.find((d) => (d?.name === selectedCol[0]?.name ? selectedCol[0]?.name : selectedCol[0]?.column_name));
                targetRow = find ? [find] : [];
            } else if (type === 'Y') {
                const foundRows = cDataGrid.filter((data) => {
                    const same = targetRow.find((row) => (row?.name ? row?.name : row?.aas_master_column_name) === data?.name && row?.aas_master_table_name === data?.aas_master_table_name);
                    if (same != null) return data;
                });
                targetRow = foundRows;
            }
        } else targetRow = [];
        const instance = gridRef.current?.getInstance();
        instance?.deselectAll();
        instance?.selectRows(targetRow);
        setSelectedRows(targetRow);

        // set header
        if (gridData?.length > 0) {
            const keys = Object.keys(gridData[0]).filter((key) => key !== 'title_json');
            const header = keys.map((key) => {
                return { dataField: key };
            });
            setHeader(header);
        }
    }, [visible]);

    const onRowSelected = (rows) => {
        // const instance = gridRef.current?.getInstance();
        if (rows == null) {
            // instance?.selectRows([]);
            setOkBtnDisabled(true);
            setSelectedRows([]);
        } else {
            // instance?.selectRows(Array.isArray(rows) ? rows : [rows]);
            setSelectedRows(Array.isArray(rows) ? rows : [rows]);
            setOkBtnDisabled(false);
        }
    };

    const removeTag = (i) => {
        let cur = [...selectedRows];
        let removed = cur.splice(i, 1);
        setSelectedRows(cur);

        const instance = gridRef.current.getInstance();
        instance.deselectRows(removed);
    };

    const removeAllTags = () => {
        const instance = gridRef.current.getInstance();
        // instance.deselectAll();
        instance.deselectRows(selectedRows);
        setSelectedRows([]);
    };

    const filtering = (keyword) => {
        const targetColumn = { Y: 'name', X: 'name', filter: 'column_name', dg_column: 'name', column: 'column_name' };
        let filtered = [];
        let lowKeyword = keyword?.toLowerCase();

        if (lowKeyword?.length > 0) filtered = dataSource.filter((data) => data[targetColumn[type]].toLowerCase().includes(lowKeyword));
        else filtered = dataSource;
        setGridData(filtered);
    };

    return (
        <Popup width={1100} height={850} visible={visible} hideOnOutsideClick={true} onHiding={onClose} dragEnabled={true} position="center" showTitle={false}>
            <div className="popup-add-yaxis">
                <div className="popup-header">
                    <p className="popup-title">{popupTitle}</p>
                </div>
                <div className="popup-body">
                    <div className="selected-item-viewer">
                        <div className="icon-checked">
                            <i></i>
                        </div>
                        <div className="selected-item-field">
                            <ul className="selected-items">
                                {selectedRows?.map((e, i) => {
                                    const itemName = ['filter', 'column'].includes(type) ? e?.column_name : e?.name;
                                    return (
                                        <li key={i} className="item">
                                            <p className="item-name">{itemName}</p>
                                            <i className="material-icons btn-delete" onClick={() => removeTag(i)}>
                                                cancel
                                            </i>
                                        </li>
                                    );
                                })}
                            </ul>
                            <div className="btn-all-delete" onClick={removeAllTags}>
                                <button>{translation.delete_all}</button>
                            </div>
                        </div>
                    </div>
                    <div className="item-select-box">
                        <ScrollView>
                            <div className="container-wrapper">
                                <div className="container">
                                    <BaseDataGrid ref={gridRef} toolbarModules={[visible && <FilterComponent label={translation.filter} onFilter={filtering} width={204} />]} headers={header} dataSource={gridData} allowModify={false} onSelectionChanged={onRowSelected} selectMode={selectMode} />
                                </div>
                            </div>
                        </ScrollView>
                    </div>
                </div>
                <div className="popup-footer">
                    <Button width={86} text={translation.cancel} type="normal" stylingMode="text" className="btn-s-r" onClick={onClose} />
                    <Button text={translation.save} width={86} type="default" stylingMode="contained" className="btn-s-r" disabled={okBtnDisabled} onClick={() => onSave(selectedRows)} />
                </div>
            </div>
        </Popup>
    );
};
