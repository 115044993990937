import { Button, Popup, ScrollView } from "devextreme-react";
import React from "react";
import { useEffect } from "react";
import { useImperativeHandle } from "react";
import { forwardRef } from "react";
import { useState } from "react";
import { getBusinessInfoByCompanyId } from "../../apiInterface/businessManagement/BusinessManagementAPI";
import { getProductGroup } from "../../apiInterface/item/ItemMasterAPI";
import { getLanguage, getUser } from "../../apiInterface/utils/Common";
import BaseDataGrid from "../../components/common/BaseDataGrid";
import useTranslation from "../../components/customHooks/translations";
import useErrorHandler from "../../components/customHooks/useErrorHandler";
import { useRef } from "react";
import { DropdownField, FilterComponent } from "../../components/common/FormField";


let searchText = "";
export default function SelectProductGroupPopup(props, ref) {
    const { onOk = () => { } } = props;
    const baseGridRef = useRef();
    const [visibleState, setVisibleState] = useState(false);
    const [dropdown, setDropdown] = useState([]);
    const [curDropdown, setCurDropdown] = useState(null);
    const [selectedItems, setSelectedItems] = useState([]);
    const [gridDataSource, setGridDataSource] = useState([]);
    const [disableOk, setDisableOk] = useState(true);
    const errorHandler = useErrorHandler();
    const language = getLanguage();
    const translation = useTranslation();

    const header = [
        { dataField: "product_group_name", caption: translation.product_group.toUpperCase(), dataType: "string", allowReordering: true },
        { dataField: "user_business_name", caption: translation._business.toUpperCase(), dataType: "string", allowReordering: true },
        { dataField: "product_group_comment", caption: translation.product_group_comment.toUpperCase(), dataType: "string", allowReordering: true },
    ]

    useEffect(() => {
        searchText = "";
    }, []);

    useImperativeHandle(ref, () => ({
        open: open,
        close: close,
    }));

    const open = () => {
        setVisibleState(true);
    }

    const close = () => {
        setVisibleState(false);
    }

    const onDropdownChanged = (e) => {
        setCurDropdown(e.value);
        fetchProductGroup(e.value, searchText);
    }

    const getBusinessDropdown = async () => {
        const user = getUser();
        const param = {
            user_company_id: user.user_company_id,
            sys_lang_code: language
        }

        const res = await errorHandler(getBusinessInfoByCompanyId, param);
        if (res) {
            setDropdown(res.data.o_data);
            if (res.data.o_data) {
                setCurDropdown(res.data.o_data[0].user_business_id);
            }
        }
    }

    const fetchProductGroup = async (businessId, text) => {
        const user = getUser();
        const param = {
            user_company_id: user.user_company_id,
            user_business_id: businessId,
            search_text: text
        }

        const res = await errorHandler(getProductGroup, param);
        if (res) {
            let data = res.data.o_data;
            const parseCode = new RegExp('\\((.+)\\)');
            const parseName = new RegExp('(.+)\\(');
            data = data.map((group) => {
                const code = group.product_group_name.match(parseCode);
                const name = group.product_group_name.match(parseName);
                group.product_group_code = code != null ? code[1] : "";
                group.product_group_name_only = name != null ? name[1] : "";
                return group;
            });
            setGridDataSource(data);
        }
    }

    const onClickOk = (e) => {
        onOk(selectedItems);
        close(e);
    }

    const onRowSelectionChanged = (e) => {
        if (e == null) {
            setDisableOk(true);
            setSelectedItems([]);
        }
        else {
            setDisableOk(false);
            setSelectedItems([e]);
        }
    }

    const searchClicked = async (val) => {
        searchText = val;
        fetchProductGroup(curDropdown, val);
    }

    const dropdownm = <DropdownField
        label={translation.select_business}
        dataSource={dropdown}
        displayExpr="user_business_name"
        valueExpr="user_business_id"
        onValueChanged={onDropdownChanged}
        value={curDropdown}
    />

    const filter = <FilterComponent
        label={translation.filter}
        onFilter={searchClicked}
    />

    const removeTag = (e) => {
        setSelectedItems([]);

        const instance = baseGridRef.current.getInstance();
        instance.deselectAll();
    }

    return (
        <Popup
            width={1100}
            height={850}
            visible={visibleState}
            hideOnOutsideClick={true}
            onInitialized={getBusinessDropdown}
            onHiding={close}
            dragEnabled={true}
            position="center"
            showTitle={false}
        >
            <div className="popup-select-product-group">
                <div className="popup-header">
                    <p className="popup-title">{translation.select_product_group}</p>
                </div>
                <div className="popup-body">
                    <div className="selected-item-viewer">
                        <div className="icon-checked">
                            <i></i>
                        </div>
                        <div className="selected-item-field">
                            {selectedItems?.length != 0 &&
                                <>
                                    <ul className="selected-items">
                                        <li className="item">
                                            <p className="item-name">{selectedItems[0]?.product_group_name}</p>
                                            <i className="material-icons btn-delete" onClick={removeTag}>cancel</i>
                                        </li>
                                    </ul>
                                    <div className="btn-all-delete" onClick={removeTag}>
                                        <button>Delete All</button>
                                    </div>
                                </>
                            }
                            {selectedItems?.length == 0 &&
                                <p className="comment-not-selected">{translation.select_product_group_notice}</p>
                            }
                        </div>
                    </div>
                    <div className="item-select-box">
                        <ScrollView>
                            <div className="container-wrapper">
                                <div className="container">
                                    <BaseDataGrid
                                        toolbarModules={[dropdownm, filter]}
                                        ref={baseGridRef}
                                        dataSource={gridDataSource}
                                        allowModify={false}
                                        headers={header}
                                        selectMode="single"
                                        keyExpr="product_group_id"
                                        onSelectionChanged={onRowSelectionChanged}
                                    />
                                </div>
                            </div>
                        </ScrollView>
                    </div>
                </div>
                <div className="popup-footer">
                    <Button
                        width={86}
                        text={translation.cancel}
                        type="normal"
                        stylingMode="text"
                        className="btn-s-r"
                        onClick={close}
                    />
                    <Button
                        text={translation.confirm}
                        width={86}
                        type="default"
                        stylingMode="contained"
                        className="btn-s-r"
                        onClick={e => onClickOk(e)}
                        disabled={disableOk}
                    />
                </div>
            </div>
        </Popup>
    )
}

SelectProductGroupPopup = forwardRef(SelectProductGroupPopup);