import { useEffect, useState } from "react";
import { GridStack } from "gridstack";
import { PageDropdown } from "../common/PageDropdown";
import PageTitle from "../common/PageTitle";
import { getMenuPageInitInformation } from "../../apiInterface/menu/MenuAPI"
import { getLanguage, getMenuMappingInfo, getUser } from "../../apiInterface/utils/Common";
import { getOneTemplateTypeById } from "../../apiInterface/templateManagment/TemplateManagementAPI";

const CMVPane2 = (props) => {
    const user = getUser();
    const [gridItemInfo, setGridItemInfo] = useState([]);
    let grid;

    useEffect(() => {
        //getCustomTemplateById();
        menuPageInit();
    }, [props.data]);

    useEffect(() => {
        const option = {
            disableResize: true,
            disableDrag: true,
        }
        grid = GridStack.init(option);
        grid.load(gridItemInfo, true);
    }, [gridItemInfo])

    const menuPageInit = async () => {
        const menuMappingInfo = await getMenuMappingInfo();
        const param = {
            user_company_id: user.user_company_id,
            user_master_id: user.user_master_id,
            menu_mapping_id: menuMappingInfo.id,
        }
        const res = await getMenuPageInitInformation(param);
        if (res !== 'fail') {
            let widgetInfo = res.data.o_data[0].menu_custom_layout;
            widgetInfo = await setGridStackItemContent(widgetInfo);
            setGridItemInfo(widgetInfo);
        }
    }

    const setGridStackItemContent = async (widgetInfo) => {
        const r = [];
        for await (let widget of widgetInfo) {
            const param = { template_type_id: widgetInfo[0].templateTypeId, sys_lang_code: getLanguage() }
            const res = await getOneTemplateTypeById(param);
            if (res !== 'fail') {
                widget.content = res.data.o_data[0].template_type_image
                r.push(widget);
            }
        }

        return r;
    }

    const setTemplateData = () => {

    }

    return (
        <div className="custom-template custom-menu">
            <div className="right-content-title">
                <PageTitle />
                <PageDropdown />
            </div>
            {/*<div className="block block-title"></div>*/}
            <div className="custom-template-body" style={{ display: "flex" }}>
                <div className="card-container">
                    <div className="card-container-box grid-stack">

                    </div>
                </div>
            </div>
        </div>
    )
}

export default CMVPane2;