import { createContext, useContext, useEffect, useState } from "react";

export const MenuMappinginfo = createContext();
export function useMenuMappingContext() {
    return useContext(MenuMappinginfo);
}
export default function MenuMappingInfoProvider(props) {
    const { children } = props
    const [menuMappingInfo, setMenuMappingInfo] = useState();

    return (
        <MenuMappinginfo.Provider value={{ menuMappingInfo, setMenuMappingInfo }}>
            {children}
        </MenuMappinginfo.Provider>
    )
}