import React, { useEffect, useState, useRef } from 'react';
import { Popup, Button, ColorBox, TextBox, ScrollView } from "devextreme-react";
import Editor from '../../../components/textEditor/Editor';

export default function TextEditorPopup(props) {
    const {
        visible,
        componentSettingChanged = () => { },
        onClose = () => { },
        content,
    } = props;

    const editorContent = useRef(content);

    const onApply = () => {
        componentSettingChanged("editorContent", editorContent.current);
        onClose();
    }

    return (
        <Popup
            width={788}
            height={564}
            visible={visible}
            hideOnOutsideClick={true}
            onHiding={onClose}
            title="Editor"
        >
            <div className="popup-text-editor">
                <div className="popup-body">
                    <ScrollView>
                        <Editor
                            visible={visible}
                            editorContent={editorContent}
                            initVal={content}
                        />
                        {/*<div>*/}
                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p><p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p><p>dsfjasklfsakl</p>*/}

                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p><p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p><p>dsfjasklfsakl</p>*/}

                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p><p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p><p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p><p>dsfjasklfsakl</p>*/}

                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p><p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p><p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p><p>dsfjasklfsakl</p>*/}

                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p><p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p><p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p><p>dsfjasklfsakl</p>*/}

                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*    <p>dsfjasklfsakl</p>*/}
                        {/*</div>*/}
                    </ScrollView>
                </div>
                <div className="popup-footer">
                    <Button
                        className="btn-s-r"
                        type="normal"
                        stylingMode="text"
                        text="CANCEL"
                        onClick={onClose}
                    />
                    <Button
                        className="btn-s-r"
                        type="default"
                        stylingMode="contained"
                        text="APPLY"
                        onClick={onApply}
                    />
                </div>
            </div>
        </Popup>
    );
}