import { useEffect, useState } from "react";
import useTranslation from "../../components/customHooks/translations";
import { Button, ColorBox, TextBox } from "devextreme-react";
import { TextboxField } from "../../components/common/FormField";
import ColumnStylingPopup from "./popup/ColumnStylingPopup";
import SelectGridLinkPopup from "./popup/SelectGridLinkPopup";
import SelectColumnsPopup from "./popup/SelectColumnsPopup";
import { useRef } from "react";

const listAnimation = () => {
    let closedList = document.querySelectorAll('.toggle-con');

    closedList.forEach((li) => {
        li.style.height = "0px";
    })

    let openedList = document.querySelector('.open .toggle-con');
    if (openedList != null) {
        let curHeight = document.querySelector('.open .toggle-con ul').clientHeight;
        openedList.style.height = curHeight + "px";
    }
}

const openList = (e) => {
    console.log(e.target);
    if (e.target.closest('.no-open-event') !== null) return;

    let clickedList = e.currentTarget.parentElement;

    if (clickedList.classList.contains('open')) {
        clickedList.classList.toggle('open');
    } else {
        let listToggle = document.querySelectorAll('.toggle-tit');
        listToggle.forEach((t) => {
            t.parentElement.classList.remove('open');
        })

        clickedList.classList.add('open');
    }

    listAnimation();
}

const changeListHeight = () => {
    let openedList = document.querySelector('.open .toggle-con');
    if (openedList != null) {
        let curHeight = document.querySelector('.open .toggle-con ul').clientHeight;
        openedList.style.height = curHeight + "px";
    }
}

export const LinkSetting = ({
    idx,
    value,
    isFirst,
    isLast,
    changeOrder = () => { },
    settingValueChanged = () => { },
    onDeleteLinkList = () => { },
    columnList,
}) => {
    const translation = useTranslation();
    // const labelEquipment = <>Parameter: <span>Equipment</span></>
    // const labelItemCode = <>Parameter: <span>ItemCode</span></>
    // const labelParameter = <>Parameter: <span>ParameterName</span></>
    const [isSelectColumnsOpen, setSelectColumnsOpen] = useState(false);
    const [linkValue, setLinkValue] = useState();
    const parameterIdx = useRef();
    const linkValueRef = useRef();
    const linkOpenType = [
        { type: "page", caption: translation.link_open_page },
        { type: "popup", caption: translation.link_open_popup }
    ]

    useEffect(() => {
        setLinkValue(value);
    }, [value])

    const valueChanged = (type, value) => {
        if (linkValue) {
            if (["wayToMove", "linkName", "captionEn", "captionKo"].includes(type)) {
                linkValue[type] = value;
            } else {    //parameter
                const key = Object.keys(linkValue.params[parameterIdx.current])[0];
                linkValue.params[parameterIdx.current][key] = value;
                parameterIdx.current = undefined;
                // setSelectColumnsOpen(false);
            }
            settingValueChanged("link_list", undefined, linkValue, idx);
        }
    }

    return (
        <li key={idx}>
            <div className="toggle-tit" onClick={(e) => openList(e)}>
                <div className="tit">
                    <i></i>
                    <p>{`${translation.link} ${idx + 1}`}</p>
                </div>
                <div className="no-open-event">
                    <div className="btn-order">
                        <Button
                            className="btn-s-xxs dx-button-mode-es"
                            type="default"
                            stylingMode="contained"
                            icon="arrowup"
                            disabled={isFirst}
                            onClick={e => changeOrder(idx, "up", "link_list")}
                        />
                        <Button
                            className="btn-s-xxs dx-button-mode-es"
                            type="default"
                            stylingMode="contained"
                            icon="arrowdown"
                            disabled={isLast}
                            onClick={e => changeOrder(idx, "down", "link_list")}
                        />
                    </div>
                    <Button
                        className="btn-s-xs btn-hover-red"
                        type="normal"
                        stylingMode="text"
                        icon="trash"
                        onClick={() => onDeleteLinkList(idx)}
                    />
                </div>
            </div>
            <div className="toggle-con">
                <ul>
                    <li>
                        <div className="dx-field column">
                            <div className="dx-field-label">
                                <p>{translation.link_open}</p>
                            </div>
                            <div className="dx-field-value">
                                <div className="toggle-radio-btn-big">
                                    {
                                        ["page", "popup"].map((way, i) => {
                                            return (
                                                <label key={i} onClick={() => valueChanged("wayToMove", way)}>
                                                    <input
                                                        type="radio"
                                                        name={`link-open-${idx}`}
                                                        checked={linkValue?.wayToMove === way ? true : false}
                                                        readOnly
                                                    />
                                                    <span>{translation[`link_open_${way}`]}</span>
                                                </label>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <TextboxField
                            size="xs"
                            label={translation.link_name}
                            isColumn={true}
                            value={linkValue?.linkName}
                            onValueChanged={(e) => valueChanged("linkName", e.value)}
                            valueChangeEvent="focusout"
                        />
                        <TextboxField
                            size="xs"
                            label={`${translation.link_name} - ${translation.english}`}
                            isColumn={true}
                            value={linkValue?.captionEn}
                            onValueChanged={(e) => valueChanged("captionEn", e.value)}
                            valueChangeEvent="focusout"
                        />
                        <TextboxField
                            size="xs"
                            label={`${translation.link_name} - ${translation.korean}`}
                            isColumn={true}
                            value={linkValue?.captionKo}
                            onValueChanged={(e) => valueChanged("captionKo", e.value)}
                            valueChangeEvent="focusout"
                        />
                    </li>
                    <li className="parameter">
                        {
                            linkValue?.params?.map((p, i) => {
                                const label = <>{translation.parameter} : <span>{Object.keys(p)[0]}</span></>
                                return (
                                    <div key={i}>
                                        <TextboxField
                                            size="xs"
                                            label={label}
                                            isColumn={true}
                                            value={Object.values(p)[0].name}
                                        />
                                        <Button
                                            className="btn-s-xs"
                                            icon="m-setting"
                                            type="normal"
                                            stylingMode="text"
                                            onClick={() => {
                                                parameterIdx.current = i;
                                                setSelectColumnsOpen(true);
                                            }}
                                        />
                                    </div>
                                )
                            })
                        }
                    </li>
                </ul>
            </div>
            <SelectColumnsPopup
                visible={isSelectColumnsOpen}
                onClose={() => {
                    parameterIdx.current = undefined;
                    setSelectColumnsOpen(false);
                }}
                dataSource={columnList}
                value={linkValue?.params[parameterIdx.current]}
                parameterIdx={parameterIdx.current}
                valueChanged={valueChanged}
            />
        </li>
    );
}