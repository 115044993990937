import React, { useEffect, useState, useRef } from 'react';
import { Popup, Button, ColorBox, TextBox } from "devextreme-react";
import useTranslation from '../../../components/customHooks/translations';

export default function ColumnStylingPopup(props) {
    const {
        settingTarget,
        targetIdx, // need for data grid column
        stylingType,
        visible,
        onClose = () => { },
        onApply = () => { },
        stylingVal,
        defaultValForStyling,
    } = props;
    const translation = useTranslation();
    const fontStyleList = ["dx-icon-m-font-bold", "dx-icon-m-font-italic"];
    const alignList = ["left", "center", "right"];
    const verticalList = ["top", "middle", "bottom"];

    const [fontStyle, setFontStyle] = useState();
    const [align, setAlign] = useState();
    const [vertical, setVertical] = useState();
    const [fontColor, setFontColor] = useState();
    const [background, setBackground] = useState();

    useEffect(() => {
        if (visible === true) {
            const settingVal = stylingVal ? stylingVal : defaultValForStyling[stylingType];
            setFontStyle(settingVal.font_style);
            setAlign(settingVal.align);
            setVertical(settingVal.vertical);
            setFontColor(settingVal.font_color);
            setBackground(settingVal.background);
        }
    }, [visible])

    const onRadioBtnClick = (type, value) => {
        if (type === "align")
            setAlign(value);
        else if (type === "vertical")
            setVertical(value);
    }

    const onCheckboxBtnClick = (value) => {
        const cFontStyle = [...fontStyle];
        const idx = cFontStyle.indexOf(value);
        if(idx < 0) {
            cFontStyle.push(value)
            setFontStyle(cFontStyle);
        } else {
            cFontStyle.splice(idx, 1);
            setFontStyle(cFontStyle);
        }
    }

    const onApplyBtnClick = () => {
        const value = {
            font_style: fontStyle,
            align: align,
            vertical: vertical,
            font_color: fontColor,
            background: background
        }
        onApply(settingTarget, stylingType, value, targetIdx);
    }

    return (
        <Popup
            width={308}
            height={368}
            visible={visible}
            title={translation.styling}
            hideOnOutsideClick={true}
            onHiding={onClose}
        >
            <div className="popup-column-styling">
                <div className="popup-content-s">
                    <div className="dx-field">
                        <div className="dx-field-label">
                            <p>{translation.font_style}</p>
                        </div>
                        <div className="dx-field-value">
                            <div className="toggle-radio-btn">
                                {
                                    fontStyleList.map((f, i) => {
                                        return (
                                            <label key={i} onClick={e => {
                                                e.preventDefault();
                                                onCheckboxBtnClick(f);
                                            }}>
                                                <input type="checkbox" checked={fontStyle?.includes(f) ? true : false} readOnly />
                                                <span className={f}></span>
                                            </label>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="dx-field">
                        <div className="dx-field-label">
                            <p>{translation.align}</p>
                        </div>
                        <div className="dx-field-value">
                            <div className="toggle-radio-btn">
                                {
                                    alignList.map((a, i) => {
                                        return (
                                            <label key={i} onClick={_ => onRadioBtnClick('align', a)}>
                                                <input type="radio" checked={a === align ? true : false} readOnly />
                                                <span className={`dx-icon-m-align-${a}`}></span>
                                            </label>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="dx-field">
                        <div className="dx-field-label">
                            <p>{translation.vertical}</p>
                        </div>
                        <div className="dx-field-value">
                            <div className="toggle-radio-btn">
                                {
                                    verticalList.map((v, i) => {
                                        return (
                                            <label key={i} onClick={_ => onRadioBtnClick('vertical', v)}>
                                                <input type="radio" checked={v === vertical ? true : false} readOnly />
                                                <span className={`dx-icon-m-vertical-${v}`}></span>
                                            </label>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="dx-field">
                        <div className="dx-field-label">
                            <p>{translation.font_color}</p>
                        </div>
                        <div className="dx-field-value">
                            <div className="color-box">
                                <TextBox
                                    readOnly={true}
                                    className="ix-tfs-r"
                                    value={fontColor}
                                    width="88px"
                                />
                                <ColorBox
                                    width="32px"
                                    value={fontColor}
                                    onValueChange={e=>setFontColor(e)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="dx-field">
                        <div className="dx-field-label">
                            <p>{translation.background_color}</p>
                        </div>
                        <div className="dx-field-value">
                            <div className="color-box">
                                <TextBox
                                    readOnly={true}
                                    className="ix-tfs-r"
                                    value={background}
                                    width="88px"
                                />
                                <ColorBox
                                    width="32px"
                                    value={background}
                                    onValueChange={e=>setBackground(e)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="popup-footer-s">
                    <Button
                        className="btn-s-xs"
                        type="normal"
                        stylingMode="text"
                        text={translation.cancel.toUpperCase()}
                        width="50%"
                        onClick={onClose}
                    />
                    <Button
                        className="btn-s-xs"
                        type="default"
                        stylingMode="contained"
                        text={translation.apply.toUpperCase()}
                        width="50%"
                        onClick={onApplyBtnClick}
                    />
                </div>
            </div>
        </Popup>
    )
}