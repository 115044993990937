import React, { useEffect, useState, useRef } from 'react';
import { Popup } from 'devextreme-react/popup';
import { Button } from 'devextreme-react/button';
import "../../../contents/styles/menu/popup.css";
import ColumnStylingPopup from './ColumnStylingPopup';
import SelectWhatToFormatPopup from './SelectWhatToFormatPopup';
import SetConditionPopup from './SetConditionPopup';
import { initStyling } from '../../../components/gridStackPane/CustomMenu';
import ScrollView from 'devextreme-react/scroll-view';

export default function ConditionFormattingPopup(props) {
    const {
        visible,
        onClose = () => { },
        settingValueChanged = () => { },
        // defaultValForStyling, // use this, when columns don't have value. It means just init value
        columnsList = [],
        formattingList, // when user save the conditional formatting, then exists
    } = props;

    const [isstylingPopupOpen, setStylingPopupOpen] = useState(false);
    const [isSelectWhatPopupOpen, setSelectWhatPopupOpen] = useState(false);
    const [isSetConditionPopupOpen, setSetConditionPopupOpen] = useState(false);

    const [saveBtnDisable, setSaveBtnDisable] = useState();
    const [ruleList, setRuleList] = useState();
    const [ruleIdx, setRuleIdx] = useState();

    useEffect(() => {
        setRuleList(formattingList);
    }, [visible])

    useEffect(() => {
        ruleList?.find(rule=>rule.column === undefined) !== undefined ? 
            setSaveBtnDisable(true) : setSaveBtnDisable(false);
    }, [ruleList])

    const addNewRule = () => {
        const initRule = {
            column: undefined,
            criteria: [],
            format: initStyling, // if need default value, then set here
            row: ["Entire Row"]
        }
        setRuleList([...ruleList, initRule]);
    }

    const setConditionalApply = (column, criteriaList) => {
        const cRulList = [...ruleList];
        cRulList[ruleIdx].column = column;
        cRulList[ruleIdx].criteria = criteriaList;
        setRuleList(cRulList);
        setSetConditionPopupOpen(false);
    }

    const formatApply = (value) => {
        const cRulList = [...ruleList];
        cRulList[ruleIdx].format = value;
        setRuleList(cRulList);
        setStylingPopupOpen(false);
    }

    const whatToApply = (value) =>{
        const cRulList = [...ruleList];
        cRulList[ruleIdx].row = value;
        setRuleList(cRulList);
        setSelectWhatPopupOpen(false);
    }

    const deleteRule = (idx) => {
        const cRuleList = [...ruleList];
        cRuleList.splice(idx, 1);
        setRuleList(cRuleList);
        setRuleIdx(undefined);
    }

    const changeOrder = (originIdx, type) => {
        const cRuleList = [...ruleList];
        const desIdx = type === "up" ? originIdx - 1 : originIdx + 1;
        const originVal = ruleList[originIdx];
        const desVal = ruleList[desIdx];
        cRuleList[desIdx] = originVal;
        cRuleList[originIdx] = desVal;
        setRuleList(cRuleList);
    }

    const onApply = () => {
        settingValueChanged("conditional_formatting", undefined, ruleList, undefined);
        setRuleIdx(undefined);
        onClose();
    }

    return (
        <Popup
            width={800}
            height={624}
            visible={visible}
            hideOnOutsideClick={true}
            onHiding={() => {
                setRuleIdx(undefined);
                onClose();
            }}
            title="Conditional Formatting"
        >
            <div className="popup-condition-formatting">
                <div className="popup-content">
                    <div className="add-new-rule">
                        <Button
                            className="btn-s-xs dx-button-mode-es"
                            type="default"
                            stylingMode="contained"
                            width="232px"
                            text="Add new Rule"
                            icon="add"
                            onClick={addNewRule}
                        />
                    </div>
                    <div className="condition-list-wrapper">
                        <ScrollView>
                            <ul className="condition-list">
                                {
                                    ruleList?.map((rule, idx) => {
                                        const stylingVal = rule.format;
                                        const styleJson = {};
                                        stylingVal.font_style.includes("dx-icon-m-font-bold") && (styleJson["font-weight"] = "bold");
                                        stylingVal.font_style.includes("dx-icon-m-font-italic") && (styleJson["font-style"] = "italic");
                                        stylingVal.font_color.length > 0 && (styleJson["color"] = stylingVal.font_color);
                                        stylingVal.align.length > 0 && (styleJson["text-align"] = stylingVal.align);
                                        stylingVal.vertical.length > 0 && (styleJson["vertical-align"] = stylingVal.vertical);
                                        stylingVal.background.length > 0 && (styleJson["background"] = stylingVal.background);
                                        return (
                                            <li>
                                                <p className="condition">
                                                    {"if "}
                                                    <span
                                                        onClick={() => {
                                                            setRuleIdx(idx);
                                                            setSetConditionPopupOpen(true);
                                                        }}>
                                                        {"set conditional "}
                                                    </span>
                                                    {"then apply "}
                                                    <span
                                                        onClick={() => {
                                                            setRuleIdx(idx);
                                                            setStylingPopupOpen(true);
                                                        }}>
                                                        {"this format "}
                                                    </span>
                                                    {"to the "}
                                                    <span
                                                        onClick={() => {
                                                            setRuleIdx(idx);
                                                            setSelectWhatPopupOpen(true);
                                                        }}>
                                                        {rule.row.join(', ')}
                                                    </span>
                                                </p>
                                                <div className="con-li-r">
                                                    <table>
                                                        <tr>
                                                            <td
                                                                className="preview"
                                                                onClick={() => {
                                                                    setRuleIdx(idx);
                                                                    setStylingPopupOpen(true);
                                                                }}
                                                                style={styleJson}
                                                            >
                                                                abcde
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <div className="btn-order">
                                                        <Button
                                                            className="btn-s-xxs dx-button-mode-es"
                                                            type="default"
                                                            stylingMode="contained"
                                                            icon="arrowup"
                                                            disabled={idx === 0 ? true : false}
                                                            onClick={() => changeOrder(idx, "up")}
                                                        />
                                                        <Button
                                                            className="btn-s-xxs dx-button-mode-es"
                                                            type="default"
                                                            stylingMode="contained"
                                                            icon="arrowdown"
                                                            disabled={idx === (ruleList.length - 1) ? true : false}
                                                            onClick={() => changeOrder(idx, "down")}
                                                        />
                                                    </div>
                                                    <Button
                                                        className="btn-s-xs btn-hover-red"
                                                        type="normal"
                                                        stylingMode="text"
                                                        icon="trash"
                                                        onClick={() => deleteRule(idx)}
                                                    />
                                                </div>
                                            </li>
                                        )
                                    })
                                }

                            </ul>
                            <p className="note">Note: higher rules take priority over lower rules.</p>
                        </ScrollView>
                    </div>
                </div>
                <div className="popup-footer">
                    <Button
                        className="btn-s-r"
                        type="normal"
                        stylingMode="text"
                        text="CANCEL"
                        onClick={() => {
                            setRuleIdx(undefined);
                            onClose();
                        }}
                    />
                    <Button
                        className="btn-s-r"
                        type="default"
                        stylingMode="contained"
                        text="SAVE"
                        disabled={saveBtnDisable}
                        onClick={onApply}
                    />
                </div>
            </div>
            <SetConditionPopup
                visible={isSetConditionPopupOpen}
                onClose={() => setSetConditionPopupOpen(false)}
                columnList={columnsList}
                column={(ruleList?.length > 0 && ruleIdx !== undefined) ? ruleList[ruleIdx].column : undefined}
                criteria={(ruleList?.length > 0 && ruleIdx !== undefined) ? ruleList[ruleIdx].criteria : undefined}
                setConditionalApply={setConditionalApply}
            />
            <ColumnStylingPopup
                visible={isstylingPopupOpen}
                onClose={() => setStylingPopupOpen(false)}
                onApply={(settingTarget, stylingType, value, targetIdx) => {
                    formatApply(value);
                }}
                stylingVal={(ruleList?.length > 0 && ruleIdx !== undefined) ? ruleList[ruleIdx].format : undefined}
                defaultValForStyling={{}}
            />
            <SelectWhatToFormatPopup
                visible={isSelectWhatPopupOpen}
                onClose={() => setSelectWhatPopupOpen(false) }
                columnList={columnsList}
                whatTo={(ruleList?.length > 0 && ruleIdx !== undefined) ? ruleList[ruleIdx].row : undefined}
                whatToApply={whatToApply}
            />
        </Popup>
    );
}