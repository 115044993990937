//test ㄴㅇㄹ
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../../contents/styles/dashboard/main-portal.css';
import 'material-icons';
import { getMenuMappingServiceAPI } from '../../apiInterface/menu/MenuAPI';
import { getLanguage, getUser, moveService, removeMenuMappingInfo, removeServiceMappingInfo } from '../../apiInterface/utils/Common';
import { swal } from '../../components/common/Swal';
import useTranslation from '../../components/customHooks/translations';
import useErrorHandler from '../../components/customHooks/useErrorHandler';

const MainPortal = (props) => {
    const [serviceMenu, setServiceMenu] = useState();
    const navigate = useNavigate();
    const translation = useTranslation();
    const errorHandler = useErrorHandler();

    const getServiceMenu = async () => {
        const user = getUser();
        const lang = getLanguage();
        if (!user) return;

        const params = {
            user_company_id: user.user_company_id,
            sys_lang_code: lang,
        };
        try {
            const res = await errorHandler(getMenuMappingServiceAPI, params);
            if (res != 'fail' && res.message === 'MSG_100') {
                setServiceMenu(res.data.o_data);
                console.log('mapping menu', res.data.o_data);
            } else {
                var iconType = 'bad';
                var swalWidth = '380';
                var htmlString = `<span>error while getting the menu mapping service.</span>`;
                var confirmationButtonText = translation.confirm;
                var swalResFn = (response) => {};

                // swal(swalWidth, iconType, htmlString, confirmationButtonText, swalResFn);
                console.log(`error while getting the menu mapping service`);
            }
        } catch (err) {
            var iconType = 'bad';
            var swalWidth = '380';
            var htmlString = `<span>${user['user_master_email']}</span><p>${translation.request_error}</p>`;
            var confirmationButtonText = translation.confirm;
            var swalResFn = (res) => {};
            // swal(swalWidth, iconType, htmlString, confirmationButtonText, swalResFn);
            console.log(`${translation.request_error}`);
        }
    };

    const addService = () => {
        var iconType = 'good';
        var swalWidth = '380';
        var htmlString = `<span>${translation.comingSoon}</span>`;
        var confirmationButtonText = translation.confirm;
        var swalResFn = (res) => {};
        swal(swalWidth, iconType, htmlString, confirmationButtonText, swalResFn);
    };

    useEffect(() => {
        getServiceMenu();
        removeServiceMappingInfo();
        removeMenuMappingInfo();
        //props.setMappingId(undefined);
        //props.setChangeState(Date.now());
    }, []);

    return (
        <div className="main-portal //no-scroll">
            <div className="portal-wrapper">
                <div className="portal-header">
                    <div className="portal-title">
                        <h1>{translation.mainportal_title1}</h1>
                        <span>{translation.mainportal_title2}</span>
                    </div>
                    <div className="btn-app-add" onClick={addService}>
                        <i className="dx-icon-m-add"></i>
                    </div>
                </div>
                <div className="portal-body">
                    <div className="apps-container">
                        <ul className="apps">
                            {serviceMenu?.map((menu, i) => (
                                <li
                                    key={i}
                                    className={`btn-app-${menu.template_master_type} ${!['framework', 'digitaltwin'].includes(menu.template_master_type) && 'badge-ai'}`}
                                    onClick={(e) => {
                                        moveService(menu);
                                    }}
                                >
                                    <div className="service-bg">
                                        <div className="service-txt">
                                            <p>{menu.menu_mapping_caption}</p>
                                            <span>{menu.menu_mapping_comment}</span>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="copywrite">
                <p>© INTERX</p>
                {/* <p>© ㈜엘앤에프</p> */}
            </div>
        </div>
    );
};

export default MainPortal;
