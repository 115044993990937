import React, { useEffect, useRef, useState } from "react";
import Button from 'devextreme-react/button';
import useTranslation from "../../components/customHooks/translations";
import { getUser, getIP, getLanguage } from '../../apiInterface/utils/Common';
import { getBusinessIds, getProcessInfo } from "../../apiInterface/process/ProcessAPI";
import useErrorHandler from "../../components/customHooks/useErrorHandler";
import { DropdownField, FilterComponent } from "../../components/common/FormField";
import BaseDataGrid from "../../components/common/BaseDataGrid";
import CustomStore from "devextreme/data/custom_store";
import { Popup, ScrollView } from "devextreme-react";
import { getFactoryInfo } from "../../apiInterface/factory/FactoryAPI";
import { getMoldInfo } from "../../apiInterface/machine/MoldAPI";

export default function EquipmentAddPupup(props) {
    const {
        title,
        onClickOk = () => { },
        onExit = () => { },
        visible,
    } = props;

    const translation = useTranslation();
    const gridRef = useRef();
    const errorHandler = useErrorHandler();
    const [selectedData, setSelectedData] = useState([]);
    const [curFactoryId, setCurFactoryId] = useState();
    const [dataSource, setDataSource] = useState([]);
    const [factoryDropdown, setFactoryDropdown] = useState([]);
    const searchText = useRef("");
    const tableHeaders = [
        { dataField: "machine_mold_no1", caption: translation.item_master_no, dataType: "number", isEdit: false, width: 100, alignment: "center", allowReordering: true, isFixed: true },
        { dataField: "machine_mold_name", caption: translation.machine_mold_name.toUpperCase(), dataType: "string", isRequired: true, allowReordering: true, isFixed: true },
        { dataField: "machine_mold_code", caption: translation.machine_mold_code.toUpperCase(), dataType: "string", allowReordering: true, isFixed: true },
        { dataField: "machine_mold_no", caption: translation.machine_mold_no.toUpperCase(), dataType: "string", allowReordering: true },
        { dataField: "operation_factory_name", caption: translation.operation_factory_name.toUpperCase(), dataType: "string", isEdit: false, width: 300, allowReordering: true },
        { dataField: "machine_mold_comment", caption: translation.machine_mold_comment.toUpperCase(), dataType: "string", isEdit: false, width: 300, allowReordering: true },
    ]

    const init = async () => {
        await getFactoryDD();
    }

    const getFactoryDD = async() => {
        const user = getUser();
        const lang = getLanguage();
        let param = {
            user_company_id: user.user_company_id,
            sys_lang_code: lang,
            user_business_id: user.user_business_id
        }
        const res = await errorHandler(getFactoryInfo, param);
        if (res) {
            if (res.data.o_data.length > 0) {
                setCurFactoryId(res.data.o_data[0].operation_factory_id);
            }
            setFactoryDropdown(res.data.o_data);
        }
    }

    const dropdownChanged = async (e) => {
        let factoryId = e.value;
        setCurFactoryId(factoryId);
        const customStore = new CustomStore({
            load: async (loadOptions) => load(loadOptions, factoryId)
        });
        setDataSource(customStore);
    }

    const load = async (loadOptions, factoryId) => {
        const user = getUser();
        const lang = getLanguage();
        const pageNo = parseInt(loadOptions["skip"] / loadOptions["take"]) + 1;
        let param = {
            user_company_id: user.user_company_id,
            operation_factory_id: factoryId,
            sys_lang_code: lang,
            row_count: loadOptions["take"],
            page_no: pageNo,
            search_text: searchText.current,
        }
        const res = await errorHandler(getMoldInfo, param);
        if (res) {
            return {
                data: res.data.o_data,
                totalCount: res.data.count,
            }
        }
        return {
            data: [],
            totalCount: 0,
        }
    }

    const onSelectionChanged = (val) => {
        setSelectedData(val);
    }

    const ok = (e) => {
        const instance = gridRef.current.getInstance();
        const selection = instance.getSelectedRowsData();
        onClickOk(selection);
        exit(e);
    }

    const exit = (e) => {
        const instance = gridRef.current.getInstance();
        instance.deselectAll();
        setSelectedData([]);
        onExit(e);
    }

    const dropdown = <DropdownField
        label={translation.select_factory}
        isSearchable={false}
        value={curFactoryId}
        dataSource={factoryDropdown}
        onValueChanged={dropdownChanged}
        valueExpr="operation_factory_id"
        displayExpr="operation_factory_name"
    />

    const filter = <FilterComponent
        label={translation.filter}
        width={204}
        onFilter={() => filtering()}
    />

    const filtering = (keyword) => {
        searchText.current = keyword;
    }

    const deleteAll = () => {
        gridRef.current.getInstance().deselectAll();
    }

    const deletOne = (e) => {
        gridRef.current.getInstance().deselectRows(e)
    }


    return (
        <Popup
            width={1100}
            height={850}
            visible={visible}
            hideOnOutsideClick={true}
            onHiding={exit}
            onInitialized={init}
            dragEnabled={true}
            position="center"
            showTitle={false}
        >
            <div className="popup-select-equipment">
                <div className="popup-header">
                    <p className="popup-title">{title}</p>
                </div>
                <div className="popup-body">
                    <div className="selected-item-viewer">
                        <div className="icon-checked">
                            <i></i>
                        </div>
                        <div className="selected-item-field">
                            {selectedData.length > 0 &&
                                <>
                                    <ul className="selected-items">
                                        {
                                        selectedData.map((e, i) =>
                                                <li key={i} className="item">
                                                <p className="item-name">{e.machine_mold_name}</p>
                                                <i onClick={() => { deletOne(e) }} className="material-icons btn-delete">cancel</i>
                                                </li>
                                            )
                                        }
                                    </ul>
                                    <div className="btn-all-delete">
                                    <button onClick={deleteAll }>Delete All</button>
                                    </div>
                                </>
                            }
                            {selectedData.length == 0 &&
                                <p className="comment-not-selected">{translation.select_mold_notice}</p>
                            }
                        </div>
                    </div>
                    <div className="item-select-box">
                        <ScrollView>
                            <div className="container-wrapper">
                                <div className="container">
                                    <BaseDataGrid
                                        toolbarModules={[dropdown, filter]}
                                        dataSource={dataSource}
                                        ref={gridRef}
                                        selectMode="multiple"
                                        allowModify={false}
                                        isRemote={true}
                                        onSelectionChanged={onSelectionChanged}
                                        headers={tableHeaders}
                                        isDoubleClicked={false}
                                    />
                                </div>
                            </div>
                        </ScrollView>
                    </div>
                </div>
                <div className="popup-footer">
                    <Button
                        width={86}
                        text={translation.cancel}
                        type="normal"
                        stylingMode="text"
                        className="btn-s-r"
                        onClick={exit}
                    />
                    <Button
                        text={translation.confirm}
                        width={86}
                        type="default"
                        stylingMode="contained"
                        className="btn-s-r"
                        onClick={ok}
                    />
                </div>
            </div>
        </Popup>
    )
}