import React, { useState, useRef } from "react";
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import "../../contents/styles/menu/menu-binding-data.css";
import DynamicSplitPaneDataBinding from "../../components/splitPane/DynamicSplitPaneDataBinding"
import CustomMenu from "../../components/gridStackPane/CustomMenu"
import { useEffect } from "react";


const MenuBindingData = () => {
    const location = useLocation();
    const templateId = location.state?.selectedTemplate?.id;
    const templateName = location.state?.selectedTemplate?.name;
    const navigate = useNavigate();

    return (
        //<DynamicSplitPaneDataBinding data={location.state.formData} title={title} templateId={templateId} path={location.state.path} />
        <>
            {
                //check sequence(adding progress) and if sequence is not valid navigate to /Menu
                //prevent user jump into this page without proper data.
                (location.state?.data?.seq == null || location.state?.data?.seq < 2) ?
                    <Navigate to="/Menu" /> :
                    <CustomMenu />
            }
        </>
    );

}
export default MenuBindingData;