import { Button, ColorBox, TextBox } from 'devextreme-react';
import { useEffect, useState } from 'react';
import { getColsByDatasetIdCustomTemp } from '../../apiInterface/aasFile/DatasetManagementAPI';
import { getSysDivisionCaptionByCode } from '../../apiInterface/menu/MenuAPI';
import { getDatasetMasterPropertyDivision } from '../../apiInterface/menu/MenuAPI';
import { getLanguage, getUser } from '../../apiInterface/utils/Common';
import { DropdownDataFilter, SearchDataFilter, TextboxDataFilter } from '../common/FilterModule';
import { DropdownField, SearchableField, TextboxField } from '../common/FormField';
import useTranslation from '../../components/customHooks/translations';
import { ColumnSetting } from '../../pages/menu/ColumnSetting';
import ConditionFormattingPopup from '../../pages/menu/popup/ConditionFormattingPopup';
import ColumnStylingPopup from '../../pages/menu/popup/ColumnStylingPopup';
import { LinkSetting } from '../../pages/menu/LinkSetting';
import SelectGridLinkPopup from '../../pages/menu/popup/SelectGridLinkPopup';
import CustomStore from 'devextreme/data/custom_store';
import { NumericRule } from 'devextreme-react/validator';

const listAnimation = () => {
    let closedList = document.querySelectorAll('.toggle-con');

    closedList.forEach((li) => {
        li.style.height = '0px';
    });

    let openedList = document.querySelector('.open .toggle-con');
    if (openedList != null) {
        let curHeight = document.querySelector('.open .toggle-con ul').clientHeight;
        openedList.style.height = curHeight + 'px';
    }
};

const openList = (e) => {
    console.log(e.target);
    if (e.target.closest('.no-open-event') !== null) return;

    let clickedList = e.currentTarget.parentElement;

    if (clickedList.classList.contains('open')) {
        clickedList.classList.toggle('open');
    } else {
        let listToggle = document.querySelectorAll('.toggle-tit');
        listToggle.forEach((t) => {
            t.parentElement.classList.remove('open');
        });

        clickedList.classList.add('open');
    }

    listAnimation();
};

const changeListHeight = () => {
    let openedList = document.querySelector('.open .toggle-con');
    if (openedList != null) {
        let curHeight = document.querySelector('.open .toggle-con ul').clientHeight;
        openedList.style.height = curHeight + 'px';
    }
};

const getFilterList = async (id) => {
    const user = getUser();
    const lang = getLanguage();
    const params = {
        user_company_id: user.user_company_id,
        sys_lang_code: lang,
        dataset_master_id: id,
        search_division: 'ALL',
    };
    const res = await getDatasetMasterPropertyDivision(params);
    if (res !== 'fail' && res.message === 'MSG_100') {
        return res.data.o_data;
    }
};

const getColsByDatasetId = async (id) => {
    // const user = getUser();
    // const params = {
    //     user_company_id: user.user_company_id,
    //     dataset_master_id: id
    // }
    // const res = await getColsByDatasetIdCustomTemp(params);
    // if (res !== "fail" && res.message === "MSG_100") {
    //     return res.data.o_data.map(y => {
    //         return { name: y.aas_master_column_name, aas_master_table_name: y.aas_master_table_name };
    //     });
    // }
    const user = getUser();
    const params = {
        user_company_id: user.user_company_id,
        dataset_master_id: id,
    };
    const res = await getColsByDatasetIdCustomTemp(params);
    if (res !== 'fail' && res.message === 'MSG_100') {
        return res.data.o_data.map((y) => {
            return { name: y.aas_master_column_name, aas_master_table_name: y.aas_master_table_name };
        });
    } else return [];
    // const customStore = new CustomStore({
    //     load: async (loadOptions) => {
    //         const user = getUser();
    //         const params = {
    //             user_company_id: user.user_company_id,
    //             dataset_master_id: id,
    //         };
    //         const res = await getColsByDatasetIdCustomTemp(params);
    //         if (res !== 'fail' && res.message === 'MSG_100') {
    //             return res.data.o_data.map((y) => {
    //                 return { name: y.aas_master_column_name, aas_master_table_name: y.aas_master_table_name };
    //             });
    //         }
    //     },
    // });

    // return customStore;
};

export const ChartFilter = ({
    idx,
    chart,
    rawDataset,
    setSearchRawDatasetPopup = () => {},
    setAddFilterPopup = () => {},
    setSearchEquipPopup = () => {},
    setAddYaxisPopup = () => {},
    colorBoxChanged = () => {},
    selectChartType = () => {}, // setChartTypeList
    allChartList,
    /* new options */
    hasSingleChart,
    /* new options */
    useOnlyMultiChart,
    deleteChart = () => {},
    deleteFilter = () => {},
    deleteAxis = () => {},
    targetListContent,
    changeTargetListContent = () => {},
    filterValueChanged = () => {},
    selectedYAxis = [],
    setYAxis = () => {},
    setFilterDataSource = () => {},
    setTargetFilter = () => {},
    selectedComponent,
    colorList,
    /* new options */
    yAuxiliary,
    onChangeAuxiliary = () => {},
    /* new options */
    clickViewDataSample = () => {},
    disabledDeleteChart,
}) => {
    const translation = useTranslation();
    const chartDataFilterInfo = selectedComponent?.item?.settingVal?.chartDataFilterInfo;
    const filterList = chartDataFilterInfo[targetListContent?.idx]?.filter_list;
    const filterValue = chartDataFilterInfo[targetListContent?.idx]?.filter_value;

    useEffect(() => {
        const fetch = async () => {
            if (rawDataset?.dataset_master_id !== undefined) {
                setFilterDataSource(await getFilterList(rawDataset?.dataset_master_id));
                // setYAxis(await getColsByDatasetId(rawDataset?.dataset_master_id));
            }
        };
        fetch();
    }, [rawDataset]);

    useEffect(() => {
        changeListHeight();
    }, [filterList]);

    const clickFilter = (idx, type) => {
        setTargetFilter({ idx, type });
    };

    return (
        <>
            <div
                className="toggle-tit"
                onClick={(e) => {
                    openList(e);
                    changeTargetListContent(chart, idx);
                }}
            >
                <div className="tit">
                    <i></i>
                    <p>
                        {translation.chart} {idx + 1}
                    </p>
                </div>
                <div className="no-open-event">
                    <p className="chart-type">{chart.template_type_name.split('-')[1]} chart</p>
                    <Button className="btn-s-xs btn-hover-red" type="normal" stylingMode="text" icon="trash" onClick={deleteChart} disabled={disabledDeleteChart} />
                </div>
            </div>
            <div className="toggle-con">
                <ul>
                    <li>
                        <DropdownField
                            dataSource={useOnlyMultiChart ? allChartList.filter((chart) => chart.template_type_is_multi === true) : allChartList}
                            value={chart?.template_type_id}
                            valueExpr="template_type_id"
                            displayExpr="template_type_name"
                            label={translation.chart_type}
                            isColumn={true}
                            size="xs"
                            onSelectionChanged={(e) => selectChartType(e.selectedItem, idx)}
                        />
                        <SearchableField label={translation.select_raw_dataset} isColumn={true} hasTextboxPopup={true} size="xs" onSearch={() => setSearchRawDatasetPopup(true)} text={rawDataset?.dataset_master_name} />
                        <Button type="default" stylingMode="contained" text={translation.view_data_sample} className="btn-s-xs dx-button-mode-es" width="100%" disabled={rawDataset?.dataset_master_id ? false : true} onClick={clickViewDataSample} />
                    </li>
                    <li>
                        <div className="dx-field column">
                            <div className="dx-field-label">
                                <p>{translation.chart_data_filter}</p>
                            </div>
                            <div className="dx-field-value">
                                {/* new options */}
                                <Button
                                    type="default"
                                    stylingMode="contained"
                                    icon="add"
                                    text={`${translation.add_filter} (${filterList?.length ? filterList?.length : 0})`}
                                    className="btn-s-xs dx-button-mode-es"
                                    width="100%"
                                    onClick={() => setAddFilterPopup(true)}
                                    disabled={rawDataset?.dataset_master_id ? false : true}
                                />
                                {/* new options */}
                            </div>
                        </div>
                        {filterList?.map((info, i) => {
                            const type = info.postgrekey ? info.postgrekey : info.datatype;
                            // set label
                            const filterTitle = JSON.parse(info.title_json);
                            const lang = getLanguage();
                            const langCodeTitle = filterTitle.find((title) => title.sys_lang_code === lang)?.sys_lang_code_title;
                            return <DataFilter key={i} idx={i} type={type} label={langCodeTitle} value={filterValue[i]} filterValueChanged={filterValueChanged} deleteFilter={(e) => deleteFilter(i)} setSearchEquipPopup={setSearchEquipPopup} clickFilter={clickFilter} />;
                        })}
                    </li>
                    <li>
                        <div className="dx-field column">
                            <div className="dx-field-label">
                                <p>{translation.y_axis}</p>
                            </div>
                            <div className="dx-field-value">
                                <Button type="default" stylingMode="contained" icon="add" text={`${translation.add_y_axis} (${selectedYAxis.length})`} className="btn-s-xs dx-button-mode-es" width="100%" onClick={() => setAddYaxisPopup(true)} disabled={rawDataset?.dataset_master_id ? false : true} />
                                {/* new options */}
                                <AxisList axis={selectedYAxis} type="y" hasSingleChart={hasSingleChart} colorBoxChanged={colorBoxChanged} colorList={colorList} yAuxiliary={yAuxiliary} onChangeAuxiliary={onChangeAuxiliary} changeListHeight={changeListHeight} deleteAxis={deleteAxis} />
                                {/* new options */}
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </>
    );
};

export const DataGridSetting = ({
    serviceId,
    rawDataset,
    selectedComponent,
    setAddFilterPopup = () => {},
    setFilterDataSource = () => {},
    filterValueChanged = () => {},
    setSearchEquipPopup = () => {},
    setTargetFilter = () => {},
    setAddDGColumnPopup = () => {},
    setDGColumnDataSource = () => {},
    changeOrder = () => {},
    componentSettingChanged = () => {},
    deleteFilter = () => {},
}) => {
    const translation = useTranslation();
    const paginationSizeList = [5, 10, 15, 20];

    const [isConditionFormattingOpen, setCondiitonFormattingOpen] = useState(false);
    const [isStylingColumnOpen, setStylingColumnOpen] = useState(false);
    const [isSelectGridLinkOpen, setSelectGridLinkOpen] = useState(false);
    const [stylingType, setStylingType] = useState();
    const [gridDataFilterInfo, setGridDataFilterInfo] = useState();

    useEffect(() => {
        const fetch = async () => {
            if (rawDataset?.dataset_master_id !== undefined) {
                setFilterDataSource(await getFilterList(rawDataset?.dataset_master_id));
                setDGColumnDataSource(await getColsByDatasetId(rawDataset?.dataset_master_id));
            }
        };
        fetch();
        // if (gridDataFilterInfo?.grid_setting === undefined) {
        //     componentSettingChanged("raw_dataset", {});
        // }
    }, [rawDataset]);

    useEffect(() => {
        // const gridDataFilterInfo = { ...selectedComponent?.item?.settingVal?.gridDataFilterInfo };
        const gridDataFilterInfo = JSON.parse(JSON.stringify(selectedComponent?.item?.settingVal?.gridDataFilterInfo));
        setGridDataFilterInfo(gridDataFilterInfo);
    }, [selectedComponent]);

    const clickFilter = (idx, type) => {
        setTargetFilter({ idx, type });
    };

    const settingValueChanged = (settingTarget, settingType, value, idx) => {
        // settingTarget => grid_setting, column_list
        // settingType => header_styling, body_styling, width, show
        const cGridDataFilterInfo = { ...gridDataFilterInfo };
        if (settingTarget === 'grid_setting') {
            cGridDataFilterInfo.grid_setting[settingType] = value;
            componentSettingChanged(settingTarget, cGridDataFilterInfo?.grid_setting);
        } else if (settingTarget === 'column_list' && settingType !== undefined) {
            cGridDataFilterInfo.column_list[idx]['setting'][settingType] = value;
            componentSettingChanged(settingTarget, cGridDataFilterInfo?.column_list);
        } else if (settingTarget === 'conditional_formatting') {
            cGridDataFilterInfo.conditional_formatting = value;
            componentSettingChanged(settingTarget, value);
        } else if (settingTarget === 'link_list') {
            cGridDataFilterInfo.link_list[idx].value = value;
            componentSettingChanged(settingTarget, cGridDataFilterInfo.link_list);
        }
        setGridDataFilterInfo(cGridDataFilterInfo);
        setStylingColumnOpen(false);
    };

    const onDeleteColumnList = (targetIdx) => {
        const originColList = [...gridDataFilterInfo.column_list];
        originColList.splice(targetIdx, 1);
        componentSettingChanged('column_list', originColList);
    };

    const onAddLinkList = (value) => {
        const linkList = [];
        value.forEach((v, i) => {
            const sameLink = gridDataFilterInfo?.link_list?.find((l) => JSON.stringify(l.link) === JSON.stringify(v.link));
            if (sameLink !== undefined) linkList.push(sameLink);
            else linkList.push(v);
        });

        componentSettingChanged('link_list', linkList);
    };

    const onDeleteLinkList = (targetIdx) => {
        const originLinkList = [...gridDataFilterInfo.link_list];
        originLinkList.splice(targetIdx, 1);
        componentSettingChanged('link_list', originLinkList);
    };

    return (
        <>
            <div className="setting-area">
                <div className="grid-settings">
                    <div className="toggle-tit" onClick={(e) => openList(e)}>
                        <div className="tit">
                            <i></i>
                            <p>{translation.grid_setting}</p>
                        </div>
                    </div>
                    <div className="toggle-con">
                        <ul>
                            <li>
                                <div className="dx-field">
                                    <div className="dx-field-label">
                                        <p>{translation.use_numbering}</p>
                                    </div>
                                    <div className="dx-field-value">
                                        <label className="toggle-on-off">
                                            <input type="checkbox" checked={!gridDataFilterInfo?.grid_setting?.useNumbering} value={gridDataFilterInfo?.grid_setting?.useNumbering} onClick={(e) => settingValueChanged('grid_setting', 'useNumbering', !(e.target.value === 'true'))} readOnly />
                                        </label>
                                    </div>
                                </div>
                                <div className="dx-field">
                                    <div className="dx-field-label">
                                        <p>{`${translation.header_styling}(${translation.global})`}</p>
                                    </div>
                                    <div className="dx-field-value">
                                        <Button
                                            className="btn-s-xs"
                                            type="normal"
                                            stylingMode="text"
                                            icon="m-setting"
                                            onClick={() => {
                                                setStylingType('header_styling');
                                                setStylingColumnOpen(true);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="dx-field">
                                    <div className="dx-field-label">
                                        <p>{`${translation.body_styling}(${translation.global})`}</p>
                                    </div>
                                    <div className="dx-field-value">
                                        <Button
                                            className="btn-s-xs"
                                            type="normal"
                                            stylingMode="text"
                                            icon="m-setting"
                                            onClick={() => {
                                                setStylingType('body_styling');
                                                setStylingColumnOpen(true);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="dx-field column">
                                    <DropdownField
                                        size="xs"
                                        label={translation.pagination_size}
                                        isColumn={true}
                                        dataSource={paginationSizeList}
                                        value={gridDataFilterInfo?.grid_setting?.paginationSize}
                                        onSelectionChanged={(e) => {
                                            settingValueChanged('grid_setting', 'paginationSize', e.selectedItem);
                                        }}
                                    />
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    <div className="dx-field column">
                        <div className="dx-field-label">
                            <p>{translation.raw_data_filter}</p>
                        </div>
                        <div className="dx-field-value">
                            {/* new options */}
                            <Button
                                type="default"
                                stylingMode="contained"
                                icon="add"
                                text={`${translation.add_filter} (${gridDataFilterInfo?.filter_list.length ? gridDataFilterInfo?.filter_list.length : 0})`}
                                className="btn-s-xs dx-button-mode-es"
                                width="100%"
                                disabled={rawDataset?.dataset_master_id ? false : true}
                                onClick={() => setAddFilterPopup(true)}
                            />
                            {/* new options */}
                        </div>
                    </div>
                    <ul>
                        {gridDataFilterInfo?.filter_list?.map((info, i) => {
                            const type = info.postgrekey ? info.postgrekey : info.datatype;
                            // set label
                            const filterTitle = JSON.parse(info.title_json);
                            const lang = getLanguage();
                            const langCodeTitle = filterTitle.find((title) => title.sys_lang_code === lang)?.sys_lang_code_title;
                            return <DataFilter key={i} idx={i} type={type} label={langCodeTitle} value={gridDataFilterInfo?.filter_value[i]} filterValueChanged={filterValueChanged} deleteFilter={(e) => deleteFilter(i)} setSearchEquipPopup={setSearchEquipPopup} clickFilter={clickFilter} />;
                        })}
                    </ul>
                </div>
                <div className="dx-field column">
                    <div className="dx-field-label">
                        <p>{translation.columns}</p>
                    </div>
                    <div className="dx-field-value">
                        <Button type="default" stylingMode="contained" icon="add" text={`${translation.add_columns} (${gridDataFilterInfo?.column_list?.length})`} className="btn-s-xs dx-button-mode-es" width="100%" disabled={rawDataset?.dataset_master_id ? false : true} onClick={() => setAddDGColumnPopup(true)} />
                    </div>
                </div>
                <ul className="list-toggle column-setting">
                    {gridDataFilterInfo?.column_list?.map((col, i) => {
                        return (
                            <ColumnSetting
                                idx={i}
                                columnInfo={col}
                                isFirst={i === 0 ? true : false}
                                isLast={i === gridDataFilterInfo?.column_list.length - 1 ? true : false}
                                changeOrder={changeOrder}
                                settingValueChanged={settingValueChanged}
                                onDeleteColumnList={onDeleteColumnList}
                                defaultValForStyling={gridDataFilterInfo?.grid_setting}
                            />
                        );
                    })}
                </ul>
                <div className="dx-field column">
                    <div className="dx-field-label">
                        <p>{translation.link_option}</p>
                    </div>
                    <div className="dx-field-value">
                        <Button type="default" stylingMode="contained" icon="add" text={`${translation.add_link} (${gridDataFilterInfo?.link_list ? gridDataFilterInfo?.link_list.length : 0})`} className="btn-s-xs dx-button-mode-es" width="100%" onClick={() => setSelectGridLinkOpen(true)} />
                    </div>
                </div>
                <ul className="list-toggle link-setting">
                    {gridDataFilterInfo?.link_list?.map((link, i) => {
                        return (
                            <LinkSetting
                                idx={i}
                                value={link.value}
                                isFirst={i === 0 ? true : false}
                                isLast={i === gridDataFilterInfo?.link_list.length - 1 ? true : false}
                                changeOrder={changeOrder}
                                settingValueChanged={settingValueChanged}
                                onDeleteLinkList={onDeleteLinkList}
                                columnList={selectedComponent?.item?.settingVal?.gridDataFilterInfo?.column_list?.map((col) => col.column)}
                            />
                        );
                    })}
                </ul>
                <div>
                    <Button type="default" stylingMode="contained" text="Conditional Formatting" className="btn-s-xs dx-button-mode-es" width="100%" onClick={() => setCondiitonFormattingOpen(true)} />
                </div>
            </div>
            <ConditionFormattingPopup visible={isConditionFormattingOpen} onClose={() => setCondiitonFormattingOpen(false)} settingValueChanged={settingValueChanged} columnsList={gridDataFilterInfo?.column_list} formattingList={gridDataFilterInfo?.conditional_formatting} />
            <ColumnStylingPopup settingTarget="grid_setting" stylingType={stylingType} visible={isStylingColumnOpen} onClose={() => setStylingColumnOpen(false)} onApply={settingValueChanged} stylingVal={gridDataFilterInfo?.grid_setting && stylingType ? gridDataFilterInfo?.grid_setting[stylingType] : undefined} />
            <SelectGridLinkPopup visible={isSelectGridLinkOpen} onClose={() => setSelectGridLinkOpen(false)} value={gridDataFilterInfo?.link_list} onAddLinkList={onAddLinkList} serviceId={serviceId} />
        </>
    );
};

export const AxisList = (props) => {
    /* new options */
    const { axis, type, hasSingleChart, colorBoxChanged = () => {}, colorList, yAuxiliary, onChangeAuxiliary, changeListHeight = () => {}, deleteAxis = () => {} } = props;
    const translation = useTranslation();
    /* new options */
    useEffect(() => {
        changeListHeight();
    }, [axis]);

    /* new options */
    const clickAuxiliary = (i, type, value) => {
        const cYAuxiliary = JSON.parse(JSON.stringify(yAuxiliary));
        cYAuxiliary[i][type] = value;
        onChangeAuxiliary(cYAuxiliary);
    };
    /* new options */

    return (
        <div name={`${type}-axis`} className="active">
            {axis?.map((col, i) => {
                return (
                    <div className="y-axis-li">
                        <div className="color-field" key={`${col.name}_field`}>
                            <div className="color-box">
                                <TextBox readOnly={true} className="ix-tfs-r" text={col.name} width={type === 'y' ? '' : '100%'} />
                                {type === 'y' ? (
                                    <ColorBox
                                        width="32px"
                                        defaultValue={colorList[i]}
                                        value={colorList[i]}
                                        onValueChange={(e) => {
                                            colorBoxChanged(e, col, i);
                                        }}
                                    />
                                ) : null}
                            </div>
                            <Button className="btn-s-xs btn-hover-red" type="normal" stylingMode="text" icon="trash" onClick={() => deleteAxis(type, col)} />
                        </div>
                        {/* new options */}
                        {type === 'y' && !hasSingleChart && (
                            <div className="dx-field row">
                                <div className="dx-field-label">
                                    <p>보조선 값 설정</p>
                                </div>
                                <div className="dx-field-value">
                                    <div className="toggle-radio-btn">
                                        {['min', 'max', 'avg'].map((type) => {
                                            return (
                                                <label
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        clickAuxiliary(i, type, !yAuxiliary?.[i]?.[type]);
                                                    }}
                                                >
                                                    <input type="radio" readOnly checked={yAuxiliary?.[i]?.[type] ? yAuxiliary?.[i]?.[type] : false} />
                                                    <span>{translation[type]}</span>
                                                </label>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        )}
                        {/* new options */}
                    </div>
                );
            })}
        </div>
    );
};

export const DataFilter = ({ type, label, setSearchEquipPopup = () => {}, filterValueChanged = () => {}, clickFilter = () => {}, idx, value, deleteFilter = () => {} }) => {
    if (type === 'machine_equipment') {
        return <SearchDataFilter idx={idx} label={label} text={value?.machine_equipment_code} onSearch={() => setSearchEquipPopup(true)} clickFilter={clickFilter} clickDeleteBtn={deleteFilter} />;
    } else if (type === 'double' || type === 'int') {
        return <DropdownDataFilter idx={idx} label={label} value={value !== undefined ? value : null} valueChanged={filterValueChanged} clickFilter={clickFilter} clickDeleteBtn={deleteFilter} changeListHeight={changeListHeight} />;
    } else {
        //type === "machine_mold" || type === "item_master" || type === "string"
        return <TextboxDataFilter idx={idx} label={label} type={type} value={value} valueChanged={filterValueChanged} clickFilter={clickFilter} clickDeleteBtn={deleteFilter} />;
    }
};

export const PageFilter = ({ filterInfo, changeOrder = () => {}, idx, isFirst, isLast, pageFilterValueChanged = () => {}, pageFilterTypeChaged = () => {}, setAddBindingColPopup, targetListContent, changeTargetListContent, onDeletePageFilter = () => {} }) => {
    const [defaultDateDataSource, setDefaultDateDataSource] = useState();
    const [filterTypeDataSource, setFilterTypeDataSource] = useState();
    const targetType = filterInfo?.type?.sys_division_sub_value;
    const translation = useTranslation();

    useEffect(() => {
        const fetchData = async () => {
            const defaultDateRes = await getDefaultDateDataSource('MENUFILTERDATERANGE');
            setDefaultDateDataSource(defaultDateRes);
            const filterTypeRes = await getDefaultDateDataSource('MENUFILTERTYPE');
            setFilterTypeDataSource(filterTypeRes);
        };
        fetchData();
    }, []);

    useEffect(() => changeListHeight());

    useEffect(() => {
        if (targetType === 'DATERANGE') {
            const defaultDateVal = filterInfo?.value?.default_date?.sys_division_sub_value;
            if (!defaultDateVal && defaultDateDataSource?.length > 0) pageFilterValueChanged(idx, 'default_date', defaultDateDataSource[0]);
        }
    }, [targetListContent]);

    const getDefaultDateDataSource = async (divisionCode) => {
        const params = {
            user_company_id: getUser().user_company_id,
            sys_division_code: divisionCode,
            sys_division_sub_lang_code: getLanguage(),
        };
        const res = await getSysDivisionCaptionByCode(params);
        if (res.message === 'MSG_100') {
            return res.data.o_data;
        }
    };

    const clickListToggle = (e) => {
        openList(e);
        changeTargetListContent({ type: filterInfo.type.sys_division_sub_value }, idx);
    };

    // binding column text
    const bindingCol = filterInfo?.value?.binding_column;
    let langCodeTitle;
    if (bindingCol) {
        const bindingColTitle = JSON.parse(bindingCol[0]?.title_json);
        const lang = getLanguage();
        langCodeTitle = bindingColTitle.find((title) => title.sys_lang_code === lang)?.sys_lang_code_title;
    }

    if (targetType === 'DATERANGE') {
        const defaultDateVal = filterInfo?.value?.default_date?.sys_division_sub_value;
        const validationOfText = <NumericRule message="숫자만 입력할 수 있습니다." />;
        //if (!defaultDateVal && defaultDateDataSource?.length > 0)
        //    pageFilterValueChanged(idx, "default_date", defaultDateDataSource[0]);

        return (
            <>
                <div className="toggle-tit" onClick={clickListToggle}>
                    <div className="tit">
                        <i className="dx-icon-m-dropdown"></i>
                        <p>{filterInfo?.type?.sys_division_sub_caption_text?.replace(` ${translation.type}`, '')}</p>
                    </div>
                    <div className="no-open-event">
                        <div className="btn-order">
                            <Button className="btn-s-xxs dx-button-mode-es" type="default" stylingMode="contained" icon="arrowup" disabled={isFirst} onClick={(e) => changeOrder(idx, 'up', 'page_filter')} />
                            <Button className="btn-s-xxs dx-button-mode-es" type="default" stylingMode="contained" icon="arrowdown" disabled={isLast} onClick={(e) => changeOrder(idx, 'down', 'page_filter')} />
                        </div>
                        <Button className="btn-s-xs btn-hover-red" type="normal" stylingMode="text" icon="trash" disabled={true} />
                    </div>
                </div>
                <div className="toggle-con">
                    <ul>
                        <li>
                            <SearchableField size="xs" label={translation.binding_column} isColumn={true} text={langCodeTitle} value={filterInfo?.value?.binding_column} valueChanged={(e) => pageFilterValueChanged(idx, 'binding_column', e)} onSearch={() => setAddBindingColPopup(true)} isEssential={true} />
                            <div className="dx-field column">
                                <div className="dx-field-label">
                                    <p className="essential">범위설정</p>
                                </div>
                                <div className="toggle-radio-btn-big">
                                    <label onClick={() => pageFilterValueChanged(idx, 'isHours', false)}>
                                        <input name="date_range" type="radio" checked={filterInfo?.value?.isHours !== true} readOnly />
                                        <span>기간</span>
                                    </label>
                                    <label onClick={() => pageFilterValueChanged(idx, 'isHours', true)}>
                                        <input name="date_range" type="radio" checked={filterInfo?.value?.isHours === true} readOnly />
                                        <span>시간</span>
                                    </label>
                                </div>
                                {/* ✨ 기간일 때 */}
                                {filterInfo?.value?.isHours !== true && (
                                    <DropdownField
                                        dataSource={defaultDateDataSource}
                                        displayExpr="sys_division_sub_caption_text"
                                        value={defaultDateVal ? defaultDateVal : defaultDateDataSource?.length > 0 ? defaultDateDataSource[0] : {}}
                                        valueExpr="sys_division_sub_value"
                                        onSelectionChanged={(e) => pageFilterValueChanged(idx, 'default_date', e.selectedItem)}
                                        size="xs"
                                        // label={translation.default_period}
                                        // isEssential={true}
                                        isColumn={true}
                                    />
                                )}

                                {/* ✨시간일 때 */}
                                {filterInfo?.value?.isHours === true && (
                                    <>
                                        <div className="time" data-label={translation.time}>
                                            <TextboxField size="xs" isColumn={true} value={filterInfo?.value?.hours} onValueChanged={(e) => pageFilterValueChanged(idx, 'hours', e.value)} validationRules={validationOfText} />
                                        </div>
                                        <p className="info-txt">
                                            <i className="dx-icon-m-info-outline"></i>정수로 입력 (최대값 24)
                                        </p>
                                    </>
                                )}
                            </div>
                        </li>
                    </ul>
                </div>
            </>
        );
    } else {
        if (filterTypeDataSource?.length > 0 && targetType === undefined)
            // init filter Info
            pageFilterTypeChaged(filterTypeDataSource[0], idx);
        else
            return (
                <>
                    <div className="toggle-tit" onClick={clickListToggle}>
                        <div className="tit">
                            <i className="dx-icon-m-dropdown"></i>
                            <p>{filterInfo?.type?.sys_division_sub_caption_text?.replace(` ${translation.type}`, '')}</p>
                        </div>
                        <div className="no-open-event">
                            <div className="btn-order">
                                <Button className="btn-s-xxs dx-button-mode-es" type="default" stylingMode="contained" icon="arrowup" disabled={isFirst} onClick={(e) => changeOrder(idx, 'up', 'page_filter')} />
                                <Button className="btn-s-xxs dx-button-mode-es" type="default" stylingMode="contained" icon="arrowdown" disabled={isLast} onClick={(e) => changeOrder(idx, 'down', 'page_filter')} />
                            </div>
                            <Button className="btn-s-xs btn-hover-red" type="normal" stylingMode="text" icon="trash" onClick={(e) => onDeletePageFilter(idx)} />
                        </div>
                    </div>
                    <div className="toggle-con">
                        <ul>
                            <li>
                                <DropdownField
                                    dataSource={filterTypeDataSource}
                                    displayExpr="sys_division_sub_caption_text"
                                    value={targetType}
                                    valueExpr="sys_division_sub_value"
                                    size="xs"
                                    label={translation.type}
                                    isEssential={true}
                                    isColumn={true}
                                    onSelectionChanged={(e) => pageFilterTypeChaged(e.selectedItem, idx)}
                                />
                                <SearchableField size="xs" label={translation.binding_column} isColumn={true} text={langCodeTitle} valueChanged={(e) => pageFilterValueChanged(idx, 'binding_column', e)} onSearch={() => setAddBindingColPopup(true)} isEssential={true} />
                            </li>
                        </ul>
                    </div>
                </>
            );
    }
};
