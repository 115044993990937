export default function UpDown(props) {
    const {
        className,
        text,
    } = props;

    return (
        <div className="updown">
            <i className={className}></i>
            <span>{text}</span>
        </div>
    )
}