﻿//test
import React, { useEffect, useState, useRef } from 'react';
import Button from 'devextreme-react/button';
import TextBox from 'devextreme-react/text-box';
import FileUploader from 'devextreme-react/file-uploader';
import { Validator, RequiredRule } from 'devextreme-react/validator';
import { getCompanyInfoByMasterId, updateCompanyInfoByMasterId, uploadLogo } from '../../apiInterface/companyManagement/CompanyManagementAPI';
import { getLanguage, getUser } from '../../apiInterface/utils/Common';
import { swal, useSwal } from '../../components/common/Swal';
import useTranslation from '../../components/customHooks/translations';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { actionDirect, moveFocus, submit } from '../../components/common/FormFuncWithEnter';
import { useNavigate } from 'react-router-dom';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import { PageDropdown } from '../../components/common/PageDropdown';
import { PageTitle } from '../../components/common/PageTitle.jsx';
import { BASE_URL } from '../../config';

const CompanyManagementInfo = () => {
    const [companyName, setCompanyName] = useState(null);
    const [companyRegNo, setCompanyRegNo] = useState(null);
    const [companyOwner, setCompanyOwner] = useState(null);
    const [zipCode, setZipcode] = useState(null);
    const [addressLine1, setAddressLine1] = useState(null);
    const [addressLine2, setAddressLine2] = useState(null);
    const [contactNo, setContactNo] = useState(null);
    const [comment, setComment] = useState(null);
    const [companyLogo, setCompanyLogo] = useState('');
    const [isDropZoneActive, setIsDropZoneActive] = useState(false);
    const [textVisible, setTextVisible] = useState(true);
    const [allowedFileExtensions, setAllowFileExtensions] = useState(['.jpg', '.jpeg', '.gif', '.png']);
    const [modifiedByName, setModifiedByName] = useState(null);
    const [modifiedByDate, setModifiedByDate] = useState(null);
    const [modifiedByDepartment, setModifiedByDepartment] = useState(null);
    const [modifiedByDuty, setModifiedByDuty] = useState(null);

    const translation = useTranslation();
    const language = getLanguage();
    const userInfo = getUser();
    const navigate = useNavigate();
    const errorHandler = useErrorHandler();
    const { success } = useSwal();
    const uploaderRef = useRef();
    const getInstance = () => {
        return uploaderRef.current?.instance;
    };

    const [imageData, setImageData] = useState();
    const [imageLogo, setImageLogo] = useState();

    const openDaumPostcode = () => {
        const daum = window.daum;
        new daum.Postcode({
            oncomplete: function (data) {
                var roadAddr = data.roadAddress; // 도로명 주소 변수
                var extraRoadAddr = ''; // 참고 항목 변수

                if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
                    extraRoadAddr += data.bname;
                }
                if (data.buildingName !== '' && data.apartment === 'Y') {
                    extraRoadAddr += extraRoadAddr !== '' ? ', ' + data.buildingName : data.buildingName;
                }
                if (extraRoadAddr !== '') {
                    extraRoadAddr = ' (' + extraRoadAddr + ')';
                }

                document.querySelector('#sample4_postcode input').value = data.zonecode;
                document.querySelector('#sample4_roadAddress input').value = roadAddr + extraRoadAddr;
                setZipcode(data.zonecode);
                setAddressLine1(roadAddr + extraRoadAddr);

                // focus move
                document.querySelector(`[focusidx='6']`).focus();
            },
        }).open({ autoClose: true });
    };

    const getCompanyInfo = async () => {
        const param = {
            user_master_id: userInfo.user_master_id,
            sys_lang_code: language,
        };

        const res = await errorHandler(getCompanyInfoByMasterId, param);
        if (res) {
            const companyInfo = res.data.o_data[0];
            if (companyInfo.user_company_logo.length > 0) {
                setCompanyLogo(`${BASE_URL}static_files/${companyInfo.user_company_logo}`);
                setImageLogo(`${companyInfo.user_company_logo}`);
                setTextVisible(false);
            }
            setCompanyName(companyInfo.user_company_name);
            setCompanyRegNo(companyInfo.user_company_registerno);
            setCompanyOwner(companyInfo.user_company_owner);
            setZipcode(companyInfo.user_company_zipcode);
            setComment(companyInfo.user_company_comment);
            setAddressLine1(companyInfo.user_company_address);
            setAddressLine2(companyInfo.user_company_address2);
            setContactNo(companyInfo.user_company_phone);
            setModifiedByName(companyInfo.modified_by_name);
            setModifiedByDate(companyInfo.modified_date);
            setModifiedByDepartment(companyInfo.modified_by_department);
            setModifiedByDuty(companyInfo.modified_by_duty);
        }
    };

    useEffect(() => {
        getCompanyInfo();
    }, []);

    //uploading company logo
    const uploadImage = async () => {
        // const files = document.getElementsByName('user_company_logo')[0].files;
        const files = imageData;

        if (files !== undefined) {
            // if (files.length == 1) {
            if (files.length < 1) return { file_name: '', db_url: '' };

            const file = files[0].file;

            var bodyFormData = new FormData();
            bodyFormData.append('img', file);
            const res = await errorHandler(uploadLogo, bodyFormData);
            if (res) {
                return res;
            } else {
                return null;
            }
        } else {
            return { db_url: imageLogo };
        }
    };

    const onDropZoneEnter = (e) => {
        if (e.dropZoneElement.id === 'dropzone-external') {
            setIsDropZoneActive(true);
        }
    };

    const onDropZoneLeave = (e) => {
        if (e.dropZoneElement.id === 'dropzone-external') {
            setIsDropZoneActive(false);
        }
    };

    const onUploadStarted = (e) => {
        const { file } = e;
        const fileReader = new FileReader();
        fileReader.onload = () => {
            setIsDropZoneActive(false);
            setCompanyLogo(fileReader.result);
            setTextVisible(false);
        };
        fileReader.readAsDataURL(file);
    };
    const updateCompanyInfo = async () => {
        const language = getLanguage();
        const user = getUser();
        let imgRes = await uploadImage();
        const formElem = document.querySelector('.company-management-info');
        const inputElements = formElem.querySelectorAll('input, select, checkbox, textarea');
        var data = {};
        inputElements.forEach((input) => {
            data[input.name] = input.value;
        });

        console.log('imgRes', imgRes);

        if (!imgRes.db_url == '') data['user_company_logo'] = imgRes.db_url;
        data['user_master_id'] = user.user_master_id;
        data['sys_lang_code'] = language;

        const res = await errorHandler(updateCompanyInfoByMasterId, data);
        if (res) {
            var swalResFn = (response) => {
                //navigate('/Dashboard');
                // document.querySelector('.company-logo').click();

                // if (sessionStorage.getItem('menuMappingInfo') === null) {
                //     navigate('/MainPortal');
                // } else {
                //     navigate('/Dashboard');
                // }
                navigate('/Dashboard');
            };

            success(`<p>${translation.save_successfully}</p>`, swalResFn);
            getCompanyInfo();
        }
    };

    const onFileChanged = (e) => {
        let inputs = e.value;
        let result = [];

        for (let i = 0; i < inputs.length; i++) {
            let reader = new FileReader();
            let img = {
                data: null,
                name: inputs[i].name,
                file: inputs[i],
            };

            reader.onload = () => {
                setImageData((cur) => {
                    let imgList = [...cur];
                    const targetIndex = imgList.findIndex((element) => element.name == inputs[i].name);
                    if (targetIndex >= 0) {
                        let img = { ...imgList[targetIndex] };
                        img.data = reader.result;
                        imgList.splice(targetIndex, 1, img);
                    }
                    return imgList;
                });
            };

            result.push(img);
            reader.readAsDataURL(inputs[i]);
        }

        setImageData(result);
    };

    const removeImage = () => {
        setCompanyLogo('');
        setImageData([]);
        setTextVisible(true);
        const instance = getInstance();
        instance.removeFile(0);
    };

    return (
        <>
            <div className="company-management-info company-management-info-form">
                <div className="right-content-title">
                    <PageTitle pageState="Edit" />
                    <PageDropdown />
                </div>
                <form onSubmit={actionDirect}>
                    <div className="right-content-body">
                        <div className="container">
                            <div className="dx-fieldset">
                                <div className="dx-field">
                                    <div className="dx-field-label">
                                        <p className="essential">{translation.companyName}</p>
                                    </div>
                                    <div className="dx-field-value">
                                        <TextBox className="ix-tfs-r" name="user_company_name" value={companyName} onValueChanged={(e) => setCompanyName(e.value)} onEnterKey={moveFocus} inputAttr={{ focusidx: 1, autofocus: true }}>
                                            <Validator>
                                                <RequiredRule message={translation.companyName + translation.is_required} />
                                            </Validator>
                                        </TextBox>
                                    </div>
                                </div>
                                <div className="dx-field">
                                    <div className="dx-field-label">
                                        <p className="essential">{translation.companyRegNo}</p>
                                    </div>
                                    <div className="dx-field-value">
                                        <TextBox name="user_company_registerno" className="ix-tfs-r" value={companyRegNo} onValueChanged={(e) => setCompanyRegNo(e.value)} onEnterKey={moveFocus} inputAttr={{ focusidx: 2 }}>
                                            <Validator>
                                                <RequiredRule message={translation.companyRegNo + translation.is_required} />
                                            </Validator>
                                        </TextBox>
                                    </div>
                                </div>
                                <div className="dx-field">
                                    <div className="dx-field-label">
                                        <p className="essential">{translation.ceo}</p>
                                    </div>
                                    <div className="dx-field-value">
                                        <TextBox name="user_company_owner" className="ix-tfs-r" value={companyOwner} onValueChanged={(e) => setCompanyOwner(e.value)} onEnterKey={moveFocus} inputAttr={{ focusidx: 3 }}>
                                            <Validator>
                                                <RequiredRule message={translation.ceo + translation.is_required} />
                                            </Validator>
                                        </TextBox>
                                    </div>
                                </div>
                                <div className="dx-field">
                                    <div className="dx-field-label">
                                        <p>{translation.address}</p>
                                    </div>
                                    <div className="dx-field-value">
                                        <div className="search">
                                            <TextBox
                                                className="ix-tfs-r"
                                                id="sample4_postcode"
                                                name="user_company_zipcode"
                                                value={zipCode}
                                                onValueChanged={(e) => {
                                                    setZipcode(e.value);
                                                }}
                                                onEnterKey={moveFocus}
                                                readOnly={true}
                                            >
                                                <div className="clickable-textbox" onClick={openDaumPostcode}></div>
                                            </TextBox>
                                            <Button icon="search" type="default" stylingMode="contained" className="btn-s-r dx-button-mode-es btn-only-icon" onClick={openDaumPostcode} elementAttr={{ focusidx: 4 }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="dx-field">
                                    <div className="dx-field-label"></div>
                                    <div className="dx-field-value">
                                        <TextBox className="ix-tfs-r" id="sample4_roadAddress" name="user_company_address" value={addressLine1} onValueChanged={(e) => setAddressLine1(e.value)} onEnterKey={moveFocus} inputAttr={{ focusidx: 5 }} readOnly={true}>
                                            <div className="clickable-textbox" onClick={openDaumPostcode}></div>
                                        </TextBox>
                                    </div>
                                </div>
                                <div className="dx-field">
                                    <div className="dx-field-label">
                                        <p>{translation.address2}</p>
                                    </div>
                                    <div className="dx-field-value">
                                        <TextBox className="ix-tfs-r" id="sample4_detailAddress" name="user_company_address2" value={addressLine2} onValueChanged={(e) => setAddressLine2(e.value)} onEnterKey={moveFocus} inputAttr={{ focusidx: 6 }}></TextBox>
                                    </div>
                                </div>
                                <div className="dx-field">
                                    <div className="dx-field-label">
                                        <p className="essential">{translation.contactNo}</p>
                                    </div>
                                    <div className="dx-field-value">
                                        <TextBox className="ix-tfs-r" name="user_company_phone" value={contactNo} onValueChanged={(e) => setContactNo(e.value)} onEnterKey={moveFocus} inputAttr={{ focusidx: 7 }}>
                                            <Validator>
                                                <RequiredRule message={translation.contactNo + translation.is_required} />
                                                {/*<PatternRule message={translation.invalid_phoneNo} pattern="^[0-9]{10}$|^[0-9]{11}$" />*/}
                                            </Validator>
                                        </TextBox>
                                    </div>
                                </div>
                                <div className="dx-field">
                                    <div className="dx-field-label">
                                        <p>{translation.comment}</p>
                                    </div>
                                    <div className="dx-field-value">
                                        <TextBox className="ix-tfs-r" name="user_company_comment" value={comment} onValueChanged={(e) => setComment(e.value)} onEnterKey={(e) => document.querySelector('#submitBtn').click()} inputAttr={{ focusidx: 8 }} />
                                    </div>
                                </div>
                                <div className="dx-field widget-container">
                                    <div className="dx-field-label">
                                        <p>{translation.companyLogo}</p>
                                    </div>
                                    <div className="dx-field-value widget-container flex-box" style={{ position: 'relative' }}>
                                        {companyLogo && (
                                            <div className="fileuploader-image-box fileuploader-single-image-box" style={{ position: 'absolute' }}>
                                                <img id="dropzone-image" src={`${companyLogo}`} alt="" className="fileuploader-image" />
                                                <Button id="dx-remove-btn" icon="trash" className="btn-s-r btn-hover-red" type="normal" stylingMode="text" onClick={removeImage} style={{ zIndex: 2 }} />
                                            </div>
                                        )}
                                        <div id="dropzone-external" className={`flex-box dropzone ${isDropZoneActive ? 'dx-theme-accent-as-border-color dropzone-active' : 'dx-theme-border-color'}`} style={{ position: 'relative', background: 'transparent' }}>
                                            {/* {companyLogo && <img id="dropzone-image" src={`${BASE_URL}static_files/${companyLogo}`} alt="" className="fileuploader-image" />} */}

                                            {textVisible && (
                                                <>
                                                    {/* <div className="fileuploader-icon"></div> */}
                                                    <i className="dx-icon-m-cam"></i>
                                                    <div id="dropzone-text" className="flex-box dropzone-text">
                                                        <span>{translation.logoDesc}</span>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        <FileUploader
                                            id="file-uploader"
                                            ref={uploaderRef}
                                            name="user_company_logo"
                                            uploadMode="instantly"
                                            dialogTrigger="#dropzone-external"
                                            dropZone="#dropzone-external"
                                            onDropZoneEnter={onDropZoneEnter}
                                            uploadUrl="https://js.devexpress.com/Demos/NetCore/FileUploader/Upload"
                                            onDropZoneLeave={onDropZoneLeave}
                                            onUploadStarted={onUploadStarted}
                                            selectButtonText={translation.logoDesc}
                                            visible={false}
                                            allowedFileExtensions={allowedFileExtensions}
                                            inputAttr={{ focusidx: 9 }}
                                            onValueChanged={onFileChanged}
                                        ></FileUploader>
                                    </div>
                                </div>
                            </div>
                            <div className="block"></div>
                            <div className="dx-fieldset write-info">
                                <div className="dx-field">
                                    <div className="dx-field-label">{translation.lastModifiedDate}</div>
                                    <div className="dx-field-value">{modifiedByDate}</div>
                                </div>
                                <div className="dx-field margin-0">
                                    <div className="dx-field-label">{translation.lastModifiedBy}</div>
                                    <div className="dx-field-value">
                                        {modifiedByName !== null ? modifiedByName : companyName}
                                        {modifiedByDuty !== null && <span className="badge badge-job-position">{modifiedByDuty}</span>}
                                        {modifiedByDepartment !== null && <span className="badge badge-team">{modifiedByDepartment}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="block"></div>
                            <div className="btn-group dx-fieldset">
                                {/* <Button
                                    type="normal"
                                    stylingMode="text"
                                    className="btn-s-r"
                                    onClick={e => document.querySelector('.company-logo').click()}
                                >
                                    <div>
                                        <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                        <span className="dx-button-text">{translation.cancel}</span>
                                    </div>
                                </Button> */}
                                <Button
                                    id="submitBtn"
                                    type="default"
                                    stylingMode="contained"
                                    className="btn-s-r"
                                    useSubmitBehavior={true}
                                    onClick={(e) => {
                                        submit(e, updateCompanyInfo);
                                    }}
                                >
                                    <div>
                                        <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                        <span className="dx-button-text">{translation.save}</span>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default CompanyManagementInfo;
