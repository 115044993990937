import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'devextreme-react/button';
import TextBox from 'devextreme-react/text-box';
import FileUploader from 'devextreme-react/file-uploader';
import { Validator, RequiredRule, EmailRule, PatternRule } from 'devextreme-react/validator';
import { ValidationGroup } from 'devextreme-react/validation-group';

import { swal, useSwal } from '../../components/common/Swal';
import '../../contents/styles/account/input-company-info.css';
import { setUserSession } from '../../apiInterface/utils/Common';
import { inputCompanyInfoAPI, uploadLogo } from '../../apiInterface/account/InputCompanyInfoAPI';
import useTranslation from '../../components/customHooks/translations';
import { actionDirect, submit } from '../../components/common/FormFuncWithEnter';
import { LoadIndicator } from 'devextreme-react';
import { SingleFileField } from '../../components/common/FormField';

const InputCompanyInfo = (props) => {
    const translation = useTranslation();
    const [isSending, setIsSending] = useState(false);
    const [companyLogo, setCompanyLogo] = useState([]);

    const navigate = useNavigate();
    const userInput = useRef({});
    const [companyName, setCompanyName] = useState('company name');
    const { success } = useSwal();
    const { fail, warning } = useSwal();

    //input change handler
    const handleChange = (e) => {
        setCompanyName(e.event.target.value);
    };

    const onFormSubmit = (e) => {
        setIsSending(true);
        handleSignup();
        e.preventDefault();

        //navigate("../Login")
    };
    const onFormSubmit2 = (e) => {};

    function openDaumPostcode() {
        const daum = window.daum;
        new daum.Postcode({
            oncomplete: function (data) {
                var roadAddr = data.roadAddress; // 도로명 주소 변수
                var extraRoadAddr = ''; // 참고 항목 변수

                if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
                    extraRoadAddr += data.bname;
                }
                if (data.buildingName !== '' && data.apartment === 'Y') {
                    extraRoadAddr += extraRoadAddr !== '' ? ', ' + data.buildingName : data.buildingName;
                }
                if (extraRoadAddr !== '') {
                    extraRoadAddr = ' (' + extraRoadAddr + ')';
                }

                document.querySelector('#sample4_postcode input').value = data.zonecode;
                document.querySelector('#sample4_roadAddress input').value = roadAddr + extraRoadAddr;
            },
        }).open({ autoClose: true });
    }

    //button event (card-footer)
    function goNext() {
        sessionStorage.setItem('pageIdx', 2);
        document.querySelector('.current-num').innerText = 2;
        document.querySelector('.dx-validationgroup:first-child').style.display = 'none';
        document.querySelector('.dx-validationgroup:last-child').style.display = 'block';
        document.querySelector('[focusidx="3"]').focus();
    }
    function goPrev() {
        sessionStorage.setItem('pageIdx', 1);
        document.querySelector('.current-num').innerText = 1;
        document.querySelector('.dx-validationgroup:first-child').style.display = 'block';
        document.querySelector('.dx-validationgroup:last-child').style.display = 'none';
    }

    //uploading company logo
    const uploadImage = async () => {
        //check object is empty
        if (companyLogo.length > 0) {
            let formData = new FormData();
            formData.append('img', companyLogo[0].file);
            const res = await uploadLogo(formData);
            return res;
        } else {
            return { file_name: '', db_url: '' };
        }
    };
    // handle button click of signup form
    const handleSignup = async () => {
        setIsSending(true);
        let imgRes = await uploadImage();
        userInput.current.user_company_logo = imgRes.db_url;
        try {
            var res = await inputCompanyInfoAPI(userInput.current);

            if (res !== 'fail' && res.message === 'MSG_100') {
                sessionStorage.removeItem('pageIdx');

                var swalResFn = (response) => {
                    navigate('/Login', { replace: true });
                };
                success(`<p>${translation.confirm_signUp}</p><span>${userInput.current['user_master_email']}</span><p>${translation.send_signUp_message}</p>`, swalResFn);
            } else {
                fail(`<p>${translation[res.message]}</p>`);
            }
        } catch (err) {
            fail(`<span>${userInput.current['user_master_email']}</span><p>${translation.request_error}</p>`);
        } finally {
            setIsSending(false);
        }
    };

    const changeInputValue = (key, value) => {
        userInput.current[key] = value;
    };

    function detectEvent(e) {
        // 새로 고침 감지
        if (e.keyCode == 116) {
            // e.preventDefault();
            let cb = (res) => {
                if (res.isConfirmed) {
                    sessionStorage.removeItem('pageIdx');
                    window.location.reload();
                }
            };

            let checkPageIdx2 = sessionStorage.getItem('pageIdx') === '2';
            if (checkPageIdx2) {
                e.preventDefault();
                warning(`<p>${translation.is_reset_reload_alert}</p>`, cb);
            }
            // document.querySelector('.dx-validationgroup:first-child').style.display = checkPageIdx2 ? 'none' : 'block';
            // document.querySelector('.dx-validationgroup:last-child').style.display = checkPageIdx2 ? 'block' : 'none';
        }
    }
    document.onkeydown = detectEvent;

    return (
        <div className="dx-swatch-ix-pds input-account-info business-account">
            <div className="content">
                <h1 className="logo-interx" onClick={() => [sessionStorage.removeItem('pageIdx'), navigate('../login')]}></h1>
                {/* <h1 className="logo-lnf" onClick={() => [sessionStorage.removeItem('pageIdx'), navigate('../login')]}></h1> */}
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">
                            <span>{translation.business}</span>
                            <br />
                            {translation.account}
                        </h3>
                        <div className="page-number">
                            <span className="current-num">1</span>/<span className="total-num">2</span>
                        </div>
                    </div>
                    <form onSubmit={actionDirect} className="form-account-lv1">
                        <ValidationGroup>
                            <div className="dx-fieldset">
                                <div className="dx-field">
                                    <div className="dx-field-label">
                                        <p className="essential">{translation.email}</p>
                                    </div>
                                    <div className="dx-field-value">
                                        <TextBox className="ix-tfs-r" name="user_master_email" inputAttr={{ focusidx: 0 }} onEnterKey={(e) => document.querySelector('[focusidx="1"]').focus()} onValueChanged={(e) => changeInputValue('user_master_email', e.value)}>
                                            <Validator>
                                                <RequiredRule message={translation.email + translation.is_required} />
                                                <EmailRule message={translation.email + translation.is_invalid} />
                                            </Validator>
                                        </TextBox>
                                    </div>
                                </div>
                                <div className="dx-field">
                                    <div className="dx-field-label">
                                        <p className="essential">{translation.companyName}</p>
                                    </div>
                                    <div className="dx-field-value">
                                        <TextBox
                                            className="ix-tfs-r"
                                            onValueChanged={(e) => {
                                                setCompanyName(e.value);
                                                changeInputValue('user_company_name', e.value);
                                            }}
                                            name="user_company_name"
                                            inputAttr={{ focusidx: 1 }}
                                            onEnterKey={(e) => document.querySelector('[focusidx="2"]').focus()}
                                        >
                                            <Validator>
                                                <RequiredRule message={translation.companyName + translation.is_required} />
                                            </Validator>
                                        </TextBox>
                                    </div>
                                </div>
                                <div className="dx-field">
                                    <div className="dx-field-label">
                                        <p className="essential">{translation.contactNo}</p>
                                    </div>
                                    <div className="dx-field-value">
                                        <TextBox
                                            className="ix-tfs-r"
                                            name="user_company_phone"
                                            inputAttr={{ focusidx: 2 }}
                                            onEnterKey={(e) => {
                                                document.querySelector('#nextBtn').click();
                                            }}
                                            onValueChanged={(e) => changeInputValue('user_company_phone', e.value)}
                                        >
                                            <Validator>
                                                <RequiredRule message={translation.contactNo + translation.is_required} />
                                                {/* <PatternRule message={translation.contactNo + translation.is_invalid} pattern="^[0-9]{10}$|^[0-9]{11}$" /> */}
                                            </Validator>
                                        </TextBox>
                                    </div>
                                </div>
                                <div className="btn-group">
                                    <Button width="76px" text={translation.prev} type="normal" stylingMode="text" className="btn-s-r" onClick={() => [sessionStorage.removeItem('pageIdx'), navigate('../SelectUserType')]} />
                                    <Button
                                        id="nextBtn"
                                        width="76px"
                                        text={translation.next}
                                        type="default"
                                        stylingMode="contained"
                                        className="btn-s-r"
                                        onClick={(e) => {
                                            let check = e.validationGroup.validate();
                                            if (check.isValid) {
                                                goNext();
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </ValidationGroup>
                        <ValidationGroup>
                            <div className="dx-fieldset">
                                <div className="dx-field">
                                    <div className="dx-field-label">
                                        <p>{translation.companyName}</p>
                                    </div>
                                    <div className="dx-field-value">
                                        <TextBox className="ix-tfs-r" disabled={true} value={companyName} name="user_company_name"></TextBox>
                                    </div>
                                </div>
                                <div className="dx-field">
                                    <div className="dx-field-label">
                                        <p className="essential">{translation.companyRegNo}</p>
                                    </div>
                                    <div className="dx-field-value">
                                        <TextBox name="user_company_registerno" className="ix-tfs-r" inputAttr={{ focusidx: 3 }} onEnterKey={(e) => document.querySelector('[focusidx="4"]').focus()} onValueChanged={(e) => changeInputValue('user_company_registerno', e.value)}></TextBox>
                                    </div>
                                </div>
                                <div className="dx-field">
                                    <div className="dx-field-label">
                                        <p className="essential">{translation.ceo}</p>
                                    </div>
                                    <div className="dx-field-value">
                                        <TextBox name="user_company_owner" className="ix-tfs-r" inputAttr={{ focusidx: 4 }} onEnterKey={(e) => document.querySelector('[focusidx="5"]').click()} onValueChanged={(e) => changeInputValue('user_company_owner', e.value)}>
                                            <Validator>
                                                <RequiredRule message={translation.ceo + translation.is_required} />
                                            </Validator>
                                        </TextBox>
                                    </div>
                                </div>
                                <div className="dx-field">
                                    <div className="dx-field-label">
                                        <p>{translation.address}</p>
                                    </div>
                                    <div className="dx-field-value">
                                        <div className="search">
                                            <TextBox
                                                className="ix-tfs-r"
                                                disabled={true}
                                                id="sample4_postcode"
                                                name="user_company_zipcode"
                                                onValueChanged={(e) => {
                                                    changeInputValue('user_company_zipcode', e.value);
                                                    document.querySelector('[focusidx="6"]').focus();
                                                }}
                                            />
                                            <Button icon="search" type="default" stylingMode="contained" className="btn-s-r dx-button-mode-es btn-only-icon" onClick={openDaumPostcode} elementAttr={{ focusidx: 5 }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="dx-field">
                                    <div className="dx-field-label"></div>
                                    <div className="dx-field-value">
                                        <TextBox className="ix-tfs-r" disabled={true} id="sample4_roadAddress" name="user_company_address" onValueChanged={(e) => changeInputValue('user_company_address', e.value)} />
                                    </div>
                                </div>
                                <div className="dx-field">
                                    <div className="dx-field-label">
                                        <p>{translation.address2}</p>
                                    </div>
                                    <div className="dx-field-value">
                                        <TextBox className="ix-tfs-r" id="sample4_detailAddress" name="user_company_address2" inputAttr={{ focusidx: 6 }} onValueChanged={(e) => changeInputValue('user_company_address2', e.value)} />
                                    </div>
                                </div>
                                <SingleFileField label={translation.companyLogo} imageData={companyLogo} setImageData={setCompanyLogo} id="normal" />
                                <div className="btn-group">
                                    <Button text={translation.prev} type="normal" stylingMode="text" className="btn-s-r" onClick={goPrev} />
                                    <Button id="submitBtn" type="default" stylingMode="contained" className="btn-s-r" disabled={isSending} onClick={(e) => submit(e, handleSignup)}>
                                        <div className="buttonloader">
                                            <LoadIndicator className="button-indicator" visible={isSending} />
                                            <span className="dx-button-text">{translation.finish}</span>
                                        </div>
                                    </Button>
                                </div>
                            </div>
                        </ValidationGroup>
                    </form>
                </div>
                <div className="copywrite">
                    <p>© INTERX</p>
                    {/* <p>© ㈜엘앤에프</p> */}
                </div>
            </div>
        </div>
    );
};

export default InputCompanyInfo;
