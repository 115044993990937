import {
    makeTokenAxiosDeleteRequest,
    makeTokenAxiosGetRequest,
    makeTokenAxiosPostRequest,
    makeTokenAxiosPutRequest,
    makeTokenAxiosPutRequestParams,
} from '../utils/AxiosMask';

const baseUrl = 'i_inspection_library_condition';

export const insertInspectionLibraryDefectType = async (param) => {
    let res;
    const urlPath = `${baseUrl}/insert_inspection_library_defect_type`;

    try {
        res = await makeTokenAxiosPostRequest(urlPath, param);
        return res.status === 200 ? res.data : 'fail';
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : 'fail';
};

export const insertInspectionLibraryCondition = async (param) => {
    let res;
    const urlPath = `${baseUrl}/insert_inspection_library_condition`;

    try {
        res = await makeTokenAxiosPostRequest(urlPath, param);
        return res.status === 200 ? res.data : 'fail';
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : 'fail';
};

export const updateInspectionLibraryDefectType = async (param) => {
    let res;
    const urlPath = `${baseUrl}/update_inspection_library_defect_type`;

    try {
        res = await makeTokenAxiosPutRequest(urlPath, param);
        return res.status === 200 ? res.data : 'fail';
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : 'fail';
};

export const deleteInspectionLibraryDefectType = async (param) => {
    let res;
    const urlPath = `${baseUrl}/delete_inspection_library_defect_type`;

    try {
        res = await makeTokenAxiosDeleteRequest(urlPath, param);
        return res.status === 200 ? res.data : 'fail';
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : 'fail';
};

export const getOneInspectionLibraryDefectType = async (param) => {
    let res;
    const urlPath = `${baseUrl}/get_one_inspection_library_defect_type`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : 'fail';
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : 'fail';
};

export const getOneInspectionLibrary = async (param) => {
    let res;
    const urlPath = `${baseUrl}/get_one_inspection_library`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : 'fail';
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : 'fail';
};

export const getMainInspectionLibraryDefectTypeDT = async (param) => {
    let res;
    const urlPath = `${baseUrl}/get_main_inspection_library_defect_type_DT`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : 'fail';
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : 'fail';
};

export const getInspectionLibrary = async (param) => {
    let res;
    const urlPath = `${baseUrl}/get_inspection_library`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : 'fail';
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : 'fail';
};

export const getSysDivisionCaptionByCode = async (param) => {
    let res;
    const urlPath = `${baseUrl}/get_sys_division_caption_by_code`;

    try {
        res = await makeTokenAxiosGetRequest(urlPath, param);
        return res.status === 200 ? res.data : 'fail';
    } catch (error) {
        console.error('error', error);
    }
    return res?.status === 200 ? res.data : 'fail';
};
