﻿import React, { useRef, useEffect, useState } from 'react';
import Button from 'devextreme-react/button';
import TextBox from 'devextreme-react/text-box';
import SelectBox from 'devextreme-react/select-box';
import FileUploader from 'devextreme-react/file-uploader';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import useTranslation from '../../components/customHooks/translations';
import { useLocation, useNavigate } from 'react-router-dom';
import LastModifiedIndicator from '../../components/common/LastModifiedIndicator';
import { BaseField, DropdownField, TextboxField } from '../../components/common/FormField';
import { useGetUseOrNotContent } from '../../components/customHooks/dropdownContent';
import FormPageValidator from '../../components/common/FormPageValidator';
import { findProps } from 'devextreme-react/core/template';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import { postAasxFileInfoAPI, uploadAasxFileAPI } from '../../apiInterface/aasFile/AASFileManagementAPI';
import { getIP, getMenuMappingInfo, getUser } from '../../apiInterface/utils/Common';
import { useSwal } from '../../components/common/Swal';
import { Validator } from 'devextreme-react';
import { CustomRule } from 'devextreme-react/validator';
import '../../contents/styles/dataManagement/aas-file-management.css';
import { PageTitle } from '../../components/common/PageTitle.jsx';

const AASFileManagement = () => {
    const translation = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const [formData, setFormData] = useState(null);
    const useItems = useGetUseOrNotContent();
    const [btnDisable, setBtnDisable] = useState(false);
    const [subtitle, setSubtitle] = useState('');
    const errorHandler = useErrorHandler();
    const { success } = useSwal();

    useEffect(() => {
        const { title } = location.state;
        setSubtitle(title);
        if (title == 'Add') {
            setFormData({ aasx_file_is_active: true });
        }
    }, []);

    const onSubmit = (ev) => {
        ev.preventDefault();
        setBtnDisable(true);

        if (btnDisable) return;

        if (subtitle == 'Edit') {
            updateData();
        } else {
            insertData();
        }
    };

    const uploadAASX = async () => {
        if (formData?.aasx_file == null) {
            return null;
        }

        const body = new FormData();
        for (let i = 0; i < formData.aasx_file.length; i++) {
            const temp = formData.aasx_file[i];
            body.append('aasx_file', formData.aasx_file[i]);
        }

        const res = await errorHandler(uploadAasxFileAPI, body);
        if (res) {
            return res.data.o_data;
        }
        return null;
    };

    const updateData = async () => {
        setBtnDisable(false);
    };

    const insertData = async () => {
        const aasx = await uploadAASX();
        const user = getUser();
        const ip = getIP();
        const mappingInfo = await getMenuMappingInfo();
        const data = {
            user_master_id: user.user_master_id,
            user_log_ip: ip,
            user_company_id: user.user_company_id,
            menu_mapping_id: mappingInfo.id,
            aasx_file_name: aasx?.file_name,
            aasx_file_path: aasx?.db_url,
            aasx_file_size: aasx?.file_size,
            aasx_file_package_name: formData?.aasx_file_package_name,
            aasx_file_is_active: formData?.aasx_file_is_active,
            aasx_file_comment: formData?.aasx_file_comment,
        };

        const res = await errorHandler(postAasxFileInfoAPI, data);
        if (res) {
            success(translation.success_insert, exitpage);
        }

        setBtnDisable(false);
    };

    const exitpage = (ev) => {
        const state = location.state;
        navigate('/AASFileManagement', { state: state });
    };

    const onFormValueChanged = (value, key) => {
        setFormData((cur) => {
            let copy = { ...cur };
            copy[key] = value;
            return copy;
        });
    };

    const onFileLoaded = (e) => {
        onFormValueChanged(e.value, 'aasx_file');
    };

    const adapterConfig = {
        getValue: () => {
            return formData.aasx_file;
        },
        applyValidationResults: (e) => {},
        validationRequestCallbacks: () => {},
    };

    const validateFileExist = (e) => {
        if (e.value.length < 1) {
            return false;
        }
        return true;
    };

    const validateFileType = (e) => {
        let fileName = e.value[0].name.split('.');
        let extension = fileName[fileName.length - 1];
        if (extension === 'aasx') {
            return true;
        }
        return false;
    };

    return (
        <>
            <div className="aasx-file-mgt-form">
                <div className="right-content-title">
                    <PageTitle pageState={location.state.title} />
                </div>
                <form onSubmit={onSubmit}>
                    <div className="right-content-body">
                        <div className="container">
                            <div className="dx-fieldset">
                                <TextboxField
                                    isEssential={true}
                                    essentialMsg={'Package name' + translation.is_required}
                                    label="Package name"
                                    value={formData?.aasx_file_package_name}
                                    onValueChanged={(e) => {
                                        onFormValueChanged(e.value, 'aasx_file_package_name');
                                    }}
                                />
                                <DropdownField
                                    isEssential={true}
                                    essentialMsg={translation.use_or_not + translation.is_required}
                                    label={translation.use_or_not}
                                    dataSource={useItems}
                                    displayExpr="name"
                                    valueExpr="value"
                                    isSearchable={false}
                                    value={formData?.aasx_file_is_active}
                                    onValueChanged={(e) => {
                                        onFormValueChanged(e.value, 'aasx_file_is_active');
                                    }}
                                />
                                <TextboxField
                                    label={translation.comment}
                                    value={formData?.aasx_file_comment}
                                    onValueChanged={(e) => {
                                        onFormValueChanged(e.value, 'aasx_file_comment');
                                    }}
                                />
                            </div>
                            <div className="block"></div>
                            <div className="dx-field-set">
                                <BaseField
                                    label="Uploading AASX File"
                                    isColumn={true}
                                    isEssential={true}
                                    component={
                                        <div className="fileuploader-container">
                                            <FileUploader width={588} className="fileuploader-file" uploadMode="useForm" onValueChanged={(e) => onFileLoaded(e)} multiple={false}>
                                                <Validator>
                                                    <CustomRule message={translation.aas_file + translation.is_required} validationCallback={validateFileExist} />
                                                    <CustomRule message={translation.aas_file + translation.is_invalid} validationCallback={validateFileType} />
                                                </Validator>
                                            </FileUploader>
                                        </div>
                                    }
                                />
                            </div>
                            <div className="block"></div>
                            {subtitle == 'Edit' && <LastModifiedIndicator date={formData?.modified_date} department={formData?.modified_by_department} position={formData?.modified_by_duty} writer={formData?.modified_by_name} />}
                            <div className="btn-group dx-fieldset" style={{ width: 612 }}>
                                <Button type="normal" stylingMode="text" className="btn-s-r" onClick={exitpage}>
                                    <div>
                                        <span className="dx-button-text">{translation.cancel}</span>
                                    </div>
                                </Button>
                                <Button id="submitBtn" type="default" stylingMode="contained" className="btn-s-r" useSubmitBehavior={true} disabled={btnDisable}>
                                    <div>
                                        <LoadIndicator className="button-indicator" visible={btnDisable} width={20} height={20} />
                                        <span className="dx-button-text">{translation.save}</span>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};
export default AASFileManagement;
