import React, { useState, useEffect } from 'react';
import { DropdownField } from '../../components/common/FormField';
import useTranslation from '../../components/customHooks/translations';
import { getMenuMappingInfo, getLanguage, getUser, getIP, getServiceMappingInfo } from '../../apiInterface/utils/Common';
import { getMenuMappingServiceAPI, getMenuGridAPI, updateMenuMappingSeq, deleteMenuMapping } from '../../apiInterface/menu/MenuAPI';
import DraggableTreeList from '../../components/common/DraggableTreeList';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import { successToastr } from '../../components/common/Toastr';
import { useSideBarContext } from '../../components/contexts/LeftSideBarContext';
import { useLocation } from 'react-router-dom';
import { useSwal } from '../../components/common/Swal';
import { PageDropdown } from '../../components/common/PageDropdown';
import { PageTitle } from '../../components/common/PageTitle.jsx';
import Badge from '../../components/common/Badge';

let param = { level: null, serviceId: null };
const Menu = () => {
    const translation = useTranslation();
    const language = getLanguage();
    const { refresh } = useSideBarContext();
    const location = useLocation();
    const { fail } = useSwal();
    const errorHandler = useErrorHandler();
    const serviceInfo = getServiceMappingInfo();

    const [selectedMenuInfo, setSelectedMenuInfo] = useState({ level: null, serviceId: null, menu: null });
    const [menuDDData, setMenuDDData] = useState([]);
    const [curMenuDD, setCurMenuDD] = useState([]);
    const [disableDD, setDisableDD] = useState(false);
    const [dataSourceGrid, setdataSourceGrid] = useState([]);
    const [allowAdd, setAllowAdd] = useState(true);
    const activeNotActiveJson = { true: translation.active, false: translation.not_active };
    const activeNotActiveClassName = { true: 'grid-badge-use', false: 'grid-badge-disabled' };

    //init
    useEffect(() => {
        const init = async () => {
            const user = getUser();
            let param = {
                user_company_id: user.user_company_id,
                sys_lang_code: language,
            };

            const result = await errorHandler(getMenuMappingServiceAPI, param);
            if (result) {
                setMenuDDData(result.data.o_data);
                let previousDDValue = location.state?.serviceId;
                if (previousDDValue != null) {
                    setCurMenuDD(previousDDValue);
                } else if (result.data.o_data.length > 0) {
                    setCurMenuDD(result.data.o_data[0].menu_mapping_id);
                }
            }
        };
        init();
    }, []);

    //dropdown value changed callback
    const onValueChanged = async (e) => {
        setCurMenuDD(e.value);
        loadRows(e.value);
        //param.serviceId = e.value;
        //param.level = 0;
        setSelectedMenuInfo({ level: 0, serviceId: e.value, menu: null });
    };

    //load rows depends on template dropdown value.
    const loadRows = async (serviceId) => {
        const user = getUser();
        setAllowAdd(true);
        let param = {
            user_company_id: user.user_company_id,
            sys_lang_code: language,
            menu_mapping_service_id: serviceId,
        };

        const res = await errorHandler(getMenuGridAPI, param);
        if (res) {
            setdataSourceGrid(res.data.o_data);
        }
    };

    //this function will be called after devexpress deleted a row.
    const onDelete = (remove) => {
        let isSuccess = true;

        remove.forEach(async (item) => {
            const user = getUser();
            const ipAddress = getIP();
            const menuMappingInfo = await getMenuMappingInfo();
            // const data = e.data;

            //have to check is that system defined menu
            //if (data.user_custom === false) {
            //    fail(translation.alert_remove_default_menu);
            //    return false;
            //}

            let param = {
                user_master_id: user.user_master_id,
                user_log_ip: ipAddress,
                menu_mapping_id: menuMappingInfo.id,
                target_menu_mapping_id: item.menu_mapping_id,
            };

            const res = await errorHandler(deleteMenuMapping, param);
            if (!res) {
                isSuccess = false;
                return;
            }
        });

        if (isSuccess) {
            successToastr(translation.success_delete_row);
            refresh(curMenuDD);
        }
    };

    const onTreeRowSelect = (e) => {
        let allow = false;
        if (e.selectedRowsData.length == 1) {
            const key = e.selectedRowKeys[0];
            const selectedNode = e.component.getNodeByKey(key);
            if (selectedNode.level < 2) {
                allow = true;
                //param.level = selectedNode.level;
            }
            setSelectedMenuInfo({ ...selectedMenuInfo, level: selectedNode.level, menu: selectedNode.data });
        } else if (e.selectedRowsData.length == 0) {
            allow = true;
            //param.level = 0;
            setSelectedMenuInfo({ ...selectedMenuInfo, level: 0, menu: null });
        }

        setAllowAdd(allow);
    };

    const onReorder = async (item) => {
        const user = getUser();
        let param = {
            user_company_id: user.user_company_id,
            menu_mapping_id: item.menu_mapping_id,
            menu_mapping_parent_id: item.menu_mapping_parent_id,
            menu_mapping_top_id: item.upperNode,
        };

        const res = await errorHandler(updateMenuMappingSeq, param);
        if (res) {
            successToastr(translation.success_update_seq);
            //update menu Layout
            refresh(curMenuDD);
        }
    };

    const onMoveDown = () => {
        setDisableDD(true);
    };

    const onMoveDownExit = () => {
        setDisableDD(false);
    };

    const dropdown = <DropdownField label={translation.select_service} dataSource={menuDDData} displayExpr="menu_mapping_caption" valueExpr="menu_mapping_id" onValueChanged={onValueChanged} value={curMenuDD} width={204} disabled={disableDD} />;

    const headerDict = [
        { dataField: 'menu_mapping_name', caption: translation.menu_mapping_name.toUpperCase(), dataType: 'string', allowReordering: true },
        { dataField: 'template_master_name', caption: translation.template_master_name.toUpperCase(), dataType: 'string', allowReordering: true },
        {
            dataField: 'menu_mapping_is_active',
            caption: translation.template_master_is_active.toUpperCase(),
            dataType: 'string',
            width: 112,
            alignment: 'center',
            cellRender: (e) => <Badge className={activeNotActiveClassName[e.value]} text={activeNotActiveJson[e.value]} />,
            allowReordering: true,
        },
        { dataField: 'menu_mapping_comment', caption: translation.menu_mapping_comment.toUpperCase(), dataType: 'string', allowReordering: true },
        { dataField: 'menu_mapping_modified_by', caption: translation.lastModifiedBy.toUpperCase(), dataType: 'string', isEdit: false, width: 300, allowReordering: true },
        { dataField: 'menu_mapping_modified_date', caption: translation.lastModifiedDate.toUpperCase(), dataType: 'string', isEdit: false, width: 150, allowReordering: true },
    ];

    return (
        <div className="menu">
            <div className="right-content-title">
                <PageTitle />
                <PageDropdown />
            </div>
            <div className="right-content-body">
                <div className="container">
                    <DraggableTreeList
                        toolbarModules={[dropdown]}
                        dataSource={dataSourceGrid}
                        keyExpr="menu_mapping_id"
                        displayExpr="menu_mapping_name"
                        parentIdExpr="menu_mapping_parent_id"
                        allowReordering={true}
                        allowReorderingOnly={true}
                        headers={headerDict}
                        onDelete={onDelete}
                        // onRowRemoving={onDelete}
                        onReorder={onReorder}
                        onMoveDown={onMoveDown}
                        onMoveDownExit={onMoveDownExit}
                        link="MenuForm"
                        selectMode="multiple"
                        rootValue={curMenuDD}
                        onSelect={onTreeRowSelect}
                        allowAdd={allowAdd}
                        showAdd={curMenuDD !== parseInt(serviceInfo.id)}
                        maxLevel={2}
                        params={selectedMenuInfo}
                        isFullSize={true}
                    />
                </div>
            </div>
        </div>
    );
};
export default Menu;
