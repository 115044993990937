// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../contents/image/no-page.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.no-page .dx-button.dx-widget {
    min-width: unset;
}
.no-page .no-page-img {
    width: 23%;
    padding-bottom: 18%;
    background: center / contain url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat;
    margin-bottom: 23px;
}
.no-page .btn-go-back {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.no-page .btn-go-back:hover div.dx-button-default.dx-widget.dx-button-mode-es,
.no-page .btn-go-back:focus div.dx-button-default.dx-widget.dx-button-mode-es {
    background-color: var(--primary-500);
    color: var(--white);
}
.no-page .btn-go-back:hover div.dx-button-default.dx-widget.dx-button-mode-es .dx-icon,
.no-page .btn-go-back:focus div.dx-button-default.dx-widget.dx-button-mode-es .dx-icon {
    color: var(--white);
}
.no-page .btn-go-back > div {
    margin-right: 16px;
}
.no-page .btn-go-back p {
    font-size: 16px !important;
    font-weight: bold !important;
    color: var(--primary-500);
    -webkit-user-select: none;
    user-select: none;
}`, "",{"version":3,"sources":["webpack://./src/contents/styles/home/no-page.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,UAAU;IACV,mBAAmB;IACnB,8EAAiF;IACjF,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;AACnB;AACA;;IAEI,oCAAoC;IACpC,mBAAmB;AACvB;AACA;;IAEI,mBAAmB;AACvB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,0BAA0B;IAC1B,4BAA4B;IAC5B,yBAAyB;IACzB,yBAAyB;IAGzB,iBAAiB;AACrB","sourcesContent":[".no-page {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n.no-page .dx-button.dx-widget {\n    min-width: unset;\n}\n.no-page .no-page-img {\n    width: 23%;\n    padding-bottom: 18%;\n    background: center / contain url(\"../../../contents/image/no-page.png\") no-repeat;\n    margin-bottom: 23px;\n}\n.no-page .btn-go-back {\n    display: flex;\n    align-items: center;\n    cursor: pointer;\n}\n.no-page .btn-go-back:hover div.dx-button-default.dx-widget.dx-button-mode-es,\n.no-page .btn-go-back:focus div.dx-button-default.dx-widget.dx-button-mode-es {\n    background-color: var(--primary-500);\n    color: var(--white);\n}\n.no-page .btn-go-back:hover div.dx-button-default.dx-widget.dx-button-mode-es .dx-icon,\n.no-page .btn-go-back:focus div.dx-button-default.dx-widget.dx-button-mode-es .dx-icon {\n    color: var(--white);\n}\n.no-page .btn-go-back > div {\n    margin-right: 16px;\n}\n.no-page .btn-go-back p {\n    font-size: 16px !important;\n    font-weight: bold !important;\n    color: var(--primary-500);\n    -webkit-user-select: none;\n    -moz-user-select: none;\n    -ms-user-select: none;\n    user-select: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
