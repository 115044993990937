import { makeTokenAxiosGetRequest, makeTokenAxiosPutRequest, makeTokenAxiosPostRequest, makeTokenAxiosDeleteRequest } from '../utils/AxiosMask';
const baseUrl = 'abnormal_settings';

export const getAbnormalSettings = async (data) => {
    var res;
    const urlPath = `${baseUrl}/abnormal_settings/`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, data);
        return res.status === 200 ? res.data : 'fail';
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : 'fail';
};

export const updateAbnormalSettings = async (data) => {
    var res;
    // const urlPath = `${baseUrl}/abnormal_settings/`;
    const urlPath = `${baseUrl}/update_abnormal_settings_ux/`;
    try {
        res = await makeTokenAxiosPutRequest(urlPath, data);
        return res.status === 200 ? res.data : 'fail';
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : 'fail';
};

export const insertAbnormalSettings = async (data) => {
    var res;
    // const urlPath = `${baseUrl}/abnormal_setting/`;
    const urlPath = `${baseUrl}/abnormal_setting_ux/`;
    try {
        res = await makeTokenAxiosPostRequest(urlPath, data);
        return res.status === 200 ? res.data : 'fail';
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : 'fail';
};

export const getOneAbnormalSetting = async (data) => {
    var res;
    const urlPath = `${baseUrl}/get_one_abnormal_setting/`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, data);
        return res.status === 200 ? res.data : 'fail';
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : 'fail';
};

export const getSysDivisionCaptionByCode = async (data) => {
    var res;
    const urlPath = `${baseUrl}/get_sys_division_caption_by_code/`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, data);
        return res.status === 200 ? res.data : 'fail';
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : 'fail';
};

export const getIdShorts = async (data) => {
    var res;
    const urlPath = `${baseUrl}/get_id_shorts/`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, data);
        return res.status === 200 ? res.data : 'fail';
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : 'fail';
};

export const getIdShortsUx = async (data) => {
    var res;
    const urlPath = `${baseUrl}/get_id_shorts_ux/`;
    try {
        res = await makeTokenAxiosGetRequest(urlPath, data);
        return res.status === 200 ? res.data : 'fail';
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : 'fail';
};

export const deleteAbnormalSettings = async (data) => {
    var res;
    const urlPath = `${baseUrl}/abnormal_settings`;
    try {
        res = await makeTokenAxiosDeleteRequest(urlPath, data);
        return res.status === 200 ? res.data : 'fail';
    } catch (error) {
        console.error('error', error);
    }
    return res.status === 200 ? res : 'fail';
};
