import React, { useEffect, useState } from "react";
import useTranslation from "../../components/customHooks/translations";
import useErrorHandler from "../../components/customHooks/useErrorHandler";
import Grid from "../../components/common/Grid"
import MasterDetailGrid from "../../components/common/MasterDetailGrid";
import { successToastr } from "../../components/common/Toastr";
import { PageDropdown } from "../../components/common/PageDropdown";
import "../../contents/styles/division/division.css";
import { getLanguage, getUser, getIP, getMenuMappingInfo } from "../../apiInterface/utils/Common";
import { getMainSysDivision, getMainSysDivisionSub, deleteSysDivisionSub, updateSysDivisionSubSeq } from "../../apiInterface/divisionManagement/DivisionAPI";
import { PageTitle } from "../../components/common/PageTitle.jsx";

const Division = () => {
  const user = getUser();
  const lang = getLanguage();
  const ipAddress = getIP();
  const translation = useTranslation();
  const errorHandler = useErrorHandler();
  const activeNotActiveJson = { true: translation.active, false: translation.not_active };
  const editNotEditJson = { true: translation.sys_division_edit, false: translation.sys_division_not_edit };
  const activeNotActiveClassName = { true: "grid-badge-use", false: "grid-badge-disabled" }
  const editNotEditClassName = { true: "grid-badge-unlock", false: "grid-badge-disabled" }
  const [dataSource, setDataSource] = useState([]);
  const [subDataSource, setSubDataSource] = useState([]);
  const [visibleState, setVisibleState] = useState(false);
  const [sysDivisionId, setSysDivisionId] = useState(null);
  const [sysDivisionName, setSysDivisionName] = useState(null);
  const [canAccess, setCanAccess] = useState(true);

  const mainHeader = [
    { dataSource: "sys_division_id", caption: "NO", dataType: "number", isEdit: false, width: 100, alignment: "center" },
    { dataSource: "sys_division_name", caption: translation.sys_division_name.toUpperCase(), dataType: "string", isEdit: false, width: 300, },
    {
      dataSource: "sys_division_is_active", caption: translation.sys_division_is_active.toUpperCase(), dataType: "string",
      isEdit: false, width: 130, alignment: "center", isBadge: true, captionForBadge: activeNotActiveJson, classNameForBadge: activeNotActiveClassName,
    },
    {
      dataSource: "sys_division_can_edit", caption: translation.sys_division_can_edit.toUpperCase(), dataType: "string",
      isEdit: false, width: 130, alignment: "center", isBadge: true, captionForBadge: editNotEditJson, classNameForBadge: editNotEditClassName,
    },
    { dataSource: "sys_division_comment", caption: translation.sys_division_comment.toUpperCase(), dataType: "string", isEdit: false },
  ]

  const subHeader = [
    { dataSource: "sys_division_sub_value", caption: translation.sys_division_sub_value.toUpperCase(), dataType: "string", isEdit: false },
    {
      dataSource: "sys_division_is_active", caption: translation.sys_division_sub_is_active.toUpperCase(), dataType: "string",
      isEdit: false, width: 130, alignment: "center", isBadge: true, captionForBadge: activeNotActiveJson, classNameForBadge: activeNotActiveClassName,
    },
    { dataSource: "sys_division_sub_modified_by", caption: translation.lastModifiedBy.toUpperCase(), dataType: "string", isEdit: false, width: 300 },
    { dataSource: "sys_division_sub_modified_date", caption: translation.lastModifiedDate.toUpperCase(), dataType: "string", isEdit: false, width: 150 },
  ]

  const masterDetailHeader = [
    { dataSource: "NO", caption: "NO", dataType: "number", isEdit: false, width: 60, alignment: "center" },
    { dataSource: "sys_division_sub_lang_code", caption: translation.sys_division_sub_lang_code.toUpperCase(), dataType: "string", isEdit: false, width: 260 },
    { dataSource: "sys_division_sub_caption_text", caption: translation.sys_division_sub_caption_text.toUpperCase(), dataType: "string", isEdit: false },
  ]

  const getMainSysDivisionInfo = async () => {
    const param = { sys_lang_code: lang };
    const res = await errorHandler(getMainSysDivision, param);
    if (res) {
      const mainSysDivisionInfo = res.data.o_data;
      setDataSource(mainSysDivisionInfo);
    }
  }

  const getMainSysDivisionSubInfo = async (sysDivisionId) => {
    const param = {
      user_company_id: user.user_company_id,
      sys_lang_code: lang,
      sys_division_id: sysDivisionId,
    };

    const res = await errorHandler(getMainSysDivisionSub, param);
    if (res) {
        const mainSysDivisionSubInfo = res.data.o_data;
      setSubDataSource(mainSysDivisionSubInfo);
    }
  }

  const onRowClick = (e) => {
    const clickedRow = e.data;
    if (clickedRow.sys_division_is_active === true) {
      setVisibleState(true);
      getMainSysDivisionSubInfo(clickedRow.sys_division_id);
      setSysDivisionId(clickedRow.sys_division_id);
      setSysDivisionName(clickedRow.sys_division_name);
    } else {
      setVisibleState(false);
      setSubDataSource(null);
      setSysDivisionId(null);
      setSysDivisionName(null);
    }

    clickedRow.sys_division_can_edit === true ?
      setCanAccess(true) : setCanAccess(false);
  }

  const deleteDivisionInfo = async (selectedData) => {
    const menuMappingInfo = await getMenuMappingInfo();
    selectedData.forEach(async data => {
      const queryData = {
        user_master_id: user.user_master_id,
        user_log_ip: ipAddress,
        user_menu_mapping_id: menuMappingInfo.id,
        sys_division_sub_id: data.sys_division_sub_id,
      }
      const res = await errorHandler(deleteSysDivisionSub, queryData);
      if (res) {
        successToastr(translation.success_delete_row);
      }
    })
  }

  const updateRowSeq = async (data) => {
    if (canAccess === true) {
      const params = {
        user_company_id: user.user_company_id,
        sys_division_id: sysDivisionId,
        sys_division_sub_id: data.subId,
        sys_division_sub_top_id: data.topId,
      }

      const res = await errorHandler(updateSysDivisionSubSeq, params);
      if (res) {
        successToastr(translation.success_update_seq);
      }
    }
  }

  useEffect(() => {
    getMainSysDivisionInfo();
  }, []);

  return (
      <>
          <div className="division">
              <div className="right-content-title">
                  <PageTitle />
                  <PageDropdown />
              </div>
              <div className="right-content-body">
                  <div className="container">
                      <div className="datagrid-line">
                          <Grid
                              dataSource={dataSource}
                              headers={mainHeader}
                              pagerVisible={false}
                              onRowClick={onRowClick}
                          />
                      </div>
                  </div>
                  <div className="block"></div>
                  <div className="container">
                      {visibleState == false && <div className="select-message">
                          <i className="dx-icon-m-file"></i>
                          <p>{translation.sys_division_select_division}</p>
                      </div>}
                      {visibleState == true && <div className="data-grid">
                          <div className="selected-row">
                              <p>{translation.selected}: <span>{sysDivisionName}</span></p>
                          </div>
                          <div className="datagrid-line">
                              <MasterDetailGrid
                                  dataSource={subDataSource}
                                  setDataSource={setSubDataSource}
                                  headers={subHeader}
                                  isEdit={true}
                                  detailDataHeaders={masterDetailHeader}
                                  selectMode="multiple"
                                  link="DivisionForm"
                                  visibleState={visibleState}
                                  addData={{ sysDivisionId }}
                                  onMultipleDeleted={deleteDivisionInfo}
                                  onReorder={updateRowSeq}
                                  isPaging={false}
                                  pagerVisible={false}
                                  isScrolling={true}
                                  canAccess={canAccess}
                              />
                          </div>
                      </div>}
                  </div>
              </div>
          </div>
      </>
  );
}

export default Division;
