import { Button, Popup, ScrollView } from "devextreme-react";
import { useRef, useState } from "react";
import { getMenuMappingInfo, getUser } from "../../apiInterface/utils/Common";
import BaseDataGrid from "../../components/common/BaseDataGrid";
import DraggableTreeList from "../../components/common/DraggableTreeList";
import Search from "../../components/common/Search";
import useTranslation from "../../components/customHooks/translations";
import { getItemCategory } from "../../apiInterface/item/ItemMasterAPI";
import { getItemMasterInfo } from "../../apiInterface/machine/MoldAPI";
import useErrorHandler from "../../components/customHooks/useErrorHandler";

export default function SelectItemPopup(props) {
    const {
        visible,
        onExit = () => { },
        onOk = () => { }
    } = props;

    const [selectItemRow, setSelectItemRow] = useState([]);
    const [selectItem, setSelectItem] = useState("");
    const [itemMasterList, setItemMasterList] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [itemCategoryList, setItemCategoryList] = useState(null);
    const translation = useTranslation();
    const gridRef = useRef();
    const treeRef = useRef();
    const errorHandler = useErrorHandler();

    const itemCategoryHeader = [
        { dataField: "item_category_name", caption: translation.item_category_name.toUpperCase(), dataType: "string", allowReordering: true }
    ]
    const itemMasterHeader = [
        //{ dataField: "NO", caption: "NO", dataType: "string", isFixed: true, width: 100, alignment: "center", allowReordering: true },
        { dataField: "item_master_name", caption: translation.quality_badtype_item_name.toUpperCase(), dataType: "string", allowReordering: true },
        { dataField: "item_master_code", caption: translation.quality_badtype_item_code.toUpperCase(), dataType: "string", allowReordering: true },
        { dataField: "product_group", caption: translation.quality_badtype_product_group.toUpperCase(), dataType: "string", allowReordering: true },
    ]

    const init = () => {
        getItemCategoryList();
    }

    const getItemMasterList = async (itemCategoryId, searchText = null) => {
        const user = getUser();
        const params = {
            user_company_id: user.user_company_id,
            item_category_id: itemCategoryId,
            search_text: searchText
        }
        const res = await errorHandler(getItemMasterInfo, params);
        if (res) {
            setItemMasterList(res.data.o_data);
        }
    }

    const getItemCategoryList = async () => {
        const user = getUser();
        const menuMappingInfo = await getMenuMappingInfo();

        const params = {
            user_company_id: user.user_company_id,
            menu_mapping_id: menuMappingInfo.id,
            user_master_id: user.user_master_id
        }
        const res = await errorHandler(getItemCategory, params);
        if (res) {
            setItemCategoryList(res.data.o_data);
        }
    }

    const onRowSelected = (rows) => {
        if (rows == null) {
            setSelectItemRow([]);
        }
        else {
            setSelectItemRow([rows]);
        }
    }

    const removeTag = () => {
        setSelectItemRow([]);

        const instance = gridRef.current.getInstance();
        instance.deselectAll();
    }

    const exit = (e) => {
        onExit(e);
    }

    const ok = (e) => {
        onOk(selectItemRow);
        exit(e);
    }

    const searchDiv = <Search
        onClick={e => getItemMasterList(0, searchText)}
        value={searchText}
        onValueChanged={(value) => {
            setSearchText(value)
        }}
    />

    const onShowing = () => {
        treeRef.current.expandAll();
    }


    return (
        <Popup
            width={1100}
            height={850}
            visible={visible}
            hideOnOutsideClick={true}
            onHiding={exit}
            onShowing={onShowing}
            dragEnabled={true}
            position="center"
            showTitle={false}
            onInitialized={init}
        >
            <div className="popup-select-item">
                <div className="popup-header">
                    <p className="popup-title">{translation.quality_badtype_select_item}</p>
                </div>
                <div className="popup-body">
                    <div className="selected-item-viewer">
                        <div className="icon-checked">
                            <i></i>
                        </div>
                        <div className="selected-item-field">
                            {selectItemRow.length != 0 &&
                                <>
                                    <ul className="selected-items">
                                        <li className="item">
                                            <p className="item-name">{`${selectItemRow[0]?.item_master_name} (${selectItemRow[0]?.item_master_code})`}</p>
                                            <i className="material-icons btn-delete" onClick={removeTag}>cancel</i>
                                        </li>
                                    </ul>
                                    <div className="btn-all-delete" onClick={removeTag}>
                                        <button>{translation.delete_all}</button>
                                    </div>
                                </>
                            }
                            {selectItemRow == 0 &&
                                <p className="comment-not-selected">{translation.select_item_notice}</p>
                            }
                        </div>
                    </div>
                    <div className="item-select-box">
                        {selectItem === "" &&
                            <div className="left-panel">
                                <DraggableTreeList
                                    ref={treeRef}
                                    showCheckBox={false}
                                    allowModify={false}
                                    dataSource={itemCategoryList}
                                    keyExpr="item_category_id"
                                    displayExpr="item_category_name"
                                    parentIdExpr="item_category_parent_id"
                                    headers={itemCategoryHeader}
                                    selectMode="single"
                                    onRowClick={e => getItemMasterList(e.data.item_category_id)}
                                    width={252}
                                />
                            </div>
                        }
                        <ScrollView>
                            <div className="container-wrapper">
                                <div className="container">
                                    {selectItem !== "" ? searchDiv : null}
                                    <BaseDataGrid
                                        allowModify={false}
                                        dataSource={itemMasterList}
                                        headers={itemMasterHeader}
                                        selectMode="single"
                                        onSelectionChanged={onRowSelected}
                                        ref={gridRef}
                                    />
                                </div>
                            </div>
                        </ScrollView>
                    </div>
                </div>
                <div className="popup-footer">
                    <Button
                        width={86}
                        text={translation.cancel}
                        type="normal"
                        stylingMode="text"
                        className="btn-s-r"
                        onClick={exit}
                    />
                    <Button
                        text={translation.confirm}
                        width={86}
                        type="default"
                        stylingMode="contained"
                        className="btn-s-r"
                        onClick={ok}
                    />
                </div>
            </div>
        </Popup>
    )
}