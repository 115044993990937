﻿import React, { useEffect, useState } from "react";
import Button from 'devextreme-react/button';
import { getUser, getLanguage, getIP, getMenuMappingInfo } from '../../apiInterface/utils/Common';
import useTranslation from "../../components/customHooks/translations";
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { useLocation, useNavigate } from 'react-router-dom';
import { uploadTemplateIconsAPI, getTemplateMasterById, updateTemplateMasterWithoutJson } from '../../apiInterface/templateManagment/TemplateManagementAPI';
import useErrorHandler from "../../components/customHooks/useErrorHandler";
import { DropdownField, SingleFileField, TextboxField } from "../../components/common/FormField";
import { PageTitle } from "../../components/common/PageTitle.jsx";
import TextArea from 'devextreme-react/text-area';
import { actionDirect } from "../../components/common/FormFuncWithEnter";
import { useSwal } from "../../components/common/Swal";

const TemplateMasterForm = () => {
    const user = getUser();
    const translation = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const errorHandler = useErrorHandler();
    const language = getLanguage();
    const { success } = useSwal();
    const { title, templateCategory, rowData, inUse } = location.state;
    //const formPageData = location.state.formPageData;   // when move to TemplateMasterFrom page from createTemplate page, then bind the form page data.
    const templateCategoryId = location.state.templateCategory.id;
    const useItems = [
        { value: true, title: translation.use },
        { value: false, title: translation.notUse }
    ];
    const [icon, setIcon] = useState([]);
    const [darkicon, setDarkicon] = useState([]);
    const [data, setData] = useState(null);
    //const [isExist, setIsExist] = useState();

    //init
    useEffect(() => {
        //if (formPageData !== undefined) {
        //    setData(formPageData.data);
        //    setIcon(formPageData.image_white);
        //    setDarkicon(formPageData.image_dark);
        //}
        if (title === "Edit") {
            const fetching = async () => {
                let param = {
                    template_master_id: location.state.rowData.template_master_id,
                    sys_lang_code: language
                };
                const res = await errorHandler(getTemplateMasterById, param);
                if (res) {
                    let temp = res.data.o_data[0];
                    setData(temp);
                    if (temp.template_master_image_white) {
                        setIcon([{
                            data: temp.template_master_image_white,
                            name: temp.template_master_image_white.split('/').slice(-1)[0],
                            file: null,
                        }]);
                    }

                    if (temp.template_master_image_black) {
                        setDarkicon([{
                            data: temp.template_master_image_black,
                            name: temp.template_master_image_black.split('/').slice(-1)[0],
                            file: null,
                        }]);
                    }
                }
                //await getExist();
            }
            fetching();
        } else {
            setData({ template_master_is_active: useItems[0].value });
        }
    }, []);

    const valueChanged = (value, key) => {
        setData((cur) => {
            let newData = { ...cur };
            newData[key] = value;
            return newData
        });
    }

    const moveToCreateTemplate = async (e) => {
        let basic_theme = await uploadImage(icon);
        let dark_theme = await uploadImage(darkicon);
        const newState = {
            ...location.state,
            formPageData: { ...data }
        }
        navigate('.', { replace: true, state: newState });
        navigate('/createTemplate', {
            state: {
                basicThemeURL: basic_theme.db_url,
                darkThemeURL: dark_theme.db_url,
                template_category_id: templateCategoryId,
                addOrEdit: title,
                ...data
            }
        });
    }

    //uploading template icons
    const uploadImage = async (img) => {
        const userInfo = getUser();
        const data = { "user_id": userInfo.user_master_id }
        let body = new FormData();

        if (img.length < 1)
            return { file_name: "", db_url: "" }

        if (img[0].file == null) {
            return { file_name: img[0].name, db_url: img[0].data }
        } else {
            body.append('img', img[0].file);
        }

        const res = await errorHandler(uploadTemplateIconsAPI, data, body);
        if (res) {
            return res;
        } else {
            return { file_name: "", db_url: "" }
        }
    }

    const updateTemplateInfoWithoutJson = async () => {
        // save the image
        await uploadImage(icon);
        await uploadImage(darkicon);

        const templateMaster = location.state.rowData;
        const templateCategory = location.state.templateCategory;
        const menuMappingInfo = await getMenuMappingInfo();
        const param = {
            template_master_id: templateMaster.template_master_id,
            user_master_id: user.user_master_id,
            user_log_ip: getIP(),
            menu_mapping_id: menuMappingInfo.id,
            user_company_id: user.user_company_id,
            template_category_id: templateCategoryId,
            template_category_parent_id: templateCategory.parentId,
            template_category_name: templateCategory.name,
            template_master_name: data.template_master_name,    // can be changed
            template_master_is_active: data.template_master_is_active,  // can be changed
            template_category_is_active: true,
            template_master_comment: data.template_master_comment,  // can be changed
            template_category_comment: templateCategory.comment,
            template_master_image_white: data.template_master_image_white,  // can be changed
            template_master_image_black: data.template_master_image_black,  // can be changed
        }
        const res = await updateTemplateMasterWithoutJson(param);
        if (res.message === "MSG_100") {
            let htmlString = `<span></span><p>${translation.update_successfully}</p>`;
            let swalResFn = (response) => { navigate("/TemplateMasterGrid") };
            success(htmlString, swalResFn);
        }
    }

    return (
        < >
            <div className="template-master-form">
                <div className="right-content-title">
                    <PageTitle pageState={title} />
                    {/*<div className="page-number"><span className="current-num">1</span>/<span className="total-num">2</span></div>*/}
                </div>
                <form onSubmit={e => {
                    e.preventDefault();
                    title === "Add" ? moveToCreateTemplate() : inUse ? updateTemplateInfoWithoutJson() : moveToCreateTemplate();
                }}>
                    <div className="right-content-body">
                        <div className="container">
                            <div className="dx-fieldset">
                                <TextboxField
                                    name="template_master_name"
                                    label={translation.template_name}
                                    isEssential={true}
                                    essentialMsg={translation.template_name + translation.is_required}
                                    value={data?.template_master_name}
                                    onValueChanged={(e) => valueChanged(e.value, "template_master_name")}
                                />
                                <DropdownField
                                    name="template_master_is_active"
                                    label={translation.template_master_is_active}
                                    isEssential={true}
                                    essentialMsg={translation.use_or_not + translation.is_required}
                                    dataSource={useItems}
                                    valueExpr="value"
                                    value={data?.template_master_is_active}
                                    displayExpr="title"
                                    onValueChanged={(e) => valueChanged(e.value, "template_master_is_active")}
                                    isSearchable={false}
                                />
                                {/*<TextboxField*/}
                                {/*    label={translation.template_master_comment}*/}
                                {/*    name="template_master_comment"*/}
                                {/*    value={data?.template_master_comment}*/}
                                {/*    onValueChanged={(e) => valueChanged(e.value, "template_master_comment")}*/}
                                {/*/>*/}
                                <div className="dx-field">
                                    <div className="dx-field-label">
                                        <p>{translation.template_master_comment}</p>
                                    </div>
                                    <div className="dx-field-value">
                                        <TextArea
                                            name="template_master_comment"
                                            value={data?.template_master_comment}
                                            onValueChanged={(e) => valueChanged(e.value, "template_master_comment")}
                                        />
                                    </div>
                                </div>
                                <SingleFileField
                                    label={<p>{translation.template_master_icon_registor}<br />({translation.template_master_basic_theme})</p>}
                                    imageData={icon}
                                    setImageData={setIcon}
                                    id="normal"
                                />
                                <SingleFileField
                                    label={<p>{translation.template_master_icon_registor}<br />({translation.template_master_dark_theme})</p>}
                                    imageData={darkicon}
                                    setImageData={setDarkicon}
                                    id="dark"
                                />
                            </div>
                            <div className="block"></div>
                            {title == "Edit" &&
                                <div>
                                    <div className="dx-fieldset write-info">
                                        <div className="dx-field">
                                            <div className="dx-field-label">{translation.lastModifiedDate}</div>
                                            <div className="dx-field-value">{data?.modified_date}</div>
                                        </div>
                                        <div className="dx-field">
                                            <div className="dx-field-label">{translation.lastModifiedBy}</div>
                                            <div className="dx-field-value">{data?.modified_by_name}
                                                {
                                                    data?.modified_by_duty &&
                                                    <span className="badge badge-job-position">{data?.modified_by_duty}</span>
                                                }
                                                {
                                                    data?.modified_by_department && 
                                                    <span className="badge badge-team">{data?.modified_by_department}</span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="block"></div>
                                </div>
                            }
                            <div className="submit dx-fieldset">
                                <div className="btn-group">
                                    <Button
                                        type="normal"
                                        stylingMode="text"
                                        className="btn-s-r"
                                        onClick={() => { navigate(-1); }}
                                    >
                                        <div>
                                            <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                            <span className="dx-button-text">{translation.cancel}</span>
                                        </div>
                                    </Button>
                                    <Button
                                        type="default"
                                        stylingMode="contained"
                                        className="btn-s-r"
                                        useSubmitBehavior={true}
                                    >
                                        <div>
                                            <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                            <span className="dx-button-text">{title === "Add" ? translation.next : inUse ? translation.save : translation.next}</span>
                                        </div>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}

export default TemplateMasterForm;
