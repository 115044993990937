import React, { useEffect, useState } from "react";
import { getUser, getIP, getMenuMappingInfo, getLanguage } from '../../apiInterface/utils/Common';
import { useLocation, useNavigate } from "react-router-dom";
import Button from 'devextreme-react/button';
import TextBox from 'devextreme-react/text-box';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import useTranslation from "../../components/customHooks/translations";
import { Validator, RequiredRule, EmailRule, PatternRule, CustomRule } from 'devextreme-react/validator';
import { SelectBox } from 'devextreme-react/select-box';
import { swal, useSwal } from '../../components/common/Swal';
import {
    getBusinessIds,
    getProcessInfo,
    insertProcessInfo,
    updateProcessInfo,
    deleteProcessInfo,
    getProcessById,
    insertEquipment as insertEquipmentAPI,
} from "../../apiInterface/process/ProcessAPI";
import useErrorHandler from "../../components/customHooks/useErrorHandler";
import { useLanguageContext } from "../../components/contexts/LanguageContext";
import { actionDirect, moveFocus, submit } from "../../components/common/FormFuncWithEnter";
import { Popup } from 'devextreme-react/popup';
import { DropdownField, SearchableField, FilterComponent } from "../../components/common/FormField";
import AddEquipmentGrid from "./AddEquipmentGrid";
import { PageTitle } from "../../components/common/PageTitle.jsx";

export default function ProcessForm(props) {
    const user = getUser();
    const ipAddress = getIP();
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const translation = useTranslation();
    const location = useLocation();
    const { state } = useLocation();
    const previousPage = "/Process";
    const errorHandler = useErrorHandler();
    const language = getLanguage();
    const { success } = useSwal();
    const [isPopupVisible, setPopupVisibility] = useState(false);


    const onInsert = async () => {
        const { id: mapping_id } = await getMenuMappingInfo();
        const queryData = {
            user_master_id: user.user_master_id,
            user_log_ip: ipAddress,
            menu_mapping_id: mapping_id,
            user_business_id: parseInt(sessionStorage.getItem("businessId")),
            operation_process_code: data.operation_process_code,
            operation_process_name: data.operation_process_name,
            operation_process_is_active: data.operation_process_is_active,
            operation_process_comment: data.operation_process_comment,
        }

        const response = await errorHandler(insertProcessInfo, queryData);
        if (response) {
            const { operation_process_id: processId } = response.data.o_data[0];
            insertEquipments(processId);
        }
    }

    const onUpdate = async () => {
        const { id: mapping_id } = await getMenuMappingInfo();
        const queryData = {
            user_master_id: user.user_master_id,
            user_log_ip: ipAddress,
            menu_mapping_id: mapping_id,
            user_business_id: sessionStorage.getItem("businessId"),
            operation_process_id: data.operation_process_id,
            operation_process_code: data.operation_process_code,
            operation_process_name: data.operation_process_name,
            operation_process_is_active: data.operation_process_is_active,
            operation_process_comment: data.operation_process_comment,
        }

        const response = await errorHandler(updateProcessInfo, queryData);
        if (response) {
            insertEquipments(data.operation_process_id);
            // const cb = () => exitForm();
            // success(translation.success_update, cb);
        }
    }

    const insertEquipments = (processId) => {
        let queue = [];
        data?.equipment?.forEach(e => {
            queue.push(insertEquipment(processId, e.machine_equipment_id))
        });

        Promise.all(queue).then((result) => {
            let isSuccess = true;

            for (let i = 0; i < result.length; i++) {
                if (result[i] == null) {
                    isSuccess = false;
                    break;
                }
            }

            if (isSuccess) {
                success(translation.success_insert, exitForm);
            } else {
                throw new Error("failed to insert equipment");
            }
        });
    }

    const insertEquipment = (process, equipment) => {
        const param = {
            operation_process_id: process,
            machine_equipment_id: equipment,
        }

        return errorHandler(insertEquipmentAPI, param);
    }

    const valueChanged = (value, key) => {
        setData((cur) => {
            let newData = { ...cur };
            newData[key] = value;
            return newData
        });
    }

    const customValidation = (option) => {
        if (option.value !== undefined && option.value !== null) {
            return true;
        } else {
            return false;
        }
    }

    const useItems = [
        { value: true, title: translation.use },
        { value: false, title: translation.notUse }
    ];

    const exitForm = () => {
        navigate(previousPage, {
            state: {
                previousPage: location.pathname
            }
        });
    }

    //init
    useEffect(() => {
        if (state.title == "Edit") {
            let param = {
                operation_process_id: state.rowData.operation_process_id,
                sys_lang_code: language
            };

            const fetching = async () => {
                const res = await errorHandler(getProcessById, param);
                if (res) {
                    res.data.o_data[0].modified_date = res.data.o_data[0].modified_date.split('T')[0];
                    let temp = res.data.o_data[0];
                    temp.operation_process_id = state.rowData.operation_process_id;

                    if (temp.equipment_str != null) {
                        temp.equipment = JSON.parse(temp.equipment_str);
                    } else {
                        temp.equipment = [];
                    }

                    setData(temp);
                }
            }
            fetching();
        } else {
            setData({ operation_process_is_active: useItems[0].value });
        }
    }, []);

    return (
        <div className="process-form">
            <div className="right-content-title">
                <PageTitle pageState={location.state.title} />
            </div>
            <form onSubmit={actionDirect}>
                <div className="right-content-body">
                    <div className="container">
                        <div className="dx-fieldset">
                            <div className="dx-field">
                                <div className="dx-field-label"><p className="essential">{translation.operation_process_name}</p></div>
                                <div className="dx-field-value">
                                    <TextBox className="ix-tfs-r"
                                        name="operation_process_name"
                                        value={data?.operation_process_name}
                                        onValueChanged={(e) => valueChanged(e.value, "operation_process_name")}
                                        onEnterKey={moveFocus}
                                        inputAttr={{ focusidx: 0, autofocus: true }}
                                    >
                                        <Validator>
                                            <RequiredRule message={translation.operation_process_name + translation.is_required} />
                                        </Validator>
                                    </TextBox>
                                </div>
                            </div>
                            <div className="dx-field">
                                <div className="dx-field-label"><p className="essential">{translation.operation_process_code}</p></div>
                                <div className="dx-field-value">
                                    <TextBox className="ix-tfs-r"
                                        name="operation_process_code"
                                        value={data?.operation_process_code}
                                        onValueChanged={(e) => valueChanged(e.value, "operation_process_code")}
                                        readOnly={location.state?.title == "Edit" ? true : false}
                                        onEnterKey={moveFocus}
                                        inputAttr={{ focusidx: 1 }}
                                    >
                                        <Validator>
                                            <RequiredRule message={translation.operation_process_code + translation.is_required} />
                                        </Validator>
                                    </TextBox>
                                </div>
                            </div>
                            <div className="dx-field">
                                <div className="dx-field-label"><p>{translation.use_or_not}</p></div>
                                <div className="dx-field-value">
                                    <SelectBox
                                        value={data?.operation_process_is_active}
                                        dataSource={useItems}
                                        className="ix-tfs-r"
                                        valueExpr="value"
                                        displayExpr="title"
                                        onValueChanged={(e) => valueChanged(e.value, "operation_process_is_active")}
                                        onEnterKey={moveFocus}
                                        inputAttr={{ focusidx: 2 }}
                                    >
                                        <Validator>
                                            <CustomRule type={'custom'} validationCallback={customValidation} message={translation.operation_process_is_active + translation.is_invalid} />
                                        </Validator>
                                    </SelectBox>
                                </div>
                            </div>
                            <div className="dx-field">
                                <div className="dx-field-label"><p>{translation.operation_process_comment}</p></div>
                                <div className="dx-field-value">
                                    <TextBox className="ix-tfs-r"
                                        name="operation_factory_comment"
                                        value={data?.operation_process_comment}
                                        onValueChanged={(e) => valueChanged(e.value, "operation_process_comment")}
                                        onEnterKey={e => document.querySelector('#submitBtn').click()}
                                        inputAttr={{ focusidx: 3 }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="block"></div>
                        <div className="dx-fieldset">
                            <div className="dx-field column">
                                <div className="dx-field-label">
                                    <p>{translation._equipment}</p>
                                </div>
                                <div className="dx-field-value">
                                    {/*input datagrid*/}
                                    <AddEquipmentGrid
                                        width={668}
                                        dataSource={data?.equipment ? data.equipment : []}
                                        setDataSource={(result) => valueChanged(result, "equipment")}
                                    />
                                    <p className="info-comment"><span className="material-icons-outlined">info</span>{translation.info_equipment_management}</p>
                                </div>
                            </div>
                        </div>
                        <div className="block"></div>
                        {location.state.title == "Edit" &&
                            <div>
                                <div className="dx-fieldset write-info">
                                    <div className="dx-field">
                                        <div className="dx-field-label">{translation.lastModifiedDate}</div>
                                        <div className="dx-field-value">{data?.modified_date}</div>
                                    </div>
                                    <div className="dx-field">
                                        <div className="dx-field-label">{translation.lastModifiedBy}</div>
                                        <div className="dx-field-value">{data?.modified_by_name}
                                            {
                                                data?.modified_by_duty &&
                                                <span className="badge badge-job-position">{data?.modified_by_duty}</span>
                                            }
                                            {
                                                data?.modified_by_department &&
                                                <span className="badge badge-team">{data?.modified_by_department}</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="block"></div>
                            </div>
                        }
                        <div>
                            <div className="btn-group dx-fieldset" style={{ width: "708px" }}>
                                <Button
                                    type="normal"
                                    stylingMode="text"
                                    className="btn-s-r"
                                    onClick={exitForm}
                                >
                                    <div>
                                        <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                        <span className="dx-button-text">{translation.cancel}</span>
                                    </div>
                                </Button>
                                <Button
                                    id="submitBtn"
                                    type="default"
                                    stylingMode="contained"
                                    className="btn-s-r"
                                    useSubmitBehavior={true}
                                    onClick={e => {
                                        if (state.title === 'Add') submit(e, onInsert);
                                        else if (state.title === 'Edit') submit(e, onUpdate);
                                    }}
                                >
                                    <div>
                                        <LoadIndicator className="button-indicator" visible={false} width={20} height={20} />
                                        <span className="dx-button-text">{translation.save}</span>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}
