import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'
import TreeList, { Column, Selection } from "devextreme-react/tree-list";

/* properties

*/

export default function CustomTreeList(props, ref) {
    const {
        id,
        parentId,
        width = 60,
        headers,
        dataSource,
        isSelect = true,
        selectMode = "single",
        recursive = true,
        onRowClick: onRowClickCallback = () => { },
        onSelectionChanged = () => { },
    } = props;

    const treeRef = useRef();
    const getInstance = () => { return treeRef.current?.instance; };
    useImperativeHandle(ref, () => ({ getInstance }));

    //auto expand
    useEffect(() => {
        const instance = getInstance();
        let copy = [...dataSource];
        //to reduce time complex create obj that key is id of data and value is value.
        let tree = {}
        copy.forEach((data) => {
            tree[data[id]] = data;
            data.hasChildren = false;
        });

        copy.forEach((data) => {
            let parent = data[parentId];
            if (parent !== 0) {
                if (tree[data[parentId]] != null)
                    tree[data[parentId]].hasChildren = true;
            }
        });

        copy.forEach((data) => {
            if (data.hasChildren) {
                instance.expandRow(data[id]);
            }
        });
    }, [dataSource]);

    const onRowClick = (e) => {
        let stack = [e.node];
        let leaves = [];

        while (stack.length > 0) {
            let node = stack.pop();
            if (node.children.length > 0) {
                stack = stack.concat(node.children);
            } else {
                leaves.push(node.data);
            }
        }

        onRowClickCallback(leaves);
    }

    return (
        <TreeList
            ref={treeRef}
            dataSource={dataSource}
            width={width}
            showBorders={true}
            columnAutoWidth={true}
            wordWrapEnabled={true}
            keyExpr={id}
            ShowColumnHeaders={true}
            showColumnLines={true}
            parentIdExpr={parentId}
            onSelectionChanged={onSelectionChanged}
            onRowClick={onRowClick}
            hoverStateEnabled={true}
        >
            {isSelect &&
                <Selection mode={selectMode} recursive={recursive} />
            }
            {headers.map((val, idx) => {
                return <Column
                    key={`col-${idx}`}
                    dataField={val?.dataField}
                    caption={val?.caption}
                    dataType={val?.dataType}
                    visible={val?.visible}
                />
            })}
        </TreeList>
    )
}
CustomTreeList = forwardRef(CustomTreeList)