import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import { setUserSession } from '../../apiInterface/utils/Common';
import { signin } from '../../apiInterface/account/LoginAPI';

import Button from 'devextreme-react/button';
import TextBox from 'devextreme-react/text-box';
import SelectBox from 'devextreme-react/select-box';
import { CheckBox } from 'devextreme-react/check-box';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import useTranslation from '../../components/customHooks/translations';
import '../../contents/styles/account/select-user-type.css';

const SelectUserType = (props) => {
    const navigate = useNavigate();
    const translation = useTranslation();
    return (
        <div className="dx-swatch-ix-pds select-user-type">
            <div className="content">
                <h1
                    className="logo"
                    onClick={() => {
                        navigate('../login');
                    }}
                ></h1>
                <div className="card-wrapper">
                    {/* <div className="card account-individual">
                        <div className="card-header">
                            <h3 className="card-title">
                                <span>{translation.indivisual}</span>
                                <br />
                                {translation.account}
                            </h3>
                            <div className="img-service">
                                <i className="dx-icon-m-person"></i>
                            </div>
                        </div>
                        <div className="card-body">
                            <p>{translation.serviceIndivisual}</p>
                            <Button
                                width="100%"
                                text={translation.signUp}
                                type="default"
                                stylingMode="contained"
                                className="btn-s-r"
                                onClick={() => {
                                    navigate('../InputPersonalInfo');
                                }}
                            />
                        </div>
                    </div> */}
                    <div className="card account-business">
                        <div className="card-header">
                            <h3 className="card-title">
                                <span>{translation.business}</span>
                                <br />
                                {translation.account}
                            </h3>
                            <div className="img-service">
                                <i className="dx-icon-m-corporate-fare"></i>
                            </div>
                        </div>
                        <div className="card-body">
                            <p>{translation.serviceBusiness}</p>
                            <Button
                                width="100%"
                                text={translation.signUp}
                                type="default"
                                stylingMode="contained"
                                className="btn-s-r"
                                onClick={() => {
                                    navigate('../InputCompanyInfo');
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="copywrite">
                <p>© INTERX</p>
                {/* <p>© ㈜엘앤에프</p> */}
            </div>
        </div>
    );
};

export default SelectUserType;
