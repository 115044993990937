import React, { useEffect, useState, useRef } from 'react';
import useTranslation from '../../../components/customHooks/translations';
import useErrorHandler from '../../../components/customHooks/useErrorHandler';
import { Button, Popup, ScrollView } from 'devextreme-react';
import { TreeView } from 'devextreme-react';
import { getLanguage, getUser, getMenuMappingInfo, getIP } from '../../../apiInterface/utils/Common';
import InspectionDefectTypeGrid from '../InspectionDefectTypeGrid';
import DraggableTreeList from '../../../components/common/DraggableTreeList';
import { getDefectTypeCategory } from '../../../apiInterface/inspection/InspectionDefectType';

export default function DefectTypePopup(props) {
    const { visible, onClose = () => {}, onOk = () => {}, getDatas, setDatas, isLoad, getDataSources, setDataSources } = props;

    const translation = useTranslation();
    const errorHandler = useErrorHandler();
    const user = getUser();
    const treeViewRef = useRef();

    const [selectedItem, setSelectedItem] = useState({});
    const [selectedItems, setSelectedItems] = useState([]);

    const [category, setCategory] = useState([]);
    const [searchId, setSearchId] = useState();

    // get category
    const itemCategory = async () => {
        const param = {
            user_company_id: user.user_company_id,
            user_master_id: user.user_master_id,
        };

        const res = await errorHandler(getDefectTypeCategory, param);
        if (res) {
            res.data.o_data.map((data) => {
                data.icon = 'activefolder';
            });
            setCategory(res.data.o_data);
        }
    };

    //auto expand treeView
    useEffect(() => {
        if (treeViewRef.current != null) {
            const instance = treeViewRef.current.instance;
            instance.expandAll();
        }
    }, [category]);

    const onClickOk = (e) => {
        let copyObj = {
            // inspection_library_defect_type_id: selectedItem.defect_type_id,
            defect_type_id: selectedItem.defect_type_id,
            defect_type_code: selectedItem.defect_type_code,
            defect_type_name: selectedItem.defect_type_name,
        };

        if (isLoad) {
            selectedItems.map((m) => {
                m.inspection_library_defect_type_is_active = m.defect_type_is_active;
            });

            let unique = selectedItems.filter((item) => {
                return !getDataSources
                    .map((m) => {
                        return m.defect_type_id;
                    })
                    .includes(item.defect_type_id);
            });

            if (unique.length === 0) {
                console.log('중복 defect_type_id 입니다.');
            }

            setDataSources([...getDataSources, ...unique]);
        } else {
            let copy = { ...getDatas, ...copyObj };
            setDatas(copy);
        }

        sessionStorage.removeItem('inspectionLibraryId');
        onClose();
    };

    const [deselect, setDeselect] = useState({ status: false });
    const removeTag = (e) => {
        setSelectedItem({});

        // getRow
        let obj = {
            status: true,
            item: selectedItem,
        };
        setDeselect(obj);
    };

    const removeOneTag = (e) => {
        selectedItems.map((m) => {
            if (m.defect_type_id === e) {
                setDeselect({ status: true, item: m });
            }
        });
        selectedItems.filter((item) => {
            return item !== e;
        });
    };
    const removeAllTag = (e) => {
        setDeselect({ status: true, item: 'all' });
        setSelectedItems([]);
    };

    useEffect(() => {
        itemCategory();
    }, []);

    return (
        <Popup width={1100} height={850} visible={visible} hideOnOutsideClick={true} onHiding={onClose} dragEnabled={true} position="center" showTitle={false}>
            <div className="popup-defect-type">
                <div className="popup-header">
                    <p className="popup-title">Select Defect Type</p>
                </div>
                <div className="popup-body">
                    <div className="selected-item-viewer">
                        <div className="icon-checked">
                            <i></i>
                        </div>
                        <div className="selected-item-field">
                            {isLoad
                                ? selectedItems !== undefined &&
                                  selectedItems.length !== 0 && (
                                      <>
                                          <ul className="selected-items">
                                              {selectedItems.map((m) => {
                                                  return (
                                                      <li key={m.defect_type_id} className="item">
                                                          <p className="item-name">{m.defect_type_name}</p>
                                                          <i className="material-icons btn-delete" onClick={() => removeOneTag(m.defect_type_id)}>
                                                              cancel
                                                          </i>
                                                      </li>
                                                  );
                                              })}
                                          </ul>
                                          <div className="btn-all-delete" onClick={removeAllTag}>
                                              <button>{translation.delete_all}</button>
                                          </div>
                                      </>
                                  )
                                : selectedItem?.defect_type_id !== undefined && (
                                      <>
                                          <ul className="selected-items">
                                              <li key={selectedItem?.defect_type_id} className="item">
                                                  <p className="item-name">{selectedItem?.defect_type_name}</p>
                                                  <i className="material-icons btn-delete" onClick={() => removeTag(selectedItem?.defect_type_id)}>
                                                      cancel
                                                  </i>
                                              </li>
                                          </ul>
                                          {/* <div className="btn-all-delete" onClick={removeTag}>
                                        <button>{translation.delete_all}</button>
                                    </div> */}
                                      </>
                                  )}
                        </div>
                    </div>
                    <div className="item-select-box">
                        <div className="left-panel">
                            {/* <DraggableTreeList
                                showCheckBox={false}
                                allowModify={false}
                                dataSource={category}
                                keyExpr="defect_type_category_id"
                                displayExpr="defect_type_category_name"
                                parentIdExpr="defect_type_category_parent_id"
                                headers={categoryHeader}
                                onRowClick={
                                    (e) =>
                                        setSearchId(
                                            e.data.defect_type_category_id
                                        )
                                    // console.log(e.data.defect_type_category_id)
                                }
                                width={252}
                                showBorders={false}
                            /> */}
                            <TreeView id="treeView" keyExpr="defect_type_category_id" parentIdExpr="defect_type_category_parent_id" displayExpr="defect_type_category_name" dataStructure="plain" items={category} onItemClick={(e) => setSearchId(e.itemData.defect_type_category_id)} ref={treeViewRef} />
                        </div>
                        <ScrollView>
                            <div className="container-wrapper">
                                <InspectionDefectTypeGrid visible={visible} items={setSelectedItem} itemsss={setSelectedItems} isDeselect={deselect} search={searchId} isLoad={isLoad} />
                            </div>
                        </ScrollView>
                    </div>
                </div>
                <div className="popup-footer">
                    <Button width={86} text={translation.cancel} type="normal" stylingMode="text" className="btn-s-r" onClick={onClose} />
                    <Button text={translation.confirm} width={86} type="default" stylingMode="contained" className="btn-s-r" onClick={(e) => onClickOk(e)} />
                </div>
            </div>
        </Popup>
    );
}
