import React, { useRef } from "react";
import DataGrid, { Column, Editing } from 'devextreme-react/data-grid'
import Validator, { RequiredRule } from 'devextreme-react/validator';
import useTranslation from "../customHooks/translations";
import { useEffect } from "react";
import { getLanguage, getUser } from "../../apiInterface/utils/Common";
import useErrorHandler from "../customHooks/useErrorHandler";
import { getMainSysDivision, getMainSysDivisionSub } from "../../apiInterface/divisionManagement/DivisionAPI";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";
import { useState } from "react";
import { TextboxField } from "./FormField";


export default function CaptionEditor(props, ref) {
    const {
        value,
        onRowUpdated = () => { },
    } = props

    const [dataSource, setDataSource] = useState([]);
    const errorHandler = useErrorHandler();

    const getCaptions = () => { return dataSource };

    useImperativeHandle(ref, () => ({
        getCaptions
    }));

    useEffect(() => {
        init();
    }, [value]);

    const init = async () => {
        const user = getUser();
        const curLanguage = getLanguage();
        let param = {
            user_company_id: user.user_company_id,
            sys_lang_code: curLanguage,
            sys_division_id: 2,
        }

        let tempDataSource = [];
        const res = await errorHandler(getMainSysDivisionSub, param);
        if (res) {
            let data = res.data.o_data;
            for (let i = 0; i < data.length; i++) {
                let captionJson = JSON.parse(data[i].caption_str);
                let langCode = data[i].sys_division_sub_value;
                for (let j = 0; j < captionJson.length; j++) {
                    if (captionJson[j].sys_division_sub_lang_code === curLanguage) {
                        let captionData = {
                            no: data.length - i,
                            langCode: langCode,
                            name: captionJson[j].sys_division_sub_caption_text,
                            caption: ""
                        };

                        // make first letter to captial
                        captionData.name = captionData.name.toLowerCase();
                        captionData.name = captionData.name.charAt(0).toUpperCase() + captionData.name.slice(1);

                        if (value?.[langCode] != null) {
                            captionData.caption = value[langCode];
                        }

                        tempDataSource.push(captionData);
                        break;
                    }
                }
            }
        }

        setDataSource(tempDataSource);
        onRowUpdated();
    }

    useEffect(() => {
        onRowUpdated();
        console.log(dataSource);
    }, [dataSource])

    const onCaptionChanged = (e, langCode) => {
        setDataSource((cur) => {
            let copy = [...cur];
            let targetIndex = cur.findIndex(e => e.langCode === langCode);
            copy[targetIndex].caption = e.value;

            //onRowUpdated();
            return copy;
        });
    }

    return (
        <>
            {
                dataSource?.map((data, i) => {
                    let text = data.caption;
                    //let text = value == undefined ? data.caption : value[data.langCode];
                    return <TextboxField
                        key={i}
                        label={data.name}
                        value={text}
                        onValueChanged={e => { onCaptionChanged(e, data.langCode) }}
                    />
                })
            }
        </>
    )
}

CaptionEditor = forwardRef(CaptionEditor);