import React, { useState, createContext, useContext } from 'react';

const SidebarContext = createContext();
export const useSideBarContext = () => useContext(SidebarContext);

export default function LeftSideBarContextProvider(props) {
    const {
        children,
        refresh = () => { },
    } = props;
    const [sideBar, setSideBar] = useState();

    return (
        <SidebarContext.Provider value={{ sideBar, setSideBar, refresh }}>
            {children}
        </SidebarContext.Provider>
    );
}