import React, { useEffect, useState, useRef } from 'react';
import useTranslation from '../../components/customHooks/translations';
import { PageDropdown } from '../../components/common/PageDropdown';
import { PageTitle } from '../../components/common/PageTitle.jsx';
import BaseDataGrid from '../../components/common/BaseDataGrid';
import { TreeView } from 'devextreme-react';
import { SearchWithTag } from '../../components/common/SearchWithTag';
import { getLanguage, getUser, getMenuMappingInfo, getIP } from '../../apiInterface/utils/Common';
import useErrorHandler from '../../components/customHooks/useErrorHandler';
import CustomStore from 'devextreme/data/custom_store';
import Button from 'devextreme-react/button';
import Badge from '../../components/common/Badge';
import { DropdownField, FilterComponent } from '../../components/common/FormField';
import { getMainDefectType, getSysDivisionCaptionByCode, deleteDefectType, getDefectTypeCategory } from '../../apiInterface/inspection/InspectionDefectType';
import InspectionDefectCategorySettingPopup from './InspectionDefectCategorySettingPopup';
import ModalContainer from '../../components/modal/ModalContainer';
import { BASE_URL } from '../../config';

let searchText = '';
const DIVISION_CODE = 'INSPECTIONTYPE';
const baseUrl = `${BASE_URL}static_files/`;
const InspectionDefectType = () => {
    const translation = useTranslation();
    const errorHandler = useErrorHandler();
    const user = getUser();
    const lang = getLanguage();
    const ipAddress = getIP();
    const treeViewRef = useRef();

    const [dropdown, setDropdown] = useState();
    const [selectedDropdown, setSelectedDropdown] = useState('');
    const [dataSource, setDataSource] = useState([]);

    // category
    const [category, setCategory] = useState([]);
    const [isPopupVisible, setPopupVisibility] = useState(false);
    const [refreshCategory, setRefreshCategory] = useState();

    // popup image
    const [isOpen, setIsOpen] = useState(false);
    const [img, setImg] = useState();

    // get selected rowData
    const onRowSelectionChanged = (e) => {
        if (e !== null && e.length === 1) {
            sessionStorage.setItem('defectTypeId', e[0]?.defect_type_id);
        }
    };

    // get dropdown
    const getDropdownInfo = async () => {
        let param = {
            user_company_id: user.user_company_id,
            sys_division_code: DIVISION_CODE,
            sys_lang_code: lang,
        };

        const res = await errorHandler(getSysDivisionCaptionByCode, param);
        if (res) {
            let newArr = [
                {
                    sys_division_sub_caption_text: translation.all,
                    sys_division_sub_seq: 0,
                    sys_division_sub_value: '',
                },
            ];
            newArr.push(...res.data.o_data);

            setDropdown(newArr);
        }
    };

    // get category
    const itemCategory = async () => {
        const { id: mapping_id } = await getMenuMappingInfo();

        const param = {
            user_company_id: user.user_company_id,
            // menu_mapping_id: mapping_id,
            user_master_id: user.user_master_id,
        };

        const res = await errorHandler(getDefectTypeCategory, param);
        if (res) {
            // console.log(res);
            // res.data.o_data.forEach((data) => {
            //     data.icon = 'activefolder';
            // });
            setCategory(res.data.o_data);
            sessionStorage.setItem('categoryId', res.data.o_data[0].defect_type_category_id);
            getInspectionTypeInfo(res.data.o_data[0].defect_type_category_id);
        }
    };

    useEffect(() => {
        if (!isPopupVisible) {
            // reload
            itemCategory();
        }
    }, [isPopupVisible]);

    //auto expand treeView
    useEffect(() => {
        if (treeViewRef.current != null) {
            const instance = treeViewRef.current.instance;
            instance.expandAll();

            // set active first node
            const nodes = document.querySelectorAll('.dx-treeview-node');
            nodes[0]?.classList.add('selected');
        }
    }, [category]);

    // event
    const categorySelected = (e) => {
        let categoryId = e.itemData.defect_type_category_id;

        getInspectionTypeInfo(categoryId);
        sessionStorage.setItem('categoryId', categoryId);

        // selected active
        const node = document.querySelectorAll('.dx-treeview-node');
        node.forEach((m) => {
            m.classList.remove('selected');
        });
        e.itemElement.parentElement.classList.add('selected');
    };

    // get grid list
    const getInspectionTypeInfo = async (categoryId, inspectionType) => {
        const customStore = new CustomStore({
            load: async (loadOptions) => {
                let targetId = categoryId !== undefined ? categoryId : sessionStorage.getItem('categoryId');

                let skip = 0;
                let take = 10;
                if (loadOptions['skip']) skip = loadOptions['skip'];
                if (loadOptions['take']) take = loadOptions['take'];
                const pageNo = parseInt(skip / take) + 1;
                const param = {
                    user_company_id: user.user_company_id,
                    sys_lang_code: lang,
                    row_count: take,
                    page_no: pageNo,
                    defect_type_category_id: targetId,
                    search_text: searchText,
                    inspection_type: inspectionType,
                };

                const res = await errorHandler(getMainDefectType, param);
                if (res) {
                    return {
                        data: res.data.o_data,
                        totalCount: res.data.count,
                    };
                }
            },
            remove: async (value) => {
                remove(value);
            },
        });
        setDataSource(customStore);
    };

    const showPopup = (e) => {
        setImg(e.target.currentSrc);
        setIsOpen(true);
    };

    const closeModal = (e) => {
        if (e.target.classList.contains('modal-container')) {
            setIsOpen(false);
        }
    };

    // setting grid option
    const header = [
        {
            dataField: 'defect_type_no',
            caption: translation.defect_type_no,
            dataType: 'number',
            isEdit: false,
            width: 60,
            alignment: 'center',
            allowReordering: true,
            isFixed: true,
        },
        {
            dataField: 'inspection_type_name',
            caption: translation.inspection_type_name.toUpperCase(),
            dataType: 'string',
            isRequired: true,
            allowReordering: true,
            isFixed: true,
        },
        {
            dataField: 'defect_type_code',
            caption: translation.defect_type_code.toUpperCase(),
            dataType: 'string',
            allowReordering: true,
            isFixed: true,
        },
        {
            dataField: 'defect_type_name',
            caption: translation.defect_type_name.toUpperCase(),
            dataType: 'string',
            allowReordering: true,
            isFixed: true,
        },
        {
            dataField: 'defect_type_example_img',
            caption: translation.defect_type_example_img.toUpperCase(),
            allowReordering: true,
            isFixed: true,
            cellRender: (e) => {
                // if (e.value !== null && e.value !== '') return <img style={{ width: '100px', height: '100px' }} src={`${baseUrl}${e.value}`} onClick={() => showPopup(e)} />;
                return <div className="dg-thumbnail">{e.value !== null && e.value !== '' ? <img style={{ width: '100px', height: '100px', cursor: 'pointer' }} src={`${baseUrl}${e.value}`} onClick={(e) => showPopup(e)} /> : <></>}</div>;
            },
        },
        {
            dataField: 'defect_type_is_active',
            caption: translation.defect_type_is_active.toUpperCase(),
            dataType: 'string',
            width: 100,
            width: 130,
            alignment: 'center',
            allowReordering: true,
            cellRender: (e) => <Badge className={activeNotActiveClassName[e.value]} text={activeNotActiveJson[e.value]} />,
        },
        {
            dataField: 'defect_type_comment',
            caption: translation.defect_type_comment.toUpperCase(),
            dataType: 'string',
            allowReordering: true,
        },
        {
            dataField: 'defect_type_modified_by',
            caption: translation.lastModifiedBy.toUpperCase(),
            dataType: 'string',
            isEdit: false,
            width: 200,
            allowReordering: true,
        },
        {
            dataField: 'defect_type_modified_date',
            caption: translation.lastModifiedDate.toUpperCase(),
            dataType: 'string',
            isEdit: false,
            width: 150,
            alignment: 'center',
            allowReordering: true,
        },
    ];

    // delete
    const remove = async (value) => {
        const { id: mapping_id } = await getMenuMappingInfo();
        const queryData = {
            user_master_id: user.user_master_id,
            user_log_ip: ipAddress,
            menu_mapping_id: mapping_id,
            user_company_id: user.user_company_id,
            defect_type_id: value.defect_type_id,
        };

        const response = await errorHandler(deleteDefectType, queryData);
        if (response !== 'fail' && response.message === 'MSG_100') {
            // successToastr(translation.success_delete_row);
        }
    };

    // change selectbox option
    const onValueChanged = (e) => {
        setSelectedDropdown(e.value);
        getInspectionTypeInfo(undefined, e.value);
    };

    const filtering = (keyword) => {
        searchText = keyword;
        getInspectionTypeInfo();
    };

    // set badge
    const activeNotActiveClassName = {
        true: 'grid-badge-use',
        false: 'grid-badge-disabled',
    };
    const activeNotActiveJson = {
        true: translation.active,
        false: translation.not_active,
    };

    // set dropdown
    const inspectionDropdown = <DropdownField label={translation.select_inspectionType} value={selectedDropdown} dataSource={dropdown} onValueChanged={onValueChanged} valueExpr="sys_division_sub_value" displayExpr="sys_division_sub_caption_text" isSearchable={true} width={204} />;

    // set filter
    const filter = <FilterComponent label={translation.filter} width={204} onFilter={filtering} />;

    // const selectSearch = <SearchWithTag />;

    // init
    useEffect(() => {
        itemCategory();
        getDropdownInfo();
    }, []);

    return (
        <>
            <div className="inspection-defect-type">
                <div className="right-content-title">
                    <PageTitle />
                    <PageDropdown />
                </div>
                <div className="right-content-body">
                    {/* Category filter */}
                    <div className="card left-card">
                        <div className="card-header">
                            <h6 className="card-title">Classification</h6>
                            <Button icon="ix-setting" type="normal" stylingMode="text" className="btn-s-xs" onClick={() => setPopupVisibility(true)} />
                        </div>
                        <div className="card-body">
                            <div className="group-grid">
                                {category?.length == 0 || category == undefined ? (
                                    <a href="/ItemCategory">{translation.add_new_item_category}</a>
                                ) : (
                                    <TreeView id="treeView" ref={treeViewRef} keyExpr="defect_type_category_id" parentIdExpr="defect_type_category_parent_id" displayExpr="defect_type_category_name" dataStructure="plain" items={category} onItemClick={(e) => categorySelected(e)} />
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <BaseDataGrid toolbarModules={[inspectionDropdown, filter]} dataSource={dataSource} allowModify={true} isRemote={true} selectMode="multiple" isPaging={true} isFullSize={false} link="InspectionDefectTypeForm" headers={header} onSelectionChanged={onRowSelectionChanged} />
                    </div>
                </div>
            </div>
            <InspectionDefectCategorySettingPopup
                visible={isPopupVisible}
                onExit={() => {
                    setPopupVisibility(false);
                }}
                data={category}
                // onOk={setRefreshCategory}
            />
            {isOpen && (
                <ModalContainer>
                    <div className="modal-container" onClick={closeModal}>
                        <div className="btn-modal-close" onClick={(e) => setIsOpen(false)}></div>
                        <img src={img} />
                    </div>
                </ModalContainer>
            )}
        </>
    );
};

export default InspectionDefectType;
